import {Injectable} from '@angular/core';
import {Preferences} from '@capacitor/preferences';

@Injectable({
	providedIn: 'root'
})
export class LocalDataService {

	constructor() {
	}

	async setItem(key, data) {
		if (key != null) {
			key = key.toLowerCase();
		}
		await Preferences.set({
			key: key,
			value: JSON.stringify(data)
		});
	}

	async getItem(key) {
		if (key != null) {
			key = key.toLowerCase();
		}
		const ret = await Preferences.get({key: key});
		return JSON.parse(ret.value);
	}

	async removeItem(key) {
		if (key != null) {
			key = key.toLowerCase();
		}
		await Preferences.remove({key: key});
	}

	async getUserByLogin(login) {
		let found = false;
		const {keys} = await Preferences.keys();
		for (const i in keys) {
			if (keys[i].indexOf('user_credentials_') > -1) {
				const result = await this.getItem(keys[i]);
				if (result && result.user_name === login) {
					found = true;
					return result;
				}
			}
		}
		if (!found) {
			throw '';
		}
	}
}
