import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LoadingController, NavParams, Platform, PopoverController} from '@ionic/angular';

import * as moment from 'moment/moment';

import {AlertsService} from '../../services/alerts.service';
import {FormsService} from '../../services/forms.service';

import {PrimeObject} from '../../objects/forms/parametres/prime.object';

import {AppConfig} from '../../app.config';

@Component({
	selector: 'app-popover-prime',
	templateUrl: './popover-prime.component.html',
	styleUrls: ['./popover-prime.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class PopoverPrimeComponent implements OnInit {

	platformIsDesktop: boolean;

	prime: PrimeObject;

	typesPrime = AppConfig.typesPrime;

	publicHolidays = [];

	constructor(private popoverCtrl: PopoverController, private platform: Platform, private navParams: NavParams, private alertsService: AlertsService, private loadingCtrl: LoadingController, private formsService: FormsService) {
		this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
	}

	async ngOnInit() {
		try {
			if (this.navParams.get('primeToUpdate')) {
				this.prime = PrimeObject.LoadFromJSON(this.navParams.get('primeToUpdate'));
			} else {
				this.prime = new PrimeObject();
			}
		} catch (error) {
			await this.alertsService.errorAlert(null, this.alertsService.getErrorMessage(error));
		}
	}

	async enregistrerPrime() {
		const missingFieldMessage = this.prime.missingMandatoryFields();
		if (missingFieldMessage) {
			await this.alertsService.errorAlert('Donnée(s) manquante(s)', missingFieldMessage);
		} else {
			const loader = await this.loadingCtrl.create({
				spinner: null,
				message: `<div class="spinner-custom"></div>`,
				cssClass: 'loader-stemi'
			});
			await loader.present();
			try {
				const result = await this.formsService.saveForm(this.prime);
				this.prime = PrimeObject.LoadFromJSON(result);
				await loader.dismiss();
				await this.closePopover(true, this.prime);
			} catch (error) {
				await loader.dismiss();
				await this.alertsService.errorAlert(null, this.alertsService.getErrorMessage(error));
			}
		}
	}

	async closePopover(refresh = false, prime = null) {
		await this.popoverCtrl.dismiss({refresh: refresh, prime: prime});
	}
}


