import {Component, OnInit} from '@angular/core';
import {LoadingController, MenuController, NavController, Platform} from '@ionic/angular';
import {Keyboard} from '@capacitor/keyboard';
import {StatusBar, Style} from '@capacitor/status-bar';

import * as moment from 'moment';
import 'moment-timezone';

import {RedirectService} from './services/redirect.service';
import {Events} from './services/events.service';
import {LocalDataService} from './services/local-data.service';
import {UsersService} from './services/users.service';
import {AlertsService} from './services/alerts.service';

import {UserObject} from './objects/user.object';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.scss']
})
export class AppComponent implements OnInit {
	pages: Array<{
		id: number, title: string, url: any, params: any, selected: boolean, icon: string, iconActive: string, expanded?: boolean
		pages: Array<{
			id: number, title: string, url: any, params: any, selected: boolean
		}>
	}>;
	userConnected: UserObject;
	platformIsDesktop: boolean;

	constructor(private platform: Platform, private navCtrl: NavController, private redirectService: RedirectService, private events: Events, private localDataService: LocalDataService, private usersService: UsersService,
				private alertsService: AlertsService, private loadingCtrl: LoadingController, private menuCtrl: MenuController) {
		this.initializeApp();
	}

	initializeApp() {
		this.platform.ready().then(async () => {
			this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
			const htmlTag = document.getElementById('html-tag');
			if (!this.platformIsDesktop) {
				htmlTag.classList.add('stemi-mobile');
				await StatusBar.setStyle({style: Style.Dark});
				if (this.platform.is('ios')) {
					await Keyboard.setAccessoryBarVisible({isVisible: true});
				}

				if (this.platform.is('android')) {
					await StatusBar.setBackgroundColor({color: '#000000'});
					this.platform.backButton.subscribe(() => {
						// code that is executed when the user pressed the back button
					});

					// To prevent interference with ionic's own backbutton handling
					// you can subscribe with a low priority instead
					this.platform.backButton.subscribeWithPriority(0, () => {
						// code that is executed when the user pressed the back button
						// and ionic doesn't already know what to do (close modals etc...)
					});
				}

				Keyboard.addListener('keyboardWillShow', () => {
					this.hideFooter();
				});

				Keyboard.addListener('keyboardDidHide', () => {
					this.showFooter();
				});
			} else {
				htmlTag.classList.add('stemi-desktop');
			}

			moment.locale('fr');
			moment.tz.setDefault('Europe/Paris');

			this.events.subscribe('logout', async (data: any) => {
				await this.localDataService.removeItem('jwt');
				await this.localDataService.removeItem('userConnected');
				await this.redirectService.goTo(this.navCtrl, '/login', null, 'back');
				if (data != null && data.loader != null) {
					await data.loader.dismiss();
				}
				await this.localDataService.removeItem('logout');
			});

			this.events.subscribe('userConnected', (userConnected: UserObject) => {
				this.userConnected = userConnected;
				this.initPages(this.userConnected);
			});

			const isUserConnected = await this.usersService.isUserConnected();
			if (isUserConnected) {
				const userConnected = await this.usersService.getStoredUserConnected();
				this.events.publish('userConnected', userConnected);
			}

			if (this.platformIsDesktop) {
				this.events.subscribe('pageSelected', (id) => {
					for (const curPage of this.pages) {
						if (id !== null && curPage.id === id) {
							curPage.selected = true;
						} else {
							let pageFound = false;
							if (curPage.pages) {
								for (const curSubPage of curPage.pages) {
									if (id !== null && curSubPage.id === id) {
										pageFound = true;
										curSubPage.selected = true;
										curPage.selected = true;
										curPage.expanded = true;
									} else {
										curSubPage.selected = false;
									}
								}
							}
							if (!pageFound) {
								curPage.selected = false;
								curPage.expanded = false;
							}
						}
					}
				});
			}
		});
	}

	ngOnInit() {
	}

	async openPage(page) {
		await this.redirectService.goTo(this.navCtrl, page.url, page.params, 'forward');
	}

	showFooter() {
		const footers: any = document.getElementsByTagName('ion-footer');
		for (const i in footers) {
			if (footers[i] != null && footers[i].style != null) {
				footers[i].style.display = 'initial';
			}
		}
	}

	hideFooter() {
		const footers: any = document.getElementsByTagName('ion-footer');
		for (const i in footers) {
			if (footers[i] != null && footers[i].style != null) {
				footers[i].style.display = 'none';
			}
		}
	}

	initPages(userConnected: UserObject) {
		this.pages = [];
		if (!userConnected) {
			return;
		}

		this.pages.push({
			id: 0, title: 'Tableau de bord', url: '/home', params: null, selected: true, icon: '/assets/imgs/grid-outline.svg', iconActive: '/assets/imgs/grid.svg',
			pages: null
		});
		if (userConnected.isAuthorized('ActualitesPage', 'Visualiser')) {
			this.pages.push({
				id: 1, title: 'Fil d\'actualités', url: '/actus', params: null, selected: true, icon: '/assets/imgs/newspaper-outline.svg', iconActive: '/assets/imgs/newspaper.svg',
				pages: null
			});
		}
		if (userConnected.isAuthorized('InfosPage', 'Visualiser')) {
			this.pages.push({
				id: 2, title: 'Bibliothèque', url: '/infos', params: null, selected: false, icon: '/assets/imgs/library-outline.svg', iconActive: '/assets/imgs/library.svg',
				pages: null
			});
		}
		if (userConnected.isAuthorized('ChiffragesPage', 'Visualiser')) {
			const pages = [];
			if (userConnected.isAuthorized('ChiffragesPage', 'Visualiser')) {
				pages.push({id: 3, title: 'Chiffrages', url: '/chiffrages', params: {viewType: 'pipe'}, selected: false});
			}
			this.pages.push({
				id: null, title: 'Commerce', url: null, params: null, selected: false, icon: '/assets/imgs/briefcase-outline.svg', iconActive: '/assets/imgs/briefcase.svg', expanded: false,
				pages: pages
			});
		}
		if (userConnected.isAuthorized('AffairesPage', 'Visualiser') ||
			userConnected.isAuthorized('PlanningGeneralPage', 'Visualiser') ||
			userConnected.isAuthorized('PlanningAffairesPage', 'Visualiser') ||
			userConnected.isAuthorized('PlanningServicePage', 'Visualiser') ||
			userConnected.isAuthorized('TasksListPage', 'Visualiser')) {
			const pages = [];
			if (userConnected.isAuthorized('AffairesPage', 'Visualiser')) {
				pages.push({id: 4, title: 'Affaires', url: '/affaires', params: {viewType: 'en-cours'}, selected: false});
			}
			if (userConnected.isAuthorized('PlanningGeneralPage', 'Visualiser')) {
				pages.push({id: 5, title: 'Planning général', url: '/planning-general', params: null, selected: false});
			}
			if (userConnected.isAuthorized('PlanningAffairesPage', 'Visualiser')) {
				pages.push({id: 11, title: 'Planning des affaires', url: '/planning-affaires', params: null, selected: false});
			}
			if (userConnected.isAuthorized('PlanningServicePage', 'Visualiser', 'etude-elec')) {
				pages.push({id: 12, title: 'Planning BE Elec', url: '/planning-service', params: {viewService: 'etude-elec'}, selected: false});
			}
			if (userConnected.isAuthorized('PlanningServicePage', 'Visualiser', 'etude-auto')) {
				pages.push({id: 13, title: 'Planning BE Autom', url: '/planning-service', params: {viewService: 'etude-auto'}, selected: false});
			}
			if (userConnected.isAuthorized('PlanningServicePage', 'Visualiser', 'atelier')) {
				pages.push({id: 14, title: 'Planning Atelier', url: '/planning-service', params: {viewService: 'atelier'}, selected: false});
			}
			if (userConnected.isAuthorized('PlanningServicePage', 'Visualiser', 'chantier')) {
				pages.push({id: 15, title: 'Planning Chantier', url: '/planning-service', params: {viewService: 'chantier'}, selected: false});
			}
			if (userConnected.isAuthorized('PlanningVehiculesPage', 'Visualiser')) {
				pages.push({id: 16, title: 'Planning des véhicules', url: '/planning-vehicules', params: null, selected: false});
			}
			if (userConnected.isAuthorized('TasksListPage', 'Visualiser')) {
				pages.push({id: 6, title: 'Tâches', url: '/tasks-list', params: {taskType: 'task_demande_travaux'}, selected: false});
			}
			this.pages.push({
				id: null, title: 'Production', url: null, params: null, selected: false, icon: '/assets/imgs/albums-outline.svg', iconActive: '/assets/imgs/albums.svg', expanded: false,
				pages: pages
			});
		}
		if (userConnected.isAuthorized('FormsListPage', 'Visualiser')) {
			this.pages.push({
				id: 7, title: 'Formulaires', url: '/forms-list', params: null, selected: false, icon: '/assets/imgs/clipboard-outline.svg', iconActive: '/assets/imgs/clipboard.svg',
				pages: null
			});
		}
		if (userConnected.isAuthorized('IndicatorsPage', 'Visualiser')) {
			this.pages.push({
				id: 8, title: 'Indicateurs', url: '/indicators', params: null, selected: false, icon: '/assets/imgs/bar-chart-outline.svg', iconActive: '/assets/imgs/bar-chart.svg',
				pages: null
			});
		}
		if (userConnected.isAuthorized('UsersPage', 'Visualiser') || userConnected.isAuthorized('VehiculesPage', 'Visualiser')) {
			const pages = [];
			if (userConnected.isAuthorized('UsersPage', 'Visualiser')) {
				pages.push({id: 9, title: 'Utilisateurs', url: '/users', params: null, selected: false});
			}
			if (userConnected.isAuthorized('VehiculesPage', 'Visualiser')) {
				pages.push({id: 10, title: 'Véhicules', url: '/vehicules', params: null, selected: false});
			}
			this.pages.push({
				id: null, title: 'Accès et paramètres', url: null, params: null, selected: false, icon: '/assets/imgs/settings-outline.svg', iconActive: '/assets/imgs/settings.svg', expanded: false,
				pages: pages
			});
		}
	}

	expandCollapsePage(page) {
		page.expanded = !!!page.expanded;
	}

	async logout() {
		const confirmed = await this.alertsService.confirmationAlert('Êtes-vous sûr(e) de vouloir vous déconnecter ?', 'Les données non enregistrées seront perdues.', 'OUI');
		if (confirmed) {
			await this.menuCtrl.close();
			const loader = await this.loadingCtrl.create({
				spinner: null,
				message: `<div class="spinner-custom"></div>`,
				cssClass: 'loader-stemi'
			});
			await loader.present();
			await this.localDataService.setItem('logout', true);
			this.events.publish('logout', {loader: loader});
		}
	}
}
