import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class Events {
	public static events = {};

	constructor() {
	}

	subscribe(evt, cb) {
		if (typeof Events.events[evt] === 'undefined') {
			Events.events[evt] = [];
		}
		Events.events[evt].push(cb);
	}

	clear(evt) {
		if (typeof Events.events[evt] === 'undefined') {
			return;
		}
		delete Events.events[evt];
	}

	publish(evt, args?) {
		if (typeof Events.events[evt] === 'undefined') {
			return;
		}
		for (let i = 0; i < Events.events[evt].length; i++) {
			Events.events[evt][i](args);
		}
	}
}
