import {UserObject} from '../../user.object';

import * as moment from 'moment';

export class AnalyseRisquesObject {

	id: number;
	table = 'analyse_risques';
	titre = 'Analyse des risques';
	createdAt: string;
	updatedAt: string;
	status: string;
	createdBy: UserObject;
	updatedBy: UserObject;

	// Step 1

	id_affaire: number;

	// Step 2

	nom_client: string;
	code_client: string;
	contact: string;
	nom_affaire: string;
	charge_affaire: string;
	chef_chantier: UserObject;
	no_devis: string;

	// Step 2 missing

	chef_chantier_missing: boolean;

	// Step 3

	risques_presents_electriques: boolean;
	risques_presents_coupure: boolean;
	risques_presents_mecanique: boolean;
	risques_presents_manuelle: boolean;
	risques_presents_ecrasement: boolean;
	risques_presents_projection: boolean;
	risques_presents_incendie: boolean;
	risques_presents_brulure: boolean;
	risques_presents_bruit: boolean;
	risques_presents_chute_plain_pied: boolean;
	risques_presents_chute_hauteur: boolean;
	risques_presents_coactivite: boolean;
	risques_presents_circulation: boolean;
	risques_presents_trajet: boolean;
	risques_presents_engin: boolean;
	risques_presents_chimiques: boolean;
	risques_presents_thermique: boolean;
	risques_presents_chutes_objets: boolean;
	risques_presents_agroalimentaire: boolean;
	autres_risques: boolean;
	autres_risques_consequences: string;
	autres_risques_prevention: string;
	specificites_chantier: string;

	// Step 3 missing

	autres_risques_consequences_missing: boolean;
	autres_risques_prevention_missing: boolean;

	// Step 4

	date_signature: string;
	ids_destinataires: Array<UserObject>;

	// Status

	// draft
	// signed

	constructor(
		id = null,
		createdAt = null,
		updatedAt = null,
		status = 'draft',
		createdBy = null,
		updatedBy = null,
		// Step 1

		id_affaire = null,
		// Step 2

		nom_client = null,
		code_client = null,
		contact = null,
		nom_affaire = null,
		charge_affaire = null,
		chef_chantier = null,
		no_devis = null,
		// Step 3

		risques_presents_electriques = false,
		risques_presents_coupure = false,
		risques_presents_mecanique = false,
		risques_presents_manuelle = false,
		risques_presents_ecrasement = false,
		risques_presents_projection = false,
		risques_presents_incendie = false,
		risques_presents_brulure = false,
		risques_presents_bruit = false,
		risques_presents_chute_plain_pied = false,
		risques_presents_chute_hauteur = false,
		risques_presents_coactivite = false,
		risques_presents_circulation = false,
		risques_presents_trajet = false,
		risques_presents_engin = false,
		risques_presents_chimiques = false,
		risques_presents_thermique = false,
		risques_presents_chutes_objets = false,
		risques_presents_agroalimentaire = false,
		autres_risques = false,
		autres_risques_consequences = null,
		autres_risques_prevention = null,
		specificites_chantier = null,
		// Step 4

		date_signature = null,
		ids_destinataires = []
	) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;

		// Step 1

		this.id_affaire = id_affaire;

		// Step 2

		this.nom_client = nom_client;
		this.code_client = code_client;
		this.contact = contact;
		this.nom_affaire = nom_affaire;
		this.charge_affaire = charge_affaire;
		this.chef_chantier = chef_chantier;
		this.no_devis = no_devis;

		// step 3

		this.risques_presents_electriques = risques_presents_electriques;
		this.risques_presents_coupure = risques_presents_coupure;
		this.risques_presents_mecanique = risques_presents_mecanique;
		this.risques_presents_manuelle = risques_presents_manuelle;
		this.risques_presents_ecrasement = risques_presents_ecrasement;
		this.risques_presents_projection = risques_presents_projection;
		this.risques_presents_incendie = risques_presents_incendie;
		this.risques_presents_brulure = risques_presents_brulure;
		this.risques_presents_bruit = risques_presents_bruit;
		this.risques_presents_chute_plain_pied = risques_presents_chute_plain_pied;
		this.risques_presents_chute_hauteur = risques_presents_chute_hauteur;
		this.risques_presents_coactivite = risques_presents_coactivite;
		this.risques_presents_circulation = risques_presents_circulation;
		this.risques_presents_trajet = risques_presents_trajet;
		this.risques_presents_engin = risques_presents_engin;
		this.risques_presents_chimiques = risques_presents_chimiques;
		this.risques_presents_thermique = risques_presents_thermique;
		this.risques_presents_chutes_objets = risques_presents_chutes_objets;
		this.risques_presents_agroalimentaire = risques_presents_agroalimentaire;
		this.autres_risques = autres_risques;
		this.autres_risques_consequences = autres_risques_consequences;
		this.autres_risques_prevention = autres_risques_prevention;
		this.specificites_chantier = specificites_chantier;

		// Step 3 missing

		this.autres_risques_consequences_missing = false;
		this.autres_risques_prevention_missing = false;


		// Step 4

		this.date_signature = date_signature;
		this.ids_destinataires = ids_destinataires;
	}

	clearRelated(source) {
		switch (source) {
			case 'autres_risques' :
				if (!this.autres_risques) {
					this.autres_risques_consequences = null;
					this.autres_risques_prevention = null;
				}
				break;
			default :
				break;
		}
	}

	missingMandatoryFields(step) {
		let missingMandatory = false;
		switch (step) {
			case 2 :
				if (this.chef_chantier == null) {
					this.chef_chantier_missing = true;
					missingMandatory = true;
				}
				break;
			case 3 :
				if (this.autres_risques && (this.autres_risques_consequences == null || this.autres_risques_consequences === '')) {
					this.autres_risques_consequences_missing = true;
					missingMandatory = true;
				}
				if (this.autres_risques && (this.autres_risques_prevention == null || this.autres_risques_prevention === '')) {
					this.autres_risques_prevention_missing = true;
					missingMandatory = true;
				}
				break;
			default :
				break;
		}
		return missingMandatory;
	}

	updateMissing(source) {
		switch (source) {
			case 'chef_chantier' :
				if (this.chef_chantier != null) {
					this.chef_chantier_missing = false;
				}
				break;
			case 'autres_risques_consequences' :
				if (!this.autres_risques) {
					this.autres_risques_consequences_missing = false;
				} else if (this.autres_risques_consequences != null) {
					this.autres_risques_consequences_missing = false;
				}
				break;
			case 'autres_risques_prevention' :
				if (!this.autres_risques) {
					this.autres_risques_prevention_missing = false;
				} else if (this.autres_risques_consequences != null) {
					this.autres_risques_prevention_missing = false;
				}
				break;
			default :
				break;
		}
	}

	getFormData() {
		const formData = new FormData();

		if (this.id != null) {
			formData.append('id', JSON.stringify(this.id));
		}
		if (this.status != null) {
			formData.append('status', this.status);
		}

		// Step 1

		if (this.id_affaire != null) {
			formData.append('id_affaire', JSON.stringify(Number(this.id_affaire)));
		}

		// Step 2

		if (this.chef_chantier != null) {
			formData.append('chef_chantier', JSON.stringify(this.chef_chantier.id));
		}

		// Step 3

		if (this.risques_presents_electriques != null) {
			formData.append('risques_presents_electriques', JSON.stringify(Number(this.risques_presents_electriques)));
		}
		if (this.risques_presents_coupure != null) {
			formData.append('risques_presents_coupure', JSON.stringify(Number(this.risques_presents_coupure)));
		}
		if (this.risques_presents_mecanique != null) {
			formData.append('risques_presents_mecanique', JSON.stringify(Number(this.risques_presents_mecanique)));
		}
		if (this.risques_presents_manuelle != null) {
			formData.append('risques_presents_manuelle', JSON.stringify(Number(this.risques_presents_manuelle)));
		}
		if (this.risques_presents_ecrasement != null) {
			formData.append('risques_presents_ecrasement', JSON.stringify(Number(this.risques_presents_ecrasement)));
		}
		if (this.risques_presents_projection != null) {
			formData.append('risques_presents_projection', JSON.stringify(Number(this.risques_presents_projection)));
		}
		if (this.risques_presents_incendie != null) {
			formData.append('risques_presents_incendie', JSON.stringify(Number(this.risques_presents_incendie)));
		}
		if (this.risques_presents_brulure != null) {
			formData.append('risques_presents_brulure', JSON.stringify(Number(this.risques_presents_brulure)));
		}
		if (this.risques_presents_bruit != null) {
			formData.append('risques_presents_bruit', JSON.stringify(Number(this.risques_presents_bruit)));
		}
		if (this.risques_presents_chute_plain_pied != null) {
			formData.append('risques_presents_chute_plain_pied', JSON.stringify(Number(this.risques_presents_chute_plain_pied)));
		}
		if (this.risques_presents_chute_hauteur != null) {
			formData.append('risques_presents_chute_hauteur', JSON.stringify(Number(this.risques_presents_chute_hauteur)));
		}
		if (this.risques_presents_coactivite != null) {
			formData.append('risques_presents_coactivite', JSON.stringify(Number(this.risques_presents_coactivite)));
		}
		if (this.risques_presents_circulation != null) {
			formData.append('risques_presents_circulation', JSON.stringify(Number(this.risques_presents_circulation)));
		}
		if (this.risques_presents_trajet != null) {
			formData.append('risques_presents_trajet', JSON.stringify(Number(this.risques_presents_trajet)));
		}
		if (this.risques_presents_engin != null) {
			formData.append('risques_presents_engin', JSON.stringify(Number(this.risques_presents_engin)));
		}
		if (this.risques_presents_chimiques != null) {
			formData.append('risques_presents_chimiques', JSON.stringify(Number(this.risques_presents_chimiques)));
		}
		if (this.risques_presents_thermique != null) {
			formData.append('risques_presents_thermique', JSON.stringify(Number(this.risques_presents_thermique)));
		}
		if (this.risques_presents_chutes_objets != null) {
			formData.append('risques_presents_chutes_objets', JSON.stringify(Number(this.risques_presents_chutes_objets)));
		}
		if (this.risques_presents_agroalimentaire != null) {
			formData.append('risques_presents_agroalimentaire', JSON.stringify(Number(this.risques_presents_agroalimentaire)));
		}
		if (this.autres_risques != null) {
			formData.append('autres_risques', JSON.stringify(Number(this.autres_risques)));
		}
		if (this.autres_risques_consequences != null) {
			formData.append('autres_risques_consequences', this.autres_risques_consequences);
		}
		if (this.autres_risques_prevention != null) {
			formData.append('autres_risques_prevention', this.autres_risques_prevention);
		}
		if (this.specificites_chantier != null) {
			formData.append('specificites_chantier', this.specificites_chantier);
		}

		// Step 4

		for (const i in this.ids_destinataires) {
			if (this.ids_destinataires[i] != null && this.ids_destinataires[i].id != null) {
				formData.append('ids_destinataires[]', JSON.stringify(this.ids_destinataires[i].id));
			}
		}

		if (this.date_signature != null) {
			formData.append('date_signature', moment(this.date_signature).format());
		}

		return formData;
	}

	destinatairesList(destinataires: Array<UserObject>) {
		if (destinataires != null && destinataires.length > 0) {
			let list = '';
			for (const i in destinataires) {
				list += destinataires[i].complete_name + '\n';
			}
			list = list.substr(0, list.length - 1);
			return list;
		} else {
			return 'Choisir';
		}
	}
}
