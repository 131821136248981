import {Injectable, isDevMode} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {Network} from '@capacitor/network';

import {WebService} from './web.service';
import {LocalDataService} from './local-data.service';

import {UserObject} from '../objects/user.object';

import {AppConfig} from '../app.config';

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	platformIsDesktop: boolean;

	constructor(private platform: Platform, private localDataService: LocalDataService, private webService: WebService, private modalCtrl: ModalController) {
		this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
	}

	async userAuthenticated(user) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				if (this.platformIsDesktop) {
					throw 'NO_NETWORK';
				} else {
					try {
						const userByLogin: UserObject = await this.localDataService.getUserByLogin(user.username);
						if (user.password === userByLogin.password) {
							const userConnected = new UserObject(userByLogin.id, userByLogin.last_name, userByLogin.first_name, userByLogin.profil, userByLogin.poste, userByLogin.service, userByLogin.contract, userByLogin.mail, userByLogin.actif, userByLogin.trigramme, user.username, user.password);
							await this.localDataService.setItem('userConnected', userConnected);
							return userConnected;
						} else {
							throw '[jwt_auth] incorrect_password';
						}
					} catch (e) {
						throw 'NO_NETWORK';
					}
				}
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'login_check',
					params: {
						username: user.username,
						password: user.password
					}
				};
				const result: any = await this.webService.unauthPost(conf);
				const jwtData = {
					token: result.token,
					refresh_token: result.refresh_token
				};
				await this.localDataService.setItem('jwt', jwtData);
				if (result.user.profil !== 'profil_0') {
					const userConnected = new UserObject(result.user.id, result.user.last_name, result.user.first_name, result.user.profil, result.user.poste, result.user.service, result.user.contract, result.user.email, result.user.actif, result.user.trigramme, user.username, user.password);
					await this.localDataService.setItem('userConnected', userConnected);
					await this.localDataService.setItem('last_user_connected', user.username);
					if (!this.platformIsDesktop) {
						await this.localDataService.setItem('user_credentials_' + userConnected.id, userConnected);
					}
					return userConnected;
				} else {
					throw 'USER_INACTIVE';
				}
			}
		} catch (error) {
			throw error;
		}
	}

	async isUserConnected() {
		const token = await this.localDataService.getItem('jwt');
		return token !== null;
	}

	async getStoredUserConnected() {
		const userConnected = await this.localDataService.getItem('userConnected');
		return userConnected ? UserObject.LoadFromJSON(userConnected) : null;
	}

	async getUsers(onlyActif = false) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				if (this.platformIsDesktop) {
					throw 'NO_NETWORK';
				} else {
					const usersStored = await this.localDataService.getItem('users');
					if (usersStored != null) {
						return usersStored;
					} else {
						throw 'NO_NETWORK';
					}
				}
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'form',
					param_4: 'user',
					params: {
						bypages: AppConfig.nbItemsPerPage
					}
				};
				try {
					const result: any = await this.webService.get(conf);
					let usersRetrieved = result.data.items;
					const users = [];
					if (!isDevMode()) {
						usersRetrieved = usersRetrieved.filter((data) => {
							return (!/profil\d+/.test(data.last_name)) && !data.roles.includes('ROLE_ADMIN');
						});
					}
					for (const i in usersRetrieved) {
						if (onlyActif && (usersRetrieved[i].profil === 'profil_0' || !usersRetrieved[i].actif)) {
							continue;
						}
						users.push(new UserObject(usersRetrieved[i].id, usersRetrieved[i].last_name, usersRetrieved[i].first_name, usersRetrieved[i].profil, usersRetrieved[i].poste, usersRetrieved[i].service, usersRetrieved[i].contract, usersRetrieved[i].email, usersRetrieved[i].actif, usersRetrieved[i].trigramme));
					}
					users.sort((a: UserObject, b: UserObject) => {
						const compA = a.last_name.toLowerCase() + ' ' + a.first_name.toLowerCase();
						const compB = b.last_name.toLowerCase() + ' ' + b.first_name.toLowerCase();
						if (compA < compB) {
							return -1;
						} else if (compA > compB) {
							return 1;
						} else {
							return 0;
						}
					});
					if (!this.platformIsDesktop) {
						await this.localDataService.setItem('users', users);
					}
					return users;
				} catch (error) {
					if (this.platformIsDesktop) {
						throw error;
					} else {
						const usersStored = await this.localDataService.getItem('users');
						if (usersStored != null) {
							return usersStored;
						} else {
							throw error;
						}
					}
				}
			}
		} catch (error) {
			throw error;
		}
	}

	async updateUser(user: UserObject, password?, mail?) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const formData = new FormData();
				formData.append('profil', user.profil);
				if (mail) {
					formData.append('email', mail);
				}
				if (password) {
					formData.append('plainPassword', password);
				}
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'form',
					param_4: 'user',
					param_5: user.id,
					formData: formData
				};
				await this.webService.post(conf);
			}
		} catch (error) {
			throw error;
		}
	}

	async checkUserPassword(user: UserObject, password) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				if (user.actif && user.contract === 'Interim') {
					if (password !== '0000') {
						throw 'ERROR';
					}
				} else {
					const formData = new FormData();
					formData.append('id', user.id?.toString());
					formData.append('password', password);

					const conf = {
						param_1: 'api',
						param_2: 'auth',
						param_3: 'account',
						param_4: 'check-password',
						formData: formData
					};
					const result:any = await this.webService.post(conf);
					if (!result.data.valid) {
						throw 'ERROR'
					}
				}
			}
		} catch (error) {
			throw error;
		}
	}

	async deleteUsers(users: Array<UserObject>) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const promises = [];
				for (const i in users) {
					const formData = new FormData();
					formData.append('profil', 'profil_0');
					formData.append('email', users[i].mail);
					const conf = {
						param_1: 'api',
						param_2: 'auth',
						param_3: 'form',
						param_4: 'user',
						param_5: users[i].id,
						formData: formData
					};
					promises.push(this.webService.post(conf));
				}
				await Promise.all(promises);
			}
		} catch (error) {
			throw error;
		}
	}

	async showModalNewUser(userPage, userConnected, userCreation, userToUpdate, users) {
		const userModal = await this.modalCtrl.create({
			id: 'UserPage',
			component: userPage,
			componentProps: {userConnected: userConnected, userCreation: userCreation, userToUpdate: userToUpdate, users: users},
			backdropDismiss: false,
			cssClass: 'custom-modal modal-new-user'
		});
		await userModal.present();
		await userModal.onWillDismiss();
	}

	getUserFromId(id, users: Array<UserObject>) {
		let userRetrieved = null;
		for (const i in users) {
			if (Number(users[i].id) === Number(id)) {
				userRetrieved = users[i];
			}
		}
		return userRetrieved;
	}

	getUsersFromIds(ids, users: Array<UserObject>) {
		const usersRetrieved = [];
		for (const i in ids) {
			for (const j in users) {
				if (Number(ids[i]) === Number(users[j].id)) {
					usersRetrieved.push(users[j]);
					break;
				}
			}
		}
		return usersRetrieved.length > 0 ? usersRetrieved : null;
	}

	updateFormsListWithNames(forms, users: Array<UserObject>) {
		for (const i in forms) {
			if (forms[i].table === 'consigne_harnais') {
				let autres_signatures = '';
				for (const j in forms[i].autres_signatures) {
					if (forms[i].autres_signatures[j] && forms[i].autres_signatures[j] !== null && forms[i].autres_signatures[j].date != null && forms[i].autres_signatures[j].id_user != null) {
						for (const k in users) {
							if (Number(forms[i].autres_signatures[j].id_user) === Number(users[k].id)) {
								autres_signatures += ', ' + users[k].complete_name;
								break;
							}
						}
					}
				}
				forms[i].autres_signatures = autres_signatures;
			}
		}
		return forms;
	}
}
