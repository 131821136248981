import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import jwt_decode from 'jwt-decode';

import {LocalDataService} from './local-data.service';

import {environment} from '../../environments/environment';

import {AppConfig} from '../app.config';

@Injectable({
	providedIn: 'root'
})
export class WebService {

	constructor(private http: HttpClient, private localDataService: LocalDataService) {
	}

	async unauthPost(conf) {
		try {
			let url = environment.url;
			if (conf.param_1 != null) {
				url += '/' + conf.param_1;
			}
			if (conf.param_2 != null) {
				url += '/' + conf.param_2;
			}

			return await this.http.post(url, conf.params).toPromise();
		} catch (error) {
			console.error('unauthPost: ', error);
			throw error.status;
		}
	}

	async getToken() {
		const jwt = await this.localDataService.getItem('jwt');
		if (jwt != null) {
			const decodedToken = this.decodeToken(jwt.token);
			if (decodedToken != null) {
				if (moment().format('X') < decodedToken.exp) {
					return jwt.token;
				} else {
					try {
						return await this.refreshToken();
					} catch (error) {
						throw error;
					}
				}
			} else {
				throw 'TOKEN';
			}
		} else {
			throw 'TOKEN';
		}
	}

	decodeToken(token) {
		let decodedToken = token.split('.');
		if (decodedToken.length > 1) {
			try {
				decodedToken = JSON.parse(atob(decodedToken[1]));
				return decodedToken;
			} catch (e) {
				return null;
			}
		} else {
			return null;
		}
	}

	async refreshToken() {
		const jwt = await this.localDataService.getItem('jwt');
		if (jwt != null) {
			try {
				const conf = {
					param_1: 'api',
					param_2: 'refresh_token',
					params: {
						refresh_token: jwt.refresh_token
					}
				};
				const result: any = await this.unauthPost(conf);
				jwt.token = result.token;
				jwt.refresh_token = result.refresh_token;
				await this.localDataService.setItem('jwt', jwt);
				return jwt.token;
			} catch (e) {
				throw 'TOKEN';
			}
		} else {
			throw 'TOKEN';
		}
	}

	async get(conf) {
		try {
			const tokenGenerated = await this.getToken();
			const params = [];
			conf.params = typeof conf.params === 'undefined' ? {} : conf.params;
			for (const i in conf.params) {
				params.push(i + '=' + encodeURIComponent(conf.params[i]));
			}

			let paramsFormated = '?' + params.join('&');
			if (paramsFormated === '?&' || paramsFormated === '?') {
				paramsFormated = '';
			}

			let url = environment.url;
			if (conf.param_1) {
				url += '/' + conf.param_1;
			}
			if (conf.param_2) {
				url += '/' + conf.param_2;
			}
			if (conf.param_3) {
				url += '/' + conf.param_3;
			}
			if (conf.param_4) {
				url += '/' + conf.param_4;
			}
			if (conf.param_5) {
				url += '/' + conf.param_5;
			}
			if (paramsFormated !== '') {
				url += paramsFormated;
			}

			const httpHeaders = new HttpHeaders({
				Authorization: 'Bearer ' + tokenGenerated
			});
			const options = {headers: httpHeaders};

			try {
				return await this.http.get(url, options).toPromise();
			} catch (error) {
				console.log(error);
				if (error.status === 502) {
					throw 502;
				} else {
					if (error && error.error) {
						throw error.error.code;
					} else if (error.status) {
						throw error.status;
					} else {
						throw null;
					}
				}
			}
		} catch (error) {
			throw error;
		}
	}

	async post(conf) {
		try {
			const tokenGenerated = await this.getToken();
			let url = environment.url;
			if (conf.param_1) {
				url += '/' + conf.param_1;
			}
			if (conf.param_2) {
				url += '/' + conf.param_2;
			}
			if (conf.param_3) {
				url += '/' + conf.param_3;
			}
			if (conf.param_4) {
				url += '/' + conf.param_4;
			}
			if (conf.param_5) {
				url += '/' + conf.param_5;
			}

			const httpHeaders = new HttpHeaders({
				Authorization: 'Bearer ' + tokenGenerated
			});
			const options = {headers: httpHeaders};

			try {
				return await this.http.post(url, conf.formData, options).toPromise();
			} catch (error) {
				console.log(error);
				if (error.status === 502) {
					throw 502;
				} else {
					if (error && error.error) {
						throw error.error.code;
					} else if (error.status) {
						throw error.status;
					} else {
						throw null;
					}
				}
			}
		} catch (error) {
			throw error;
		}
	}

	async delete(conf) {
		try {
			const tokenGenerated = await this.getToken();
			const params = [];
			conf.params = typeof conf.params === 'undefined' ? {} : conf.params;
			for (const i in conf.params) {
				params.push(i + '=' + encodeURIComponent(conf.params[i]));
			}

			let paramsFormated = '?' + params.join('&');
			if (paramsFormated === '?&' || paramsFormated === '?') {
				paramsFormated = '';
			}

			let url = environment.url;
			if (conf.param_1) {
				url += '/' + conf.param_1;
			}
			if (conf.param_2) {
				url += '/' + conf.param_2;
			}
			if (conf.param_3) {
				url += '/' + conf.param_3;
			}
			if (conf.param_4) {
				url += '/' + conf.param_4;
			}
			if (conf.param_5) {
				url += '/' + conf.param_5;
			}
			if (paramsFormated !== '') {
				url += '/' + paramsFormated;
			}

			const httpHeaders = new HttpHeaders({
				Authorization: 'Bearer ' + tokenGenerated
			});
			const options = {headers: httpHeaders};

			try {
				return await this.http.delete(url, options).toPromise();
			} catch (error) {
				console.log(error);
				if (error.status === 502) {
					throw 502;
				} else {
					if (error && error.error) {
						throw error.error.code;
					} else if (error.status) {
						throw error.status;
					} else {
						throw null;
					}
				}
			}
		} catch (error) {
			throw error;
		}
	}

	async getAppRequiredVersion(conf) {
		try {
			let url = environment.url;
			if (conf.param_1 != null) {
				url += '/' + conf.param_1;
			}
			if (conf.param_2 != null) {
				url += '/' + conf.param_2;
			}
			try {
				return await this.http.get(url).toPromise();
			} catch (error) {
				console.log(error);
				if (error.status === 502) {
					throw 502;
				} else {
					if (error && error.error) {
						throw error.error.code;
					} else if (error.status) {
						throw error.status;
					} else {
						throw null;
					}
				}
			}
		} catch (error) {
			throw error;
		}
	}

	async unauthExternalGet(url) {
		try {
			return await this.http.get(url).toPromise();
		} catch (error) {
			console.log(error);
			throw error.status;
		}
	}
}
