import {UserObject} from '../../user.object';

import * as moment from 'moment';
import 'moment-business-days';

export class DemandeAbsenceObject {
	id: number;
	table = 'demande_absence';
	titre = 'Demande d\'absence';
	createdAt: string;
	updatedAt: string;
	status: string;
	createdBy: UserObject;
	updatedBy: UserObject;

	// Step 1

	type: string;
	date_debut: string;
	date_fin: string;
	total_jours: number;
	commentaire: string;
	id_responsable: UserObject;
	no_chrono: string;

	// Step 1 missing

	type_missing: boolean;
	date_debut_missing: boolean;
	date_fin_missing: boolean;
	id_responsable_missing: boolean;

	// Step 2

	date_signature: string;

	// Status

	// draft
	// to_be_validated
	// validate
	// processed

	constructor(
		id = null,
		createdAt = null,
		updatedAt = null,
		status = 'draft',
		createdBy = null,
		updatedBy = null,
		// Step 1

		type = null,
		date_debut = null,
		date_fin = null,
		total_jours = null,
		commentaire = null,
		id_responsable = null,
		no_chrono = null,
		// Step 2

		date_signature = null
	) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;

		// Step 1

		this.type = type;
		this.date_debut = date_debut;
		this.date_fin = date_fin;
		this.total_jours = total_jours;
		this.commentaire = commentaire;
		this.id_responsable = id_responsable;
		this.no_chrono = no_chrono;

		// Step 1 missing

		this.type_missing = false;
		this.date_debut_missing = false;
		this.date_fin_missing = false;
		this.id_responsable_missing = false;

		// Step 2

		this.date_signature = date_signature;
	}

	public static LoadFromJSON(json: any) {
		return new DemandeAbsenceObject(
			json['id'],
			json['createdAt'],
			json['updatedAt'],
			json['status'],
			json['createdBy'] ? UserObject.LoadFromJSON(json['createdBy']) : null,
			json['updatedBy'] ? UserObject.LoadFromJSON(json['updatedBy']) : null,
			json['type'],
			json['date_debut'],
			json['date_fin'],
			json['total_jours'],
			json['commentaire'],
			json['id_responsable'] ? UserObject.LoadFromJSON(json['id_responsable']) : null,
			json['no_chrono']
		);
	}

	missingMandatoryFields(step) {
		let missingMandatory = false;
		switch (step) {
			case 1 :
				if (this.type == null || this.type === '') {
					this.type_missing = true;
					missingMandatory = true;
				}
				if (this.date_debut == null) {
					this.date_debut_missing = true;
					missingMandatory = true;
				}
				if (this.date_fin == null) {
					this.date_fin_missing = true;
					missingMandatory = true;
				}
				if (this.id_responsable == null) {
					this.id_responsable_missing = true;
					missingMandatory = true;
				}
				break;
			default:
				break;
		}
		return missingMandatory;
	}

	updateMissing(source) {
		switch (source) {
			case 'type':
				if (this.type !== null && this.type !== '') {
					this.type_missing = false;
				}
				break;
			case 'date_debut':
				if (this.date_debut) {
					this.date_debut_missing = false;
				}
				break;
			case 'date_fin':
				if (this.date_fin) {
					this.date_fin_missing = false;
				}
				break;
			case 'id_responsable':
				if (this.id_responsable) {
					this.id_responsable_missing = false;
				}
				break;
			default:
				break;
		}
	}

	consistantDates() {
		if (this.date_debut != null && this.date_fin != null) {
			if (moment(this.date_debut).format('YYYY-MM-DD') > moment(this.date_fin).format('YYYY-MM-DD')) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}

	getNbDays(publicHolidays) {
		let nbDays = 0;
		if (this.consistantDates()) {
			const now = moment(this.date_debut).clone();

			while (now.isSameOrBefore(this.date_fin)) {
				if (now.isBusinessDay() && !publicHolidays.some(day => moment(day.date).format('YYYY-MM-DD') === moment(now).format('YYYY-MM-DD'))) {
					nbDays++;
				}
				now.add(1, 'days');
			}
		}
		this.total_jours = nbDays;
		return nbDays;
	}

	getFormData() {
		const formData = new FormData();

		if (this.id != null) {
			formData.append('id', JSON.stringify(this.id));
		}
		if (this.createdBy != null) {
			formData.append('createdBy', JSON.stringify(this.createdBy.id));
		}
		if (this.status != null) {
			formData.append('status', this.status);
		}

		// Step 1

		if (this.type != null) {
			formData.append('type', this.type);
		}
		if (this.date_debut != null) {
			formData.append('date_debut', moment(this.date_debut).format());
		}
		if (this.date_fin != null) {
			formData.append('date_fin', moment(this.date_fin).format());
		}
		if (this.total_jours != null) {
			formData.append('total_jours', JSON.stringify(this.total_jours));
		}
		if (this.commentaire != null) {
			formData.append('commentaire', this.commentaire);
		}
		if (this.id_responsable != null && this.id_responsable.id != null) {
			formData.append('id_responsable', JSON.stringify(this.id_responsable.id));
		}

		// Step 2

		if (this.date_signature != null) {
			formData.append('date_signature', moment(this.date_signature).format());
		}

		return formData;
	}

	updateDateFin() {
		this.date_fin = moment(this.date_debut).format();
	}

	getPlanningBackgroundColor() {
		switch (this.status) {
			case 'to_be_validated':
				return '#0c4d98';
			case 'validate':
			case 'processed':
				return '#259972';
		}
	}

	getPlanningBorderColor() {
		return '#000000';
	}

	getPlanningTextColor() {
		return '#000000';
	}

	getPlanningTitle(datesRange) {
		let title = '';
		if (!this.date_debut || !this.date_fin) {
			return  this.type ? this.type : '';
		} else {
			let cptDays = 0;
			for (const d = moment(datesRange[0]); d.format('YYYY-MM-DD') <= moment(datesRange[1]).format('YYYY-MM-DD'); d.add(1, 'day'))
			{
				if (d.format('YYYY-MM-DD') >= moment(this.date_debut).format('YYYY-MM-DD') && d.format('YYYY-MM-DD') <= moment(this.date_fin).format('YYYY-MM-DD')) {
					cptDays++;
				}
			}
			const cptWeeks = Math.ceil(cptDays / 7);
			for (let i = 0; i < cptWeeks; i++) {
				title += '<div class="display-inline-table" style="width: ' + (100 / cptWeeks) + '%">' +  this.type ? this.type : '' + '</div>';
			}
			return title;
		}
	}

	missingMandatoryFieldsModal() {
		if (this.createdBy === null) {
			return 'La personne concernée n\'a pas été renseignée.';
		}
		if (this.type === null || this.type === '') {
			return 'Le type d\'absence n\'a pas été renseigné.';
		}
		if (this.date_debut === null) {
			return 'La date de début n\'a pas été renseignée.';
		}
		if (this.date_fin === null) {
			return 'La date de fin n\'a pas été renseignée.';
		}
		if (moment(this.date_fin).format('YYYY-MM-DD') < moment(this.date_debut).format('YYYY-MM-DD')) {
			return 'La date de fin est inférieure à la date de début.';
		}
		return null;
	}
}
