import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Network} from '@capacitor/network';

import * as moment from 'moment';

import {WebService} from './web.service';

import {IndicateurObject} from '../objects/indicateur.object';

@Injectable({
	providedIn: 'root'
})
export class IndicatorsService {

	constructor(private webService: WebService, private modalCtrl: ModalController) {
	}

	async getIndicators(datesRange) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'mystemi',
					param_4: 'indicateurs',
					params: {
						from: moment(datesRange[0]).format('YYYY-MM-DD'),
						to: moment(datesRange[1]).format('YYYY-MM-DD')
					}
				};
				const indicatorsRetrieved = await this.webService.get(conf);
				const indicateurs = [];
				for (const i in indicatorsRetrieved) {
					const curIndicator = indicatorsRetrieved[i];
					indicateurs.push(new IndicateurObject(curIndicator.id, curIndicator.titre, curIndicator.valeur, curIndicator.total, curIndicator.objectif, curIndicator.valeurs));
				}
				return indicateurs;
			}
		} catch (error) {
			throw error;
		}
	}

	getIndicatorsByBlock(indicators, block) {
		const indicatorsFound = [];
		switch (block) {
			case 'objectifsEntreprise' :
				for (const i in indicators) {
					const curIndicator = indicators[i];
					if (curIndicator.id === 2 || curIndicator.id === 3 || curIndicator.id === 4) {
						indicatorsFound.push(curIndicator);
					}
				}
				return indicatorsFound;
			case 'objectifsEntrepriseAccueilSecurite' :
				for (const i in indicators) {
					const curIndicator = indicators[i];
					if (curIndicator.id === 2 || curIndicator.id === 13 || curIndicator.id === 14) {
						indicatorsFound.push(curIndicator);
					}
				}
				return indicatorsFound;
			case 'objectifsEntrepriseAnalyseRisques' :
				for (const i in indicators) {
					const curIndicator = indicators[i];
					if (curIndicator.id === 3 || curIndicator.id === 15) {
						indicatorsFound.push(curIndicator);
					}
				}
				return indicatorsFound;
			case 'objectifsEntrepriseRapportIncident' :
				for (const i in indicators) {
					const curIndicator = indicators[i];
					if (curIndicator.id === 4) {
						indicatorsFound.push(curIndicator);
					}
				}
				return indicatorsFound;
			case 'objectifsEntrepriseInspectionChantier' :
				for (const i in indicators) {
					const curIndicator = indicators[i];
					if (curIndicator.id === 16 || curIndicator.id === 17) {
						indicatorsFound.push(curIndicator);
					}
				}
				return indicatorsFound;
			case 'realisationAnalysesRisquesParChargeAffaire' :
				for (const i in indicators) {
					const curIndicator = indicators[i];
					if (curIndicator.id === 5) {
						return curIndicator;
					}
				}
				return {indicators: []};
			case 'realisationInspectionsChantierParChargeAffaire' :
				for (const i in indicators) {
					const curIndicator = indicators[i];
					if (curIndicator.id === 6) {
						return curIndicator;
					}
				}
				return {indicators: []};
			case 'realisationInspectionsChantierParHse' :
				for (const i in indicators) {
					const curIndicator = indicators[i];
					if (curIndicator.id === 7) {
						return curIndicator;
					}
				}
				return {indicators: []};
			case 'quelquesChiffres' :
				for (const i in indicators) {
					const curIndicator = indicators[i];
					if (curIndicator.id === 8 || curIndicator.id === 9 || curIndicator.id === 10 || curIndicator.id === 11 || curIndicator.id === 12) {
						indicatorsFound.push(curIndicator);
					}
				}
				return indicatorsFound;
			default :
				return null;
		}
	}

	getIndicatorById(indicators, id) {
		for (const i in indicators) {
			if (indicators[i].id === id) {
				return indicators[i];
			}
		}
		return null;
	}

	async updateIndicator(id, value) {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				throw 'NO_NETWORK';
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'auth',
					param_3: 'mystemi',
					param_4: 'indicateurs',
					param_5: id,
					formData: {
						value: value
					}
				};
				await this.webService.post(conf);
			}
		} catch (error) {
			throw error;
		}
	}

	async showModalIndicator(indicatorPage, userConnected, indicators) {
		const indicatorsModal = await this.modalCtrl.create({
			id: 'IndicatorPage',
			component: indicatorPage,
			componentProps: {userConnected: userConnected, indicators: indicators},
			backdropDismiss: false,
			cssClass: 'custom-modal modal-indicators'
		});
		await indicatorsModal.present();
		await indicatorsModal.onWillDismiss();
	}
}
