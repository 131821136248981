import {Directive, HostListener, ElementRef, OnInit} from '@angular/core';

@Directive({
	selector: 'ion-textarea[appAutoResizeTextArea]'
})
export class AutoResizeTextareaDirective implements OnInit {
	@HostListener('input', ['$event.target'])
	onInput(textArea: HTMLTextAreaElement): void {
		this.adjust();
	}

	constructor(public element: ElementRef) {
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.adjust();
		}, 150);
	}

	adjust(): void {
		const ta = this.element.nativeElement.querySelector('textarea');
		if (ta) {
			ta.style.overflow = 'hidden';
			ta.style.height = 'auto';
			ta.style.height = ta.scrollHeight + 'px';
		}
	}
}
