import {FileService} from '../../../services/file.service';

import {UserObject} from '../../user.object';

import * as moment from 'moment';

export class CrInterventionObject {
	id: number;
	table = 'cr_intervention';
	titre = 'Compte rendu d\'intervention';
	createdAt: string;
	updatedAt: string;
	status: string;
	createdBy: UserObject;
	updatedBy: UserObject;

	// Step 1

	id_affaire: number;

	// Step 2

	client: string;
	interlocuteur: string;
	lieu: string;
	batiment: string;
	machine: string;
	intervenant_stemi: Array<UserObject>;
	date_intervention: string;
	date_fin_intervention: string;
	heures_bureau: number;
	heures_chantier: number;
	heures_route: number;

	// Step 2 missing

	client_missing: boolean;
	interlocuteur_missing: boolean;
	lieu_missing: boolean;
	machine_missing: boolean;
	intervenant_stemi_missing: boolean;
	date_intervention_missing: boolean;
	date_fin_intervention_missing: boolean;

	// Step 3

	description: string;
	materiel_fourni: string;
	commentaire: string;
	photos: Array<any>;

	// Step 3 missing

	description_missing: boolean;

	// Step 4

	date_signature: string;
	client_reception_nom: string;
	client_reception_date: string;
	client_reception_signature: any;

	// Status

	// draft
	// signed

	constructor(
		id = null,
		createdAt = null,
		updatedAt = null,
		status = 'draft',
		createdBy = null,
		updatedBy = null,
		// Step 1

		id_affaire = null,
		// Step 2

		client = null,
		interlocuteur = null,
		lieu = null,
		batiment = null,
		machine = null,
		intervenant_stemi = [],
		date_intervention = null,
		date_fin_intervention = null,
		heures_bureau = null,
		heures_chantier = null,
		heures_route = null,
		// Step 3

		description = null,
		materiel_fourni = null,
		commentaire = null,
		photos = null,
		// Step 4

		date_signature = null,
		client_reception_nom = null,
		client_reception_date = null,
		client_reception_signature = null
	) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;

		// Step 1

		this.id_affaire = id_affaire;

		// Step 2

		this.client = client;
		this.interlocuteur = interlocuteur;
		this.lieu = lieu;
		this.batiment = batiment;
		this.machine = machine;
		this.intervenant_stemi = intervenant_stemi;
		this.date_intervention = date_intervention;
		this.date_fin_intervention = date_fin_intervention;
		this.heures_bureau = heures_bureau;
		this.heures_chantier = heures_chantier;
		this.heures_route = heures_route;

		// Step 2 missing

		this.client_missing = false;
		this.interlocuteur_missing = false;
		this.lieu_missing = false;
		this.machine_missing = false;
		this.intervenant_stemi_missing = false;
		this.date_intervention_missing = false;
		this.date_fin_intervention_missing = false;

		// Step 3

		this.description = description;
		this.materiel_fourni = materiel_fourni;
		this.commentaire = commentaire;
		this.photos = photos;

		// Step 3 missing

		this.description_missing = false;

		// Step 4

		this.date_signature = date_signature;
		this.client_reception_nom = client_reception_nom;
		this.client_reception_date = client_reception_date;
		this.client_reception_signature = client_reception_signature;
	}

	missingMandatoryFields(step) {
		let missingMandatory = false;
		const missingDescriptions = [];
		switch (step) {
			case 2 :
				if (this.client == null || this.client === '') {
					this.client_missing = true;
					missingMandatory = true;
				}
				if (this.interlocuteur == null || this.interlocuteur === '') {
					this.interlocuteur_missing = true;
					missingMandatory = true;
				}
				if (this.lieu == null || this.client === '') {
					this.lieu_missing = true;
					missingMandatory = true;
				}
				if (this.machine == null || this.machine === '') {
					this.machine_missing = true;
					missingMandatory = true;
				}
				if (this.intervenant_stemi == null || this.intervenant_stemi.length === 0) {
					this.intervenant_stemi_missing = true;
					missingMandatory = true;
				}
				if (this.date_intervention == null) {
					this.date_intervention_missing = true;
					missingMandatory = true;
				}
				if (this.date_fin_intervention == null) {
					this.date_fin_intervention_missing = true;
					missingMandatory = true;
				}
				if (this.date_intervention != null && this.date_fin_intervention != null && moment(this.date_intervention).format('YYYY-MM-DD') > moment(this.date_fin_intervention).format('YYYY-MM-DD')) {
					missingMandatory = true;
					missingDescriptions.push('La date de fin d\'intervention doit être supérieure ou égale à la date de début d\'intervention.');
				}
				break;
			case 3 :
				if (this.description == null || this.client === '') {
					this.description_missing = true;
					missingMandatory = true;
				}
				break;
			default:
				break;
		}
		return {missingMandatory: missingMandatory, missingDescriptions: missingDescriptions};
	}

	updateMissing(source) {
		switch (source) {
			case 'client':
				if (this.client != null && this.client !== '') {
					this.client_missing = false;
				}
				break;
			case 'interlocuteur':
				if (this.interlocuteur != null && this.interlocuteur !== '') {
					this.interlocuteur_missing = false;
				}
				break;
			case 'lieu':
				if (this.lieu != null && this.lieu !== '') {
					this.lieu_missing = false;
				}
				break;
			case 'machine':
				if (this.machine != null && this.machine !== '') {
					this.machine_missing = false;
				}
				break;
			case 'intervenant_stemi':
				if (this.intervenant_stemi != null) {
					this.intervenant_stemi_missing = false;
				}
				break;
			case 'date_intervention':
				if (this.date_intervention != null) {
					this.date_intervention_missing = false;
				}
				break;
			case 'date_fin_intervention':
				if (this.date_fin_intervention != null) {
					this.date_fin_intervention_missing = false;
				}
				break;
			case 'description':
				if (this.description != null && this.description !== '') {
					this.description_missing = false;
				}
				break;
			default:
				break;
		}
	}

	getFormData() {
		const formData = new FormData();

		if (this.id != null) {
			formData.append('id', JSON.stringify(this.id));
		}
		if (this.status != null) {
			formData.append('status', this.status);
		}

		// Step 1

		if (this.id_affaire != null) {
			formData.append('id_affaire', JSON.stringify(Number(this.id_affaire)));
		}

		// Step 2

		if (this.client != null) {
			formData.append('client', this.client);
		}
		if (this.interlocuteur != null) {
			formData.append('interlocuteur', this.interlocuteur);
		}
		if (this.lieu != null) {
			formData.append('lieu', this.lieu);
		}
		if (this.batiment != null) {
			formData.append('batiment', this.batiment);
		}
		if (this.machine != null) {
			formData.append('machine', this.machine);
		}
		for (const i in this.intervenant_stemi) {
			formData.append('intervenant_stemi[]', JSON.stringify(this.intervenant_stemi[i].id));
		}
		if (this.date_intervention != null) {
			formData.append('date_intervention', moment(this.date_intervention).format());
		}
		if (this.date_fin_intervention != null) {
			formData.append('date_fin_intervention', moment(this.date_fin_intervention).format());
		}
		if (this.heures_bureau != null) {
			formData.append('heures_bureau', JSON.stringify(this.heures_bureau));
		}
		if (this.heures_chantier != null) {
			formData.append('heures_chantier', JSON.stringify(this.heures_chantier));
		}
		if (this.heures_route != null) {
			formData.append('heures_route', JSON.stringify(this.heures_route));
		}

		// Step 3

		if (this.description != null) {
			formData.append('description', this.description);
		}
		if (this.materiel_fourni != null) {
			formData.append('materiel_fourni', this.materiel_fourni);
		}
		if (this.commentaire != null) {
			formData.append('commentaire', this.commentaire);
		}
		for (const i in this.photos) {
			const curPhoto = this.photos[i];
			if (curPhoto.type === 'local') {
				formData.append('photos[]', FileService.dataURItoBlob(curPhoto.preview), curPhoto.fileName);
			}
		}

		// Step 4

		if (this.date_signature != null) {
			formData.append('date_signature', moment(this.date_signature).format());
		}
		if (this.client_reception_nom != null) {
			formData.append('client_reception_nom', this.client_reception_nom);
		}
		if (this.client_reception_date != null) {
			formData.append('client_reception_date', moment(this.client_reception_date).format());
		}

		if (this.client_reception_signature != null && this.client_reception_signature.type === 'local') {
			formData.append('client_reception_signature', FileService.dataURItoBlob(this.client_reception_signature.preview), this.client_reception_signature.fileName);
		}

		return formData;
	}

	intervenantsList(intervenants: Array<UserObject>) {
		if (intervenants != null && intervenants.length > 0) {
			let list = '';
			for (const i in intervenants) {
				list += intervenants[i].complete_name + '\n';
			}
			list = list.substr(0, list.length - 1);
			return list;
		} else {
			return 'Choisir';
		}
	}
}
