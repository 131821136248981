import * as moment from 'moment';

import {UserObject} from '../../user.object';

export class VehiculeObject {
	id: number;
	table = 'vehicule';
	createdAt: string;
	updatedAt: string;
	createdBy: UserObject;
	updatedBy: UserObject;
	immatriculation: string;
	reference: string;
	nb_places: number;
	logo: string;
	galerie: boolean;
	commentaire: string;
	ressource_affectee: UserObject;
	actif: boolean;
	marque: string;
	modele: string;
	pneus: string;
	entretiens: Array<{ date_debut: string, date_fin: string, commentaire: string }>;
	indisponibilites: Array<{ date_debut: string, date_fin: string, commentaire: string }>;

	prochain_entretien: { date_debut: string, date_fin: string, commentaire: string };

	checked: boolean;

	searchDesc: string;

	constructor(id = null, createdAt = null, updatedAt = null, createdBy = null, updatedBy = null, immatriculation = null, reference = null, nb_places = null, logo = null,
				galerie = null, commentaire = null, ressource_affectee = null, actif = true, marque = null, modele = null, pneus = null, entretiens = [], indisponibilites = []) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;
		this.immatriculation = immatriculation;
		this.reference = reference;
		this.nb_places = nb_places;
		this.logo = logo;
		this.galerie = galerie;
		this.indisponibilites = indisponibilites;
		this.commentaire = commentaire;
		this.ressource_affectee = ressource_affectee;
		this.actif = actif;
		this.marque = marque;
		this.modele = modele;
		this.pneus = pneus;
		this.entretiens = entretiens.sort((a, b) => {
			if (moment(a.date_debut).isBefore(moment(b.date_debut))) {
				return 1;
			} else if (moment(a.date_debut).isAfter(moment(b.date_debut))) {
				return -1;
			} else {
				return 0;
			}
		});
		this.indisponibilites = indisponibilites.sort((a, b) => {
			if (moment(a.date_debut).isBefore(moment(b.date_debut))) {
				return 1;
			} else if (moment(a.date_debut).isAfter(moment(b.date_debut))) {
				return -1;
			} else {
				return 0;
			}
		});

		this.setProchainEntretien();

		this.searchDesc = (this.reference !== null ? this.reference + ' - ' : '') + this.immatriculation;
	}

	public static LoadFromJSON(json: any) {
		return new VehiculeObject(
			json['id'],
			json['createdAt'],
			json['updatedAt'],
			json['createdBy'] ? UserObject.LoadFromJSON(json['createdBy']) : null,
			json['updatedBy'] ? UserObject.LoadFromJSON(json['updatedBy']) : null,
			json['immatriculation'],
			json['reference'],
			json['nb_places'],
			json['logo'],
			json['galerie'],
			json['commentaire'],
			json['ressource_affectee'] ? UserObject.LoadFromJSON(json['ressource_affectee']) : null,
			json['actif'],
			json['marque'],
			json['modele'],
			json['pneus'],
			json['entretiens'],
			json['indisponibilites']
		);
	}

	setProchainEntretien() {
		let nbJours = null;
		this.prochain_entretien = null;
		for (const i in this.entretiens) {
			const curEntretien = this.entretiens[i];
			const curNbJours = moment(curEntretien.date_debut).diff(moment(), 'days');
			if (curEntretien.date_debut && moment(curEntretien.date_debut).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') && (nbJours === null || curNbJours < nbJours)) {
				nbJours = curNbJours;
				this.prochain_entretien = curEntretien;
			}
		}
	}

	addEntretien() {
		if (!this.entretiens) {
			this.entretiens = [];
		}
		this.entretiens.push({
			date_debut: moment().format(),
			date_fin: moment().format(),
			commentaire: null
		});
	}

	addIndisponibilite() {
		if (!this.indisponibilites) {
			this.indisponibilites = [];
		}
		this.indisponibilites.push({
			date_debut: moment().format(),
			date_fin: moment().format(),
			commentaire: null
		});
	}

	missingMandatoryFields() {
		if (this.immatriculation === null || this.immatriculation === '') {
			return 'L\'immatriculation n\'a pas été renseignée.';
		}
		if (this.reference === null || this.reference === '') {
			return 'La référence n\'a pas été renseignée.';
		}
		if (this.nb_places === null) {
			return 'Le nombre de places n\'a pas été renseigné.';
		}
		return null;
	}

	getFormData() {
		return {
			immatriculation: this.immatriculation,
			reference: this.reference,
			nb_places: this.nb_places,
			logo: this.logo,
			galerie: this.galerie,
			commentaire: this.commentaire,
			ressource_affectee: this.ressource_affectee ? this.ressource_affectee.id : null,
			actif: this.actif,
			marque: this.marque,
			modele: this.modele,
			pneus: this.pneus,
			entretiens: this.entretiens,
			indisponibilites: this.indisponibilites,
		};
	}

	isDisponible(dateDebut, dateFin) {
		for (const i in this.indisponibilites) {
			const debutIndispo = this.indisponibilites[i].date_debut ? moment(this.indisponibilites[i].date_debut).format('YYYY-MM-DD') : null;
			const finIndispo = this.indisponibilites[i].date_fin ? moment(this.indisponibilites[i].date_fin).format('YYYY-MM-DD') : moment().add(6, 'months').format('YYYY-MM-DD');
			if (!debutIndispo) {
				continue;
			}
			if (moment(dateFin).format('YYYY-MM-DD') >= debutIndispo && moment(dateDebut).format('YYYY-MM-DD') <= finIndispo) {
				return false;
			}
		}
		return true;
	}
}
