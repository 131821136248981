import * as moment from 'moment/moment';

import {UserObject} from '../../user.object';

export class DevisObject {
	id: string;
	table = 'devis';
	createdAt: string;
	updatedAt: string;
	titre: string;
	nom_client: string;
	charge_affaire: string;
	interlocuteur: string;
	redacteur: string;
	prix: number;
	date_remise: string;
	status: string;
	notes: Array<{ createdAt: string, createdBy: UserObject, content: string }>;
	status_history: Array<{ createdAt: string, updatedAt: string, status: string }>;

	suivi_temps_passe: number;

	constructor(id = null, createdAt = null, updatedAt = null, titre = null, nom_client = null, charge_affaire = null, interlocuteur = null, redacteur = null, prix = null, date_remise = null, status = null, notes = [], status_history = []) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.titre = titre;
		this.nom_client = nom_client;
		this.charge_affaire = charge_affaire;
		this.interlocuteur = interlocuteur;
		this.redacteur = redacteur;
		this.prix = prix;
		this.date_remise = date_remise;
		this.status = status;
		this.notes = notes.sort((a, b) => {
			if (moment(a.updatedAt).isBefore(moment(b.updatedAt))) {
				return -1;
			} else if (moment(b.updatedAt).isBefore(moment(a.updatedAt))) {
				return 1;
			} else {
				return 0;
			}
		});
		this.status_history = status_history;
		this.setSuiviTempsPasse();
	}

	public static LoadFromJSON(json: any) {
		return new DevisObject(
			json['id'],
			json['createdAt'],
			json['updatedAt'],
			json['titre'],
			json['nom_client'],
			json['charge_affaire'],
			json['interlocuteur'],
			json['redacteur'],
			json['prix'],
			json['date_remise'],
			json['status'],
			this.LoadFromArray(json['notes'], 'notes'),
			json['status_history']
		);
	}

	public static LoadFromArray(arr: any[], type) {
		const ret = [];
		if (arr) {
			for (let i = 0; i < arr.length; i++) {
				switch (type) {
					case 'notes':
						ret.push({
							createdAt: arr[i].createdAt,
							createdBy: arr[i].createdBy ? UserObject.LoadFromJSON(arr[i].createdBy) : null,
							content: arr[i].content
						});
						break;
				}
			}
		}
		return ret;
	}

	getFormData() {
		const notes = [];
		for (const i in this.notes) {
			notes.push({
				content: this.notes[i].content
			});
		}
		return {
			notes: notes
		};
	}

	addNote(userConnected) {
		if (!this.notes) {
			this.notes = [];
		}
		this.notes.push({
			createdAt: moment().format(),
			createdBy: userConnected,
			content: null
		});
	}

	setSuiviTempsPasse() {
		this.suivi_temps_passe = null;
		if (!['A l\'étude', 'Attente réponse', 'Révisé'].includes(this.status)) {
			return;
		}
		for (const i in this.status_history) {
			if (this.status_history[i].status === this.status) {
				this.suivi_temps_passe = Math.round(moment().diff(this.status_history[i].createdAt, 'day', true) * 2) / 2;
				break;
			}
		}
	}
}
