import {UserObject} from '../../user.object';

import * as moment from 'moment';
import {FileService} from '../../../services/file.service';

export class PvReceptionObject {
	id: number;
	table = 'pv_reception';
	titre = 'PV de réception';
	createdAt: string;
	updatedAt: string;
	status: string;
	createdBy: UserObject;
	updatedBy: UserObject;

	// Step 1

	id_affaire: number;

	// Step 2

	nom_client: string;
	code_client: string;
	contact: string;
	nom_affaire: string;
	charge_affaire: string;
	no_devis: string;
	no_commande: string;
	sous_traitant: boolean;

	// Step 3

	reception: string;
	travaux: Array<{ id: string, designation: string, designation_missing: boolean, conformite: string, conformite_missing: boolean, commentaire: string, photos: Array<any> }>;
	complementaires: string;
	commentaires: string;

	// Step 3 missing

	reception_missing: boolean;
	travaux_missing: boolean;
	complementaires_missing: boolean;
	commentaires_missing: boolean;

	// Step 4

	date_signature: string;
	client_reception_nom: string;
	client_reception_date: string;
	client_reception_signature: any;
	sous_traitant_nom: string;
	sous_traitant_societe: string;
	sous_traitant_date_signature: string;
	sous_traitant_signature: any;

	// Status

	// draft
	// signed

	constructor(
		id = null,
		createdAt = null,
		updatedAt = null,
		status = 'draft',
		createdBy = null,
		updatedBy = null,
		// Step 1

		id_affaire = null,
		// Step 2

		nom_client = null,
		code_client = null,
		contact = null,
		charge_affaire = null,
		nom_affaire = null,
		no_devis = null,
		no_commande = null,
		sous_traitant = null,
		// Step 3

		reception = null,
		travaux = [],
		complementaires = null,
		commentaires = null,
		// Step 4

		date_signature = null,
		client_reception_nom = null,
		client_reception_date = null,
		client_reception_signature = null,
		sous_traitant_nom = null,
		sous_traitant_societe = null,
		sous_traitant_date_signature = null,
		sous_traitant_signature = null
	) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;

		// Step 1

		this.id_affaire = id_affaire;

		// Step 2

		this.nom_client = nom_client;
		this.code_client = code_client;
		this.contact = contact;
		this.charge_affaire = charge_affaire;
		this.nom_affaire = nom_affaire;
		this.no_devis = no_devis;
		this.no_commande = no_commande;
		this.sous_traitant = sous_traitant;

		// Step 3

		this.reception = reception;
		this.travaux = travaux;
		this.complementaires = complementaires;
		this.commentaires = commentaires;

		// Step 3 missing

		this.reception_missing = false;
		this.travaux_missing = false;
		this.complementaires_missing = false;
		this.commentaires_missing = false;

		// Step 4

		this.date_signature = date_signature;
		this.client_reception_nom = client_reception_nom;
		this.client_reception_date = client_reception_date;
		this.client_reception_signature = client_reception_signature;
		this.sous_traitant_nom = sous_traitant_nom;
		this.sous_traitant_societe = sous_traitant_societe;
		this.sous_traitant_date_signature = sous_traitant_date_signature;
		this.sous_traitant_signature = sous_traitant_signature;
	}

	clearRelated(source, index?) {
		switch (source) {
			case 'conformite':
				this.travaux[index].commentaire = null;
				break;
			case 'complementaires':
				this.commentaires = null;
				break;
			case 'sous_traitant':
				if (this.sous_traitant) {
					this.client_reception_nom = null;
				} else {
					this.sous_traitant_nom = null;
					this.sous_traitant_societe = null;
				}
				break;
			default:
				break;
		}
	}

	missingMandatoryFields(step) {
		let missingMandatory = false;
		switch (step) {
			case 3 :
				if (this.reception == null) {
					this.reception_missing = true;
					missingMandatory = true;
				}
				if (this.travaux == null || this.travaux.length === 0) {
					this.travaux_missing = true;
					missingMandatory = true;
				}
				for (const i in this.travaux) {
					if (this.travaux[i].designation == null || this.travaux[i].designation === '') {
						this.travaux[i].designation_missing = true;
						missingMandatory = true;
					}
					if (this.travaux[i].conformite == null) {
						this.travaux[i].conformite_missing = true;
						missingMandatory = true;
					}
				}
				if (this.complementaires == null) {
					this.complementaires_missing = true;
					missingMandatory = true;
				}
				if (this.complementaires === 'Oui' && (this.commentaires == null || this.commentaires === '')) {
					this.commentaires_missing = true;
					missingMandatory = true;
				}
				break;
			default:
				break;
		}
		return missingMandatory;
	}

	updateMissing(source, index?) {
		switch (source) {
			case 'reception':
				if (this.reception != null) {
					this.reception_missing = false;
				}
				break;
			case 'travaux':
				if (this.travaux && this.travaux.length > 0) {
					this.travaux_missing = false;
				}
				break;
			case 'designation':
				if (this.travaux[index].designation != null) {
					this.travaux[index].designation_missing = false;
				}
				break;
			case 'conformite':
				if (this.travaux[index].conformite != null) {
					this.travaux[index].conformite_missing = false;
				}
				break;
			case 'complementaires':
				if (this.complementaires != null) {
					this.complementaires_missing = false;
				}
				break;
			case 'commentaires':
				if (this.commentaires != null) {
					this.commentaires_missing = false;
				}
				break;
			default:
				break;
		}
	}

	getFormData() {
		const formData = new FormData();

		if (this.id != null) {
			formData.append('id', JSON.stringify(this.id));
		}
		if (this.status != null) {
			formData.append('status', this.status);
		}

		// Step 1

		if (this.id_affaire != null) {
			formData.append('id_affaire', JSON.stringify(Number(this.id_affaire)));
		}

		// Step 2

		if (this.sous_traitant != null) {
			formData.append('sous_traitant', JSON.stringify(Number(this.sous_traitant)));
		}

		// Step 3

		if (this.reception != null) {
			formData.append('reception', this.reception);
		}
		for (const i in this.travaux) {
			if ( this.travaux[i].id !== null) {
				formData.append('travaux[' + i + '][id]', this.travaux[i].id);
			}
			formData.append('travaux[' + i + '][designation]', this.travaux[i].designation);
			formData.append('travaux[' + i + '][conformite]', this.travaux[i].conformite);
			formData.append('travaux[' + i + '][commentaire]', this.travaux[i].commentaire);
			for (const j in this.travaux[i].photos) {
				const curPhoto = this.travaux[i].photos[j];
				if (curPhoto.type === 'local') {
					formData.append('travaux[' + i + '][photos][]', FileService.dataURItoBlob(curPhoto.preview), curPhoto.fileName);
				}
			}

		}
		if (this.complementaires != null) {
			formData.append('complementaires', this.complementaires);
		}
		if (this.commentaires != null) {
			formData.append('commentaires', this.commentaires);
		}

		// Step 4

		if (this.date_signature != null) {
			formData.append('date_signature', moment(this.date_signature).format());
		}
		if (this.client_reception_nom != null) {
			formData.append('client_reception_nom', this.client_reception_nom);
		}
		if (this.client_reception_date != null) {
			formData.append('client_reception_date', moment(this.client_reception_date).format());
		}
		if (this.client_reception_signature != null && this.client_reception_signature.type === 'local') {
			formData.append('client_reception_signature', FileService.dataURItoBlob(this.client_reception_signature.preview), this.client_reception_signature.fileName);
		}
		if (this.sous_traitant_nom != null) {
			formData.append('sous_traitant_nom', this.sous_traitant_nom);
		}
		if (this.sous_traitant_societe != null) {
			formData.append('sous_traitant_societe', this.sous_traitant_societe);
		}
		if (this.sous_traitant_date_signature != null) {
			formData.append('sous_traitant_date_signature', moment(this.sous_traitant_date_signature).format());
		}
		if (this.sous_traitant_signature != null && this.sous_traitant_signature.type === 'local') {
			formData.append('sous_traitant_signature', FileService.dataURItoBlob(this.sous_traitant_signature.preview), this.sous_traitant_signature.fileName);
		}

		return formData;
	}
}
