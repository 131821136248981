import {UserObject} from '../../user.object';

import * as moment from 'moment';

export class PlanningTacheObject {
	id: number;
	table = 'planning_tache';
	titre = 'Tache planning';
	createdAt: string;
	updatedAt: string;
	type: string;
	date_debut: string;
	date_fin: string;
	utilisateurs: Array<UserObject>;
	commentaire: string;

	constructor(id = null, createdAt = null, updatedAt = null, type = null, date_debut = null, date_fin = null, utilisateurs = [], commentaire = null) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.type = type;
		this.date_debut = date_debut;
		this.date_fin = date_fin;
		this.utilisateurs = utilisateurs;
		this.commentaire = commentaire;
	}

	public static LoadFromJSON(json: any) {
		return new PlanningTacheObject(
			json['id'],
			json['createdAt'],
			json['updatedAt'],
			json['type'],
			json['date_debut'],
			json['date_fin'],
			this.LoadFromArray(json['utilisateurs']),
			json['commentaire']
		);
	}

	public static LoadFromArray(arr: any[]) {
		const ret = [];
		if (arr) {
			for (let i = 0; i < arr.length; i++) {
				ret.push(UserObject.LoadFromJSON(arr[i]));
			}
		}
		return ret;
	}

	missingMandatoryFields() {
		if (this.type === null || this.type === '') {
			return 'Le type de tâche n\'a pas été renseigné.'
		}
		if (this.date_debut === null) {
			return 'La date de début n\'a pas été renseignée.'
		}
		if (this.date_fin === null) {
			return 'La date de fin n\'a pas été renseignée.'
		}
		if (moment(this.date_debut).isSameOrAfter(moment(this.date_fin))) {
			return 'La date de fin doit être supérieure à la date de début.'
		}
		if (this.utilisateurs?.length === 0) {
			return 'Aucune ressource n\'a été attribuée à cette tâche.';
		}
		return null;
	}

	getFormData() {
		const utilisateurs = [];
		for (const i in this.utilisateurs) {
			utilisateurs.push(this.utilisateurs[i].id);
		}
		return {
			type: this.type,
			date_debut: this.date_debut ? moment(this.date_debut).format() : null,
			date_fin: this.date_fin ? moment(this.date_fin).format() : null,
			utilisateurs: utilisateurs,
			commentaire: this.commentaire
		};
	}

	getPlanningBackgroundColor() {
		return '#fe9c15';
	}

	getPlanningBorderColor() {
		return 'transparent';
	}

	getPlanningTextColor() {
		return '#000000';
	}
}
