import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from '../../assets/fonts/pdfmake-fonts/vfs_fonts';

import * as moment from 'moment';

import {ImagesService} from './images.service';
import {IndicatorsService} from './indicators.service';

// Sécurité

import {AccueilSecuriteObject} from '../objects/forms/securite/accueil-securite.object';
import {PlanPreventionObject} from '../objects/forms/securite/plan-prevention.object';
import {AnalyseRisquesObject} from '../objects/forms/securite/analyse-risques.object';
import {InspectionChantierObject} from '../objects/forms/securite/inspection-chantier.object';
import {RapportIncidentObject} from '../objects/forms/securite/rapport-incident.object';
import {ConsigneHarnaisObject} from '../objects/forms/securite/consigne-harnais.object';

// Production

import {OuvertureAffaireObject} from '../objects/forms/production/ouverture-affaire.object';
import {DemandeTravauxObject} from '../objects/forms/production/demande-travaux.object';
import {CrInterventionObject} from '../objects/forms/production/cr-intervention.object';
import {PvReceptionObject} from '../objects/forms/production/pv-reception.object';
import {FicheQualiteObject} from '../objects/forms/production/fiche-qualite.object';

// Administratif

import {DemandeAbsenceObject} from '../objects/forms/administratif/demande-absence.object';

import {UserObject} from '../objects/user.object';
import {IndicateurObject} from '../objects/indicateur.object';

import {CurrencyFormatPipe} from '../pipes/currency-format/currency-format';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
	Fontello: {
		normal: 'fontello.ttf',
		bold: 'fontello.ttf',
	},
	Arial: {
		normal: 'arial.ttf',
		bold: 'arial-bold.ttf'
	}
};

@Injectable({
	providedIn: 'root'
})
export class PdfService {

	pdfObj = null;

	platformIsDesktop: boolean;

	currencyFormatPipe: CurrencyFormatPipe;

	constructor(private imagesService: ImagesService, private indicatorsService: IndicatorsService, private platform: Platform) {
		this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
		this.currencyFormatPipe = new CurrencyFormatPipe();
	}

	async generateFormPdf(form, userConnected?: UserObject) {
		try {
			let pdfGenerated;
			switch (form.table) {

				// Sécurité

				case 'accueil_securite' :
					pdfGenerated = await this.generateAccueilSecuritePdf(form);
					return {data: pdfGenerated, fileName: moment().format('YYMMDD') + '-' + form.code_client + '-' + form.id_affaire + '-accueil_securite.pdf'};
				case 'plan_prevention' :
					pdfGenerated = await this.generatePlanPreventionPdf(form);
					return {data: pdfGenerated, fileName: moment().format('YYMMDD') + '-' + form.id_affaire + '-plan_prevention.pdf'};
				case 'analyse_risques' :
					pdfGenerated = await this.generateAnalyseRisquesPdf(form);
					return {data: pdfGenerated, fileName: moment().format('YYMMDD') + '-' + form.code_client + '-' + form.id_affaire + '-analyse_risques.pdf'};
				case 'inspection_chantier' :
					pdfGenerated = await this.generateInspectionChantierPdf(form);
					return {data: pdfGenerated, fileName: moment().format('YYMMDD') + '-' + form.code_client + '-' + form.id_affaire + '-inspection_atelier_chantier.pdf'};
				case 'rapport_incident' :
					pdfGenerated = await this.generateRapportIncidentPdf(form, userConnected);
					return {data: pdfGenerated, fileName: moment().format('YYMMDD') + '-' + form.code_client + '-' + form.id_affaire + '-rapport_incident.pdf'};
				case 'consigne_harnais' :
					pdfGenerated = await this.generateConsigneHarnaisPdf(form);
					return {data: pdfGenerated, fileName: moment().format('YYMMDD') + '-sensibilisation_port_harnais.pdf'};

				// Production

				case 'ouverture_affaire' :
					pdfGenerated = await this.generateOuvertureAffairePdf(form);
					return {data: pdfGenerated, fileName: moment().format('YYMMDD') + '-' + form.numero_devis + '-ouverture_affaire.pdf'};
				case 'demande_travaux' :
					pdfGenerated = await this.generateDemandeTravauxPdf(form);
					return {data: pdfGenerated, fileName: moment().format('YYMMDD') + '-' + form.code_client + '-' + form.id_affaire + '-demande_travaux.pdf'};
				case 'cr_intervention' :
					pdfGenerated = await this.generateCrInterventionPdf(form);
					return {data: pdfGenerated, fileName: moment().format('YYMMDD') + '-' + form.id_affaire + '-cr_intervention.pdf'};
				case 'pv_reception' :
					pdfGenerated = await this.generatePvReceptionPdf(form);
					return {data: pdfGenerated, fileName: moment().format('YYMMDD') + '-' + form.code_client + '-' + form.id_affaire + '-pv_reception.pdf'};
				case 'fiche_qualite' :
					pdfGenerated = await this.generateFicheQualitePdf(form);
					return {data: pdfGenerated, fileName: moment().format('YYMMDD') + '-' + form.id_affaire + '-fiche_qualite.pdf'};

				// Administratif

				case 'demande_absence' :
					pdfGenerated = await this.generateDemandeAbsencePdf(form);
					return {data: pdfGenerated, fileName: moment().format('YYMMDD-HHmm') + '-demande_absence.pdf'};


				default :
					throw 'PDF_FORM_UNKNOWN';
			}
		} catch (error) {
			throw error;
		}
	}

	getHeader(title, image, dateModified) {
		return {
			table: {
				headerRows: 1,
				widths: ['20%', '60%', '20%'],
				body: [
					[{
						image: image,
						fit: [95, 95],
						margin: [0, 10, 0, 10]
					},
						{
							text: title, style: 'titreForm'
						},
						{
							text: dateModified ? moment(dateModified).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY'), style: 'creationDate'
						}
					]
				],
				margin: [10, 10]
			}
		};
	}

	getHeaderWithMargin(title, image, dateModified) {
		return {
			table: {
				headerRows: 1,
				widths: ['20%', '60%', '20%'],
				body: [
					[{
						image: image,
						fit: [95, 95],
						margin: [0, 10, 0, 10]
					},
						{
							text: title, style: 'titreForm'
						},
						{
							text: dateModified ? moment(dateModified).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY'), style: 'creationDate'
						}
					]
				],
				margin: [10, 10]
			},
			margin: [40, 30, 40, 10]
		};
	}

	getFooter(currentPage, pageCount) {
		return {
			table: {
				widths: ['80%', '20%'],
				body: [
					[
						{text: 'STEMI - 39 chemin de la Veyle 01310 SAINT REMY - 04 74 24 38 37 - info@stemi.com\nSAS au capital de 625 000 € - APE 4321A - RCS Bourg en Bresse - SIRET 505 327 817 000 19', style: 'labelHeader2', alignment: 'left'},
						{text: 'Page ' + currentPage + ' sur ' + pageCount, style: 'labelHeader2', alignment: 'right'}
					]
				]
			},
			margin: [40, 10, 40, 0],
			layout: {defaultBorder: false}
		};
	}

	getStyles() {
		return {
			MarginTop10: {
				margin: [0, 10, 0, 0]
			},
			titreForm: {
				alignment: 'center',
				margin: [0, 15, 0, 5],
				fontSize: 16,
				bold: true,
				color: '#414141'
			},
			creationDate: {
				alignment: 'center',
				margin: [0, 15, 0, 0],
				fontSize: 14,
				bold: true,
				color: '#414141'
			},
			tableBottomMarginLarge: {
				margin: [0, 0, 0, 10]
			},
			tableBottomMargin: {
				margin: [0, 0, 0, 5]
			},
			tableTopBottomMarginLarge: {
				margin: [0, 10, 0, 10]
			},
			ouvertureAffaireTitle: {
				alignment: 'center',
				fontSize: 32,
				bold: true,
				margin: [0, 5],
				color: '#414141'
			},
			ouvertureAffaireSubtitle: {
				alignment: 'center',
				fontSize: 24,
				bold: true,
				margin: [0, 5],
				color: '#414141'
			},
			title: {
				alignment: 'left',
				fontSize: 14,
				bold: true,
				margin: [0, 5],
				color: '#414141'
			},
			titleBordered: {
				alignment: 'center',
				fontSize: 14,
				bold: true,
				margin: [5, 5],
				color: '#414141'
			},
			titleCentered: {
				alignment: 'center',
				fontSize: 14,
				bold: true,
				margin: [0, 5],
				color: '#414141'
			},
			titlePadded: {
				alignment: 'left',
				fontSize: 14,
				bold: true,
				margin: [10, 5],
				color: '#414141'
			},
			subtitle: {
				alignment: 'left',
				fontSize: 13,
				bold: true,
				margin: [0, 5],
				color: '#414141'
			},
			subtitlePadded: {
				alignment: 'left',
				fontSize: 13,
				bold: true,
				margin: [10, 5],
				color: '#414141'
			},
			labelHeader: {
				alignment: 'left',
				fontSize: 12,
				margin: [0, 3],
				color: '#414141'
			},
			labelHeaderBold: {
				alignment: 'left',
				fontSize: 12,
				margin: [0, 3],
				bold: true,
				color: '#414141'
			},
			label: {
				alignment: 'left',
				fontSize: 10,
				margin: [0, 3],
				color: '#414141'
			},
			labelRed: {
				alignment: 'left',
				fontSize: 10,
				margin: [0, 3],
				color: '#ff5353',
			},
			labelHeader2: {
				alignment: 'center',
				fontSize: 9,
				margin: [0, 3],
				color: '#414141'
			},
			labelCentered: {
				alignment: 'center',
				fontSize: 10,
				margin: [0, 3],
				color: '#414141'
			},
			labelBold: {
				alignment: 'left',
				fontSize: 10,
				bold: true,
				margin: [0, 3],
				color: '#414141'
			},
			labelBoldCentered: {
				alignment: 'center',
				fontSize: 10,
				bold: true,
				margin: [0, 3],
				color: '#414141'
			},
			labelHeaderPadded: {
				alignment: 'left',
				fontSize: 12,
				margin: [10, 5],
				color: '#414141'
			},
			labelPadded: {
				alignment: 'left',
				fontSize: 10,
				margin: [10, 3],
				color: '#414141'
			},
			labelBoldPadded: {
				alignment: 'left',
				fontSize: 10,
				bold: true,
				margin: [10, 3],
				color: '#414141'
			},
			labelIndicatorGreenRight: {
				alignment: 'right',
				fontSize: 13,
				bold: true,
				color: '#7ebf57',
				margin: [5, 3]
			},
			labelIndicatorGreenLeft: {
				alignment: 'left',
				fontSize: 13,
				bold: true,
				color: '#7ebf57',
				margin: [5, 15]
			},
			labelIndicatorGreenLeftTop: {
				alignment: 'left',
				fontSize: 13,
				bold: true,
				color: '#7ebf57',
				margin: [5, 3]
			},
			labelIndicatorGreenCenter: {
				alignment: 'center',
				fontSize: 13,
				bold: true,
				color: '#7ebf57',
				margin: [5, 3]
			},
			labelIndicatorOrangeRight: {
				alignment: 'right',
				fontSize: 13,
				bold: true,
				color: '#fe9c15',
				margin: [5, 3]
			},
			labelIndicatorOrangeLeft: {
				alignment: 'left',
				fontSize: 13,
				bold: true,
				color: '#fe9c15',
				margin: [5, 15]
			},
			labelIndicatorOrangeLeftTop: {
				alignment: 'left',
				fontSize: 13,
				bold: true,
				color: '#fe9c15',
				margin: [5, 3]
			},
			labelIndicatorOrangeCenter: {
				alignment: 'center',
				fontSize: 13,
				bold: true,
				color: '#fe9c15',
				margin: [5, 3]
			},
			labelIndicatorRedRight: {
				alignment: 'right',
				fontSize: 13,
				bold: true,
				color: '#ff5353',
				margin: [5, 3]
			},
			labelIndicatorRedLeft: {
				alignment: 'left',
				fontSize: 13,
				bold: true,
				color: '#ff5353',
				margin: [5, 15]
			},
			labelIndicatorRedLeftTop: {
				alignment: 'left',
				fontSize: 13,
				bold: true,
				color: '#ff5353',
				margin: [5, 3]
			},
			labelIndicatorRedCenter: {
				alignment: 'center',
				fontSize: 13,
				bold: true,
				color: '#ff5353',
				margin: [5, 3]
			},
			labelIndicatorBlackLeft: {
				alignment: 'left',
				fontSize: 13,
				bold: true,
				color: '#414141',
				margin: [5, 15]
			},
			labelIndicatorBlackLeftTop: {
				alignment: 'left',
				fontSize: 13,
				bold: true,
				color: '#414141',
				margin: [5, 3]
			},
			content: {
				alignment: 'left',
				fontSize: 10,
				fillColor: '#eeeeee',
				margin: [10, 3]
			},
			contentRight: {
				alignment: 'right',
				fontSize: 10,
				fillColor: '#eeeeee',
				margin: [10, 3]
			},
			contentBold: {
				alignment: 'left',
				fontSize: 10,
				fillColor: '#eeeeee',
				margin: [0, 3]
			},
			contentBlank: {
				alignment: 'left',
				fontSize: 10,
				margin: [0, 3]
			},
			icon: {
				font: 'Fontello',
				fontSize: 12
			},
			spaceBetweenIndicators: {
				margin: [0, 5]
			},
			link: {
				alignment: 'left',
				fontSize: 10,
				margin: [0, 3],
				color: '#0000EE'
			}
		};
	}

	getElementValue(element: any, type) {
		if ((element == null || typeof element === 'undefined') && type !== 'boolean' && type !== 'signature') {
			if (type === 'DateSignature') {
				return 'Non signé';
			} else {
				return ' ';
			}
		} else {
			switch (type) {
				case 'string':
					return element;
				case 'c/nc/ca':
					switch (element) {
						case 'C' :
							return 'Conforme';
						case 'NC' :
							return 'Non conforme';
						case 'NA' :
							return 'Non applicable';
						default :
							return '';
					}
				case 'c/nc/r/so':
					switch (element) {
						case 'C' :
							return 'Conforme';
						case 'NC' :
							return 'Non conforme';
						case 'R' :
							return 'Réserve';
						case 'SO' :
							return 'Sans objet';
						default :
							return '';
					}
				case 'number':
					return element;
				case 'UserObject':
					return element ? element.complete_name : '';
				case 'UserObjects':
					return element.map(({complete_name}) => complete_name).join('\n');
				case 'Date':
					return moment(element).format('DD.MM.YYYY');
				case 'DateTime':
					return moment(element).format('DD.MM.YYYY HH:mm');
				case 'Time':
					return moment(element).format('HH:mm');
				case 'DateSignature':
					return 'Signé le ' + moment(element).format('DD.MM.YYYY') + ' à ' + moment(element).format('HH:mm');
				case 'boolean':
					if (element == null || !element) {
						return '';
					} else {
						return '';
					}
				case 'signature':
					if (element == null) {
						return '';
					} else {
						return '';
					}
				default :
					return ' ';
			}
		}
	}

	getTableLayoutNoBorderNoPadding() {
		return {
			defaultBorder: false,
			paddingLeft: () => {
				return 0;
			},
			paddingRight: () => {
				return 0;
			}
		};
	}

	getTableLayoutBorderNoPadding() {
		return {
			paddingLeft: () => {
				return 0;
			},
			paddingRight: () => {
				return 0;
			}
		};
	}

	getTableLayoutOutsideBorderPadding() {
		return {
			hLineWidth: (i, node) => {
				return (i === 0 || i === node.table.body.length) ? 1 : 0;
			},
			vLineWidth: (i, node) => {
				return (i === 0 || i === node.table.widths.length) ? 1 : 0;
			},
			paddingLeft: () => {
				return 10;
			},
			paddingRight: () => {
				return 10;
			},
			paddingTop: () => {
				return 5;
			},
			paddingBottom: () => {
				return 5;
			}
		};
	}

	getPermissions() {
		return {
			modifying: true,
			copying: true,
			annotating: true,
			fillingForms: true,
			contentAccessibility: true,
			documentAssembly: true
		};
	}

	getInfos(docType, formData) {
		switch (docType) {

			// Sécurité

			case 'accueil_securite' :
				return {
					title: moment().format('YYMMDD') + '-' + formData.id_affaire + '-accueil securité',
					author: formData.createdBy ? formData.createdBy.complete_name : ''
				};
			case 'plan_prevention' :
				return {
					title: moment().format('YYMMDD') + '-' + formData.id_affaire + '-plan de prévention',
					author: formData.createdBy ? formData.createdBy.complete_name : ''
				};
			case 'analyse_risques' :
				return {
					title: moment().format('YYMMDD') + '-' + formData.id_affaire + '-analyse des risques',
					author: formData.createdBy ? formData.createdBy.complete_name : ''
				};
			case 'inspection_chantier' :
				return {
					title: moment().format('YYMMDD') + '-' + formData.id_affaire + '-inspection de chantier',
					author: formData.createdBy ? formData.createdBy.complete_name : ''
				};
			case 'rapport_incident' :
				return {
					title: moment().format('YYMMDD') + '-' + formData.id_affaire + '-rapport accident, soin, situation dangereuse',
					author: formData.createdBy ? formData.createdBy.complete_name : ''
				};
			case 'consigne_harnais' :
				return {
					title: moment().format('YYMMDD') + '-consigne securité n°10',
					author: formData.createdBy ? formData.createdBy.complete_name : ''
				};

			// Production

			case 'ouverture_affaire' :
				return {
					title: moment().format('YYMMDD') + '-' + formData.numero_devis + '-ouverture d\'affaire',
					author: formData.createdBy ? formData.createdBy.complete_name : ''
				};
			case 'demande_travaux' :
				return {
					title: moment().format('YYMMDD') + '-' + formData.id_affaire + '-demande de travaux',
					author: formData.createdBy ? formData.createdBy.complete_name : ''
				};
			case 'cr_intervention' :
				return {
					title: moment().format('YYMMDD') + '-' + formData.id_affaire + '-compte rendu intervention',
					author: formData.createdBy ? formData.createdBy.complete_name : ''
				};
			case 'pv_reception' :
				return {
					title: moment().format('YYMMDD') + '-' + formData.id_affaire + '-pv de reception',
					author: formData.createdBy ? formData.createdBy.complete_name : ''
				};
			case 'fiche_qualite' :
				return {
					title: moment().format('YYMMDD') + '-' + formData.id_affaire + '-fiche qualité',
					author: formData.createdBy ? formData.createdBy.complete_name : ''
				};

			// Administratif

			case 'demande_absence' :
				return {
					title: moment().format('YYMMDD-HHmm') + '-demande absence',
					author: formData.createdBy ? formData.createdBy.complete_name : ''
				};

			// Indicators

			case 'indicators' :
				return {
					title: 'Indicateurs'
				};
			default :
				return {};
		}
	}

	getBlockSeparator() {
		return {
			canvas:
				[
					{type: 'line', x1: 515 / 2 - 60, y1: 10, x2: 515 / 2 + 60, y2: 10, lineWidth: 1.5}
				]
		};
	}

	getImageColBlock(imageData, imageWidth) {
		if (imageData) {
			return {
				table: {
					dontBreakRows: true,
					widths: '*',
					body: [
						[{image: imageData, width: imageWidth}]
					]
				}
			};
		} else {
			return {
				table: {
					dontBreakRows: true,
					widths: '*',
					body: [
						[{text: ''}]
					]
				},
				layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
			};
		}
	}

	// Sécurité

	async generateAccueilSecuritePdf(formData: AccueilSecuriteObject) {
		try {
			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];
			content.push(this.getHeader('Accueil Securité', logo, formData.updatedAt));
			content.push(
				// Step 1-2
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '30%', '48%'],
						body: [
							[{text: 'N° d’affaire', style: 'label'},
								{text: ''},
								{text: 'Client', style: 'label'}
							],
							[{text: this.getElementValue(formData.id_affaire, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nom_client, 'string') + ' (' + this.getElementValue(formData.code_client, 'string') + ')', style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Responsable du chantier', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Titre', style: 'label'}
							],
							[{text: this.getElementValue(formData.responsable, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nom_affaire, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 3

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Est-il en possession des éléments suivants ?', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			if (formData.aptitude_medicale === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Aptitude médicale pour les salariés STEMI', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Qui, quand, pourquoi ?', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.aptitude_medicale, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.aptitude_medicale_description, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Aptitude médicale pour les salariés STEMI', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.aptitude_medicale, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.autorisation_acces_chantier === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Autorisation d’accès au chantier', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Qui, quand, pourquoi ?', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.autorisation_acces_chantier, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.autorisation_acces_chantier_description, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Autorisation d’accès au chantier', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.autorisation_acces_chantier, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.titre_habilitation_electrique === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Titre d’habilitation électrique', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Qui, quand, pourquoi ?', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.titre_habilitation_electrique, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.titre_habilitation_electrique_description, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Titre d’habilitation électrique', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.titre_habilitation_electrique, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.titre_formation_chimique === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Titre de formation chimique NC1 - NC2', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Qui, quand, pourquoi ?', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.titre_formation_chimique, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.titre_formation_chimique_description, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Titre de formation chimique NC1 - NC2', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.titre_formation_chimique, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.cerfa_n1138302 === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'CERFA n°11383*02 pour les salariés STEMI', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Qui, quand, pourquoi ?', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.cerfa_n1138302, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.cerfa_n1138302_description, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'CERFA n°11383*02 pour les salariés STEMI', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.cerfa_n1138302, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.autorisation_conduite_nacelle === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Autorisation de conduite nacelle', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Qui, quand, pourquoi ?', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.autorisation_conduite_nacelle, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.autorisation_conduite_nacelle_description, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Autorisation de conduite nacelle', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.autorisation_conduite_nacelle, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.caces_nacelle === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'CACES nacelle', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Qui, quand, pourquoi ?', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.caces_nacelle, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.caces_nacelle_description, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'CACES nacelle', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.caces_nacelle, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.autorisation_echaffaudage === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Autorisation échafaudage adaptée', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Qui, quand, pourquoi ?', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.autorisation_echaffaudage, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.autorisation_echaffaudage_description, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Autorisation échafaudage adaptée', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.autorisation_echaffaudage, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.casque_vetements_travail_chaussures_securite === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Casque, vêtement de travail, chaussures de sécurité', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Qui, quand, pourquoi ?', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.casque_vetements_travail_chaussures_securite, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.casque_vetements_travail_chaussures_securite_description, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Casque, vêtement de travail, chaussures de sécurité', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.casque_vetements_travail_chaussures_securite, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.vat_casque_idra === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'VAT, casque IDRA, gants isolés, cadenas consignation', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Qui, quand, pourquoi ?', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.vat_casque_idra, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.vat_casque_idra_description, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[{text: 'VAT, casque IDRA, gants isolés, cadenas consignation', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.vat_casque_idra, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.outillage_individuel === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Outillage individuel, adéquat et en bon état', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Qui, quand, pourquoi ?', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.outillage_individuel, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.outillage_individuel_description, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Outillage individuel, adéquat et en bon état', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.outillage_individuel, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Consignes à transmettre', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[
								{text: 'Travail en cours et travail à effectuer', style: 'label'},
								{text: '', style: 'label'},
								{text: this.getElementValue(formData.travail_cours_effectuer, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[
								{text: 'Présence et signature d’un PdP / PPSPS client', style: 'label'},
								{text: '', style: 'label'},
								{text: this.getElementValue(formData.presence_signature_pdp_ppsps, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[
								{text: 'Explication et visite du chantier réalisées', style: 'label'},
								{text: '', style: 'label'},
								{text: this.getElementValue(formData.explication_visite_chantier_realises, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[
								{text: 'Droit d\'alerte et de retrait', style: 'label'},
								{text: '', style: 'label'},
								{text: this.getElementValue(formData.droit_alerte_retrait, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 4

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Risques présents sur le chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_electriques, 'boolean'), style: 'icon'}, '\tRisques électriques'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_electriques_details_basse_tension, 'boolean'), style: 'icon'}, '\tBasse tension'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_electriques_details_haute_tension, 'boolean'), style: 'icon'}, '\tHaute tension'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_electriques_details_voisinage_tension, 'boolean'), style: 'icon'}, '\tVoisinage tension'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_electriques_details_energie_permanente, 'boolean'), style: 'icon'}, '\tEnergie permanente : batterie, condensateur'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_electriques_prevention_habilitation, 'boolean'), style: 'icon'}, '\tHabilitation électrique adaptée'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_electriques_prevention_consignation, 'boolean'), style: 'icon'}, '\tConsignation'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_electriques_prevention_voisinage, 'boolean'), style: 'icon'}, '\tVoisinage de tension : casque IDRA + gants isolés'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_lies_circulation, 'boolean'), style: 'icon'}, '\tRisques liés à la circulation'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_lies_circulation_details_vehicules, 'boolean'), style: 'icon'}, '\tCirculation véhicule léger/poids lourds/engin'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_lies_circulation_details_pietonne, 'boolean'), style: 'icon'}, '\tCirculation piétonne'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_lies_circulation_moyens_prevention_regles, 'boolean'), style: 'icon'}, '\tS’Informer sur les règles de circulation et de stationnement'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_lies_circulation_moyens_prevention_baliser, 'boolean'), style: 'icon'}, '\tBaliser la zone d’intervention'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_lies_circulation_moyens_prevention_epi, 'boolean'), style: 'icon'}, '\tPort des chaussures de sécurité et du gilet haute visibilité'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_chute_hauteur, 'boolean'), style: 'icon'}, '\tRisques de chute de hauteur'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_details_nacelle, 'boolean'), style: 'icon'}, '\tNacelle'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_details_echafaudage, 'boolean'), style: 'icon'}, '\tEchafaudage'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_details_toiture, 'boolean'), style: 'icon'}, '\tToiture'], style: 'labelPadded'}],
											[{
												text: [{text: this.getElementValue(formData.risques_chute_hauteur_details_echelle, 'boolean'), style: 'icon'}, '\tEchelle / escabeau (seulement si impossibilité d’utiliser un autre moyen ou travaux très courte durée)'],
												style: 'labelPadded'
											}],
											[{
												text: [{text: this.getElementValue(formData.risques_chute_hauteur_details_harnais, 'boolean'), style: 'icon'}, '\tSensibilisation au port du harnais de sécurité'],
												style: 'labelPadded'
											}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_prevention_materiel, 'boolean'), style: 'icon'}, '\tMatériel conforme'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_prevention_conduite, 'boolean'), style: 'icon'}, '\tAutorisation de conduite adaptée'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_prevention_balisage, 'boolean'), style: 'icon'}, '\tBalisage de la zone'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_prevention_harnais, 'boolean'), style: 'icon'}, '\tPort du harnais sécurité/mise en place garde-corps'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_prevention_permis, 'boolean'), style: 'icon'}, '\tPermis d’accès en hauteur est-il nécessaire ?'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_chute_charge_objet, 'boolean'), style: 'icon'}, '\tRisques de chute de charges ou d\'objets'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: '', style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chute_charge_objet_prevention_casque, 'boolean'), style: 'icon'}, '\tPort du casque de sécurité'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chute_charge_objet_prevention_baliser, 'boolean'), style: 'icon'}, '\tBaliser et protéger la zone d\'intervention'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_mecaniques, 'boolean'), style: 'icon'}, '\tRisques mécaniques, coupure, projections, manutention'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_details_manipulation, 'boolean'), style: 'icon'}, '\tManipulation (câble, touret, armoire, chemin câble…)'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_details_mecanisme, 'boolean'), style: 'icon'}, '\tMécanisme en mouvement'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_details_electro, 'boolean'), style: 'icon'}, '\tUtilisation d’électroportatifs'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_prevention_gants, 'boolean'), style: 'icon'}, '\tPort des gants adaptés, protection auditives et lunette de sécurité'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_prevention_etat, 'boolean'), style: 'icon'}, '\tMatériel en bon état'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_prevention_balisage, 'boolean'), style: 'icon'}, '\tBalisage de la zone'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_prevention_permis, 'boolean'), style: 'icon'}, '\tPermis de feu est-il nécessaire ?'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_prevention_mecaniques, 'boolean'), style: 'icon'}, '\tUtiliser des moyens mécaniques'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_chimiques, 'boolean'), style: 'icon'}, '\tRisques chimiques'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_chimiques_details_manipulation, 'boolean'), style: 'icon'}, '\tManipulation/utilisation de produits chimiques'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chimiques_details_voisinage, 'boolean'), style: 'icon'}, '\tVoisinage des produits chimiques'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chimiques_prevention_epi, 'boolean'), style: 'icon'}, '\tPort d\'EPI obligatoire lunette de sécurité + gants'], style: 'labelPadded'}],
											[{
												text: [{text: this.getElementValue(formData.risques_chimiques_prevention_produit, 'boolean'), style: 'icon'}, '\tS’informer sur la dangerosité du produit et les EPI à utiliser (gants, masque respiratoire, combinaison)'],
												style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_chimiques_prevention_epi_client, 'boolean'), style: 'icon'}, '\tEPI spécifiques client'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_incendie, 'boolean'), style: 'icon'}, '\tRisques d\'incendie et/ou d\'explosion'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_incendie_details_soudure, 'boolean'), style: 'icon'}, '\tSoudure, meulage, travaux par point chaud'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_incendie_details_produits, 'boolean'), style: 'icon'}, '\tPrésence de produit ou matière inflammable'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_incendie_prevention_consignes, 'boolean'), style: 'icon'}, '\tConsignes internes de sécurité'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_incendie_prevention_fumer, 'boolean'), style: 'icon'}, '\tInterdiction de fumer'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_incendie_prevention_atex, 'boolean'), style: 'icon'}, '\tUtilisation matériel ATEX'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_incendie_prevention_permis, 'boolean'), style: 'icon'}, '\tPermis de feu'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_incendie_prevention_extincteurs, 'boolean'), style: 'icon'}, '\tPrésence d’extincteur'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_nuisances, 'boolean'), style: 'icon'}, '\tRisques de nuisances'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_nuisances_details_poussieres, 'boolean'), style: 'icon'}, '\tEmission de poussière'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_nuisances_details_bruit, 'boolean'), style: 'icon'}, '\tEmission de bruit'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_nuisances_prevention_auditives, 'boolean'), style: 'icon'}, '\tPort des protections auditives'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_nuisances_prevention_masque, 'boolean'), style: 'icon'}, '\tPort du masque poussière FFP3'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_nuisances_prevention_lunettes, 'boolean'), style: 'icon'}, '\tPort des lunettes de sécurité'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_nuisances_prevention_zone, 'boolean'), style: 'icon'}, '\tProtection de la zone de travail'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_lies_coactivite, 'boolean'), style: 'icon'}, '\tRisques liés à la coactivité'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_lies_coactivite_details_entreprises, 'boolean'), style: 'icon'}, '\tPrésence d\'autres entreprises'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: ' Nature des travaux : ' + this.getElementValue(formData.risques_lies_coactivite_prevention_nature, 'string'), style: 'labelPadded'}],
											[{text: ' Précautions : ' + this.getElementValue(formData.risques_lies_coactivite_prevention_precautions, 'string'), style: 'labelPadded'}],
											[{text: ' Communication : ' + this.getElementValue(formData.risques_lies_coactivite_prevention_communication, 'string'), style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_zone_alimentaire, 'boolean'), style: 'icon'}, '\tRisques zone alimentaire/salle blanche'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: '', style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_zone_alimentaire_prevention_regles, 'boolean'), style: 'icon'}, '\tInformer sur les règles d’hygiène du client'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_zone_alimentaire_prevention_epi, 'boolean'), style: 'icon'}, '\tPort des EPI adapté (charlotte, blouse, sur-chaussure…)'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				}
			);

			if (formData.autres_risques) {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: [{text: this.getElementValue(formData.autres_risques, 'boolean'), style: 'icon'}, '\tAutres risques : (amiante, plomb, confiné, isolé…)'], style: 'labelHeaderPadded'}],
								[
									{
										table: {
											dontBreakRows: true,
											widths: ['100%'],
											body: [
												[{text: 'Type de risque', style: 'labelBoldPadded'}],
												[{text: this.getElementValue(formData.autres_risques_type, 'string'), style: 'labelPadded'}]
											]
										},
										layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
									}
								],
								[
									{
										table: {
											dontBreakRows: true,
											widths: ['100%'],
											body: [
												[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
												[{text: this.getElementValue(formData.autres_risques_prevention, 'string'), style: 'labelPadded'}]
											]
										},
										layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
									}
								]
							]
						},
						layout: this.getTableLayoutBorderNoPadding(),
						style: 'tableBottomMargin',
						unbreakable: true
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: [{text: this.getElementValue(formData.autres_risques, 'boolean'), style: 'icon'}, '\tAutres risques : (amiante, plomb, confiné, isolé…)'], style: 'labelHeaderPadded'}]
							]
						},
						layout: this.getTableLayoutBorderNoPadding(),
						style: 'tableBottomMargin',
						unbreakable: true
					}
				);
			}

			// Step 5

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					stack: [
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Moyens d’urgence présents sur le chantier', style: 'title'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						},
						{
							table: {
								dontBreakRows: true,
								widths: ['48%', '4%', '48%'],
								body: [
									[
										{text: [{text: this.getElementValue(formData.moyens_urgence_sortie, 'boolean'), style: 'icon'}, '\tSortie de secours'], style: 'labelHeaderPadded'},
										{text: '', style: 'label'},
										{text: [{text: this.getElementValue(formData.moyens_urgence_pharmacie, 'boolean'), style: 'icon'}, '\tPharmacie'], style: 'labelHeaderPadded'}
									],
									[
										{text: [{text: this.getElementValue(formData.moyens_urgence_telephone, 'boolean'), style: 'icon'}, '\tTéléphone d’urgence'], style: 'labelHeaderPadded'},
										{text: '', style: 'label'},
										{text: [{text: this.getElementValue(formData.moyens_urgence_rassemblement, 'boolean'), style: 'icon'}, '\tPoint de rassemblement'], style: 'labelHeaderPadded'}
									],
									[
										{text: [{text: this.getElementValue(formData.moyens_urgence_rincage, 'boolean'), style: 'icon'}, '\tEquipement de rinçage des yeux'], style: 'labelHeaderPadded'},
										{text: '', style: 'label'},
										{text: [{text: this.getElementValue(formData.moyens_urgence_douche, 'boolean'), style: 'icon'}, '\tDouche de sécurité'], style: 'labelHeaderPadded'}
									],
									[
										{text: [{text: this.getElementValue(formData.moyens_urgence_extincteur, 'boolean'), style: 'icon'}, '\tExtincteur d’incendie'], style: 'labelHeaderPadded'},
										{text: '', style: 'label'},
										{text: '', style: 'label'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					],
					unbreakable: true
				}
			);

			// Step 6

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Signatures', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			if (formData.signatories && formData.signatories.length > 0) {

				const bodyData = [];

				bodyData.push(
					[
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Nom, prénom', style: 'label'}],
									[{text: this.getElementValue(formData.createdBy, 'UserObject'), style: 'content'}],
									[{text: '', style: 'label'}],
									[{text: [{text: this.getElementValue(formData.date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.date_signature, 'DateSignature')], style: 'content'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						},
						{text: '', style: 'label'},
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Nom, prénom', style: 'label'}],
									[{text: this.getElementValue(formData.signatories[0], 'UserObject'), style: 'content'}],
									[{text: '', style: 'label'}],
									[{text: [{text: this.getElementValue(formData.signatories_dates[0], 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.signatories_dates[0], 'DateSignature')], style: 'content'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					]
				);

				for (let i = 1; i < formData.signatories.length; i = i + 2) {
					const bodyRow = [];
					if (i + 1 < formData.signatories.length) {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Nom, prénom', style: 'label'}],
										[{text: this.getElementValue(formData.signatories[i], 'UserObject'), style: 'content'}],
										[{text: '', style: 'label'}],
										[{text: [{text: this.getElementValue(formData.signatories_dates[i], 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.signatories_dates[i], 'DateSignature')], style: 'content'}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							},
							{text: '', style: 'label'},
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Nom, prénom', style: 'label'}],
										[{text: this.getElementValue(formData.signatories[i + 1], 'UserObject'), style: 'content'}],
										[{text: '', style: 'label'}],
										[{text: [{text: this.getElementValue(formData.signatories_dates[i + 1], 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.signatories_dates[i + 1], 'DateSignature')], style: 'content'}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							}
						);
					} else {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Nom, prénom', style: 'label'}],
										[{text: this.getElementValue(formData.signatories[i], 'UserObject'), style: 'content'}],
										[{text: '', style: 'label'}],
										[{text: [{text: this.getElementValue(formData.signatories_dates[i], 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.signatories_dates[i], 'DateSignature')], style: 'content'}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							},
							{text: '', style: 'label'},
							{text: '', style: 'label'}
						);
					}

					bodyData.push(bodyRow);

				}
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: bodyData
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%'],
							body: [
								[{text: 'Nom, prénom', style: 'label'}],
								[{text: this.getElementValue(formData.createdBy, 'UserObject'), style: 'content'}],
								[{text: '', style: 'label'}],
								[{text: [{text: this.getElementValue(formData.date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.date_signature, 'DateSignature')], style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);
			}
			const signatures_personnes_supplementaires = [];
			for (const i in formData.personnes_supplementaires) {
				let signature_personne_supplementaire;
				if (formData.personnes_supplementaires[i].signature && formData.personnes_supplementaires[i].signature.type === 'remote' && formData.personnes_supplementaires[i].signature.data && formData.personnes_supplementaires[i].signature.data.url) {
					signature_personne_supplementaire = await this.imagesService.getBlobFromImageUrl(formData.personnes_supplementaires[i].signature.data.url, null);
					signatures_personnes_supplementaires.push(signature_personne_supplementaire);
				} else if (formData.personnes_supplementaires[i].signature && formData.personnes_supplementaires[i].signature.type === 'local') {
					signature_personne_supplementaire = await this.imagesService.getBlobFromImageUrl(formData.personnes_supplementaires[i].signature.preview, 'local');
					signatures_personnes_supplementaires.push(signature_personne_supplementaire);
				} else {
					signatures_personnes_supplementaires.push(null);
				}
			}

			if (formData.personnes_supplementaires && formData.personnes_supplementaires.length > 0) {
				const bodyData = [];
				for (let i = 0; i < formData.personnes_supplementaires.length; i = i + 2) {
					const bodyRow = [];
					if (i + 1 < formData.personnes_supplementaires.length) {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Nom (Société, Type)', style: 'label'}],
										[{
											text: this.getElementValue((formData.personnes_supplementaires[i].nom ? formData.personnes_supplementaires[i].nom : '') + ' (' + (formData.personnes_supplementaires[i].societe ? formData.personnes_supplementaires[i].societe : '') + ', ' + (formData.personnes_supplementaires[i].type ? formData.personnes_supplementaires[i].type : '') + ')', 'string'),
											style: 'content'
										}],
										[{text: '', style: 'label'}],
										[{
											text: [{text: this.getElementValue(formData.personnes_supplementaires[i].date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.personnes_supplementaires[i].date_signature, 'DateSignature')],
											style: 'content'
										}],
										[{text: '', style: 'label'}],
										[signatures_personnes_supplementaires[i] ?
											{
												image: signatures_personnes_supplementaires[i],
												fit: [150, 112.5],
												alignment: 'center',
												border: [true, true, true, true]
											} :
											{}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							},
							{text: '', style: 'label'},
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Nom (Société, Type)', style: 'label'}],
										[{
											text: this.getElementValue((formData.personnes_supplementaires[i + 1].nom ? formData.personnes_supplementaires[i + 1].nom : '') + ' (' + (formData.personnes_supplementaires[i + 1].societe ? formData.personnes_supplementaires[i + 1].societe : '') + ', ' + (formData.personnes_supplementaires[i + 1].type ? formData.personnes_supplementaires[i + 1].type : '') + ')', 'string'),
											style: 'content'
										}],
										[{text: '', style: 'label'}],
										[{
											text: [{text: this.getElementValue(formData.personnes_supplementaires[i + 1].date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.personnes_supplementaires[i + 1].date_signature, 'DateSignature')],
											style: 'content'
										}],
										[{text: '', style: 'label'}],
										[signatures_personnes_supplementaires[i + 1] ?
											{
												image: signatures_personnes_supplementaires[i + 1],
												fit: [150, 112.5],
												alignment: 'center',
												border: [true, true, true, true],
											} :
											{}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							}
						);
					} else {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Nom (Société, Type)', style: 'label'}],
										[{
											text: this.getElementValue((formData.personnes_supplementaires[i].nom ? formData.personnes_supplementaires[i].nom : '') + ' (' + (formData.personnes_supplementaires[i].societe ? formData.personnes_supplementaires[i].societe : '') + ', ' + (formData.personnes_supplementaires[i].type ? formData.personnes_supplementaires[i].type : '') + ')', 'string'),
											style: 'content'
										}],
										[{text: '', style: 'label'}],
										[{
											text: [{text: this.getElementValue(formData.personnes_supplementaires[i].date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.personnes_supplementaires[i].date_signature, 'DateSignature')],
											style: 'content'
										}],
										[{text: '', style: 'label'}],
										[signatures_personnes_supplementaires[i] ?
											{
												image: signatures_personnes_supplementaires[i],
												fit: [150, 112.5],
												alignment: 'center',
												border: [true, true, true, true]
											} :
											{}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							},
							{text: '', style: 'label'},
							{text: '', style: 'label'}
						);
					}

					bodyData.push(bodyRow);

				}
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: bodyData
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			const id_affaire = this.getElementValue(formData.id_affaire, 'string');
			const nom_client = this.getElementValue(formData.nom_client, 'string') + ' (' + this.getElementValue(formData.code_client, 'string') + ')';
			const nom_affaire = this.getElementValue(formData.nom_affaire, 'string');
			const resp_chantier = this.getElementValue(formData.responsable, 'string');

			const docDefinition = {
				info: this.getInfos('accueil_securite', formData),
				permissions: this.getPermissions(),
				header: (currentPage) => {
					if (currentPage !== 1) {
						return {
							table: {
								widths: ['25%', '25%', '25%', '25%'],
								body: [
									[
										{text: 'N° d\'affaire : ' + id_affaire, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Client : ' + nom_client, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Titre : ' + nom_affaire, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Resp. chantier : ' + resp_chantier, style: 'labelHeader2', border: [false, false, false, true]}
									]
								]
							}
						};
					} else {
						return {};
					}
				},
				footer: (currentPage, pageCount) => {
					return {
						table: {
							widths: ['100%'],
							body: [
								[
									{text: 'Page ' + currentPage + ' sur ' + pageCount, alignment: 'center'}
								]
							]
						},
						margin: [0, 10, 0, 0],
						layout: {defaultBorder: false}
					};
				},
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};

			this.pdfObj = pdfMake.createPdf(docDefinition);
			return this.pdfObj;
		} catch (e) {
			throw 'FORM_PDF';
		}
	}

	async generatePlanPreventionPdf(formData: PlanPreventionObject) {
		try {
			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];
			content.push(this.getHeader('Plan de prévention', logo, formData.updatedAt));

			// Step 1-2

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[
								{text: 'STEMI adhère à une démarche "Santé Sécurité Environnement". Le non-respect des règles lors de vos interventions entraînera une information au niveau des données d\'ordres, notamment vos supérieurs hiérarchiques.', style: 'labelRed'},
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				}
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Chantier', style: 'titleBordered'}]
						]
					},
					unbreakable: true,
					style: 'tableTopBottomMarginLarge'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '78%'],
						body: [
							[{text: 'N° d’affaire', style: 'label'},
								{text: ''}
							],
							[{text: this.getElementValue(formData.id_affaire, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Entreprise Utilisatrice - EU', style: 'titleBordered'}]
						]
					},
					unbreakable: true,
					style: 'tableTopBottomMarginLarge'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Raison sociale', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Responsable de travaux', style: 'label'}
							],
							[{text: this.getElementValue(formData.nom_client, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.interlocuteur, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '78%'],
						body: [
							[{text: 'Téléphone', style: 'label'},
								{text: ''}
							],
							[{text: this.getElementValue(formData.telephone, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Entreprise Extérieure - EE', style: 'titleBordered'}]
						]
					},
					unbreakable: true,
					style: 'tableTopBottomMarginLarge'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Raison sociale', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Responsable de travaux', style: 'label'}
							],
							[{text: this.getElementValue(formData.entreprise_exterieure, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.entreprise_exterieure_responsable, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '30%', '48%'],
						body: [
							[{text: 'Téléphone', style: 'label'},
								{text: ''},
								{text: 'Nb personnes intervenantes (maximum)', style: 'label'}
							],
							[{text: this.getElementValue(formData.entreprise_exterieure_telephone, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nb_stemi, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Nature et lieux des travaux', style: 'titleBordered'}]
						]
					},
					unbreakable: true,
					style: 'tableTopBottomMarginLarge'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Nature des travaux', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Lieu(x) d\'intervention', style: 'label'}
							],
							[{text: this.getElementValue(formData.nom_affaire, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.lieu, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '4%', '22%', '4%', '22%', '4%', '22%'],
						body: [
							[{text: 'Date des travaux', style: 'label'},
								{text: '', style: 'label'},
								{text: '', style: 'label'},
								{text: '', style: 'label'},
								{text: '', style: 'label'},
								{text: '', style: 'label'},
								{text: '', style: 'label'}
							],
							[{text: this.getElementValue(formData.date_debut_travaux, 'Date'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.date_fin_travaux, 'Date'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: '', style: 'contentBlank'},
								{text: '', style: 'contentBlank'},
								{text: '', style: 'contentBlank'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '4%', '22%', '4%', '22%', '4%', '22%'],
						body: [
							[{text: 'Horaires matin', style: 'label'},
								{text: '', style: 'label'},
								{text: '', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Horaires après-midi', style: 'label'},
								{text: '', style: 'label'},
								{text: '', style: 'label'}
							],
							[{text: this.getElementValue(formData.horaires_intervention ? formData.horaires_intervention.debut : null, 'Time'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.horaires_intervention ? formData.horaires_intervention.fin : null, 'Time'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.horaires_intervention ? formData.horaires_intervention.debut_am : null, 'Time'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.horaires_intervention ? formData.horaires_intervention.fin_am : null, 'Time'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Présence d\'amiante à proximité du chantier ?', style: 'label'},
								{text: '', style: 'label'},
								formData.presence_amiante === 'Oui' ? {text: 'Localisation de l\'amiante', style: 'label'} : {text: '', style: 'label'}
							],
							[{text: this.getElementValue(formData.presence_amiante, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								formData.presence_amiante === 'Oui' ? {text: this.getElementValue(formData.localisation_amiante, 'string'), style: 'content'} : {text: '', style: 'contentBlank'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 3

			content.push(
				{
					stack: [
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'EPI obligatoires sur le site', style: 'titleBordered'}]
								]
							},
							unbreakable: true,
							style: 'tableTopBottomMarginLarge'
						},
						{
							table: {
								dontBreakRows: true,
								widths: ['48%', '4%', '48%'],
								body: [
									[
										{text: [{text: this.getElementValue(formData.epi_chaussures_securite, 'boolean'), style: 'icon'}, '\tChaussures de sécurité'], style: 'labelHeaderPadded'},
										{text: '', style: 'label'},
										{text: [{text: this.getElementValue(formData.epi_lunettes_protection, 'boolean'), style: 'icon'}, '\tLunettes de protection'], style: 'labelHeaderPadded'}
									],
									[
										{text: [{text: this.getElementValue(formData.epi_protection_auditive, 'boolean'), style: 'icon'}, '\tProtection auditive'], style: 'labelHeaderPadded'},
										{text: '', style: 'label'},
										{text: [{text: this.getElementValue(formData.epi_casque_chantier, 'boolean'), style: 'icon'}, '\tCasque de chantier'], style: 'labelHeaderPadded'}
									],
									[
										{text: [{text: this.getElementValue(formData.epi_vetements_travail, 'boolean'), style: 'icon'}, '\tVêtements de travail manche longue'], style: 'labelHeaderPadded'},
										{text: '', style: 'label'},
										{text: [{text: this.getElementValue(formData.epi_gilet, 'boolean'), style: 'icon'}, '\tGilet haute visiblité'], style: 'labelHeaderPadded'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					],
					unbreakable: true
				}
			);

			// Step 4

			const incendie = await this.imagesService.getBlobFromImageUrl('./assets/imgs/plan-prevention/incendie.jpg', null);
			const accident = await this.imagesService.getBlobFromImageUrl('./assets/imgs/plan-prevention/accident.png', null);
			const deversement = await this.imagesService.getBlobFromImageUrl('./assets/imgs/plan-prevention/deversement.png', null);
			const evacuation = await this.imagesService.getBlobFromImageUrl('./assets/imgs/plan-prevention/evacuation.jpg', null);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Consignes d\'urgence', style: 'titleBordered'}]
						]
					},
					unbreakable: true,
					style: 'tableTopBottomMarginLarge'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '4%', '22%', '4%', '22%', '4%', '22%'],
						body: [
							[
								{image: incendie, width: 60, alignment: 'center'},
								{text: '', style: 'label'},
								{image: accident, width: 60, alignment: 'center'},
								{text: '', style: 'label'},
								{image: deversement, width: 60, alignment: 'center'},
								{text: '', style: 'label'},
								{image: evacuation, width: 60, alignment: 'center'}
							],
							[
								{text: 'EN CAS D\'INCENDIE', style: 'labelBoldCentered', decoration: 'underline'},
								{text: '', style: 'label'},
								{text: 'EN CAS D\'ACCIDENT', style: 'labelBoldCentered', decoration: 'underline'},
								{text: '', style: 'label'},
								{text: 'EN CAS DE DÉVERSEMENT ACCIDENTEL', style: 'labelBoldCentered', decoration: 'underline'},
								{text: '', style: 'label'},
								{text: 'EN CAS D\'ÉVACUATION', style: 'labelBoldCentered', decoration: 'underline'}
							],
							[
								{text: 'Si vous entendez l\'alarme, évacuez.\nSi vous êtes à l\'origine de l\'incendie, lutter contre le feu à l\'aide d\'un extincteur adpaté et appeler le 18', style: 'labelCentered'},
								{text: '', style: 'label'},
								{text: 'Appelez un SST qui saura comment agir, ou appelez le 15 ou 112', style: 'labelCentered'},
								{text: '', style: 'label'},
								{text: 'Appelez le responsable des travaux de l\'EU qui vous transmettra les consignes à suivre.', style: 'labelCentered'},
								{text: '', style: 'label'},
								{text: 'Rejoignez le point de rassemblement sur le parking coté entrée principale. Attendez les consignes.', style: 'labelCentered'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			content.push(
				{
					stack: [
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Moyens d’urgence présents sur le site', style: 'titleBordered'}]
								]
							},
							unbreakable: true,
							style: 'tableTopBottomMarginLarge'
						},
						{
							table: {
								dontBreakRows: true,
								widths: ['48%', '4%', '48%'],
								body: [
									[
										{
											text: [{
												text: this.getElementValue(formData.moyens_urgence_sortie, 'boolean'),
												style: 'icon'
											}, '\tSortie de secours' + (formData.moyens_urgence_sortie_commentaire !== null && formData.moyens_urgence_sortie_commentaire !== '' ? '\n\n' + formData.moyens_urgence_sortie_commentaire : '')], style: 'labelHeaderPadded'
										},
										{text: '', style: 'label'},
										{
											text: [{
												text: this.getElementValue(formData.moyens_urgence_pharmacie, 'boolean'),
												style: 'icon'
											}, '\tPharmacie' + (formData.moyens_urgence_pharmacie_commentaire !== null && formData.moyens_urgence_pharmacie_commentaire !== '' ? '\n\n' + formData.moyens_urgence_pharmacie_commentaire : '')], style: 'labelHeaderPadded'
										}
									],
									[
										{
											text: [{
												text: this.getElementValue(formData.moyens_urgence_telephone, 'boolean'),
												style: 'icon'
											}, '\tTéléphone d’urgence' + (formData.moyens_urgence_telephone_commentaire !== null && formData.moyens_urgence_telephone_commentaire !== '' ? '\n\n' + formData.moyens_urgence_telephone_commentaire : '')], style: 'labelHeaderPadded'
										},
										{text: '', style: 'label'},
										{
											text: [{
												text: this.getElementValue(formData.moyens_urgence_rassemblement, 'boolean'),
												style: 'icon'
											}, '\tPoint de rassemblement' + (formData.moyens_urgence_rassemblement_commentaire !== null && formData.moyens_urgence_rassemblement_commentaire !== '' ? '\n\n' + formData.moyens_urgence_rassemblement_commentaire : '')],
											style: 'labelHeaderPadded'
										}
									],
									[
										{
											text: [{
												text: this.getElementValue(formData.moyens_urgence_rincage, 'boolean'),
												style: 'icon'
											}, '\tEquipement de rinçage des yeux' + (formData.moyens_urgence_rincage_commentaire !== null && formData.moyens_urgence_rincage_commentaire !== '' ? '\n\n' + formData.moyens_urgence_rincage_commentaire : '')],
											style: 'labelHeaderPadded'
										},
										{text: '', style: 'label'},
										{
											text: [{
												text: this.getElementValue(formData.moyens_urgence_douche, 'boolean'),
												style: 'icon'
											}, '\tDouche de sécurité' + (formData.moyens_urgence_douche_commentaire !== null && formData.moyens_urgence_douche_commentaire !== '' ? '\n\n' + formData.moyens_urgence_douche_commentaire : '')], style: 'labelHeaderPadded'
										}
									],
									[
										{
											text: [{
												text: this.getElementValue(formData.moyens_urgence_extincteur, 'boolean'),
												style: 'icon'
											}, '\tExtincteur d’incendie' + (formData.moyens_urgence_extincteur_commentaire !== null && formData.moyens_urgence_extincteur_commentaire !== '' ? '\n\n' + formData.moyens_urgence_extincteur_commentaire : '')],
											style: 'labelHeaderPadded'
										},
										{text: '', style: 'label'},
										{text: '', style: 'label'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					],
					unbreakable: true
				}
			);

			const mains = await this.imagesService.getBlobFromImageUrl('./assets/imgs/pictos-covid/picto_mains.jpg', null);
			const distance = await this.imagesService.getBlobFromImageUrl('./assets/imgs/pictos-covid/picto_2-metres.jpg', null);
			const mouchoir = await this.imagesService.getBlobFromImageUrl('./assets/imgs/pictos-covid/picto_mouchoir.jpg', null);
			const masque = await this.imagesService.getBlobFromImageUrl('./assets/imgs/pictos-covid/picto_masque-obligatoire.jpg', null);
			const coude = await this.imagesService.getBlobFromImageUrl('./assets/imgs/pictos-covid/picto_coude.jpg', null);
			const visage = await this.imagesService.getBlobFromImageUrl('./assets/imgs/pictos-covid/picto_visage.jpg', null);
			const aeration = await this.imagesService.getBlobFromImageUrl('./assets/imgs/pictos-covid/picto_aeration.jpg', null);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Consignes COVID-19', style: 'titleBordered'}]
						]
					},
					unbreakable: true,
					style: 'tableTopBottomMarginLarge'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['14%', '14%', '14%', '14%', '14%', '14%', '14%'],
						body: [
							[
								{image: mains, width: 50, alignment: 'center'},
								{image: distance, width: 50, alignment: 'center'},
								{image: mouchoir, width: 50, alignment: 'center'},
								{image: masque, width: 50, alignment: 'center'},
								{image: coude, width: 50, alignment: 'center'},
								{image: visage, width: 50, alignment: 'center'},
								{image: aeration, width: 50, alignment: 'center'},
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 5

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Prestataire', style: 'titleBordered'}]
						]
					},
					unbreakable: true,
					style: 'tableTopBottomMarginLarge'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: ['Présence\t', {text: this.getElementValue(formData.prestataire, 'boolean'), style: 'icon'}, ''], style: 'label'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			if (formData.prestataire) {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[{text: 'Société', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable des travaux', style: 'label'}
								],
								[{text: this.getElementValue(formData.prestataire_societe, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.prestataire_responsable, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Nature des travaux', style: 'label'}],
								[{text: this.getElementValue(formData.prestataire_nature_travaux, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[
									{text: 'Les risques liés aux travaux du prestataire doivent être ajoutés dans l’analyse des risques ci-dessous.', style: 'labelRed'},
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Risques présents sur le site', style: 'titleBordered'}]
						]
					},
					unbreakable: true,
					style: 'tableTopBottomMarginLarge'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[
								{text: 'Légende : EE = Entreprise Extérieure, EU = Entreprise Utilisatrice', style: 'labelRed'},
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_electriques, 'boolean'), style: 'icon'}, '\tRisques électriques'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_electriques_details_basse_tension, 'boolean'), style: 'icon'}, '\tBasse tension'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_electriques_details_haute_tension, 'boolean'), style: 'icon'}, '\tHaute tension'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_electriques_details_voisinage_tension, 'boolean'), style: 'icon'}, '\tVoisinage tension'], style: 'labelPadded'}],
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{
												text: [{
													text: this.getElementValue(formData.risques_electriques_prevention_habilitation, 'boolean'),
													style: 'icon'
												}, '\tHabilitation électrique adaptée'], style: 'labelPadded'
											}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_electriques_prevention_habilitation_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_electriques_prevention_habilitation_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_electriques_prevention_consignation, 'boolean'), style: 'icon'}, '\tConsignation'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_electriques_prevention_consignation_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_electriques_prevention_consignation_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_electriques_prevention_voisinage, 'boolean'), style: 'icon'}, '\tVoisinage de tension : casque IDRA + gants isolés'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_electriques_prevention_voisinage_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_electriques_prevention_voisinage_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							],
							[{colSpan: 2, text: ' Remarques : ' + this.getElementValue(formData.risques_electriques_remarques, 'string'), style: 'labelPadded'}, {}]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_lies_circulation, 'boolean'), style: 'icon'}, '\tRisques liés à la circulation'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_lies_circulation_details_vehicules, 'boolean'), style: 'icon'}, '\tVéhicule léger/poids lourds/engin'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_lies_circulation_details_pietonne, 'boolean'), style: 'icon'}, '\tCirculation piétonne'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_lies_circulation_moyens_prevention_regles, 'boolean'), style: 'icon'}, '\tS’Informer sur les règles de circulation/stationnement'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_lies_circulation_moyens_prevention_regles_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_lies_circulation_moyens_prevention_regles_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_lies_circulation_moyens_prevention_baliser, 'boolean'), style: 'icon'}, '\tBaliser la zone d’intervention'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_lies_circulation_moyens_prevention_baliser_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_lies_circulation_moyens_prevention_baliser_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_lies_circulation_moyens_prevention_epi, 'boolean'), style: 'icon'}, '\tPort des chaussures de sécurité et du gilet haute visibilité'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_lies_circulation_moyens_prevention_epi_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_lies_circulation_moyens_prevention_epi_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							],
							[{colSpan: 2, text: ' Remarques : ' + this.getElementValue(formData.risques_lies_circulation_remarques, 'string'), style: 'labelPadded'}, {}]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_chute_hauteur, 'boolean'), style: 'icon'}, '\tRisques de chute de hauteur'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_details_nacelle, 'boolean'), style: 'icon'}, '\tNacelle'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_details_echafaudage, 'boolean'), style: 'icon'}, '\tEchafaudage'], style: 'labelPadded'}],
											[{
												text: [{text: this.getElementValue(formData.risques_chute_hauteur_details_echelle, 'boolean'), style: 'icon'}, '\tEchelle si impossibilité d’utiliser un autre moyen ou travaux courte durée'],
												style: 'labelPadded'
											}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_prevention_materiel, 'boolean'), style: 'icon'}, '\tMatériel conforme'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_chute_hauteur_prevention_materiel_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_chute_hauteur_prevention_materiel_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_prevention_conduite, 'boolean'), style: 'icon'}, '\tAutorisation de conduite adaptée'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_chute_hauteur_prevention_conduite_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_chute_hauteur_prevention_conduite_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_prevention_balisage, 'boolean'), style: 'icon'}, '\tBalisage de la zone'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_chute_hauteur_prevention_balisage_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_chute_hauteur_prevention_balisage_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_prevention_harnais, 'boolean'), style: 'icon'}, '\tPort du harnais sécurité/mise en place garde-corps'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_chute_hauteur_prevention_harnais_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_chute_hauteur_prevention_harnais_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_prevention_permis, 'boolean'), style: 'icon'}, '\tPermis d’accès en hauteur est-il nécessaire ?'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_chute_hauteur_prevention_permis_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_chute_hauteur_prevention_permis_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_chute_hauteur_prevention_pret_materiel, 'boolean'), style: 'icon'}, '\tPrêt de matériel conforme par l\'EU'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							],
							[{colSpan: 2, text: ' Remarques : ' + this.getElementValue(formData.risques_chute_hauteur_remarques, 'string'), style: 'labelPadded'}, {}]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_chute_charge_objet, 'boolean'), style: 'icon'}, '\tRisques de chute de charges ou d\'objets'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: '', style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chute_charge_objet_prevention_casque, 'boolean'), style: 'icon'}, '\tPort du casque de sécurité'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_chute_charge_objet_prevention_casque_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_chute_charge_objet_prevention_casque_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_chute_charge_objet_prevention_baliser, 'boolean'), style: 'icon'}, '\tBaliser et protéger la zone d\'intervention'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_chute_charge_objet_prevention_baliser_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_chute_charge_objet_prevention_baliser_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							],
							[{colSpan: 2, text: ' Remarques : ' + this.getElementValue(formData.risques_chute_charge_objet_remarques, 'string'), style: 'labelPadded'}, {}]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_mecaniques, 'boolean'), style: 'icon'}, '\tRisques mécaniques, coupure, projections'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_details_mecanisme, 'boolean'), style: 'icon'}, '\tMécanisme en mouvement'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_details_electro, 'boolean'), style: 'icon'}, '\tUtilisation d’électroportatifs'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_prevention_gants, 'boolean'), style: 'icon'}, '\tPort des gants adaptés, protection auditives et lunette de sécurité'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_mecaniques_prevention_gants_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_mecaniques_prevention_gants_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_prevention_etat, 'boolean'), style: 'icon'}, '\tMatériel en bon état'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_mecaniques_prevention_etat_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_mecaniques_prevention_etat_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_prevention_balisage, 'boolean'), style: 'icon'}, '\tBalisage de la zone'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_mecaniques_prevention_balisage_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_mecaniques_prevention_balisage_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_mecaniques_prevention_permis, 'boolean'), style: 'icon'}, '\tPermis de feu'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_mecaniques_prevention_permis_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_mecaniques_prevention_permis_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							],
							[{colSpan: 2, text: ' Remarques : ' + this.getElementValue(formData.risques_mecaniques_remarques, 'string'), style: 'labelPadded'}, {}]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_chimiques, 'boolean'), style: 'icon'}, '\tRisques chimiques'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_chimiques_details_manipulation, 'boolean'), style: 'icon'}, '\tManipulation/utilisation de produits chimiques'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chimiques_details_voisinage, 'boolean'), style: 'icon'}, '\tVoisinage des produits chimiques'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_chimiques_prevention_epi, 'boolean'), style: 'icon'}, '\tPort d\'EPI obligatoire lunette de sécurité + gants'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_chimiques_prevention_epi_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_chimiques_prevention_epi_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{
												text: [{text: this.getElementValue(formData.risques_chimiques_prevention_produit, 'boolean'), style: 'icon'}, '\tS’informer sur la dangerosité du produit et les EPI à utiliser (gants, masque respiratoire, combinaison)'],
												style: 'labelPadded'
											}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_chimiques_prevention_produit_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_chimiques_prevention_produit_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							],
							[{colSpan: 2, text: ' Remarques : ' + this.getElementValue(formData.risques_chimiques_remarques, 'string'), style: 'labelPadded'}, {}]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_incendie, 'boolean'), style: 'icon'}, '\tRisques d\'incendie et/ou d\'explosion'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_incendie_details_soudure, 'boolean'), style: 'icon'}, '\tSoudure, meulage, travaux par point chaud'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_incendie_details_produits, 'boolean'), style: 'icon'}, '\tPrésence de produit ou matière inflammable'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_incendie_prevention_consignes, 'boolean'), style: 'icon'}, '\tConsignes internes de sécurité'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_incendie_prevention_consignes_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_incendie_prevention_consignes_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_incendie_prevention_extincteurs, 'boolean'), style: 'icon'}, '\tPrésence d’extincteur'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_incendie_prevention_extincteurs_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_incendie_prevention_extincteurs_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_incendie_prevention_permis, 'boolean'), style: 'icon'}, '\tPermis de feu nécessaire ?'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_incendie_prevention_permis_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_incendie_prevention_permis_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_incendie_prevention_atex, 'boolean'), style: 'icon'}, '\tUtilisation matériel ATEX'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_incendie_prevention_atex_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_incendie_prevention_atex_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_incendie_prevention_fumer, 'boolean'), style: 'icon'}, '\tInterdiction de fumer'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_incendie_prevention_fumer_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_incendie_prevention_fumer_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							],
							[{colSpan: 2, text: ' Remarques : ' + this.getElementValue(formData.risques_incendie_remarques, 'string'), style: 'labelPadded'}, {}]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_nuisances, 'boolean'), style: 'icon'}, '\tRisques de nuisances'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_nuisances_details_poussieres, 'boolean'), style: 'icon'}, '\tEmission de poussière'], style: 'labelPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_nuisances_details_bruit, 'boolean'), style: 'icon'}, '\tEmission de bruit'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: [{text: this.getElementValue(formData.risques_nuisances_prevention_auditives, 'boolean'), style: 'icon'}, '\tPort des protections auditives'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_nuisances_prevention_auditives_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_nuisances_prevention_auditives_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_nuisances_prevention_masque, 'boolean'), style: 'icon'}, '\tPort du masque poussière FFP3'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_nuisances_prevention_masque_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_nuisances_prevention_masque_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_nuisances_prevention_lunettes, 'boolean'), style: 'icon'}, '\tPort des lunettes de sécurité'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_nuisances_prevention_lunettes_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_nuisances_prevention_lunettes_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							],
							[{colSpan: 2, text: ' Remarques : ' + this.getElementValue(formData.risques_nuisances_remarques, 'string'), style: 'labelPadded'}, {}]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_lies_coactivite, 'boolean'), style: 'icon'}, '\tRisques liés à la coactivité'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_lies_coactivite_details_entreprises, 'boolean'), style: 'icon'}, '\tPrésence d\'autres entreprises'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: ' Nature des travaux : ' + this.getElementValue(formData.risques_lies_coactivite_prevention_nature, 'string'), style: 'labelPadded'}],
											[{text: ' Précautions/Communication : ' + this.getElementValue(formData.risques_lies_coactivite_prevention_precautions, 'string'), style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_lies_coactivite_prevention_precautions_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_lies_coactivite_prevention_precautions_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							],
							[{colSpan: 2, text: ' Remarques : ' + this.getElementValue(formData.risques_lies_coactivite_remarques, 'string'), style: 'labelPadded'}, {}]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['50%', '50%'],
						body: [
							[{colSpan: 2, text: [{text: this.getElementValue(formData.risques_bacteriologiques, 'boolean'), style: 'icon'}, '\tRisques bactériologiques'], style: 'labelHeaderPadded'}, {}],
							[
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: [{text: this.getElementValue(formData.risques_bacteriologiques_details_zone_blanche, 'boolean'), style: 'icon'}, '\tZone blanche/alimentaire'], style: 'labelPadded'}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								},
								{
									table: {
										dontBreakRows: true,
										widths: ['100%'],
										body: [
											[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
											[{text: ' S\'informer sur les règles d’hygiène du client : ' + this.getElementValue(formData.risques_bacteriologiques_prevention_regles_hygiene, 'string'), style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_bacteriologiques_prevention_regles_hygiene_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_bacteriologiques_prevention_regles_hygiene_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}],
											[{text: [{text: this.getElementValue(formData.risques_bacteriologiques_prevention_port_epi, 'boolean'), style: 'icon'}, '\tPort des EPI adapté'], style: 'labelPadded'}],
											[{
												text: [
													'\t\t', '\t\t', '\t\t',
													{text: this.getElementValue(formData.risques_bacteriologiques_prevention_port_epi_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
													{text: this.getElementValue(formData.risques_bacteriologiques_prevention_port_epi_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
												], style: 'labelPadded'
											}]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
							],
							[{colSpan: 2, text: ' Remarques : ' + this.getElementValue(formData.risques_bacteriologiques_remarques, 'string'), style: 'labelPadded'}, {}]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				}
			);

			if (formData.autres_risques) {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: [{text: this.getElementValue(formData.autres_risques, 'boolean'), style: 'icon'}, '\tAutres risques :'], style: 'labelHeaderPadded'}],
								[
									{
										table: {
											dontBreakRows: true,
											widths: ['100%'],
											body: [
												[{text: 'Type de risque', style: 'labelBoldPadded'}],
												[{text: this.getElementValue(formData.autres_risques_type, 'string'), style: 'labelPadded'}]
											]
										},
										layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
									}
								],
								[
									{
										table: {
											dontBreakRows: true,
											widths: ['100%'],
											body: [
												[{text: 'Moyens de prévention', style: 'labelBoldPadded'}],
												[{text: this.getElementValue(formData.autres_risques_prevention, 'string'), style: 'labelPadded'}],
												[{
													text: [
														'\t\t', '\t\t', '\t\t',
														{text: this.getElementValue(formData.autres_risques_prevention_par_ee, 'boolean'), style: 'icon'}, '\tPar EE\t',
														{text: this.getElementValue(formData.autres_risques_prevention_par_eu, 'boolean'), style: 'icon'}, '\tPar EU'
													], style: 'labelPadded'
												}]
											]
										},
										layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
									}
								],
								[{text: ' Remarques : ' + this.getElementValue(formData.autres_risques_remarques, 'string'), style: 'labelPadded'}]
							]
						},
						layout: this.getTableLayoutBorderNoPadding(),
						style: 'tableBottomMargin',
						unbreakable: true
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: [{text: this.getElementValue(formData.autres_risques, 'boolean'), style: 'icon'}, '\tAutres risques :'], style: 'labelHeaderPadded'}]
							]
						},
						layout: this.getTableLayoutBorderNoPadding(),
						style: 'tableBottomMargin',
						unbreakable: true
					}
				);
			}

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Documents remis par EU', style: 'titleBordered'}]
						]
					},
					unbreakable: true,
					style: 'tableTopBottomMarginLarge'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: [{text: this.getElementValue(formData.document_eu_plan_site, 'boolean'), style: 'icon'}, '\tPlan du site'], style: 'labelHeaderPadded'}],
							[{
								text: [{text: this.getElementValue(formData.document_eu_autres, 'boolean'), style: 'icon'}, '\tAutres :' + (formData.document_eu_autres ? '\n\n' + this.getElementValue(formData.document_eu_autres_description, 'string') : '')],
								style: 'labelHeaderPadded'
							}]
						]
					},
					layout: this.getTableLayoutBorderNoPadding(),
					style: 'tableBottomMargin',
					unbreakable: true
				}
			);

			// Step 6

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Signatures', style: 'titleBordered'}]
						]
					},
					unbreakable: true,
					style: 'tableTopBottomMarginLarge'
				},
			);

			const tableBody = [];
			tableBody.push(
				[{text: 'Client', style: 'label'}, {text: '', style: 'label'}, {text: 'Rédacteur', style: 'label'}],
				[{text: this.getElementValue(formData.client_nom_signature, 'string'), style: 'content'}, {text: '', style: 'contentBlank'}, {text: this.getElementValue(formData.createdBy, 'UserObject'), style: 'content'}],
				[{text: '', style: 'label'}, {text: '', style: 'label'}, {text: '', style: 'label'}],
				[{text: [{text: this.getElementValue(formData.client_date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.client_date_signature, 'DateSignature')], style: 'content'}, {
					text: '',
					style: 'contentBlank'
				}, {text: [{text: this.getElementValue(formData.date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.date_signature, 'DateSignature')], style: 'content'}]
			);

			let clientSignature = null;
			if (formData.client_signature) {
				if (formData.client_signature.type === 'remote' && formData.client_signature.data && formData.client_signature.data.url) {
					clientSignature = await this.imagesService.getBlobFromImageUrl(formData.client_signature.data.url, null);
				} else if (formData.client_signature.type === 'local') {
					clientSignature = await this.imagesService.getBlobFromImageUrl(formData.client_signature.preview, 'local');
				}
				tableBody.push([{text: '', style: 'label'}, {text: '', style: 'label'}, {text: '', style: 'label'}]);
				tableBody.push(
					[
						{
							image: clientSignature,
							fit: [150, 112.5],
							alignment: 'center',
							border: [true, true, true, true]
						},
						{text: '', style: 'contentBlank'},
						{text: '', style: 'contentBlank'}
					]
				);
			}

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: tableBody
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			const id_affaire = this.getElementValue(formData.id_affaire, 'string');
			const nom_client = this.getElementValue(formData.nom_client, 'string');
			const nom_affaire = this.getElementValue(formData.nom_affaire, 'string');
			const resp_chantier = this.getElementValue(formData.entreprise_exterieure_responsable, 'string');

			const docDefinition = {
				info: this.getInfos('plan_prevention', formData),
				permissions: this.getPermissions(),
				header: (currentPage) => {
					if (currentPage !== 1) {
						return {
							table: {
								widths: ['25%', '25%', '25%', '25%'],
								body: [
									[
										{text: 'N° d\'affaire : ' + id_affaire, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Client : ' + nom_client, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Titre : ' + nom_affaire, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Resp. chantier : ' + resp_chantier, style: 'labelHeader2', border: [false, false, false, true]}
									]
								]
							}
						};
					} else {
						return {};
					}
				},
				footer: (currentPage, pageCount) => {
					return {
						table: {
							widths: ['100%'],
							body: [
								[
									{text: 'Page ' + currentPage + ' sur ' + pageCount, alignment: 'center'}
								]
							]
						},
						margin: [0, 10, 0, 0],
						layout: {defaultBorder: false}
					};
				},
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};

			this.pdfObj = pdfMake.createPdf(docDefinition);
			return this.pdfObj;
		} catch (e) {
			throw 'FORM_PDF';
		}
	}

	async generateAnalyseRisquesPdf(formData: AnalyseRisquesObject) {
		try {
			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];
			content.push(this.getHeader('Analyse des risques', logo, formData.updatedAt));

			// Step 1-2

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '30%', '48%'],
						body: [
							[{text: 'N° d’affaire', style: 'label'},
								{text: ''},
								{text: 'Client', style: 'label'}
							],
							[{text: this.getElementValue(formData.id_affaire, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nom_client, 'string') + ' (' + this.getElementValue(formData.code_client, 'string') + ')', style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Interlocuteur', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Titre', style: 'label'}
							],
							[{text: this.getElementValue(formData.contact, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nom_affaire, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Chargé d’affaire', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Chef de chantier', style: 'label'}
							],
							[{text: this.getElementValue(formData.charge_affaire, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.chef_chantier, 'UserObject'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '78%'],
						body: [
							[{text: 'N° de devis', style: 'label'},
								{text: '', style: 'label'}
							],
							[{text: this.getElementValue(formData.no_devis, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 3

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Risques présents sur le chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['33.3%', '33.3%', '33.3%'],
						headerRows: 1,
						body: [
							[{text: 'Type de risque', style: 'labelHeader', border: [true, true, false, true]},
								{text: 'Conséquences', style: 'labelHeader', border: [false, true, false, true]},
								{text: 'Prévention', style: 'labelHeader', border: [false, true, true, true]},
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_electriques, 'boolean'), style: 'icon'}, '\tRisques électrique'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'électrisation, électrocution', style: 'label', border: [false, false, false, false]},
								{
									text: 'EPI, tenue de travail, balisage de la zone de travail, utilisation de cadenas de consignation, travail au voisinage ou hors tension, habilitation électrique C18 510, consigne de travail N°3',
									style: 'label',
									border: [false, false, true, false]
								}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_coupure, 'boolean'), style: 'icon'}, '\tRisques de coupure'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'coupure plus ou moins profonde, sectionnement de membre', style: 'label', border: [false, false, false, false]},
								{text: 'Consigne de travail N°4.5.6, utilisation d’outil approprié', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_mecanique, 'boolean'), style: 'icon'}, '\tRisques lié à la manutention mécanique'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'traumatismes : contusions, entorses, plaies, fractures, etc. ; écrasement de membres', style: 'label', border: [false, false, false, false]},
								{text: 'Vérification du matériel de levage', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_manuelle, 'boolean'), style: 'icon'}, '\tRisques lié à la manutention manuelle'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'fatigue, douleurs et pathologies des muscles et tendons ; affections du bras, du dos, des épaules et des membres inférieurs', style: 'label', border: [false, false, false, false]},
								{text: 'EPI (chaussure sécurité, gants,…), outils d’aide au levage', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_ecrasement, 'boolean'), style: 'icon'}, '\tRisques d’écrasement'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'compression d\'une masse musculaire importante (cuisse, mollet, épaule, bras)', style: 'label', border: [false, false, false, false]},
								{text: 'EPI (chaussure de sécurité,…)', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_projection, 'boolean'), style: 'icon'}, '\tRisques de projection'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'irritations des yeux, irritations et sensibilisation de la peau', style: 'label', border: [false, false, false, false]},
								{text: 'EPI (lunette de protection, masque à poussières, gants, tenue de travail,…)', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_incendie, 'boolean'), style: 'icon'}, '\tRisques incendie, explosion'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'rupture des tympans, plaies, traumatismes, brûlures, décès,  effondrement de la structure des bâtiments', style: 'label', border: [false, false, false, false]},
								{text: 'Formation extincteur, vérification des extincteurs, permit feu', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_brulure, 'boolean'), style: 'icon'}, '\tRisque de brûlure'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'brûlure plus ou moins profonde', style: 'label', border: [false, false, false, false]},
								{text: 'Trousse de secours et ou infirmerie, SST, EPI (gants de protection, tenue de travail, chaussure sécurité,….)', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_bruit, 'boolean'), style: 'icon'}, '\tRisques lié aux bruits'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'fatigue auditive, pertes d’audition, surdité, stress, hypertension,  troubles du sommeil ou de la digestion', style: 'label', border: [false, false, false, false]},
								{text: 'EPI (casque de protection, bouchon oreille,…)', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_chute_plain_pied, 'boolean'), style: 'icon'}, '\tRisques de chute de plain-pied'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'Traumatismes, contusions,  entorses,  plaies,  fractures, etc.', style: 'label', border: [false, false, false, false]},
								{text: 'EPI (chaussure de sécurité, tenue de travail…), nettoyage du poste de travail, balisage de la zone de travail', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_chute_hauteur, 'boolean'), style: 'icon'}, '\tRisques de chute de hauteur'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'traumatismes : contusions,  entorses,  plaies,  fractures, etc. ; décès', style: 'label', border: [false, false, false, false]},
								{text: 'Consigne de travail N°8, EPI (casque, tenue travail, chaussure sécurité,…), harnais, habilitation CACES, balisage de la zone', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_coactivite, 'boolean'), style: 'icon'}, '\tRisques lié à la co-activité'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'blessures, traumatismes', style: 'label', border: [false, false, false, false]},
								{text: 'Plan de prévention, balisage des zones de travail, personnel identifiable', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_circulation, 'boolean'), style: 'icon'}, '\tRisques lié à la circulation'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'traumatismes : contusions,  entorses,  plaies,  fractures, etc. ; décès', style: 'label', border: [false, false, false, false]},
								{text: 'Plan de circulation entreprise client, validation du permis de conduire, véhicule identifiable, vérification périodique des véhicules', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_trajet, 'boolean'), style: 'icon'}, '\tRisques de trajet'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'écrasement, choc, traumatisme, décès', style: 'label', border: [false, false, false, false]},
								{text: 'Validation du permis de conduire, respect du code de la route, vérification périodique des véhicules. Consigne de travail N°2', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_engin, 'boolean'), style: 'icon'}, '\tRisques lié à la conduite d\'engin'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'écrasement, choc, traumatisme, décès', style: 'label', border: [false, false, false, false]},
								{text: 'Habilitation CACES, balisage de la zone de travail, travail a deux', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_chimiques, 'boolean'), style: 'icon'}, '\tRisques lié aux agents chimiques dangereux'], style: 'labelBold', border: [true, false, false, false]},
								{
									text: 'irritations des yeux ou des voies respiratoires, irritations et sensibilisation de la peau, intoxication aigue et chronique : fatigue, nausées, maux de tête, perte de connaissance, affections du système nerveux…',
									style: 'label',
									border: [false, false, false, false]
								},
								{text: '"EPI (masque, gants,…), Consigne de travail N°4.5.6, Fiche de données de sécurité des produits, plan de prévention', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_thermique, 'boolean'), style: 'icon'}, '\tRisques lié à l’ambiance thermique'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'déshydrations, crampes de chaleur, épuisement, sensation de vertiges, coup de chaleur, hypothermie, engourdissement ou blanchissement du visage et des extrémités', style: 'label', border: [false, false, false, false]},
								{text: 'Présence de fontaine a eau, rotation du personnel a la mission, duré max de travail, tenue adapté aux conditions de travail', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_chutes_objets, 'boolean'), style: 'icon'}, '\tRisques liés aux effondrements et aux chutes d’objets'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'blessures, traumatismes', style: 'label', border: [false, false, false, false]},
								{text: 'EPI (casque, chaussure sécurité,…)', style: 'label', border: [false, false, true, false]}
							],
							[
								{text: [{text: this.getElementValue(formData.risques_presents_agroalimentaire, 'boolean'), style: 'icon'}, '\tRisques liés au milieu agroalimentaire'], style: 'labelBold', border: [true, false, false, false]},
								{text: 'transmission des infections, perte d\'éléments étrangers dans la production.', style: 'label', border: [false, false, false, false]},
								{
									text: 'EPI spécifique (charlotte, blouse, sur-chausse, cache barbe…), aucun bijoux, nettoyage/désinfection des mains et du matériel, réalisation de perçage/etc hors production ou mise en place de protection , nettoyage spécifique poste de travail',
									style: 'label',
									border: [false, false, true, false]
								}
							],
							[
								{text: [{text: this.getElementValue(formData.autres_risques, 'boolean'), style: 'icon'}, '\tAutre : '], style: 'labelBold', border: [true, false, false, true]},
								{text: this.getElementValue(formData.autres_risques_consequences, 'string'), style: 'label', border: [false, false, false, true]},
								{text: this.getElementValue(formData.autres_risques_prevention, 'string'), style: 'label', border: [false, false, true, true]}
							]
						]
					},
					layout: {
						fillColor: (rowIndex) => {
							return (rowIndex % 2 === 0 && rowIndex !== 0) ? '#CCCCCC' : null;
						}
					},
					style: 'tableBottomMargin'
				}
			);

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Spécificités du chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: this.getElementValue(formData.specificites_chantier, 'string'), style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 4

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Signatures', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%'],
						body: [
							[{text: 'Nom, prénom', style: 'label'}],
							[{text: this.getElementValue(formData.createdBy, 'UserObject'), style: 'content'}],
							[{text: '', style: 'label'}],
							[{text: [{text: this.getElementValue(formData.date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.date_signature, 'DateSignature')], style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			const id_affaire = this.getElementValue(formData.id_affaire, 'string');
			const nom_client = this.getElementValue(formData.nom_client, 'string') + ' (' + this.getElementValue(formData.code_client, 'string') + ')';
			const nom_affaire = this.getElementValue(formData.nom_affaire, 'string');
			const resp_chantier = this.getElementValue(formData.chef_chantier, 'UserObject');

			const docDefinition = {
				info: this.getInfos('analyse_risques', formData),
				permissions: this.getPermissions(),
				header: (currentPage) => {
					if (currentPage !== 1) {
						return {
							table: {
								widths: ['25%', '25%', '25%', '25%'],
								body: [
									[
										{text: 'N° d\'affaire : ' + id_affaire, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Client : ' + nom_client, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Titre : ' + nom_affaire, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Resp. chantier : ' + resp_chantier, style: 'labelHeader2', border: [false, false, false, true]}
									]
								]
							}
						};
					} else {
						return {};
					}
				},
				footer: (currentPage, pageCount) => {
					return {
						table: {
							widths: ['100%'],
							body: [
								[
									{text: 'Page ' + currentPage + ' sur ' + pageCount, alignment: 'center'}
								]
							]
						},
						margin: [0, 10, 0, 0],
						layout: {defaultBorder: false}
					};
				},
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};
			this.pdfObj = pdfMake.createPdf(docDefinition);
			return this.pdfObj;
		} catch (e) {
			throw 'FORM_PDF';
		}
	}

	async generateInspectionChantierPdf(formData: InspectionChantierObject) {
		try {
			const promises = [];
			for (const i in formData.photos) {
				if (formData.photos[i].type === 'remote' && formData.photos[i].data && formData.photos[i].data.url) {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.photos[i].data.url, null));
				} else if (formData.photos[i].type === 'local') {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.photos[i].preview, 'local'));
				}
			}
			const blobs = await Promise.all(promises);
			const photosBlock = [];

			for (const i in blobs) {
				if (blobs[i]) {
					photosBlock.push(
						{
							image: blobs[i],
							width: 300,
							alignment: 'center',
							margin: [0, 0, 0, 10]
						}
					);
				}
			}
			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];
			content.push(this.getHeader('Inspection de chantier', logo, formData.updatedAt));
			content.push(
				// Step 1-2
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '30%', '48%'],
						body: [
							[{text: 'N° d’affaire', style: 'label'},
								{text: ''},
								{text: 'Client', style: 'label'}
							],
							[{text: this.getElementValue(formData.id_affaire, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nom_client, 'string') + ' (' + this.getElementValue(formData.code_client, 'string') + ')', style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Responsable du chantier', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Titre', style: 'label'}
							],
							[{text: this.getElementValue(formData.responsable, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nom_affaire, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Nature des travaux', style: 'label'}],
							[{text: this.getElementValue(formData.nature_travaux, 'string'), style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Personnel', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Personne(s) STEMI auditée(s)', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Personne(s) intérimaire(s) auditée(s)', style: 'label'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			let personnesStemiAuditees = '';
			let personnesInterimAuditees = '';

			for (const i in formData.personne_stemi_auditee) {
				personnesStemiAuditees += formData.personne_stemi_auditee[i].complete_name + '\n';
			}

			for (const i in formData.personne_interim_auditee) {
				personnesInterimAuditees += formData.personne_interim_auditee[i].complete_name + '\n';
			}

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: personnesStemiAuditees, style: 'content'},
								{text: ' ', style: 'contentBlank'},
								{text: personnesInterimAuditees, style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '30%', '22%', '26%'],
						body: [
							[{text: 'Effectif STEMI', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Effectif intérim', style: 'label'},
								{text: '', style: 'label'}
							],
							[{text: this.getElementValue(formData.nb_stemi, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nb_interim, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			if (formData.personnes_supplementaires && formData.personnes_supplementaires.length > 0) {
				let personnesSupplementairesAuditees = '';

				for (const i in formData.personnes_supplementaires) {
					personnesSupplementairesAuditees += formData.personnes_supplementaires[i].nom + ' (' + formData.personnes_supplementaires[i].societe + ', ' + formData.personnes_supplementaires[i].type + ')' + '\n';
				}

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '52%'],
							body: [
								[
									{text: 'Personne(s) supplémentaires auditée(s)', style: 'label'},
									{text: '', style: 'label'}
								],
								[
									{text: personnesSupplementairesAuditees, style: 'content'},
									{text: ' ', style: 'contentBlank'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			// Step 3

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Accès et signalisation du chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			if (formData.voie_acces === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les voies d\'accès sont-elles accessibles et propres ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.voie_acces, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.voie_acces_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les voies d\'accès sont-elles accessibles et propres ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.voie_acces, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.chantier_signalise === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le chantier est-il protégé, balisé et signalisé STEMI ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.chantier_signalise, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.chantier_signalise_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le chantier est-il protégé, balisé et signalisé STEMI ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.chantier_signalise, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.sortie_secours_libre === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les sorties de secours sont-elles libre d\'accès ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.sortie_secours_libre, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.sortie_secours_libre_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les sorties de secours sont-elles libre d\'accès ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.sortie_secours_libre, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.chantier_ordre === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le chantier est-il en ordre ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.chantier_ordre, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.chantier_ordre_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le chantier est-il en ordre ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.chantier_ordre, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Autorisations', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			if (formData.dta === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Existe-t-il un Dossier Technique Amiante (DTA) ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.dta, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.dta_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Existe-t-il un Dossier Technique Amiante (DTA) ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.dta, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.autorisation_permis_valide === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Si nécessité d’autorisation ou permis de travail, sont ils  valide ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.autorisation_permis_valide, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.autorisation_permis_valide_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Si nécessité d’autorisation ou permis de travail, sont ils  valide ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.autorisation_permis_valide, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.manoeuvre_consignation === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Si manœuvre de consignation et/ou déconsignation, une fiche est elle rempli ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.manoeuvre_consignation, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.manoeuvre_consignation_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Si manœuvre de consignation et/ou déconsignation, une fiche est elle rempli ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.manoeuvre_consignation, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.permis_feu_extincteur === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Possèdent-il un permis feu ? Un extincteur est-il disponible?\t', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.permis_feu_extincteur, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.permis_feu_extincteur_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Possèdent-il un permis feu ? Un extincteur est-il disponible?\t', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.permis_feu_extincteur, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.permis_penetrer === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Possèdent-il un permis de pénétrer ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.permis_penetrer, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.permis_penetrer_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Possèdent-il un permis de pénétrer ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.permis_penetrer, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Personnel', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			if (formData.personnel_stemi_identifie === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le personnel STEMI est il clairement identifié ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.personnel_stemi_identifie, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.personnel_stemi_identifie_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le personnel STEMI est il clairement identifié ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.personnel_stemi_identifie, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.personnel_interim_identifie === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le personnel intérimaire est il clairement identifié ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.personnel_interim_identifie, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.personnel_interim_identifie_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le personnel intérimaire est il clairement identifié ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.personnel_interim_identifie, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.accueils_hse === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Tous les accueils HSE de chantier ont-t-ils été réalisés ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.accueils_hse, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.accueils_hse_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[{text: 'Tous les accueils HSE de chantier ont-t-ils été réalisés ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.accueils_hse, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.plan_prevention === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Existe-t-il un PPSPS ou plan de prévention ?\t', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.plan_prevention, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.plan_prevention_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Existe-t-il un PPSPS ou plan de prévention ?\t', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.plan_prevention, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.analyse_risques) {
				if (formData.analyse_risques === 'NC') {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['48%', '4%', '48%'],
								body: [
									[
										{text: 'Si pas de PDP, l\'analyse des risques est-elle disponible et complétée ?', style: 'label'},
										{text: '', style: 'label'},
										{text: 'Précisions', style: 'label'}
									],
									[
										{text: this.getElementValue(formData.analyse_risques, 'c/nc/ca'), style: 'content'},
										{text: '', style: 'contentBlank'},
										{text: this.getElementValue(formData.analyse_risques_precision, 'string'), style: 'content'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				} else {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['48%', '4%', '48%'],
								body: [
									[
										{text: 'Si pas de PDP, l\'analyse des risques est-elle disponible et complétée ?', style: 'label'},
										{text: '', style: 'label'},
										{text: this.getElementValue(formData.analyse_risques, 'c/nc/ca'), style: 'content'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				}
			}

			if (formData.risques_lies_chantier === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le personnel connait-il les risques liés au chantier ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.risques_lies_chantier, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.risques_lies_chantier_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le personnel connait-il les risques liés au chantier ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.risques_lies_chantier, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.risques_specifiques === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le personnel connait-il les risques spécifiques au site du client ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.risques_specifiques, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.risques_specifiques_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le personnel connait-il les risques spécifiques au site du client ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.risques_specifiques, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Habiliation et formation', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			if (formData.habilitations_disponibles === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les habilitations requises sont disponibles sur le chantier (élec, chimique, nacelle)', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.habilitations_disponibles, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.habilitations_disponibles_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les habilitations requises sont disponibles sur le chantier (élec, chimique, nacelle)', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.habilitations_disponibles, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.habilitations_valides === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Si présence d’habilitation sur lieu de travail sont elle valide ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.habilitations_valides, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.habilitations_valides_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Si présence d’habilitation sur lieu de travail sont elle valide ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.habilitations_valides, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.sst_present === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le personnel Stemi est il supérieur à 10, si oui, un SST est-il présent ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.sst_present, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.sst_present_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le personnel Stemi est il supérieur à 10, si oui, un SST est-il présent ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.sst_present, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Matériel', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			if (formData.echelle_etat === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les échelles sont-elles en bon état et stabilisées ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.echelle_etat, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.echelle_etat_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les échelles sont-elles en bon état et stabilisées ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.echelle_etat, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.echafaudage_conforme === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Utilisation d’échafaudages conformes ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.echafaudage_conforme, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.echafaudage_conforme_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Utilisation d’échafaudages conformes ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.echafaudage_conforme, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.fiches_autorisation === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Sont-ils accompagnés de la fiche autorisation ? est-elle rempli et affiché sur celui-ci ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.fiches_autorisation, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.fiches_autorisation_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Sont-ils accompagnés de la fiche autorisation ? est-elle rempli et affiché sur celui-ci ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.fiches_autorisation, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.nacelle_conforme === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les nacelles utilisées sont elles conformes et réceptionnées ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.nacelle_conforme, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.nacelle_conforme_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les nacelles utilisées sont elles conformes et réceptionnées ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.nacelle_conforme, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.etat_outillage === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'L’état général d’outillage à main ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.etat_outillage, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etat_outillage_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'L’état général d’outillage à main ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.etat_outillage, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Sécurité', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			if (formData.epi === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le port des Equipements de Protection Individuelle (chaussures, casque, lunette)', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.epi, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.epi_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Le port des Equipements de Protection Individuelle (chaussures, casque, lunette)', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.epi, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.epi_etat === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les EPI sont-ils en bon état ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.epi_etat, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.epi_etat_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les EPI sont-ils en bon état ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.epi_etat, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.risques_coactivite === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Vous a-t-on informer du risque lié à la co-activité ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.risques_coactivite, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.risques_coactivite_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Vous a-t-on informer du risque lié à la co-activité ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.risques_coactivite, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.vat_chantier === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Détient-il un VAT sur le chantier ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.vat_chantier, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.vat_chantier_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[{text: 'Détient-il un VAT sur le chantier ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.vat_chantier, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.emplacement_douches === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants connaissent les emplacements des douches et des laves yeux ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.emplacement_douches, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.emplacement_douches_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[{text: 'Les intervenants connaissent les emplacements des douches et des laves yeux ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.emplacement_douches, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.moyens_alerte === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants connaissent les moyens d’alertes et les points de rassemblements ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.moyens_alerte, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.moyens_alerte_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants connaissent les moyens d’alertes et les points de rassemblements ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.moyens_alerte, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.localisation_extincteurs === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants connaissent la localisation des extincteurs ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.localisation_extincteurs, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.localisation_extincteurs_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants connaissent la localisation des extincteurs ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.localisation_extincteurs, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.harnais_controle === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Utilisation du harnais,est-il correctement rangé, contrôlé et avec la fiche de suivi ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.harnais_controle, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.harnais_controle_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Utilisation du harnais,est-il correctement rangé, contrôlé et avec la fiche de suivi ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.harnais_controle, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.cadenas_consignation === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Possèdent-ils un cadenas de consignation ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.cadenas_consignation, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.cadenas_consignation_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Possèdent-ils un cadenas de consignation ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.cadenas_consignation, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Hygiène et environnement', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			if (formData.etat_vestiaires === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'L’état des vestiaires et réfectoires', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.etat_vestiaires, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etat_vestiaires_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'L’état des vestiaires et réfectoires', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.etat_vestiaires, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.etat_sanitaires === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'L’état des sanitaires', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.etat_sanitaires, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etat_sanitaires_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'L’état des sanitaires', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.etat_sanitaires, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.tri_dechets === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants connaissent et respectent le tri des déchets', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.tri_dechets, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.tri_dechets_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants connaissent et respectent le tri des déchets', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.tri_dechets, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.emplacement_bennes === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants connaissent et respectent les emplacements des bennes de décharge', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.emplacement_bennes, 'c/nc/ca'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.emplacement_bennes_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants connaissent et respectent les emplacements des bennes de décharge', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.emplacement_bennes, 'c/nc/ca'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Sensibilisation HSE', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			if (formData.actions_non_securitaires === 'Oui') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants réalisent-ils des actions dangereuses ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.actions_non_securitaires, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.actions_non_securitaires_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants réalisent-ils des actions dangereuses ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.actions_non_securitaires, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.actions_securitaires === 'Oui') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Des bonnes pratiques sont-elles remarquables ? Si oui valoriser ces bonnes pratiques', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.actions_securitaires, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.actions_securitaires_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Des bonnes pratiques sont-elles remarquables ? Si oui valoriser ces bonnes pratiques', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.actions_securitaires, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.situations_dangereuses === 'Oui') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants ont-ils rencontré des situations dangereuses ?', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Précisions', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.situations_dangereuses, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.situations_dangereuses_precision, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Les intervenants ont-ils rencontré des situations dangereuses ?', style: 'label'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.situations_dangereuses, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Photos du chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			content.push(photosBlock);

			// Step 4

			const nbActions = formData.getNbActions();

			if (nbActions > 0) {

				content.push(
					this.getBlockSeparator()
				);

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Actions (' + nbActions + ')', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);
			}

			if (formData.voie_acces === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Les voies d\'accès sont-elles ? Accessibles et propres', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_voie_acces_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_voie_acces_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_voie_acces_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.chantier_signalise === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Le chantier est-il protégé, balisé et signalisé STEMI ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_chantier_signalise_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_chantier_signalise_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_chantier_signalise_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.sortie_secours_libre === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Les sorties de secours sont-elles libre d\'accès ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_sortie_secours_libre_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_sortie_secours_libre_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_sortie_secours_libre_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.chantier_ordre === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Le chantier est-il en ordre ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_chantier_ordre_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_chantier_ordre_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_chantier_ordre_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.dta === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Existe-t-il un Dossier Technique Amiante (DTA) ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_dta_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_dta_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_dta_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.autorisation_permis_valide === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Si nécessité d’autorisation ou permis de travail, sont ils  valide ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_autorisation_permis_valide_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_autorisation_permis_valide_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_autorisation_permis_valide_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.manoeuvre_consignation === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Si manœuvre de consignation et/ou déconsignation, une fiche est elle rempli ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_manoeuvre_consignation_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_manoeuvre_consignation_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_manoeuvre_consignation_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}
			if (formData.permis_feu_extincteur === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Possèdent-il un permis feu ? Un extincteur est-il disponible ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_permis_feu_extincteur_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_permis_feu_extincteur_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_permis_feu_extincteur_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.permis_penetrer === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Possèdent-il un permis de pénétrer ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_permis_penetrer_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_permis_penetrer_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_permis_penetrer_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.personnel_stemi_identifie === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Le personnel STEMI est il clairement identifié ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_personnel_stemi_identifie_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_personnel_stemi_identifie_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_personnel_stemi_identifie_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}
			if (formData.personnel_interim_identifie === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Le personnel intérimaire est il clairement identifié ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_personnel_interim_identifie_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_personnel_interim_identifie_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_personnel_interim_identifie_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.accueils_hse === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Tous les accueils HSE de chantier ont-t-ils été réalisés ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_accueils_hse_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_accueils_hse_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_accueils_hse_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.plan_prevention === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Existe-t-il un PPSPS ou plan de prévention ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_plan_prevention_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_plan_prevention_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_plan_prevention_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}
			if (formData.analyse_risques) {
				if (formData.analyse_risques === 'NC') {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['48%', '4%', '48%'],
								body: [
									[
										{colSpan: 3, text: 'Si pas de PDP, l\'analyse des risques est-elle disponible et complétée ?', style: 'labelHeader'},
										{},
										{}
									],
									[
										{text: 'Description', style: 'label'},
										{text: '', style: 'label'},
										{text: 'Responsable', style: 'label'}
									],
									[
										{rowSpan: 3, text: this.getElementValue(formData.action_analyse_risques_description, 'string'), style: 'content'},
										{text: '', style: 'label'},
										{text: this.getElementValue(formData.action_analyse_risques_responsable, 'UserObject'), style: 'content'}
									],
									[
										{},
										{text: '', style: 'label'},
										{text: 'Application', style: 'label'}
									],
									[
										{},
										{text: '', style: 'label'},
										{text: this.getElementValue(formData.action_analyse_risques_application, 'string'), style: 'content'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				}
			}

			if (formData.risques_lies_chantier === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Le personnel connait-il les risques liés au chantier ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_risques_lies_chantier_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_risques_lies_chantier_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_risques_lies_chantier_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.risques_specifiques === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Le personnel connait-il les risques spécifiques au site du client ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_risques_specifiques_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_risques_specifiques_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_risques_specifiques_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}
			if (formData.habilitations_disponibles === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Les habilitations requises sont disponibles sur le chantier (élec, chimique, nacelle)', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_habilitations_disponibles_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_habilitations_disponibles_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_habilitations_disponibles_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.habilitations_valides === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Si présence d’habilitation sur lieu de travail sont elle valide ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_habilitations_valides_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_habilitations_valides_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_habilitations_valides_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.sst_present === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Le personnel Stemi est il supérieur à 10, si oui, un SST est-il présent ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_sst_present_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_sst_present_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_sst_present_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}
			if (formData.echelle_etat === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Les échelles sont-elles en bon état et stabilisées ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_echelle_etat_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_echelle_etat_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_echelle_etat_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.echafaudage_conforme === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Utilisation d’échafaudages conformes ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_echafaudage_conforme_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_echafaudage_conforme_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_echafaudage_conforme_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.fiches_autorisation === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Sont-ils accompagnés de la fiche autorisation ? est-elle rempli et affiché sur celui-ci ?\t\t\t', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_fiches_autorisation_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_fiches_autorisation_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_fiches_autorisation_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}
			if (formData.nacelle_conforme === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Les nacelles utilisées sont elles conformes et réceptionnées ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_nacelle_conforme_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_nacelle_conforme_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_nacelle_conforme_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.etat_outillage === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'L’état général d’outillage à main ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_etat_outillage_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_etat_outillage_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_etat_outillage_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.epi === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Le port des Equipements de Protection Individuelle (chaussures, casque, lunette)', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_epi_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_epi_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_epi_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}
			if (formData.epi_etat === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Les EPI sont-ils en bon état ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_epi_etat_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_epi_etat_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_epi_etat_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.risques_coactivite === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Vous a-t-on informer du risque lié à la co-activité ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_risques_coactivite_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_risques_coactivite_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_risques_coactivite_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.vat_chantier === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Détient-il un VAT sur le chantier ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_vat_chantier_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_vat_chantier_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_vat_chantier_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}
			if (formData.emplacement_douches === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Les intervenants connaissent les emplacements des douches et des laves yeux ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_emplacement_douches_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_emplacement_douches_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_emplacement_douches_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.moyens_alerte === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Les intervenants connaissent les moyens d’alertes et les points de rassemblements ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_moyens_alerte_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_moyens_alerte_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_moyens_alerte_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.localisation_extincteurs === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Les intervenants connaissent la localisation des extincteurs ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_localisation_extincteurs_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_localisation_extincteurs_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_localisation_extincteurs_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}
			if (formData.harnais_controle === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Utilisation du harnais,est-il correctement rangé, contrôlé et avec la fiche de suivi ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_harnais_controle_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_harnais_controle_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_harnais_controle_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.cadenas_consignation === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Possèdent-ils un cadenas de consignation ?', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_cadenas_consignation_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_cadenas_consignation_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_cadenas_consignation_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.etat_vestiaires === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'L’état des vestiaires et réfectoires', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_etat_vestiaires_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_etat_vestiaires_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_etat_vestiaires_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}
			if (formData.etat_sanitaires === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'L’état des sanitaires', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_etat_sanitaires_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_etat_sanitaires_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_etat_sanitaires_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.tri_dechets === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Les intervenants connaissent et respectent le tri des déchets', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_tri_dechets_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_tri_dechets_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_tri_dechets_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.emplacement_bennes === 'NC') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{colSpan: 3, text: 'Les intervenants connaissent et respectent les emplacements des bennes de décharge', style: 'labelHeader'},
									{},
									{}
								],
								[
									{text: 'Description', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Responsable', style: 'label'}
								],
								[
									{rowSpan: 3, text: this.getElementValue(formData.action_emplacement_bennes_description, 'string'), style: 'content'},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_emplacement_bennes_responsable, 'UserObject'), style: 'content'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: 'Application', style: 'label'}
								],
								[
									{},
									{text: '', style: 'label'},
									{text: this.getElementValue(formData.action_emplacement_bennes_application, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			// Step 5

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Signature', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%'],
						body: [
							[{text: 'Nom, prénom', style: 'label'}],
							[{text: this.getElementValue(formData.createdBy, 'UserObject'), style: 'content'}],
							[{text: '', style: 'label'}],
							[{text: [{text: this.getElementValue(formData.date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.date_signature, 'DateSignature')], style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			const id_affaire = this.getElementValue(formData.id_affaire, 'string');
			const nom_client = this.getElementValue(formData.nom_client, 'string') + ' (' + this.getElementValue(formData.code_client, 'string') + ')';
			const nom_affaire = this.getElementValue(formData.nom_affaire, 'string');
			const resp_chantier = this.getElementValue(formData.responsable, 'string');

			const docDefinition = {
				info: this.getInfos('inspection_chantier', formData),
				permissions: this.getPermissions(),
				header: (currentPage) => {
					if (currentPage !== 1) {
						return {
							table: {
								widths: ['25%', '25%', '25%', '25%'],
								body: [
									[
										{text: 'N° d\'affaire : ' + id_affaire, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Client : ' + nom_client, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Titre : ' + nom_affaire, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Resp. chantier : ' + resp_chantier, style: 'labelHeader2', border: [false, false, false, true]}
									]
								]
							}
						};
					} else {
						return {};
					}
				},
				footer: (currentPage, pageCount) => {
					return {
						table: {
							widths: ['100%'],
							body: [
								[
									{text: 'Page ' + currentPage + ' sur ' + pageCount, alignment: 'center'}
								]
							]
						},
						margin: [0, 10, 0, 0],
						layout: {defaultBorder: false}
					};
				},
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};
			this.pdfObj = pdfMake.createPdf(docDefinition);
			return this.pdfObj;
		} catch (e) {
			throw 'FORM_PDF';
		}
	}

	async generateRapportIncidentPdf(formData: RapportIncidentObject, userConnected: UserObject) {
		try {
			const promises = [];
			if (formData.nature_evenement === 'Accident' || formData.nature_evenement === 'Soin') {
				if ((formData.image_corps_face.type === 'remote' || formData.image_corps_face.type === 'defaut') && formData.image_corps_face.data && formData.image_corps_face.data.url) {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.image_corps_face.data.url, null));
				} else {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.image_corps_face.preview, 'local'));
				}

				if ((formData.image_corps_dos.type === 'remote' || formData.image_corps_dos.type === 'defaut') && formData.image_corps_dos.data && formData.image_corps_dos.data.url) {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.image_corps_dos.data.url, null));
				} else {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.image_corps_dos.preview, 'local'));
				}

				if ((formData.image_visage.type === 'remote' || formData.image_visage.type === 'defaut') && formData.image_visage.data && formData.image_visage.data.url) {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.image_visage.data.url, null));
				} else {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.image_visage.preview, 'local'));
				}

				if ((formData.image_main.type === 'remote' || formData.image_main.type === 'defaut') && formData.image_main.data && formData.image_main.data.url) {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.image_main.data.url, null));
				} else {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.image_main.preview, 'local'));
				}

				if ((formData.image_pied.type === 'remote' || formData.image_pied.type === 'defaut') && formData.image_pied.data && formData.image_pied.data.url) {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.image_pied.data.url, null));
				} else {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.image_pied.preview, 'local'));
				}
			}

			if (formData.nature_evenement === 'Incident sur véhicule') {
				if ((formData.vehicule_dos.type === 'remote' || formData.vehicule_dos.type === 'defaut') && formData.vehicule_dos.data && formData.vehicule_dos.data.url) {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.vehicule_dos.data.url, null));
				} else {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.vehicule_dos.preview, 'local'));
				}

				if ((formData.vehicule_face.type === 'remote' || formData.vehicule_face.type === 'defaut') && formData.vehicule_face.data && formData.vehicule_face.data.url) {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.vehicule_face.data.url, null));
				} else {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.vehicule_face.preview, 'local'));
				}

				if ((formData.vehicule_droite.type === 'remote' || formData.vehicule_droite.type === 'defaut') && formData.vehicule_droite.data && formData.vehicule_droite.data.url) {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.vehicule_droite.data.url, null));
				} else {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.vehicule_droite.preview, 'local'));
				}

				if ((formData.vehicule_gauche.type === 'remote' || formData.vehicule_gauche.type === 'defaut') && formData.vehicule_gauche.data && formData.vehicule_gauche.data.url) {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.vehicule_gauche.data.url, null));
				} else {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.vehicule_gauche.preview, 'local'));
				}

			}
			const images: any = await Promise.all(promises);
			const imagesCorpsBlock = [];
			if (formData.nature_evenement === 'Accident' || formData.nature_evenement === 'Soin') {
				const imageWidth = 90;
				imagesCorpsBlock.push(
					{
						table: {
							dontBreakRows: true,
							widths: [imageWidth, 10, imageWidth, 10, imageWidth, 10, imageWidth, 10, imageWidth],
							body: [
								[this.getImageColBlock(images[0], imageWidth), {}, this.getImageColBlock(images[1], imageWidth), {}, this.getImageColBlock(images[2], imageWidth), {}, this.getImageColBlock(images[3], imageWidth), {}, this.getImageColBlock(images[4], imageWidth)]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			const imagesVehiculeBlock = [];
			if (formData.nature_evenement === 'Incident sur véhicule') {
				const imageWidth = 116;
				imagesVehiculeBlock.push(
					{
						table: {
							dontBreakRows: true,
							widths: [imageWidth, 10, imageWidth, 10, imageWidth, 10, imageWidth],
							body: [
								[this.getImageColBlock(images[0], imageWidth), {}, this.getImageColBlock(images[1], imageWidth), {}, this.getImageColBlock(images[2], imageWidth), {}, this.getImageColBlock(images[3], imageWidth)]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			const promises2 = [];
			for (const i in formData.photos) {
				if (formData.photos[i].type === 'remote' && formData.photos[i].data && formData.photos[i].data.url) {
					promises2.push(this.imagesService.getBlobFromImageUrl(formData.photos[i].data.url, null));
				} else if (formData.photos[i].type === 'local') {
					promises2.push(this.imagesService.getBlobFromImageUrl(formData.photos[i].preview, 'local'));
				}
			}
			const photos: any = await Promise.all(promises2);
			const photosBlock = [];
			for (const i in photos) {
				if (photos[i]) {
					photosBlock.push(
						{
							image: photos[i],
							width: 300,
							alignment: 'center',
							margin: [0, 0, 0, 10]
						}
					);
				}
			}

			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];
			content.push(this.getHeader('Rapport accident, soin, situation dangereuse', logo, formData.updatedAt));

			// Step 1-2

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '30%', '48%'],
						body: [
							[{text: 'N° d’affaire', style: 'label'},
								{text: ''},
								{text: 'Client', style: 'label'}
							],
							[{text: this.getElementValue(formData.id_affaire, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nom_client, 'string') + ' (' + this.getElementValue(formData.code_client, 'string') + ')', style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[
								{text: 'Titre', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Nature de l\'évènement', style: 'label'}
							],
							[
								{text: this.getElementValue(formData.nom_affaire, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nature_evenement, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 3

			content.push(
				this.getBlockSeparator()
			);

			if (formData.nature_evenement === 'Accident') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Victime', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Nom, prénom', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Type de contrat', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.id_victime, 'UserObject'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.type_contrat_victime, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%'],
							body: [
								[{text: 'Poste occupé le jour de l’incident', style: 'label'}],
								[{text: this.getElementValue(formData.id_victime ? formData.id_victime.poste : ' ', 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%'],
							body: [
								[{text: 'Y-a-t-il d’autres personnes concernées ?', style: 'label'}],
								[{text: this.getElementValue(formData.autres_personnes, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);

				if (formData.arret_travail === 'Oui') {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['48%', '4%', '48%'],
								body: [
									[
										{text: 'Arrêt de travail ?', style: 'label'},
										{text: '', style: 'label'},
										{text: 'Nombre de jours', style: 'label'},
									],
									[
										{text: this.getElementValue(formData.arret_travail, 'string'), style: 'content'},
										{text: '', style: 'contentBlank'},
										{text: this.getElementValue(formData.arret_travail_nb_jours, 'string'), style: 'content'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				} else {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['48%'],
								body: [
									[
										{text: 'Arrêt de travail ?', style: 'label'}
									],
									[
										{text: this.getElementValue(formData.arret_travail, 'string'), style: 'content'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				}
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%'],
							body: [
								[
									{text: 'Horaires effectuées', style: 'label'},
								],
								[
									{text: this.getElementValue(moment(formData.horaires_effectuees.start).format('HH:mm'), 'string') + ' - ' + this.getElementValue(moment(formData.horaires_effectuees.end).format('HH:mm'), 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Personne concernée', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: 'Nom, prénom', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Type de contrat', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.id_personne_concernee, 'UserObject'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.type_contrat_personne_concernee, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%'],
							body: [
								[{text: 'Poste occupé le jour de l’incident', style: 'label'}],
								[{text: this.getElementValue(formData.id_personne_concernee ? formData.id_personne_concernee.poste : ' ', 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);

				if (formData.nature_evenement === 'Soin') {
					if (formData.autres_personnes_concernees === 'Oui') {
						content.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['48%', '4%', '48%'],
									body: [
										[
											{text: 'Y-a-t-il d’autres personnes concernées ?', style: 'label'},
											{text: '', style: 'label'},
											{text: 'Noms, prénoms', style: 'label'}

										],
										[
											{text: this.getElementValue(formData.autres_personnes_concernees, 'string'), style: 'content'},
											{text: '', style: 'contentBlank'},
											{text: this.getElementValue(formData.autres_personnes_concernees_noms_prenoms, 'string'), style: 'content'}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							}
						);
					} else {
						content.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['48%'],
									body: [
										[{text: 'Y-a-t-il d’autres personnes concernées ?', style: 'label'}],
										[{text: this.getElementValue(formData.autres_personnes_concernees, 'string'), style: 'content'}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							}
						);
					}
				}
			}

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Renseignements sur l’événement', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%'],
						body: [
							[
								{text: 'Date de l\'évènement', style: 'label'}
							],
							[
								{text: this.getElementValue(formData.date_evenement, 'DateTime'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			if (formData.nature_evenement === 'Accident' || formData.nature_evenement === 'Soin') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%'],
							body: [
								[
									{text: 'L’accident a-t-il été causé par un tiers ?', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.cause_tier, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);

				if (formData.temoins === 'Oui') {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['48%', '4%', '48%'],
								body: [
									[
										{text: 'Y a-t-il un ou des témoin(s) ?', style: 'label'},
										{text: '', style: 'label'},
										{text: 'Noms, prénoms', style: 'label'}
									],
									[
										{text: this.getElementValue(formData.temoins, 'string'), style: 'content'},
										{text: '', style: 'contentBlank'},
										{text: this.getElementValue(formData.temoins_noms, 'UserObjects'), style: 'content'}
									],
									[
										{text: '', style: 'label'},
										{text: '', style: 'label'},
										{text: 'Autre(s) témoin(s)', style: 'label'}
									],
									[
										{text: '', style: 'contentBlank'},
										{text: '', style: 'contentBlank'},
										{text: this.getElementValue(formData.temoins_autre, 'string'), style: 'content'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				} else {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['48%'],
								body: [
									[
										{text: 'Y a-t-il un ou des témoin(s) ?', style: 'label'}
									],
									[
										{text: this.getElementValue(formData.temoins, 'string'), style: 'content'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				}
			}

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Description de l’événement', style: 'label'}],
							[{text: this.getElementValue(formData.description, 'string'), style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			if (formData.nature_evenement === 'Accident') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Lieu de l\'accident', style: 'label'}],
								[{text: this.getElementValue(formData.lieu_accident, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Photos', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			content.push(photosBlock);

			content.push(
				this.getBlockSeparator()
			);

			if (formData.nature_evenement === 'Accident' || formData.nature_evenement === 'Soin') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Premiers secours et évacuation', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);

				if (formData.premiers_soins) {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: [{text: this.getElementValue(formData.premiers_soins, 'boolean'), style: 'icon'}, '\tPremiers soins'], style: 'labelHeaderPadded'}],
									[
										{
											table: {
												dontBreakRows: true,
												widths: ['48%'],
												body: [
													[{text: 'Par qui ?', style: 'label'}],
													[{text: this.getElementValue(formData.premiers_soins_qui, 'string'), style: 'content'}]
												]
											},
											layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
										}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				} else {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: [{text: this.getElementValue(formData.premiers_soins, 'boolean'), style: 'icon'}, '\tPremiers soins'], style: 'labelHeaderPadded'}],
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				}

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: [{text: this.getElementValue(formData.appel_secours, 'boolean'), style: 'icon'}, '\tAppel du 15 ou du 18'], style: 'labelHeaderPadded'}],
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);

				if (formData.evacuation) {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: [{text: this.getElementValue(formData.evacuation, 'boolean'), style: 'icon'}, '\tEvacuation'], style: 'labelHeaderPadded'}],
									[
										{
											table: {
												dontBreakRows: true,
												widths: ['48%', '4%', '48%'],
												body: [
													[
														{text: 'Par qui ?', style: 'label'},
														{text: '', style: 'label'},
														{text: 'Où ?', style: 'label'}
													],
													[
														{text: this.getElementValue(formData.evacuation_qui, 'string'), style: 'content'},
														{text: '', style: 'contentBlank'},
														{text: this.getElementValue(formData.evacuation_ou, 'string'), style: 'content'}
													]
												]
											},
											layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
										}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				} else {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: [{text: this.getElementValue(formData.evacuation, 'boolean'), style: 'icon'}, '\tEvacuation'], style: 'labelHeaderPadded'}],
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				}

				content.push(
					this.getBlockSeparator()
				);
			}

			// Step 4

			if (formData.nature_evenement === 'Accident' || formData.nature_evenement === 'Soin') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Siège des lésions', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);

				content.push(imagesCorpsBlock);

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Précisions', style: 'label'}],
								[{text: this.getElementValue(formData.lesions_precisions, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);

				content.push(
					this.getBlockSeparator()
				);

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Nature des lésions', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: [{text: this.getElementValue(formData.nature_lesions_plaie, 'boolean'), style: 'icon'}, '\tCoupure / Plaie'], style: 'labelHeaderPadded'},
									{text: '', style: 'label'},
									{text: [{text: this.getElementValue(formData.nature_lesions_fracture, 'boolean'), style: 'icon'}, '\tFracture'], style: 'labelHeaderPadded'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: [{text: this.getElementValue(formData.nature_lesions_entorse, 'boolean'), style: 'icon'}, '\tInflammation / Entorse'], style: 'labelHeaderPadded'},
									{text: '', style: 'label'},
									{text: [{text: this.getElementValue(formData.nature_lesions_douleurs, 'boolean'), style: 'icon'}, '\tDouleur'], style: 'labelHeaderPadded'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: [{text: this.getElementValue(formData.nature_lesions_hematome, 'boolean'), style: 'icon'}, '\tCoup / Hématome'], style: 'labelHeaderPadded'},
									{text: '', style: 'label'},
									{text: [{text: this.getElementValue(formData.nature_lesions_malaise, 'boolean'), style: 'icon'}, '\tMalaise'], style: 'labelHeaderPadded'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[
									{text: [{text: this.getElementValue(formData.nature_lesions_asphyxie, 'boolean'), style: 'icon'}, '\tAsphyxie'], style: 'labelHeaderPadded'},
									{text: '', style: 'label'},
									{text: [{text: this.getElementValue(formData.nature_lesions_brulure, 'boolean'), style: 'icon'}, '\tBrûlure'], style: 'labelHeaderPadded'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);

				if (formData.nature_lesions_autre) {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['48%'],
								body: [
									[
										{
											table: {
												dontBreakRows: true,
												widths: ['100%'],
												body: [
													[{text: [{text: this.getElementValue(formData.nature_lesions_autre, 'boolean'), style: 'icon'}, '\tAutre'], style: 'labelHeaderPadded'}],
													[
														{
															table: {
																dontBreakRows: true,
																widths: ['100%'],
																body: [
																	[{text: 'Précisions : ', style: 'labelPadded'}],
																	[{text: this.getElementValue(formData.nature_lesions_precisions, 'string'), style: 'content'}]
																]
															},
															layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
														}
													]
												]
											},
											layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
										}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						}
					);
				} else {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['48%'],
								body: [
									[
										{text: [{text: this.getElementValue(formData.nature_lesions_autre, 'boolean'), style: 'icon'}, '\tAutre'], style: 'labelHeaderPadded'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						}
					);
				}

				content.push(
					this.getBlockSeparator()
				);
			}

			if (formData.nature_evenement === 'Incident sur véhicule') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Siège de dégradations matérielles', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);

				content.push(imagesVehiculeBlock);

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Précisions', style: 'label'}],
								[{text: this.getElementValue(formData.dommages_precisions, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);

				content.push(
					this.getBlockSeparator()
				);
			}

			const preventionImmediatesPar = [];
			preventionImmediatesPar.push([{text: 'Mise en place par', style: 'label'}]);

			if (formData.prevention_immediates_par) {
				const prevention_immediates_par = this.getElementValue(formData.prevention_immediates_par, 'UserObject');
				preventionImmediatesPar.push([{text: prevention_immediates_par, style: 'content'}]);
			}
			if (formData.prevention_immediates_par_autre && formData.prevention_immediates_par_autre !== '') {
				const prevention_immediates_par_autre = this.getElementValue(formData.prevention_immediates_par_autre, 'string');
				preventionImmediatesPar.push([{text: prevention_immediates_par_autre, style: 'content'}]);
			}

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Mesures de prévention', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Immédiates, prises le jour de l’incident', style: 'labelHeader'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%'],
						body: [
							[{text: 'Description', style: 'label'}],
							[{text: this.getElementValue(formData.prevention_immediates_description, 'string'), style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%'],
						body: preventionImmediatesPar
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			content.push(
				this.getBlockSeparator()
			);

			// Step 5

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Signature', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			let bodyData = [];

			bodyData.push(
				[
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Redacteur', style: 'labelHeader'}],
								[{text: 'Nom, prénom', style: 'label'}],
								[{text: this.getElementValue(formData.createdBy, 'UserObject'), style: 'content'}],
								[{text: '', style: 'label'}],
								[{text: [{text: this.getElementValue(formData.date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.date_signature, 'DateSignature')], style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{text: '', style: 'label'},
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: formData.nature_evenement === 'Accident' ? 'Victime' : 'Personne concernée', style: 'labelHeader'}],
								[{text: 'Nom, prénom', style: 'label'}],
								[{text: this.getElementValue(formData.nature_evenement === 'Accident' ? formData.id_victime : formData.id_personne_concernee, 'UserObject'), style: 'content'}],
								[{text: '', style: 'label'}],
								[{
									text: [{
										text: this.getElementValue(formData.nature_evenement === 'Accident' ? formData.date_signature_victime : formData.date_signature_personne_concernee, 'signature'),
										style: 'icon'
									}, '\t' + this.getElementValue(formData.nature_evenement === 'Accident' ? formData.date_signature_victime : formData.date_signature_personne_concernee, 'DateSignature')], style: 'content'
								}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				]
			);

			if (formData.temoins_noms) {
				for (let i = 0; i < formData.temoins_noms.length; i = i + 2) {
					const bodyRow = [];
					if (i + 1 < formData.temoins_noms.length) {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Temoin', style: 'labelHeader'}],
										[{text: 'Nom, prénom', style: 'label'}],
										[{text: this.getElementValue(formData.temoins_noms[i], 'UserObject'), style: 'content'}],
										[{text: '', style: 'label'}],
										[{text: [{text: this.getElementValue(formData.temoins_signatures[i], 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.temoins_signatures[i], 'DateSignature')], style: 'content'}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							},
							{text: '', style: 'label'},
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Temoin', style: 'labelHeader'}],
										[{text: 'Nom, prénom', style: 'label'}],
										[{text: this.getElementValue(formData.temoins_noms[i + 1], 'UserObject'), style: 'content'}],
										[{text: '', style: 'label'}],
										[{text: [{text: this.getElementValue(formData.temoins_signatures[i + 1], 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.temoins_signatures[i + 1], 'DateSignature')], style: 'content'}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							}
						);
					} else {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Temoin', style: 'labelHeader'}],
										[{text: 'Nom, prénom', style: 'label'}],
										[{text: this.getElementValue(formData.temoins_noms[i], 'UserObject'), style: 'content'}],
										[{text: '', style: 'label'}],
										[{text: [{text: this.getElementValue(formData.temoins_signatures[i], 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.temoins_signatures[i], 'DateSignature')], style: 'content'}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							},
							{text: '', style: 'label'},
							{text: '', style: 'label'}
						);
					}

					bodyData.push(bodyRow);

				}
			}

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: bodyData
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			const promises3 = [];
			const pdfNames = [];
			for (const i in formData.causes_pieces_jointes) {
				if (formData.causes_pieces_jointes[i].type === 'remote' && formData.causes_pieces_jointes[i].data && formData.causes_pieces_jointes[i].data.url && formData.causes_pieces_jointes[i].data.mime) {
					if (formData.causes_pieces_jointes[i].data.mime !== 'application/pdf') {
						promises3.push(this.imagesService.getBlobFromImageUrl(formData.causes_pieces_jointes[i].data.url, null));
					} else if (formData.causes_pieces_jointes[i].data.mime === 'application/pdf' && formData.causes_pieces_jointes[i].data.name) {
						pdfNames.push({name: formData.causes_pieces_jointes[i].data.name, link: formData.causes_pieces_jointes[i].data.url});
					}
				} else if (formData.causes_pieces_jointes[i].type === 'local') {
					if (formData.causes_pieces_jointes[i].fileType === 'image/jpeg' || formData.causes_pieces_jointes[i].fileType === 'image/png') {
						promises3.push(this.imagesService.getBlobFromImageUrl(formData.causes_pieces_jointes[i].preview, 'local'));
					}
				}
			}

			const photos2 = await Promise.all(promises3);
			const photosBlock2 = [];
			for (const i in photos2) {
				if (photos2[i]) {
					photosBlock2.push(
						{
							image: photos2[i],
							width: 300,
							alignment: 'center',
							margin: [0, 0, 0, 10]
						}
					);
				}
			}

			if (userConnected && userConnected.profil === 'profil_2' && (formData.status === 'validate' || formData.status === 'closed')) {

				content.push(
					this.getBlockSeparator()
				);

				// Step 6

				if (formData.analyse_cause === 'Oui') {

					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Analyse des causes de l’événement', style: 'title'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						},
						{
							table: {
								dontBreakRows: true,
								widths: ['22%'],
								body: [
									[{text: 'Date de l’analyse', style: 'label'}],
									[{text: this.getElementValue(formData.date_analyse, 'Date'), style: 'content'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						},
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Description', style: 'label'}],
									[{text: this.getElementValue(formData.causes_descriptions, 'string'), style: 'content'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);

					content.push(
						this.getBlockSeparator()
					);


					let preventionCorrectivesPar = null;

					if (formData.prevention_correctives_par) {
						preventionCorrectivesPar = this.getElementValue(formData.prevention_correctives_par, 'UserObject');
					}
					if (formData.prevention_correctives_par_autre && formData.prevention_correctives_par_autre !== '') {
						if (preventionCorrectivesPar) {
							preventionCorrectivesPar += '\n' + this.getElementValue(formData.prevention_correctives_par_autre, 'string');
						} else {
							preventionCorrectivesPar = this.getElementValue(formData.prevention_correctives_par_autre, 'string');
						}
					}

					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Mesures de prévention', style: 'title'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						},
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Correctives, pour éviter que l’incident ne se reproduise', style: 'labelHeader'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						},
						{
							table: {
								dontBreakRows: true,
								widths: ['48%'],
								body: [
									[{text: 'Description', style: 'label'}],
									[{
										text: this.getElementValue(formData.prevention_correctives_description, 'string'),
										style: 'content'
									}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						},
						{
							table: {
								dontBreakRows: true,
								widths: ['48%', '4%', '22%', '4%', '22%'],
								body: [
									[
										{text: 'Responsable', style: 'label'},
										{text: '', style: 'label'},
										{text: 'Date prévue de mise en place', style: 'label'},
										{text: '', style: 'label'},
										{text: 'Date de réalisation effective', style: 'label'},
									],
									[
										{text: preventionCorrectivesPar, style: 'content'},
										{text: '', style: 'contentBlank'},
										{
											text: this.getElementValue(formData.prevention_correctives_date_prevue, 'Date'),
											style: 'content'
										},
										{text: '', style: 'contentBlank'},
										{
											text: this.getElementValue(formData.prevention_correctives_date_reel, 'Date'),
											style: 'content'
										}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);

					content.push(
						this.getBlockSeparator()
					);

					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Pièces jointes', style: 'title'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						}
					);

					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Images', style: 'subtitlePadded'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						}
					);

					content.push(photosBlock2);

					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'PDF', style: 'subtitlePadded'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						}
					);
					for (const i in pdfNames) {
						content.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: this.getElementValue(pdfNames[i].name, 'string'), link: pdfNames[i].link, style: 'link'}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							}
						);
					}

					content.push(
						this.getBlockSeparator()
					);

					// Step 7

					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Visa des membres du groupe de travail', style: 'title'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						}
					);

					bodyData = [];

					bodyData.push(
						[
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{
											text: formData.nature_evenement === 'Accident' ? 'Victime' : 'Personne concernée',
											style: 'labelHeader'
										}],
										[{text: 'Nom, prénom', style: 'label'}],
										[{
											text: this.getElementValue(formData.nature_evenement === 'Accident' ? formData.id_victime : formData.id_personne_concernee, 'UserObject'),
											style: 'content'
										}],
										[{text: '', style: 'label'}],
										[{
											text: [{
												text: this.getElementValue(formData.nature_evenement === 'Accident' ? formData.date_signature_victime_2 : formData.date_signature_personne_concernee_2, 'signature'),
												style: 'icon'
											}, '\t' + this.getElementValue(formData.nature_evenement === 'Accident' ? formData.date_signature_victime_2 : formData.date_signature_personne_concernee_2, 'DateSignature')],
											style: 'content'
										}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							},
							{text: '', style: 'label'},
							{text: '', style: 'label'}
						]
					);

					if (formData.temoins_noms) {
						for (let i = 0; i < formData.temoins_noms.length; i = i + 2) {
							const bodyRow = [];
							if (i + 1 < formData.temoins_noms.length) {
								bodyRow.push(
									{
										table: {
											dontBreakRows: true,
											widths: ['100%'],
											body: [
												[{text: 'Temoin', style: 'labelHeader'}],
												[{text: 'Nom, prénom', style: 'label'}],
												[{
													text: this.getElementValue(formData.temoins_noms[i], 'UserObject'),
													style: 'content'
												}],
												[{text: '', style: 'label'}],
												[{
													text: [{
														text: this.getElementValue(formData.temoins_signatures_2[i], 'signature'),
														style: 'icon'
													}, '\t' + this.getElementValue(formData.temoins_signatures_2[i], 'DateSignature')],
													style: 'content'
												}]
											]
										},
										layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
										style: 'tableBottomMargin'
									},
									{text: '', style: 'label'},
									{
										table: {
											dontBreakRows: true,
											widths: ['100%'],
											body: [
												[{text: 'Temoin', style: 'labelHeader'}],
												[{text: 'Nom, prénom', style: 'label'}],
												[{
													text: this.getElementValue(formData.temoins_noms[i + 1], 'UserObject'),
													style: 'content'
												}],
												[{text: '', style: 'label'}],
												[{
													text: [{
														text: this.getElementValue(formData.temoins_signatures_2[i + 1], 'signature'),
														style: 'icon'
													}, '\t' + this.getElementValue(formData.temoins_signatures_2[i + 1], 'DateSignature')],
													style: 'content'
												}]
											]
										},
										layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
										style: 'tableBottomMargin'
									}
								);
							} else {
								bodyRow.push(
									{
										table: {
											dontBreakRows: true,
											widths: ['100%'],
											body: [
												[{text: 'Temoin', style: 'labelHeader'}],
												[{text: 'Nom, prénom', style: 'label'}],
												[{
													text: this.getElementValue(formData.temoins_noms[i], 'UserObject'),
													style: 'content'
												}],
												[{text: '', style: 'label'}],
												[{
													text: [{
														text: this.getElementValue(formData.temoins_signatures_2[i], 'signature'),
														style: 'icon'
													}, '\t' + this.getElementValue(formData.temoins_signatures_2[i], 'DateSignature')],
													style: 'content'
												}]
											]
										},
										layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
										style: 'tableBottomMargin'
									},
									{text: '', style: 'label'},
									{text: '', style: 'label'}
								);
							}

							bodyData.push(bodyRow);

						}
					}

					bodyData.push(
						[
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Responsable HSE', style: 'labelHeader'}],
										[{text: 'Nom, prénom', style: 'label'}],
										[{text: this.getElementValue(formData.id_hse, 'UserObject'), style: 'content'}],
										[{text: '', style: 'label'}],
										[{
											text: [{
												text: this.getElementValue(formData.date_signature_hse, 'signature'),
												style: 'icon'
											}, '\t' + this.getElementValue(formData.date_signature_hse, 'DateSignature')],
											style: 'content'
										}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							},
							{text: '', style: 'label'},
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Chef de chantier / d\'equipe', style: 'labelHeader'}],
										[{text: 'Nom, prénom', style: 'label'}],
										[{text: this.getElementValue(formData.id_chef_chantier, 'UserObject'), style: 'content'}],
										[{text: '', style: 'label'}],
										[{
											text: [{
												text: this.getElementValue(formData.date_signature_chantier, 'signature'),
												style: 'icon'
											}, '\t' + this.getElementValue(formData.date_signature_chantier, 'DateSignature')],
											style: 'content'
										}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							}
						]
					);

					bodyData.push(
						[
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Chargé d\'affaire', style: 'labelHeader'}],
										[{text: 'Nom, prénom', style: 'label'}],
										[{
											text: this.getElementValue(formData.id_charge_affaire, 'UserObject'),
											style: 'content'
										}],
										[{text: '', style: 'label'}],
										[{
											text: [{
												text: this.getElementValue(formData.date_signature_affaire, 'signature'),
												style: 'icon'
											}, '\t' + this.getElementValue(formData.date_signature_affaire, 'DateSignature')],
											style: 'content'
										}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							},
							{text: '', style: 'label'},
							formData.personnes_supplementaires ? {
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Personne(s) supplémentaire(s)', style: 'labelHeader'}],
										[{text: 'Nom, prénom, société', style: 'label'}],
										[{
											text: this.getElementValue(formData.personnes_supplementaires, 'string'),
											style: 'content'
										}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							} : {text: '', style: 'label'}
						]
					);

					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['48%', '4%', '48%'],
								body: bodyData
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				} else {
					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Analyse des causes de l’événement - Non', style: 'title'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						}
					);
				}

				if (formData.status === 'closed') {

					// Step 8

					content.push(
						this.getBlockSeparator()
					);

					content.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['100%'],
								body: [
									[{text: 'Clôture', style: 'title'}]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						},
						{
							table: {
								dontBreakRows: true,
								widths: ['48%', '4%', '48%'],
								body: [
									[
										{
											table: {
												dontBreakRows: true,
												widths: ['100%'],
												body: [
													[{text: 'Responsable HSE', style: 'labelHeader'}],
													[{text: 'Nom, prénom', style: 'label'}],
													[{text: this.getElementValue(formData.id_hse, 'UserObject'), style: 'content'}],
													[{text: '', style: 'label'}],
													[{
														text: [{
															text: this.getElementValue(formData.date_signature_hse_2, 'signature'),
															style: 'icon'
														}, '\t' + this.getElementValue(formData.date_signature_hse_2, 'DateSignature')],
														style: 'content'
													}]
												]
											},
											layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
											style: 'tableBottomMargin'
										},
										{text: '', style: 'label'},
										{text: '', style: 'label'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
							style: 'tableBottomMargin'
						}
					);
				}
			}

			const id_affaire = this.getElementValue(formData.id_affaire, 'string');
			const nom_client = this.getElementValue(formData.nom_client, 'string') + ' (' + this.getElementValue(formData.code_client, 'string') + ')';
			const nom_affaire = this.getElementValue(formData.nom_affaire, 'string');
			const resp_chantier = this.getElementValue(formData.id_chef_chantier, 'UserObject');

			const docDefinition = {
				info: this.getInfos('rapport_incident', formData),
				permissions: this.getPermissions(),
				header: (currentPage) => {
					if (currentPage !== 1) {
						return {
							table: {
								widths: ['25%', '25%', '25%', '25%'],
								body: [
									[
										{text: 'N° d\'affaire : ' + id_affaire, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Client : ' + nom_client, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Titre : ' + nom_affaire, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Resp. chantier : ' + resp_chantier, style: 'labelHeader2', border: [false, false, false, true]}
									]
								]
							}
						};
					} else {
						return {};
					}
				},
				footer: (currentPage, pageCount) => {
					return {
						table: {
							widths: ['100%'],
							body: [
								[
									{text: 'Page ' + currentPage + ' sur ' + pageCount, alignment: 'center'}
								]
							]
						},
						margin: [0, 10, 0, 0],
						layout: {defaultBorder: false}
					};
				},
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};
			this.pdfObj = pdfMake.createPdf(docDefinition);
			return this.pdfObj;
		} catch (e) {
			throw 'FORM_PDF';
		}
	}

	async generateConsigneHarnaisPdf(formData: ConsigneHarnaisObject) {
		try {
			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];
			content.push(this.getHeader('Sensibilisation au port du harnais', logo, formData.updatedAt));

			// Step 1

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Comment mettre un harnais ?', style: 'labelHeaderBold'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				}
			);

			const consigneHarnais1 = await this.imagesService.getBlobFromImageUrl('./assets/imgs/consigne-harnais/consigne-harnais-1.jpg', null);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[
								{
									text: '- Passer le harnais en mettant l’attache dorsale dans le dos.\n\n - Régler les sangles des jambes puis celles du torse.\n\n- Fermer le harnais avec le clip.\n\n- Ajuster les sangles jusqu’à pouvoir passer une main entre le corps et la sangle.',
									style: 'label'
								},
								{text: '', style: 'label'},
								{
									image: consigneHarnais1,
									width: 250
								}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			// Step 2

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Les longes et connecteurs ?', style: 'labelHeaderBold'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				}
			);

			const consigneHarnais2 = await this.imagesService.getBlobFromImageUrl('./assets/imgs/consigne-harnais/consigne-harnais-2.jpg', null);
			const consigneHarnais2bis = await this.imagesService.getBlobFromImageUrl('./assets/imgs/consigne-harnais/consigne-harnais-2-bis.jpg', null);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[
								{
									text: '- Accrocher la longe au harnais avec un mousqueton à vis sur le point d’attache sternal ou dorsal\n\n - Accrocher le crochet sur un point d’ancrage toujours au dessus de soi de préférence ou au même niveau. Le tirant d’air doit être respecté.',
									style: 'label'
								},
								{text: '', style: 'label'},
								{
									text: '- Le point d’ancrage doit être solide et sûr.\n\n - La longe doit toujours être réglée sur la position la plus tendue possible.',
									style: 'label'
								}
							],
							[
								{
									image: consigneHarnais2,
									width: 250
								},
								{text: '', style: 'label'},
								{
									image: consigneHarnais2bis,
									width: 250
								}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			// Step 3

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Vérifier le matériel', style: 'labelHeaderBold'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				}
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[
								{
									text: '- Le harnais et la longe doivent être vérifiés par l’utilisateur avant chaque utilisation :\n\n\t- Bon état général\n\n\t- Sangles non coupées, non effilochées, non souillées par produits chimiques/ciment/huile presse…\n\n\t- Boucles et plaques d’attache en bon état\n\n- Une vérification périodique doit être réalisée annuellement par une personne formée au contôle.',
									style: 'label'
								}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			// Step 4

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'EPI obligatoire dans la nacelle', style: 'labelHeaderBold'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				}
			);

			const consigneHarnais4 = await this.imagesService.getBlobFromImageUrl('./assets/imgs/consigne-harnais/consigne-harnais-4.jpg', null);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[
								{
									image: consigneHarnais4,
									width: 250
								}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			// Step 5

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Comment mettre un harnais ?', style: 'labelHeaderBold'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				}
			);

			const consigneHarnais5 = await this.imagesService.getBlobFromImageUrl('./assets/imgs/consigne-harnais/consigne-harnais-5.jpg', null);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[
								{
									text: '- Un contrôle périodique est obligatoire tous les 6 mois, assurez-vous que la pastille de contrôle date de moins de 6 mois.\n\n - Le conducteur vérifie la nacelle avant chaque utilisation :\n\n\t- État général\n\n\t- Absence de fuite\n\n\t- État et pression des pneus\n\n- Le rôle de l’homme nacelle au sol est de :\n\n\t- Guider le conducteur de la nacelle dans ses déplacements => schémas ci-contre\n\n\t- Surveiller l’environnement :\n\n\t\t- Mettre en position et déplacer le balisage selon les déplacements de la nacelle.\n\n\t\t- Pas de passage de personnel/chariot sous la nacelle dans la zone d’évolution de la nacelle.\n\n\t\t- Alerter en cas de présence d’obstacle.\n\n\t- Alerter les secours en cas d’accident.\n\n\t- Utiliser le poste de secours si nécessaire.\n\n\t- S’assurer que la vigie sait utiliser le poste de secours.',
									style: 'label'
								},
								{text: '', style: 'label'},
								{
									image: consigneHarnais5,
									width: 250
								}
							],
							[
								{colSpan: 3, text: 'Cette sensibilisation ne permet pas de conduire une nacelle, seule une autorisation de conduite de l’employeur le permet !', style: 'labelIndicatorRedLeft'}, {}, {}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					pageBreak: 'after'
				}
			);

			// Step 6

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Signature du chef de chantier', style: 'labelHeaderBold'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%'],
						body: [
							[{text: 'Nom, prénom', style: 'label'}],
							[{text: this.getElementValue(formData.createdBy, 'UserObject'), style: 'content'}],
							[{text: '', style: 'label'}],
							[{text: [{text: this.getElementValue(formData.date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.date_signature, 'DateSignature')], style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			if (formData.signatories && formData.signatories.length > 0) {

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Personne(s) ayant participé à la sensibilisation au port du harnais', style: 'labelHeaderBold'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					}
				);

				const bodyData = [];

				for (let i = 0; i < formData.signatories.length; i = i + 2) {
					const bodyRow = [];
					if (i + 1 < formData.signatories.length) {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Nom, prénom', style: 'label'}],
										[{text: this.getElementValue(formData.signatories[i], 'UserObject'), style: 'content'}],
										[{text: '', style: 'label'}],
										[{text: [{text: this.getElementValue(formData.signatories_dates[i], 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.signatories_dates[i], 'DateSignature')], style: 'content'}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							},
							{text: '', style: 'label'},
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Nom, prénom', style: 'label'}],
										[{text: this.getElementValue(formData.signatories[i + 1], 'UserObject'), style: 'content'}],
										[{text: '', style: 'label'}],
										[{text: [{text: this.getElementValue(formData.signatories_dates[i + 1], 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.signatories_dates[i + 1], 'DateSignature')], style: 'content'}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							}
						);
					} else {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: 'Nom, prénom', style: 'label'}],
										[{text: this.getElementValue(formData.signatories[i], 'UserObject'), style: 'content'}],
										[{text: '', style: 'label'}],
										[{text: [{text: this.getElementValue(formData.signatories_dates[i], 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.signatories_dates[i], 'DateSignature')], style: 'content'}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
								style: 'tableBottomMargin'
							},
							{text: '', style: 'label'},
							{text: '', style: 'label'}
						);
					}

					bodyData.push(bodyRow);

				}
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: bodyData
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			const docDefinition = {
				info: this.getInfos('consigne_harnais', formData),
				permissions: this.getPermissions(),
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};
			this.pdfObj = pdfMake.createPdf(docDefinition);
			return this.pdfObj;
		} catch (e) {
			console.log(e);
			throw 'FORM_PDF';
		}
	}

	// Production

	async generateOuvertureAffairePdf(formData: OuvertureAffaireObject) {
		try {
			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];

			// Step 1

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: this.getElementValue(formData.nom_client, 'string'), style: 'ouvertureAffaireTitle'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					margin: [0, 220, 0, 0]
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: this.getElementValue(formData.titre_affaire, 'string'), style: 'ouvertureAffaireSubtitle'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					margin: [0, 20, 0, 0],
					pageBreak: 'after'
				}
			);

			// Step 1-2

			const numeroDevisComp = [];
			for (const i in formData.numero_devis_comp) {
				numeroDevisComp.push(formData.numero_devis_comp[i].numero_devis);
			}

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '30%', '48%'],
						body: [
							[{text: 'N° de devis', style: 'label'},
								{text: ''},
								{text: 'Client', style: 'label'}
							],
							[{text: this.getElementValue(formData.numero_devis, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nom_client, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '52%'],
						body: [
							[{text: 'N° de devis complémentaires', style: 'label'},
								{text: ''}
							],
							[{text: numeroDevisComp.length > 0 ? this.getElementValue(numeroDevisComp.join(', '), 'string') : ' ', style: 'content'},
								{text: '', style: 'contentBlank'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Interlocuteur', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Titre', style: 'label'}
							],
							[{text: this.getElementValue(formData.interlocuteur, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.titre_affaire, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '4%', '22%', '4%', '22%', '26%'],
						body: [
							[{text: 'Prix de vente (€)', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Heures', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Matériel (€)', style: 'label'},
								{text: '', style: 'label'}
							],
							[{text: this.currencyFormatPipe.transform(this.getElementValue(formData.prix, 'number')), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.currencyFormatPipe.transform(this.getElementValue(formData.heures, 'number')), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.currencyFormatPipe.transform(this.getElementValue(formData.materiel, 'string')), style: 'content'},
								{text: '', style: 'contentBlank'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 3

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Répartition', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Chargé(e) d\'affaires', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Assistant(e) chargé d\'affaires', style: 'label'}
							],
							[{text: this.getElementValue(formData.charge_affaire, 'UserObject'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.assistant_charge_affaire, 'UserObject'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Responsable chantier', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Assistantes administratives ', style: 'label'}
							],
							[{text: this.getElementValue(formData.responsable_chantier, 'UserObject'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.assistante, 'UserObject'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 4

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Traitement', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '22%', '4%', '22%'],
						body: [
							[{text: 'Zone de classement', style: 'label'},
								{text: '', style: 'label'},
								{text: 'N° d\'affaire', style: 'label'},
								{text: '', style: 'label'},
								{text: 'N° de commande', style: 'label'}
							],
							[{text: formData.initials ? this.getElementValue(formData.initials.toUpperCase(), 'string') : ' ', style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.id_affaire, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.numero_commande, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			const idAffaire = this.getElementValue(formData.id_affaire, 'string');
			const interlocuteur = this.getElementValue(formData.interlocuteur, 'string');
			const chargeAffaire = this.getElementValue(formData.charge_affaire, 'UserObject');

			const docDefinition = {
				pageMargins: [40, 110, 40, 45],
				info: this.getInfos('ouverture_affaire', formData),
				permissions: this.getPermissions(),
				header: (page) => {
					return {
						table: {
							widths: ['50%', '50%'],
							body: [
								[
									{image: logo, fit: [150, 150], alignment: 'center', border: [false, false, true, true], margin: [0, 10, 0, 10]},
									{text: 'Affaire n°' + idAffaire, style: 'ouvertureAffaireSubtitle', border: [true, false, false, true], margin: [5, 15, 5, 0]}
								]
							],
							margin: [10, 10]
						}
					};
				},
				footer: (page) => {
					if (page === 1) {
						return {
							table: {
								dontBreakRows: true,
								widths: ['50%', '50%'],
								body: [
									[
										{text: 'Interlocuteur', style: 'label', alignment: 'center', border: [false, true, true, false]},
										{text: 'Chargé d’affaire', style: 'label', alignment: 'center', border: [true, true, false, false]}
									],
									[
										{text: interlocuteur, style: 'content', alignment: 'center', border: [false, false, true, false]},
										{text: chargeAffaire, style: 'content', alignment: 'center', border: [true, false, false, false]}
									]
								],
								margin: [10, 10]
							}
						};
					}
				},
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};
			this.pdfObj = pdfMake.createPdf(docDefinition);
			return this.pdfObj;
		} catch (e) {
			console.log(e);
			throw 'FORM_PDF';
		}
	}

	async generateDemandeTravauxPdf(formData: DemandeTravauxObject) {
		try {
			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];
			content.push(this.getHeader('Demande de travaux', logo, formData.updatedAt));

			// Step 1-2

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '30%', '48%'],
						body: [
							[{text: 'N° d’affaire', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Client', style: 'label'}
							],
							[{text: this.getElementValue(formData.id_affaire, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nom_client, 'string') + ' (' + this.getElementValue(formData.code_client, 'string') + ')', style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Interlocuteur', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Chargé d’affaire', style: 'label'}
							],
							[{text: this.getElementValue(formData.contact, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.charge_affaire, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Titre', style: 'label'},
								{text: '', style: 'label'},
								{text: 'N° de devis', style: 'label'}
							],
							[{text: this.getElementValue(formData.nom_affaire, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.no_devis, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Adresse du chantier', style: 'label'}
							],
							[{text: this.getElementValue(formData.adresse_chantier, 'string'), style: 'content'},
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '4%', '22%', '4%', '48%'],
						body: [
							[
								{text: 'N° de commande', style: 'label'},
								{text: '', style: 'label'},
								{text: 'N° d’affaire si projet existant', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Assistant chargé d\'affaire', style: 'label'}
							],
							[
								{text: this.getElementValue(formData.no_commande, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.numero_affaire, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.id_assistant_charge_affaire, 'UserObject'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 3

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Informations importantes', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[
								{text: this.getElementValue(formData.infos_importantes, 'string'), style: 'content'},
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
			);

			content.push(
				this.getBlockSeparator()
			);

			if (formData.etude_electrique === 'Oui') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Etude électrique - Oui', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '52%'],
							body: [
								[{text: 'Responsable BE élec', style: 'label'},
									{text: '', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.id_resp_elec, 'UserObject'), style: 'content'},
									{text: '', style: 'contentBlank'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['22%', '30%', '48%'],
							body: [
								[{text: 'Temps étude estimé', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Spécificités client', style: 'label'}
								],
								[{text: this.getElementValue(formData.etude_electrique_temps, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_electrique_specificites, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Description des travaux à réaliser', style: 'label'}],
								[{text: this.getElementValue(formData.etude_electrique_description, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '30%', '22%'],
							body: [
								[{text: 'Diffusion (NOM CLIENT)', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Date de remise prévue', style: 'label'}
								],
								[{text: this.getElementValue(formData.etude_electrique_diffusion, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_electrique_date_prevue, 'Date'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Commentaires', style: 'label'}],
								[{text: this.getElementValue(formData.etude_electrique_commentaires, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Etude électrique - Non', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			if (formData.etude_automatisme === 'Oui') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Etude automatisme - Oui', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '52%'],
							body: [
								[{text: 'Responsable BE automatisme', style: 'label'},
									{text: '', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.id_resp_auto, 'UserObject'), style: 'content'},
									{text: '', style: 'contentBlank'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[{text: 'Technicien en charge du projet', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Spécificités client', style: 'label'}
								],
								[{text: this.getElementValue(formData.etude_automatisme_technicien, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_automatisme_specificites, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Nature du projet', style: 'subtitle'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[{text: '', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Marque et Type', style: 'label'}
								],
								[{text: 'Automate', style: 'contentRight'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_automatisme_automate, 'string'), style: 'content'}
								],
								[{text: 'IHM', style: 'contentRight'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_automatisme_ihm, 'string'), style: 'content'}
								],
								[{text: 'Supervision', style: 'contentRight'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_automatisme_supervision, 'string'), style: 'content'}
								],
								[{text: 'Variateur', style: 'contentRight'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_automatisme_variateur, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Documentation à remettre au client', style: 'subtitle'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[{text: 'Analyse fonctionnelle', style: 'contentRight'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_automatisme_analyse, 'string'), style: 'content'}
								],
								[{text: 'Manuel opérateur', style: 'contentRight'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_automatisme_manuel, 'string'), style: 'content'}
								],
								[{text: 'Cahier d’essais', style: 'contentRight'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_automatisme_cahier, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['22%', '4%', '22%', '4%', '22%', '26%'],
							body: [
								[{text: 'Temps développement', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Tps essais plateforme', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Temps mise en service', style: 'label'},
									{text: '', style: 'label'}
								],
								[{text: this.getElementValue(formData.etude_automatisme_temps_developpement, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_automatisme_temps_essai, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_automatisme_temps_service, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['22%', '4%', '22%', '52%'],
							body: [
								[
									{text: 'Date essai plateforme', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Date mise en service', style: 'label'},
									{text: '', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.etude_automatisme_date_essai, 'Date'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.etude_automatisme_date_service, 'Date'), style: 'content'},
									{text: '', style: 'contentBlank'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Commentaires', style: 'label'}],
								[{text: this.getElementValue(formData.etude_automatisme_commentaires, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true, style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Etude automatisme - Non', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			if (formData.atelier === 'Oui') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Atelier - Oui', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '52%'],
							body: [
								[{text: 'Chef d’atelier', style: 'label'},
									{text: '', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.id_chef_atelier, 'UserObject'), style: 'content'},
									{text: '', style: 'contentBlank'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '28%', '20%'],
							body: [
								[{text: 'Essai client chez STEMI', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Date livraison equipement', style: 'label'},
									{text: '', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.atelier_essai, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.atelier_date_livraison, 'Date'), style: 'content'},
									{text: '', style: 'contentBlank'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Adresse livraison', style: 'label'}],
								[{text: this.getElementValue(formData.atelier_adresse_livraison, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[{text: 'Mode de livraison (ex : par STEMI ou Transporteur)', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Contrainte de livraison', style: 'label'}
								],
								[{text: this.getElementValue(formData.atelier_mode_livraison, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.atelier_contrainte_livraison, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '28%', '20%'],
							body: [
								[{text: 'Spécifications client', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Tps cablage atelier estimé', style: 'label'},
									{text: '', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.atelier_specifications, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.atelier_temps_cablage, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Commentaires', style: 'label'}],
								[{text: this.getElementValue(formData.atelier_commentaires, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Atelier - Non', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			if (formData.chantier === 'Oui') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Chantier - Oui', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%', '4%', '48%'],
							body: [
								[{text: 'Chef de chantier', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Spécificités client', style: 'label'}
								],
								[{text: this.getElementValue(formData.chantier_chef, 'UserObject'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.chantier_specificites, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['22%', '4%', '22%', '4%', '22%', '26%'],
							body: [
								[{text: 'Demarrage de chantier', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Fin de chantier', style: 'label'},
									{text: '', style: 'label'},
									{text: 'Tps de chantier estimé', style: 'label'},
									{text: '', style: 'label'},
								],
								[
									{text: this.getElementValue(formData.chantier_date_demarrage, 'Date'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.chantier_date_fin, 'Date'), style: 'content'},
									{text: '', style: 'contentBlank'},
									{text: this.getElementValue(formData.chantier_temps_estime, 'string'), style: 'content'},
									{text: '', style: 'contentBlank'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Commentaires', style: 'label'}],
								[{text: this.getElementValue(formData.chantier_commentaires, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true, style: 'tableBottomMargin'
					}
				);
			} else {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Chantier - Non', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					}
				);
			}

			content.push(
				this.getBlockSeparator()
			);

			if (formData.analyse_necessaire) {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Analyse des risques nécessaire pour ce chantier - ' + this.getElementValue(formData.analyse_necessaire, 'string'), style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					}
				);
			}

			const id_affaire = this.getElementValue(formData.id_affaire, 'string');
			const nom_client = this.getElementValue(formData.nom_client, 'string') + ' (' + this.getElementValue(formData.code_client, 'string') + ')';
			const nom_affaire = this.getElementValue(formData.nom_affaire, 'string');

			const docDefinition = {
				info: this.getInfos('demande_travaux', formData),
				permissions: this.getPermissions(),
				header: (currentPage) => {
					if (currentPage !== 1) {
						return {
							table: {
								widths: ['33.3%', '33.3%', '33.3%'],
								body: [
									[
										{text: 'N° d\'affaire : ' + id_affaire, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Client : ' + nom_client, style: 'labelHeader2', border: [false, false, true, true]},
										{text: 'Titre : ' + nom_affaire, style: 'labelHeader2', border: [false, false, true, true]}
									]
								]
							}
						};
					} else {
						return {};
					}
				},
				footer: (currentPage, pageCount) => {
					return {
						table: {
							widths: ['100%'],
							body: [
								[
									{text: 'Page ' + currentPage + ' sur ' + pageCount, alignment: 'center'}
								]
							]
						},
						margin: [0, 10, 0, 0],
						layout: {defaultBorder: false}
					};
				},
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};
			this.pdfObj = pdfMake.createPdf(docDefinition);
			return this.pdfObj;
		} catch (e) {
			throw 'FORM_PDF';
		}
	}

	async generateCrInterventionPdf(formData: CrInterventionObject) {
		try {
			const promises = [];
			for (const i in formData.photos) {
				if (formData.photos[i].type === 'remote' && formData.photos[i].data && formData.photos[i].data.url) {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.photos[i].data.url, null));
				} else if (formData.photos[i].type === 'local') {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.photos[i].preview, 'local'));
				}
			}
			const blobs = await Promise.all(promises);
			const photosBlock = [];

			for (const i in blobs) {
				if (blobs[i]) {
					photosBlock.push(
						{
							image: blobs[i],
							width: 300,
							alignment: 'center',
							margin: [0, 0, 0, 10]
						}
					);
				}
			}
			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];

			// Step 1

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '30%', '48%'],
						body: [
							[{text: 'N° d’affaire', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Client', style: 'label'}
							],
							[{text: this.getElementValue(formData.id_affaire, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.client, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Interlocuteur', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Lieu', style: 'label'}
							],
							[{text: this.getElementValue(formData.interlocuteur, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.lieu, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Batiment', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Nom ou repère machine', style: 'label'}
							],
							[{text: this.getElementValue(formData.batiment, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.machine, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '22%', '4%', '22%'],
						body: [
							[{text: 'Intervenant(s) Stemi', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Début d’intervention', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Fin d’intervention', style: 'label'}
							],
							[{text: this.getElementValue(formData.intervenant_stemi, 'UserObjects'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.date_intervention, 'Date'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.date_fin_intervention, 'Date'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '4%', '22%', '4%', '22%', '26%'],
						body: [
							[{text: 'Heures bureau', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Heures chantier', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Heures de route', style: 'label'},
								{text: '', style: 'label'},
							],
							[{text: this.getElementValue(formData.heures_bureau, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.heures_chantier, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.heures_route, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 2

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Intervention', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				},
				{
					table: {
						widths: ['100%'],
						body: [
							[{text: 'Description', style: 'label'}],
							[{text: this.getElementValue(formData.description, 'string'), style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(),
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Matériel fourni', style: 'label'}],
							[{text: this.getElementValue(formData.materiel_fourni, 'string'), style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Commentaire client', style: 'label'}],
							[{text: this.getElementValue(formData.commentaire, 'string'), style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 3

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Signatures', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
			);

			const tableBody = [];
			tableBody.push(
				[{text: 'Client', style: 'label'}, {text: '', style: 'label'}, {text: 'Rédacteur', style: 'label'}],
				[{text: this.getElementValue(formData.client_reception_nom, 'string'), style: 'content'}, {text: '', style: 'contentBlank'}, {text: this.getElementValue(formData.createdBy, 'UserObject'), style: 'content'}],
				[{text: '', style: 'label'}, {text: '', style: 'label'}, {text: '', style: 'label'}],
				[{text: [{text: this.getElementValue(formData.client_reception_date, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.client_reception_date, 'DateSignature')], style: 'content'}, {
					text: '',
					style: 'contentBlank'
				}, {text: [{text: this.getElementValue(formData.date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.date_signature, 'DateSignature')], style: 'content'}]
			);

			let clientSignature = null;
			if (formData.client_reception_signature) {
				if (formData.client_reception_signature.type === 'remote' && formData.client_reception_signature.data && formData.client_reception_signature.data.url) {
					clientSignature = await this.imagesService.getBlobFromImageUrl(formData.client_reception_signature.data.url, null);
				} else if (formData.client_reception_signature.type === 'local') {
					clientSignature = await this.imagesService.getBlobFromImageUrl(formData.client_reception_signature.preview, 'local');
				}
				tableBody.push([{text: '', style: 'label'}, {text: '', style: 'label'}, {text: '', style: 'label'}]);
				tableBody.push(
					[
						{
							image: clientSignature,
							fit: [150, 112.5],
							alignment: 'center',
							border: [true, true, true, true]
						},
						{text: '', style: 'contentBlank'},
						{text: '', style: 'contentBlank'}
					]
				);
			}

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: tableBody
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			if (photosBlock.length > 0) {

				content.push(
					this.getBlockSeparator()
				);

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Photos', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);

				content.push(photosBlock);
			}

			const docDefinition = {
				pageMargins: [40, 110, 40, 50],
				info: this.getInfos('cr_intervention', formData),
				permissions: this.getPermissions(),
				header: this.getHeaderWithMargin('Compte rendu d\'intervention', logo, formData.updatedAt),
				footer: (currentPage, pageCount) => {
					return this.getFooter(currentPage, pageCount);
				},
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};
			this.pdfObj = pdfMake.createPdf(docDefinition);
			return this.pdfObj;
		} catch (e) {
			throw 'FORM_PDF';
		}
	}

	async generatePvReceptionPdf(formData: PvReceptionObject) {
		try {
			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];

			// Step 1-2

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '30%', '48%'],
						body: [
							[{text: 'N° d’affaire', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Client', style: 'label'}
							],
							[{text: this.getElementValue(formData.id_affaire, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nom_client, 'string') + ' (' + this.getElementValue(formData.code_client, 'string') + ')', style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Interlocuteur', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Chargé d’affaire', style: 'label'}
							],
							[{text: this.getElementValue(formData.contact, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.charge_affaire, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '22%', '4%', '22%'],
						body: [
							[{text: 'Titre', style: 'label'},
								{text: '', style: 'label'},
								{text: 'N° de devis', style: 'label'},
								{text: '', style: 'label'},
								{text: 'N° de commande', style: 'label'}
							],
							[{text: this.getElementValue(formData.nom_affaire, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.no_devis, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.no_commande, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: ['PV Sous-traitant\t', {text: this.getElementValue(formData.sous_traitant, 'boolean'), style: 'icon'}, ''], style: 'label'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 3

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Réception - ' + this.getElementValue(formData.reception, 'string'), style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Travaux réceptionnés', style: 'subtitle'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				}
			);

			let tableBody = [];

			for (const i in formData.travaux) {
				tableBody = [];
				tableBody.push(
					[
						{text: 'Désignation', style: 'label'},
						{text: '', style: 'label'},
						{text: 'Etat des travaux', style: 'label'}
					]
				);
				tableBody.push(
					[
						{text: this.getElementValue(formData.travaux[i].designation, 'string'), style: 'content'},
						{text: '', style: 'contentBlank'},
						{text: this.getElementValue(formData.travaux[i].conformite, 'c/nc/r/so'), style: 'content'}
					]
				);
				if (formData.travaux[i].conformite === 'NC' || formData.travaux[i].conformite === 'R') {
					tableBody.push(
						[
							{colSpan: 3, text: 'Commentaire', style: 'label'}, {}, {}
						]
					);
					tableBody.push(
						[
							{colSpan: 3, text: this.getElementValue(formData.travaux[i].commentaire, 'string'), style: 'content'}, {}, {}
						]
					);
				}
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[
									{
										table: {
											dontBreakRows: true,
											widths: ['48%', '4%', '48%'],
											body: tableBody
										},
										layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
										style: 'tableBottomMarginLarge'
									}
								]
							]
						},
						layout: this.getTableLayoutOutsideBorderPadding(), unbreakable: true,
						style: 'MarginTop10'
					}
				);
			}

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Travaux complémentaires à chiffrer - ' + this.getElementValue(formData.complementaires, 'string'), style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				}
			);

			if (formData.complementaires === 'Oui') {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[
									{text: 'Commentaire', style: 'label'}
								],
								[
									{text: this.getElementValue(formData.commentaires, 'string'), style: 'content'}
								]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			// Step 4

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Signatures', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
			);

			tableBody = [];
			let signature = null;
			if (!formData.sous_traitant) {
				tableBody.push(
					[{text: 'Client', style: 'label'}, {text: '', style: 'label'}, {text: 'Rédacteur', style: 'label'}],
					[{text: this.getElementValue(formData.client_reception_nom, 'string'), style: 'content'}, {text: '', style: 'contentBlank'}, {text: this.getElementValue(formData.createdBy, 'UserObject'), style: 'content'}],
					[{text: '', style: 'label'}, {text: '', style: 'label'}, {text: '', style: 'label'}],
					[{text: [{text: this.getElementValue(formData.client_reception_date, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.client_reception_date, 'DateSignature')], style: 'content'},
						{text: '', style: 'contentBlank'},
						{text: [{text: this.getElementValue(formData.date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.date_signature, 'DateSignature')], style: 'content'}]
				);
				if (formData.client_reception_signature) {
					if (formData.client_reception_signature.type === 'remote' && formData.client_reception_signature.data && formData.client_reception_signature.data.url) {
						signature = await this.imagesService.getBlobFromImageUrl(formData.client_reception_signature.data.url, null);
					} else if (formData.client_reception_signature.type === 'local') {
						signature = await this.imagesService.getBlobFromImageUrl(formData.client_reception_signature.preview, 'local');
					}
				}
			} else {
				tableBody.push(
					[{text: 'Sous-traitant', style: 'label'}, {text: '', style: 'label'}, {text: 'Rédacteur', style: 'label'}],
					[{text: this.getElementValue(formData.sous_traitant_nom, 'string') + ' (' + this.getElementValue(formData.sous_traitant_societe, 'string') + ')', style: 'content'}, {text: '', style: 'contentBlank'}, {
						text: this.getElementValue(formData.createdBy, 'UserObject'),
						style: 'content'
					}],
					[{text: '', style: 'label'}, {text: '', style: 'label'}, {text: '', style: 'label'}],
					[{text: [{text: this.getElementValue(formData.sous_traitant_date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.sous_traitant_date_signature, 'DateSignature')], style: 'content'},
						{text: '', style: 'contentBlank'},
						{text: [{text: this.getElementValue(formData.date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.date_signature, 'DateSignature')], style: 'content'}]
				);
				if (formData.sous_traitant_signature) {
					if (formData.sous_traitant_signature.type === 'remote' && formData.sous_traitant_signature.data && formData.sous_traitant_signature.data.url) {
						signature = await this.imagesService.getBlobFromImageUrl(formData.sous_traitant_signature.data.url, null);
					} else if (formData.sous_traitant_signature.type === 'local') {
						signature = await this.imagesService.getBlobFromImageUrl(formData.sous_traitant_signature.preview, 'local');
					}
				}
			}
			tableBody.push(
			);

			if (signature) {
				tableBody.push([{text: '', style: 'label'}, {text: '', style: 'label'}, {text: '', style: 'label'}]);
				tableBody.push(
					[
						{
							image: signature,
							fit: [150, 112.5],
							alignment: 'center',
							border: [true, true, true, true]
						},
						{text: '', style: 'contentBlank'},
						{text: '', style: 'contentBlank'}
					]
				);
			}

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: tableBody
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			// Photos

			const photosBlock = [];
			for (const i in formData.travaux) {
				if (formData.travaux[i].photos?.length > 0) {
					const promises = [];
					for (const j in formData.travaux[i].photos) {
						if (formData.travaux[i].photos[j].type === 'remote' && formData.travaux[i].photos[j].data && formData.travaux[i].photos[j].data.url) {
							promises.push(this.imagesService.getBlobFromImageUrl(formData.travaux[i].photos[j].data.url, null));
						} else if (formData.travaux[i].photos[j].type === 'local') {
							promises.push(this.imagesService.getBlobFromImageUrl(formData.travaux[i].photos[j].preview, 'local'));
						}
					}
					const blobs = await Promise.all(promises);
					if (blobs.length > 0) {
						photosBlock.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['100%'],
									body: [
										[{text: this.getElementValue(formData.travaux[i].designation, 'string'), style: 'subtitle'}]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							}
						);
						for (const j in blobs) {
							if (blobs[j]) {
								photosBlock.push(
									{
										image: blobs[j],
										width: 300,
										alignment: 'center',
										margin: [0, 0, 0, 10]
									}
								);
							}
						}
					}
				}
			}

			if (photosBlock.length > 0) {

				content.push(
					this.getBlockSeparator()
				);

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Photos', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);

				content.push(photosBlock);
			}

			const docDefinition = {
				pageMargins: [40, 110, 40, 50],
				info: this.getInfos('pv_reception', formData),
				permissions: this.getPermissions(),
				header: this.getHeaderWithMargin('PV de réception', logo, formData.updatedAt),
				footer: (currentPage, pageCount) => {
					return this.getFooter(currentPage, pageCount);
				},
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};
			this.pdfObj = pdfMake.createPdf(docDefinition);
			return this.pdfObj;
		} catch (e) {
			throw 'FORM_PDF';
		}
	}

	async generateFicheQualitePdf(formData: FicheQualiteObject) {
		try {
			const promises = [];
			for (const i in formData.photos) {
				if (formData.photos[i].type === 'remote' && formData.photos[i].data && formData.photos[i].data.url) {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.photos[i].data.url, null));
				} else if (formData.photos[i].type === 'local') {
					promises.push(this.imagesService.getBlobFromImageUrl(formData.photos[i].preview, 'local'));
				}
			}
			const blobs = await Promise.all(promises);
			const photosBlock = [];

			for (const i in blobs) {
				if (blobs[i]) {
					photosBlock.push(
						{
							image: blobs[i],
							width: 300,
							alignment: 'center',
							margin: [0, 0, 0, 10]
						}
					);
				}
			}
			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];

			// Step 1

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Chantier', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '30%', '48%'],
						body: [
							[{text: 'N° d’affaire', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Client', style: 'label'}
							],
							[{text: this.getElementValue(formData.id_affaire, 'number'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.nom_client, 'string') + ' (' + this.getElementValue(formData.code_client, 'string') + ')', style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Titre', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Chargé d’affaire', style: 'label'}
							],
							[{text: this.getElementValue(formData.nom_affaire, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.charge_affaire, 'string'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			// Step 2

			content.push(
				this.getBlockSeparator()
			);

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Non confirmité(s)', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				},
				{
					table: {
						widths: ['100%'],
						body: [
							[{text: 'Description', style: 'label'}],
							[{text: this.getElementValue(formData.commentaire, 'string'), style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(),
					style: 'tableBottomMargin'
				},
			);

			if (photosBlock.length > 0) {

				content.push(
					this.getBlockSeparator()
				);

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Photos', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);

				content.push(photosBlock);
			}

			if (formData.status === 'validate' || formData.status === 'closed') {

				// Step 3

				content.push(
					this.getBlockSeparator()
				);

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Actions correctives', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					},
					{
						table: {
							widths: ['100%'],
							body: [
								[{text: this.getElementValue(formData.actions_correctives, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(),
						style: 'tableBottomMargin'
					},
				);

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Signature', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%'],
							body: [
								[{
									text: 'Responsable technique',
									style: 'labelHeader'
								}],
								[{text: 'Nom, prénom', style: 'label'}],
								[{
									text: this.getElementValue(formData.id_resp_tech, 'UserObject'),
									style: 'content'
								}],
								[{text: '', style: 'label'}],
								[{
									text: [{
										text: this.getElementValue(formData.date_signature_resp_tech, 'signature'),
										style: 'icon'
									}, '\t' + this.getElementValue(formData.date_signature_resp_tech, 'DateSignature')],
									style: 'content'
								}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			if (formData.status === 'closed') {

				// Step 4

				content.push(
					this.getBlockSeparator()
				);

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Suivi des actions', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					},
					{
						table: {
							widths: ['100%'],
							body: [
								[{text: this.getElementValue(formData.suivi_actions, 'string'), style: 'content'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(),
						style: 'tableBottomMargin'
					},
				);

				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: 'Clôture', style: 'title'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'MarginTop10'
					},
					{
						table: {
							dontBreakRows: true,
							widths: ['48%'],
							body: [
								[{
									text: 'Responsable technique',
									style: 'labelHeader'
								}],
								[{text: 'Nom, prénom', style: 'label'}],
								[{
									text: this.getElementValue(formData.id_resp_tech, 'UserObject'),
									style: 'content'
								}],
								[{text: '', style: 'label'}],
								[{
									text: [{
										text: this.getElementValue(formData.date_signature_resp_tech_2, 'signature'),
										style: 'icon'
									}, '\t' + this.getElementValue(formData.date_signature_resp_tech_2, 'DateSignature')],
									style: 'content'
								}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
						style: 'tableBottomMargin'
					}
				);
			}

			const docDefinition = {
				pageMargins: [40, 110, 40, 50],
				info: this.getInfos('fiche_qualite', formData),
				permissions: this.getPermissions(),
				header: this.getHeaderWithMargin('Fiche qualité', logo, formData.updatedAt),
				footer: (currentPage, pageCount) => {
					return this.getFooter(currentPage, pageCount);
				},
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};
			this.pdfObj = pdfMake.createPdf(docDefinition);
			return this.pdfObj;
		} catch (e) {
			throw 'FORM_PDF';
		}
	}

	// Administratif

	async generateDemandeAbsencePdf(formData: DemandeAbsenceObject) {
		try {
			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];
			content.push(this.getHeader('Demande d\'absence', logo, formData.updatedAt));

			// Step 1

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Récapitulatif', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '4%', '22%', '4%', '22%', '4%', '22%'],
						body: [
							[{text: 'Début', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Fin', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Type d\'absence', style: 'label'},
								{text: '', style: 'label'},
								{text: 'Total (jrs)', style: 'label'}
							],
							[{text: this.getElementValue(formData.date_debut, 'Date'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.date_fin, 'Date'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.type, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'},
								{text: this.getElementValue(formData.total_jours, 'number'), style: 'content'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Commentaire', style: 'label'}],
							[{text: this.getElementValue(formData.commentaire, 'string'), style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['22%', '78%'],
						body: [
							[{text: 'N° chrono', style: 'label'},
								{text: '', style: 'label'}
							],
							[{text: this.getElementValue(formData.no_chrono, 'string'), style: 'content'},
								{text: '', style: 'contentBlank'}
							]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'tableBottomMargin'
				}
			);

			// Step 2

			content.push(
				this.getBlockSeparator()
			);

			let status = '';
			switch (formData.status) {
				case 'to_be_validated' :
					status = 'Demande en attente de validation';
					break;
				case 'validate' :
				case 'processed' :
					status = 'Demande validée';
					break;
				case 'rejected' :
					status = 'Demande refusée';
					break;
				default :
					break;
			}

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Demandeur', style: 'title'}, {text: '', style: 'title'}, {text: 'Responsable', style: 'title'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				},
				{
					table: {
						dontBreakRows: true,
						widths: ['48%', '4%', '48%'],
						body: [
							[{text: 'Nom, prénom', style: 'label'}, {text: '', style: 'label'}, {text: 'Nom, prénom', style: 'label'}],
							[{text: this.getElementValue(formData.createdBy, 'UserObject'), style: 'content'}, {text: '', style: 'contentBlank'}, {text: this.getElementValue(formData.id_responsable, 'UserObject'), style: 'content'}],
							[{text: '', style: 'label'}, {text: '', style: 'label'}, {text: '', style: 'label'}],
							[{text: [{text: this.getElementValue(formData.date_signature, 'signature'), style: 'icon'}, '\t' + this.getElementValue(formData.date_signature, 'DateSignature')], style: 'content'}, {
								text: '',
								style: 'contentBlank'
							}, {text: status, style: 'content'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
				}
			);

			const docDefinition = {
				info: this.getInfos('demande_absence', formData),
				permissions: this.getPermissions(),
				footer: (currentPage, pageCount) => {
					return {
						table: {
							widths: ['100%'],
							body: [
								[
									{text: 'Page ' + currentPage + ' sur ' + pageCount, alignment: 'center'}
								]
							]
						},
						margin: [0, 10, 0, 0],
						layout: {defaultBorder: false}
					};
				},
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};
			this.pdfObj = pdfMake.createPdf(docDefinition);
			return this.pdfObj;
		} catch (e) {
			console.log(e);
			throw 'FORM_PDF';
		}
	}

	// Indicateurs

	async generateIndicatorsPdf(indicators, datesRange) {
		try {
			const promises = [];
			promises.push(this.imagesService.getBlobFromImageUrl('./assets/imgs/accidents-arret.svg', null));
			promises.push(this.imagesService.getBlobFromImageUrl('./assets/imgs/accidents-sans-arret.svg', null));
			promises.push(this.imagesService.getBlobFromImageUrl('./assets/imgs/incidents.svg', null));
			promises.push(this.imagesService.getBlobFromImageUrl('./assets/imgs/situations-dangereuses.svg', null));
			promises.push(this.imagesService.getBlobFromImageUrl('./assets/imgs/soins.svg', null));

			const blobs = await Promise.all(promises);
			const logo = await this.imagesService.getBlobFromImageUrl('./assets/imgs/logo-stemi.png', null);
			const content = [];
			content.push(this.getHeader('Indicateurs', logo, null));

			content.push(
				{
					table: {
						dontBreakRows: true,
						widths: ['100%'],
						body: [
							[{text: 'Période : ' + moment(datesRange[0]).format('MMMM YYYY') + ' - ' + moment(datesRange[1]).format('MMMM YYYY'), style: 'subtitle'}]
						]
					},
					layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true,
					style: 'MarginTop10'
				}
			);

			// Objectifs Entreprise - Accueil Sécurité

			let curIndicator = null;

			for (const i in indicators) {
				if (indicators[i].type === 'objectifsEntrepriseAccueilSecurite') {
					curIndicator = indicators[i].indicator;
				}
			}

			if (curIndicator != null) {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: '', style: 'subtitle'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);
				const realisationAnalysesRisquesPasPdp: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 2);
				const accueilChantierNC: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 13);
				const accueilChantierNCparType: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 14);
				const tableBody = [
					[{colSpan: 2, text: 'Objectifs entreprise - Accueil Sécurité', style: 'subtitlePadded'}, {}],
					[
						{
							table: {
								dontBreakRows: true,
								widths: ['70%', '30%'],
								body: [
									[
										{text: realisationAnalysesRisquesPasPdp ? realisationAnalysesRisquesPasPdp.titre : '', style: 'labelPadded'},
										{
											text: realisationAnalysesRisquesPasPdp ? realisationAnalysesRisquesPasPdp.valeur + ' %' : 'N/A',
											style: realisationAnalysesRisquesPasPdp && realisationAnalysesRisquesPasPdp.valeur < realisationAnalysesRisquesPasPdp.seuilOrange ? 'labelIndicatorRedLeftTop' : realisationAnalysesRisquesPasPdp && realisationAnalysesRisquesPasPdp.valeur >= realisationAnalysesRisquesPasPdp.seuilOrange && realisationAnalysesRisquesPasPdp.valeur < realisationAnalysesRisquesPasPdp.seuilVert ? 'labelIndicatorOrangeLeftTop' : 'labelIndicatorGreenLeftTop'
										}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						},
						{
							table: {
								dontBreakRows: true,
								widths: ['70%', '30%'],
								body: [
									[
										{text: accueilChantierNC ? accueilChantierNC.titre : '', style: 'labelPadded'},
										{text: accueilChantierNC ? accueilChantierNC.valeur : 'N/A', style: 'labelIndicatorBlackLeftTop'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						}
					],
					[{colSpan: 2, text: accueilChantierNCparType ? accueilChantierNCparType.titre : '', style: 'labelPadded'}, {}]
				];
				let tableRow = [];
				if (accueilChantierNCparType) {
					for (const i in accueilChantierNCparType.valeurs) {
						tableRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['70%', '30%'],
									body: [
										[
											{text: accueilChantierNCparType.valeurs[i].titre, style: 'labelPadded'},
											{text: accueilChantierNCparType.valeurs[i].valeur + '/' + accueilChantierNCparType.valeurs[i].total + ' - ' + accueilChantierNCparType.valeurs[i].percentage + '%', style: 'labelIndicatorBlackLeftTop'}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							}
						);
						if (Number(i) % 2 === 1) {
							tableBody.push(tableRow);
							tableRow = [];
						}
					}
				}
				if (tableRow.length === 1) {
					tableRow.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['70%', '30%'],
								body: [
									[
										{text: '', style: 'labelPadded'},
										{text: '', style: 'labelIndicatorBlackLeftTop'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						}
					);
					tableBody.push(tableRow);
				}
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{
									table: {
										dontBreakRows: true,
										widths: ['50%', '50%'],
										body: tableBody
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
								]
							]
						},
						layout: this.getTableLayoutBorderNoPadding(), unbreakable: true
					}
				);
			}

			// Objectifs Entreprise - Analyse des risques

			curIndicator = null;

			for (const i in indicators) {
				if (indicators[i].type === 'objectifsEntrepriseAnalyseRisques') {
					curIndicator = indicators[i].indicator;
				}
			}

			if (curIndicator != null) {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: '', style: 'subtitle'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);
				const realisationGlobaleAnalysesRisques: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 3);
				const analyseRisquesNCparType: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 15);
				const tableBody = [
					[{colSpan: 2, text: 'Objectifs entreprise - Analyse des risques', style: 'subtitlePadded'}, {}],
					[
						{
							table: {
								dontBreakRows: true,
								widths: ['70%', '30%'],
								body: [
									[
										{text: realisationGlobaleAnalysesRisques ? realisationGlobaleAnalysesRisques.titre : '', style: 'labelPadded'},
										{
											text: realisationGlobaleAnalysesRisques ? realisationGlobaleAnalysesRisques.valeur + ' %' : 'N/A',
											style: realisationGlobaleAnalysesRisques && realisationGlobaleAnalysesRisques.valeur < realisationGlobaleAnalysesRisques.seuilOrange ? 'labelIndicatorRedLeftTop' : realisationGlobaleAnalysesRisques && realisationGlobaleAnalysesRisques.valeur >= realisationGlobaleAnalysesRisques.seuilOrange && realisationGlobaleAnalysesRisques.valeur < realisationGlobaleAnalysesRisques.seuilVert ? 'labelIndicatorOrangeLeftTop' : 'labelIndicatorGreenLeftTop'
										}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						},
						{}
					],
					[{colSpan: 2, text: analyseRisquesNCparType ? analyseRisquesNCparType.titre : '', style: 'labelPadded'}, {}]
				];
				let tableRow = [];
				if (analyseRisquesNCparType) {
					for (const i in analyseRisquesNCparType.valeurs) {
						tableRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['70%', '30%'],
									body: [
										[
											{text: analyseRisquesNCparType.valeurs[i].titre, style: 'labelPadded'},
											{text: analyseRisquesNCparType.valeurs[i].valeur + '/' + analyseRisquesNCparType.valeurs[i].total + ' - ' + analyseRisquesNCparType.valeurs[i].percentage + '%', style: 'labelIndicatorBlackLeftTop'}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							}
						);
						if (Number(i) % 2 === 1) {
							tableBody.push(tableRow);
							tableRow = [];
						}
					}
				}
				if (tableRow.length === 1) {
					tableRow.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['70%', '30%'],
								body: [
									[
										{text: '', style: 'labelPadded'},
										{text: '', style: 'labelIndicatorBlackLeftTop'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						}
					);
					tableBody.push(tableRow);
				}
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{
									table: {
										dontBreakRows: true,
										widths: ['50%', '50%'],
										body: tableBody
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
								]
							]
						},
						layout: this.getTableLayoutBorderNoPadding(), unbreakable: true
					}
				);
			}

			// Objectifs Entreprise - Rapport d'incident

			curIndicator = null;

			for (const i in indicators) {
				if (indicators[i].type === 'objectifsEntrepriseRapportIncident') {
					curIndicator = indicators[i].indicator;
				}
			}

			if (curIndicator != null) {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: '', style: 'subtitle'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);
				const traitementRemonteesEvenements: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 4);
				const tableBody = [
					[{colSpan: 2, text: 'Objectifs entreprise - Rapport d\'indicent', style: 'subtitlePadded'}, {}],
					[
						{
							table: {
								dontBreakRows: true,
								widths: ['70%', '30%'],
								body: [
									[
										{text: traitementRemonteesEvenements ? traitementRemonteesEvenements.titre : '', style: 'labelPadded'},
										{
											text: traitementRemonteesEvenements ? traitementRemonteesEvenements.valeur + ' %' : 'N/A',
											style: traitementRemonteesEvenements && traitementRemonteesEvenements.valeur < traitementRemonteesEvenements.seuilOrange ? 'labelIndicatorRedLeftTop' : traitementRemonteesEvenements && traitementRemonteesEvenements.valeur >= traitementRemonteesEvenements.seuilOrange && traitementRemonteesEvenements.valeur < traitementRemonteesEvenements.seuilVert ? 'labelIndicatorOrangeLeftTop' : 'labelIndicatorGreenLeftTop'
										}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						},
						{}
					]
				];
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{
									table: {
										dontBreakRows: true,
										widths: ['50%', '50%'],
										body: tableBody
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
								]
							]
						},
						layout: this.getTableLayoutBorderNoPadding(), unbreakable: true
					}
				);
			}

			// Objectifs Entreprise - Inspection de chantier

			curIndicator = null;

			for (const i in indicators) {
				if (indicators[i].type === 'objectifsEntrepriseInspectionChantier') {
					curIndicator = indicators[i].indicator;
				}
			}

			if (curIndicator != null) {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: '', style: 'subtitle'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);
				const inspectionChantierNC: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 16);
				const inspectionChantierNCparType: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 17);
				const tableBody = [
					[{colSpan: 2, text: 'Objectifs entreprise - Inspection de chantier', style: 'subtitlePadded'}, {}],
					[
						{
							table: {
								dontBreakRows: true,
								widths: ['70%', '30%'],
								body: [
									[
										{text: inspectionChantierNC ? inspectionChantierNC.titre : '', style: 'labelPadded'},
										{text: inspectionChantierNC ? inspectionChantierNC.valeur : 'N/A', style: 'labelIndicatorBlackLeftTop'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						},
						{}
					],
					[{colSpan: 2, text: inspectionChantierNCparType ? inspectionChantierNCparType.titre : '', style: 'labelPadded'}, {}]
				];
				let tableRow = [];
				if (inspectionChantierNCparType) {
					for (const i in inspectionChantierNCparType.valeurs) {
						tableRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['70%', '30%'],
									body: [
										[
											{text: inspectionChantierNCparType.valeurs[i].titre, style: 'labelPadded'},
											{text: inspectionChantierNCparType.valeurs[i].valeur + '/' + inspectionChantierNCparType.valeurs[i].total + ' - ' + inspectionChantierNCparType.valeurs[i].percentage + '%', style: 'labelIndicatorBlackLeftTop'}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							}
						);
						if (Number(i) % 2 === 1) {
							tableBody.push(tableRow);
							tableRow = [];
						}
					}
				}
				if (tableRow.length === 1) {
					tableRow.push(
						{
							table: {
								dontBreakRows: true,
								widths: ['70%', '30%'],
								body: [
									[
										{text: '', style: 'labelPadded'},
										{text: '', style: 'labelIndicatorBlackLeftTop'}
									]
								]
							},
							layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
						}
					);
					tableBody.push(tableRow);
				}
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{
									table: {
										dontBreakRows: true,
										widths: ['50%', '50%'],
										body: tableBody
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
								]
							]
						},
						layout: this.getTableLayoutBorderNoPadding(), unbreakable: true
					}
				);
			}

			// Quelques chiffres

			curIndicator = null;

			for (const i in indicators) {
				if (indicators[i].type === 'quelquesChiffres') {
					curIndicator = indicators[i].indicator;
				}
			}

			if (curIndicator != null) {
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: '', style: 'spaceBetweenIndicators'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);
				const accidentsSansArret: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 8);
				const accidentsAvecArret: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 9);
				const soins: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 10);
				const situationsDangereuses: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 11);
				const incidentsVehicule: IndicateurObject = this.indicatorsService.getIndicatorById(curIndicator, 12);
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{
									table: {
										dontBreakRows: true,
										widths: ['20%', '20%', '20%', '20%', '20%'],
										body: [
											[{colSpan: 5, text: 'Quelques chiffres', style: 'subtitlePadded'}, {}, {}, {}, {}],
											[
												{
													table: {
														dontBreakRows: true,
														widths: ['70%', '30%'],
														body: [
															[{
																image: blobs[0],
																width: 50,
																alignment: 'right'
															},
																{
																	text: accidentsSansArret ? accidentsSansArret.valeur : '',
																	style: accidentsSansArret && accidentsSansArret.valeur > accidentsSansArret.seuilOrange ? 'labelIndicatorRedLeft' : accidentsSansArret && accidentsSansArret.valeur <= accidentsSansArret.seuilOrange && accidentsSansArret.valeur > accidentsSansArret.seuilVert ? 'labelIndicatorOrangeLeft' : 'labelIndicatorBlackLeft'
																}],
															[{colSpan: 2, text: accidentsSansArret ? accidentsSansArret.titre : '', style: 'labelCentered'}, {}]
														]
													},
													layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
												},
												{
													table: {
														dontBreakRows: true,
														widths: ['70%', '30%'],
														body: [
															[{
																image: blobs[1],
																width: 50,
																alignment: 'right'
															},
																{
																	text: accidentsAvecArret ? accidentsAvecArret.valeur : '',
																	style: accidentsAvecArret && accidentsAvecArret.valeur > accidentsAvecArret.seuilOrange ? 'labelIndicatorRedLeft' : accidentsAvecArret && accidentsAvecArret.valeur <= accidentsAvecArret.seuilOrange && accidentsAvecArret.valeur > accidentsAvecArret.seuilVert ? 'labelIndicatorOrangeLeft' : 'labelIndicatorBlackLeft'
																}],
															[{colSpan: 2, text: accidentsAvecArret ? accidentsAvecArret.titre : '', style: 'labelCentered'}, {}]
														]
													},
													layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
												},
												{
													table: {
														dontBreakRows: true,
														widths: ['70%', '30%'],
														body: [
															[{
																image: blobs[4],
																width: 50,
																alignment: 'right'
															},
																{
																	text: soins ? soins.valeur : '',
																	style: soins && soins.valeur > soins.seuilOrange ? 'labelIndicatorRedLeft' : soins && soins.valeur <= soins.seuilOrange && soins.valeur > soins.seuilVert ? 'labelIndicatorOrangeLeft' : 'labelIndicatorBlackLeft'
																}],
															[{colSpan: 2, text: soins ? soins.titre : '', style: 'labelCentered'}, {}]
														]
													},
													layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
												},
												{
													table: {
														dontBreakRows: true,
														widths: ['70%', '30%'],
														body: [
															[{
																image: blobs[3],
																width: 50,
																alignment: 'right'
															},
																{text: situationsDangereuses ? situationsDangereuses.valeur : '', style: 'labelIndicatorBlackLeft'}],
															[{colSpan: 2, text: situationsDangereuses ? situationsDangereuses.titre : '', style: 'labelCentered'}, {}]
														]
													},
													layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
												},
												{
													table: {
														dontBreakRows: true,
														widths: ['70%', '30%'],
														body: [
															[{
																image: blobs[2],
																width: 50,
																alignment: 'right'
															},
																{
																	text: incidentsVehicule ? incidentsVehicule.valeur : '',
																	style: incidentsVehicule && incidentsVehicule.valeur > incidentsVehicule.seuilOrange ? 'labelIndicatorRedLeft' : incidentsVehicule && incidentsVehicule.valeur <= incidentsVehicule.seuilOrange && incidentsVehicule.valeur > incidentsVehicule.seuilVert ? 'labelIndicatorOrangeLeft' : 'labelIndicatorBlackLeft'
																}],
															[{colSpan: 2, text: incidentsVehicule ? incidentsVehicule.titre : '', style: 'labelCentered'}, {}]
														]
													},
													layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
												}
											]
										]
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
								]
							]
						},
						layout: this.getTableLayoutBorderNoPadding(), unbreakable: true
					}
				);
			}

			curIndicator = null;

			for (const i in indicators) {
				if (indicators[i].type === 'realisationInspectionsChantierParChargeAffaire') {
					curIndicator = indicators[i].indicator;
				}
			}

			if (curIndicator != null) {
				const realisationInspectionsChantierParChargeAffaire: IndicateurObject = curIndicator;
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: '', style: 'spaceBetweenIndicators'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);
				const bodyData = [];
				bodyData.push([{colSpan: 3, text: realisationInspectionsChantierParChargeAffaire.titre, style: 'subtitlePadded'}, {}, {}]);
				for (let i = 0; i < realisationInspectionsChantierParChargeAffaire.valeurs.length; i = i + 2) {
					const bodyRow = [];
					if (i + 1 < realisationInspectionsChantierParChargeAffaire.valeurs.length) {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['70%', '30%'],
									body: [
										[
											{text: realisationInspectionsChantierParChargeAffaire.valeurs[i].titre, style: 'labelPadded'},
											{
												text: realisationInspectionsChantierParChargeAffaire.valeurs[i].valeur + '/' + realisationInspectionsChantierParChargeAffaire.valeurs[i].objectif,
												style: realisationInspectionsChantierParChargeAffaire.valeurs[i].percentage < realisationInspectionsChantierParChargeAffaire.valeurs[i].seuilOrange ? 'labelIndicatorRedLeftTop' : realisationInspectionsChantierParChargeAffaire.valeurs[i].percentage >= realisationInspectionsChantierParChargeAffaire.valeurs[i].seuilOrange && realisationInspectionsChantierParChargeAffaire.valeurs[i].percentage < realisationInspectionsChantierParChargeAffaire.valeurs[i].seuilVert ? 'labelIndicatorOrangeLeftTop' : 'labelIndicatorGreenLeftTop'
											}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							},
							{text: '', style: 'label'},
							{
								table: {
									dontBreakRows: true,
									widths: ['70%', '30%'],
									body: [
										[
											{text: realisationInspectionsChantierParChargeAffaire.valeurs[i + 1].titre, style: 'labelPadded'},
											{
												text: realisationInspectionsChantierParChargeAffaire.valeurs[i + 1].valeur + '/' + realisationInspectionsChantierParChargeAffaire.valeurs[i + 1].objectif,
												style: realisationInspectionsChantierParChargeAffaire.valeurs[i + 1].percentage < realisationInspectionsChantierParChargeAffaire.valeurs[i + 1].seuilOrange ? 'labelIndicatorRedLeftTop' : realisationInspectionsChantierParChargeAffaire.valeurs[i + 1].percentage >= realisationInspectionsChantierParChargeAffaire.valeurs[i + 1].seuilOrange && realisationInspectionsChantierParChargeAffaire.valeurs[i + 1].percentage < realisationInspectionsChantierParChargeAffaire.valeurs[i + 1].seuilVert ? 'labelIndicatorOrangeLeftTop' : 'labelIndicatorGreenLeftTop'
											}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							}
						);
					} else {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['70%', '30%'],
									body: [
										[
											{text: realisationInspectionsChantierParChargeAffaire.valeurs[i].titre, style: 'labelPadded'},
											{
												text: realisationInspectionsChantierParChargeAffaire.valeurs[i].valeur + '/' + realisationInspectionsChantierParChargeAffaire.valeurs[i].objectif,
												style: realisationInspectionsChantierParChargeAffaire.valeurs[i].percentage < realisationInspectionsChantierParChargeAffaire.valeurs[i].seuilOrange ? 'labelIndicatorRedLeftTop' : realisationInspectionsChantierParChargeAffaire.valeurs[i].percentage >= realisationInspectionsChantierParChargeAffaire.valeurs[i].seuilOrange && realisationInspectionsChantierParChargeAffaire.valeurs[i].percentage < realisationInspectionsChantierParChargeAffaire.valeurs[i].seuilVert ? 'labelIndicatorOrangeLeftTop' : 'labelIndicatorGreenLeftTop'
											}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							},
							{text: '', style: 'label'},
							{text: '', style: 'label'}
						);
					}
					bodyData.push(bodyRow);
				}
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{
									table: {
										dontBreakRows: true,
										widths: ['48%', '4%', '48%'],
										body: bodyData
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
								]
							]
						},
						layout: this.getTableLayoutBorderNoPadding(), unbreakable: true
					}
				);
			}

			curIndicator = null;

			for (const i in indicators) {
				if (indicators[i].type === 'realisationInspectionsChantierParHse') {
					curIndicator = indicators[i].indicator;
				}
			}

			if (curIndicator != null) {
				const realisationInspectionsChantierParHse: IndicateurObject = curIndicator;
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: '', style: 'spaceBetweenIndicators'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);
				const bodyData = [];
				bodyData.push([{colSpan: 3, text: realisationInspectionsChantierParHse.titre, style: 'subtitlePadded'}, {}, {}]);
				for (let i = 0; i < realisationInspectionsChantierParHse.valeurs.length; i = i + 2) {
					const bodyRow = [];
					if (i + 1 < realisationInspectionsChantierParHse.valeurs.length) {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['70%', '30%'],
									body: [
										[
											{text: realisationInspectionsChantierParHse.valeurs[i].titre, style: 'labelPadded'},
											{
												text: realisationInspectionsChantierParHse.valeurs[i].valeur + '/' + realisationInspectionsChantierParHse.valeurs[i].objectif,
												style: realisationInspectionsChantierParHse.valeurs[i].percentage < realisationInspectionsChantierParHse.valeurs[i].seuilOrange ? 'labelIndicatorRedLeftTop' : realisationInspectionsChantierParHse.valeurs[i].percentage >= realisationInspectionsChantierParHse.valeurs[i].seuilOrange && realisationInspectionsChantierParHse.valeurs[i].percentage < realisationInspectionsChantierParHse.valeurs[i].seuilVert ? 'labelIndicatorOrangeLeftTop' : 'labelIndicatorGreenLeftTop'
											}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							},
							{text: '', style: 'label'},
							{
								table: {
									dontBreakRows: true,
									widths: ['70%', '30%'],
									body: [
										[
											{text: realisationInspectionsChantierParHse.valeurs[i + 1].titre, style: 'labelPadded'},
											{
												text: realisationInspectionsChantierParHse.valeurs[i + 1].valeur + '/' + realisationInspectionsChantierParHse.valeurs[i + 1].objectif,
												style: realisationInspectionsChantierParHse.valeurs[i + 1].percentage < realisationInspectionsChantierParHse.valeurs[i + 1].seuilOrange ? 'labelIndicatorRedLeftTop' : realisationInspectionsChantierParHse.valeurs[i + 1].percentage >= realisationInspectionsChantierParHse.valeurs[i + 1].seuilOrange && realisationInspectionsChantierParHse.valeurs[i + 1].percentage < realisationInspectionsChantierParHse.valeurs[i + 1].seuilVert ? 'labelIndicatorOrangeLeftTop' : 'labelIndicatorGreenLeftTop'
											}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							}
						);
					} else {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['70%', '30%'],
									body: [
										[
											{text: realisationInspectionsChantierParHse.valeurs[i].titre, style: 'labelPadded'},
											{
												text: realisationInspectionsChantierParHse.valeurs[i].valeur + '/' + realisationInspectionsChantierParHse.valeurs[i].objectif,
												style: realisationInspectionsChantierParHse.valeurs[i].percentage < realisationInspectionsChantierParHse.valeurs[i].seuilOrange ? 'labelIndicatorRedLeftTop' : realisationInspectionsChantierParHse.valeurs[i].percentage >= realisationInspectionsChantierParHse.valeurs[i].seuilOrange && realisationInspectionsChantierParHse.valeurs[i].percentage < realisationInspectionsChantierParHse.valeurs[i].seuilVert ? 'labelIndicatorOrangeLeftTop' : 'labelIndicatorGreenLeftTop'
											}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							},
							{text: '', style: 'label'},
							{text: '', style: 'label'}
						);
					}
					bodyData.push(bodyRow);
				}
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{
									table: {
										dontBreakRows: true,
										widths: ['48%', '4%', '48%'],
										body: bodyData
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
								]
							]
						},
						layout: this.getTableLayoutBorderNoPadding(), unbreakable: true
					}
				);
			}

			curIndicator = null;

			for (const i in indicators) {
				if (indicators[i].type === 'realisationAnalysesRisquesParChargeAffaire') {
					curIndicator = indicators[i].indicator;
					break;
				}
			}

			if (curIndicator != null) {
				const realisationAnalysesRisquesParChargeAffaire: IndicateurObject = curIndicator;
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{text: '', style: 'spaceBetweenIndicators'}]
							]
						},
						layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
					}
				);
				const bodyData = [];
				bodyData.push([{colSpan: 3, text: realisationAnalysesRisquesParChargeAffaire.titre, style: 'subtitlePadded'}, {}, {}]);
				for (let i = 0; i < realisationAnalysesRisquesParChargeAffaire.valeurs.length; i = i + 2) {
					const bodyRow = [];
					if (i + 1 < realisationAnalysesRisquesParChargeAffaire.valeurs.length) {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['70%', '30%'],
									body: [
										[
											{text: realisationAnalysesRisquesParChargeAffaire.valeurs[i].titre, style: 'labelPadded'},
											{
												text: realisationAnalysesRisquesParChargeAffaire.valeurs[i].percentage + ' %',
												style: realisationAnalysesRisquesParChargeAffaire.valeurs[i].percentage < realisationAnalysesRisquesParChargeAffaire.valeurs[i].seuilOrange ? 'labelIndicatorRedLeftTop' : realisationAnalysesRisquesParChargeAffaire.valeurs[i].percentage >= realisationAnalysesRisquesParChargeAffaire.valeurs[i].seuilOrange && realisationAnalysesRisquesParChargeAffaire.valeurs[i].percentage < realisationAnalysesRisquesParChargeAffaire.valeurs[i].seuilVert ? 'labelIndicatorOrangeLeftTop' : 'labelIndicatorGreenLeftTop'
											}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							},
							{text: '', style: 'label'},
							{
								table: {
									dontBreakRows: true,
									widths: ['70%', '30%'],
									body: [
										[
											{text: realisationAnalysesRisquesParChargeAffaire.valeurs[i + 1].titre, style: 'labelPadded'},
											{
												text: realisationAnalysesRisquesParChargeAffaire.valeurs[i + 1].percentage + ' %',
												style: realisationAnalysesRisquesParChargeAffaire.valeurs[i + 1].percentage < realisationAnalysesRisquesParChargeAffaire.valeurs[i + 1].seuilOrange ? 'labelIndicatorRedLeftTop' : realisationAnalysesRisquesParChargeAffaire.valeurs[i + 1].percentage >= realisationAnalysesRisquesParChargeAffaire.valeurs[i + 1].seuilOrange && realisationAnalysesRisquesParChargeAffaire.valeurs[i + 1].percentage < realisationAnalysesRisquesParChargeAffaire.valeurs[i + 1].seuilVert ? 'labelIndicatorOrangeLeftTop' : 'labelIndicatorGreenLeftTop'
											}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							}
						);
					} else {
						bodyRow.push(
							{
								table: {
									dontBreakRows: true,
									widths: ['70%', '30%'],
									body: [
										[
											{text: realisationAnalysesRisquesParChargeAffaire.valeurs[i].titre, style: 'labelPadded'},
											{
												text: realisationAnalysesRisquesParChargeAffaire.valeurs[i].percentage + ' %',
												style: realisationAnalysesRisquesParChargeAffaire.valeurs[i].percentage < realisationAnalysesRisquesParChargeAffaire.valeurs[i].seuilOrange ? 'labelIndicatorRedLeftTop' : realisationAnalysesRisquesParChargeAffaire.valeurs[i].percentage >= realisationAnalysesRisquesParChargeAffaire.valeurs[i].seuilOrange && realisationAnalysesRisquesParChargeAffaire.valeurs[i].percentage < realisationAnalysesRisquesParChargeAffaire.valeurs[i].seuilVert ? 'labelIndicatorOrangeLeftTop' : 'labelIndicatorGreenLeftTop'
											}
										]
									]
								},
								layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
							},
							{text: '', style: 'label'},
							{text: '', style: 'label'}
						);
					}
					bodyData.push(bodyRow);
				}
				content.push(
					{
						table: {
							dontBreakRows: true,
							widths: ['100%'],
							body: [
								[{
									table: {
										dontBreakRows: true,
										widths: ['48%', '4%', '48%'],
										body: bodyData
									},
									layout: this.getTableLayoutNoBorderNoPadding(), unbreakable: true
								}
								]
							]
						},
						layout: this.getTableLayoutBorderNoPadding(), unbreakable: true
					}
				);
			}

			const docDefinition = {
				info: this.getInfos('indicators', null),
				permissions: this.getPermissions(),
				footer: (currentPage, pageCount) => {
					return {
						table: {
							widths: ['100%'],
							body: [
								[
									{text: 'Page ' + currentPage + ' sur ' + pageCount, alignment: 'center'}
								]
							]
						},
						margin: [0, 10, 0, 0],
						layout: {defaultBorder: false}
					};
				},
				content: content,
				styles: this.getStyles(),
				defaultStyle: {
					font: 'Arial'
				}
			};
			this.pdfObj = pdfMake.createPdf(docDefinition);
			return {data: this.pdfObj, fileName: 'indicateurs_' + moment(datesRange[0]).format('MM-YYYY') + '_' + moment(datesRange[1]).format('MM-YYYY') + '.pdf'};
		} catch (e) {
			console.log(e);
			throw 'FORM_PDF';
		}
	}
}
