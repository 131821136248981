export class IndicateurObject {
	id: number;
	titre: string;
	valeur: number;
	total: number;
	objectif: number;
	valeurs: Array<IndicateurObject>;

	seuilOrange: number;
	seuilVert: number;

	percentage: number;

	constructor(id, titre = null, valeur = null, total = null, objectif = null, valeurs = []) {
		this.id = id;
		this.titre = titre;
		this.valeur = valeur;
		this.total = total;
		this.objectif = objectif;
		this.valeurs = [];
		for (const i in valeurs) {
			this.valeurs.push(new IndicateurObject(this.id, valeurs[i].titre, valeurs[i].valeur, valeurs[i].total, this.objectif));
		}

		if ([5, 14, 15, 17].includes(this.id)) {
			if (this.total != null && this.total !== 0) {
				this.percentage = Math.round((this.valeur / this.total) * 100);
			} else {
				this.total = 0;
			}
		} else {
			if (this.objectif != null && this.objectif !== 0) {
				this.percentage = Math.round((this.valeur / this.objectif) * 100);
			} else {
				this.percentage = 0;
			}
		}

		switch (id) {
			case 2 :
				this.seuilOrange = 80;
				this.seuilVert = 100;
				break;
			case 3 :
				this.seuilOrange = 80;
				this.seuilVert = 100;
				break;
			case 4 :
				this.seuilOrange = 75;
				this.seuilVert = 90;
				break;
			case 5 :
				this.seuilOrange = 80;
				this.seuilVert = 100;
				break;
			case 6 :
				this.seuilOrange = 80;
				this.seuilVert = 100;
				break;
			case 7 :
				this.seuilOrange = 80;
				this.seuilVert = 100;
				break;
			case 8 :
				this.seuilOrange = 4;
				this.seuilVert = 2;
				break;
			case 9 :
				this.seuilOrange = 4;
				this.seuilVert = 2;
				break;
			case 10 :
				this.seuilOrange = 7;
				this.seuilVert = 4;
				break;
			case 11 :
				this.seuilOrange = null;
				this.seuilVert = null;
				break;
			case 12 :
				this.seuilOrange = 4;
				this.seuilVert = 2;
				break;
			case 13 :
				this.seuilOrange = null;
				this.seuilVert = null;
				break;
			case 16 :
				this.seuilOrange = null;
				this.seuilVert = null;
				break;
			default :
				this.seuilOrange = null;
				this.seuilVert = null;
				break;
		}
	}
}
