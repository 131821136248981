import {NgModule, Injectable} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

import {OrderModule} from 'ngx-order-pipe';
import {NgStringPipesModule} from 'ngx-pipes';
import {IonicSelectableModule} from 'ionic-selectable';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import {FullCalendarModule} from '@fullcalendar/angular';

import {OwlDateTimeModule, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS, OwlDateTimeIntl} from '@danielmoncada/angular-datetime-picker';
import {OwlMomentDateTimeModule} from '@danielmoncada/angular-datetime-picker-moment-adapter';

export const MY_MOMENT_FORMATS = {
	parseInput: 'DD/MM/YYYY',
	fullPickerInput: 'ddd DD.MM.YYYY HH:mm',
	datePickerInput: 'ddd DD.MM.YYYY',
	timePickerInput: 'HH:mm',
	monthYearLabel: 'MMM YYYY',
	dateA11yLabel: 'LL',
	monthYearA11yLabel: 'MMMM YYYY'
};

import {PopoverActionsComponent} from './popovers/popover-actions/popover-actions.component';

@Injectable({
	providedIn: 'root'
})
export class DefaultIntl extends OwlDateTimeIntl {
	rangeFromLabel = 'Du';
	rangeToLabel = 'Au';
	cancelBtnLabel = 'Annuler';
	setBtnLabel = 'Ok';
}

import {UserComponent} from './components/user/user.component';
import {UserPopoverComponent} from './popovers/user-popover/user-popover.component';
import {ShowHidePasswordComponent} from './components/show-hide-password/show-hide-password.component';
import {PopoverAffaireTachePlanificationComponent} from './popovers/popover-affaire-tache-planification/popover-affaire-tache-planification.component';
import {VehiculeComponent} from './modals/forms/parametres/vehicule/vehicule.component';
import {PopoverPrimeComponent} from './popovers/popover-prime/popover-prime.component';

import {MomentDateFormatPipe} from './pipes/moment-date-format/moment-date-format';
import {FormStatusPipe} from './pipes/form-status/form-status';
import {TaskStatusPipe} from './pipes/task-status/task-status';
import {BooleanToNumberPipe} from './pipes/boolean-to-number/boolean-to-number';
import {FormCategoryPipe} from './pipes/form-category/form-category';
import {CurrencyFormatPipe} from './pipes/currency-format/currency-format';
import {ServicePipe} from './pipes/service/service';
import {RoundNumberPipe} from './pipes/round-number/round-number';

import {AutoResizeTextareaDirective} from './directives/autoresize-textarea.directive';

@NgModule({
	declarations: [
		MomentDateFormatPipe,
		FormStatusPipe,
		TaskStatusPipe,
		BooleanToNumberPipe,
		FormCategoryPipe,
		CurrencyFormatPipe,
		ServicePipe,
		RoundNumberPipe,
		UserComponent,
		UserPopoverComponent,
		ShowHidePasswordComponent,
		AutoResizeTextareaDirective,
		PopoverActionsComponent,
		PopoverAffaireTachePlanificationComponent,
		VehiculeComponent,
		PopoverPrimeComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		OwlDateTimeModule,
		OwlMomentDateTimeModule,
		InfiniteScrollModule,
		IonicSelectableModule
	],
	exports: [
		MomentDateFormatPipe,
		FormStatusPipe,
		TaskStatusPipe,
		BooleanToNumberPipe,
		FormCategoryPipe,
		CurrencyFormatPipe,
		ServicePipe,
		RoundNumberPipe,
		OrderModule,
		NgStringPipesModule,
		IonicSelectableModule,
		InfiniteScrollModule,
		UserComponent,
		UserPopoverComponent,
		ShowHidePasswordComponent,
		OwlDateTimeModule,
		OwlMomentDateTimeModule,
		FullCalendarModule,
		AutoResizeTextareaDirective,
		PopoverActionsComponent,
		PopoverAffaireTachePlanificationComponent,
		VehiculeComponent,
		PopoverPrimeComponent
	],
	providers: [
		{provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'},
		{provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
		{provide: OwlDateTimeIntl, useClass: DefaultIntl}
	]
})
export class SharedModule {
}
