import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {App} from '@capacitor/app';
import {Network} from '@capacitor/network';
import {Market} from '@ionic-native/market/ngx';
import packageInfo from '../../../package.json';

import {WebService} from './web.service';

@Injectable({
	providedIn: 'root'
})
export class VersionService {

	platformIsDesktop: boolean;

	constructor(private webService: WebService, private platform: Platform, private market: Market) {
		this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
	}

	async isAppVersionOutdated() {
		try {
			const networkStatus = await Network.getStatus();
			if (!networkStatus.connected) {
				return false;
			} else {
				const conf = {
					param_1: 'api',
					param_2: 'version'
				};
				const result: any = await this.webService.getAppRequiredVersion(conf);
				let minVersionRetrieved;
				let info;
				if (this.platformIsDesktop) {
					info = {
						version: packageInfo.version
					};
					minVersionRetrieved = result?.data?.configs?.WEB_VERSION;
				} else {
					info = await App.getInfo();
					if (this.platform.is('android')) {
						minVersionRetrieved = result?.data?.configs?.ANDROID_VERSION;
					} else {
						minVersionRetrieved = result?.data?.configs?.IOS_VERSION;
					}
				}
				if (info.version != null && info.version !== '' && minVersionRetrieved != null && minVersionRetrieved !== '') {
					const minVersionSplit = minVersionRetrieved.split('.');
					let minVersion = '';
					for (const i in minVersionSplit) {
						if (minVersionSplit[i].length === 1) {
							minVersion += '0' + minVersionSplit[i];
						} else {
							minVersion += minVersionSplit[i];
						}
					}
					const curVersionSplit = info.version.split('.');
					let curVersion = '';
					for (const i in curVersionSplit) {
						if (curVersionSplit[i].length === 1) {
							curVersion += '0' + curVersionSplit[i];
						} else {
							curVersion += curVersionSplit[i];
						}
					}
					return minVersion > curVersion;
				} else {
					return false;
				}
			}
		} catch (e) {
			return false;
		}
	}

	openMarket() {
		this.market.open(this.platform.is('android') ? 'fr.anura.stemi' : '1473808786');
	}

	openOldAppMarket() {
		this.market.open(this.platform.is('android') ? 'fr.anura.stemiold' : '6505000433');
	}
}
