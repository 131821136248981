import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LoadingController, NavParams, Platform, PopoverController} from '@ionic/angular';

import {AlertsService} from '../../services/alerts.service';
import {FormsService} from '../../services/forms.service';

import {AffaireTachePlanificationObject} from '../../objects/forms/commerce/affaire-tache-planification.object';
import {AffaireTacheObject} from '../../objects/forms/commerce/affaire-tache.object';
import {AffaireObject} from '../../objects/forms/commerce/affaire.object';
import {UserObject} from '../../objects/user.object';

import {AppUtils} from '../../app.utils';
import {AppConfig} from '../../app.config';

@Component({
	selector: 'app-popover-affaire-tache-planification',
	templateUrl: './popover-affaire-tache-planification.component.html',
	styleUrls: ['./popover-affaire-tache-planification.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class PopoverAffaireTachePlanificationComponent implements OnInit {

	platformIsDesktop: boolean;

	affaireTachePlanification: AffaireTachePlanificationObject;
	affaireTache: AffaireTacheObject;
	affaire: AffaireObject;
	users: Array<UserObject>;

	constructor(public platform: Platform, private navParams: NavParams, private popoverCtrl: PopoverController, private alertsService: AlertsService, private loadingCtrl: LoadingController, private formsService: FormsService) {
		this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
	}

	ngOnInit() {
		this.users = this.users.filter((user) => {
			return user.actif;
		});
		this.affaireTachePlanification = AffaireTachePlanificationObject.LoadFromJSON(this.navParams.get('affaireTachePlanificationToUpdate'));
	}

	async enregistrerAffaireTachePlanification() {
		const loader = await this.loadingCtrl.create({
			spinner: null,
			message: `<div class="spinner-custom"></div>`,
			cssClass: 'loader-stemi'
		});
		await loader.present();
		try {
			const result = await this.formsService.saveForm(this.affaireTachePlanification);
			this.affaireTachePlanification.id = result.id;
			await loader.dismiss();
			await this.closePopover(true, this.affaireTachePlanification);
		} catch (error) {
			await AppUtils.delay(AppConfig.waitAfterLoader);
			await loader.dismiss();
			if (error === 502 || error === 'TOKEN') {
				await this.alertsService.logoutAlert(this.alertsService.getErrorMessage(error));
			} else {
				await this.alertsService.errorAlert(null, this.alertsService.getErrorMessage(error));
			}
		}
	}

	async supprimerAffaireTachePlanification() {
		const confirmed = await this.alertsService.confirmationAlert(null, 'Voulez-vous vraiment supprimer cette planification ?', 'Supprimer');
		if (confirmed) {
			const loader = await this.loadingCtrl.create({
				spinner: null,
				message: `<div class="spinner-custom"></div>`,
				cssClass: 'loader-stemi'
			});
			await loader.present();
			try {
				await this.formsService.deleteForms([this.affaireTachePlanification]);
				await loader.dismiss();
				await this.closePopover(true, this.affaireTachePlanification);
			} catch (error) {
				await loader.dismiss();
				await this.alertsService.errorAlert(null, this.alertsService.getErrorMessage(error));
			}
		}
	}

	async closePopover(refresh = false, affaireTachePlanification = null) {
		await this.popoverCtrl.dismiss({refresh: refresh, affaireTachePlanification: affaireTachePlanification});
	}
}
