import {FileService} from '../../../services/file.service';

import {UserObject} from '../../user.object';
import * as moment from 'moment/moment';

export class FicheQualiteObject {
	id: number;
	table = 'fiche_qualite';
	titre = 'Fiche qualité';
	createdAt: string;
	updatedAt: string;
	status: string;
	createdBy: UserObject;
	updatedBy: UserObject;

	// Step 1

	id_affaire: number;

	// Step 2

	nom_client: string;
	code_client: string;
	nom_affaire: string;
	charge_affaire: string;
	photos: Array<any>;
	commentaire: string;

	// Step 2 missing

	photos_missing: boolean;
	commentaire_missing: boolean;

	// Step 3

	actions_correctives: string;
	id_resp_tech: UserObject;
	date_signature_resp_tech: string;

	// Step 3 missing

	actions_correctives_missing: boolean;

	// Step 4

	suivi_actions: string;
	date_signature_resp_tech_2: string;

	// Step 3 missing

	suivi_actions_missing: boolean;

	// Status

	// draft
	// signed
	// validate
	// closed

	constructor(
		id = null,
		createdAt = null,
		updatedAt = null,
		status = 'draft',
		createdBy = null,
		updatedBy = null,
		// Step 1

		id_affaire = null,
		// Step 2

		nom_client = null,
		code_client = null,
		charge_affaire = null,
		nom_affaire = null,
		photos = null,
		commentaire = null,
		// Step 3

		actions_correctives = null,
		id_resp_tech = null,
		date_signature_resp_tech = null,
		// Step 4

		suivi_actions = null,
		date_signature_resp_tech_2 = null
	) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;

		// Step 1

		this.id_affaire = id_affaire;

		// Step 2

		this.nom_client = nom_client;
		this.code_client = code_client;
		this.charge_affaire = charge_affaire;
		this.nom_affaire = nom_affaire;
		this.photos = photos;
		this.commentaire = commentaire;

		// Step 2 missing

		this.photos_missing = false;
		this.commentaire_missing = false;

		// Step 3

		this.actions_correctives = actions_correctives;
		this.id_resp_tech = id_resp_tech;
		this.date_signature_resp_tech = date_signature_resp_tech;

		// Step 3 missing

		this.actions_correctives_missing = false;

		// Step 4

		this.suivi_actions = suivi_actions;
		this.date_signature_resp_tech_2 = date_signature_resp_tech_2;

		// Step 4 missing

		this.suivi_actions_missing = false;
	}

	missingMandatoryFields(step) {
		let missingMandatory = false;
		const missingDescriptions = [];
		switch (step) {
			case 2 :
				if (this.photos == null || this.photos.length === 0) {
					this.photos_missing = true;
					missingMandatory = true;
				}
				if (this.commentaire == null || this.commentaire === '') {
					this.commentaire_missing = true;
					missingMandatory = true;
				}
				break;
			case 3:
				if (this.actions_correctives === null || this.actions_correctives === '') {
					this.actions_correctives_missing = true;
					missingMandatory = true;
				}
				break;
			case 4:
				if (this.suivi_actions === null || this.suivi_actions === '') {
					this.suivi_actions_missing = true;
					missingMandatory = true;
				}
				break;
			default:
				break;
		}
		return {missingMandatory: missingMandatory, missingDescriptions: missingDescriptions};
	}

	updateMissing(source) {
		switch (source) {
			case 'photos':
				if (this.photos != null && this.photos.length > 0) {
					this.photos_missing = false;
				}
				break;
			case 'commentaire':
				if (this.commentaire != null && this.commentaire !== '') {
					this.commentaire_missing = false;
				}
				break;
			case 'actions_correctives':
				if (this.actions_correctives != null && this.actions_correctives !== '') {
					this.actions_correctives_missing = false;
				}
				break;
			case 'suivi_actions':
				if (this.suivi_actions != null && this.suivi_actions !== '') {
					this.suivi_actions_missing = false;
				}
				break;
			default:
				break;
		}
	}

	getFormData() {
		const formData = new FormData();

		if (this.id != null) {
			formData.append('id', JSON.stringify(this.id));
		}
		if (this.status != null) {
			formData.append('status', this.status);
		}

		// Step 1

		if (this.id_affaire != null) {
			formData.append('id_affaire', JSON.stringify(Number(this.id_affaire)));
		}

		// Step 2

		for (const i in this.photos) {
			const curPhoto = this.photos[i];
			if (curPhoto.type === 'local') {
				formData.append('photos[]', FileService.dataURItoBlob(curPhoto.preview), curPhoto.fileName);
			}
		}

		if (this.commentaire != null) {
			formData.append('commentaire', this.commentaire);
		}

		// Step 3

		if (this.actions_correctives != null) {
			formData.append('actions_correctives', this.actions_correctives);
		}
		if (this.id_resp_tech != null) {
			formData.append('id_resp_tech', JSON.stringify(this.id_resp_tech.id));
		}
		if (this.date_signature_resp_tech != null) {
			formData.append('date_signature_resp_tech', moment(this.date_signature_resp_tech).format());
		}

		// Step 4

		if (this.suivi_actions != null) {
			formData.append('suivi_actions', this.suivi_actions);
		}
		if (this.date_signature_resp_tech_2 != null) {
			formData.append('date_signature_resp_tech_2', moment(this.date_signature_resp_tech_2).format());
		}

		return formData;
	}
}
