import * as moment from 'moment';

import {UserObject} from '../../user.object';
import {AffaireTachePlanificationObject} from './affaire-tache-planification.object';

export class AffaireTacheObject {
	id: number;
	table = 'affaire_tache';
	createdAt: string;
	updatedAt: string;
	createdBy: UserObject;
	updatedBy: UserObject;
	id_affaire: number;
	service: string;
	nom: string;
	observations: string;
	delai: string;
	heures_prevues: number;
	approvisionnement: string;

	planifications: Array<AffaireTachePlanificationObject>;

	constructor(id = null, createdAt = null, updatedAt = null, createdBy = null, updatedBy = null, id_affaire = null, service = null, nom = null, observations = null, delai = null, heures_prevues = null,
				approvisionnement = null, planifications = []) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;
		this.id_affaire = id_affaire;
		this.service = service;
		this.nom = nom;
		this.observations = observations;
		this.delai = delai;
		this.heures_prevues = heures_prevues;
		this.approvisionnement = approvisionnement;

		this.planifications = planifications;
	}

	public static LoadFromJSON(json: any) {
		return new AffaireTacheObject(
			json['id'],
			json['createdAt'],
			json['updatedAt'],
			json['createdBy'] ? UserObject.LoadFromJSON(json['createdBy']) : null,
			json['updatedBy'] ? UserObject.LoadFromJSON(json['updatedBy']) : null,
			json['id_affaire'],
			json['service'],
			json['nom'],
			json['observations'],
			json['delai'],
			json['heures_prevues'],
			json['approvisionnement'],
			this.LoadFromArray(json['planifications'], 'planifications')
		);
	}

	public static LoadFromArray(arr: any[], type) {
		const ret = [];
		if (arr) {
			for (let i = 0; i < arr.length; i++) {
				switch (type) {
					case 'planifications':
						ret.push(AffaireTachePlanificationObject.LoadFromJSON(arr[i]));
						break;
				}
			}
		}
		return ret;
	}

	getFormData() {
		return {
			id_affaire: this.id_affaire,
			service: this.service,
			nom: this.nom,
			observations: this.observations,
			delai: this.delai ? moment(this.delai).format('YYYY-MM-DD') : null,
			heures_prevues: this.heures_prevues,
			approvisionnement: this.approvisionnement
		};
	}

	getHeuresPlanifiees() {
		let heuresPlanifiees = 0;
		for (const i in this.planifications) {
			heuresPlanifiees += this.planifications[i].getHeuresPlanifiees();
		}
		return Math.round(heuresPlanifiees * 100) / 100;
	}

	getHeuresRestantes() {
		let heuresRestantes = 0;
		if (this.heures_prevues !== null) {
			heuresRestantes = this.heures_prevues - this.getHeuresPlanifiees();
		}
		return Math.round(heuresRestantes * 100) / 100;
	}
}
