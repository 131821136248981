import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'taskStatus',
})
export class TaskStatusPipe implements PipeTransform {

	transform(value: string, table: string) {
		switch (table) {

			case 'task_demande_travaux' :
				switch (value) {
					case 'inprogress' :
						return 'En cours';
					case 'finished' :
						return 'Terminé';
					default :
						return 'Inconnu';
				}
			case 'task_ouverture_affaire' :
				switch (value) {
					case 'inprogress' :
						return 'En cours';
					case 'finished' :
						return 'Terminée';
					default :
						return 'Inconnu';
				}
			default :
				return 'Inconnu';
		}
	}
}
