import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {NavController, Platform} from '@ionic/angular';
import {Browser} from '@capacitor/browser';
import {EmailComposer} from '@ionic-native/email-composer/ngx';

import {Events} from './events.service';
import {AlertsService} from './alerts.service';
import {VersionService} from './version.service';

import {AppUtils} from '../app.utils';

import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class RedirectService {

	platformIsDesktop: boolean;

	constructor(private navCtrl: NavController, private events: Events, private platform: Platform, private emailComposer: EmailComposer, private alertsService: AlertsService, private versionService: VersionService, private router: Router, private titleService: Title) {
		this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
	}

	async goTo(navCtrl: NavController, url, params, direction, newWindow = false) {
		if (newWindow) {
			url = environment.url_front + url;
			await Browser.open({url: url, windowName: '_blank'});
		} else {
			const navigated = await navCtrl.navigateRoot(url, {queryParams: params, animated: true, animationDirection: direction, skipLocationChange: true});
			if (navigated) {
				this.pageSelected(url, params);
			}
		}
	}

	async push(navCtrl, url, params) {
		this.pageSelected(url, params);
		await navCtrl.navigateForward(url, {queryParams: params, animate: true, skipLocationChange: true});
	}

	async goBack(navCtrl) {
		const url = this.router.getCurrentNavigation().previousNavigation.initialUrl.toString();
		this.pageSelected(url);
		await navCtrl.navigateBack(url, {animate: true, skipLocationChange: true});
	}

	async composeMail(to, subject, body) {
		if (this.platformIsDesktop) {
			await AppUtils.delay(1000);
			let url = '';
			if (to) {
				url += 'mailto:' + to;
			} else {
				url += 'mailto:';
			}
			url += '?subject=' + subject + '&';
			url += 'body=' + body;
			await Browser.open({url: encodeURI(url), windowName: '_system'});
		} else {
			try {
				await AppUtils.delay(500);
				await this.emailComposer.open({
					to: to,
					subject: subject,
					body: body
				});
			} catch (e) {
				await this.alertsService.errorAlert(null, 'Erreur lors de la composition de l\'email.');
			}
		}
	}

	goToMyStemiOld() {
		if (this.platformIsDesktop) {
			window.open('https://mystemi-old.stemi.com', '_system');
		} else {
			this.versionService.openOldAppMarket();
		}
	}

	pageSelected(url, params?) {
		let pageId = null;
		let title = 'MySTEMI';
		switch (url) {
			case '/home':
				pageId = 0;
				title = 'Tableau de bord';
				break;
			case '/actus':
				pageId = 1;
				title = 'Fil d\'actualité';
				break;
			case '/infos':
				pageId = 2;
				title = 'Bibliothèque';
				break;
			case '/chiffrages':
			case '/chiffrage':
				pageId = 3;
				title = 'Chiffrages';
				break;
			case '/affaires':
			case '/affaire':
				pageId = 4;
				title = 'Affaires';
				break;
			case '/planning-general':
				pageId = 5;
				title = 'Planning général';
				break;
			case '/planning-affaires':
				pageId = 11;
				title = 'Planning des affaires';
				break;
			case '/planning-service':
				switch (params?.viewService) {
					case 'etude-elec' :
						pageId = 12;
						title = 'Planning BE Elec';
						break;
					case 'etude-auto' :
						pageId = 13;
						title = 'Planning BE Autom';
						break;
					case 'atelier' :
						pageId = 14;
						title = 'Planning Atelier';
						break;
					case 'chantier' :
						pageId = 15;
						title = 'Planning Chantier';
						break;
				}
				break;
			case '/planning-vehicules':
				pageId = 16;
				title = 'Planning des véhicules';
				break;
			case '/tasks-list':
			case 'task-demande-travaux-desktop':
				pageId = 6;
				title = 'Tâches';
				break;
			case '/forms-list':
			case '/new-form':
			case '/accueil-securite-desktop':
			case '/analyse-risques-desktop':
			case '/demande-travaux-desktop':
			case '/inspection-chantier-desktop':
			case '/rapport-accident-desktop':
			case '/pv-reception-desktop':
			case '/cr-intervention-desktop':
			case '/fiche-qualite-desktop':
			case '/demande-absence-desktop':
			case '/consigne-harnais-desktop':
			case '/ouverture-affaire-desktop':
			case '/plan-prevention-desktop':
				pageId = 7;
				title = 'Formulaires';
				break;
			case '/indicators':
				pageId = 8;
				title = 'Indicateurs';
				break;
			case '/users':
				pageId = 9;
				title = 'Utilisateurs';
				break;
			case '/vehicules':
			case '/vehicule':
				pageId = 10;
				title = 'Véhicules';
				break;
		}
		this.events.publish('pageSelected', pageId);
		this.titleService.setTitle(title);
	}

	toggleFullScreen() {
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	}
}
