import {FileService} from '../../../services/file.service';

import {UserObject} from '../../user.object';

import * as moment from 'moment';

export class RapportIncidentObject {

	id: number;
	table = 'rapport_incident';
	titre = 'Rapport d’incident, accident, Situation dangereuse';
	createdAt: string;
	updatedAt: string;
	status: string;
	createdBy: UserObject;
	updatedBy: UserObject;

	// Step 1

	id_affaire: number;

	// Step 2

	nom_client: string;
	code_client: string;
	nom_affaire: string;
	nature_evenement: string;

	// Step 2 missing

	nature_evenement_missing: boolean;

	// Step 3

	id_victime: UserObject;
	type_contrat_victime: string;
	autres_personnes: string;
	arret_travail: string;
	arret_travail_nb_jours: number;
	id_personne_concernee: UserObject;
	type_contrat_personne_concernee: string;
	autres_personnes_concernees: string;
	autres_personnes_concernees_noms_prenoms: string;
	date_evenement: string;
	cause_tier: string;
	temoins: string;
	temoins_noms: Array<UserObject>;
	temoins_autre: string;
	description: string;
	photos: Array<any>;
	premiers_soins: boolean;
	premiers_soins_qui: string;
	appel_secours: boolean;
	evacuation: boolean;
	evacuation_qui: string;
	evacuation_ou: string;
	horaires_effectuees: { start: string, end: string };
	lieu_accident: string;

	// Step 3 missing

	id_victime_missing: boolean;
	type_contrat_victime_missing: boolean;
	autres_personnes_missing: boolean;
	arret_travail_missing: boolean;
	arret_travail_nb_jours_missing: boolean;
	id_personne_concernee_missing: boolean;
	type_contrat_personne_concernee_missing: boolean;
	autres_personnes_concernees_missing: boolean;
	autres_personnes_concernees_noms_prenoms_missing: boolean;
	date_evenement_missing: boolean;
	cause_tier_missing: boolean;
	temoins_missing: boolean;
	temoins_noms_missing: boolean;
	temoins_autre_missing: boolean;
	description_missing: boolean;
	premiers_soins_qui_missing: boolean;
	evacuation_qui_missing: boolean;
	evacuation_ou_missing: boolean;
	horaires_effectuees_missing_start: boolean;
	horaires_effectuees_missing_end: boolean;
	horaires_effectuees_missmatche: boolean;
	lieu_accident_missing: boolean;

	// Step 4

	image_corps_face: any;
	image_corps_dos: any;
	image_visage: any;
	image_main: any;
	image_pied: any;
	image_corps_face_defaut: string;
	image_corps_dos_defaut: string;
	image_visage_defaut: string;
	image_main_defaut: string;
	image_pied_defaut: string;
	lesions_precisions: string;
	nature_lesions_plaie: boolean;
	nature_lesions_entorse: boolean;
	nature_lesions_hematome: boolean;
	nature_lesions_asphyxie: boolean;
	nature_lesions_brulure: boolean;
	nature_lesions_fracture: boolean;
	nature_lesions_douleurs: boolean;
	nature_lesions_malaise: boolean;
	nature_lesions_autre: boolean;
	nature_lesions_precisions: string;
	vehicule_dos: any;
	vehicule_face: any;
	vehicule_droite: any;
	vehicule_gauche: any;
	vehicule_dos_defaut: string;
	vehicule_face_defaut: string;
	vehicule_droite_defaut: string;
	vehicule_gauche_defaut: string;
	dommages_precisions: string;

	// Step 4 missing

	nature_lesions_precisions_missing: boolean;

	// Step 5

	date_signature: string;
	date_signature_victime: string;
	date_signature_personne_concernee: string;
	temoins_signatures: Array<string>;

	// Step 6

	analyse_cause: string;
	date_analyse: string;
	causes_descriptions: string;
	causes_pieces_jointes: Array<any>;
	prevention_immediates_description: string;
	prevention_immediates_par: UserObject;
	prevention_immediates_par_autre: string;
	prevention_correctives_description: string;
	prevention_correctives_par: UserObject;
	prevention_correctives_par_autre: string;

	// Step 6 missing

	analyse_cause_missing: boolean;
	date_analyse_missing: boolean;
	causes_descriptions_missing: boolean;
	prevention_immediates_description_missing: boolean;
	prevention_immediates_par_missing: boolean;
	prevention_immediates_par_autre_missing: boolean;
	prevention_correctives_description_missing: boolean;
	prevention_correctives_par_missing: boolean;
	prevention_correctives_par_autre_missing: boolean;

	// Step 7

	date_signature_victime_2: string;
	date_signature_personne_concernee_2: string;
	temoins_signatures_2: Array<string>;
	id_hse: UserObject;
	date_signature_hse: string;
	id_chef_chantier: UserObject;
	date_signature_chantier: string;
	id_charge_affaire: UserObject;
	date_signature_affaire: string;
	personnes_supplementaires: string;

	// Step 8

	prevention_correctives_date_prevue: string;
	prevention_correctives_date_reel: string;

	// Step 8 missing

	prevention_correctives_date_prevue_missing: boolean;
	prevention_correctives_date_reel_missing: boolean;

	// Step 9

	date_signature_hse_2: string;

	// Status

	// draft
	// signed
	// validate
	// closed

	constructor(
		id = null,
		createdAt = null,
		updatedAt = null,
		status = 'draft',
		createdBy = null,
		updatedBy = null,
		// Step 1

		id_affaire = null,
		// Step 2

		nom_client = null,
		code_client = null,
		nom_affaire = null,
		nature_evenement = null,
		// Step 3

		id_victime = null,
		type_contrat_victime = null,
		autres_personnes = null,
		arret_travail = null,
		arret_travail_nb_jours = null,
		id_personne_concernee = null,
		type_contrat_personne_concernee = null,
		autres_personnes_concernees = null,
		autres_personnes_concernees_noms_prenoms = null,
		date_evenement = null,
		cause_tier = null,
		temoins = null,
		temoins_noms = null,
		temoins_autre = null,
		description = null,
		photos = null,
		premiers_soins = false,
		premiers_soins_qui = null,
		appel_secours = false,
		evacuation = false,
		evacuation_qui = null,
		evacuation_ou = null,
		horaires_effectuees = {start: null, end: null},
		lieu_accident = null,
		// Step 4

		image_corps_face = {type: 'defaut', data: {url: './assets/imgs/image_corps_face.svg'}},
		image_corps_dos = {type: 'defaut', data: {url: './assets/imgs/image_corps_dos.svg'}},
		image_visage = {type: 'defaut', data: {url: './assets/imgs/image_visage.svg'}},
		image_main = {type: 'defaut', data: {url: './assets/imgs/image_main.svg'}},
		image_pied = {type: 'defaut', data: {url: './assets/imgs/image_pied.svg'}},
		lesions_precisions = null,
		nature_lesions_plaie = false,
		nature_lesions_entorse = false,
		nature_lesions_hematome = false,
		nature_lesions_asphyxie = false,
		nature_lesions_brulure = false,
		nature_lesions_fracture = false,
		nature_lesions_douleurs = false,
		nature_lesions_malaise = false,
		nature_lesions_autre = false,
		nature_lesions_precisions = null,
		vehicule_dos = {type: 'defaut', data: {url: './assets/imgs/vehicule_dos.svg'}},
		vehicule_face = {type: 'defaut', data: {url: './assets/imgs/vehicule_face.svg'}},
		vehicule_droite = {type: 'defaut', data: {url: './assets/imgs/vehicule_droite.svg'}},
		vehicule_gauche = {type: 'defaut', data: {url: './assets/imgs/vehicule_gauche.svg'}},
		dommages_precisions = null,
		// Step 5

		date_signature = null,
		date_signature_victime = null,
		date_signature_personne_concernee = null,
		temoins_signatures = [],
		// Step 6

		analyse_cause = null,
		date_analyse = null,
		causes_descriptions = null,
		causes_pieces_jointes = null,
		prevention_immediates_description = null,
		prevention_immediates_par = null,
		prevention_immediates_par_autre = null,
		prevention_correctives_description = null,
		prevention_correctives_par = null,
		prevention_correctives_par_autre = null,
		prevention_correctives_date_prevue = null,
		// Step 7

		date_signature_victime_2 = null,
		date_signature_personne_concernee_2 = null,
		temoins_signatures_2 = [],
		id_hse = null,
		date_signature_hse = null,
		id_chef_chantier = null,
		date_signature_chantier = null,
		id_charge_affaire = null,
		date_signature_affaire = null,
		personnes_supplementaires = null,
		// Step 8

		prevention_correctives_date_reel = null,
		// Step 9

		date_signature_hse_2 = null
	) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;

		// Step 1

		this.id_affaire = id_affaire;

		// Step 2

		this.nom_client = nom_client;
		this.code_client = code_client;
		this.nom_affaire = nom_affaire;
		this.nature_evenement = nature_evenement;

		// Step 2 missing

		this.nature_evenement_missing = false;

		// Step 3

		this.id_victime = id_victime;
		this.type_contrat_victime = type_contrat_victime;
		this.autres_personnes = autres_personnes;
		this.arret_travail = arret_travail;
		this.arret_travail_nb_jours = arret_travail_nb_jours;
		this.id_personne_concernee = id_personne_concernee;
		this.type_contrat_personne_concernee = type_contrat_personne_concernee;
		this.autres_personnes_concernees = autres_personnes_concernees;
		this.autres_personnes_concernees_noms_prenoms = autres_personnes_concernees_noms_prenoms;
		this.date_evenement = date_evenement;
		this.cause_tier = cause_tier;
		this.temoins = temoins;
		this.temoins_noms = temoins_noms;
		this.temoins_autre = temoins_autre;
		this.description = description;
		this.photos = photos;
		this.premiers_soins = premiers_soins;
		this.premiers_soins_qui = premiers_soins_qui;
		this.appel_secours = appel_secours;
		this.evacuation = evacuation;
		this.evacuation_qui = evacuation_qui;
		this.evacuation_ou = evacuation_ou;
		this.horaires_effectuees = horaires_effectuees;
		this.lieu_accident = lieu_accident;

		// Step 3 missing

		this.id_victime_missing = false;
		this.type_contrat_victime_missing = false;
		this.autres_personnes_missing = false;
		this.arret_travail_missing = false;
		this.arret_travail_nb_jours_missing = false;
		this.id_personne_concernee_missing = false;
		this.type_contrat_personne_concernee_missing = false;
		this.autres_personnes_concernees_missing = false;
		this.autres_personnes_concernees_noms_prenoms_missing = false;
		this.date_evenement_missing = false;
		this.cause_tier_missing = false;
		this.temoins_missing = false;
		this.temoins_noms_missing = false;
		this.temoins_autre_missing = false;
		this.description_missing = false;
		this.premiers_soins_qui_missing = false;
		this.evacuation_qui_missing = false;
		this.evacuation_ou_missing = false;
		this.horaires_effectuees_missing_start = false;
		this.horaires_effectuees_missing_end = false;
		this.horaires_effectuees_missmatche = false;
		this.lieu_accident_missing = false;

		// Step 4

		this.image_corps_face = image_corps_face;
		this.image_corps_dos = image_corps_dos;
		this.image_visage = image_visage;
		this.image_main = image_main;
		this.image_pied = image_pied;
		this.image_corps_face_defaut = './assets/imgs/image_corps_face.svg';
		this.image_corps_dos_defaut = './assets/imgs/image_corps_dos.svg';
		this.image_visage_defaut = './assets/imgs/image_visage.svg';
		this.image_main_defaut = './assets/imgs/image_main.svg';
		this.image_pied_defaut = './assets/imgs/image_pied.svg';
		this.lesions_precisions = lesions_precisions;
		this.nature_lesions_plaie = nature_lesions_plaie;
		this.nature_lesions_entorse = nature_lesions_entorse;
		this.nature_lesions_hematome = nature_lesions_hematome;
		this.nature_lesions_asphyxie = nature_lesions_asphyxie;
		this.nature_lesions_brulure = nature_lesions_brulure;
		this.nature_lesions_fracture = nature_lesions_fracture;
		this.nature_lesions_douleurs = nature_lesions_douleurs;
		this.nature_lesions_malaise = nature_lesions_malaise;
		this.nature_lesions_autre = nature_lesions_autre;
		this.nature_lesions_precisions = nature_lesions_precisions;
		this.vehicule_dos = vehicule_dos;
		this.vehicule_face = vehicule_face;
		this.vehicule_droite = vehicule_droite;
		this.vehicule_gauche = vehicule_gauche;
		this.vehicule_dos_defaut = './assets/imgs/vehicule_dos.svg';
		this.vehicule_face_defaut = './assets/imgs/vehicule_face.svg';
		this.vehicule_droite_defaut = './assets/imgs/vehicule_droite.svg';
		this.vehicule_gauche_defaut = './assets/imgs/vehicule_gauche.svg';
		this.dommages_precisions = dommages_precisions;

		// Step 4 missing

		this.nature_lesions_precisions_missing = false;

		// Step 5

		this.date_signature = date_signature;
		this.date_signature_victime = date_signature_victime;
		this.date_signature_personne_concernee = date_signature_personne_concernee;
		this.temoins_signatures = temoins_signatures;

		// Step 6

		this.analyse_cause = analyse_cause;
		this.date_analyse = date_analyse;
		this.causes_descriptions = causes_descriptions;
		this.causes_pieces_jointes = causes_pieces_jointes;
		this.prevention_immediates_description = prevention_immediates_description;
		this.prevention_immediates_par = prevention_immediates_par;
		this.prevention_immediates_par_autre = prevention_immediates_par_autre;
		this.prevention_correctives_description = prevention_correctives_description;
		this.prevention_correctives_par = prevention_correctives_par;
		this.prevention_correctives_par_autre = prevention_correctives_par_autre;

		// Step 6 missing

		this.analyse_cause_missing = false;
		this.date_analyse_missing = false;
		this.causes_descriptions_missing = false;
		this.prevention_immediates_description_missing = false;
		this.prevention_immediates_par_missing = false;
		this.prevention_immediates_par_autre_missing = false;
		this.prevention_correctives_description_missing = false;
		this.prevention_correctives_par_missing = false;
		this.prevention_correctives_par_autre_missing = false;

		// Step 7

		this.date_signature_victime_2 = date_signature_victime_2;
		this.date_signature_personne_concernee_2 = date_signature_personne_concernee_2;
		this.temoins_signatures_2 = temoins_signatures_2;
		this.id_hse = id_hse;
		this.date_signature_hse = date_signature_hse;
		this.id_chef_chantier = id_chef_chantier;
		this.date_signature_chantier = date_signature_chantier;
		this.id_charge_affaire = id_charge_affaire;
		this.date_signature_affaire = date_signature_affaire;
		this.personnes_supplementaires = personnes_supplementaires;

		// Step 8

		this.prevention_correctives_date_prevue = prevention_correctives_date_prevue;
		this.prevention_correctives_date_reel = prevention_correctives_date_reel;

		// Step 8 missing

		this.prevention_correctives_date_prevue_missing = false;
		this.prevention_correctives_date_reel_missing = false;

		// Step 9

		this.date_signature_hse_2 = date_signature_hse_2;
	}

	clearRelated(source) {
		switch (source) {
			case 'nature_evenement' :
				if (this.nature_evenement !== 'Accident') {
					this.id_victime = null;
					this.type_contrat_victime = null;
					this.autres_personnes = null;
					this.arret_travail = null;
					this.arret_travail_nb_jours = null;
				} else {
					this.id_personne_concernee = null;
					this.type_contrat_personne_concernee = null;
					this.autres_personnes_concernees = null;
					this.autres_personnes_concernees_noms_prenoms = null;
					this.horaires_effectuees = {start: null, end: null};
					this.lieu_accident = null;
				}
				break;
			case 'arret_travail' :
				if (this.arret_travail === 'Non') {
					this.arret_travail_nb_jours = null;
				}
				break;
			case 'autres_personnes_concernees' :
				if (this.autres_personnes_concernees === 'Non') {
					this.autres_personnes_concernees_noms_prenoms = null;
				}
				break;
			case 'temoins' :
				if (this.temoins === 'Non') {
					this.temoins_noms = null;
					this.temoins_autre = null;
				}
				break;
			case 'premiers_soins' :
				if (!this.premiers_soins) {
					this.premiers_soins_qui = null;
				}
				break;
			case 'evacuation' :
				if (!this.evacuation) {
					this.evacuation_qui = null;
					this.evacuation_ou = null;
				}
				break;
			case 'nature_lesions_autre' :
				if (!this.nature_lesions_autre) {
					this.nature_lesions_precisions = null;
				}
				break;
			case 'analyse_cause':
				if (this.analyse_cause === 'Non') {
					this.date_analyse = null;
					this.causes_descriptions = null;
					this.causes_pieces_jointes = null;
					this.prevention_correctives_description = null;
					this.prevention_correctives_par = null;
					this.prevention_correctives_par_autre = null;
					this.prevention_correctives_date_prevue = null;
				}
				break;
			default :
				break;
		}
	}

	missingMandatoryFields(step) {
		let missingMandatory = false;
		switch (step) {
			case 2 :
				if (this.nature_evenement == null) {
					this.nature_evenement_missing = true;
					missingMandatory = true;
				}
				break;
			case 3 :
				if (this.nature_evenement === 'Accident') {
					if (this.id_victime == null) {
						this.id_victime_missing = true;
						missingMandatory = true;
					}
					if (this.type_contrat_victime == null) {
						this.type_contrat_victime_missing = true;
						missingMandatory = true;
					}
					if (this.autres_personnes == null) {
						this.autres_personnes_missing = true;
						missingMandatory = true;
					}
					if (this.arret_travail == null) {
						this.arret_travail_missing = true;
						missingMandatory = true;
					} else if (this.arret_travail === 'Oui') {
						if (this.arret_travail_nb_jours == null) {
							this.arret_travail_nb_jours_missing = true;
							missingMandatory = true;
						}
					}
					if (this.horaires_effectuees == null) {
						this.horaires_effectuees_missing_start = true;
						this.horaires_effectuees_missing_end = true;
						missingMandatory = true;
					} else {
						if (this.horaires_effectuees.start == null) {
							this.horaires_effectuees_missing_start = true;
							missingMandatory = true;
						}
						if (this.horaires_effectuees.end == null) {
							this.horaires_effectuees_missing_end = true;
							missingMandatory = true;
						}
						if (this.horaires_effectuees.start != null && this.horaires_effectuees.end != null) {
							if (this.horaires_effectuees.start > this.horaires_effectuees.end) {
								this.horaires_effectuees_missmatche = true;
								missingMandatory = true;
							}
						}
					}
					if (this.lieu_accident == null || this.lieu_accident === '') {
						this.lieu_accident_missing = true;
						missingMandatory = true;
					}
				}
				if (this.nature_evenement !== 'Accident') {
					if (this.id_personne_concernee == null) {
						this.id_personne_concernee_missing = true;
						missingMandatory = true;
					}
					if (this.type_contrat_personne_concernee == null) {
						this.type_contrat_personne_concernee_missing = true;
						missingMandatory = true;
					}
					if (this.nature_evenement === 'Soin' && this.autres_personnes_concernees == null) {
						this.autres_personnes_concernees_missing = true;
						missingMandatory = true;
					} else if (this.nature_evenement === 'Soin' && this.autres_personnes_concernees === 'Oui') {
						if (this.autres_personnes_concernees_noms_prenoms == null) {
							this.autres_personnes_concernees_noms_prenoms_missing = true;
							missingMandatory = true;
						}
					}
				}
				if (this.date_evenement == null) {
					this.date_evenement_missing = true;
					missingMandatory = true;
				}
				if ((this.nature_evenement === 'Accident' || this.nature_evenement === 'Soin') && (this.cause_tier == null || this.cause_tier === '')) {
					this.cause_tier_missing = true;
					missingMandatory = true;
				}
				if ((this.nature_evenement === 'Accident' || this.nature_evenement === 'Soin') && this.temoins == null) {
					this.temoins_missing = true;
					missingMandatory = true;
				} else if ((this.nature_evenement === 'Accident' || this.nature_evenement === 'Soin') && this.temoins === 'Oui') {
					if ((this.temoins_noms == null || this.temoins_noms.length === 0) && (this.temoins_autre == null || this.temoins_autre === '')) {
						missingMandatory = true;
						if (this.temoins_noms == null || this.temoins_noms.length === 0) {
							this.temoins_noms_missing = true;
						}
						if (this.temoins_autre == null || this.temoins_autre === '') {
							this.temoins_autre_missing = true;
						}
					}
				}
				if (this.description == null) {
					this.description_missing = true;
					missingMandatory = true;
				}
				if (this.premiers_soins) {
					if (this.premiers_soins_qui == null) {
						this.premiers_soins_qui_missing = true;
						missingMandatory = true;
					}
				}
				if (this.evacuation) {
					if (this.evacuation_qui == null) {
						this.evacuation_qui_missing = true;
						missingMandatory = true;
					}
					if (this.evacuation_ou == null) {
						this.evacuation_ou_missing = true;
						missingMandatory = true;
					}
				}
				break;
			case 4 :
				if (this.nature_lesions_autre) {
					if (this.nature_lesions_precisions == null) {
						this.nature_lesions_precisions_missing = true;
						missingMandatory = true;
					}
				}
				if (this.prevention_immediates_description == null) {
					this.prevention_immediates_description_missing = true;
					missingMandatory = true;
				}
				if (this.prevention_immediates_par == null && (this.prevention_immediates_par_autre == null || this.prevention_immediates_par_autre === '')) {
					missingMandatory = true;
					if (this.prevention_immediates_par == null) {
						this.prevention_immediates_par_missing = true;
					}
					if (this.prevention_immediates_par_autre == null || this.prevention_immediates_par_autre === '') {
						this.prevention_immediates_par_autre_missing = true;
					}
				}
				break;
			case 6 :
				if (this.analyse_cause == null) {
					this.analyse_cause_missing = true;
					missingMandatory = true;
				}
				if (this.analyse_cause === 'Oui') {
					if (this.date_analyse == null) {
						this.date_analyse_missing = true;
						missingMandatory = true;
					}
					if (this.causes_descriptions == null) {
						this.causes_descriptions_missing = true;
						missingMandatory = true;
					}
					if (this.prevention_correctives_description == null) {
						this.prevention_correctives_description_missing = true;
						missingMandatory = true;
					}
					if (this.prevention_correctives_par == null && (this.prevention_correctives_par_autre == null || this.prevention_correctives_par_autre === '')) {
						missingMandatory = true;
						if (this.prevention_correctives_par == null) {
							this.prevention_correctives_par_missing = true;
						}
						if (this.prevention_correctives_par_autre == null || this.prevention_correctives_par_autre === '') {
							this.prevention_correctives_par_autre_missing = true;
						}
					}
					if (this.prevention_correctives_date_prevue == null) {
						this.prevention_correctives_date_prevue_missing = true;
						missingMandatory = true;
					}
				}
				break;
			case 8 :
				if (this.prevention_correctives_date_reel == null) {
					this.prevention_correctives_date_reel_missing = true;
					missingMandatory = true;
				}
				break;
			default :
				break;
		}
		return missingMandatory;
	}

	updateMissing(source) {
		switch (source) {
			case 'nature_evenement' :
				if (this.nature_evenement !== null) {
					this.nature_evenement_missing = false;
					if (this.nature_evenement !== 'Accident') {
						this.id_victime_missing = false;
						this.type_contrat_victime_missing = false;
						this.autres_personnes_missing = false;
						this.arret_travail_missing = false;
						this.arret_travail_nb_jours_missing = false;
					} else {
						this.id_personne_concernee_missing = false;
						this.type_contrat_personne_concernee_missing = false;
						this.autres_personnes_concernees_missing = false;
						this.autres_personnes_concernees_noms_prenoms_missing = false;
					}
				}
				break;
			case 'id_victime' :
				if (this.id_victime != null) {
					this.id_victime_missing = false;
				}
				break;
			case 'type_contrat_victime' :
				if (this.type_contrat_victime != null) {
					this.type_contrat_victime_missing = false;
				}
				break;
			case 'autres_personnes' :
				if (this.autres_personnes != null) {
					this.autres_personnes_missing = false;
				}
				break;
			case 'arret_travail' :
				if (this.arret_travail != null) {
					this.arret_travail_missing = false;
				}
				break;
			case 'arret_travail_nb_jours' :
				if (this.arret_travail === 'Non') {
					this.arret_travail_nb_jours_missing = false;
				} else if (this.arret_travail_nb_jours != null) {
					this.arret_travail_nb_jours_missing = false;
				}
				break;
			case 'id_personne_concernee' :
				if (this.id_personne_concernee != null) {
					this.id_personne_concernee_missing = false;
				}
				break;
			case 'type_contrat_personne_concernee' :
				if (this.type_contrat_personne_concernee != null) {
					this.type_contrat_personne_concernee_missing = false;
				}
				break;
			case 'autres_personnes_concernees' :
				if (this.autres_personnes_concernees != null) {
					this.autres_personnes_concernees_missing = false;
				}
				break;
			case 'autres_personnes_concernees_noms_prenoms' :
				if (this.autres_personnes_concernees === 'Non') {
					this.autres_personnes_concernees_noms_prenoms_missing = false;
				} else if (this.autres_personnes_concernees_noms_prenoms != null) {
					this.autres_personnes_concernees_noms_prenoms_missing = false;
				}
				break;
			case 'date_evenement' :
				if (this.date_evenement != null) {
					this.date_evenement_missing = false;
				}
				break;
			case 'cause_tier' :
				if (this.cause_tier != null && this.cause_tier !== '') {
					this.cause_tier_missing = false;
				}
				break;
			case 'temoins' :
				if (this.temoins != null) {
					this.temoins_missing = false;
				}
				break;
			case 'temoins_noms' :
				if (this.temoins === 'Non') {
					this.temoins_noms_missing = false;
					this.temoins_autre_missing = false;
				} else if (this.temoins_noms != null && this.temoins_noms.length > 0) {
					this.temoins_noms_missing = false;
					this.temoins_autre_missing = false;
				}
				break;
			case 'temoins_autre' :
				if (this.temoins === 'Non') {
					this.temoins_autre_missing = false;
					this.temoins_autre_missing = false;
				} else if (this.temoins_autre != null && this.temoins_autre !== '') {
					this.temoins_noms_missing = false;
					this.temoins_autre_missing = false;
				}
				break;
			case 'description' :
				if (this.description != null) {
					this.description_missing = false;
				}
				break;
			case 'premiers_soins_qui' :
				if (!this.premiers_soins) {
					this.premiers_soins_qui_missing = false;
				} else if (this.premiers_soins_qui != null) {
					this.premiers_soins_qui_missing = false;
				}
				break;
			case 'evacuation_qui' :
				if (!this.evacuation) {
					this.evacuation_qui_missing = false;
				} else if (this.evacuation_qui != null) {
					this.evacuation_qui_missing = false;
				}
				break;
			case 'evacuation_ou' :
				if (!this.evacuation) {
					this.evacuation_ou_missing = false;
				} else if (this.evacuation_ou != null) {
					this.evacuation_ou_missing = false;
				}
				break;
			case 'horaires_effectuees':
				if (this.horaires_effectuees != null) {
					if (this.horaires_effectuees.start != null) {
						this.horaires_effectuees_missing_start = false;
					}
					if (this.horaires_effectuees.end != null) {
						this.horaires_effectuees_missing_end = false;
					}
					if (this.horaires_effectuees.start <= this.horaires_effectuees.end) {
						this.horaires_effectuees_missmatche = false;
					}
				}
				break;
			case 'lieu_accident':
				if (this.lieu_accident != null) {
					this.lieu_accident_missing = false;
				}
				break;
			case 'nature_lesions_precisions' :
				if (!this.nature_lesions_autre) {
					this.nature_lesions_precisions_missing = false;
				} else if (this.nature_lesions_precisions != null) {
					this.nature_lesions_precisions_missing = false;
				}
				break;
			case 'causes_descriptions' :
				if (this.causes_descriptions != null) {
					this.causes_descriptions_missing = false;
				}
				break;
			case 'analyse_cause':
				if (this.analyse_cause != null) {
					this.analyse_cause_missing = false;
				}
				break;
			case 'date_analyse' :
				if (this.date_analyse != null) {
					this.date_analyse_missing = false;
				}
				break;
			case 'prevention_immediates_description' :
				if (this.prevention_immediates_description != null) {
					this.prevention_immediates_description_missing = false;
				}
				break;
			case 'prevention_immediates_par' :
				if (this.prevention_immediates_par != null) {
					this.prevention_immediates_par_missing = false;
					this.prevention_immediates_par_autre_missing = false;
				}
				break;
			case 'prevention_immediates_par_autre' :
				if (this.prevention_immediates_par_autre != null && this.prevention_immediates_par_autre !== '') {
					this.prevention_immediates_par_autre_missing = false;
					this.prevention_immediates_par_missing = false;
				}
				break;
			case 'prevention_correctives_description' :
				if (this.prevention_correctives_description != null) {
					this.prevention_correctives_description_missing = false;
				}
				break;
			case 'prevention_correctives_par' :
				if (this.prevention_correctives_par != null) {
					this.prevention_correctives_par_missing = false;
					this.prevention_correctives_par_autre_missing = false;
				}
				break;
			case 'prevention_correctives_par_autre' :
				if (this.prevention_correctives_par_autre != null && this.prevention_correctives_par_autre !== '') {
					this.prevention_correctives_par_autre_missing = false;
					this.prevention_correctives_par_missing = false;
				}
				break;
			case 'prevention_correctives_date_prevue' :
				if (this.prevention_correctives_date_prevue != null) {
					this.prevention_correctives_date_prevue_missing = false;
				}
				break;
			case 'prevention_correctives_date_reel' :
				if (this.prevention_correctives_date_reel != null) {
					this.prevention_correctives_date_reel_missing = false;
				}
				break;
			default :
				break;
		}
	}

	getFormData() {
		const formData = new FormData();
		if (this.id != null) {
			formData.append('id', JSON.stringify(this.id));
		}
		if (this.status != null) {
			formData.append('status', this.status);
		}

		// Step 1

		if (this.id_affaire != null) {
			formData.append('id_affaire', JSON.stringify(Number(this.id_affaire)));
		}

		// Step 2

		if (this.nature_evenement != null) {
			formData.append('nature_evenement', this.nature_evenement);
		}

		// Step 3

		if (this.id_victime != null) {
			formData.append('id_victime', JSON.stringify(this.id_victime.id));
		}
		if (this.type_contrat_victime != null) {
			formData.append('type_contrat_victime', this.type_contrat_victime);
		}
		if (this.autres_personnes != null) {
			formData.append('autres_personnes', this.autres_personnes);
		}
		if (this.arret_travail != null) {
			formData.append('arret_travail', this.arret_travail);
		}
		if (this.arret_travail_nb_jours != null) {
			formData.append('arret_travail_nb_jours', JSON.stringify(Number(this.arret_travail_nb_jours)));
		}
		if (this.id_personne_concernee != null) {
			formData.append('id_personne_concernee', JSON.stringify(this.id_personne_concernee.id));
		}
		if (this.type_contrat_personne_concernee != null) {
			formData.append('type_contrat_personne_concernee', this.type_contrat_personne_concernee);
		}
		if (this.autres_personnes_concernees != null) {
			formData.append('autres_personnes_concernees', this.autres_personnes_concernees);
		}
		if (this.autres_personnes_concernees_noms_prenoms != null) {
			formData.append('autres_personnes_concernees_noms_prenoms', this.autres_personnes_concernees_noms_prenoms);
		}
		if (this.date_evenement != null) {
			formData.append('date_evenement', moment(this.date_evenement).format());
		}
		if (this.cause_tier != null) {
			formData.append('cause_tier', this.cause_tier);
		}
		if (this.temoins != null) {
			formData.append('temoins', this.temoins);
		}
		for (const i in this.temoins_noms) {
			if (this.temoins_noms[i].id != null) {
				formData.append('temoins_noms[' + i + '][nom]', JSON.stringify(this.temoins_noms[i].id));
			}
			if (this.temoins_signatures[i] != null) {
				formData.append('temoins_noms[' + i + '][date_signature]', moment(this.temoins_signatures[i]).format());
			}
			if (this.temoins_signatures_2[i] != null) {
				formData.append('temoins_noms[' + i + '][date_signature_2]', moment(this.temoins_signatures_2[i]).format());
			}
		}
		if (this.temoins_autre != null) {
			formData.append('temoins_autre', this.temoins_autre);
		}
		if (this.description != null) {
			formData.append('description', this.description);
		}
		for (const i in this.photos) {
			const curPhoto = this.photos[i];
			if (curPhoto.type === 'local') {
				formData.append('photos[]', FileService.dataURItoBlob(curPhoto.preview), curPhoto.fileName);
			}
		}
		if (this.premiers_soins != null) {
			formData.append('premiers_soins', JSON.stringify(Number(this.premiers_soins)));
		}
		if (this.premiers_soins_qui != null) {
			formData.append('premiers_soins_qui', this.premiers_soins_qui);
		}
		if (this.appel_secours != null) {
			formData.append('appel_secours', JSON.stringify(Number(this.appel_secours)));
		}
		if (this.evacuation != null) {
			formData.append('evacuation', JSON.stringify(Number(this.evacuation)));
		}
		if (this.evacuation_qui != null) {
			formData.append('evacuation_qui', this.evacuation_qui);
		}
		if (this.evacuation_ou != null) {
			formData.append('evacuation_ou', this.evacuation_ou);
		}

		if (this.nature_evenement === 'Accident') {
			if (this.horaires_effectuees != null) {
				formData.append('horaires_effectuees', JSON.stringify(this.horaires_effectuees));
			}
			if (this.lieu_accident != null) {
				formData.append('lieu_accident', this.lieu_accident);
			}
		}


		// Step 4

		if (this.nature_evenement === 'Accident' || this.nature_evenement === 'Soin') {
			if (this.image_corps_face.type === 'local') {
				formData.append('image_corps_face', FileService.dataURItoBlob(this.image_corps_face.preview), this.image_corps_face.fileName);
			}
			if (this.image_corps_dos.type === 'local') {
				formData.append('image_corps_dos', FileService.dataURItoBlob(this.image_corps_dos.preview), this.image_corps_dos.fileName);
			}
			if (this.image_visage.type === 'local') {
				formData.append('image_visage', FileService.dataURItoBlob(this.image_visage.preview), this.image_visage.fileName);
			}
			if (this.image_main.type === 'local') {
				formData.append('image_main', FileService.dataURItoBlob(this.image_main.preview), this.image_main.fileName);
			}
			if (this.image_pied.type === 'local') {
				formData.append('image_pied', FileService.dataURItoBlob(this.image_pied.preview), this.image_pied.fileName);
			}
			if (this.lesions_precisions != null) {
				formData.append('lesions_precisions', this.lesions_precisions);
			}
			if (this.nature_lesions_plaie != null) {
				formData.append('nature_lesions_plaie', JSON.stringify(Number(this.nature_lesions_plaie)));
			}
			if (this.nature_lesions_entorse != null) {
				formData.append('nature_lesions_entorse', JSON.stringify(Number(this.nature_lesions_entorse)));
			}
			if (this.nature_lesions_hematome != null) {
				formData.append('nature_lesions_hematome', JSON.stringify(Number(this.nature_lesions_hematome)));
			}
			if (this.nature_lesions_asphyxie != null) {
				formData.append('nature_lesions_asphyxie', JSON.stringify(Number(this.nature_lesions_asphyxie)));
			}
			if (this.nature_lesions_brulure != null) {
				formData.append('nature_lesions_brulure', JSON.stringify(Number(this.nature_lesions_brulure)));
			}
			if (this.nature_lesions_fracture != null) {
				formData.append('nature_lesions_fracture', JSON.stringify(Number(this.nature_lesions_fracture)));
			}
			if (this.nature_lesions_douleurs != null) {
				formData.append('nature_lesions_douleurs', JSON.stringify(Number(this.nature_lesions_douleurs)));
			}
			if (this.nature_lesions_malaise != null) {
				formData.append('nature_lesions_malaise', JSON.stringify(Number(this.nature_lesions_malaise)));
			}
			if (this.nature_lesions_autre != null) {
				formData.append('nature_lesions_autre', JSON.stringify(Number(this.nature_lesions_autre)));
			}
			if (this.nature_lesions_precisions != null) {
				formData.append('nature_lesions_precisions', this.nature_lesions_precisions);
			}
		}

		if (this.nature_evenement === 'Incident sur véhicule') {
			if (this.vehicule_dos.type === 'local') {
				formData.append('vehicule_dos', FileService.dataURItoBlob(this.vehicule_dos.preview), this.vehicule_dos.fileName);
			}
			if (this.vehicule_face.type === 'local') {
				formData.append('vehicule_face', FileService.dataURItoBlob(this.vehicule_face.preview), this.vehicule_face.fileName);
			}
			if (this.vehicule_droite.type === 'local') {
				formData.append('vehicule_droite', FileService.dataURItoBlob(this.vehicule_droite.preview), this.vehicule_droite.fileName);
			}
			if (this.vehicule_gauche.type === 'local') {
				formData.append('vehicule_gauche', FileService.dataURItoBlob(this.vehicule_gauche.preview), this.vehicule_gauche.fileName);
			}
			if (this.dommages_precisions != null) {
				formData.append('dommages_precisions', this.dommages_precisions);
			}
		}

		if (this.prevention_immediates_description != null) {
			formData.append('prevention_immediates_description', this.prevention_immediates_description);
		}
		if (this.prevention_immediates_par != null) {
			formData.append('prevention_immediates_par', JSON.stringify(this.prevention_immediates_par.id));
		}
		if (this.prevention_immediates_par_autre != null) {
			formData.append('prevention_immediates_par_autre', this.prevention_immediates_par_autre);
		}

		// Step 5

		if (this.date_signature != null) {
			formData.append('date_signature', moment(this.date_signature).format());
		}
		if (this.date_signature_victime != null) {
			formData.append('date_signature_victime', moment(this.date_signature_victime).format());
		}
		if (this.date_signature_personne_concernee != null) {
			formData.append('date_signature_personne_concernee', moment(this.date_signature_personne_concernee).format());
		}

		// Step 6

		if (this.analyse_cause != null) {
			formData.append('analyse_cause', this.analyse_cause);
		}
		if (this.analyse_cause === 'Oui') {
			if (this.date_analyse != null) {
				formData.append('date_analyse', moment(this.date_analyse).format());
			}
			if (this.causes_descriptions != null) {
				formData.append('causes_descriptions', this.causes_descriptions);
			}
			for (const i in this.causes_pieces_jointes) {
				const curPj = this.causes_pieces_jointes[i];
				if (curPj.type === 'local') {
					formData.append('causes_pieces_jointes[]', FileService.dataURItoBlob(curPj.preview), curPj.fileName);
				}
			}
			if (this.prevention_correctives_description != null) {
				formData.append('prevention_correctives_description', this.prevention_correctives_description);
			}
			if (this.prevention_correctives_par != null) {
				formData.append('prevention_correctives_par', JSON.stringify(this.prevention_correctives_par.id));
			}
			if (this.prevention_correctives_par_autre != null) {
				formData.append('prevention_correctives_par_autre', this.prevention_correctives_par_autre);
			}
			if (this.prevention_correctives_date_prevue != null) {
				formData.append('prevention_correctives_date_prevue', moment(this.prevention_correctives_date_prevue).format());
			}
		}


		// Step 7

		if (this.id_victime != null) {
			formData.append('id_victime_2', JSON.stringify(this.id_victime.id));
		}
		if (this.date_signature_victime_2 != null) {
			formData.append('date_signature_victime_2', moment(this.date_signature_victime_2).format());
		}
		if (this.id_personne_concernee != null) {
			formData.append('id_personne_concernee_2', JSON.stringify(this.id_personne_concernee.id));
		}
		if (this.date_signature_personne_concernee_2 != null) {
			formData.append('date_signature_personne_concernee_2', moment(this.date_signature_personne_concernee_2).format());
		}
		if (this.id_hse != null) {
			formData.append('id_hse', JSON.stringify(this.id_hse.id));
		}
		if (this.date_signature_hse != null) {
			formData.append('date_signature_hse', moment(this.date_signature_hse).format());
		}
		if (this.id_chef_chantier != null) {
			formData.append('id_chef_chantier', JSON.stringify(this.id_chef_chantier.id));
		}
		if (this.date_signature_chantier != null) {
			formData.append('date_signature_chantier', moment(this.date_signature_chantier).format());
		}
		if (this.id_charge_affaire != null) {
			formData.append('id_charge_affaire', JSON.stringify(this.id_charge_affaire.id));
		}
		if (this.date_signature_affaire != null) {
			formData.append('date_signature_affaire', moment(this.date_signature_affaire).format());
		}

		if (this.personnes_supplementaires != null) {
			formData.append('personnes_supplementaires', this.personnes_supplementaires);
		}

		// Step 8

		if (this.prevention_correctives_date_reel != null) {
			formData.append('prevention_correctives_date_reel', moment(this.prevention_correctives_date_reel).format());
		}

		// Step 9

		if (this.date_signature_hse_2 != null) {
			formData.append('date_signature_hse_2', moment(this.date_signature_hse_2).format());
		}

		return formData;
	}

	personnesList(personnes: Array<UserObject>) {
		if (personnes != null && personnes.length > 0) {
			let list = '';
			for (const i in personnes) {
				list += personnes[i].complete_name + '\n';
			}
			list = list.substr(0, list.length - 1);
			return list;
		} else {
			return 'Choisir';
		}
	}
}
