import {CurrencyFormatPipe} from '../../../pipes/currency-format/currency-format';

export class PrimeObject {
	id: number;
	table = 'prime';
	nom: string;
	type: string;
	montant: number;
	km_debut: number;
	km_fin: number;
	demi_mobilite: boolean;

	checked: boolean;

	full_desc: string;

	constructor(id = null, nom = null, type = null, montant = null, km_debut = null, km_fin = null, demi_mobilite = null) {
		this.id = id !== null ? Number(id) : null;
		this.nom = nom;
		this.type = type;
		this.montant = montant !== null ? Number(montant) : null;
		this.km_debut = km_debut !== null ? Number(km_debut) : null;
		this.km_fin = km_fin !== null ? Number(km_fin) : null;
		this.demi_mobilite = demi_mobilite;

		const currencyFormatPipe = new CurrencyFormatPipe();
		if (['Panier', 'Mobilité'].includes(this.type)) {
			this.full_desc = this.nom + ' / ' + this.km_debut + ' - ' + this.km_fin + ' km / ' + currencyFormatPipe.transform(this.montant) + ' €';
		} else if (['Prime nuité', 'Forfait nuité', 'Forfait grand déplacement'].includes(this.type)) {
			this.full_desc = this.type + ' / ' + currencyFormatPipe.transform(this.montant) + ' €';
		}
	}

	public static LoadFromJSON(json: any) {
		return new PrimeObject(
			json['id'],
			json['nom'],
			json['type'],
			json['montant'],
			json['km_debut'],
			json['km_fin'],
			json['demi_mobilite']
		);
	}

	missingMandatoryFields() {
		if (this.type === null) {
			return 'Le type de prime n\'a pas été renseigné.';
		}
		if (this.montant === null) {
			return 'Le montant de la prime n\'a pas été renseigné.';
		}
		if (['Panier', 'Mobilité'].includes(this.type) && (this.nom === null || this.nom === '')) {
			return 'Le nom de la prime n\'a pas été renseigné.';
		}
		if (['Panier', 'Mobilité'].includes(this.type) && this.km_debut === null) {
			return 'La tranche km début de la prime n\'a pas été renseignée.';
		}
		if (['Panier', 'Mobilité'].includes(this.type) && this.km_fin === null) {
			return 'La tranche km fin de la prime n\'a pas été renseignée.';
		}
		return null;
	}

	clearRelatedFields(field) {
		switch (field) {
			case 'type':
				if (this.type !== 'Mobilité' && this.type !== 'Panier') {
					this.nom = null;
					this.km_debut = null;
					this.km_fin = null;
				}
				if (this.type !== 'Mobilité') {
					this.demi_mobilite = null;
				}
				break;
			default :
				break;
		}
	}

	getFormData(forFeuilleHeures = false) {
		const formData = {
			nom: this.nom,
			type: this.type,
			montant: this.montant,
			km_debut: this.km_debut,
			km_fin: this.km_fin,
			demi_mobilite: this.demi_mobilite
		};
		if (forFeuilleHeures) {
			formData['id'] = this.id;
		}
		return formData;
	}
}
