import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Filesystem, Directory} from '@capacitor/filesystem';
import {FileOpener} from '@ionic-native/file-opener/ngx';

import {saveAs} from 'file-saver';
import * as JSZip from 'jszip';

@Injectable({
	providedIn: 'root'
})
export class FileService {

	platformIsDesktop: boolean;

	constructor(private platform: Platform, private fileOpener: FileOpener) {
		this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
	}

	public static dataURItoBlob(dataURI) {
		// convert base64 to raw binary data held in a string
		const byteString = atob(dataURI.split(',')[1]);

		// separate out the mime component
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to an ArrayBuffer
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const _ia = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			_ia[i] = byteString.charCodeAt(i);
		}

		const dataView = new DataView(arrayBuffer);
		return new Blob([dataView], {type: mimeString});
	}

	async openPdf(pdfGenerated) {
		try {
			if (this.platformIsDesktop) {
				pdfGenerated.data.open();
			} else {
				const pdfBase64: any = await this.getPdfBase64(pdfGenerated);
				try {
					const filePath = await Filesystem.writeFile({
						path: pdfBase64.fileName,
						data: pdfBase64.data,
						directory: Directory.Cache
					});
					await this.fileOpener.open(filePath.uri, 'application/pdf');
				} catch (e) {
					console.log(e);
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	async savePdfs(pdfsGenerated) {
		try {
			const pdfsToSave = [];
			for (const i in pdfsGenerated) {
				if (pdfsGenerated[i] != null) {
					pdfsToSave.push(pdfsGenerated[i]);
				}
			}
			if (pdfsToSave.length > 0) {
				if (pdfsToSave.length === 1) {
					const pdfBlob: any = await this.getPdfBlob(pdfsToSave[0]);
					saveAs(pdfBlob.data, pdfBlob.fileName);
				} else {
					const promises = [];
					for (const i in pdfsToSave) {
						promises.push(this.getPdfBlob(pdfsToSave[i]));
					}
					const pdfsBlobs = await Promise.all(promises);
					const zip: JSZip = new JSZip();
					for (const i in pdfsBlobs) {
						if (pdfsBlobs[i] != null) {
							zip.folder('documents').file(pdfsBlobs[i].fileName, pdfsBlobs[i].data);
						}
					}
					const content = await zip.generateAsync({type: 'blob'});
					saveAs(content, 'documents.zip');
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	getPdfBlob(pdfGenerated) {
		return new Promise((resolve) => {
			try {
				pdfGenerated.data.getBlob((blob) => {
					resolve({data: blob, fileName: pdfGenerated.fileName});
				});
			} catch (e) {
				resolve(null);
			}
		});
	}

	getPdfBase64(pdfGenerated) {
		return new Promise((resolve) => {
			try {
				pdfGenerated.data.getBase64((blob) => {
					resolve({data: 'data:application/pdf;base64,' + blob, fileName: pdfGenerated.fileName});
				});
			} catch (e) {
				resolve(null);
			}
		});
	}

	async saveAttachedFiles(form) {
		try {
			const promises = [];
			switch (form.table) {
				case 'inspection_chantier' :
					for (const i in form.photos) {
						promises.push(this.getRemoteFile(form.photos[i]));
					}
					break;
				case 'rapport_incident' :
					for (const i in form.photos) {
						promises.push(this.getRemoteFile(form.photos[i]));
					}
					for (const i in form.causes_pieces_jointes) {
						promises.push(this.getRemoteFile(form.causes_pieces_jointes[i]));
					}
					break;
				case 'cr_intervention' :
					for (const i in form.photos) {
						promises.push(this.getRemoteFile(form.photos[i]));
					}
					break;
				case 'pv_reception' :
					for (const i in form.travaux) {
						for (const j in form.travaux[i]) {
							promises.push(this.getRemoteFile(form.travaux[i].photos[j]));
						}
					}
					break;
				case 'fiche_qualite' :
					for (const i in form.photos) {
						promises.push(this.getRemoteFile(form.photos[i]));
					}
					break;
				default :
					break;
			}
			const remoteFiles = await Promise.all(promises);
			const zip: JSZip = new JSZip();
			for (const i in remoteFiles) {
				if (remoteFiles[i] != null) {
					zip.folder('affaire_' + form.id_affaire + '_' + form.table).file(remoteFiles[i].fileName, remoteFiles[i].data);
				}
			}
			const content = await zip.generateAsync({type: 'blob'});
			saveAs(content, 'affaire_' + form.id_affaire + '_' + form.table + '.zip');
		} catch (e) {

		}
	}

	getRemoteFile(file) {
		return new Promise((resolve) => {
			try {
				const xhr = new XMLHttpRequest();
				xhr.onload = () => {
					resolve({data: xhr.response, fileName: file.data.name});
				};
				xhr.open('GET', file.data.url);
				xhr.responseType = 'blob';
				xhr.send();
			} catch (e) {
				resolve(null);
			}
		});
	}

	async saveExcels(excelsGenerated: Array<any>, fileType) {
		if (excelsGenerated.length === 1) {
			saveAs(new Blob([excelsGenerated[0].data], {type: fileType}), excelsGenerated[0].fileName);
		} else {
			const zip: JSZip = new JSZip();
			const excelsZip = zip.folder('documents');
			for (const i in excelsGenerated) {
				if (excelsGenerated[i] != null) {
					excelsZip.file(excelsGenerated[i].fileName, new Blob([excelsGenerated[i].data], {type: fileType}));
				}
			}
			const content = await excelsZip.generateAsync({type: 'blob'});
			saveAs(content, 'documents.zip');
		}
	}
}
