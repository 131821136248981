import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NavParams, Platform, PopoverController} from '@ionic/angular';

@Component({
	selector: 'app-popover-actions',
	templateUrl: './popover-actions.component.html',
	styleUrls: ['./popover-actions.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class PopoverActionsComponent implements OnInit {

	platformIsDesktop: boolean;

	actions: Array<{ id: string, text: string }>;

	constructor(public platform: Platform, private navParams: NavParams, private popoverCtrl: PopoverController) {
		this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
	}

	ngOnInit() {
		this.actions = this.navParams.get('actions');
	}

	async actionClicked(id) {
		await this.popoverCtrl.dismiss(id);
	}

}
