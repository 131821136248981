import {UserObject} from '../../user.object';

export class OuvertureAffaireObject {
	id: number;
	table = 'ouverture_affaire';
	titre = 'Demande d\'ouverture d\'affaire';
	createdAt: string;
	updatedAt: string;
	status: string;
	createdBy: UserObject;
	updatedBy: UserObject;

	// Step 1

	numero_devis: string;

	// Step 2

	nom_client: string;
	numero_devis_comp: Array<{ numero_devis: string }>;
	interlocuteur: string;
	titre_affaire: string;
	prix: number;
	heures: number;
	materiel: number;

	// Step 2 missing

	nom_client_missing: boolean;
	interlocuteur_missing: boolean;
	titre_affaire_missing: boolean;
	prix_missing: boolean;
	heures_missing: boolean;
	materiel_missing: boolean;

	// Step 3

	charge_affaire: UserObject;
	assistant_charge_affaire: UserObject;
	responsable_chantier: UserObject;
	assistante: UserObject;

	// Step 3 missing

	charge_affaire_missing: boolean;
	responsable_chantier_missing: boolean;
	assistante_missing: boolean;

	// Step 4

	initials: string;
	id_affaire: number;
	numero_commande: string;

	// Step 4 missing

	id_affaire_missing: boolean;

	// Status

	// draft
	// to_be_completed
	// signed
	// closed

	constructor(
		id = null,
		createdAt = null,
		updatedAt = null,
		status = 'draft',
		createdBy = null,
		updatedBy = null,
		// Step 1

		numero_devis = null,
		// Step 2

		nom_client = null,
		numero_devis_comp = [],
		interlocuteur = null,
		titre_affaire = null,
		prix = null,
		heures = null,
		materiel = null,
		// Step 3

		charge_affaire = null,
		assistant_charge_affaire = null,
		responsable_chantier = null,
		assistante = null,
		// Step 4

		initials = null,
		id_affaire = null,
		numero_commande = null
	) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;

		// Step 1

		this.numero_devis = numero_devis;

		// Step 2

		this.nom_client = nom_client;
		this.numero_devis_comp = numero_devis_comp;
		this.interlocuteur = interlocuteur;
		this.titre_affaire = titre_affaire;
		this.prix = prix;
		this.heures = heures;
		this.materiel = materiel;

		// Step 2 missing

		this.nom_client_missing = false;
		this.interlocuteur_missing = false;
		this.titre_affaire_missing = false;
		this.prix_missing = false;
		this.heures_missing = false;
		this.materiel_missing = false;

		// Step 3

		this.charge_affaire = charge_affaire;
		this.assistant_charge_affaire = assistant_charge_affaire;
		this.responsable_chantier = responsable_chantier;
		this.assistante = assistante;

		// Step 3 missing

		this.charge_affaire_missing = false;
		this.responsable_chantier_missing = false;
		this.assistante_missing = false;

		// Step 4

		this.initials = initials;
		this.id_affaire = id_affaire;
		this.numero_commande = numero_commande;

		// Step 4 missing

		this.id_affaire_missing = false;
	}

	missingMandatoryFields(step) {
		let missingMandatory = false;
		switch (step) {
			case 2 :
				if (this.nom_client == null || this.nom_client === '') {
					this.nom_client_missing = true;
					missingMandatory = true;
				}
				if (this.interlocuteur == null || this.interlocuteur === '') {
					this.interlocuteur_missing = true;
					missingMandatory = true;
				}
				if (this.titre_affaire == null || this.titre_affaire === '') {
					this.titre_affaire_missing = true;
					missingMandatory = true;
				}
				if (this.prix == null) {
					this.prix_missing = true;
					missingMandatory = true;
				}
				if (this.heures == null) {
					this.heures_missing = true;
					missingMandatory = true;
				}
				if (this.materiel == null) {
					this.materiel_missing = true;
					missingMandatory = true;
				}
				break;
			case 3 :
				if (this.charge_affaire == null) {
					this.charge_affaire_missing = true;
					missingMandatory = true;
				}
				if (this.responsable_chantier == null) {
					this.responsable_chantier_missing = true;
					missingMandatory = true;
				}
				if (this.assistante == null) {
					this.assistante_missing = true;
					missingMandatory = true;
				}
				break;
			case 4 :
				if (this.id_affaire == null) {
					this.id_affaire_missing = true;
					missingMandatory = true;
				}
				break;
			default:
				break;
		}
		return missingMandatory;
	}

	updateMissing(source) {
		switch (source) {
			case 'nom_client':
				if (this.nom_client != null && this.nom_client !== '') {
					this.nom_client_missing = false;
				}
				break;
			case 'interlocuteur':
				if (this.interlocuteur != null && this.interlocuteur !== '') {
					this.interlocuteur_missing = false;
				}
				break;
			case 'titre_affaire':
				if (this.titre_affaire != null && this.titre_affaire !== '') {
					this.titre_affaire_missing = false;
				}
				break;
			case 'prix':
				if (this.prix != null) {
					this.prix_missing = false;
				}
				break;
			case 'heures':
				if (this.heures != null) {
					this.heures_missing = false;
				}
				break;
			case 'materiel':
				if (this.materiel != null) {
					this.materiel_missing = false;
				}
				break;
			case 'charge_affaire':
				if (this.charge_affaire != null) {
					this.charge_affaire_missing = false;
				}
				break;
			case 'responsable_chantier':
				if (this.responsable_chantier != null) {
					this.responsable_chantier_missing = false;
				}
				break;
			case 'assistante':
				if (this.assistante != null) {
					this.assistante_missing = false;
				}
				break;
			case 'id_affaire':
				if (this.id_affaire != null) {
					this.id_affaire_missing = false;
				}
				break;
			default:
				break;
		}
	}

	updateInitials() {
		if (this.charge_affaire) {
			this.initials = '';
			if (this.charge_affaire.first_name) {
				const firstLetter = this.charge_affaire.first_name.substr(0, 1);
				if (firstLetter) {
					this.initials += firstLetter.toUpperCase();
				}
			}
			if (this.charge_affaire.last_name) {
				const firstLetter = this.charge_affaire.last_name.substr(0, 1);
				if (firstLetter) {
					this.initials += firstLetter.toUpperCase();
				}
			}
		}
	}

	removeNumeroDevis(index?) {
		if (index != null && this.numero_devis_comp.length > 0) {
			this.numero_devis_comp.splice(index, 1);
		} else if (this.numero_devis_comp.length > 0) {
			this.numero_devis_comp.pop();
		}
	}

	addNumeroDevis() {
		this.numero_devis_comp.push({numero_devis: null});
	}

	numeroDevisCompFormated() {
		let formated = '';
		for (const i in this.numero_devis_comp) {
			formated += this.numero_devis_comp[i].numero_devis;
			if (Number(i) < this.numero_devis_comp.length - 1) {
				formated += ', ';
			}
		}
		return formated;
	}

	getFormData() {
		const formData = new FormData();

		if (this.id != null) {
			formData.append('id', JSON.stringify(this.id));
		}
		if (this.status != null) {
			formData.append('status', this.status);
		}

		// Step 1

		if (this.numero_devis != null) {
			formData.append('numero_devis', this.numero_devis);
		}

		// Step 2

		if (this.nom_client != null) {
			formData.append('nom_client', this.nom_client);
		}
		for (const i in this.numero_devis_comp) {
			formData.append('numero_devis_comp[]', this.numero_devis_comp[i].numero_devis);
		}
		if (this.interlocuteur != null) {
			formData.append('interlocuteur', this.interlocuteur);
		}
		if (this.titre_affaire != null) {
			formData.append('titre', this.titre_affaire);
		}
		if (this.prix != null) {
			formData.append('prix', JSON.stringify(Number(this.prix)));
		}
		if (this.heures != null) {
			formData.append('heures', JSON.stringify(Number(this.heures)));
		}
		if (this.materiel != null) {
			formData.append('materiel', JSON.stringify(Number(this.materiel)));
		}

		// Step 3

		if (this.charge_affaire != null && this.charge_affaire.id != null) {
			formData.append('charge_affaire', JSON.stringify(this.charge_affaire.id));
		}
		if (this.assistant_charge_affaire != null && this.assistant_charge_affaire.id != null) {
			formData.append('assistant_charge_affaire', JSON.stringify(this.assistant_charge_affaire.id));
		}
		if (this.responsable_chantier != null && this.responsable_chantier.id != null) {
			formData.append('responsable_chantier', JSON.stringify(this.responsable_chantier.id));
		}
		if (this.assistante != null && this.assistante.id != null) {
			formData.append('assistante', JSON.stringify(this.assistante.id));
		}

		// Step 4

		if (this.initials != null) {
			formData.append('initials', this.initials);
		}
		if (this.id_affaire != null) {
			formData.append('id_affaire', JSON.stringify(this.id_affaire));
		}
		if (this.numero_commande != null) {
			formData.append('numero_commande', this.numero_commande);
		}

		return formData;
	}
}
