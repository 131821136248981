import {Pipe, PipeTransform} from '@angular/core';

import * as moment from 'moment';

@Pipe({
	name: 'momentDateFormat',
})
export class MomentDateFormatPipe implements PipeTransform {

	transform(value: string, outputFormat: string, inputFormat?: string) {
		if (value == null) {
			return '';
		} else {
			if (inputFormat != null) {
				return moment(value, inputFormat).format(outputFormat);
			} else {
				return moment(value).format(outputFormat);
			}
		}
	}
}
