import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {UsersService} from '../services/users.service';

export interface CanDeactivatePage {
	canDeactivate: () => Promise<boolean> | boolean;
}

@Injectable({
	providedIn: 'root'
})
export class SecuredGuard implements CanActivate, CanDeactivate<CanDeactivatePage> {

	constructor(private router: Router, private usersService: UsersService) {
	}

	async canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot) {
		try {
			const isUserConnected = await this.usersService.isUserConnected();
			if (isUserConnected) {
				const userConnected = await this.usersService.getStoredUserConnected();
				const page = this.removeParamsFromUrl(router.url);
				let params = null;
				if (page === '/planning-service') {
					params = route.queryParams?.viewService;
				}
				return userConnected.isAuthorized(page, 'Visualiser', params) || userConnected.isAuthorized(page, 'Ajouter');
			} else {
				this.router.navigateByUrl('/login');
				return false;
			}
		} catch (error) {
			this.router.navigateByUrl('/login');
			return false;
		}
	}

	/*async canActivate(next: ActivatedRouteSnapshot) {
		const params = next.queryParams;
		if (params['userConnected']) {
			return true;
		} else {
			this.router.navigateByUrl('/login');
			return false;
		}
	}*/

	async canDeactivate(component: CanDeactivatePage) {
		return component.canDeactivate ? await component.canDeactivate() : true;
	}

	removeParamsFromUrl(url: string) {
		const end = url?.indexOf('?');
		if (end > -1) {
			return url.substring(0, end);
		} else {
			return url;
		}
	}
}
