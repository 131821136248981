import * as moment from 'moment';

import {UserObject} from '../../user.object';
import {VehiculeObject} from '../parametres/vehicule.object';

export class PlanningVehiculeObject {
	id: number;
	table = 'planning_vehicule';
	titre = 'Planning véhicule';
	createdAt: string;
	updatedAt: string;
	date_debut: string;
	date_fin: string;
	ressources: Array<UserObject>;
	vehicule: VehiculeObject;
	conducteur: UserObject;

	constructor(id = null, createdAt = null, updatedAt = null, date_debut = null, date_fin = null, ressources = [], vehicule = null, conducteur = null) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.date_debut = date_debut;
		this.date_fin = date_fin;
		this.ressources = ressources;
		this.vehicule = vehicule;
		this.conducteur = conducteur;
	}

	public static LoadFromJSON(json: any) {
		return new PlanningVehiculeObject(
			json['id'],
			json['createdAt'],
			json['updatedAt'],
			json['date_debut'],
			json['date_fin'],
			this.LoadFromArray(json['ressources']),
			json['vehicule'] ? VehiculeObject.LoadFromJSON(json['vehicule']) : null,
			json['conducteur'] ? UserObject.LoadFromJSON(json['conducteur']) : null,
		);
	}

	public static LoadFromArray(arr: any[]) {
		const ret = [];
		if (arr) {
			for (let i = 0; i < arr.length; i++) {
				ret.push(UserObject.LoadFromJSON(arr[i]));
			}
		}
		return ret;
	}

	missingMandatoryFields() {
		if (this.date_debut === null) {
			return 'La date de début n\'a pas été renseignée.';
		}
		if (this.date_fin === null) {
			return 'La date de fin n\'a pas été renseignée.';
		}
		if (moment(this.date_debut).format('YYYY-MM-DD') > moment(this.date_fin).format('YYYY-MM-DD')) {
			return 'La date de fin doit être supérieure à la date de début.';
		}
		if (this.ressources?.length === 0) {
			return 'Aucune ressource n\'a été attribuée.';
		}
		if (!this.vehicule) {
			return 'Aucune véhicule n\'a été attribué.';
		}
		if (!this.conducteur) {
			return 'Aucune conducteur n\'a été attribué.';
		}
		if (this.vehicule && this.vehicule.nb_places !== null && this.vehicule.nb_places < this.ressources.length) {
			return 'Il y a ' + this.ressources.length + ' personnes dans ce véhicule qui n\'a que ' + this.vehicule.nb_places + ' place(s).';
		}
		return null;
	}

	getFormData() {
		const ressources = [];
		for (const i in this.ressources) {
			ressources.push(this.ressources[i].id);
		}
		return {
			date_debut: this.date_debut ? moment(this.date_debut).format('YYYY-MM-DD') : null,
			date_fin: this.date_fin ? moment(this.date_fin).format('YYYY-MM-DD') : null,
			ressources: ressources,
			vehicule: this.vehicule ? this.vehicule.id : null,
			conducteur: this.conducteur ? this.conducteur.id : null
		};
	}

	getPlanningBackgroundColor(planningType) {
		switch (planningType) {
			case 'general':
				return '#fde2f5';
			case 'vehicules':
				return '#fde2f5';
		}
	}

	getPlanningBorderColor() {
		return '#000000';
	}

	getPlanningTextColor() {
		return '#000000';
	}

	getPlanningTitle(datesRange) {
		let title = '';
		if (!this.date_debut || !this.date_fin) {
			return this.vehicule.reference + ' (' + this.conducteur?.trigramme + ')';
		} else {
			let cptDays = 0;
			for (const d = moment(datesRange[0]); d.format('YYYY-MM-DD') <= moment(datesRange[1]).format('YYYY-MM-DD'); d.add(1, 'day'))
			{
				if (d.format('YYYY-MM-DD') >= moment(this.date_debut).format('YYYY-MM-DD') && d.format('YYYY-MM-DD') <= moment(this.date_fin).format('YYYY-MM-DD')) {
					cptDays++;
				}
			}
			const cptWeeks = Math.ceil(cptDays / 7);
			for (let i = 0; i < cptWeeks; i++) {
				title += '<div class="display-inline-table" style="width: ' + (100 / cptWeeks) + '%">' + this.vehicule.reference + ' (' + this.conducteur?.trigramme + ')' + '</div>';
			}
			return title;
		}
	}
}
