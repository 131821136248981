import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'service',
})
export class ServicePipe implements PipeTransform {

	transform(value: string) {
		switch (value) {
			case 'etude-elec' :
				return 'Étude électrique';
			case 'etude-auto' :
				return 'Etude automatisme';
			case 'atelier' :
				return 'Atelier';
			case 'chantier' :
				return 'Chantier';
			default :
				return 'Inconnu';
		}
	}
}
