import {Injectable} from '@angular/core';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import {ActionSheet, ActionSheetButtonStyle} from '@capacitor/action-sheet';
import {LoadingController, ModalController} from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class ImagesService {

	constructor(private modalCtrl: ModalController, private loadingCtrl: LoadingController) {
	}

	async showModalBodyPart(bodyPartPage, userConnected, bodyImage, bodyImageDefaut, bodyPart) {
		let cssClass = 'custom-modal ';
		switch (bodyPart) {
			case 'image_corps_face' :
				cssClass += 'modal-body-part-large-height';
				break;
			case 'image_corps_dos' :
				cssClass += 'modal-body-part-large-height';
				break;
			case 'image_visage' :
				cssClass += 'modal-body-part-medium-height';
				break;
			case 'image_pied' :
				cssClass += 'modal-body-part-small-height';
				break;
			case 'image_main' :
				cssClass += 'modal-body-part-medium-height';
				break;
			default :
				break;
		}

		const bodyPartModal = await this.modalCtrl.create({
			id: 'BodyPartPage',
			component: bodyPartPage,
			componentProps: {userConnected: userConnected, bodyImage: bodyImage, bodyImageDefaut: bodyImageDefaut, bodyPart: bodyPart},
			backdropDismiss: false,
			cssClass: cssClass
		});
		await bodyPartModal.present();
		const result: any = await bodyPartModal.onWillDismiss();
		return result.data;
	}

	getBlobFromImageUrl(url, type) {
		return new Promise((resolve) => {
			if (type === 'local') {
				resolve(url);
			} else {
				try {
					const img = new Image();
					img.crossOrigin = 'Anonymous';
					img.onload = () => {
						const canvas = document.createElement('canvas') as HTMLCanvasElement;
						const width = 600;
						const scaleFactor = width / img.width;
						canvas.width = width;
						canvas.height = img.height * scaleFactor;
						const ctx = canvas.getContext('2d');
						ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
						resolve(canvas.toDataURL('image/png'));
					};
					img.src = url;
				} catch (e) {
					resolve(null);
				}
			}
		});
	}

	getImageFromUrl(url) {
		return new Promise((resolve, reject) => {
			try {
				const img = new Image();
				img.crossOrigin = 'Anonymous';
				img.onload = async () => {
					const canvas = document.createElement('canvas') as HTMLCanvasElement;
					canvas.width = img.width;
					canvas.height = img.height;
					const ctx = canvas.getContext('2d');
					ctx.drawImage(img, 0, 0, img.width, img.height);
					resolve(canvas.toDataURL());
				};
				img.src = url;
			} catch (e) {
				reject();
			}
		});
	}

	async getPhoto() {
		try {
			const photo = await Camera.getPhoto({
				source: CameraSource.Camera,
				saveToGallery: false,
				correctOrientation: true,
				resultType: CameraResultType.DataUrl,
				width: 2000,
				height: 2000
			});
			return [photo];
		} catch (error) {
			if (error != null && (error.message === 'User cancelled photos app' || error.message === 'No image picked')) {
				return null;
			} else if (error != null && (error.message === 'User denied access to camera' || error.message === 'Unable to access camera, user denied permission request')) {
				throw 'CAMERA_DENIED';
			} else if (error != null && error.message === 'User denied access to photos') {
				throw 'LIBRARY_DENIED';
			} else {
				throw 'ERROR';
			}
		}
	}

	async pickPhotos() {
		const loader = await this.loadingCtrl.create({
			spinner: null,
			message: `<div class="spinner-custom"></div>`,
			cssClass: 'loader-stemi'
		});
		try {
			const result = await Camera.pickImages({
				correctOrientation: true,
				width: 2000,
				height: 2000
			});
			const photos = [];
			const promises = [];
			for (const i in result.photos) {
				promises.push(this.getImageFromUrl(result.photos[i].webPath));
			}
			await loader.present();
			const dataUrls = await Promise.all(promises);
			await loader.dismiss();
			for (const i in dataUrls) {
				photos.push({
					dataUrl: dataUrls[i]
				});
			}
			return photos;
		} catch (error) {
			await loader.dismiss();
			if (error != null && (error.message === 'User cancelled photos app' || error.message === 'No image picked' || error.message === 'No images picked')) {
				return null;
			} else if (error != null && (error.message === 'User denied access to camera' || error.message === 'Unable to access camera, user denied permission request')) {
				throw 'CAMERA_DENIED';
			} else if (error != null && error.message === 'User denied access to photos') {
				throw 'LIBRARY_DENIED';
			} else {
				throw 'ERROR';
			}
		}
	}

	async chooseOrTakePhoto() {
		const actionSheetResult = await ActionSheet.showActions({
			options: [
				{
					title: 'Appareil Photo'
				},
				{
					title: 'Galerie'
				},
				{
					title: 'Annuler',
					style: ActionSheetButtonStyle.Destructive
				}
			]
		});
		switch (actionSheetResult.index) {
			case 0 :
				try {
					return await this.getPhoto();
				} catch (error) {
					throw error;
				}
			case 1 :
				try {
					return await this.pickPhotos();
				} catch (error) {
					throw error;
				}
			default :
				break;
		}
	}
}
