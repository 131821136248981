export class AppConfig {

	public static waitAfterLoader = 500;
	public static waitBeforeTransition = 500;

	public static monthShortNames = ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'];
	public static dayShortNames = ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'];

	public static itemsPerPageList = [10, 20, 50, 100];
	public static itemsPerPageDesktopDefault = 100;
	public static itemsPerPageMobileDefault = 20;

	public static scrollDuration = 1200;

	public static nbItemsPerPage = 10000;

	public static autocompleteStartSearching = 3;

	public static forms = [
		{table: 'accueil_securite', titre: 'Accueil sécurité'},
		{table: 'analyse_risques', titre: 'Analyse des risques'},
		{table: 'demande_travaux', titre: 'Demande de travaux'},
		{table: 'rapport_incident', titre: 'Rapport d’incident, accident, Situation dangereuse'},
		{table: 'inspection_chantier', titre: 'Inspection Atelier / Chantier'},
		{table: 'pv_reception', titre: 'PV de réception'},
		{table: 'cr_intervention', titre: 'Compte rendu d\'intervention'},
		{table: 'plan_prevention', titre: 'Plan de prévention'},
		{table: 'consigne_harnais', titre: 'Sensibilisation au port du harnais'},
		{table: 'demande_absence', titre: 'Demande d\'absence'},
		{table: 'ouverture_affaire', titre: 'Demande d\'ouverture d\'affaire'},
		{table: 'fiche_qualite', titre: 'Fiche qualité'}
	];

	public static profils = [
		{code: 'profil_1', value: 'Administrateur'},
		{code: 'profil_2', value: 'Responsable HSE'},
		{code: 'profil_3', value: 'Responsable BE élec'},
		{code: 'profil_4', value: 'Responsable achats / logistique'},
		{code: 'profil_5', value: 'Chef d’atelier'},
		{code: 'profil_6', value: 'Responsable BE automatisme'},
		{code: 'profil_7', value: 'Chargé d’affaire'},
		{code: 'profil_8', value: 'Assistante Administrative'},
		{code: 'profil_9', value: 'Assistant chargé d’affaire'},
		{code: 'profil_10', value: 'Chef d’équipe'},
		{code: 'profil_11', value: 'Chef de chantier Resp Equipes'},
		{code: 'profil_12', value: 'Technicien / Projeteur'},
		{code: 'profil_13', value: 'Monteur / cableur'},
		{code: 'profil_14', value: 'Administrateur Directrice Générale'},
		{code: 'profil_16', value: 'Administrateur Responsable Technique'},
		{code: 'profil_17', value: 'Magasinier'},
		{code: 'profil_18', value: 'Apprenti'},
		{code: 'profil_19', value: 'Intérimaire'}
	];

	public static categoriesBiblio = ['Document formation', 'Document technique', 'Flash info', 'Compte rendu planning'];

	public static devisStatus = ['A l\'étude', 'Attente réponse', 'Accepté', 'Révisé', 'Sans suite', 'Refusé'];
	public static affaireStatus = ['En cours', 'Terminée'];

	public static typesAbsence = ['Congés', 'RTT', 'Récupération', 'Arrêt maladie', 'Autre', 'École'];
	public static typesPlanningTache = ['Autre', 'Bureau', 'Dépannage', 'Formation', 'Télétravail', 'Visite médicale'];
	public static horaires = {
		debut: 8,
		fin: 18
	};

	public static heuresTravailJour = 8;

	public static feuilleHeuresStatus = ['draft', 'done', 'validated', 'verified'];
	public static feuilleHeuresProfilsNonConcernes = ['profil_1', 'profil_14'];

	public static horairesPlanning = {morningFrom: '08:00', morningTo: '12:00', afternoonFrom: '14:00', afternoonTo: '18:00'}

	public static typesPrime = ['Panier', 'Mobilité', 'Forfait grand déplacement', 'Prime nuité', 'Forfait nuité', 'Samedi forfait', 'Samedi contrat'];

	/* Fullcalendar */

	public static FullCalendarLicenceKey = '0296357684-fcs-1739267514';

	public static stemiCoordonnees = {lat: 46.197482, lng: 5.173612};
}
