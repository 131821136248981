import {UserObject} from '../../user.object';

import * as moment from 'moment';
import {FileService} from '../../../services/file.service';

export class AccueilSecuriteObject {
	id: number;
	table = 'accueil_securite';
	titre = 'Accueil sécurité';
	createdAt: string;
	updatedAt: string;
	status: string;
	createdBy: UserObject;
	updatedBy: UserObject;

	// Step 1

	id_affaire: number;

	// Step 2

	nom_client: string;
	code_client: string;
	nom_affaire: string;
	responsable: string;

	// Step 3

	aptitude_medicale: string;
	aptitude_medicale_description: string;
	autorisation_acces_chantier: string;
	autorisation_acces_chantier_description: string;
	titre_habilitation_electrique: string;
	titre_habilitation_electrique_description: string;
	titre_formation_chimique: string;
	titre_formation_chimique_description: string;
	cerfa_n1138302: string;
	cerfa_n1138302_description: string;
	autorisation_conduite_nacelle: string;
	autorisation_conduite_nacelle_description: string;
	caces_nacelle: string;
	caces_nacelle_description: string;
	autorisation_echaffaudage: string;
	autorisation_echaffaudage_description: string;
	casque_vetements_travail_chaussures_securite: string;
	casque_vetements_travail_chaussures_securite_description: string;
	vat_casque_idra: string;
	vat_casque_idra_description: string;
	outillage_individuel: string;
	outillage_individuel_description: string;
	travail_cours_effectuer: string;
	presence_signature_pdp_ppsps: string;
	explication_visite_chantier_realises: string;
	droit_alerte_retrait: string;

	// Step 3 missing

	aptitude_medicale_missing: boolean;
	aptitude_medicale_description_missing: boolean;
	autorisation_acces_chantier_missing: boolean;
	autorisation_acces_chantier_description_missing: boolean;
	titre_habilitation_electrique_missing: boolean;
	titre_habilitation_electrique_description_missing: boolean;
	titre_formation_chimique_missing: boolean;
	titre_formation_chimique_description_missing: boolean;
	cerfa_n1138302_missing: boolean;
	cerfa_n1138302_description_missing: boolean;
	autorisation_conduite_nacelle_missing: boolean;
	autorisation_conduite_nacelle_description_missing: boolean;
	caces_nacelle_missing: boolean;
	caces_nacelle_description_missing: boolean;
	autorisation_echaffaudage_missing: boolean;
	autorisation_echaffaudage_description_missing: boolean;
	casque_vetements_travail_chaussures_securite_missing: boolean;
	casque_vetements_travail_chaussures_securite_description_missing: boolean;
	vat_casque_idra_missing: boolean;
	vat_casque_idra_description_missing: boolean;
	outillage_individuel_missing: boolean;
	outillage_individuel_description_missing: boolean;
	travail_cours_effectuer_missing: boolean;
	presence_signature_pdp_ppsps_missing: boolean;
	explication_visite_chantier_realises_missing: boolean;
	droit_alerte_retrait_missing: boolean;

	// Step 4

	risques_electriques: boolean;
	risques_electriques_details_basse_tension: boolean;
	risques_electriques_details_haute_tension: boolean;
	risques_electriques_details_voisinage_tension: boolean;
	risques_electriques_details_energie_permanente: boolean;
	risques_electriques_prevention_habilitation: boolean;
	risques_electriques_prevention_consignation: boolean;
	risques_electriques_prevention_voisinage: boolean;
	risques_lies_circulation: boolean;
	risques_lies_circulation_details_vehicules: boolean;
	risques_lies_circulation_details_pietonne: boolean;
	risques_lies_circulation_moyens_prevention_regles: boolean;
	risques_lies_circulation_moyens_prevention_baliser: boolean;
	risques_lies_circulation_moyens_prevention_epi: boolean;
	risques_chute_hauteur: boolean;
	risques_chute_hauteur_details_nacelle: boolean;
	risques_chute_hauteur_details_toiture: boolean;
	risques_chute_hauteur_details_echafaudage: boolean;
	risques_chute_hauteur_details_echelle: boolean;
	risques_chute_hauteur_details_harnais: boolean;
	risques_chute_hauteur_prevention_materiel: boolean;
	risques_chute_hauteur_prevention_conduite: boolean;
	risques_chute_hauteur_prevention_balisage: boolean;
	risques_chute_hauteur_prevention_harnais: boolean;
	risques_chute_hauteur_prevention_permis: boolean;
	risques_chute_charge_objet: boolean;
	risques_chute_charge_objet_prevention_casque: boolean;
	risques_chute_charge_objet_prevention_baliser: boolean;
	risques_mecaniques: boolean;
	risques_mecaniques_details_manipulation: boolean;
	risques_mecaniques_details_mecanisme: boolean;
	risques_mecaniques_details_electro: boolean;
	risques_mecaniques_prevention_gants: boolean;
	risques_mecaniques_prevention_etat: boolean;
	risques_mecaniques_prevention_balisage: boolean;
	risques_mecaniques_prevention_permis: boolean;
	risques_mecaniques_prevention_mecaniques: boolean;
	risques_chimiques: boolean;
	risques_chimiques_details_manipulation: boolean;
	risques_chimiques_details_voisinage: boolean;
	risques_chimiques_prevention_epi: boolean;
	risques_chimiques_prevention_produit: boolean;
	risques_chimiques_prevention_epi_client: boolean;
	risques_incendie: boolean;
	risques_incendie_details_soudure: boolean;
	risques_incendie_details_produits: boolean;
	risques_incendie_prevention_consignes: boolean;
	risques_incendie_prevention_fumer: boolean;
	risques_incendie_prevention_atex: boolean;
	risques_incendie_prevention_permis: boolean;
	risques_incendie_prevention_extincteurs: boolean;
	risques_nuisances: boolean;
	risques_nuisances_details_poussieres: boolean;
	risques_nuisances_details_bruit: boolean;
	risques_nuisances_prevention_auditives: boolean;
	risques_nuisances_prevention_masque: boolean;
	risques_nuisances_prevention_lunettes: boolean;
	risques_nuisances_prevention_zone: boolean;
	risques_lies_coactivite: boolean;
	risques_lies_coactivite_details_entreprises: boolean;
	risques_lies_coactivite_prevention_nature: string;
	risques_lies_coactivite_prevention_precautions: string;
	risques_lies_coactivite_prevention_communication: string;
	risques_zone_alimentaire: boolean;
	risques_zone_alimentaire_prevention_regles: boolean;
	risques_zone_alimentaire_prevention_epi: boolean;
	autres_risques: boolean;
	autres_risques_type: string;
	autres_risques_prevention: string;

	// Step 4 missing

	risques_electriques_details_missing: boolean;
	risques_electriques_prevention_missing: boolean;
	risques_lies_circulation_details_missing: boolean;
	risques_lies_circulation_prevention_missing: boolean;
	risques_chute_hauteur_details_missing: boolean;
	risques_chute_hauteur_prevention_missing: boolean;
	risques_chute_charge_objet_prevention_missing: boolean;
	risques_mecaniques_details_missing: boolean;
	risques_mecaniques_prevention_missing: boolean;
	risques_chimiques_details_missing: boolean;
	risques_chimiques_prevention_missing: boolean;
	risques_incendie_details_missing: boolean;
	risques_incendie_prevention_missing: boolean;
	risques_nuisances_details_missing: boolean;
	risques_nuisances_prevention_missing: boolean;
	risques_lies_coactivite_details_missing: boolean;
	risques_lies_coactivite_prevention_missing: boolean;
	risques_zone_alimentaire_prevention_missing: boolean;
	autres_risques_type_missing: boolean;
	autres_risques_prevention_missing: boolean;

	// Step 5

	moyens_urgence_sortie: boolean;
	moyens_urgence_pharmacie: boolean;
	moyens_urgence_telephone: boolean;
	moyens_urgence_rassemblement: boolean;
	moyens_urgence_rincage: boolean;
	moyens_urgence_douche: boolean;
	moyens_urgence_extincteur: boolean;

	// Step 5 missing

	moyens_urgence_missing: boolean;

	// Step 6

	date_signature: string;
	signatories: Array<UserObject>;
	signatories_dates: Array<string>;
	personnes_supplementaires: Array<{ id: string, societe: string, societe_missing: boolean, nom: string, nom_missing: boolean, type: string, type_missing: boolean, date_signature: string, signature: any }>;

	// Status

	// draft
	// signed

	constructor(
		id = null,
		createdAt = null,
		updatedAt = null,
		status = 'draft',
		createdBy = null,
		updatedBy = null,
		// Step 1

		id_affaire = null,
		// Step 2

		nom_client = null,
		code_client = null,
		nom_affaire = null,
		responsable = null,
		// Step 3

		aptitude_medicale = null,
		aptitude_medicale_description = null,
		autorisation_acces_chantier = null,
		autorisation_acces_chantier_description = null,
		titre_habilitation_electrique = null,
		titre_habilitation_electrique_description = null,
		titre_formation_chimique = null,
		titre_formation_chimique_description = null,
		cerfa_n1138302 = null,
		cerfa_n1138302_description = null,
		autorisation_conduite_nacelle = null,
		autorisation_conduite_nacelle_description = null,
		caces_nacelle = null,
		caces_nacelle_description = null,
		autorisation_echaffaudage = null,
		autorisation_echaffaudage_description = null,
		casque_vetements_travail_chaussures_securite = null,
		casque_vetements_travail_chaussures_securite_description = null,
		vat_casque_idra = null,
		vat_casque_idra_description = null,
		outillage_individuel = null,
		outillage_individuel_description = null,
		travail_cours_effectuer = null,
		presence_signature_pdp_ppsps = null,
		explication_visite_chantier_realises = null,
		droit_alerte_retrait = null,
		// Step 4

		risques_electriques = false,
		risques_electriques_details_basse_tension = false,
		risques_electriques_details_haute_tension = false,
		risques_electriques_details_voisinage_tension = false,
		risques_electriques_details_energie_permanente = false,
		risques_electriques_prevention_habilitation = false,
		risques_electriques_prevention_consignation = false,
		risques_electriques_prevention_voisinage = false,
		risques_lies_circulation = false,
		risques_lies_circulation_details_vehicules = false,
		risques_lies_circulation_details_pietonne = false,
		risques_lies_circulation_moyens_prevention_regles = false,
		risques_lies_circulation_moyens_prevention_baliser = false,
		risques_lies_circulation_moyens_prevention_epi = false,
		risques_chute_hauteur = false,
		risques_chute_hauteur_details_nacelle = false,
		risques_chute_hauteur_details_toiture = false,
		risques_chute_hauteur_details_echafaudage = false,
		risques_chute_hauteur_details_echelle = false,
		risques_chute_hauteur_details_harnais = false,
		risques_chute_hauteur_prevention_materiel = false,
		risques_chute_hauteur_prevention_conduite = false,
		risques_chute_hauteur_prevention_balisage = false,
		risques_chute_hauteur_prevention_harnais = false,
		risques_chute_hauteur_prevention_permis = false,
		risques_chute_charge_objet = false,
		risques_chute_charge_objet_prevention_casque = false,
		risques_chute_charge_objet_prevention_baliser = false,
		risques_mecaniques = false,
		risques_mecaniques_details_manipulation = false,
		risques_mecaniques_details_mecanisme = false,
		risques_mecaniques_details_electro = false,
		risques_mecaniques_prevention_gants = false,
		risques_mecaniques_prevention_etat = false,
		risques_mecaniques_prevention_balisage = false,
		risques_mecaniques_prevention_permis = false,
		risques_mecaniques_prevention_mecaniques = false,
		risques_chimiques = false,
		risques_chimiques_details_manipulation = false,
		risques_chimiques_details_voisinage = false,
		risques_chimiques_prevention_epi = false,
		risques_chimiques_prevention_produit = false,
		risques_chimiques_prevention_epi_client = false,
		risques_incendie = false,
		risques_incendie_details_soudure = false,
		risques_incendie_details_produits = false,
		risques_incendie_prevention_consignes = false,
		risques_incendie_prevention_fumer = false,
		risques_incendie_prevention_atex = false,
		risques_incendie_prevention_permis = false,
		risques_incendie_prevention_extincteurs = false,
		risques_nuisances = false,
		risques_nuisances_details_poussieres = false,
		risques_nuisances_details_bruit = false,
		risques_nuisances_prevention_auditives = false,
		risques_nuisances_prevention_masque = false,
		risques_nuisances_prevention_lunettes = false,
		risques_nuisances_prevention_zone = false,
		risques_lies_coactivite = false,
		risques_lies_coactivite_details_entreprises = false,
		risques_lies_coactivite_prevention_nature = null,
		risques_lies_coactivite_prevention_precautions = null,
		risques_lies_coactivite_prevention_communication = null,
		risques_zone_alimentaire = false,
		risques_zone_alimentaire_prevention_regles = false,
		risques_zone_alimentaire_prevention_epi = false,
		autres_risques = false,
		autres_risques_type = null,
		autres_risques_prevention = null,
		// Step 5

		moyens_urgence_sortie = false,
		moyens_urgence_pharmacie = false,
		moyens_urgence_telephone = false,
		moyens_urgence_rassemblement = false,
		moyens_urgence_rincage = false,
		moyens_urgence_douche = false,
		moyens_urgence_extincteur = false,
		// Step 6

		date_signature = null,
		signatories = [],
		signatories_dates = [],
		personnes_supplementaires = [],
	) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;

		// Step 1

		this.id_affaire = id_affaire;

		// Step 2

		this.nom_client = nom_client;
		this.code_client = code_client;
		this.nom_affaire = nom_affaire;
		this.responsable = responsable;

		// Step 3

		this.aptitude_medicale = aptitude_medicale;
		this.aptitude_medicale_description = aptitude_medicale_description;
		this.autorisation_acces_chantier = autorisation_acces_chantier;
		this.autorisation_acces_chantier_description = autorisation_acces_chantier_description;
		this.titre_habilitation_electrique = titre_habilitation_electrique;
		this.titre_habilitation_electrique_description = titre_habilitation_electrique_description;
		this.titre_formation_chimique = titre_formation_chimique;
		this.titre_formation_chimique_description = titre_formation_chimique_description;
		this.cerfa_n1138302 = cerfa_n1138302;
		this.cerfa_n1138302_description = cerfa_n1138302_description;
		this.autorisation_conduite_nacelle = autorisation_conduite_nacelle;
		this.autorisation_conduite_nacelle_description = autorisation_conduite_nacelle_description;
		this.caces_nacelle = caces_nacelle;
		this.caces_nacelle_description = caces_nacelle_description;
		this.autorisation_echaffaudage = autorisation_echaffaudage;
		this.autorisation_echaffaudage_description = autorisation_echaffaudage_description;
		this.casque_vetements_travail_chaussures_securite = casque_vetements_travail_chaussures_securite;
		this.casque_vetements_travail_chaussures_securite_description = casque_vetements_travail_chaussures_securite_description;
		this.vat_casque_idra = vat_casque_idra;
		this.vat_casque_idra_description = vat_casque_idra_description;
		this.outillage_individuel = outillage_individuel;
		this.outillage_individuel_description = outillage_individuel_description;
		this.travail_cours_effectuer = travail_cours_effectuer;
		this.presence_signature_pdp_ppsps = presence_signature_pdp_ppsps;
		this.explication_visite_chantier_realises = explication_visite_chantier_realises;
		this.droit_alerte_retrait = droit_alerte_retrait;

		// Step 3 missing

		this.aptitude_medicale_missing = false;
		this.aptitude_medicale_description_missing = false;
		this.autorisation_acces_chantier_missing = false;
		this.autorisation_acces_chantier_description_missing = false;
		this.titre_habilitation_electrique_missing = false;
		this.titre_habilitation_electrique_description_missing = false;
		this.titre_formation_chimique_missing = false;
		this.titre_formation_chimique_description_missing = false;
		this.cerfa_n1138302_missing = false;
		this.cerfa_n1138302_description_missing = false;
		this.autorisation_conduite_nacelle_missing = false;
		this.autorisation_conduite_nacelle_description_missing = false;
		this.caces_nacelle_missing = false;
		this.caces_nacelle_description_missing = false;
		this.autorisation_echaffaudage_missing = false;
		this.autorisation_echaffaudage_description_missing = false;
		this.casque_vetements_travail_chaussures_securite_missing = false;
		this.casque_vetements_travail_chaussures_securite_description_missing = false;
		this.vat_casque_idra_missing = false;
		this.vat_casque_idra_description_missing = false;
		this.outillage_individuel_missing = false;
		this.outillage_individuel_description_missing = false;
		this.travail_cours_effectuer_missing = false;
		this.presence_signature_pdp_ppsps_missing = false;
		this.explication_visite_chantier_realises_missing = false;
		this.droit_alerte_retrait_missing = false;

		// Step 4

		this.risques_electriques = risques_electriques;
		this.risques_electriques_details_basse_tension = risques_electriques_details_basse_tension;
		this.risques_electriques_details_haute_tension = risques_electriques_details_haute_tension;
		this.risques_electriques_details_voisinage_tension = risques_electriques_details_voisinage_tension;
		this.risques_electriques_details_energie_permanente = risques_electriques_details_energie_permanente;
		this.risques_electriques_prevention_habilitation = risques_electriques_prevention_habilitation;
		this.risques_electriques_prevention_consignation = risques_electriques_prevention_consignation;
		this.risques_electriques_prevention_voisinage = risques_electriques_prevention_voisinage;
		this.risques_lies_circulation = risques_lies_circulation;
		this.risques_lies_circulation_details_vehicules = risques_lies_circulation_details_vehicules;
		this.risques_lies_circulation_details_pietonne = risques_lies_circulation_details_pietonne;
		this.risques_lies_circulation_moyens_prevention_regles = risques_lies_circulation_moyens_prevention_regles;
		this.risques_lies_circulation_moyens_prevention_baliser = risques_lies_circulation_moyens_prevention_baliser;
		this.risques_lies_circulation_moyens_prevention_epi = risques_lies_circulation_moyens_prevention_epi;
		this.risques_chute_hauteur = risques_chute_hauteur;
		this.risques_chute_hauteur_details_nacelle = risques_chute_hauteur_details_nacelle;
		this.risques_chute_hauteur_details_toiture = risques_chute_hauteur_details_toiture;
		this.risques_chute_hauteur_details_echafaudage = risques_chute_hauteur_details_echafaudage;
		this.risques_chute_hauteur_details_echelle = risques_chute_hauteur_details_echelle;
		this.risques_chute_hauteur_details_harnais = risques_chute_hauteur_details_harnais;
		this.risques_chute_hauteur_prevention_materiel = risques_chute_hauteur_prevention_materiel;
		this.risques_chute_hauteur_prevention_conduite = risques_chute_hauteur_prevention_conduite;
		this.risques_chute_hauteur_prevention_balisage = risques_chute_hauteur_prevention_balisage;
		this.risques_chute_hauteur_prevention_harnais = risques_chute_hauteur_prevention_harnais;
		this.risques_chute_hauteur_prevention_permis = risques_chute_hauteur_prevention_permis;
		this.risques_chute_charge_objet = risques_chute_charge_objet;
		this.risques_chute_charge_objet_prevention_casque = risques_chute_charge_objet_prevention_casque;
		this.risques_chute_charge_objet_prevention_baliser = risques_chute_charge_objet_prevention_baliser;
		this.risques_mecaniques = risques_mecaniques;
		this.risques_mecaniques_details_manipulation = risques_mecaniques_details_manipulation;
		this.risques_mecaniques_details_mecanisme = risques_mecaniques_details_mecanisme;
		this.risques_mecaniques_details_electro = risques_mecaniques_details_electro;
		this.risques_mecaniques_prevention_gants = risques_mecaniques_prevention_gants;
		this.risques_mecaniques_prevention_etat = risques_mecaniques_prevention_etat;
		this.risques_mecaniques_prevention_balisage = risques_mecaniques_prevention_balisage;
		this.risques_mecaniques_prevention_permis = risques_mecaniques_prevention_permis;
		this.risques_mecaniques_prevention_mecaniques = risques_mecaniques_prevention_mecaniques;
		this.risques_chimiques = risques_chimiques;
		this.risques_chimiques_details_manipulation = risques_chimiques_details_manipulation;
		this.risques_chimiques_details_voisinage = risques_chimiques_details_voisinage;
		this.risques_chimiques_prevention_epi = risques_chimiques_prevention_epi;
		this.risques_chimiques_prevention_produit = risques_chimiques_prevention_produit;
		this.risques_chimiques_prevention_epi_client = risques_chimiques_prevention_epi_client;
		this.risques_incendie = risques_incendie;
		this.risques_incendie_details_soudure = risques_incendie_details_soudure;
		this.risques_incendie_details_produits = risques_incendie_details_produits;
		this.risques_incendie_prevention_consignes = risques_incendie_prevention_consignes;
		this.risques_incendie_prevention_fumer = risques_incendie_prevention_fumer;
		this.risques_incendie_prevention_atex = risques_incendie_prevention_atex;
		this.risques_incendie_prevention_permis = risques_incendie_prevention_permis;
		this.risques_incendie_prevention_extincteurs = risques_incendie_prevention_extincteurs;
		this.risques_nuisances = risques_nuisances;
		this.risques_nuisances_details_poussieres = risques_nuisances_details_poussieres;
		this.risques_nuisances_details_bruit = risques_nuisances_details_bruit;
		this.risques_nuisances_prevention_auditives = risques_nuisances_prevention_auditives;
		this.risques_nuisances_prevention_masque = risques_nuisances_prevention_masque;
		this.risques_nuisances_prevention_lunettes = risques_nuisances_prevention_lunettes;
		this.risques_nuisances_prevention_zone = risques_nuisances_prevention_zone;
		this.risques_lies_coactivite = risques_lies_coactivite;
		this.risques_lies_coactivite_details_entreprises = risques_lies_coactivite_details_entreprises;
		this.risques_lies_coactivite_prevention_nature = risques_lies_coactivite_prevention_nature;
		this.risques_lies_coactivite_prevention_precautions = risques_lies_coactivite_prevention_precautions;
		this.risques_lies_coactivite_prevention_communication = risques_lies_coactivite_prevention_communication;
		this.risques_zone_alimentaire = risques_zone_alimentaire;
		this.risques_zone_alimentaire_prevention_regles = risques_zone_alimentaire_prevention_regles;
		this.risques_zone_alimentaire_prevention_epi = risques_zone_alimentaire_prevention_epi;
		this.autres_risques = autres_risques;
		this.autres_risques_type = autres_risques_type;
		this.autres_risques_prevention = autres_risques_prevention;

		// Step 4 missing

		this.risques_electriques_details_missing = false;
		this.risques_electriques_prevention_missing = false;
		this.risques_lies_circulation_details_missing = false;
		this.risques_lies_circulation_prevention_missing = false;
		this.risques_chute_hauteur_details_missing = false;
		this.risques_chute_hauteur_prevention_missing = false;
		this.risques_chute_charge_objet_prevention_missing = false;
		this.risques_mecaniques_details_missing = false;
		this.risques_mecaniques_prevention_missing = false;
		this.risques_chimiques_details_missing = false;
		this.risques_chimiques_prevention_missing = false;
		this.risques_incendie_details_missing = false;
		this.risques_incendie_prevention_missing = false;
		this.risques_nuisances_details_missing = false;
		this.risques_nuisances_prevention_missing = false;
		this.risques_lies_coactivite_details_missing = false;
		this.risques_lies_coactivite_prevention_missing = false;
		this.risques_zone_alimentaire_prevention_missing = false;
		this.autres_risques_type_missing = false;
		this.autres_risques_prevention_missing = false;

		// Step 5

		this.moyens_urgence_sortie = moyens_urgence_sortie;
		this.moyens_urgence_pharmacie = moyens_urgence_pharmacie;
		this.moyens_urgence_telephone = moyens_urgence_telephone;
		this.moyens_urgence_rassemblement = moyens_urgence_rassemblement;
		this.moyens_urgence_rincage = moyens_urgence_rincage;
		this.moyens_urgence_douche = moyens_urgence_douche;
		this.moyens_urgence_extincteur = moyens_urgence_extincteur;

		// Step 5 missing

		this.moyens_urgence_missing = false;

		// Step 6

		this.date_signature = date_signature;
		this.signatories = signatories;
		this.signatories_dates = signatories_dates;
		this.personnes_supplementaires = personnes_supplementaires;
	}

	clearRelated(source) {
		switch (source) {
			case 'aptitude_medicale' :
				this.aptitude_medicale_description = null;
				break;
			case 'autorisation_acces_chantier' :
				this.autorisation_acces_chantier_description = null;
				break;
			case 'titre_habilitation_electrique' :
				this.titre_habilitation_electrique_description = null;
				break;
			case 'titre_formation_chimique' :
				this.titre_formation_chimique_description = null;
				break;
			case 'cerfa_n1138302' :
				this.cerfa_n1138302_description = null;
				break;
			case 'autorisation_conduite_nacelle' :
				this.autorisation_conduite_nacelle_description = null;
				break;
			case 'caces_nacelle' :
				this.caces_nacelle_description = null;
				break;
			case 'autorisation_echaffaudage' :
				this.autorisation_echaffaudage_description = null;
				break;
			case 'casque_vetements_travail_chaussures_securite' :
				this.casque_vetements_travail_chaussures_securite_description = null;
				break;
			case 'vat_casque_idra' :
				this.vat_casque_idra_description = null;
				break;
			case 'outillage_individuel' :
				this.outillage_individuel_description = null;
				break;
			case 'risques_electriques' :
				if (!this.risques_electriques) {
					this.risques_electriques_details_basse_tension = false;
					this.risques_electriques_details_haute_tension = false;
					this.risques_electriques_details_voisinage_tension = false;
					this.risques_electriques_details_energie_permanente = false;
					this.risques_electriques_prevention_habilitation = false;
					this.risques_electriques_prevention_consignation = false;
					this.risques_electriques_prevention_voisinage = false;
				}
				break;
			case 'risques_lies_circulation' :
				if (!this.risques_lies_circulation) {
					this.risques_lies_circulation_details_vehicules = false;
					this.risques_lies_circulation_details_pietonne = false;
					this.risques_lies_circulation_moyens_prevention_regles = false;
					this.risques_lies_circulation_moyens_prevention_baliser = false;
					this.risques_lies_circulation_moyens_prevention_epi = false;
				}
				break;
			case 'risques_chute_hauteur' :
				if (!this.risques_chute_hauteur) {
					this.risques_chute_hauteur_details_nacelle = false;
					this.risques_chute_hauteur_details_toiture = false;
					this.risques_chute_hauteur_details_echafaudage = false;
					this.risques_chute_hauteur_details_echelle = false;
					this.risques_chute_hauteur_details_harnais = false;
					this.risques_chute_hauteur_prevention_materiel = false;
					this.risques_chute_hauteur_prevention_conduite = false;
					this.risques_chute_hauteur_prevention_balisage = false;
					this.risques_chute_hauteur_prevention_harnais = false;
					this.risques_chute_hauteur_prevention_permis = false;
				}
				break;
			case 'risques_chute_charge_objet' :
				if (!this.risques_chute_charge_objet) {
					this.risques_chute_charge_objet_prevention_casque = false;
					this.risques_chute_charge_objet_prevention_baliser = false;
				}
				break;
			case 'risques_mecaniques' :
				if (!this.risques_mecaniques) {
					this.risques_mecaniques_details_manipulation = false;
					this.risques_mecaniques_details_mecanisme = false;
					this.risques_mecaniques_details_electro = false;
					this.risques_mecaniques_prevention_gants = false;
					this.risques_mecaniques_prevention_etat = false;
					this.risques_mecaniques_prevention_balisage = false;
					this.risques_mecaniques_prevention_permis = false;
					this.risques_mecaniques_prevention_mecaniques = false;
				}
				break;
			case 'risques_chimiques' :
				if (!this.risques_chimiques) {
					this.risques_chimiques_details_manipulation = false;
					this.risques_chimiques_details_voisinage = false;
					this.risques_chimiques_prevention_epi = false;
					this.risques_chimiques_prevention_produit = false;
					this.risques_chimiques_prevention_epi_client = false;
				}
				break;
			case 'risques_incendie' :
				if (!this.risques_incendie) {
					this.risques_incendie_details_soudure = false;
					this.risques_incendie_details_produits = false;
					this.risques_incendie_prevention_consignes = false;
					this.risques_incendie_prevention_fumer = false;
					this.risques_incendie_prevention_atex = false;
					this.risques_incendie_prevention_permis = false;
					this.risques_incendie_prevention_extincteurs = false;
				}
				break;
			case 'risques_nuisances' :
				if (!this.risques_nuisances) {
					this.risques_nuisances_details_poussieres = false;
					this.risques_nuisances_details_bruit = false;
					this.risques_nuisances_prevention_auditives = false;
					this.risques_nuisances_prevention_masque = false;
					this.risques_nuisances_prevention_lunettes = false;
					this.risques_nuisances_prevention_zone = false;
				}
				break;
			case 'risques_lies_coactivite' :
				if (!this.risques_lies_coactivite) {
					this.risques_lies_coactivite_details_entreprises = false;
					this.risques_lies_coactivite_prevention_nature = null;
					this.risques_lies_coactivite_prevention_precautions = null;
					this.risques_lies_coactivite_prevention_communication = null;
				}
				break;
			case 'risques_zone_alimentaire' :
				if (!this.risques_zone_alimentaire) {
					this.risques_zone_alimentaire_prevention_regles = false;
					this.risques_zone_alimentaire_prevention_epi = false;
				}
				break;
			case 'autres_risques' :
				if (!this.autres_risques) {
					this.autres_risques_type = null;
					this.autres_risques_prevention = null;
				}
				break;
			default :
				break;
		}
	}

	missingMandatoryFields(step) {
		let missingMandatory = false;
		switch (step) {
			case 3 :
				if (this.aptitude_medicale == null) {
					this.aptitude_medicale_missing = true;
					missingMandatory = true;
				}
				if (this.aptitude_medicale === 'NC' && (this.aptitude_medicale_description == null || this.aptitude_medicale_description === '')) {
					this.aptitude_medicale_description_missing = true;
					missingMandatory = true;
				}
				if (this.autorisation_acces_chantier == null) {
					this.autorisation_acces_chantier_missing = true;
					missingMandatory = true;
				}
				if (this.autorisation_acces_chantier === 'NC' && (this.autorisation_acces_chantier_description == null || this.autorisation_acces_chantier_description === '')) {
					this.autorisation_acces_chantier_description_missing = true;
					missingMandatory = true;
				}
				if (this.titre_habilitation_electrique == null) {
					this.titre_habilitation_electrique_missing = true;
					missingMandatory = true;
				}
				if (this.titre_habilitation_electrique === 'NC' && (this.titre_habilitation_electrique_description == null || this.titre_habilitation_electrique_description === '')) {
					this.titre_habilitation_electrique_description_missing = true;
					missingMandatory = true;
				}
				if (this.titre_formation_chimique == null) {
					this.titre_formation_chimique_missing = true;
					missingMandatory = true;
				}
				if (this.titre_formation_chimique === 'NC' && (this.titre_formation_chimique_description == null || this.titre_formation_chimique_description === '')) {
					this.titre_formation_chimique_description_missing = true;
					missingMandatory = true;
				}
				if (this.cerfa_n1138302 == null) {
					this.cerfa_n1138302_missing = true;
					missingMandatory = true;
				}
				if (this.cerfa_n1138302 === 'NC' && (this.cerfa_n1138302_description == null || this.cerfa_n1138302_description === '')) {
					this.cerfa_n1138302_description_missing = true;
					missingMandatory = true;
				}
				if (this.autorisation_conduite_nacelle == null) {
					this.autorisation_conduite_nacelle_missing = true;
					missingMandatory = true;
				}
				if (this.autorisation_conduite_nacelle === 'NC' && (this.autorisation_conduite_nacelle_description == null || this.autorisation_conduite_nacelle_description === '')) {
					this.autorisation_conduite_nacelle_description_missing = true;
					missingMandatory = true;
				}
				if (this.caces_nacelle == null) {
					this.caces_nacelle_missing = true;
					missingMandatory = true;
				}
				if (this.caces_nacelle === 'NC' && (this.caces_nacelle_description == null || this.caces_nacelle_description === '')) {
					this.caces_nacelle_description_missing = true;
					missingMandatory = true;
				}
				if (this.autorisation_echaffaudage == null) {
					this.autorisation_echaffaudage_missing = true;
					missingMandatory = true;
				}
				if (this.autorisation_echaffaudage === 'NC' && (this.autorisation_echaffaudage_description == null || this.autorisation_echaffaudage_description === '')) {
					this.autorisation_echaffaudage_description_missing = true;
					missingMandatory = true;
				}
				if (this.casque_vetements_travail_chaussures_securite == null) {
					this.casque_vetements_travail_chaussures_securite_missing = true;
					missingMandatory = true;
				}
				if (this.casque_vetements_travail_chaussures_securite === 'NC' && (this.casque_vetements_travail_chaussures_securite_description == null || this.casque_vetements_travail_chaussures_securite_description === '')) {
					this.casque_vetements_travail_chaussures_securite_description_missing = true;
					missingMandatory = true;
				}
				if (this.vat_casque_idra == null) {
					this.vat_casque_idra_missing = true;
					missingMandatory = true;
				}
				if (this.vat_casque_idra === 'NC' && (this.vat_casque_idra_description == null || this.vat_casque_idra_description === '')) {
					this.vat_casque_idra_description_missing = true;
					missingMandatory = true;
				}
				if (this.outillage_individuel == null) {
					this.outillage_individuel_missing = true;
					missingMandatory = true;
				}
				if (this.outillage_individuel === 'NC' && (this.outillage_individuel_description == null || this.outillage_individuel_description === '')) {
					this.outillage_individuel_description_missing = true;
					missingMandatory = true;
				}
				if (this.travail_cours_effectuer == null) {
					this.travail_cours_effectuer_missing = true;
					missingMandatory = true;
				}
				if (this.presence_signature_pdp_ppsps == null) {
					this.presence_signature_pdp_ppsps_missing = true;
					missingMandatory = true;
				}
				if (this.explication_visite_chantier_realises == null) {
					this.explication_visite_chantier_realises_missing = true;
					missingMandatory = true;
				}
				if (this.droit_alerte_retrait == null) {
					this.droit_alerte_retrait_missing = true;
					missingMandatory = true;
				}
				break;
			case 4 :
				if (this.risques_electriques) {
					if (!this.risques_electriques_details_basse_tension &&
						!this.risques_electriques_details_haute_tension &&
						!this.risques_electriques_details_voisinage_tension &&
						!this.risques_electriques_details_energie_permanente) {
						this.risques_electriques_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_electriques_prevention_habilitation &&
						!this.risques_electriques_prevention_consignation &&
						!this.risques_electriques_prevention_voisinage) {
						this.risques_electriques_prevention_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_lies_circulation) {
					if (!this.risques_lies_circulation_details_vehicules &&
						!this.risques_lies_circulation_details_pietonne) {
						this.risques_lies_circulation_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_lies_circulation_moyens_prevention_regles &&
						!this.risques_lies_circulation_moyens_prevention_baliser &&
						!this.risques_lies_circulation_moyens_prevention_epi) {
						this.risques_lies_circulation_prevention_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_chute_hauteur) {
					if (!this.risques_chute_hauteur_details_nacelle &&
						!this.risques_chute_hauteur_details_toiture &&
						!this.risques_chute_hauteur_details_echafaudage &&
						!this.risques_chute_hauteur_details_echelle &&
						!this.risques_chute_hauteur_details_harnais
					) {
						this.risques_chute_hauteur_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_chute_hauteur_prevention_materiel &&
						!this.risques_chute_hauteur_prevention_conduite &&
						!this.risques_chute_hauteur_prevention_balisage &&
						!this.risques_chute_hauteur_prevention_harnais &&
						!this.risques_chute_hauteur_prevention_permis) {
						this.risques_chute_hauteur_prevention_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_chute_charge_objet) {
					if (!this.risques_chute_charge_objet_prevention_casque &&
						!this.risques_chute_charge_objet_prevention_baliser) {
						this.risques_chute_charge_objet_prevention_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_mecaniques) {
					if (!this.risques_mecaniques_details_manipulation &&
						!this.risques_mecaniques_details_mecanisme &&
						!this.risques_mecaniques_details_electro) {
						this.risques_mecaniques_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_mecaniques_prevention_gants &&
						!this.risques_mecaniques_prevention_etat &&
						!this.risques_mecaniques_prevention_balisage &&
						!this.risques_mecaniques_prevention_permis &&
						!this.risques_mecaniques_prevention_mecaniques) {
						this.risques_mecaniques_prevention_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_chimiques) {
					if (!this.risques_chimiques_details_manipulation &&
						!this.risques_chimiques_details_voisinage) {
						this.risques_chimiques_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_chimiques_prevention_epi &&
						!this.risques_chimiques_prevention_produit &&
						!this.risques_chimiques_prevention_epi_client) {
						this.risques_chimiques_prevention_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_incendie) {
					if (!this.risques_incendie_details_soudure &&
						!this.risques_incendie_details_produits) {
						this.risques_incendie_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_incendie_prevention_consignes &&
						!this.risques_incendie_prevention_fumer &&
						!this.risques_incendie_prevention_atex &&
						!this.risques_incendie_prevention_permis &&
						!this.risques_incendie_prevention_extincteurs) {
						this.risques_incendie_prevention_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_nuisances) {
					if (!this.risques_nuisances_details_poussieres &&
						!this.risques_nuisances_details_bruit) {
						this.risques_nuisances_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_nuisances_prevention_auditives &&
						!this.risques_nuisances_prevention_masque &&
						!this.risques_nuisances_prevention_lunettes &&
						!this.risques_nuisances_prevention_zone) {
						this.risques_nuisances_prevention_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_lies_coactivite) {
					if (!this.risques_lies_coactivite_details_entreprises) {
						this.risques_lies_coactivite_details_missing = true;
						missingMandatory = true;
					}
					if ((this.risques_lies_coactivite_prevention_nature == null || this.risques_lies_coactivite_prevention_nature === '') &&
						(this.risques_lies_coactivite_prevention_precautions == null || this.risques_lies_coactivite_prevention_precautions === '') &&
						(this.risques_lies_coactivite_prevention_communication == null || this.risques_lies_coactivite_prevention_communication === '')) {
						this.risques_lies_coactivite_prevention_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_zone_alimentaire) {
					if (!this.risques_zone_alimentaire_prevention_regles &&
						!this.risques_zone_alimentaire_prevention_epi) {
						this.risques_zone_alimentaire_prevention_missing = true;
						missingMandatory = true;
					}
				}
				if (this.autres_risques) {
					if (this.autres_risques_type == null || this.autres_risques_type === '') {
						this.autres_risques_type_missing = true;
						missingMandatory = true;
					}
					if (this.autres_risques_prevention == null || this.autres_risques_prevention === '') {
						this.autres_risques_prevention_missing = true;
						missingMandatory = true;
					}
				}
				break;
			case 5 :
				if (!this.moyens_urgence_sortie &&
					!this.moyens_urgence_pharmacie &&
					!this.moyens_urgence_telephone &&
					!this.moyens_urgence_rassemblement &&
					!this.moyens_urgence_rincage &&
					!this.moyens_urgence_douche &&
					!this.moyens_urgence_extincteur) {
					this.moyens_urgence_missing = true;
					missingMandatory = true;
				}
				break;
			default :
				break;
		}
		return missingMandatory;
	}

	updateMissing(source) {
		switch (source) {
			case 'aptitude_medicale' :
				if (this.aptitude_medicale != null) {
					this.aptitude_medicale_missing = false;
				}
				if (this.aptitude_medicale !== 'NC') {
					this.aptitude_medicale_description_missing = false;
				}
				break;
			case 'aptitude_medicale_description' :
				if (this.aptitude_medicale_description != null) {
					this.aptitude_medicale_description_missing = false;
				}
				break;
			case 'autorisation_acces_chantier' :
				if (this.autorisation_acces_chantier != null) {
					this.autorisation_acces_chantier_missing = false;
				}
				if (this.autorisation_acces_chantier !== 'NC') {
					this.autorisation_acces_chantier_description_missing = false;
				}
				break;
			case 'autorisation_acces_chantier_description' :
				if (this.autorisation_acces_chantier_description != null) {
					this.autorisation_acces_chantier_description_missing = false;
				}
				break;
			case 'titre_habilitation_electrique' :
				if (this.titre_habilitation_electrique != null) {
					this.titre_habilitation_electrique_missing = false;
				}
				if (this.titre_habilitation_electrique !== 'NC') {
					this.titre_habilitation_electrique_description_missing = false;
				}
				break;
			case 'titre_habilitation_electrique_description' :
				if (this.titre_habilitation_electrique_description != null) {
					this.titre_habilitation_electrique_description_missing = false;
				}
				break;
			case 'titre_formation_chimique' :
				if (this.titre_formation_chimique != null) {
					this.titre_formation_chimique_missing = false;
				}
				if (this.titre_formation_chimique !== 'NC') {
					this.titre_formation_chimique_description_missing = false;
				}
				break;
			case 'titre_formation_chimique_description' :
				if (this.titre_formation_chimique_description != null) {
					this.titre_formation_chimique_description_missing = false;
				}
				break;
			case 'cerfa_n1138302' :
				if (this.cerfa_n1138302 != null) {
					this.cerfa_n1138302_missing = false;
				}
				if (this.cerfa_n1138302 !== 'NC') {
					this.cerfa_n1138302_description_missing = false;
				}
				break;
			case 'cerfa_n1138302_description' :
				if (this.cerfa_n1138302_description != null) {
					this.cerfa_n1138302_description_missing = false;
				}
				break;
			case 'autorisation_conduite_nacelle' :
				if (this.autorisation_conduite_nacelle != null) {
					this.autorisation_conduite_nacelle_missing = false;
				}
				if (this.autorisation_conduite_nacelle !== 'NC') {
					this.autorisation_conduite_nacelle_description_missing = false;
				}
				break;
			case 'autorisation_conduite_nacelle_description' :
				if (this.autorisation_conduite_nacelle_description != null) {
					this.autorisation_conduite_nacelle_description_missing = false;
				}
				break;
			case 'caces_nacelle' :
				if (this.caces_nacelle != null) {
					this.caces_nacelle_missing = false;
				}
				if (this.caces_nacelle !== 'NC') {
					this.caces_nacelle_description_missing = false;
				}
				break;
			case 'caces_nacelle_description' :
				if (this.caces_nacelle_description != null) {
					this.caces_nacelle_description_missing = false;
				}
				break;
			case 'autorisation_echaffaudage' :
				if (this.autorisation_echaffaudage != null) {
					this.autorisation_echaffaudage_missing = false;
				}
				if (this.autorisation_echaffaudage !== 'NC') {
					this.autorisation_echaffaudage_description_missing = false;
				}
				break;
			case 'autorisation_echaffaudage_description' :
				if (this.autorisation_echaffaudage_description != null) {
					this.autorisation_echaffaudage_description_missing = false;
				}
				break;
			case 'casque_vetements_travail_chaussures_securite' :
				if (this.casque_vetements_travail_chaussures_securite != null) {
					this.casque_vetements_travail_chaussures_securite_missing = false;
				}
				if (this.casque_vetements_travail_chaussures_securite !== 'NC') {
					this.casque_vetements_travail_chaussures_securite_description_missing = false;
				}
				break;
			case 'casque_vetements_travail_chaussures_securite_description' :
				if (this.casque_vetements_travail_chaussures_securite_description != null) {
					this.casque_vetements_travail_chaussures_securite_description_missing = false;
				}
				break;
			case 'vat_casque_idra' :
				if (this.vat_casque_idra != null) {
					this.vat_casque_idra_missing = false;
				}
				if (this.vat_casque_idra !== 'NC') {
					this.vat_casque_idra_description_missing = false;
				}
				break;
			case 'vat_casque_idra_description' :
				if (this.vat_casque_idra_description) {
					this.vat_casque_idra_description_missing = false;
				}
				break;
			case 'outillage_individuel' :
				if (this.outillage_individuel != null) {
					this.outillage_individuel_missing = false;
				}
				if (this.outillage_individuel !== 'NC') {
					this.outillage_individuel_description_missing = false;
				}
				break;
			case 'outillage_individuel_description' :
				if (this.outillage_individuel_description != null) {
					this.outillage_individuel_description_missing = false;
				}
				break;
			case 'travail_cours_effectuer' :
				if (this.travail_cours_effectuer != null) {
					this.travail_cours_effectuer_missing = false;
				}
				break;
			case 'presence_signature_pdp_ppsps' :
				if (this.presence_signature_pdp_ppsps != null) {
					this.presence_signature_pdp_ppsps_missing = false;
				}
				break;
			case 'explication_visite_chantier_realises' :
				if (this.explication_visite_chantier_realises != null) {
					this.explication_visite_chantier_realises_missing = false;
				}
				break;
			case 'droit_alerte_retrait' :
				if (this.droit_alerte_retrait != null) {
					this.droit_alerte_retrait_missing = false;
				}
				break;
			case 'risques_electriques_details' :
				if (!this.risques_electriques) {
					this.risques_electriques_details_missing = false;
				} else if (this.risques_electriques_details_basse_tension ||
					this.risques_electriques_details_haute_tension ||
					this.risques_electriques_details_voisinage_tension ||
					this.risques_electriques_details_energie_permanente) {
					this.risques_electriques_details_missing = false;
				}
				break;
			case 'risques_electriques_prevention' :
				if (!this.risques_electriques) {
					this.risques_electriques_prevention_missing = false;
				} else if (this.risques_electriques_prevention_habilitation ||
					this.risques_electriques_prevention_consignation ||
					this.risques_electriques_prevention_voisinage) {
					this.risques_electriques_prevention_missing = false;
				}
				break;
			case 'risques_lies_circulation_details' :
				if (!this.risques_lies_circulation) {
					this.risques_lies_circulation_details_missing = false;
				} else if (this.risques_lies_circulation_details_vehicules ||
					this.risques_lies_circulation_details_pietonne) {
					this.risques_lies_circulation_details_missing = false;
				}
				break;
			case 'risques_lies_circulation_prevention' :
				if (!this.risques_electriques) {
					this.risques_lies_circulation_prevention_missing = false;
				} else if (this.risques_lies_circulation_moyens_prevention_regles ||
					this.risques_lies_circulation_moyens_prevention_baliser ||
					this.risques_lies_circulation_moyens_prevention_epi) {
					this.risques_lies_circulation_prevention_missing = false;
				}
				break;
			case 'risques_chute_hauteur_details' :
				if (!this.risques_chute_hauteur) {
					this.risques_chute_hauteur_details_missing = false;
				} else if (this.risques_chute_hauteur_details_nacelle ||
					this.risques_chute_hauteur_details_toiture ||
					this.risques_chute_hauteur_details_echafaudage ||
					this.risques_chute_hauteur_details_echelle ||
					this.risques_chute_hauteur_details_harnais
				) {
					this.risques_chute_hauteur_details_missing = false;
				}
				break;
			case 'risques_chute_hauteur_prevention' :
				if (!this.risques_chute_hauteur) {
					this.risques_chute_hauteur_prevention_missing = false;
				} else if (this.risques_chute_hauteur_prevention_materiel ||
					this.risques_chute_hauteur_prevention_conduite ||
					this.risques_chute_hauteur_prevention_balisage ||
					this.risques_chute_hauteur_prevention_harnais ||
					this.risques_chute_hauteur_prevention_permis) {
					this.risques_chute_hauteur_prevention_missing = false;
				}
				break;
			case 'risques_chute_charge_objet_prevention' :
				if (!this.risques_chute_charge_objet) {
					this.risques_chute_charge_objet_prevention_missing = false;
				} else if (this.risques_chute_charge_objet_prevention_casque ||
					this.risques_chute_charge_objet_prevention_baliser) {
					this.risques_chute_charge_objet_prevention_missing = false;
				}
				break;
			case 'risques_mecaniques_details' :
				if (!this.risques_mecaniques) {
					this.risques_mecaniques_details_missing = false;
				} else if (this.risques_mecaniques_details_manipulation ||
					this.risques_mecaniques_details_mecanisme ||
					this.risques_mecaniques_details_electro) {
					this.risques_mecaniques_details_missing = false;
				}
				break;
			case 'risques_mecaniques_prevention' :
				if (!this.risques_mecaniques) {
					this.risques_mecaniques_prevention_missing = false;
				} else if (this.risques_mecaniques_prevention_gants ||
					this.risques_mecaniques_prevention_etat ||
					this.risques_mecaniques_prevention_balisage ||
					this.risques_mecaniques_prevention_permis ||
					this.risques_mecaniques_prevention_mecaniques) {
					this.risques_mecaniques_prevention_missing = false;
				}
				break;
			case 'risques_chimiques_details' :
				if (!this.risques_chimiques) {
					this.risques_chimiques_details_missing = false;
				} else if (this.risques_chimiques_details_manipulation ||
					this.risques_chimiques_details_voisinage) {
					this.risques_mecaniques_details_missing = false;
				}
				break;
			case 'risques_chimiques_prevention' :
				if (!this.risques_chimiques) {
					this.risques_chimiques_prevention_missing = false;
				} else if (this.risques_chimiques_prevention_epi ||
					this.risques_chimiques_prevention_produit ||
					this.risques_chimiques_prevention_epi_client) {
					this.risques_chimiques_prevention_missing = false;
				}
				break;
			case 'risques_incendie_details' :
				if (!this.risques_incendie) {
					this.risques_incendie_details_missing = false;
				} else if (this.risques_incendie_details_soudure ||
					this.risques_incendie_details_produits) {
					this.risques_incendie_details_missing = false;
				}
				break;
			case 'risques_incendie_prevention' :
				if (!this.risques_incendie) {
					this.risques_incendie_prevention_missing = false;
				} else if (this.risques_incendie_prevention_consignes ||
					this.risques_incendie_prevention_fumer ||
					this.risques_incendie_prevention_atex ||
					this.risques_incendie_prevention_permis ||
					this.risques_incendie_prevention_extincteurs) {
					this.risques_incendie_prevention_missing = false;
				}
				break;
			case 'risques_nuisances_details' :
				if (!this.risques_nuisances) {
					this.risques_nuisances_details_missing = false;
				} else if (this.risques_nuisances_details_poussieres ||
					this.risques_nuisances_details_bruit) {
					this.risques_nuisances_details_missing = false;
				}
				break;
			case 'risques_nuisances_prevention' :
				if (!this.risques_nuisances) {
					this.risques_nuisances_prevention_missing = false;
				} else if (this.risques_nuisances_prevention_auditives ||
					this.risques_nuisances_prevention_masque ||
					this.risques_nuisances_prevention_lunettes ||
					this.risques_nuisances_prevention_zone) {
					this.risques_nuisances_prevention_missing = false;
				}
				break;
			case 'risques_lies_coactivite_details' :
				if (!this.risques_lies_coactivite) {
					this.risques_lies_coactivite_details_missing = false;
				} else if (this.risques_lies_coactivite_details_entreprises) {
					this.risques_lies_coactivite_details_missing = false;
				}
				break;
			case 'risques_lies_coactivite_prevention' :
				if (!this.risques_lies_coactivite) {
					this.risques_lies_coactivite_prevention_missing = false;
				} else if (this.risques_lies_coactivite_prevention_nature != null ||
					this.risques_lies_coactivite_prevention_precautions != null ||
					this.risques_lies_coactivite_prevention_communication != null) {
					this.risques_lies_coactivite_prevention_missing = false;
				}
				break;
			case 'risques_zone_alimentaire_prevention' :
				if (!this.risques_zone_alimentaire) {
					this.risques_zone_alimentaire_prevention_missing = false;
				} else if (this.risques_zone_alimentaire_prevention_regles ||
					this.risques_zone_alimentaire_prevention_epi) {
					this.risques_zone_alimentaire_prevention_missing = false;
				}
				break;
			case 'autres_risques_type' :
				if (!this.autres_risques) {
					this.autres_risques_type_missing = false;
				} else if (this.autres_risques_type != null) {
					this.autres_risques_type_missing = false;
				}
				break;
			case 'autres_risques_prevention' :
				if (!this.autres_risques) {
					this.autres_risques_prevention_missing = false;
				} else if (this.autres_risques_prevention != null) {
					this.autres_risques_prevention_missing = false;
				}
				break;
			case 'moyens_urgence' :
				if (this.moyens_urgence_sortie ||
					this.moyens_urgence_pharmacie ||
					this.moyens_urgence_telephone ||
					this.moyens_urgence_rassemblement ||
					this.moyens_urgence_rincage ||
					this.moyens_urgence_douche ||
					this.moyens_urgence_extincteur) {
					this.moyens_urgence_missing = false;
				}
				break;
			default :
				break;
		}
	}

	hasNonConformite() {
		if (this.aptitude_medicale === 'NC') {
			return true;
		}
		if (this.autorisation_acces_chantier === 'NC') {
			return true;
		}
		if (this.titre_habilitation_electrique === 'NC') {
			return true;
		}
		if (this.titre_formation_chimique === 'NC') {
			return true;
		}
		if (this.cerfa_n1138302 === 'NC') {
			return true;
		}
		if (this.autorisation_conduite_nacelle === 'NC') {
			return true;
		}
		if (this.caces_nacelle === 'NC') {
			return true;
		}
		if (this.autorisation_echaffaudage === 'NC') {
			return true;
		}
		if (this.casque_vetements_travail_chaussures_securite === 'NC') {
			return true;
		}
		if (this.vat_casque_idra === 'NC') {
			return true;
		}
		if (this.outillage_individuel === 'NC') {
			return true;
		}
		return false;
	}

	getFormData() {
		const formData = new FormData();

		if (this.id != null) {
			formData.append('id', JSON.stringify(this.id));
		}
		formData.append('status', this.status);

		// Step 1

		if (this.id_affaire != null) {
			formData.append('id_affaire', JSON.stringify(Number(this.id_affaire)));
		}

		// Step 3

		if (this.aptitude_medicale != null) {
			formData.append('aptitude_medicale', this.aptitude_medicale);
		}
		if (this.aptitude_medicale_description != null) {
			formData.append('aptitude_medicale_description', this.aptitude_medicale_description);
		}
		if (this.autorisation_acces_chantier != null) {
			formData.append('autorisation_acces_chantier', this.autorisation_acces_chantier);
		}
		if (this.autorisation_acces_chantier_description != null) {
			formData.append('autorisation_acces_chantier_description', this.autorisation_acces_chantier_description);
		}
		if (this.titre_habilitation_electrique != null) {
			formData.append('titre_habilitation_electrique', this.titre_habilitation_electrique);
		}
		if (this.titre_habilitation_electrique_description != null) {
			formData.append('titre_habilitation_electrique_description', this.titre_habilitation_electrique_description);
		}
		if (this.titre_formation_chimique != null) {
			formData.append('titre_formation_chimique', this.titre_formation_chimique);
		}
		if (this.titre_formation_chimique_description != null) {
			formData.append('titre_formation_chimique_description', this.titre_formation_chimique_description);
		}
		if (this.cerfa_n1138302 != null) {
			formData.append('cerfa_n1138302', this.cerfa_n1138302);
		}
		if (this.cerfa_n1138302_description != null) {
			formData.append('cerfa_n1138302_description', this.cerfa_n1138302_description);
		}
		if (this.autorisation_conduite_nacelle != null) {
			formData.append('autorisation_conduite_nacelle', this.autorisation_conduite_nacelle);
		}
		if (this.autorisation_conduite_nacelle_description != null) {
			formData.append('autorisation_conduite_nacelle_description', this.autorisation_conduite_nacelle_description);
		}
		if (this.caces_nacelle != null) {
			formData.append('caces_nacelle', this.caces_nacelle);
		}
		if (this.caces_nacelle_description != null) {
			formData.append('caces_nacelle_description', this.caces_nacelle_description);
		}
		if (this.autorisation_echaffaudage != null) {
			formData.append('autorisation_echaffaudage', this.autorisation_echaffaudage);
		}
		if (this.autorisation_echaffaudage_description != null) {
			formData.append('autorisation_echaffaudage_description', this.autorisation_echaffaudage_description);
		}
		if (this.casque_vetements_travail_chaussures_securite != null) {
			formData.append('casque_vetements_travail_chaussures_securite', this.casque_vetements_travail_chaussures_securite);
		}
		if (this.casque_vetements_travail_chaussures_securite_description != null) {
			formData.append('casque_vetements_travail_chaussures_securite_description', this.casque_vetements_travail_chaussures_securite_description);
		}
		if (this.vat_casque_idra != null) {
			formData.append('vat_casque_idra', this.vat_casque_idra);
		}
		if (this.vat_casque_idra_description != null) {
			formData.append('vat_casque_idra_description', this.vat_casque_idra_description);
		}
		if (this.outillage_individuel != null) {
			formData.append('outillage_individuel', this.outillage_individuel);
		}
		if (this.outillage_individuel_description != null) {
			formData.append('outillage_individuel_description', this.outillage_individuel_description);
		}
		if (this.travail_cours_effectuer != null) {
			formData.append('travail_cours_effectuer', this.travail_cours_effectuer);
		}
		if (this.presence_signature_pdp_ppsps != null) {
			formData.append('presence_signature_pdp_ppsps', this.presence_signature_pdp_ppsps);
		}
		if (this.explication_visite_chantier_realises != null) {
			formData.append('explication_visite_chantier_realises', this.explication_visite_chantier_realises);
		}
		if (this.droit_alerte_retrait != null) {
			formData.append('droit_alerte_retrait', this.droit_alerte_retrait);
		}

		// Step 4

		if (this.risques_electriques != null) {
			formData.append('risques_electriques', JSON.stringify(Number(this.risques_electriques)));
		}
		if (this.risques_electriques_details_basse_tension != null) {
			formData.append('risques_electriques_details_basse_tension', JSON.stringify(Number(this.risques_electriques_details_basse_tension)));
		}
		if (this.risques_electriques_details_haute_tension != null) {
			formData.append('risques_electriques_details_haute_tension', JSON.stringify(Number(this.risques_electriques_details_haute_tension)));
		}
		if (this.risques_electriques_details_voisinage_tension != null) {
			formData.append('risques_electriques_details_voisinage_tension', JSON.stringify(Number(this.risques_electriques_details_voisinage_tension)));
		}
		if (this.risques_electriques_details_energie_permanente != null) {
			formData.append('risques_electriques_details_energie_permanente', JSON.stringify(Number(this.risques_electriques_details_energie_permanente)));
		}
		if (this.risques_electriques_prevention_habilitation != null) {
			formData.append('risques_electriques_prevention_habilitation', JSON.stringify(Number(this.risques_electriques_prevention_habilitation)));
		}
		if (this.risques_electriques_prevention_consignation != null) {
			formData.append('risques_electriques_prevention_consignation', JSON.stringify(Number(this.risques_electriques_prevention_consignation)));
		}
		if (this.risques_electriques_prevention_voisinage != null) {
			formData.append('risques_electriques_prevention_voisinage', JSON.stringify(Number(this.risques_electriques_prevention_voisinage)));
		}
		if (this.risques_lies_circulation != null) {
			formData.append('risques_lies_circulation', JSON.stringify(Number(this.risques_lies_circulation)));
		}
		if (this.risques_lies_circulation_details_vehicules != null) {
			formData.append('risques_lies_circulation_details_vehicules', JSON.stringify(Number(this.risques_lies_circulation_details_vehicules)));
		}
		if (this.risques_lies_circulation_details_pietonne != null) {
			formData.append('risques_lies_circulation_details_pietonne', JSON.stringify(Number(this.risques_lies_circulation_details_pietonne)));
		}
		if (this.risques_lies_circulation_moyens_prevention_regles != null) {
			formData.append('risques_lies_circulation_moyens_prevention_regles', JSON.stringify(Number(this.risques_lies_circulation_moyens_prevention_regles)));
		}
		if (this.risques_lies_circulation_moyens_prevention_baliser != null) {
			formData.append('risques_lies_circulation_moyens_prevention_baliser', JSON.stringify(Number(this.risques_lies_circulation_moyens_prevention_baliser)));
		}
		if (this.risques_lies_circulation_moyens_prevention_epi != null) {
			formData.append('risques_lies_circulation_moyens_prevention_epi', JSON.stringify(Number(this.risques_lies_circulation_moyens_prevention_epi)));
		}
		if (this.risques_chute_hauteur != null) {
			formData.append('risques_chute_hauteur', JSON.stringify(Number(this.risques_chute_hauteur)));
		}
		if (this.risques_chute_hauteur_details_nacelle != null) {
			formData.append('risques_chute_hauteur_details_nacelle', JSON.stringify(Number(this.risques_chute_hauteur_details_nacelle)));
		}
		if (this.risques_chute_hauteur_details_toiture != null) {
			formData.append('risques_chute_hauteur_details_toiture', JSON.stringify(Number(this.risques_chute_hauteur_details_toiture)));
		}
		if (this.risques_chute_hauteur_details_echafaudage != null) {
			formData.append('risques_chute_hauteur_details_echafaudage', JSON.stringify(Number(this.risques_chute_hauteur_details_echafaudage)));
		}
		if (this.risques_chute_hauteur_details_echelle != null) {
			formData.append('risques_chute_hauteur_details_echelle', JSON.stringify(Number(this.risques_chute_hauteur_details_echelle)));
		}
		if (this.risques_chute_hauteur_details_harnais != null) {
			formData.append('risques_chute_hauteur_details_harnais', JSON.stringify(Number(this.risques_chute_hauteur_details_harnais)));
		}
		if (this.risques_chute_hauteur_prevention_materiel != null) {
			formData.append('risques_chute_hauteur_prevention_materiel', JSON.stringify(Number(this.risques_chute_hauteur_prevention_materiel)));
		}
		if (this.risques_chute_hauteur_prevention_conduite != null) {
			formData.append('risques_chute_hauteur_prevention_conduite', JSON.stringify(Number(this.risques_chute_hauteur_prevention_conduite)));
		}
		if (this.risques_chute_hauteur_prevention_balisage != null) {
			formData.append('risques_chute_hauteur_prevention_balisage', JSON.stringify(Number(this.risques_chute_hauteur_prevention_balisage)));
		}
		if (this.risques_chute_hauteur_prevention_harnais != null) {
			formData.append('risques_chute_hauteur_prevention_harnais', JSON.stringify(Number(this.risques_chute_hauteur_prevention_harnais)));
		}
		if (this.risques_chute_hauteur_prevention_permis != null) {
			formData.append('risques_chute_hauteur_prevention_permis', JSON.stringify(Number(this.risques_chute_hauteur_prevention_permis)));
		}
		if (this.risques_chute_charge_objet != null) {
			formData.append('risques_chute_charge_objet', JSON.stringify(Number(this.risques_chute_charge_objet)));
		}
		if (this.risques_chute_charge_objet_prevention_casque != null) {
			formData.append('risques_chute_charge_objet_prevention_casque', JSON.stringify(Number(this.risques_chute_charge_objet_prevention_casque)));
		}
		if (this.risques_chute_charge_objet_prevention_baliser != null) {
			formData.append('risques_chute_charge_objet_prevention_baliser', JSON.stringify(Number(this.risques_chute_charge_objet_prevention_baliser)));
		}
		if (this.risques_mecaniques != null) {
			formData.append('risques_mecaniques', JSON.stringify(Number(this.risques_mecaniques)));
		}
		if (this.risques_mecaniques_details_manipulation != null) {
			formData.append('risques_mecaniques_details_manipulation', JSON.stringify(Number(this.risques_mecaniques_details_manipulation)));
		}
		if (this.risques_mecaniques_details_mecanisme != null) {
			formData.append('risques_mecaniques_details_mecanisme', JSON.stringify(Number(this.risques_mecaniques_details_mecanisme)));
		}
		if (this.risques_mecaniques_details_electro != null) {
			formData.append('risques_mecaniques_details_electro', JSON.stringify(Number(this.risques_mecaniques_details_electro)));
		}
		if (this.risques_mecaniques_prevention_gants != null) {
			formData.append('risques_mecaniques_prevention_gants', JSON.stringify(Number(this.risques_mecaniques_prevention_gants)));
		}
		if (this.risques_mecaniques_prevention_etat != null) {
			formData.append('risques_mecaniques_prevention_etat', JSON.stringify(Number(this.risques_mecaniques_prevention_etat)));
		}
		if (this.risques_mecaniques_prevention_balisage != null) {
			formData.append('risques_mecaniques_prevention_balisage', JSON.stringify(Number(this.risques_mecaniques_prevention_balisage)));
		}
		if (this.risques_mecaniques_prevention_permis != null) {
			formData.append('risques_mecaniques_prevention_permis', JSON.stringify(Number(this.risques_mecaniques_prevention_permis)));
		}
		if (this.risques_mecaniques_prevention_mecaniques != null) {
			formData.append('risques_mecaniques_prevention_mecaniques', JSON.stringify(Number(this.risques_mecaniques_prevention_mecaniques)));
		}
		if (this.risques_chimiques != null) {
			formData.append('risques_chimiques', JSON.stringify(Number(this.risques_chimiques)));
		}
		if (this.risques_chimiques_details_manipulation != null) {
			formData.append('risques_chimiques_details_manipulation', JSON.stringify(Number(this.risques_chimiques_details_manipulation)));
		}
		if (this.risques_chimiques_details_voisinage != null) {
			formData.append('risques_chimiques_details_voisinage', JSON.stringify(Number(this.risques_chimiques_details_voisinage)));
		}
		if (this.risques_chimiques_prevention_epi != null) {
			formData.append('risques_chimiques_prevention_epi', JSON.stringify(Number(this.risques_chimiques_prevention_epi)));
		}
		if (this.risques_chimiques_prevention_produit != null) {
			formData.append('risques_chimiques_prevention_produit', JSON.stringify(Number(this.risques_chimiques_prevention_produit)));
		}
		if (this.risques_chimiques_prevention_epi_client != null) {
			formData.append('risques_chimiques_prevention_epi_client', JSON.stringify(Number(this.risques_chimiques_prevention_epi_client)));
		}
		if (this.risques_incendie != null) {
			formData.append('risques_incendie', JSON.stringify(Number(this.risques_incendie)));
		}
		if (this.risques_incendie_details_soudure != null) {
			formData.append('risques_incendie_details_soudure', JSON.stringify(Number(this.risques_incendie_details_soudure)));
		}
		if (this.risques_incendie_details_produits != null) {
			formData.append('risques_incendie_details_produits', JSON.stringify(Number(this.risques_incendie_details_produits)));
		}
		if (this.risques_incendie_prevention_consignes != null) {
			formData.append('risques_incendie_prevention_consignes', JSON.stringify(Number(this.risques_incendie_prevention_consignes)));
		}
		if (this.risques_incendie_prevention_fumer != null) {
			formData.append('risques_incendie_prevention_fumer', JSON.stringify(Number(this.risques_incendie_prevention_fumer)));
		}
		if (this.risques_incendie_prevention_atex != null) {
			formData.append('risques_incendie_prevention_atex', JSON.stringify(Number(this.risques_incendie_prevention_atex)));
		}
		if (this.risques_incendie_prevention_permis != null) {
			formData.append('risques_incendie_prevention_permis', JSON.stringify(Number(this.risques_incendie_prevention_permis)));
		}
		if (this.risques_incendie_prevention_extincteurs != null) {
			formData.append('risques_incendie_prevention_extincteurs', JSON.stringify(Number(this.risques_incendie_prevention_extincteurs)));
		}
		if (this.risques_nuisances != null) {
			formData.append('risques_nuisances', JSON.stringify(Number(this.risques_nuisances)));
		}
		if (this.risques_nuisances_details_poussieres != null) {
			formData.append('risques_nuisances_details_poussieres', JSON.stringify(Number(this.risques_nuisances_details_poussieres)));
		}
		if (this.risques_nuisances_details_bruit != null) {
			formData.append('risques_nuisances_details_bruit', JSON.stringify(Number(this.risques_nuisances_details_bruit)));
		}
		if (this.risques_nuisances_prevention_auditives != null) {
			formData.append('risques_nuisances_prevention_auditives', JSON.stringify(Number(this.risques_nuisances_prevention_auditives)));
		}
		if (this.risques_nuisances_prevention_masque != null) {
			formData.append('risques_nuisances_prevention_masque', JSON.stringify(Number(this.risques_nuisances_prevention_masque)));
		}
		if (this.risques_nuisances_prevention_lunettes != null) {
			formData.append('risques_nuisances_prevention_lunettes', JSON.stringify(Number(this.risques_nuisances_prevention_lunettes)));
		}
		if (this.risques_nuisances_prevention_zone != null) {
			formData.append('risques_nuisances_prevention_zone', JSON.stringify(Number(this.risques_nuisances_prevention_zone)));
		}
		if (this.risques_lies_coactivite != null) {
			formData.append('risques_lies_coactivite', JSON.stringify(Number(this.risques_lies_coactivite)));
		}
		if (this.risques_lies_coactivite_details_entreprises != null) {
			formData.append('risques_lies_coactivite_details_entreprises', JSON.stringify(Number(this.risques_lies_coactivite_details_entreprises)));
		}
		if (this.risques_lies_coactivite_prevention_nature != null) {
			formData.append('risques_lies_coactivite_prevention_nature', this.risques_lies_coactivite_prevention_nature);
		}
		if (this.risques_lies_coactivite_prevention_precautions != null) {
			formData.append('risques_lies_coactivite_prevention_precautions', this.risques_lies_coactivite_prevention_precautions);
		}
		if (this.risques_lies_coactivite_prevention_communication != null) {
			formData.append('risques_lies_coactivite_prevention_communication', this.risques_lies_coactivite_prevention_communication);
		}
		if (this.risques_zone_alimentaire != null) {
			formData.append('risques_zone_alimentaire', JSON.stringify(Number(this.risques_zone_alimentaire)));
		}
		if (this.risques_zone_alimentaire_prevention_regles != null) {
			formData.append('risques_zone_alimentaire_prevention_regles', JSON.stringify(Number(this.risques_zone_alimentaire_prevention_regles)));
		}
		if (this.risques_zone_alimentaire_prevention_epi != null) {
			formData.append('risques_zone_alimentaire_prevention_epi', JSON.stringify(Number(this.risques_zone_alimentaire_prevention_epi)));
		}
		if (this.autres_risques != null) {
			formData.append('autres_risques', JSON.stringify(Number(this.autres_risques)));
		}
		if (this.autres_risques_type != null) {
			formData.append('autres_risques_type', this.autres_risques_type);
		}
		if (this.autres_risques_prevention != null) {
			formData.append('autres_risques_prevention', this.autres_risques_prevention);
		}

		// Step 5

		if (this.moyens_urgence_sortie != null) {
			formData.append('moyens_urgence_sortie', JSON.stringify(Number(this.moyens_urgence_sortie)));
		}
		if (this.moyens_urgence_pharmacie != null) {
			formData.append('moyens_urgence_pharmacie', JSON.stringify(Number(this.moyens_urgence_pharmacie)));
		}
		if (this.moyens_urgence_telephone != null) {
			formData.append('moyens_urgence_telephone', JSON.stringify(Number(this.moyens_urgence_telephone)));
		}
		if (this.moyens_urgence_rassemblement != null) {
			formData.append('moyens_urgence_rassemblement', JSON.stringify(Number(this.moyens_urgence_rassemblement)));
		}
		if (this.moyens_urgence_rincage != null) {
			formData.append('moyens_urgence_rincage', JSON.stringify(Number(this.moyens_urgence_rincage)));
		}
		if (this.moyens_urgence_douche != null) {
			formData.append('moyens_urgence_douche', JSON.stringify(Number(this.moyens_urgence_douche)));
		}
		if (this.moyens_urgence_extincteur != null) {
			formData.append('moyens_urgence_extincteur', JSON.stringify(Number(this.moyens_urgence_extincteur)));
		}

		// Step 6

		if (this.date_signature != null) {
			formData.append('date_signature', moment(this.date_signature).format());
		}

		for (const i in this.signatories) {
			if (this.signatories[i] != null && this.signatories[i].id != null) {
				if (this.signatories[i].id !== null) {
					formData.append('autres_signatures[' + i + '][id_user]', JSON.stringify(this.signatories[i].id));
				}
				if (this.signatories_dates[i] !== null) {
					formData.append('autres_signatures[' + i + '][date]', moment(this.signatories_dates[i]).format());
				}
			}
		}

		for (const i in this.personnes_supplementaires) {
			if ( this.personnes_supplementaires[i].id !== null) {
				formData.append('personnes_supplementaires[' + i + '][id]', this.personnes_supplementaires[i].id);
			}
			formData.append('personnes_supplementaires[' + i + '][societe]', this.personnes_supplementaires[i].societe);
			formData.append('personnes_supplementaires[' + i + '][nom]', this.personnes_supplementaires[i].nom);
			formData.append('personnes_supplementaires[' + i + '][type]', this.personnes_supplementaires[i].type);
			formData.append('personnes_supplementaires[' + i + '][date_signature]', this.personnes_supplementaires[i].date_signature !== null ? moment(this.personnes_supplementaires[i].date_signature).format() : null);
			const curSignature = this.personnes_supplementaires[i].signature;
			if (curSignature && curSignature.type === 'local') {
				formData.append('personnes_supplementaires[' + i + '][signature]', FileService.dataURItoBlob(curSignature.preview), curSignature.fileName);
			}
		}

		return formData;
	}
}
