import {UserObject} from '../../user.object';

import * as moment from 'moment';

import {FileService} from '../../../services/file.service';

export class PlanPreventionObject {
	id: number;
	table = 'plan_prevention';
	titre = 'Plan de prévention';
	createdAt: string;
	updatedAt: string;
	status: string;
	createdBy: UserObject;
	updatedBy: UserObject;

	// Step 1

	id_affaire: number;

	// Step 2

	nom_client: string;
	nom_affaire: string;
	interlocuteur: string;
	telephone: string;
	entreprise_exterieure: string;
	entreprise_exterieure_responsable: string;
	entreprise_exterieure_telephone: string;
	responsable_travaux: UserObject;
	nb_stemi: number;
	lieu: string;
	date_debut_travaux: string;
	date_fin_travaux: string;
	horaires_intervention: { debut: string, fin: string, debut_am: string, fin_am: string };
	presence_amiante: string;
	localisation_amiante: string;

	// Step 2 missing

	nom_client_missing: boolean;
	interlocuteur_missing: boolean;
	telephone_missing: boolean;
	entreprise_exterieure_missing: boolean;
	entreprise_exterieure_responsable_missing: boolean;
	entreprise_exterieure_telephone_missing: boolean;
	nb_stemi_missing: boolean;
	lieu_missing: boolean;
	date_debut_travaux_missing: boolean;
	date_fin_travaux_missing: boolean;
	horaires_intervention_matin_missing: boolean;
	horaires_intervention_am_missing: boolean;
	presence_amiante_missing: boolean;
	localisation_amiante_missing: boolean;

	// Step 3

	epi_chaussures_securite: boolean;
	epi_lunettes_protection: boolean;
	epi_protection_auditive: boolean;
	epi_casque_chantier: boolean;
	epi_vetements_travail: boolean;
	epi_gilet: boolean;

	// Step 4

	moyens_urgence_sortie: boolean;
	moyens_urgence_sortie_commentaire: string;
	moyens_urgence_pharmacie: boolean;
	moyens_urgence_pharmacie_commentaire: string;
	moyens_urgence_telephone: boolean;
	moyens_urgence_telephone_commentaire: string;
	moyens_urgence_rassemblement: boolean;
	moyens_urgence_rassemblement_commentaire: string;
	moyens_urgence_rincage: boolean;
	moyens_urgence_rincage_commentaire: string;
	moyens_urgence_douche: boolean;
	moyens_urgence_douche_commentaire: string;
	moyens_urgence_extincteur: boolean;
	moyens_urgence_extincteur_commentaire: string;

	// Step 4 missing

	moyens_urgence_missing: boolean;

	// Step 5

	prestataire: boolean;
	prestataire_societe: string;
	prestataire_responsable: string;
	prestataire_nature_travaux: string;
	risques_electriques: boolean;
	risques_electriques_details_basse_tension: boolean;
	risques_electriques_details_haute_tension: boolean;
	risques_electriques_details_voisinage_tension: boolean;
	risques_electriques_prevention_habilitation: boolean;
	risques_electriques_prevention_habilitation_par_ee: boolean;
	risques_electriques_prevention_habilitation_par_eu: boolean;
	risques_electriques_prevention_consignation: boolean;
	risques_electriques_prevention_consignation_par_ee: boolean;
	risques_electriques_prevention_consignation_par_eu: boolean;
	risques_electriques_prevention_voisinage: boolean;
	risques_electriques_prevention_voisinage_par_ee: boolean;
	risques_electriques_prevention_voisinage_par_eu: boolean;
	risques_electriques_remarques: string;
	risques_lies_circulation: boolean;
	risques_lies_circulation_details_vehicules: boolean;
	risques_lies_circulation_details_pietonne: boolean;
	risques_lies_circulation_moyens_prevention_regles: boolean;
	risques_lies_circulation_moyens_prevention_regles_par_ee: boolean;
	risques_lies_circulation_moyens_prevention_regles_par_eu: boolean;
	risques_lies_circulation_moyens_prevention_baliser: boolean;
	risques_lies_circulation_moyens_prevention_baliser_par_ee: boolean;
	risques_lies_circulation_moyens_prevention_baliser_par_eu: boolean;
	risques_lies_circulation_moyens_prevention_epi: boolean;
	risques_lies_circulation_moyens_prevention_epi_par_ee: boolean;
	risques_lies_circulation_moyens_prevention_epi_par_eu: boolean;
	risques_lies_circulation_remarques: string;
	risques_chute_hauteur: boolean;
	risques_chute_hauteur_details_nacelle: boolean;
	risques_chute_hauteur_details_echafaudage: boolean;
	risques_chute_hauteur_details_echelle: boolean;
	risques_chute_hauteur_prevention_materiel: boolean;
	risques_chute_hauteur_prevention_materiel_par_ee: boolean;
	risques_chute_hauteur_prevention_materiel_par_eu: boolean;
	risques_chute_hauteur_prevention_conduite: boolean;
	risques_chute_hauteur_prevention_conduite_par_ee: boolean;
	risques_chute_hauteur_prevention_conduite_par_eu: boolean;
	risques_chute_hauteur_prevention_balisage: boolean;
	risques_chute_hauteur_prevention_balisage_par_ee: boolean;
	risques_chute_hauteur_prevention_balisage_par_eu: boolean;
	risques_chute_hauteur_prevention_harnais: boolean;
	risques_chute_hauteur_prevention_harnais_par_ee: boolean;
	risques_chute_hauteur_prevention_harnais_par_eu: boolean;
	risques_chute_hauteur_prevention_permis: boolean;
	risques_chute_hauteur_prevention_permis_par_ee: boolean;
	risques_chute_hauteur_prevention_permis_par_eu: boolean;
	risques_chute_hauteur_prevention_pret_materiel: boolean;
	risques_chute_hauteur_remarques: string;
	risques_chute_charge_objet: boolean;
	risques_chute_charge_objet_prevention_casque: boolean;
	risques_chute_charge_objet_prevention_casque_par_ee: boolean;
	risques_chute_charge_objet_prevention_casque_par_eu: boolean;
	risques_chute_charge_objet_prevention_baliser: boolean;
	risques_chute_charge_objet_prevention_baliser_par_ee: boolean;
	risques_chute_charge_objet_prevention_baliser_par_eu: boolean;
	risques_chute_charge_objet_remarques: string;
	risques_mecaniques: boolean;
	risques_mecaniques_details_mecanisme: boolean;
	risques_mecaniques_details_electro: boolean;
	risques_mecaniques_prevention_gants: boolean;
	risques_mecaniques_prevention_gants_par_ee: boolean;
	risques_mecaniques_prevention_gants_par_eu: boolean;
	risques_mecaniques_prevention_etat: boolean;
	risques_mecaniques_prevention_etat_par_ee: boolean;
	risques_mecaniques_prevention_etat_par_eu: boolean;
	risques_mecaniques_prevention_balisage: boolean;
	risques_mecaniques_prevention_balisage_par_ee: boolean;
	risques_mecaniques_prevention_balisage_par_eu: boolean;
	risques_mecaniques_prevention_permis: boolean;
	risques_mecaniques_prevention_permis_par_ee: boolean;
	risques_mecaniques_prevention_permis_par_eu: boolean;
	risques_mecaniques_remarques: string;
	risques_chimiques: boolean;
	risques_chimiques_details_manipulation: boolean;
	risques_chimiques_details_voisinage: boolean;
	risques_chimiques_prevention_epi: boolean;
	risques_chimiques_prevention_epi_par_ee: boolean;
	risques_chimiques_prevention_epi_par_eu: boolean;
	risques_chimiques_prevention_produit: boolean;
	risques_chimiques_prevention_produit_par_ee: boolean;
	risques_chimiques_prevention_produit_par_eu: boolean;
	risques_chimiques_remarques: string;
	risques_incendie: boolean;
	risques_incendie_details_soudure: boolean;
	risques_incendie_details_produits: boolean;
	risques_incendie_prevention_consignes: boolean;
	risques_incendie_prevention_consignes_par_ee: boolean;
	risques_incendie_prevention_consignes_par_eu: boolean;
	risques_incendie_prevention_fumer: boolean;
	risques_incendie_prevention_fumer_par_ee: boolean;
	risques_incendie_prevention_fumer_par_eu: boolean;
	risques_incendie_prevention_atex: boolean;
	risques_incendie_prevention_atex_par_ee: boolean;
	risques_incendie_prevention_atex_par_eu: boolean;
	risques_incendie_prevention_permis: boolean;
	risques_incendie_prevention_permis_par_ee: boolean;
	risques_incendie_prevention_permis_par_eu: boolean;
	risques_incendie_prevention_extincteurs: boolean;
	risques_incendie_prevention_extincteurs_par_ee: boolean;
	risques_incendie_prevention_extincteurs_par_eu: boolean;
	risques_incendie_remarques: string;
	risques_nuisances: boolean;
	risques_nuisances_details_poussieres: boolean;
	risques_nuisances_details_bruit: boolean;
	risques_nuisances_prevention_auditives: boolean;
	risques_nuisances_prevention_auditives_par_ee: boolean;
	risques_nuisances_prevention_auditives_par_eu: boolean;
	risques_nuisances_prevention_masque: boolean;
	risques_nuisances_prevention_masque_par_ee: boolean;
	risques_nuisances_prevention_masque_par_eu: boolean;
	risques_nuisances_prevention_lunettes: boolean;
	risques_nuisances_prevention_lunettes_par_ee: boolean;
	risques_nuisances_prevention_lunettes_par_eu: boolean;
	risques_nuisances_remarques: string;
	risques_lies_coactivite: boolean;
	risques_lies_coactivite_details_entreprises: boolean;
	risques_lies_coactivite_prevention_nature: string;
	risques_lies_coactivite_prevention_precautions: string;
	risques_lies_coactivite_prevention_precautions_par_ee: boolean;
	risques_lies_coactivite_prevention_precautions_par_eu: boolean;
	risques_lies_coactivite_remarques: string;
	risques_bacteriologiques: boolean;
	risques_bacteriologiques_details_zone_blanche: boolean;
	risques_bacteriologiques_prevention_regles_hygiene: string;
	risques_bacteriologiques_prevention_regles_hygiene_par_ee: boolean;
	risques_bacteriologiques_prevention_regles_hygiene_par_eu: boolean;
	risques_bacteriologiques_prevention_port_epi: boolean;
	risques_bacteriologiques_prevention_port_epi_par_ee: boolean;
	risques_bacteriologiques_prevention_port_epi_par_eu: boolean;
	risques_bacteriologiques_remarques: string;
	autres_risques: boolean;
	autres_risques_type: string;
	autres_risques_prevention: string;
	autres_risques_prevention_par_ee: boolean;
	autres_risques_prevention_par_eu: boolean;
	autres_risques_remarques: string;
	document_eu_plan_site: boolean;
	document_eu_autres: boolean;
	document_eu_autres_description: string;

	// Step 5 missing

	prestataire_societe_missing: boolean;
	prestataire_responsable_missing: boolean;
	prestataire_nature_travaux_missing: boolean;
	risques_electriques_details_missing: boolean;
	risques_electriques_prevention_missing: boolean;
	risques_electriques_prevention_habilitation_par_missing: boolean;
	risques_electriques_prevention_consignation_par_missing: boolean;
	risques_electriques_prevention_voisinage_par_missing: boolean;
	risques_lies_circulation_details_missing: boolean;
	risques_lies_circulation_prevention_missing: boolean;
	risques_lies_circulation_moyens_prevention_regles_par_missing: boolean;
	risques_lies_circulation_moyens_prevention_baliser_par_missing: boolean;
	risques_lies_circulation_moyens_prevention_epi_par_missing: boolean;
	risques_chute_hauteur_details_missing: boolean;
	risques_chute_hauteur_prevention_missing: boolean;
	risques_chute_hauteur_prevention_materiel_par_missing: boolean;
	risques_chute_hauteur_prevention_conduite_par_missing: boolean;
	risques_chute_hauteur_prevention_balisage_par_missing: boolean;
	risques_chute_hauteur_prevention_harnais_par_missing: boolean;
	risques_chute_hauteur_prevention_permis_par_missing: boolean;
	risques_chute_charge_objet_prevention_missing: boolean;
	risques_chute_charge_objet_prevention_casque_par_missing: boolean;
	risques_chute_charge_objet_prevention_baliser_par_missing: boolean;
	risques_mecaniques_details_missing: boolean;
	risques_mecaniques_prevention_missing: boolean;
	risques_mecaniques_prevention_gants_par_missing: boolean;
	risques_mecaniques_prevention_etat_par_missing: boolean;
	risques_mecaniques_prevention_balisage_par_missing: boolean;
	risques_mecaniques_prevention_permis_par_missing: boolean;
	risques_chimiques_details_missing: boolean;
	risques_chimiques_prevention_missing: boolean;
	risques_chimiques_prevention_epi_par_missing: boolean;
	risques_chimiques_prevention_produit_par_missing: boolean;
	risques_incendie_details_missing: boolean;
	risques_incendie_prevention_missing: boolean;
	risques_incendie_prevention_consignes_par_missing: boolean;
	risques_incendie_prevention_fumer_par_missing: boolean;
	risques_incendie_prevention_atex_par_missing: boolean;
	risques_incendie_prevention_permis_par_missing: boolean;
	risques_incendie_prevention_extincteurs_par_missing: boolean;
	risques_nuisances_details_missing: boolean;
	risques_nuisances_prevention_missing: boolean;
	risques_nuisances_prevention_auditives_par_missing: boolean;
	risques_nuisances_prevention_masque_par_missing: boolean;
	risques_nuisances_prevention_lunettes_par_missing: boolean;
	risques_lies_coactivite_details_missing: boolean;
	risques_lies_coactivite_prevention_missing: boolean;
	risques_lies_coactivite_prevention_precautions_par_missing: boolean;
	risques_bacteriologiques_details_missing: boolean;
	risques_bacteriologiques_prevention_missing: boolean;
	risques_bacteriologiques_prevention_regles_hygiene_par_missing: boolean;
	risques_bacteriologiques_prevention_port_epi_par_missing: boolean;
	autres_risques_type_missing: boolean;
	autres_risques_prevention_missing: boolean;
	autres_risques_prevention_par_missing: boolean;
	document_eu_autres_description_missing: boolean;

	// Step 7

	date_signature: string;
	client_nom_signature: string;
	client_date_signature: string;
	client_signature: any;

	// Status

	// draft
	// signed

	constructor(
		id = null,
		createdAt = null,
		updatedAt = null,
		status = 'draft',
		createdBy = null,
		updatedBy = null,
		// Step 1

		id_affaire = null,
		// Step 2

		nom_client = null,
		nom_affaire = null,
		interlocuteur = null,
		telephone = null,
		entreprise_exterieure = null,
		entreprise_exterieure_responsable = null,
		entreprise_exterieure_telephone = null,
		responsable_travaux = null,
		nb_stemi = null,
		lieu = null,
		date_debut_travaux = null,
		date_fin_travaux = null,
		horaires_intervention = {debut: null, fin: null, debut_am: null, fin_am: null},
		presence_amiante = null,
		localisation_amiante = null,
		// Step 3

		epi_chaussures_securite = false,
		epi_lunettes_protection = false,
		epi_protection_auditive = false,
		epi_casque_chantier = false,
		epi_vetements_travail = false,
		epi_gilet = false,
		// Step 4

		moyens_urgence_sortie = false,
		moyens_urgence_sortie_commentaire = null,
		moyens_urgence_pharmacie = false,
		moyens_urgence_pharmacie_commentaire = null,
		moyens_urgence_telephone = false,
		moyens_urgence_telephone_commentaire = null,
		moyens_urgence_rassemblement = false,
		moyens_urgence_rassemblement_commentaire = null,
		moyens_urgence_rincage = false,
		moyens_urgence_rincage_commentaire = null,
		moyens_urgence_douche = false,
		moyens_urgence_douche_commentaire = null,
		moyens_urgence_extincteur = false,
		moyens_urgence_extincteur_commentaire = null,
		// Step 5

		prestataire = false,
		prestataire_societe = null,
		prestataire_responsable = null,
		prestataire_nature_travaux = null,
		risques_electriques = false,
		risques_electriques_details_basse_tension = false,
		risques_electriques_details_haute_tension = false,
		risques_electriques_details_voisinage_tension = false,
		risques_electriques_prevention_habilitation = false,
		risques_electriques_prevention_habilitation_par_ee = false,
		risques_electriques_prevention_habilitation_par_eu = false,
		risques_electriques_prevention_consignation = false,
		risques_electriques_prevention_consignation_par_ee = false,
		risques_electriques_prevention_consignation_par_eu = false,
		risques_electriques_prevention_voisinage = false,
		risques_electriques_prevention_voisinage_par_ee = false,
		risques_electriques_prevention_voisinage_par_eu = false,
		risques_electriques_remarques = null,
		risques_lies_circulation = false,
		risques_lies_circulation_details_vehicules = false,
		risques_lies_circulation_details_pietonne = false,
		risques_lies_circulation_moyens_prevention_regles = false,
		risques_lies_circulation_moyens_prevention_regles_par_ee = false,
		risques_lies_circulation_moyens_prevention_regles_par_eu = false,
		risques_lies_circulation_moyens_prevention_baliser = false,
		risques_lies_circulation_moyens_prevention_baliser_par_ee = false,
		risques_lies_circulation_moyens_prevention_baliser_par_eu = false,
		risques_lies_circulation_moyens_prevention_epi = false,
		risques_lies_circulation_moyens_prevention_epi_par_ee = false,
		risques_lies_circulation_moyens_prevention_epi_par_eu = false,
		risques_lies_circulation_remarques = null,
		risques_chute_hauteur = false,
		risques_chute_hauteur_details_nacelle = false,
		risques_chute_hauteur_details_echafaudage = false,
		risques_chute_hauteur_details_echelle = false,
		risques_chute_hauteur_prevention_materiel = false,
		risques_chute_hauteur_prevention_materiel_par_ee = false,
		risques_chute_hauteur_prevention_materiel_par_eu = false,
		risques_chute_hauteur_prevention_conduite = false,
		risques_chute_hauteur_prevention_conduite_par_ee = false,
		risques_chute_hauteur_prevention_conduite_par_eu = false,
		risques_chute_hauteur_prevention_balisage = false,
		risques_chute_hauteur_prevention_balisage_par_ee = false,
		risques_chute_hauteur_prevention_balisage_par_eu = false,
		risques_chute_hauteur_prevention_harnais = false,
		risques_chute_hauteur_prevention_harnais_par_ee = false,
		risques_chute_hauteur_prevention_harnais_par_eu = false,
		risques_chute_hauteur_prevention_permis = false,
		risques_chute_hauteur_prevention_permis_par_ee = false,
		risques_chute_hauteur_prevention_permis_par_eu = false,
		risques_chute_hauteur_prevention_pret_materiel = false,
		risques_chute_hauteur_remarques = null,
		risques_chute_charge_objet = false,
		risques_chute_charge_objet_prevention_casque = false,
		risques_chute_charge_objet_prevention_casque_par_ee = false,
		risques_chute_charge_objet_prevention_casque_par_eu = false,
		risques_chute_charge_objet_prevention_baliser = false,
		risques_chute_charge_objet_prevention_baliser_par_ee = false,
		risques_chute_charge_objet_prevention_baliser_par_eu = false,
		risques_chute_charge_objet_remarques = null,
		risques_mecaniques = false,
		risques_mecaniques_details_mecanisme = false,
		risques_mecaniques_details_electro = false,
		risques_mecaniques_prevention_gants = false,
		risques_mecaniques_prevention_gants_par_ee = false,
		risques_mecaniques_prevention_gants_par_eu = false,
		risques_mecaniques_prevention_etat = false,
		risques_mecaniques_prevention_etat_par_ee = false,
		risques_mecaniques_prevention_etat_par_eu = false,
		risques_mecaniques_prevention_balisage = false,
		risques_mecaniques_prevention_balisage_par_ee = false,
		risques_mecaniques_prevention_balisage_par_eu = false,
		risques_mecaniques_prevention_permis = false,
		risques_mecaniques_prevention_permis_par_ee = false,
		risques_mecaniques_prevention_permis_par_eu = false,
		risques_mecaniques_remarques = null,
		risques_chimiques = false,
		risques_chimiques_details_manipulation = false,
		risques_chimiques_details_voisinage = false,
		risques_chimiques_prevention_epi = false,
		risques_chimiques_prevention_epi_par_ee = false,
		risques_chimiques_prevention_epi_par_eu = false,
		risques_chimiques_prevention_produit = false,
		risques_chimiques_prevention_produit_par_ee = false,
		risques_chimiques_prevention_produit_par_eu = false,
		risques_chimiques_remarques = null,
		risques_incendie = false,
		risques_incendie_details_soudure = false,
		risques_incendie_details_produits = false,
		risques_incendie_prevention_consignes = false,
		risques_incendie_prevention_consignes_par_ee = false,
		risques_incendie_prevention_consignes_par_eu = false,
		risques_incendie_prevention_fumer = false,
		risques_incendie_prevention_fumer_par_ee = false,
		risques_incendie_prevention_fumer_par_eu = false,
		risques_incendie_prevention_atex = false,
		risques_incendie_prevention_atex_par_ee = false,
		risques_incendie_prevention_atex_par_eu = false,
		risques_incendie_prevention_permis = false,
		risques_incendie_prevention_permis_par_ee = false,
		risques_incendie_prevention_permis_par_eu = false,
		risques_incendie_prevention_extincteurs = false,
		risques_incendie_prevention_extincteurs_par_ee = false,
		risques_incendie_prevention_extincteurs_par_eu = false,
		risques_incendie_remarques = null,
		risques_nuisances = false,
		risques_nuisances_details_poussieres = false,
		risques_nuisances_details_bruit = false,
		risques_nuisances_prevention_auditives = false,
		risques_nuisances_prevention_auditives_par_ee = false,
		risques_nuisances_prevention_auditives_par_eu = false,
		risques_nuisances_prevention_masque = false,
		risques_nuisances_prevention_masque_par_ee = false,
		risques_nuisances_prevention_masque_par_eu = false,
		risques_nuisances_prevention_lunettes = false,
		risques_nuisances_prevention_lunettes_par_ee = false,
		risques_nuisances_prevention_lunettes_par_eu = false,
		risques_nuisances_remarques = null,
		risques_lies_coactivite = false,
		risques_lies_coactivite_details_entreprises = false,
		risques_lies_coactivite_prevention_nature = null,
		risques_lies_coactivite_prevention_precautions = null,
		risques_lies_coactivite_prevention_precautions_par_ee = false,
		risques_lies_coactivite_prevention_precautions_par_eu = false,
		risques_lies_coactivite_remarques = null,
		risques_bacteriologiques = false,
		risques_bacteriologiques_details_zone_blanche = false,
		risques_bacteriologiques_prevention_regles_hygiene = null,
		risques_bacteriologiques_prevention_regles_hygiene_par_ee = false,
		risques_bacteriologiques_prevention_regles_hygiene_par_eu = false,
		risques_bacteriologiques_prevention_port_epi = false,
		risques_bacteriologiques_prevention_port_epi_par_ee = false,
		risques_bacteriologiques_prevention_port_epi_par_eu = false,
		risques_bacteriologiques_remarques = null,
		autres_risques = false,
		autres_risques_type = null,
		autres_risques_prevention = null,
		autres_risques_prevention_par_ee = false,
		autres_risques_prevention_par_eu = false,
		autres_risques_remarques = null,
		document_eu_plan_site = false,
		document_eu_autres = false,
		document_eu_autres_description = null,
		// Step 6

		date_signature = null,
		client_nom_signature = null,
		client_date_signature = null,
		client_signature = null
	) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;

		// Step 1

		this.id_affaire = id_affaire;

		// Step 2

		this.nom_client = nom_client;
		this.nom_affaire = nom_affaire;
		this.interlocuteur = interlocuteur;
		this.telephone = telephone;
		this.entreprise_exterieure = entreprise_exterieure;
		this.entreprise_exterieure_responsable = entreprise_exterieure_responsable;
		this.entreprise_exterieure_telephone = entreprise_exterieure_telephone;
		this.responsable_travaux = responsable_travaux;
		this.nb_stemi = nb_stemi;
		this.lieu = lieu;
		this.date_debut_travaux = date_debut_travaux;
		this.date_fin_travaux = date_fin_travaux;
		this.horaires_intervention = horaires_intervention;
		this.presence_amiante = presence_amiante;
		this.localisation_amiante = localisation_amiante;

		// Step 2 missing

		this.nom_client_missing = false;
		this.interlocuteur_missing = false;
		this.telephone_missing = false;
		this.entreprise_exterieure_missing = false;
		this.entreprise_exterieure_responsable_missing = false;
		this.entreprise_exterieure_telephone_missing = false;
		this.nb_stemi_missing = false;
		this.lieu_missing = false;
		this.date_debut_travaux_missing = false;
		this.date_fin_travaux_missing = false;
		this.horaires_intervention_matin_missing = false;
		this.horaires_intervention_am_missing = false;
		this.presence_amiante_missing = false;
		this.localisation_amiante_missing = false;

		// Step 3

		this.epi_chaussures_securite = epi_chaussures_securite;
		this.epi_lunettes_protection = epi_lunettes_protection;
		this.epi_protection_auditive = epi_protection_auditive;
		this.epi_casque_chantier = epi_casque_chantier;
		this.epi_vetements_travail = epi_vetements_travail;
		this.epi_gilet = epi_gilet;

		// Step 4

		this.moyens_urgence_sortie = moyens_urgence_sortie;
		this.moyens_urgence_sortie_commentaire = moyens_urgence_sortie_commentaire;
		this.moyens_urgence_pharmacie = moyens_urgence_pharmacie;
		this.moyens_urgence_pharmacie_commentaire = moyens_urgence_pharmacie_commentaire;
		this.moyens_urgence_telephone = moyens_urgence_telephone;
		this.moyens_urgence_telephone_commentaire = moyens_urgence_telephone_commentaire;
		this.moyens_urgence_rassemblement = moyens_urgence_rassemblement;
		this.moyens_urgence_rassemblement_commentaire = moyens_urgence_rassemblement_commentaire;
		this.moyens_urgence_rincage = moyens_urgence_rincage;
		this.moyens_urgence_rincage_commentaire = moyens_urgence_rincage_commentaire;
		this.moyens_urgence_douche = moyens_urgence_douche;
		this.moyens_urgence_douche_commentaire = moyens_urgence_douche_commentaire;
		this.moyens_urgence_extincteur = moyens_urgence_extincteur;
		this.moyens_urgence_extincteur_commentaire = moyens_urgence_extincteur_commentaire;

		// Step 4 missing

		this.moyens_urgence_missing = false;

		// Step 5

		this.prestataire = prestataire;
		this.prestataire_societe = prestataire_societe;
		this.prestataire_responsable = prestataire_responsable;
		this.prestataire_nature_travaux = prestataire_nature_travaux;
		this.risques_electriques = risques_electriques;
		this.risques_electriques_details_basse_tension = risques_electriques_details_basse_tension;
		this.risques_electriques_details_haute_tension = risques_electriques_details_haute_tension;
		this.risques_electriques_details_voisinage_tension = risques_electriques_details_voisinage_tension;
		this.risques_electriques_prevention_habilitation = risques_electriques_prevention_habilitation;
		this.risques_electriques_prevention_habilitation_par_ee = risques_electriques_prevention_habilitation_par_ee;
		this.risques_electriques_prevention_habilitation_par_eu = risques_electriques_prevention_habilitation_par_eu;
		this.risques_electriques_prevention_consignation = risques_electriques_prevention_consignation;
		this.risques_electriques_prevention_consignation_par_ee = risques_electriques_prevention_consignation_par_ee;
		this.risques_electriques_prevention_consignation_par_eu = risques_electriques_prevention_consignation_par_eu;
		this.risques_electriques_prevention_voisinage = risques_electriques_prevention_voisinage;
		this.risques_electriques_prevention_voisinage_par_ee = risques_electriques_prevention_voisinage_par_ee;
		this.risques_electriques_prevention_voisinage_par_eu = risques_electriques_prevention_voisinage_par_eu;
		this.risques_electriques_remarques = risques_electriques_remarques;
		this.risques_lies_circulation = risques_lies_circulation;
		this.risques_lies_circulation_details_vehicules = risques_lies_circulation_details_vehicules;
		this.risques_lies_circulation_details_pietonne = risques_lies_circulation_details_pietonne;
		this.risques_lies_circulation_moyens_prevention_regles = risques_lies_circulation_moyens_prevention_regles;
		this.risques_lies_circulation_moyens_prevention_regles_par_ee = risques_lies_circulation_moyens_prevention_regles_par_ee;
		this.risques_lies_circulation_moyens_prevention_regles_par_eu = risques_lies_circulation_moyens_prevention_regles_par_eu;
		this.risques_lies_circulation_moyens_prevention_baliser = risques_lies_circulation_moyens_prevention_baliser;
		this.risques_lies_circulation_moyens_prevention_baliser_par_ee = risques_lies_circulation_moyens_prevention_baliser_par_ee;
		this.risques_lies_circulation_moyens_prevention_baliser_par_eu = risques_lies_circulation_moyens_prevention_baliser_par_eu;
		this.risques_lies_circulation_moyens_prevention_epi = risques_lies_circulation_moyens_prevention_epi;
		this.risques_lies_circulation_moyens_prevention_epi_par_ee = risques_lies_circulation_moyens_prevention_epi_par_ee;
		this.risques_lies_circulation_moyens_prevention_epi_par_eu = risques_lies_circulation_moyens_prevention_epi_par_eu;
		this.risques_lies_circulation_remarques = risques_lies_circulation_remarques;
		this.risques_chute_hauteur = risques_chute_hauteur;
		this.risques_chute_hauteur_details_nacelle = risques_chute_hauteur_details_nacelle;
		this.risques_chute_hauteur_details_echafaudage = risques_chute_hauteur_details_echafaudage;
		this.risques_chute_hauteur_details_echelle = risques_chute_hauteur_details_echelle;
		this.risques_chute_hauteur_prevention_materiel = risques_chute_hauteur_prevention_materiel;
		this.risques_chute_hauteur_prevention_materiel_par_ee = risques_chute_hauteur_prevention_materiel_par_ee;
		this.risques_chute_hauteur_prevention_materiel_par_eu = risques_chute_hauteur_prevention_materiel_par_eu;
		this.risques_chute_hauteur_prevention_conduite = risques_chute_hauteur_prevention_conduite;
		this.risques_chute_hauteur_prevention_conduite_par_ee = risques_chute_hauteur_prevention_conduite_par_ee;
		this.risques_chute_hauteur_prevention_conduite_par_eu = risques_chute_hauteur_prevention_conduite_par_eu;
		this.risques_chute_hauteur_prevention_balisage = risques_chute_hauteur_prevention_balisage;
		this.risques_chute_hauteur_prevention_balisage_par_ee = risques_chute_hauteur_prevention_balisage_par_ee;
		this.risques_chute_hauteur_prevention_balisage_par_eu = risques_chute_hauteur_prevention_balisage_par_eu;
		this.risques_chute_hauteur_prevention_harnais = risques_chute_hauteur_prevention_harnais;
		this.risques_chute_hauteur_prevention_harnais_par_ee = risques_chute_hauteur_prevention_harnais_par_ee;
		this.risques_chute_hauteur_prevention_harnais_par_eu = risques_chute_hauteur_prevention_harnais_par_eu;
		this.risques_chute_hauteur_prevention_permis = risques_chute_hauteur_prevention_permis;
		this.risques_chute_hauteur_prevention_permis_par_ee = risques_chute_hauteur_prevention_permis_par_ee;
		this.risques_chute_hauteur_prevention_permis_par_eu = risques_chute_hauteur_prevention_permis_par_eu;
		this.risques_chute_hauteur_prevention_pret_materiel = risques_chute_hauteur_prevention_pret_materiel;
		this.risques_chute_hauteur_remarques = risques_chute_hauteur_remarques;
		this.risques_chute_charge_objet = risques_chute_charge_objet;
		this.risques_chute_charge_objet_prevention_casque = risques_chute_charge_objet_prevention_casque;
		this.risques_chute_charge_objet_prevention_casque_par_ee = risques_chute_charge_objet_prevention_casque_par_ee;
		this.risques_chute_charge_objet_prevention_casque_par_eu = risques_chute_charge_objet_prevention_casque_par_eu;
		this.risques_chute_charge_objet_prevention_baliser = risques_chute_charge_objet_prevention_baliser;
		this.risques_chute_charge_objet_prevention_baliser_par_ee = risques_chute_charge_objet_prevention_baliser_par_ee;
		this.risques_chute_charge_objet_prevention_baliser_par_eu = risques_chute_charge_objet_prevention_baliser_par_eu;
		this.risques_chute_charge_objet_remarques = risques_chute_charge_objet_remarques;
		this.risques_mecaniques = risques_mecaniques;
		this.risques_mecaniques_details_mecanisme = risques_mecaniques_details_mecanisme;
		this.risques_mecaniques_details_electro = risques_mecaniques_details_electro;
		this.risques_mecaniques_prevention_gants = risques_mecaniques_prevention_gants;
		this.risques_mecaniques_prevention_gants_par_ee = risques_mecaniques_prevention_gants_par_ee;
		this.risques_mecaniques_prevention_gants_par_eu = risques_mecaniques_prevention_gants_par_eu;
		this.risques_mecaniques_prevention_etat = risques_mecaniques_prevention_etat;
		this.risques_mecaniques_prevention_etat_par_ee = risques_mecaniques_prevention_etat_par_ee;
		this.risques_mecaniques_prevention_etat_par_eu = risques_mecaniques_prevention_etat_par_eu;
		this.risques_mecaniques_prevention_balisage = risques_mecaniques_prevention_balisage;
		this.risques_mecaniques_prevention_balisage_par_ee = risques_mecaniques_prevention_balisage_par_ee;
		this.risques_mecaniques_prevention_balisage_par_eu = risques_mecaniques_prevention_balisage_par_eu;
		this.risques_mecaniques_prevention_permis = risques_mecaniques_prevention_permis;
		this.risques_mecaniques_prevention_permis_par_ee = risques_mecaniques_prevention_permis_par_ee;
		this.risques_mecaniques_prevention_permis_par_eu = risques_mecaniques_prevention_permis_par_eu;
		this.risques_mecaniques_remarques = risques_mecaniques_remarques;
		this.risques_chimiques = risques_chimiques;
		this.risques_chimiques_details_manipulation = risques_chimiques_details_manipulation;
		this.risques_chimiques_details_voisinage = risques_chimiques_details_voisinage;
		this.risques_chimiques_prevention_epi = risques_chimiques_prevention_epi;
		this.risques_chimiques_prevention_epi_par_ee = risques_chimiques_prevention_epi_par_ee;
		this.risques_chimiques_prevention_epi_par_eu = risques_chimiques_prevention_epi_par_eu;
		this.risques_chimiques_prevention_produit = risques_chimiques_prevention_produit;
		this.risques_chimiques_prevention_produit_par_ee = risques_chimiques_prevention_produit_par_ee;
		this.risques_chimiques_prevention_produit_par_eu = risques_chimiques_prevention_produit_par_eu;
		this.risques_chimiques_remarques = risques_chimiques_remarques;
		this.risques_incendie = risques_incendie;
		this.risques_incendie_details_soudure = risques_incendie_details_soudure;
		this.risques_incendie_details_produits = risques_incendie_details_produits;
		this.risques_incendie_prevention_consignes = risques_incendie_prevention_consignes;
		this.risques_incendie_prevention_consignes_par_ee = risques_incendie_prevention_consignes_par_ee;
		this.risques_incendie_prevention_consignes_par_eu = risques_incendie_prevention_consignes_par_eu;
		this.risques_incendie_prevention_fumer = risques_incendie_prevention_fumer;
		this.risques_incendie_prevention_fumer_par_ee = risques_incendie_prevention_fumer_par_ee;
		this.risques_incendie_prevention_fumer_par_eu = risques_incendie_prevention_fumer_par_eu;
		this.risques_incendie_prevention_atex = risques_incendie_prevention_atex;
		this.risques_incendie_prevention_atex_par_ee = risques_incendie_prevention_atex_par_ee;
		this.risques_incendie_prevention_atex_par_eu = risques_incendie_prevention_atex_par_eu;
		this.risques_incendie_prevention_permis = risques_incendie_prevention_permis;
		this.risques_incendie_prevention_permis_par_ee = risques_incendie_prevention_permis_par_ee;
		this.risques_incendie_prevention_permis_par_eu = risques_incendie_prevention_permis_par_eu;
		this.risques_incendie_prevention_extincteurs = risques_incendie_prevention_extincteurs;
		this.risques_incendie_prevention_extincteurs_par_ee = risques_incendie_prevention_extincteurs_par_ee;
		this.risques_incendie_prevention_extincteurs_par_eu = risques_incendie_prevention_extincteurs_par_eu;
		this.risques_incendie_remarques = risques_incendie_remarques;
		this.risques_nuisances = risques_nuisances;
		this.risques_nuisances_details_poussieres = risques_nuisances_details_poussieres;
		this.risques_nuisances_details_bruit = risques_nuisances_details_bruit;
		this.risques_nuisances_prevention_auditives = risques_nuisances_prevention_auditives;
		this.risques_nuisances_prevention_auditives_par_ee = risques_nuisances_prevention_auditives_par_ee;
		this.risques_nuisances_prevention_auditives_par_eu = risques_nuisances_prevention_auditives_par_eu;
		this.risques_nuisances_prevention_masque = risques_nuisances_prevention_masque;
		this.risques_nuisances_prevention_masque_par_ee = risques_nuisances_prevention_masque_par_ee;
		this.risques_nuisances_prevention_masque_par_eu = risques_nuisances_prevention_masque_par_eu;
		this.risques_nuisances_prevention_lunettes = risques_nuisances_prevention_lunettes;
		this.risques_nuisances_prevention_lunettes_par_ee = risques_nuisances_prevention_lunettes_par_ee;
		this.risques_nuisances_prevention_lunettes_par_eu = risques_nuisances_prevention_lunettes_par_eu;
		this.risques_nuisances_remarques = risques_nuisances_remarques;
		this.risques_lies_coactivite = risques_lies_coactivite;
		this.risques_lies_coactivite_details_entreprises = risques_lies_coactivite_details_entreprises;
		this.risques_lies_coactivite_prevention_nature = risques_lies_coactivite_prevention_nature;
		this.risques_lies_coactivite_prevention_precautions = risques_lies_coactivite_prevention_precautions;
		this.risques_lies_coactivite_prevention_precautions_par_ee = risques_lies_coactivite_prevention_precautions_par_ee;
		this.risques_lies_coactivite_prevention_precautions_par_eu = risques_lies_coactivite_prevention_precautions_par_eu;
		this.risques_lies_coactivite_remarques = risques_lies_coactivite_remarques;
		this.risques_bacteriologiques = risques_bacteriologiques;
		this.risques_bacteriologiques_details_zone_blanche = risques_bacteriologiques_details_zone_blanche;
		this.risques_bacteriologiques_prevention_regles_hygiene = risques_bacteriologiques_prevention_regles_hygiene;
		this.risques_bacteriologiques_prevention_regles_hygiene_par_ee = risques_bacteriologiques_prevention_regles_hygiene_par_ee;
		this.risques_bacteriologiques_prevention_regles_hygiene_par_eu = risques_bacteriologiques_prevention_regles_hygiene_par_eu;
		this.risques_bacteriologiques_prevention_port_epi = risques_bacteriologiques_prevention_port_epi;
		this.risques_bacteriologiques_prevention_port_epi_par_ee = risques_bacteriologiques_prevention_port_epi_par_ee;
		this.risques_bacteriologiques_prevention_port_epi_par_eu = risques_bacteriologiques_prevention_port_epi_par_eu;
		this.risques_bacteriologiques_remarques = risques_bacteriologiques_remarques;
		this.autres_risques = autres_risques;
		this.autres_risques_type = autres_risques_type;
		this.autres_risques_prevention = autres_risques_prevention;
		this.autres_risques_prevention_par_ee = autres_risques_prevention_par_ee;
		this.autres_risques_prevention_par_eu = autres_risques_prevention_par_eu;
		this.autres_risques_remarques = autres_risques_remarques;
		this.document_eu_plan_site = document_eu_plan_site;
		this.document_eu_autres = document_eu_autres;
		this.document_eu_autres_description = document_eu_autres_description;

		// Step 5 missing

		this.prestataire_societe_missing = false;
		this.prestataire_responsable_missing = false;
		this.prestataire_nature_travaux_missing = false;
		this.risques_electriques_details_missing = false;
		this.risques_electriques_prevention_missing = false;
		this.risques_electriques_prevention_habilitation_par_missing = false;
		this.risques_electriques_prevention_consignation_par_missing = false;
		this.risques_electriques_prevention_voisinage_par_missing = false;
		this.risques_lies_circulation_details_missing = false;
		this.risques_lies_circulation_prevention_missing = false;
		this.risques_lies_circulation_moyens_prevention_regles_par_missing = false;
		this.risques_lies_circulation_moyens_prevention_baliser_par_missing = false;
		this.risques_lies_circulation_moyens_prevention_epi_par_missing = false;
		this.risques_chute_hauteur_details_missing = false;
		this.risques_chute_hauteur_prevention_missing = false;
		this.risques_chute_hauteur_prevention_materiel_par_missing = false;
		this.risques_chute_hauteur_prevention_conduite_par_missing = false;
		this.risques_chute_hauteur_prevention_balisage_par_missing = false;
		this.risques_chute_hauteur_prevention_harnais_par_missing = false;
		this.risques_chute_hauteur_prevention_permis_par_missing = false;
		this.risques_chute_charge_objet_prevention_missing = false;
		this.risques_chute_charge_objet_prevention_casque_par_missing = false;
		this.risques_chute_charge_objet_prevention_baliser_par_missing = false;
		this.risques_mecaniques_details_missing = false;
		this.risques_mecaniques_prevention_missing = false;
		this.risques_mecaniques_prevention_gants_par_missing = false;
		this.risques_mecaniques_prevention_etat_par_missing = false;
		this.risques_mecaniques_prevention_balisage_par_missing = false;
		this.risques_mecaniques_prevention_permis_par_missing = false;
		this.risques_chimiques_details_missing = false;
		this.risques_chimiques_prevention_missing = false;
		this.risques_chimiques_prevention_epi_par_missing = false;
		this.risques_chimiques_prevention_produit_par_missing = false;
		this.risques_incendie_details_missing = false;
		this.risques_incendie_prevention_missing = false;
		this.risques_incendie_prevention_consignes_par_missing = false;
		this.risques_incendie_prevention_fumer_par_missing = false;
		this.risques_incendie_prevention_atex_par_missing = false;
		this.risques_incendie_prevention_permis_par_missing = false;
		this.risques_incendie_prevention_extincteurs_par_missing = false;
		this.risques_nuisances_details_missing = false;
		this.risques_nuisances_prevention_missing = false;
		this.risques_nuisances_prevention_auditives_par_missing = false;
		this.risques_nuisances_prevention_masque_par_missing = false;
		this.risques_nuisances_prevention_lunettes_par_missing = false;
		this.risques_lies_coactivite_details_missing = false;
		this.risques_lies_coactivite_prevention_missing = false;
		this.risques_lies_coactivite_prevention_precautions_par_missing = false;
		this.risques_bacteriologiques_details_missing = false;
		this.risques_bacteriologiques_prevention_missing = false;
		this.risques_bacteriologiques_prevention_regles_hygiene_par_missing = false;
		this.risques_bacteriologiques_prevention_port_epi_par_missing = false;
		this.autres_risques_type_missing = false;
		this.autres_risques_prevention_missing = false;
		this.autres_risques_prevention_par_missing = false;
		this.document_eu_autres_description_missing = false;

		// Step 6

		this.date_signature = date_signature;
		this.client_nom_signature = client_nom_signature;
		this.client_date_signature = client_date_signature;
		this.client_signature = client_signature;
	}

	clearRelated(source) {
		switch (source) {
			case 'presence_amiante' :
				if (this.presence_amiante === 'Non') {
					this.localisation_amiante = null;
				}
				break;
			case 'moyens_urgence_sortie' :
				if (!this.moyens_urgence_sortie) {
					this.moyens_urgence_sortie_commentaire = null;
				}
				break;
			case 'moyens_urgence_pharmacie' :
				if (!this.moyens_urgence_pharmacie) {
					this.moyens_urgence_pharmacie_commentaire = null;
				}
				break;
			case 'moyens_urgence_telephone' :
				if (!this.moyens_urgence_telephone) {
					this.moyens_urgence_telephone_commentaire = null;
				}
				break;
			case 'moyens_urgence_rassemblement' :
				if (!this.moyens_urgence_rassemblement) {
					this.moyens_urgence_rassemblement_commentaire = null;
				}
				break;
			case 'moyens_urgence_rincage' :
				if (!this.moyens_urgence_rincage) {
					this.moyens_urgence_rincage_commentaire = null;
				}
				break;
			case 'moyens_urgence_douche' :
				if (!this.moyens_urgence_douche) {
					this.moyens_urgence_douche_commentaire = null;
				}
				break;
			case 'moyens_urgence_extincteur' :
				if (!this.moyens_urgence_extincteur) {
					this.moyens_urgence_extincteur_commentaire = null;
				}
				break;
			case 'prestataire' :
				if (!this.prestataire) {
					this.prestataire_societe = null;
					this.prestataire_responsable = null;
					this.prestataire_nature_travaux = null;
				}
				break;
			case 'risques_electriques' :
				if (!this.risques_electriques) {
					this.risques_electriques_details_basse_tension = false;
					this.risques_electriques_details_haute_tension = false;
					this.risques_electriques_details_voisinage_tension = false;
					this.risques_electriques_prevention_habilitation = false;
					this.risques_electriques_prevention_habilitation_par_ee = false;
					this.risques_electriques_prevention_habilitation_par_eu = false;
					this.risques_electriques_prevention_consignation = false;
					this.risques_electriques_prevention_consignation_par_ee = false;
					this.risques_electriques_prevention_consignation_par_eu = false;
					this.risques_electriques_prevention_voisinage = false;
					this.risques_electriques_prevention_voisinage_par_ee = false;
					this.risques_electriques_prevention_voisinage_par_eu = false;
					this.risques_electriques_remarques = null;
				}
				break;
			case 'risques_electriques_prevention_habilitation':
				if (!this.risques_electriques_prevention_habilitation) {
					this.risques_electriques_prevention_habilitation_par_ee = false;
					this.risques_electriques_prevention_habilitation_par_eu = false;
				}
				break;
			case 'risques_electriques_prevention_consignation':
				if (!this.risques_electriques_prevention_consignation) {
					this.risques_electriques_prevention_consignation_par_ee = false;
					this.risques_electriques_prevention_consignation_par_eu = false;
				}
				break;
			case 'risques_electriques_prevention_voisinage':
				if (!this.risques_electriques_prevention_voisinage) {
					this.risques_electriques_prevention_voisinage_par_ee = false;
					this.risques_electriques_prevention_voisinage_par_eu = false;
				}
				break;
			case 'risques_lies_circulation' :
				if (!this.risques_lies_circulation) {
					this.risques_lies_circulation_details_vehicules = false;
					this.risques_lies_circulation_details_pietonne = false;
					this.risques_lies_circulation_moyens_prevention_regles = false;
					this.risques_lies_circulation_moyens_prevention_regles_par_ee = false;
					this.risques_lies_circulation_moyens_prevention_regles_par_eu = false;
					this.risques_lies_circulation_moyens_prevention_baliser = false;
					this.risques_lies_circulation_moyens_prevention_baliser_par_ee = false;
					this.risques_lies_circulation_moyens_prevention_baliser_par_eu = false;
					this.risques_lies_circulation_moyens_prevention_epi = false;
					this.risques_lies_circulation_moyens_prevention_epi_par_ee = false;
					this.risques_lies_circulation_moyens_prevention_epi_par_eu = false;
					this.risques_lies_circulation_remarques = null;
				}
				break;
			case 'risques_lies_circulation_moyens_prevention_regles':
				if (!this.risques_lies_circulation_moyens_prevention_regles) {
					this.risques_lies_circulation_moyens_prevention_regles_par_ee = false;
					this.risques_lies_circulation_moyens_prevention_regles_par_eu = false;
				}
				break;
			case 'risques_lies_circulation_moyens_prevention_baliser':
				if (!this.risques_lies_circulation_moyens_prevention_baliser) {
					this.risques_lies_circulation_moyens_prevention_baliser_par_ee = false;
					this.risques_lies_circulation_moyens_prevention_baliser_par_eu = false;
				}
				break;
			case 'risques_lies_circulation_moyens_prevention_epi':
				if (!this.risques_lies_circulation_moyens_prevention_epi) {
					this.risques_lies_circulation_moyens_prevention_epi_par_ee = false;
					this.risques_lies_circulation_moyens_prevention_epi_par_eu = false;
				}
				break;
			case 'risques_chute_hauteur' :
				if (!this.risques_chute_hauteur) {
					this.risques_chute_hauteur_details_nacelle = false;
					this.risques_chute_hauteur_details_echafaudage = false;
					this.risques_chute_hauteur_details_echelle = false;
					this.risques_chute_hauteur_prevention_materiel = false;
					this.risques_chute_hauteur_prevention_materiel_par_ee = false;
					this.risques_chute_hauteur_prevention_materiel_par_eu = false;
					this.risques_chute_hauteur_prevention_conduite = false;
					this.risques_chute_hauteur_prevention_conduite_par_ee = false;
					this.risques_chute_hauteur_prevention_conduite_par_eu = false;
					this.risques_chute_hauteur_prevention_balisage = false;
					this.risques_chute_hauteur_prevention_balisage_par_ee = false;
					this.risques_chute_hauteur_prevention_balisage_par_eu = false;
					this.risques_chute_hauteur_prevention_harnais = false;
					this.risques_chute_hauteur_prevention_harnais_par_ee = false;
					this.risques_chute_hauteur_prevention_harnais_par_eu = false;
					this.risques_chute_hauteur_prevention_permis = false;
					this.risques_chute_hauteur_prevention_permis_par_ee = false;
					this.risques_chute_hauteur_prevention_permis_par_eu = false;
					this.risques_chute_hauteur_prevention_pret_materiel = false;
					this.risques_chute_hauteur_remarques = null;
				}
				break;
			case 'risques_chute_hauteur_prevention_materiel':
				if (!this.risques_chute_hauteur_prevention_materiel) {
					this.risques_chute_hauteur_prevention_materiel_par_ee = false;
					this.risques_chute_hauteur_prevention_materiel_par_eu = false;
				}
				break;
			case 'risques_chute_hauteur_prevention_conduite':
				if (!this.risques_chute_hauteur_prevention_conduite) {
					this.risques_chute_hauteur_prevention_conduite_par_ee = false;
					this.risques_chute_hauteur_prevention_conduite_par_eu = false;
				}
				break;
			case 'risques_chute_hauteur_prevention_balisage':
				if (!this.risques_chute_hauteur_prevention_balisage) {
					this.risques_chute_hauteur_prevention_balisage_par_ee = false;
					this.risques_chute_hauteur_prevention_balisage_par_eu = false;
				}
				break;
			case 'risques_chute_hauteur_prevention_harnais':
				if (!this.risques_chute_hauteur_prevention_harnais) {
					this.risques_chute_hauteur_prevention_harnais_par_ee = false;
					this.risques_chute_hauteur_prevention_harnais_par_eu = false;
				}
				break;
			case 'risques_chute_hauteur_prevention_permis':
				if (!this.risques_chute_hauteur_prevention_permis) {
					this.risques_chute_hauteur_prevention_permis_par_ee = false;
					this.risques_chute_hauteur_prevention_permis_par_eu = false;
				}
				break;
			case 'risques_chute_charge_objet' :
				if (!this.risques_chute_charge_objet) {
					this.risques_chute_charge_objet_prevention_casque = false;
					this.risques_chute_charge_objet_prevention_casque_par_ee = false;
					this.risques_chute_charge_objet_prevention_casque_par_eu = false;
					this.risques_chute_charge_objet_prevention_baliser = false;
					this.risques_chute_charge_objet_prevention_baliser_par_ee = false;
					this.risques_chute_charge_objet_prevention_baliser_par_eu = false;
					this.risques_chute_charge_objet_remarques = null;
				}
				break;
			case 'risques_chute_charge_objet_prevention_casque':
				if (!this.risques_chute_charge_objet_prevention_casque) {
					this.risques_chute_charge_objet_prevention_casque_par_ee = false;
					this.risques_chute_charge_objet_prevention_casque_par_eu = false;
				}
				break;
			case 'risques_chute_charge_objet_prevention_baliser':
				if (!this.risques_chute_charge_objet_prevention_baliser) {
					this.risques_chute_charge_objet_prevention_baliser_par_ee = false;
					this.risques_chute_charge_objet_prevention_baliser_par_eu = false;
				}
				break;
			case 'risques_mecaniques' :
				if (!this.risques_mecaniques) {
					this.risques_mecaniques_details_mecanisme = false;
					this.risques_mecaniques_details_electro = false;
					this.risques_mecaniques_prevention_gants = false;
					this.risques_mecaniques_prevention_gants_par_ee = false;
					this.risques_mecaniques_prevention_gants_par_eu = false;
					this.risques_mecaniques_prevention_etat = false;
					this.risques_mecaniques_prevention_etat_par_ee = false;
					this.risques_mecaniques_prevention_etat_par_eu = false;
					this.risques_mecaniques_prevention_balisage = false;
					this.risques_mecaniques_prevention_balisage_par_ee = false;
					this.risques_mecaniques_prevention_balisage_par_eu = false;
					this.risques_mecaniques_prevention_permis = false;
					this.risques_mecaniques_prevention_permis_par_ee = false;
					this.risques_mecaniques_prevention_permis_par_eu = false;
					this.risques_mecaniques_remarques = null;
				}
				break;
			case 'risques_mecaniques_prevention_gants':
				if (!this.risques_mecaniques_prevention_gants) {
					this.risques_mecaniques_prevention_gants_par_ee = false;
					this.risques_mecaniques_prevention_gants_par_eu = false;
				}
				break;
			case 'risques_mecaniques_prevention_etat':
				if (!this.risques_mecaniques_prevention_etat) {
					this.risques_mecaniques_prevention_etat_par_ee = false;
					this.risques_mecaniques_prevention_etat_par_eu = false;
				}
				break;
			case 'risques_mecaniques_prevention_balisage':
				if (!this.risques_mecaniques_prevention_balisage) {
					this.risques_mecaniques_prevention_balisage_par_ee = false;
					this.risques_mecaniques_prevention_balisage_par_eu = false;
				}
				break;
			case 'risques_mecaniques_prevention_permis':
				if (!this.risques_mecaniques_prevention_permis) {
					this.risques_mecaniques_prevention_permis_par_ee = false;
					this.risques_mecaniques_prevention_permis_par_eu = false;
				}
				break;
			case 'risques_chimiques' :
				if (!this.risques_chimiques) {
					this.risques_chimiques_details_manipulation = false;
					this.risques_chimiques_details_voisinage = false;
					this.risques_chimiques_prevention_epi = false;
					this.risques_chimiques_prevention_epi_par_ee = false;
					this.risques_chimiques_prevention_epi_par_eu = false;
					this.risques_chimiques_prevention_produit = false;
					this.risques_chimiques_prevention_produit_par_ee = false;
					this.risques_chimiques_prevention_produit_par_eu = false;
					this.risques_chimiques_remarques = null;
				}
				break;
			case 'risques_chimiques_prevention_epi':
				if (!this.risques_chimiques_prevention_epi) {
					this.risques_chimiques_prevention_epi_par_ee = false;
					this.risques_chimiques_prevention_epi_par_eu = false;
				}
				break;
			case 'risques_chimiques_prevention_produit':
				if (!this.risques_chimiques_prevention_produit) {
					this.risques_chimiques_prevention_produit_par_ee = false;
					this.risques_chimiques_prevention_produit_par_eu = false;
				}
				break;
			case 'risques_incendie' :
				if (!this.risques_incendie) {
					this.risques_incendie_details_soudure = false;
					this.risques_incendie_details_produits = false;
					this.risques_incendie_prevention_consignes = false;
					this.risques_incendie_prevention_consignes_par_ee = false;
					this.risques_incendie_prevention_consignes_par_eu = false;
					this.risques_incendie_prevention_fumer = false;
					this.risques_incendie_prevention_fumer_par_ee = false;
					this.risques_incendie_prevention_fumer_par_eu = false;
					this.risques_incendie_prevention_atex = false;
					this.risques_incendie_prevention_atex_par_ee = false;
					this.risques_incendie_prevention_atex_par_eu = false;
					this.risques_incendie_prevention_permis = false;
					this.risques_incendie_prevention_permis_par_ee = false;
					this.risques_incendie_prevention_permis_par_eu = false;
					this.risques_incendie_prevention_extincteurs = false;
					this.risques_incendie_prevention_extincteurs_par_ee = false;
					this.risques_incendie_prevention_extincteurs_par_eu = false;
					this.risques_incendie_remarques = null;
				}
				break;
			case 'risques_incendie_prevention_consignes':
				if (!this.risques_incendie_prevention_consignes) {
					this.risques_incendie_prevention_consignes_par_ee = false;
					this.risques_incendie_prevention_consignes_par_eu = false;
				}
				break;
			case 'risques_incendie_prevention_fumer':
				if (!this.risques_incendie_prevention_fumer) {
					this.risques_incendie_prevention_fumer_par_ee = false;
					this.risques_incendie_prevention_fumer_par_eu = false;
				}
				break;
			case 'risques_incendie_prevention_atex':
				if (!this.risques_incendie_prevention_atex) {
					this.risques_incendie_prevention_atex_par_ee = false;
					this.risques_incendie_prevention_atex_par_eu = false;
				}
				break;
			case 'risques_incendie_prevention_permis':
				if (!this.risques_incendie_prevention_permis) {
					this.risques_incendie_prevention_permis_par_ee = false;
					this.risques_incendie_prevention_permis_par_eu = false;
				}
				break;
			case 'risques_incendie_prevention_extincteurs':
				if (!this.risques_incendie_prevention_extincteurs) {
					this.risques_incendie_prevention_extincteurs_par_ee = false;
					this.risques_incendie_prevention_extincteurs_par_eu = false;
				}
				break;
			case 'risques_nuisances' :
				if (!this.risques_nuisances) {
					this.risques_nuisances_details_poussieres = false;
					this.risques_nuisances_details_bruit = false;
					this.risques_nuisances_prevention_auditives = false;
					this.risques_nuisances_prevention_auditives_par_ee = false;
					this.risques_nuisances_prevention_auditives_par_eu = false;
					this.risques_nuisances_prevention_masque = false;
					this.risques_nuisances_prevention_masque_par_ee = false;
					this.risques_nuisances_prevention_masque_par_eu = false;
					this.risques_nuisances_prevention_lunettes = false;
					this.risques_nuisances_prevention_lunettes_par_ee = false;
					this.risques_nuisances_prevention_lunettes_par_eu = false;
					this.risques_nuisances_remarques = null;
				}
				break;
			case 'risques_nuisances_prevention_auditives':
				if (!this.risques_nuisances_prevention_auditives) {
					this.risques_nuisances_prevention_auditives_par_ee = false;
					this.risques_nuisances_prevention_auditives_par_eu = false;
				}
				break;
			case 'risques_nuisances_prevention_masque':
				if (!this.risques_nuisances_prevention_masque) {
					this.risques_nuisances_prevention_masque_par_ee = false;
					this.risques_nuisances_prevention_masque_par_eu = false;
				}
				break;
			case 'risques_nuisances_prevention_lunettes':
				if (!this.risques_nuisances_prevention_lunettes) {
					this.risques_nuisances_prevention_lunettes_par_ee = false;
					this.risques_nuisances_prevention_lunettes_par_eu = false;
				}
				break;
			case 'risques_lies_coactivite' :
				if (!this.risques_lies_coactivite) {
					this.risques_lies_coactivite_details_entreprises = false;
					this.risques_lies_coactivite_prevention_nature = null;
					this.risques_lies_coactivite_prevention_precautions = null;
					this.risques_lies_coactivite_prevention_precautions_par_ee = false;
					this.risques_lies_coactivite_prevention_precautions_par_eu = false;
					this.risques_lies_coactivite_remarques = null;
				}
				break;
			case 'risques_lies_coactivite_prevention_precautions':
				if (!this.risques_lies_coactivite_prevention_precautions) {
					this.risques_lies_coactivite_prevention_precautions_par_ee = false;
					this.risques_lies_coactivite_prevention_precautions_par_eu = false;
				}
				break;
			case 'risques_bacteriologiques' :
				if (!this.risques_bacteriologiques) {
					this.risques_bacteriologiques_details_zone_blanche = false;
					this.risques_bacteriologiques_prevention_regles_hygiene = null;
					this.risques_bacteriologiques_prevention_regles_hygiene_par_ee = false;
					this.risques_bacteriologiques_prevention_regles_hygiene_par_eu = false;
					this.risques_bacteriologiques_prevention_port_epi = false;
					this.risques_bacteriologiques_prevention_port_epi_par_ee = false;
					this.risques_bacteriologiques_prevention_port_epi_par_eu = false;
					this.risques_bacteriologiques_remarques = null;
				}
				break;
			case 'risques_bacteriologiques_prevention_regles_hygiene':
				if (!this.risques_bacteriologiques_prevention_regles_hygiene) {
					this.risques_bacteriologiques_prevention_regles_hygiene_par_ee = false;
					this.risques_bacteriologiques_prevention_regles_hygiene_par_eu = false;
				}
				break;
			case 'risques_bacteriologiques_prevention_port_epi':
				if (!this.risques_bacteriologiques_prevention_port_epi) {
					this.risques_bacteriologiques_prevention_port_epi_par_ee = false;
					this.risques_bacteriologiques_prevention_port_epi_par_eu = false;
				}
				break;
			case 'autres_risques' :
				if (!this.autres_risques) {
					this.autres_risques_type = null;
					this.autres_risques_prevention = null;
					this.autres_risques_prevention_par_ee = false;
					this.autres_risques_prevention_par_eu = false;
					this.autres_risques_remarques = null;
				}
				break;
			case 'autres_risques_prevention':
				if (!this.autres_risques_prevention) {
					this.autres_risques_prevention_par_ee = false;
					this.autres_risques_prevention_par_eu = false;
				}
				break;
			case 'document_eu_autres' :
				if (!this.document_eu_autres) {
					this.document_eu_autres_description = null;
				}
				break;
			default :
				break;
		}
	}

	missingMandatoryFields(step) {
		let missingMandatory = false;
		switch (step) {
			case 2 :
				if (this.nom_client == null || this.nom_client === '') {
					this.nom_client_missing = true;
					missingMandatory = true;
				}
				if (this.interlocuteur == null || this.interlocuteur === '') {
					this.interlocuteur_missing = true;
					missingMandatory = true;
				}
				if (this.telephone == null || this.telephone === '') {
					this.telephone_missing = true;
					missingMandatory = true;
				}
				if (this.entreprise_exterieure == null || this.entreprise_exterieure === '') {
					this.entreprise_exterieure_missing = true;
					missingMandatory = true;
				}
				if (this.entreprise_exterieure_responsable == null || this.entreprise_exterieure_responsable === '') {
					this.entreprise_exterieure_responsable_missing = true;
					missingMandatory = true;
				}
				if (this.entreprise_exterieure_telephone == null || this.entreprise_exterieure_telephone === '') {
					this.entreprise_exterieure_telephone_missing = true;
					missingMandatory = true;
				}
				if (this.nb_stemi == null) {
					this.nb_stemi_missing = true;
					missingMandatory = true;
				}
				if (this.lieu == null || this.lieu === '') {
					this.lieu_missing = true;
					missingMandatory = true;
				}
				if (this.date_debut_travaux == null) {
					this.date_debut_travaux_missing = true;
					missingMandatory = true;
				}
				if (this.date_fin_travaux == null) {
					this.date_fin_travaux_missing = true;
					missingMandatory = true;
				}
				if (this.horaires_intervention == null) {
					this.horaires_intervention_matin_missing = true;
					this.horaires_intervention_am_missing = true;
					missingMandatory = true;
				} else {
					if ((this.horaires_intervention.debut == null || this.horaires_intervention.fin == null) && (this.horaires_intervention.debut_am === null && this.horaires_intervention.fin_am === null)) {
						this.horaires_intervention_matin_missing = true;
						missingMandatory = true;
					}
					if ((this.horaires_intervention.debut_am == null || this.horaires_intervention.fin_am == null) && (this.horaires_intervention.debut === null && this.horaires_intervention.fin === null)) {
						this.horaires_intervention_am_missing = true;
						missingMandatory = true;
					}
				}
				if (this.presence_amiante == null) {
					this.presence_amiante_missing = true;
					missingMandatory = true;
				}
				if (this.presence_amiante === 'Oui' && (this.localisation_amiante == null || this.localisation_amiante === '')) {
					this.localisation_amiante_missing = true;
					missingMandatory = true;
				}
				break;
			case 4 :
				if (!this.moyens_urgence_sortie &&
					!this.moyens_urgence_pharmacie &&
					!this.moyens_urgence_telephone &&
					!this.moyens_urgence_rassemblement &&
					!this.moyens_urgence_rincage &&
					!this.moyens_urgence_douche &&
					!this.moyens_urgence_extincteur) {
					this.moyens_urgence_missing = true;
					missingMandatory = true;
				}
				break;
			case 5 :
				if (this.prestataire && (this.prestataire_societe == null || this.prestataire_societe === '')) {
					this.prestataire_societe_missing = true;
					missingMandatory = true;
				}
				if (this.prestataire && (this.prestataire_responsable == null || this.prestataire_responsable === '')) {
					this.prestataire_responsable_missing = true;
					missingMandatory = true;
				}
				if (this.prestataire && (this.prestataire_nature_travaux == null || this.prestataire_nature_travaux === '')) {
					this.prestataire_nature_travaux_missing = true;
					missingMandatory = true;
				}
				if (this.risques_electriques) {
					if (!this.risques_electriques_details_basse_tension &&
						!this.risques_electriques_details_haute_tension &&
						!this.risques_electriques_details_voisinage_tension) {
						this.risques_electriques_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_electriques_prevention_habilitation &&
						!this.risques_electriques_prevention_consignation &&
						!this.risques_electriques_prevention_voisinage) {
						this.risques_electriques_prevention_missing = true;
						missingMandatory = true;
					}
					if (this.risques_electriques_prevention_habilitation &&
						!this.risques_electriques_prevention_habilitation_par_ee &&
						!this.risques_electriques_prevention_habilitation_par_eu
					) {
						this.risques_electriques_prevention_habilitation_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_electriques_prevention_consignation &&
						!this.risques_electriques_prevention_consignation_par_ee &&
						!this.risques_electriques_prevention_consignation_par_eu
					) {
						this.risques_electriques_prevention_consignation_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_electriques_prevention_voisinage &&
						!this.risques_electriques_prevention_voisinage_par_ee &&
						!this.risques_electriques_prevention_voisinage_par_eu
					) {
						this.risques_electriques_prevention_voisinage_par_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_lies_circulation) {
					if (!this.risques_lies_circulation_details_vehicules &&
						!this.risques_lies_circulation_details_pietonne) {
						this.risques_lies_circulation_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_lies_circulation_moyens_prevention_regles &&
						!this.risques_lies_circulation_moyens_prevention_baliser &&
						!this.risques_lies_circulation_moyens_prevention_epi) {
						this.risques_lies_circulation_prevention_missing = true;
						missingMandatory = true;
					}
					if (this.risques_lies_circulation_moyens_prevention_regles &&
						!this.risques_lies_circulation_moyens_prevention_regles_par_ee &&
						!this.risques_lies_circulation_moyens_prevention_regles_par_eu
					) {
						this.risques_lies_circulation_moyens_prevention_regles_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_lies_circulation_moyens_prevention_baliser &&
						!this.risques_lies_circulation_moyens_prevention_baliser_par_ee &&
						!this.risques_lies_circulation_moyens_prevention_baliser_par_eu
					) {
						this.risques_lies_circulation_moyens_prevention_baliser_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_lies_circulation_moyens_prevention_epi &&
						!this.risques_lies_circulation_moyens_prevention_epi_par_ee &&
						!this.risques_lies_circulation_moyens_prevention_epi_par_eu
					) {
						this.risques_lies_circulation_moyens_prevention_epi_par_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_chute_hauteur) {
					if (!this.risques_chute_hauteur_details_nacelle &&
						!this.risques_chute_hauteur_details_echafaudage &&
						!this.risques_chute_hauteur_details_echelle) {
						this.risques_chute_hauteur_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_chute_hauteur_prevention_materiel &&
						!this.risques_chute_hauteur_prevention_conduite &&
						!this.risques_chute_hauteur_prevention_balisage &&
						!this.risques_chute_hauteur_prevention_harnais &&
						!this.risques_chute_hauteur_prevention_permis &&
						!this.risques_chute_hauteur_prevention_pret_materiel) {
						this.risques_chute_hauteur_prevention_missing = true;
						missingMandatory = true;
					}
					if (this.risques_chute_hauteur_prevention_materiel &&
						!this.risques_chute_hauteur_prevention_materiel_par_ee &&
						!this.risques_chute_hauteur_prevention_materiel_par_eu
					) {
						this.risques_chute_hauteur_prevention_materiel_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_chute_hauteur_prevention_conduite &&
						!this.risques_chute_hauteur_prevention_conduite_par_ee &&
						!this.risques_chute_hauteur_prevention_conduite_par_eu
					) {
						this.risques_chute_hauteur_prevention_conduite_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_chute_hauteur_prevention_balisage &&
						!this.risques_chute_hauteur_prevention_balisage_par_ee &&
						!this.risques_chute_hauteur_prevention_balisage_par_eu
					) {
						this.risques_chute_hauteur_prevention_balisage_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_chute_hauteur_prevention_harnais &&
						!this.risques_chute_hauteur_prevention_harnais_par_ee &&
						!this.risques_chute_hauteur_prevention_harnais_par_eu
					) {
						this.risques_chute_hauteur_prevention_harnais_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_chute_hauteur_prevention_permis &&
						!this.risques_chute_hauteur_prevention_permis_par_ee &&
						!this.risques_chute_hauteur_prevention_permis_par_eu
					) {
						this.risques_chute_hauteur_prevention_permis_par_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_chute_charge_objet) {
					if (!this.risques_chute_charge_objet_prevention_casque &&
						!this.risques_chute_charge_objet_prevention_baliser) {
						this.risques_chute_charge_objet_prevention_missing = true;
						missingMandatory = true;
					}
					if (this.risques_chute_charge_objet_prevention_casque &&
						!this.risques_chute_charge_objet_prevention_casque_par_ee &&
						!this.risques_chute_charge_objet_prevention_casque_par_eu
					) {
						this.risques_chute_charge_objet_prevention_casque_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_chute_charge_objet_prevention_baliser &&
						!this.risques_chute_charge_objet_prevention_baliser_par_ee &&
						!this.risques_chute_charge_objet_prevention_baliser_par_eu
					) {
						this.risques_chute_charge_objet_prevention_baliser_par_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_mecaniques) {
					if (!this.risques_mecaniques_details_mecanisme &&
						!this.risques_mecaniques_details_electro) {
						this.risques_mecaniques_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_mecaniques_prevention_gants &&
						!this.risques_mecaniques_prevention_etat &&
						!this.risques_mecaniques_prevention_balisage &&
						!this.risques_mecaniques_prevention_permis) {
						this.risques_mecaniques_prevention_missing = true;
						missingMandatory = true;
					}
					if (this.risques_mecaniques_prevention_gants &&
						!this.risques_mecaniques_prevention_gants_par_ee &&
						!this.risques_mecaniques_prevention_gants_par_eu
					) {
						this.risques_mecaniques_prevention_gants_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_mecaniques_prevention_etat &&
						!this.risques_mecaniques_prevention_etat_par_ee &&
						!this.risques_mecaniques_prevention_etat_par_eu
					) {
						this.risques_mecaniques_prevention_etat_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_mecaniques_prevention_balisage &&
						!this.risques_mecaniques_prevention_balisage_par_ee &&
						!this.risques_mecaniques_prevention_balisage_par_eu
					) {
						this.risques_mecaniques_prevention_balisage_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_mecaniques_prevention_permis &&
						!this.risques_mecaniques_prevention_permis_par_ee &&
						!this.risques_mecaniques_prevention_permis_par_eu
					) {
						this.risques_mecaniques_prevention_permis_par_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_chimiques) {
					if (!this.risques_chimiques_details_manipulation &&
						!this.risques_chimiques_details_voisinage) {
						this.risques_chimiques_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_chimiques_prevention_epi &&
						!this.risques_chimiques_prevention_produit) {
						this.risques_chimiques_prevention_missing = true;
						missingMandatory = true;
					}
					if (this.risques_chimiques_prevention_epi &&
						!this.risques_chimiques_prevention_epi_par_ee &&
						!this.risques_chimiques_prevention_epi_par_eu
					) {
						this.risques_chimiques_prevention_epi_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_chimiques_prevention_produit &&
						!this.risques_chimiques_prevention_produit_par_ee &&
						!this.risques_chimiques_prevention_produit_par_eu
					) {
						this.risques_chimiques_prevention_produit_par_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_incendie) {
					if (!this.risques_incendie_details_soudure &&
						!this.risques_incendie_details_produits) {
						this.risques_incendie_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_incendie_prevention_consignes &&
						!this.risques_incendie_prevention_fumer &&
						!this.risques_incendie_prevention_atex &&
						!this.risques_incendie_prevention_permis &&
						!this.risques_incendie_prevention_extincteurs) {
						this.risques_incendie_prevention_missing = true;
						missingMandatory = true;
					}
					if (this.risques_incendie_prevention_consignes &&
						!this.risques_incendie_prevention_consignes_par_ee &&
						!this.risques_incendie_prevention_consignes_par_eu
					) {
						this.risques_incendie_prevention_consignes_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_incendie_prevention_fumer &&
						!this.risques_incendie_prevention_fumer_par_ee &&
						!this.risques_incendie_prevention_fumer_par_eu
					) {
						this.risques_incendie_prevention_fumer_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_incendie_prevention_atex &&
						!this.risques_incendie_prevention_atex_par_ee &&
						!this.risques_incendie_prevention_atex_par_eu
					) {
						this.risques_incendie_prevention_atex_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_incendie_prevention_permis &&
						!this.risques_incendie_prevention_permis_par_ee &&
						!this.risques_incendie_prevention_permis_par_eu
					) {
						this.risques_incendie_prevention_permis_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_incendie_prevention_extincteurs &&
						!this.risques_incendie_prevention_extincteurs_par_ee &&
						!this.risques_incendie_prevention_extincteurs_par_eu
					) {
						this.risques_incendie_prevention_extincteurs_par_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_nuisances) {
					if (!this.risques_nuisances_details_poussieres &&
						!this.risques_nuisances_details_bruit) {
						this.risques_nuisances_details_missing = true;
						missingMandatory = true;
					}
					if (!this.risques_nuisances_prevention_auditives &&
						!this.risques_nuisances_prevention_masque &&
						!this.risques_nuisances_prevention_lunettes) {
						this.risques_nuisances_prevention_missing = true;
						missingMandatory = true;
					}
					if (this.risques_nuisances_prevention_auditives &&
						!this.risques_nuisances_prevention_auditives_par_ee &&
						!this.risques_nuisances_prevention_auditives_par_eu
					) {
						this.risques_nuisances_prevention_auditives_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_nuisances_prevention_masque &&
						!this.risques_nuisances_prevention_masque_par_ee &&
						!this.risques_nuisances_prevention_masque_par_eu
					) {
						this.risques_nuisances_prevention_masque_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_nuisances_prevention_lunettes &&
						!this.risques_nuisances_prevention_lunettes_par_ee &&
						!this.risques_nuisances_prevention_lunettes_par_eu
					) {
						this.risques_nuisances_prevention_lunettes_par_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_lies_coactivite) {
					if (!this.risques_lies_coactivite_details_entreprises) {
						this.risques_lies_coactivite_details_missing = true;
						missingMandatory = true;
					}
					if ((this.risques_lies_coactivite_prevention_nature == null || this.risques_lies_coactivite_prevention_nature === '') &&
						(this.risques_lies_coactivite_prevention_precautions == null || this.risques_lies_coactivite_prevention_precautions === '')) {
						this.risques_lies_coactivite_prevention_missing = true;
						missingMandatory = true;
					}
					if (this.risques_lies_coactivite_prevention_precautions !== null &&
						!this.risques_lies_coactivite_prevention_precautions_par_ee &&
						!this.risques_lies_coactivite_prevention_precautions_par_eu
					) {
						this.risques_lies_coactivite_prevention_precautions_par_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_bacteriologiques) {
					if (!this.risques_bacteriologiques_details_zone_blanche) {
						this.risques_bacteriologiques_details_missing = true;
						missingMandatory = true;
					}
					if ((this.risques_bacteriologiques_prevention_regles_hygiene == null || this.risques_bacteriologiques_prevention_regles_hygiene === '') &&
						!this.risques_bacteriologiques_prevention_port_epi) {
						this.risques_bacteriologiques_prevention_missing = true;
						missingMandatory = true;
					}
					if (this.risques_bacteriologiques_prevention_regles_hygiene !== null &&
						!this.risques_bacteriologiques_prevention_regles_hygiene_par_ee &&
						!this.risques_bacteriologiques_prevention_regles_hygiene_par_eu
					) {
						this.risques_bacteriologiques_prevention_regles_hygiene_par_missing = true;
						missingMandatory = true;
					}
					if (this.risques_bacteriologiques_prevention_port_epi &&
						!this.risques_bacteriologiques_prevention_port_epi_par_ee &&
						!this.risques_bacteriologiques_prevention_port_epi_par_eu
					) {
						this.risques_bacteriologiques_prevention_port_epi_par_missing = true;
						missingMandatory = true;
					}
				}
				if (this.autres_risques) {
					if (this.autres_risques_type == null || this.autres_risques_type === '') {
						this.autres_risques_type_missing = true;
						missingMandatory = true;
					}
					if (this.autres_risques_prevention == null || this.autres_risques_prevention === '') {
						this.autres_risques_prevention_missing = true;
						missingMandatory = true;
					}
					if (this.autres_risques_prevention !== null &&
						!this.autres_risques_prevention_par_ee &&
						!this.autres_risques_prevention_par_eu
					) {
						this.autres_risques_prevention_par_missing = true;
						missingMandatory = true;
					}
				}
				if (this.document_eu_autres && (this.document_eu_autres_description === null || this.document_eu_autres_description === '')) {
					this.document_eu_autres_description_missing = true;
					missingMandatory = true;
				}
				break;
			default :
				break;
		}
		return missingMandatory;
	}

	updateMissing(source) {
		switch (source) {
			case 'nom_client' :
				if (this.nom_client != null) {
					this.nom_client_missing = false;
				}
				break;
			case 'interlocuteur' :
				if (this.interlocuteur != null) {
					this.interlocuteur_missing = false;
				}
				break;
			case 'telephone' :
				if (this.telephone != null) {
					this.telephone_missing = false;
				}
				break;
			case 'entreprise_exterieure' :
				if (this.entreprise_exterieure != null) {
					this.entreprise_exterieure_missing = false;
				}
				break;
			case 'entreprise_exterieure_responsable' :
				if (this.entreprise_exterieure_responsable != null) {
					this.entreprise_exterieure_responsable_missing = false;
				}
				break;
			case 'entreprise_exterieure_telephone' :
				if (this.entreprise_exterieure_telephone != null) {
					this.entreprise_exterieure_telephone_missing = false;
				}
				break;
			case 'nb_stemi' :
				if (this.nb_stemi != null) {
					this.nb_stemi_missing = false;
				}
				break;
			case 'lieu' :
				if (this.lieu != null) {
					this.lieu_missing = false;
				}
				break;
			case 'date_debut_travaux' :
				if (this.date_debut_travaux != null) {
					this.date_debut_travaux_missing = false;
				}
				break;
			case 'date_fin_travaux' :
				if (this.date_fin_travaux != null) {
					this.date_fin_travaux_missing = false;
				}
				break;
			case 'horaires_intervention':
				if (this.horaires_intervention != null) {
					if (this.horaires_intervention.debut != null || this.horaires_intervention.fin != null) {
						this.horaires_intervention_am_missing = false;
					}
					if (this.horaires_intervention.debut != null && this.horaires_intervention.fin != null) {
						this.horaires_intervention_matin_missing = false;
					}
					if (this.horaires_intervention.debut_am != null || this.horaires_intervention.fin_am != null) {
						this.horaires_intervention_matin_missing = false;
					}
					if (this.horaires_intervention.debut_am != null && this.horaires_intervention.fin_am != null) {
						this.horaires_intervention_am_missing = false;
					}
				}
				break;
			case 'presence_amiante' :
				if (this.presence_amiante != null) {
					this.presence_amiante_missing = false;
				}
				break;
			case 'localisation_amiante' :
				if (this.localisation_amiante != null) {
					this.localisation_amiante_missing = false;
				}
				break;
			case 'moyens_urgence' :
				if (this.moyens_urgence_sortie ||
					this.moyens_urgence_pharmacie ||
					this.moyens_urgence_telephone ||
					this.moyens_urgence_rassemblement ||
					this.moyens_urgence_rincage ||
					this.moyens_urgence_douche ||
					this.moyens_urgence_extincteur) {
					this.moyens_urgence_missing = false;
				}
				break;
			case 'prestataire' :
				if (!this.prestataire) {
					this.prestataire_societe_missing = false;
					this.prestataire_responsable_missing = false;
					this.prestataire_nature_travaux_missing = false;
				}
				break;
			case 'prestataire_societe' :
				if (this.prestataire_societe != null) {
					this.prestataire_societe_missing = false;
				}
				break;
			case 'prestataire_responsable' :
				if (this.prestataire_responsable != null) {
					this.prestataire_responsable_missing = false;
				}
				break;
			case 'prestataire_nature_travaux' :
				if (this.prestataire_nature_travaux != null) {
					this.prestataire_nature_travaux_missing = false;
				}
				break;
			case 'risques_electriques_details' :
				if (!this.risques_electriques) {
					this.risques_electriques_details_missing = false;
				} else if (this.risques_electriques_details_basse_tension ||
					this.risques_electriques_details_haute_tension ||
					this.risques_electriques_details_voisinage_tension) {
					this.risques_electriques_details_missing = false;
				}
				break;
			case 'risques_electriques_prevention' :
				if (!this.risques_electriques) {
					this.risques_electriques_prevention_missing = false;
				} else if (this.risques_electriques_prevention_habilitation ||
					this.risques_electriques_prevention_consignation ||
					this.risques_electriques_prevention_voisinage) {
					this.risques_electriques_prevention_missing = false;
				}
				break;
			case 'risques_electriques_prevention_habilitation_par' :
				if (!this.risques_electriques_prevention_habilitation) {
					this.risques_electriques_prevention_habilitation_par_missing = false;
				} else if (this.risques_electriques_prevention_habilitation &&
					(this.risques_electriques_prevention_habilitation_par_ee ||
						this.risques_electriques_prevention_habilitation_par_eu)) {
					this.risques_electriques_prevention_habilitation_par_missing = false;
				}
				break;
			case 'risques_electriques_prevention_consignation_par' :
				if (!this.risques_electriques_prevention_consignation) {
					this.risques_electriques_prevention_consignation_par_missing = false;
				} else if (this.risques_electriques_prevention_consignation &&
					(this.risques_electriques_prevention_consignation_par_ee ||
						this.risques_electriques_prevention_consignation_par_eu)) {
					this.risques_electriques_prevention_consignation_par_missing = false;
				}
				break;
			case 'risques_electriques_prevention_voisinage_par' :
				if (!this.risques_electriques_prevention_voisinage) {
					this.risques_electriques_prevention_voisinage_par_missing = false;
				} else if (this.risques_electriques_prevention_voisinage &&
					(this.risques_electriques_prevention_voisinage_par_ee ||
						this.risques_electriques_prevention_voisinage_par_eu)) {
					this.risques_electriques_prevention_voisinage_par_missing = false;
				}
				break;
			case 'risques_lies_circulation_details' :
				if (!this.risques_lies_circulation) {
					this.risques_lies_circulation_details_missing = false;
				} else if (this.risques_lies_circulation_details_vehicules ||
					this.risques_lies_circulation_details_pietonne) {
					this.risques_lies_circulation_details_missing = false;
				}
				break;
			case 'risques_lies_circulation_prevention' :
				if (!this.risques_electriques) {
					this.risques_lies_circulation_prevention_missing = false;
				} else if (this.risques_lies_circulation_moyens_prevention_regles ||
					this.risques_lies_circulation_moyens_prevention_baliser ||
					this.risques_lies_circulation_moyens_prevention_epi) {
					this.risques_lies_circulation_prevention_missing = false;
				}
				break;
			case 'risques_lies_circulation_moyens_prevention_regles_par' :
				if (!this.risques_lies_circulation_moyens_prevention_regles) {
					this.risques_lies_circulation_moyens_prevention_regles_par_missing = false;
				} else if (this.risques_lies_circulation_moyens_prevention_regles &&
					(this.risques_lies_circulation_moyens_prevention_regles_par_ee ||
						this.risques_lies_circulation_moyens_prevention_regles_par_eu)) {
					this.risques_lies_circulation_moyens_prevention_regles_par_missing = false;
				}
				break;
			case 'risques_lies_circulation_moyens_prevention_baliser_par' :
				if (!this.risques_lies_circulation_moyens_prevention_baliser) {
					this.risques_lies_circulation_moyens_prevention_baliser_par_missing = false;
				} else if (this.risques_lies_circulation_moyens_prevention_baliser &&
					(this.risques_lies_circulation_moyens_prevention_baliser_par_ee ||
						this.risques_lies_circulation_moyens_prevention_baliser_par_eu)) {
					this.risques_lies_circulation_moyens_prevention_baliser_par_missing = false;
				}
				break;
			case 'risques_lies_circulation_moyens_prevention_epi_par' :
				if (!this.risques_lies_circulation_moyens_prevention_epi) {
					this.risques_lies_circulation_moyens_prevention_epi_par_missing = false;
				} else if (this.risques_lies_circulation_moyens_prevention_epi &&
					(this.risques_lies_circulation_moyens_prevention_epi_par_ee ||
						this.risques_lies_circulation_moyens_prevention_epi_par_eu)) {
					this.risques_lies_circulation_moyens_prevention_epi_par_missing = false;
				}
				break;
			case 'risques_chute_hauteur_details' :
				if (!this.risques_chute_hauteur) {
					this.risques_chute_hauteur_details_missing = false;
				} else if (this.risques_chute_hauteur_details_nacelle ||
					this.risques_chute_hauteur_details_echafaudage ||
					this.risques_chute_hauteur_details_echelle) {
					this.risques_chute_hauteur_details_missing = false;
				}
				break;
			case 'risques_chute_hauteur_prevention' :
				if (!this.risques_chute_hauteur) {
					this.risques_chute_hauteur_prevention_missing = false;
				} else if (this.risques_chute_hauteur_prevention_materiel ||
					this.risques_chute_hauteur_prevention_conduite ||
					this.risques_chute_hauteur_prevention_balisage ||
					this.risques_chute_hauteur_prevention_harnais ||
					this.risques_chute_hauteur_prevention_permis ||
					this.risques_chute_hauteur_prevention_pret_materiel) {
					this.risques_chute_hauteur_prevention_missing = false;
				}
				break;
			case 'risques_chute_hauteur_prevention_materiel_par' :
				if (!this.risques_chute_hauteur_prevention_materiel) {
					this.risques_chute_hauteur_prevention_materiel_par_missing = false;
				} else if (this.risques_chute_hauteur_prevention_materiel &&
					(this.risques_chute_hauteur_prevention_materiel_par_ee ||
						this.risques_chute_hauteur_prevention_materiel_par_eu)) {
					this.risques_chute_hauteur_prevention_materiel_par_missing = false;
				}
				break;
			case 'risques_chute_hauteur_prevention_conduite_par' :
				if (!this.risques_chute_hauteur_prevention_conduite) {
					this.risques_chute_hauteur_prevention_conduite_par_missing = false;
				} else if (this.risques_chute_hauteur_prevention_conduite &&
					(this.risques_chute_hauteur_prevention_conduite_par_ee ||
						this.risques_chute_hauteur_prevention_conduite_par_eu)) {
					this.risques_chute_hauteur_prevention_conduite_par_missing = false;
				}
				break;
			case 'risques_chute_hauteur_prevention_balisage_par' :
				if (!this.risques_chute_hauteur_prevention_balisage) {
					this.risques_chute_hauteur_prevention_balisage_par_missing = false;
				} else if (this.risques_chute_hauteur_prevention_balisage &&
					(this.risques_chute_hauteur_prevention_balisage_par_ee ||
						this.risques_chute_hauteur_prevention_balisage_par_eu)) {
					this.risques_chute_hauteur_prevention_balisage_par_missing = false;
				}
				break;
			case 'risques_chute_hauteur_prevention_harnais_par' :
				if (!this.risques_chute_hauteur_prevention_harnais) {
					this.risques_chute_hauteur_prevention_harnais_par_missing = false;
				} else if (this.risques_chute_hauteur_prevention_harnais &&
					(this.risques_chute_hauteur_prevention_harnais_par_ee ||
						this.risques_chute_hauteur_prevention_harnais_par_eu)) {
					this.risques_chute_hauteur_prevention_harnais_par_missing = false;
				}
				break;
			case 'risques_chute_hauteur_prevention_permis_par' :
				if (!this.risques_chute_hauteur_prevention_permis) {
					this.risques_chute_hauteur_prevention_permis_par_missing = false;
				} else if (this.risques_chute_hauteur_prevention_permis &&
					(this.risques_chute_hauteur_prevention_permis_par_ee ||
						this.risques_chute_hauteur_prevention_permis_par_eu)) {
					this.risques_chute_hauteur_prevention_permis_par_missing = false;
				}
				break;
			case 'risques_chute_charge_objet_prevention' :
				if (!this.risques_chute_charge_objet) {
					this.risques_chute_charge_objet_prevention_missing = false;
				} else if (this.risques_chute_charge_objet_prevention_casque ||
					this.risques_chute_charge_objet_prevention_baliser) {
					this.risques_chute_charge_objet_prevention_missing = false;
				}
				break;
			case 'risques_chute_charge_objet_prevention_casque_par' :
				if (!this.risques_chute_charge_objet_prevention_casque) {
					this.risques_chute_charge_objet_prevention_casque_par_missing = false;
				} else if (this.risques_chute_charge_objet_prevention_casque &&
					(this.risques_chute_charge_objet_prevention_casque_par_ee ||
						this.risques_chute_charge_objet_prevention_casque_par_eu)) {
					this.risques_chute_charge_objet_prevention_casque_par_missing = false;
				}
				break;
			case 'risques_chute_charge_objet_prevention_baliser_par' :
				if (!this.risques_chute_charge_objet_prevention_baliser) {
					this.risques_chute_charge_objet_prevention_baliser_par_missing = false;
				} else if (this.risques_chute_charge_objet_prevention_baliser &&
					(this.risques_chute_charge_objet_prevention_baliser_par_ee ||
						this.risques_chute_charge_objet_prevention_baliser_par_eu)) {
					this.risques_chute_charge_objet_prevention_baliser_par_missing = false;
				}
				break;
			case 'risques_mecaniques_details' :
				if (!this.risques_mecaniques) {
					this.risques_mecaniques_details_missing = false;
				} else if (this.risques_mecaniques_details_mecanisme ||
					this.risques_mecaniques_details_electro) {
					this.risques_mecaniques_details_missing = false;
				}
				break;
			case 'risques_mecaniques_prevention' :
				if (!this.risques_mecaniques) {
					this.risques_mecaniques_prevention_missing = false;
				} else if (this.risques_mecaniques_prevention_gants ||
					this.risques_mecaniques_prevention_etat ||
					this.risques_mecaniques_prevention_balisage ||
					this.risques_mecaniques_prevention_permis) {
					this.risques_mecaniques_prevention_missing = false;
				}
				break;
			case 'risques_mecaniques_prevention_gants_par' :
				if (!this.risques_mecaniques_prevention_gants) {
					this.risques_mecaniques_prevention_gants_par_missing = false;
				} else if (this.risques_mecaniques_prevention_gants &&
					(this.risques_mecaniques_prevention_gants_par_ee ||
						this.risques_mecaniques_prevention_gants_par_eu)) {
					this.risques_mecaniques_prevention_gants_par_missing = false;
				}
				break;
			case 'risques_mecaniques_prevention_etat_par' :
				if (!this.risques_mecaniques_prevention_etat) {
					this.risques_mecaniques_prevention_etat_par_missing = false;
				} else if (this.risques_mecaniques_prevention_etat &&
					(this.risques_mecaniques_prevention_etat_par_ee ||
						this.risques_mecaniques_prevention_etat_par_eu)) {
					this.risques_mecaniques_prevention_etat_par_missing = false;
				}
				break;
			case 'risques_mecaniques_prevention_balisage_par' :
				if (!this.risques_mecaniques_prevention_balisage) {
					this.risques_mecaniques_prevention_balisage_par_missing = false;
				} else if (this.risques_mecaniques_prevention_balisage &&
					(this.risques_mecaniques_prevention_balisage_par_ee ||
						this.risques_mecaniques_prevention_balisage_par_eu)) {
					this.risques_mecaniques_prevention_balisage_par_missing = false;
				}
				break;
			case 'risques_mecaniques_prevention_permis_par' :
				if (!this.risques_mecaniques_prevention_permis) {
					this.risques_mecaniques_prevention_permis_par_missing = false;
				} else if (this.risques_mecaniques_prevention_permis &&
					(this.risques_mecaniques_prevention_permis_par_ee ||
						this.risques_mecaniques_prevention_permis_par_eu)) {
					this.risques_mecaniques_prevention_permis_par_missing = false;
				}
				break;
			case 'risques_chimiques_details' :
				if (!this.risques_chimiques) {
					this.risques_chimiques_details_missing = false;
				} else if (this.risques_chimiques_details_manipulation ||
					this.risques_chimiques_details_voisinage) {
					this.risques_chimiques_details_missing = false;
				}
				break;
			case 'risques_chimiques_prevention' :
				if (!this.risques_chimiques) {
					this.risques_chimiques_prevention_missing = false;
				} else if (this.risques_chimiques_prevention_epi ||
					this.risques_chimiques_prevention_produit) {
					this.risques_chimiques_prevention_missing = false;
				}
				break;
			case 'risques_chimiques_prevention_epi_par' :
				if (!this.risques_chimiques_prevention_epi) {
					this.risques_chimiques_prevention_epi_par_missing = false;
				} else if (this.risques_chimiques_prevention_epi &&
					(this.risques_chimiques_prevention_epi_par_ee ||
						this.risques_chimiques_prevention_epi_par_eu)) {
					this.risques_chimiques_prevention_epi_par_missing = false;
				}
				break;
			case 'risques_chimiques_prevention_produit_par' :
				if (!this.risques_chimiques_prevention_produit) {
					this.risques_chimiques_prevention_produit_par_missing = false;
				} else if (this.risques_chimiques_prevention_produit &&
					(this.risques_chimiques_prevention_produit_par_ee ||
						this.risques_chimiques_prevention_produit_par_eu)) {
					this.risques_chimiques_prevention_produit_par_missing = false;
				}
				break;
			case 'risques_incendie_details' :
				if (!this.risques_incendie) {
					this.risques_incendie_details_missing = false;
				} else if (this.risques_incendie_details_soudure ||
					this.risques_incendie_details_produits) {
					this.risques_incendie_details_missing = false;
				}
				break;
			case 'risques_incendie_prevention' :
				if (!this.risques_incendie) {
					this.risques_incendie_prevention_missing = false;
				} else if (this.risques_incendie_prevention_consignes ||
					this.risques_incendie_prevention_fumer ||
					this.risques_incendie_prevention_atex ||
					this.risques_incendie_prevention_permis ||
					this.risques_incendie_prevention_extincteurs) {
					this.risques_incendie_prevention_missing = false;
				}
				break;
			case 'risques_incendie_prevention_consignes_par' :
				if (!this.risques_incendie_prevention_consignes) {
					this.risques_incendie_prevention_consignes_par_missing = false;
				} else if (this.risques_incendie_prevention_consignes &&
					(this.risques_incendie_prevention_consignes_par_ee ||
						this.risques_incendie_prevention_consignes_par_eu)) {
					this.risques_incendie_prevention_consignes_par_missing = false;
				}
				break;
			case 'risques_incendie_prevention_fumer_par' :
				if (!this.risques_incendie_prevention_fumer) {
					this.risques_incendie_prevention_fumer_par_missing = false;
				} else if (this.risques_incendie_prevention_fumer &&
					(this.risques_incendie_prevention_fumer_par_ee ||
						this.risques_incendie_prevention_fumer_par_eu)) {
					this.risques_incendie_prevention_fumer_par_missing = false;
				}
				break;
			case 'risques_incendie_prevention_atex_par' :
				if (!this.risques_incendie_prevention_atex) {
					this.risques_incendie_prevention_atex_par_missing = false;
				} else if (this.risques_incendie_prevention_atex &&
					(this.risques_incendie_prevention_atex_par_ee ||
						this.risques_incendie_prevention_atex_par_eu)) {
					this.risques_incendie_prevention_atex_par_missing = false;
				}
				break;
			case 'risques_incendie_prevention_permis_par' :
				if (!this.risques_incendie_prevention_permis) {
					this.risques_incendie_prevention_permis_par_missing = false;
				} else if (this.risques_incendie_prevention_permis &&
					(this.risques_incendie_prevention_permis_par_ee ||
						this.risques_incendie_prevention_permis_par_eu)) {
					this.risques_incendie_prevention_permis_par_missing = false;
				}
				break;
			case 'risques_incendie_prevention_extincteurs_par' :
				if (!this.risques_incendie_prevention_extincteurs) {
					this.risques_incendie_prevention_extincteurs_par_missing = false;
				} else if (this.risques_incendie_prevention_extincteurs &&
					(this.risques_incendie_prevention_extincteurs_par_ee ||
						this.risques_incendie_prevention_extincteurs_par_eu)) {
					this.risques_incendie_prevention_extincteurs_par_missing = false;
				}
				break;
			case 'risques_nuisances_details' :
				if (!this.risques_nuisances) {
					this.risques_nuisances_details_missing = false;
				} else if (this.risques_nuisances_details_poussieres ||
					this.risques_nuisances_details_bruit) {
					this.risques_nuisances_details_missing = false;
				}
				break;
			case 'risques_nuisances_prevention' :
				if (!this.risques_nuisances) {
					this.risques_nuisances_prevention_missing = false;
				} else if (this.risques_nuisances_prevention_auditives ||
					this.risques_nuisances_prevention_masque ||
					this.risques_nuisances_prevention_lunettes) {
					this.risques_nuisances_prevention_missing = false;
				}
				break;
			case 'risques_nuisances_prevention_auditives_par' :
				if (!this.risques_nuisances_prevention_auditives) {
					this.risques_nuisances_prevention_auditives_par_missing = false;
				} else if (this.risques_nuisances_prevention_auditives &&
					(this.risques_nuisances_prevention_auditives_par_ee ||
						this.risques_nuisances_prevention_auditives_par_eu)) {
					this.risques_nuisances_prevention_auditives_par_missing = false;
				}
				break;
			case 'risques_nuisances_prevention_masque_par' :
				if (!this.risques_nuisances_prevention_masque) {
					this.risques_nuisances_prevention_masque_par_missing = false;
				} else if (this.risques_nuisances_prevention_masque &&
					(this.risques_nuisances_prevention_masque_par_ee ||
						this.risques_nuisances_prevention_masque_par_eu)) {
					this.risques_nuisances_prevention_masque_par_missing = false;
				}
				break;
			case 'risques_nuisances_prevention_lunettes_par' :
				if (!this.risques_nuisances_prevention_lunettes) {
					this.risques_nuisances_prevention_lunettes_par_missing = false;
				} else if (this.risques_nuisances_prevention_lunettes &&
					(this.risques_nuisances_prevention_lunettes_par_ee ||
						this.risques_nuisances_prevention_lunettes_par_eu)) {
					this.risques_nuisances_prevention_lunettes_par_missing = false;
				}
				break;
			case 'risques_lies_coactivite_details' :
				if (!this.risques_lies_coactivite) {
					this.risques_lies_coactivite_details_missing = false;
				} else if (this.risques_lies_coactivite_details_entreprises) {
					this.risques_lies_coactivite_details_missing = false;
				}
				break;
			case 'risques_lies_coactivite_prevention' :
				if (!this.risques_lies_coactivite) {
					this.risques_lies_coactivite_prevention_missing = false;
				} else if (this.risques_lies_coactivite_prevention_nature != null ||
					this.risques_lies_coactivite_prevention_precautions != null) {
					this.risques_lies_coactivite_prevention_missing = false;
				}
				break;
			case 'risques_lies_coactivite_prevention_precautions_par' :
				if (this.risques_lies_coactivite_prevention_precautions === null || this.risques_lies_coactivite_prevention_precautions === '') {
					this.risques_lies_coactivite_prevention_precautions_par_missing = false;
				} else if (this.risques_lies_coactivite_prevention_precautions !== null &&
					(this.risques_lies_coactivite_prevention_precautions_par_ee ||
						this.risques_lies_coactivite_prevention_precautions_par_eu)) {
					this.risques_lies_coactivite_prevention_precautions_par_missing = false;
				}
				break;
			case 'risques_bacteriologiques_details' :
				if (!this.risques_bacteriologiques) {
					this.risques_bacteriologiques_details_missing = false;
				} else if (this.risques_bacteriologiques_details_zone_blanche) {
					this.risques_bacteriologiques_details_missing = false;
				}
				break;
			case 'risques_bacteriologiques_prevention' :
				if (!this.risques_bacteriologiques) {
					this.risques_bacteriologiques_prevention_missing = false;
				} else if (this.risques_bacteriologiques_prevention_regles_hygiene !== null ||
					this.risques_bacteriologiques_prevention_port_epi !== null) {
					this.risques_bacteriologiques_prevention_missing = false;
				}
				break;
			case 'risques_bacteriologiques_prevention_regles_hygiene_par' :
				if (this.risques_bacteriologiques_prevention_regles_hygiene === null || this.risques_bacteriologiques_prevention_regles_hygiene === '') {
					this.risques_bacteriologiques_prevention_regles_hygiene_par_missing = false;
				} else if (this.risques_bacteriologiques_prevention_regles_hygiene !== null &&
					(this.risques_bacteriologiques_prevention_regles_hygiene_par_ee ||
						this.risques_bacteriologiques_prevention_regles_hygiene_par_eu)) {
					this.risques_bacteriologiques_prevention_regles_hygiene_par_missing = false;
				}
				break;
			case 'risques_bacteriologiques_prevention_port_epi_par' :
				if (!this.risques_bacteriologiques_prevention_port_epi) {
					this.risques_bacteriologiques_prevention_port_epi_par_missing = false;
				} else if (this.risques_bacteriologiques_prevention_port_epi &&
					(this.risques_bacteriologiques_prevention_port_epi_par_ee ||
						this.risques_bacteriologiques_prevention_port_epi_par_eu)) {
					this.risques_bacteriologiques_prevention_port_epi_par_missing = false;
				}
				break;
			case 'autres_risques_type' :
				if (!this.autres_risques) {
					this.autres_risques_type_missing = false;
				} else if (this.autres_risques_type != null) {
					this.autres_risques_type_missing = false;
				}
				break;
			case 'autres_risques_prevention' :
				if (!this.autres_risques) {
					this.autres_risques_prevention_missing = false;
				} else if (this.autres_risques_prevention != null) {
					this.autres_risques_prevention_missing = false;
				}
				break;
			case 'autres_risques_prevention_par' :
				if (this.autres_risques_prevention === null || this.autres_risques_prevention === '') {
					this.autres_risques_prevention_par_missing = false;
				} else if (this.autres_risques_prevention !== null &&
					(this.autres_risques_prevention_par_ee ||
						this.autres_risques_prevention_par_eu)) {
					this.autres_risques_prevention_par_missing = false;
				}
				break;
			case 'document_eu_autres_description' :
				if (!this.document_eu_autres) {
					this.document_eu_autres_description_missing = false;
				} else if (this.document_eu_autres_description != null) {
					this.document_eu_autres_description_missing = false;
				}
				break;
			default :
				break;
		}
	}

	getFormData() {
		const formData = new FormData();

		if (this.id != null) {
			formData.append('id', JSON.stringify(this.id));
		}
		if (this.status != null) {
			formData.append('status', this.status);
		}

		// Step 1

		if (this.id_affaire != null) {
			formData.append('id_affaire', JSON.stringify(Number(this.id_affaire)));
		}

		// Step 2

		if (this.nom_client != null) {
			formData.append('nom_client', this.nom_client);
		}
		if (this.interlocuteur != null) {
			formData.append('interlocuteur', this.interlocuteur);
		}
		if (this.telephone != null) {
			formData.append('telephone', this.telephone);
		}
		if (this.entreprise_exterieure != null) {
			formData.append('entreprise_exterieure', this.entreprise_exterieure);
		}
		if (this.entreprise_exterieure_responsable != null) {
			formData.append('entreprise_exterieure_responsable', this.entreprise_exterieure_responsable);
		}
		if (this.entreprise_exterieure_telephone != null) {
			formData.append('entreprise_exterieure_telephone', this.entreprise_exterieure_telephone);
		}
		if (this.responsable_travaux != null && this.responsable_travaux.id != null) {
			formData.append('responsable_travaux', JSON.stringify(this.responsable_travaux.id));
		}
		if (this.nb_stemi != null) {
			formData.append('nb_stemi', JSON.stringify(Number(this.nb_stemi)));
		}
		if (this.lieu != null) {
			formData.append('lieu', this.lieu);
		}
		if (this.date_debut_travaux != null) {
			formData.append('date_debut_travaux', moment(this.date_debut_travaux).format());
		}
		if (this.date_fin_travaux != null) {
			formData.append('date_fin_travaux', moment(this.date_fin_travaux).format());
		}
		if (this.horaires_intervention != null) {
			formData.append('horaires_intervention', JSON.stringify(this.horaires_intervention));
		}
		if (this.presence_amiante != null) {
			formData.append('presence_amiante', this.presence_amiante);
		}
		if (this.localisation_amiante != null) {
			formData.append('localisation_amiante', this.localisation_amiante);
		}

		// Step 3

		if (this.epi_chaussures_securite != null) {
			formData.append('epi_chaussures_securite', JSON.stringify(Number(this.epi_chaussures_securite)));
		}
		if (this.epi_lunettes_protection != null) {
			formData.append('epi_lunettes_protection', JSON.stringify(Number(this.epi_lunettes_protection)));
		}
		if (this.epi_protection_auditive != null) {
			formData.append('epi_protection_auditive', JSON.stringify(Number(this.epi_protection_auditive)));
		}
		if (this.epi_casque_chantier != null) {
			formData.append('epi_casque_chantier', JSON.stringify(Number(this.epi_casque_chantier)));
		}
		if (this.epi_vetements_travail != null) {
			formData.append('epi_vetements_travail', JSON.stringify(Number(this.epi_vetements_travail)));
		}
		if (this.epi_gilet != null) {
			formData.append('epi_gilet', JSON.stringify(Number(this.epi_gilet)));
		}

		// Step 4

		if (this.moyens_urgence_sortie != null) {
			formData.append('moyens_urgence_sortie', JSON.stringify(Number(this.moyens_urgence_sortie)));
		}
		if (this.moyens_urgence_sortie_commentaire != null) {
			formData.append('moyens_urgence_sortie_commentaire', this.moyens_urgence_sortie_commentaire);
		}
		if (this.moyens_urgence_pharmacie != null) {
			formData.append('moyens_urgence_pharmacie', JSON.stringify(Number(this.moyens_urgence_pharmacie)));
		}
		if (this.moyens_urgence_pharmacie_commentaire != null) {
			formData.append('moyens_urgence_pharmacie_commentaire', this.moyens_urgence_pharmacie_commentaire);
		}
		if (this.moyens_urgence_telephone != null) {
			formData.append('moyens_urgence_telephone', JSON.stringify(Number(this.moyens_urgence_telephone)));
		}
		if (this.moyens_urgence_telephone_commentaire != null) {
			formData.append('moyens_urgence_telephone_commentaire', this.moyens_urgence_telephone_commentaire);
		}
		if (this.moyens_urgence_rassemblement != null) {
			formData.append('moyens_urgence_rassemblement', JSON.stringify(Number(this.moyens_urgence_rassemblement)));
		}
		if (this.moyens_urgence_rassemblement_commentaire != null) {
			formData.append('moyens_urgence_rassemblement_commentaire', this.moyens_urgence_rassemblement_commentaire);
		}
		if (this.moyens_urgence_rincage != null) {
			formData.append('moyens_urgence_rincage', JSON.stringify(Number(this.moyens_urgence_rincage)));
		}
		if (this.moyens_urgence_rincage_commentaire != null) {
			formData.append('moyens_urgence_rincage_commentaire', this.moyens_urgence_rincage_commentaire);
		}
		if (this.moyens_urgence_douche != null) {
			formData.append('moyens_urgence_douche', JSON.stringify(Number(this.moyens_urgence_douche)));
		}
		if (this.moyens_urgence_douche_commentaire != null) {
			formData.append('moyens_urgence_douche_commentaire', this.moyens_urgence_douche_commentaire);
		}
		if (this.moyens_urgence_extincteur != null) {
			formData.append('moyens_urgence_extincteur', JSON.stringify(Number(this.moyens_urgence_extincteur)));
		}
		if (this.moyens_urgence_extincteur_commentaire != null) {
			formData.append('moyens_urgence_extincteur_commentaire', this.moyens_urgence_extincteur_commentaire);
		}

		// Step 5

		if (this.prestataire != null) {
			formData.append('prestataire', JSON.stringify(Number(this.prestataire)));
		}
		if (this.prestataire_societe != null) {
			formData.append('prestataire_societe', this.prestataire_societe);
		}
		if (this.prestataire_responsable != null) {
			formData.append('prestataire_responsable', this.prestataire_responsable);
		}
		if (this.prestataire_nature_travaux != null) {
			formData.append('prestataire_nature_travaux', this.prestataire_nature_travaux);
		}
		if (this.risques_electriques != null) {
			formData.append('risques_electriques', JSON.stringify(Number(this.risques_electriques)));
		}
		if (this.risques_electriques_details_basse_tension != null) {
			formData.append('risques_electriques_details_basse_tension', JSON.stringify(Number(this.risques_electriques_details_basse_tension)));
		}
		if (this.risques_electriques_details_haute_tension != null) {
			formData.append('risques_electriques_details_haute_tension', JSON.stringify(Number(this.risques_electriques_details_haute_tension)));
		}
		if (this.risques_electriques_details_voisinage_tension != null) {
			formData.append('risques_electriques_details_voisinage_tension', JSON.stringify(Number(this.risques_electriques_details_voisinage_tension)));
		}
		if (this.risques_electriques_prevention_habilitation != null) {
			formData.append('risques_electriques_prevention_habilitation', JSON.stringify(Number(this.risques_electriques_prevention_habilitation)));
		}
		if (this.risques_electriques_prevention_habilitation_par_ee != null) {
			formData.append('risques_electriques_prevention_habilitation_par_ee', JSON.stringify(Number(this.risques_electriques_prevention_habilitation_par_ee)));
		}
		if (this.risques_electriques_prevention_habilitation_par_eu != null) {
			formData.append('risques_electriques_prevention_habilitation_par_eu', JSON.stringify(Number(this.risques_electriques_prevention_habilitation_par_eu)));
		}
		if (this.risques_electriques_prevention_consignation != null) {
			formData.append('risques_electriques_prevention_consignation', JSON.stringify(Number(this.risques_electriques_prevention_consignation)));
		}
		if (this.risques_electriques_prevention_consignation_par_ee != null) {
			formData.append('risques_electriques_prevention_consignation_par_ee', JSON.stringify(Number(this.risques_electriques_prevention_consignation_par_ee)));
		}
		if (this.risques_electriques_prevention_consignation_par_eu != null) {
			formData.append('risques_electriques_prevention_consignation_par_eu', JSON.stringify(Number(this.risques_electriques_prevention_consignation_par_eu)));
		}
		if (this.risques_electriques_prevention_voisinage != null) {
			formData.append('risques_electriques_prevention_voisinage', JSON.stringify(Number(this.risques_electriques_prevention_voisinage)));
		}
		if (this.risques_electriques_prevention_voisinage_par_ee != null) {
			formData.append('risques_electriques_prevention_voisinage_par_ee', JSON.stringify(Number(this.risques_electriques_prevention_voisinage_par_ee)));
		}
		if (this.risques_electriques_prevention_voisinage_par_eu != null) {
			formData.append('risques_electriques_prevention_voisinage_par_eu', JSON.stringify(Number(this.risques_electriques_prevention_voisinage_par_eu)));
		}
		if (this.risques_electriques_remarques != null) {
			formData.append('risques_electriques_remarques', this.risques_electriques_remarques);
		}
		if (this.risques_lies_circulation != null) {
			formData.append('risques_lies_circulation', JSON.stringify(Number(this.risques_lies_circulation)));
		}
		if (this.risques_lies_circulation_details_vehicules != null) {
			formData.append('risques_lies_circulation_details_vehicules', JSON.stringify(Number(this.risques_lies_circulation_details_vehicules)));
		}
		if (this.risques_lies_circulation_details_pietonne != null) {
			formData.append('risques_lies_circulation_details_pietonne', JSON.stringify(Number(this.risques_lies_circulation_details_pietonne)));
		}
		if (this.risques_lies_circulation_moyens_prevention_regles != null) {
			formData.append('risques_lies_circulation_moyens_prevention_regles', JSON.stringify(Number(this.risques_lies_circulation_moyens_prevention_regles)));
		}
		if (this.risques_lies_circulation_moyens_prevention_regles_par_ee != null) {
			formData.append('risques_lies_circulation_moyens_prevention_regles_par_ee', JSON.stringify(Number(this.risques_lies_circulation_moyens_prevention_regles_par_ee)));
		}
		if (this.risques_lies_circulation_moyens_prevention_regles_par_eu != null) {
			formData.append('risques_lies_circulation_moyens_prevention_regles_par_eu', JSON.stringify(Number(this.risques_lies_circulation_moyens_prevention_regles_par_eu)));
		}
		if (this.risques_lies_circulation_moyens_prevention_baliser != null) {
			formData.append('risques_lies_circulation_moyens_prevention_baliser', JSON.stringify(Number(this.risques_lies_circulation_moyens_prevention_baliser)));
		}
		if (this.risques_lies_circulation_moyens_prevention_baliser_par_ee != null) {
			formData.append('risques_lies_circulation_moyens_prevention_baliser_par_ee', JSON.stringify(Number(this.risques_lies_circulation_moyens_prevention_baliser_par_ee)));
		}
		if (this.risques_lies_circulation_moyens_prevention_baliser_par_eu != null) {
			formData.append('risques_lies_circulation_moyens_prevention_baliser_par_eu', JSON.stringify(Number(this.risques_lies_circulation_moyens_prevention_baliser_par_eu)));
		}
		if (this.risques_lies_circulation_moyens_prevention_epi != null) {
			formData.append('risques_lies_circulation_moyens_prevention_epi', JSON.stringify(Number(this.risques_lies_circulation_moyens_prevention_epi)));
		}
		if (this.risques_lies_circulation_moyens_prevention_epi_par_ee != null) {
			formData.append('risques_lies_circulation_moyens_prevention_epi_par_ee', JSON.stringify(Number(this.risques_lies_circulation_moyens_prevention_epi_par_ee)));
		}
		if (this.risques_lies_circulation_moyens_prevention_epi_par_eu != null) {
			formData.append('risques_lies_circulation_moyens_prevention_epi_par_eu', JSON.stringify(Number(this.risques_lies_circulation_moyens_prevention_epi_par_eu)));
		}
		if (this.risques_lies_circulation_remarques != null) {
			formData.append('risques_lies_circulation_remarques', this.risques_lies_circulation_remarques);
		}
		if (this.risques_chute_hauteur != null) {
			formData.append('risques_chute_hauteur', JSON.stringify(Number(this.risques_chute_hauteur)));
		}
		if (this.risques_chute_hauteur_details_nacelle != null) {
			formData.append('risques_chute_hauteur_details_nacelle', JSON.stringify(Number(this.risques_chute_hauteur_details_nacelle)));
		}
		if (this.risques_chute_hauteur_details_echafaudage != null) {
			formData.append('risques_chute_hauteur_details_echafaudage', JSON.stringify(Number(this.risques_chute_hauteur_details_echafaudage)));
		}
		if (this.risques_chute_hauteur_details_echelle != null) {
			formData.append('risques_chute_hauteur_details_echelle', JSON.stringify(Number(this.risques_chute_hauteur_details_echelle)));
		}
		if (this.risques_chute_hauteur_prevention_materiel != null) {
			formData.append('risques_chute_hauteur_prevention_materiel', JSON.stringify(Number(this.risques_chute_hauteur_prevention_materiel)));
		}
		if (this.risques_chute_hauteur_prevention_materiel_par_ee != null) {
			formData.append('risques_chute_hauteur_prevention_materiel_par_ee', JSON.stringify(Number(this.risques_chute_hauteur_prevention_materiel_par_ee)));
		}
		if (this.risques_chute_hauteur_prevention_materiel_par_eu != null) {
			formData.append('risques_chute_hauteur_prevention_materiel_par_eu', JSON.stringify(Number(this.risques_chute_hauteur_prevention_materiel_par_eu)));
		}
		if (this.risques_chute_hauteur_prevention_conduite != null) {
			formData.append('risques_chute_hauteur_prevention_conduite', JSON.stringify(Number(this.risques_chute_hauteur_prevention_conduite)));
		}
		if (this.risques_chute_hauteur_prevention_conduite_par_ee != null) {
			formData.append('risques_chute_hauteur_prevention_conduite_par_ee', JSON.stringify(Number(this.risques_chute_hauteur_prevention_conduite_par_ee)));
		}
		if (this.risques_chute_hauteur_prevention_conduite_par_eu != null) {
			formData.append('risques_chute_hauteur_prevention_conduite_par_eu', JSON.stringify(Number(this.risques_chute_hauteur_prevention_conduite_par_eu)));
		}
		if (this.risques_chute_hauteur_prevention_balisage != null) {
			formData.append('risques_chute_hauteur_prevention_balisage', JSON.stringify(Number(this.risques_chute_hauteur_prevention_balisage)));
		}
		if (this.risques_chute_hauteur_prevention_balisage_par_ee != null) {
			formData.append('risques_chute_hauteur_prevention_balisage_par_ee', JSON.stringify(Number(this.risques_chute_hauteur_prevention_balisage_par_ee)));
		}
		if (this.risques_chute_hauteur_prevention_balisage_par_eu != null) {
			formData.append('risques_chute_hauteur_prevention_balisage_par_eu', JSON.stringify(Number(this.risques_chute_hauteur_prevention_balisage_par_eu)));
		}
		if (this.risques_chute_hauteur_prevention_harnais != null) {
			formData.append('risques_chute_hauteur_prevention_harnais', JSON.stringify(Number(this.risques_chute_hauteur_prevention_harnais)));
		}
		if (this.risques_chute_hauteur_prevention_harnais_par_ee != null) {
			formData.append('risques_chute_hauteur_prevention_harnais_par_ee', JSON.stringify(Number(this.risques_chute_hauteur_prevention_harnais_par_ee)));
		}
		if (this.risques_chute_hauteur_prevention_harnais_par_eu != null) {
			formData.append('risques_chute_hauteur_prevention_harnais_par_eu', JSON.stringify(Number(this.risques_chute_hauteur_prevention_harnais_par_eu)));
		}
		if (this.risques_chute_hauteur_prevention_permis != null) {
			formData.append('risques_chute_hauteur_prevention_permis', JSON.stringify(Number(this.risques_chute_hauteur_prevention_permis)));
		}
		if (this.risques_chute_hauteur_prevention_permis_par_ee != null) {
			formData.append('risques_chute_hauteur_prevention_permis_par_ee', JSON.stringify(Number(this.risques_chute_hauteur_prevention_permis_par_ee)));
		}
		if (this.risques_chute_hauteur_prevention_permis_par_eu != null) {
			formData.append('risques_chute_hauteur_prevention_permis_par_eu', JSON.stringify(Number(this.risques_chute_hauteur_prevention_permis_par_eu)));
		}
		if (this.risques_chute_hauteur_prevention_pret_materiel != null) {
			formData.append('risques_chute_hauteur_prevention_pret_materiel', JSON.stringify(Number(this.risques_chute_hauteur_prevention_pret_materiel)));
		}
		if (this.risques_chute_hauteur_remarques != null) {
			formData.append('risques_chute_hauteur_remarques', this.risques_chute_hauteur_remarques);
		}
		if (this.risques_chute_charge_objet != null) {
			formData.append('risques_chute_charge_objet', JSON.stringify(Number(this.risques_chute_charge_objet)));
		}
		if (this.risques_chute_charge_objet_prevention_casque != null) {
			formData.append('risques_chute_charge_objet_prevention_casque', JSON.stringify(Number(this.risques_chute_charge_objet_prevention_casque)));
		}
		if (this.risques_chute_charge_objet_prevention_casque_par_ee != null) {
			formData.append('risques_chute_charge_objet_prevention_casque_par_ee', JSON.stringify(Number(this.risques_chute_charge_objet_prevention_casque_par_ee)));
		}
		if (this.risques_chute_charge_objet_prevention_casque_par_eu != null) {
			formData.append('risques_chute_charge_objet_prevention_casque_par_eu', JSON.stringify(Number(this.risques_chute_charge_objet_prevention_casque_par_eu)));
		}
		if (this.risques_chute_charge_objet_prevention_baliser != null) {
			formData.append('risques_chute_charge_objet_prevention_baliser', JSON.stringify(Number(this.risques_chute_charge_objet_prevention_baliser)));
		}
		if (this.risques_chute_charge_objet_prevention_baliser_par_ee != null) {
			formData.append('risques_chute_charge_objet_prevention_baliser_par_ee', JSON.stringify(Number(this.risques_chute_charge_objet_prevention_baliser_par_ee)));
		}
		if (this.risques_chute_charge_objet_prevention_baliser_par_eu != null) {
			formData.append('risques_chute_charge_objet_prevention_baliser_par_eu', JSON.stringify(Number(this.risques_chute_charge_objet_prevention_baliser_par_eu)));
		}
		if (this.risques_chute_charge_objet_remarques != null) {
			formData.append('risques_chute_charge_objet_remarques', this.risques_chute_charge_objet_remarques);
		}
		if (this.risques_mecaniques != null) {
			formData.append('risques_mecaniques', JSON.stringify(Number(this.risques_mecaniques)));
		}
		if (this.risques_mecaniques_details_mecanisme != null) {
			formData.append('risques_mecaniques_details_mecanisme', JSON.stringify(Number(this.risques_mecaniques_details_mecanisme)));
		}
		if (this.risques_mecaniques_details_electro != null) {
			formData.append('risques_mecaniques_details_electro', JSON.stringify(Number(this.risques_mecaniques_details_electro)));
		}
		if (this.risques_mecaniques_prevention_gants != null) {
			formData.append('risques_mecaniques_prevention_gants', JSON.stringify(Number(this.risques_mecaniques_prevention_gants)));
		}
		if (this.risques_mecaniques_prevention_gants_par_ee != null) {
			formData.append('risques_mecaniques_prevention_gants_par_ee', JSON.stringify(Number(this.risques_mecaniques_prevention_gants_par_ee)));
		}
		if (this.risques_mecaniques_prevention_gants_par_eu != null) {
			formData.append('risques_mecaniques_prevention_gants_par_eu', JSON.stringify(Number(this.risques_mecaniques_prevention_gants_par_eu)));
		}
		if (this.risques_mecaniques_prevention_etat != null) {
			formData.append('risques_mecaniques_prevention_etat', JSON.stringify(Number(this.risques_mecaniques_prevention_etat)));
		}
		if (this.risques_mecaniques_prevention_etat_par_ee != null) {
			formData.append('risques_mecaniques_prevention_etat_par_ee', JSON.stringify(Number(this.risques_mecaniques_prevention_etat_par_ee)));
		}
		if (this.risques_mecaniques_prevention_etat_par_eu != null) {
			formData.append('risques_mecaniques_prevention_etat_par_eu', JSON.stringify(Number(this.risques_mecaniques_prevention_etat_par_eu)));
		}
		if (this.risques_mecaniques_prevention_balisage != null) {
			formData.append('risques_mecaniques_prevention_balisage', JSON.stringify(Number(this.risques_mecaniques_prevention_balisage)));
		}
		if (this.risques_mecaniques_prevention_balisage_par_ee != null) {
			formData.append('risques_mecaniques_prevention_balisage_par_ee', JSON.stringify(Number(this.risques_mecaniques_prevention_balisage_par_ee)));
		}
		if (this.risques_mecaniques_prevention_balisage_par_eu != null) {
			formData.append('risques_mecaniques_prevention_balisage_par_eu', JSON.stringify(Number(this.risques_mecaniques_prevention_balisage_par_eu)));
		}
		if (this.risques_mecaniques_prevention_permis != null) {
			formData.append('risques_mecaniques_prevention_permis', JSON.stringify(Number(this.risques_mecaniques_prevention_permis)));
		}
		if (this.risques_mecaniques_prevention_permis_par_ee != null) {
			formData.append('risques_mecaniques_prevention_permis_par_ee', JSON.stringify(Number(this.risques_mecaniques_prevention_permis_par_ee)));
		}
		if (this.risques_mecaniques_prevention_permis_par_eu != null) {
			formData.append('risques_mecaniques_prevention_permis_par_eu', JSON.stringify(Number(this.risques_mecaniques_prevention_permis_par_eu)));
		}
		if (this.risques_mecaniques_remarques != null) {
			formData.append('risques_mecaniques_remarques', this.risques_mecaniques_remarques);
		}
		if (this.risques_chimiques != null) {
			formData.append('risques_chimiques', JSON.stringify(Number(this.risques_chimiques)));
		}
		if (this.risques_chimiques_details_manipulation != null) {
			formData.append('risques_chimiques_details_manipulation', JSON.stringify(Number(this.risques_chimiques_details_manipulation)));
		}
		if (this.risques_chimiques_details_voisinage != null) {
			formData.append('risques_chimiques_details_voisinage', JSON.stringify(Number(this.risques_chimiques_details_voisinage)));
		}
		if (this.risques_chimiques_prevention_epi != null) {
			formData.append('risques_chimiques_prevention_epi', JSON.stringify(Number(this.risques_chimiques_prevention_epi)));
		}
		if (this.risques_chimiques_prevention_epi_par_ee != null) {
			formData.append('risques_chimiques_prevention_epi_par_ee', JSON.stringify(Number(this.risques_chimiques_prevention_epi_par_ee)));
		}
		if (this.risques_chimiques_prevention_epi_par_eu != null) {
			formData.append('risques_chimiques_prevention_epi_par_eu', JSON.stringify(Number(this.risques_chimiques_prevention_epi_par_eu)));
		}
		if (this.risques_chimiques_prevention_produit != null) {
			formData.append('risques_chimiques_prevention_produit', JSON.stringify(Number(this.risques_chimiques_prevention_produit)));
		}
		if (this.risques_chimiques_prevention_produit_par_ee != null) {
			formData.append('risques_chimiques_prevention_produit_par_ee', JSON.stringify(Number(this.risques_chimiques_prevention_produit_par_ee)));
		}
		if (this.risques_chimiques_prevention_produit_par_eu != null) {
			formData.append('risques_chimiques_prevention_produit_par_eu', JSON.stringify(Number(this.risques_chimiques_prevention_produit_par_eu)));
		}
		if (this.risques_chimiques_remarques != null) {
			formData.append('risques_chimiques_remarques', this.risques_chimiques_remarques);
		}
		if (this.risques_incendie != null) {
			formData.append('risques_incendie', JSON.stringify(Number(this.risques_incendie)));
		}
		if (this.risques_incendie_details_soudure != null) {
			formData.append('risques_incendie_details_soudure', JSON.stringify(Number(this.risques_incendie_details_soudure)));
		}
		if (this.risques_incendie_details_produits != null) {
			formData.append('risques_incendie_details_produits', JSON.stringify(Number(this.risques_incendie_details_produits)));
		}
		if (this.risques_incendie_prevention_consignes != null) {
			formData.append('risques_incendie_prevention_consignes', JSON.stringify(Number(this.risques_incendie_prevention_consignes)));
		}
		if (this.risques_incendie_prevention_consignes_par_ee != null) {
			formData.append('risques_incendie_prevention_consignes_par_ee', JSON.stringify(Number(this.risques_incendie_prevention_consignes_par_ee)));
		}
		if (this.risques_incendie_prevention_consignes_par_eu != null) {
			formData.append('risques_incendie_prevention_consignes_par_eu', JSON.stringify(Number(this.risques_incendie_prevention_consignes_par_eu)));
		}
		if (this.risques_incendie_prevention_fumer != null) {
			formData.append('risques_incendie_prevention_fumer', JSON.stringify(Number(this.risques_incendie_prevention_fumer)));
		}
		if (this.risques_incendie_prevention_fumer_par_ee != null) {
			formData.append('risques_incendie_prevention_fumer_par_ee', JSON.stringify(Number(this.risques_incendie_prevention_fumer_par_ee)));
		}
		if (this.risques_incendie_prevention_fumer_par_eu != null) {
			formData.append('risques_incendie_prevention_fumer_par_eu', JSON.stringify(Number(this.risques_incendie_prevention_fumer_par_eu)));
		}
		if (this.risques_incendie_prevention_atex != null) {
			formData.append('risques_incendie_prevention_atex', JSON.stringify(Number(this.risques_incendie_prevention_atex)));
		}
		if (this.risques_incendie_prevention_atex_par_ee != null) {
			formData.append('risques_incendie_prevention_atex_par_ee', JSON.stringify(Number(this.risques_incendie_prevention_atex_par_ee)));
		}
		if (this.risques_incendie_prevention_atex_par_eu != null) {
			formData.append('risques_incendie_prevention_atex_par_eu', JSON.stringify(Number(this.risques_incendie_prevention_atex_par_eu)));
		}
		if (this.risques_incendie_prevention_permis != null) {
			formData.append('risques_incendie_prevention_permis', JSON.stringify(Number(this.risques_incendie_prevention_permis)));
		}
		if (this.risques_incendie_prevention_permis_par_ee != null) {
			formData.append('risques_incendie_prevention_permis_par_ee', JSON.stringify(Number(this.risques_incendie_prevention_permis_par_ee)));
		}
		if (this.risques_incendie_prevention_permis_par_eu != null) {
			formData.append('risques_incendie_prevention_permis_par_eu', JSON.stringify(Number(this.risques_incendie_prevention_permis_par_eu)));
		}
		if (this.risques_incendie_prevention_extincteurs != null) {
			formData.append('risques_incendie_prevention_extincteurs', JSON.stringify(Number(this.risques_incendie_prevention_extincteurs)));
		}
		if (this.risques_incendie_prevention_extincteurs_par_ee != null) {
			formData.append('risques_incendie_prevention_extincteurs_par_ee', JSON.stringify(Number(this.risques_incendie_prevention_extincteurs_par_ee)));
		}
		if (this.risques_incendie_prevention_extincteurs_par_eu != null) {
			formData.append('risques_incendie_prevention_extincteurs_par_eu', JSON.stringify(Number(this.risques_incendie_prevention_extincteurs_par_eu)));
		}
		if (this.risques_incendie_remarques != null) {
			formData.append('risques_incendie_remarques', this.risques_incendie_remarques);
		}
		if (this.risques_nuisances != null) {
			formData.append('risques_nuisances', JSON.stringify(Number(this.risques_nuisances)));
		}
		if (this.risques_nuisances_details_poussieres != null) {
			formData.append('risques_nuisances_details_poussieres', JSON.stringify(Number(this.risques_nuisances_details_poussieres)));
		}
		if (this.risques_nuisances_details_bruit != null) {
			formData.append('risques_nuisances_details_bruit', JSON.stringify(Number(this.risques_nuisances_details_bruit)));
		}
		if (this.risques_nuisances_prevention_auditives != null) {
			formData.append('risques_nuisances_prevention_auditives', JSON.stringify(Number(this.risques_nuisances_prevention_auditives)));
		}
		if (this.risques_nuisances_prevention_auditives_par_ee != null) {
			formData.append('risques_nuisances_prevention_auditives_par_ee', JSON.stringify(Number(this.risques_nuisances_prevention_auditives_par_ee)));
		}
		if (this.risques_nuisances_prevention_auditives_par_eu != null) {
			formData.append('risques_nuisances_prevention_auditives_par_eu', JSON.stringify(Number(this.risques_nuisances_prevention_auditives_par_eu)));
		}
		if (this.risques_nuisances_prevention_masque != null) {
			formData.append('risques_nuisances_prevention_masque', JSON.stringify(Number(this.risques_nuisances_prevention_masque)));
		}
		if (this.risques_nuisances_prevention_masque_par_ee != null) {
			formData.append('risques_nuisances_prevention_masque_par_ee', JSON.stringify(Number(this.risques_nuisances_prevention_masque_par_ee)));
		}
		if (this.risques_nuisances_prevention_masque_par_eu != null) {
			formData.append('risques_nuisances_prevention_masque_par_eu', JSON.stringify(Number(this.risques_nuisances_prevention_masque_par_eu)));
		}
		if (this.risques_nuisances_prevention_lunettes != null) {
			formData.append('risques_nuisances_prevention_lunettes', JSON.stringify(Number(this.risques_nuisances_prevention_lunettes)));
		}
		if (this.risques_nuisances_prevention_lunettes_par_ee != null) {
			formData.append('risques_nuisances_prevention_lunettes_par_ee', JSON.stringify(Number(this.risques_nuisances_prevention_lunettes_par_ee)));
		}
		if (this.risques_nuisances_prevention_lunettes_par_eu != null) {
			formData.append('risques_nuisances_prevention_lunettes_par_eu', JSON.stringify(Number(this.risques_nuisances_prevention_lunettes_par_eu)));
		}
		if (this.risques_nuisances_remarques != null) {
			formData.append('risques_nuisances_remarques', this.risques_nuisances_remarques);
		}
		if (this.risques_lies_coactivite != null) {
			formData.append('risques_lies_coactivite', JSON.stringify(Number(this.risques_lies_coactivite)));
		}
		if (this.risques_lies_coactivite_details_entreprises != null) {
			formData.append('risques_lies_coactivite_details_entreprises', JSON.stringify(Number(this.risques_lies_coactivite_details_entreprises)));
		}
		if (this.risques_lies_coactivite_prevention_nature != null) {
			formData.append('risques_lies_coactivite_prevention_nature', this.risques_lies_coactivite_prevention_nature);
		}
		if (this.risques_lies_coactivite_prevention_precautions != null) {
			formData.append('risques_lies_coactivite_prevention_precautions', this.risques_lies_coactivite_prevention_precautions);
		}
		if (this.risques_lies_coactivite_prevention_precautions_par_ee != null) {
			formData.append('risques_lies_coactivite_prevention_precautions_par_ee', JSON.stringify(Number(this.risques_lies_coactivite_prevention_precautions_par_ee)));
		}
		if (this.risques_lies_coactivite_prevention_precautions_par_eu != null) {
			formData.append('risques_lies_coactivite_prevention_precautions_par_eu', JSON.stringify(Number(this.risques_lies_coactivite_prevention_precautions_par_eu)));
		}
		if (this.risques_lies_coactivite_remarques != null) {
			formData.append('risques_lies_coactivite_remarques', this.risques_lies_coactivite_remarques);
		}
		if (this.risques_bacteriologiques != null) {
			formData.append('risques_bacteriologiques', JSON.stringify(Number(this.risques_bacteriologiques)));
		}
		if (this.risques_bacteriologiques_details_zone_blanche != null) {
			formData.append('risques_bacteriologiques_details_zone_blanche', JSON.stringify(Number(this.risques_bacteriologiques_details_zone_blanche)));
		}
		if (this.risques_bacteriologiques_prevention_regles_hygiene != null) {
			formData.append('risques_bacteriologiques_prevention_regles_hygiene', this.risques_bacteriologiques_prevention_regles_hygiene);
		}
		if (this.risques_bacteriologiques_prevention_regles_hygiene_par_ee != null) {
			formData.append('risques_bacteriologiques_prevention_regles_hygiene_par_ee', JSON.stringify(Number(this.risques_bacteriologiques_prevention_regles_hygiene_par_ee)));
		}
		if (this.risques_bacteriologiques_prevention_regles_hygiene_par_eu != null) {
			formData.append('risques_bacteriologiques_prevention_regles_hygiene_par_eu', JSON.stringify(Number(this.risques_bacteriologiques_prevention_regles_hygiene_par_eu)));
		}
		if (this.risques_bacteriologiques_prevention_port_epi != null) {
			formData.append('risques_bacteriologiques_prevention_port_epi', JSON.stringify(Number(this.risques_bacteriologiques_prevention_port_epi)));
		}
		if (this.risques_bacteriologiques_prevention_port_epi_par_ee != null) {
			formData.append('risques_bacteriologiques_prevention_port_epi_par_ee', JSON.stringify(Number(this.risques_bacteriologiques_prevention_port_epi_par_ee)));
		}
		if (this.risques_bacteriologiques_prevention_port_epi_par_eu != null) {
			formData.append('risques_bacteriologiques_prevention_port_epi_par_eu', JSON.stringify(Number(this.risques_bacteriologiques_prevention_port_epi_par_eu)));
		}
		if (this.risques_bacteriologiques_remarques != null) {
			formData.append('risques_bacteriologiques_remarques', this.risques_bacteriologiques_remarques);
		}
		if (this.autres_risques != null) {
			formData.append('autres_risques', JSON.stringify(Number(this.autres_risques)));
		}
		if (this.autres_risques_type != null) {
			formData.append('autres_risques_type', this.autres_risques_type);
		}
		if (this.autres_risques_prevention != null) {
			formData.append('autres_risques_prevention', this.autres_risques_prevention);
		}
		if (this.autres_risques_prevention_par_ee != null) {
			formData.append('autres_risques_prevention_par_ee', JSON.stringify(Number(this.autres_risques_prevention_par_ee)));
		}
		if (this.autres_risques_prevention_par_eu != null) {
			formData.append('autres_risques_prevention_par_eu', JSON.stringify(Number(this.autres_risques_prevention_par_eu)));
		}
		if (this.autres_risques_remarques != null) {
			formData.append('autres_risques_remarques', this.autres_risques_remarques);
		}
		if (this.document_eu_plan_site != null) {
			formData.append('document_eu_plan_site', JSON.stringify(Number(this.document_eu_plan_site)));
		}
		if (this.document_eu_autres != null) {
			formData.append('document_eu_autres', JSON.stringify(Number(this.document_eu_autres)));
		}
		if (this.document_eu_autres_description != null) {
			formData.append('document_eu_autres_description', this.document_eu_autres_description);
		}

		// Step 6

		if (this.date_signature != null) {
			formData.append('date_signature', moment(this.date_signature).format());
		}
		if (this.client_nom_signature != null) {
			formData.append('client_nom_signature', this.client_nom_signature);
		}
		if (this.client_date_signature != null) {
			formData.append('client_date_signature', moment(this.client_date_signature).format());
		}

		if (this.client_signature != null && this.client_signature.type === 'local') {
			formData.append('client_signature', FileService.dataURItoBlob(this.client_signature.preview), this.client_signature.fileName);
		}

		return formData;
	}
}
