import * as moment from 'moment';

export class AffaireObject {
	id: number;
	table = 'affaire';
	createdAt: string;
	updatedAt: string;
	nom: string;
	nom_client: string;
	code_client: string;
	responsable: string;
	contact: string;
	charge_affaire: string;
	no_devis: string;
	no_commande: string;
	interlocuteur: string;
	date_commande: string;
	montant_commande: number;
	status: string;

	etude_electrique_schema_existant: string;
	etude_electrique_cahier_charges: string;
	etude_electrique_specifications: string;
	etude_electrique_logiciel: string;
	etude_electrique_pre_approvisionnement: string;
	etude_electrique_note_calcul: string;
	etude_electrique_regime_neutre: string;
	etude_electrique_icc: string;
	etude_electrique_cables: string;
	etude_electrique_cables_autre: string;
	etude_electrique_poignees: string;
	etude_electrique_poignees_autre: string;
	etude_electrique_options: Array<string>;
	etude_electrique_materiel: string;
	etude_electrique_materiel_autre: string;
	etude_electrique_documents: string;

	constructor(id = null, createdAt = null, updatedAt = null, nom = null, nom_client = null, code_client = null, responsable = null, contact = null, charge_affaire = null,
				no_devis = null, no_commande = null, interlocuteur = null, date_commande = null, montant_commande = null, status = null,
				etude_electrique_schema_existant = null, etude_electrique_cahier_charges = null, etude_electrique_specifications = null, etude_electrique_logiciel = null, etude_electrique_pre_approvisionnement = null, etude_electrique_note_calcul = null,
				etude_electrique_regime_neutre = null, etude_electrique_icc = null, etude_electrique_cables = null, etude_electrique_cables_autre = null, etude_electrique_poignees = null, etude_electrique_poignees_autre = null, etude_electrique_options = [],
				etude_electrique_materiel = null, etude_electrique_materiel_autre = null, etude_electrique_documents = null) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.nom = nom;
		this.nom_client = nom_client;
		this.code_client = code_client;
		this.responsable = responsable;
		this.contact = contact;
		this.charge_affaire = charge_affaire;
		this.no_devis = no_devis;
		this.no_commande = no_commande;
		this.interlocuteur = interlocuteur;
		this.date_commande = date_commande;
		this.montant_commande = montant_commande;
		this.status = status;

		this.etude_electrique_schema_existant = etude_electrique_schema_existant;
		this.etude_electrique_cahier_charges = etude_electrique_cahier_charges;
		this.etude_electrique_specifications = etude_electrique_specifications;
		this.etude_electrique_logiciel = etude_electrique_logiciel;
		this.etude_electrique_pre_approvisionnement = etude_electrique_pre_approvisionnement;
		this.etude_electrique_note_calcul = etude_electrique_note_calcul;
		this.etude_electrique_regime_neutre = etude_electrique_regime_neutre;
		this.etude_electrique_icc = etude_electrique_icc;
		this.etude_electrique_cables = etude_electrique_cables;
		this.etude_electrique_cables_autre = etude_electrique_cables_autre;
		this.etude_electrique_poignees = etude_electrique_poignees;
		this.etude_electrique_poignees_autre = etude_electrique_poignees_autre;
		this.etude_electrique_options = etude_electrique_options;
		this.etude_electrique_materiel = etude_electrique_materiel;
		this.etude_electrique_materiel_autre = etude_electrique_materiel_autre;
		this.etude_electrique_documents = etude_electrique_documents;
	}

	public static LoadFromJSON(json: any) {
		return new AffaireObject(
			json['id'],
			json['createdAt'],
			json['updatedAt'],
			json['nom'],
			json['nom_client'],
			json['code_client'],
			json['responsable'],
			json['contact'],
			json['charge_affaire'],
			json['no_devis'],
			json['no_commande'],
			json['interlocuteur'],
			json['date_commande'],
			json['montant_commande'],
			json['status'],
			json['etude_electrique_schema_existant'],
			json['etude_electrique_cahier_charges'],
			json['etude_electrique_specifications'],
			json['etude_electrique_logiciel'],
			json['etude_electrique_pre_approvisionnement'],
			json['etude_electrique_note_calcul'],
			json['etude_electrique_regime_neutre'],
			json['etude_electrique_icc'],
			json['etude_electrique_cables'],
			json['etude_electrique_cables_autre'],
			json['etude_electrique_poignees'],
			json['etude_electrique_poignees_autre'],
			json['etude_electrique_options'],
			json['etude_electrique_materiel'],
			json['etude_electrique_materiel_autre'],
			json['etude_electrique_documents']
		);
	}

	clearRelated(source) {
		switch (source) {
			case 'etude_electrique_cables':
				if (this.etude_electrique_cables !== 'Autre') {
					this.etude_electrique_cables_autre = null;
				}
				break;
			case 'etude_electrique_poignees':
				if (this.etude_electrique_poignees !== 'Autre') {
					this.etude_electrique_poignees_autre = null;
				}
				break;
			case 'etude_electrique_materiel':
				if (this.etude_electrique_materiel !== 'Autre') {
					this.etude_electrique_materiel_autre = null;
				}
				break;
			default :
				break;
		}
	}

	getFormData() {
		return {
			nom: this.nom,
			nom_client: this.nom_client,
			code_client: this.code_client,
			responsable: this.responsable,
			contact: this.contact,
			charge_affaire: this.charge_affaire,
			no_devis: this.no_devis,
			no_commande: this.no_commande,
			interlocuteur: this.interlocuteur,
			date_commande: this.date_commande ? moment(this.date_commande).format('YYYY-MM-DD') : null,
			montant_commande: this.montant_commande,
			etude_electrique_schema_existant: this.etude_electrique_schema_existant,
			etude_electrique_cahier_charges: this.etude_electrique_cahier_charges,
			etude_electrique_specifications: this.etude_electrique_specifications,
			etude_electrique_logiciel: this.etude_electrique_logiciel,
			etude_electrique_pre_approvisionnement: this.etude_electrique_pre_approvisionnement,
			etude_electrique_note_calcul: this.etude_electrique_note_calcul,
			etude_electrique_regime_neutre: this.etude_electrique_regime_neutre,
			etude_electrique_icc: this.etude_electrique_icc,
			etude_electrique_cables: this.etude_electrique_cables,
			etude_electrique_cables_autre: this.etude_electrique_cables_autre,
			etude_electrique_poignees: this.etude_electrique_poignees,
			etude_electrique_poignees_autre: this.etude_electrique_poignees_autre,
			etude_electrique_options: this.etude_electrique_options,
			etude_electrique_materiel: this.etude_electrique_materiel,
			etude_electrique_materiel_autre: this.etude_electrique_materiel_autre,
			etude_electrique_documents: this.etude_electrique_documents
		};
	}
}
