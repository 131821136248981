import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Platform, PopoverController} from '@ionic/angular';

import {UserPopoverComponent} from '../../popovers/user-popover/user-popover.component';

import {UserObject} from '../../objects/user.object';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit {

	@Input() userConnected: UserObject;

	platformIsDesktop: boolean;

	constructor(private popoverCtrl: PopoverController, private platform: Platform) {
		this.platformIsDesktop = (this.platform.is('desktop') && !this.platform.is('phablet')) || (this.platform.is('tablet') && this.platform.is('mobileweb')) || (this.platform.is('mobile') && this.platform.is('mobileweb'));
	}

	ngOnInit() {
	}

	async openUserPopover(event) {
		const popover = await this.popoverCtrl.create({
			component: UserPopoverComponent,
			componentProps: {userConnected: this.userConnected},
			event: event,
			cssClass: 'user-popover'
		});
		await popover.present();
	}
}
