import {UserObject} from '../../user.object';

import * as moment from 'moment';

export class DemandeTravauxObject {

	id: number;
	table = 'demande_travaux';
	titre = 'Demande de travaux';
	createdAt: string;
	updatedAt: string;
	status: string;
	createdBy: UserObject;
	updatedBy: UserObject;

	// Step 1

	id_affaire: number;

	// Step 2

	nom_client: string;
	code_client: string;
	contact: string;
	charge_affaire: string;
	nom_affaire: string;
	adresse_chantier: string;
	no_devis: string;
	no_commande: string;
	numero_affaire: string;
	id_assistant_charge_affaire: UserObject;

	// Step 2 missing

	adresse_chantier_missing: boolean;

	// Step 3

	infos_importantes: string;
	etude_electrique: string;
	id_resp_elec: UserObject;
	etude_electrique_temps: string;
	etude_electrique_specificites: string;
	etude_electrique_description: string;
	etude_electrique_diffusion: string;
	etude_electrique_date_prevue: string;
	etude_electrique_commentaires: string;
	etude_automatisme: string;
	id_resp_auto: UserObject;
	etude_automatisme_technicien: string;
	etude_automatisme_specificites: string;
	etude_automatisme_automate: string;
	etude_automatisme_ihm: string;
	etude_automatisme_supervision: string;
	etude_automatisme_variateur: string;
	etude_automatisme_analyse: string;
	etude_automatisme_manuel: string;
	etude_automatisme_cahier: string;
	etude_automatisme_temps_developpement: string;
	etude_automatisme_temps_essai: string;
	etude_automatisme_temps_service: string;
	etude_automatisme_date_essai: string;
	etude_automatisme_date_service: string;
	etude_automatisme_commentaires: string;
	atelier: string;
	id_chef_atelier: UserObject;
	atelier_essai: string;
	atelier_date_livraison: string;
	atelier_adresse_livraison: string;
	atelier_mode_livraison: string;
	atelier_contrainte_livraison: string;
	atelier_specifications: string;
	atelier_temps_cablage: string;
	atelier_commentaires: string;
	chantier: string;
	chantier_chef: UserObject;
	chantier_specificites: string;
	chantier_date_demarrage: string;
	chantier_date_fin: string;
	chantier_temps_estime: string;
	chantier_commentaires: string;
	ids_destinataires: Array<UserObject>;
	analyse_necessaire: string;

	// Step 3 missing

	etude_electrique_missing: boolean;
	etude_automatisme_missing: boolean;
	atelier_missing: boolean;
	chantier_missing: boolean;
	id_resp_elec_missing: boolean;
	id_resp_auto_missing: boolean;
	id_chef_atelier_missing: boolean;
	chantier_chef_missing: boolean;
	analyse_necessaire_missing: boolean;

	// Status

	// draft
	// signed

	constructor(
		id = null,
		createdAt = null,
		updatedAt = null,
		status = 'draft',
		createdBy = null,
		updatedBy = null,
		// Step 1

		id_affaire = null,
		// Step 2

		nom_client = null,
		code_client = null,
		contact = null,
		charge_affaire = null,
		nom_affaire = null,
		adresse_chantier = null,
		no_devis = null,
		no_commande = null,
		numero_affaire = null,
		id_assistant_charge_affaire = null,
		// Step 3

		infos_importantes = null,
		etude_electrique = null,
		id_resp_elec = null,
		etude_electrique_temps = null,
		etude_electrique_specificites = null,
		etude_electrique_description = null,
		etude_electrique_diffusion = null,
		etude_electrique_date_prevue = null,
		etude_electrique_commentaires = null,
		etude_automatisme = null,
		id_resp_auto = null,
		etude_automatisme_technicien = null,
		etude_automatisme_specificites = null,
		etude_automatisme_automate = null,
		etude_automatisme_ihm = null,
		etude_automatisme_supervision = null,
		etude_automatisme_variateur = null,
		etude_automatisme_analyse = null,
		etude_automatisme_manuel = null,
		etude_automatisme_cahier = null,
		etude_automatisme_temps_developpement = null,
		etude_automatisme_temps_essai = null,
		etude_automatisme_temps_service = null,
		etude_automatisme_date_essai = null,
		etude_automatisme_date_service = null,
		etude_automatisme_commentaires = null,
		atelier = null,
		id_chef_atelier = null,
		atelier_essai = null,
		atelier_date_livraison = null,
		atelier_adresse_livraison = null,
		atelier_mode_livraison = null,
		atelier_contrainte_livraison = null,
		atelier_specifications = null,
		atelier_temps_cablage = null,
		atelier_commentaires = null,
		chantier = null,
		chantier_chef = null,
		chantier_specificites = null,
		chantier_date_demarrage = null,
		chantier_date_fin = null,
		chantier_temps_estime = null,
		chantier_commentaires = null,
		ids_destinataires = [],
		analyse_necessaire = null
	) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;

		// Step 1

		this.id_affaire = id_affaire;

		// Step 2

		this.nom_client = nom_client;
		this.code_client = code_client;
		this.contact = contact;
		this.charge_affaire = charge_affaire;
		this.nom_affaire = nom_affaire;
		this.adresse_chantier = adresse_chantier;
		this.no_devis = no_devis;
		this.no_commande = no_commande;
		this.numero_affaire = numero_affaire;
		this.id_assistant_charge_affaire = id_assistant_charge_affaire;

		// Step 2 missing

		this.adresse_chantier_missing = false;

		// Step 3

		this.infos_importantes = infos_importantes;
		this.etude_electrique = etude_electrique;
		this.id_resp_elec = id_resp_elec;
		this.etude_electrique_temps = etude_electrique_temps;
		this.etude_electrique_specificites = etude_electrique_specificites;
		this.etude_electrique_description = etude_electrique_description;
		this.etude_electrique_diffusion = etude_electrique_diffusion;
		this.etude_electrique_date_prevue = etude_electrique_date_prevue;
		this.etude_electrique_commentaires = etude_electrique_commentaires;
		this.etude_automatisme = etude_automatisme;
		this.id_resp_auto = id_resp_auto;
		this.etude_automatisme_technicien = etude_automatisme_technicien;
		this.etude_automatisme_specificites = etude_automatisme_specificites;
		this.etude_automatisme_automate = etude_automatisme_automate;
		this.etude_automatisme_ihm = etude_automatisme_ihm;
		this.etude_automatisme_supervision = etude_automatisme_supervision;
		this.etude_automatisme_variateur = etude_automatisme_variateur;
		this.etude_automatisme_analyse = etude_automatisme_analyse;
		this.etude_automatisme_manuel = etude_automatisme_manuel;
		this.etude_automatisme_cahier = etude_automatisme_cahier;
		this.etude_automatisme_temps_developpement = etude_automatisme_temps_developpement;
		this.etude_automatisme_temps_essai = etude_automatisme_temps_essai;
		this.etude_automatisme_temps_service = etude_automatisme_temps_service;
		this.etude_automatisme_date_essai = etude_automatisme_date_essai;
		this.etude_automatisme_date_service = etude_automatisme_date_service;
		this.etude_automatisme_commentaires = etude_automatisme_commentaires;
		this.atelier = atelier;
		this.id_chef_atelier = id_chef_atelier;
		this.atelier_essai = atelier_essai;
		this.atelier_date_livraison = atelier_date_livraison;
		this.atelier_adresse_livraison = atelier_adresse_livraison;
		this.atelier_mode_livraison = atelier_mode_livraison;
		this.atelier_contrainte_livraison = atelier_contrainte_livraison;
		this.atelier_specifications = atelier_specifications;
		this.atelier_temps_cablage = atelier_temps_cablage;
		this.atelier_commentaires = atelier_commentaires;
		this.chantier = chantier;
		this.chantier_chef = chantier_chef;
		this.chantier_specificites = chantier_specificites;
		this.chantier_date_demarrage = chantier_date_demarrage;
		this.chantier_date_fin = chantier_date_fin;
		this.chantier_temps_estime = chantier_temps_estime;
		this.chantier_commentaires = chantier_commentaires;
		this.ids_destinataires = ids_destinataires;
		this.analyse_necessaire = analyse_necessaire;

		// Step 3 missing

		this.etude_electrique_missing = false;
		this.etude_automatisme_missing = false;
		this.atelier_missing = false;
		this.chantier_missing = false;
		this.id_resp_elec_missing = false;
		this.id_resp_auto_missing = false;
		this.id_chef_atelier_missing = false;
		this.chantier_chef_missing = false;
		this.analyse_necessaire_missing = false;
	}

	clearRelated(source) {
		switch (source) {
			case 'etude_electrique' :
				if (this.etude_electrique === 'Non') {
					this.id_resp_elec = null;
					this.etude_electrique_temps = null;
					this.etude_electrique_specificites = null;
					this.etude_electrique_description = null;
					this.etude_electrique_diffusion = null;
					this.etude_electrique_date_prevue = null;
					this.etude_electrique_commentaires = null;
				}
				break;
			case 'etude_automatisme' :
				if (this.etude_automatisme === 'Non') {
					this.id_resp_auto = null;
					this.etude_automatisme_technicien = null;
					this.etude_automatisme_specificites = null;
					this.etude_automatisme_automate = null;
					this.etude_automatisme_ihm = null;
					this.etude_automatisme_supervision = null;
					this.etude_automatisme_variateur = null;
					this.etude_automatisme_analyse = null;
					this.etude_automatisme_manuel = null;
					this.etude_automatisme_cahier = null;
					this.etude_automatisme_temps_developpement = null;
					this.etude_automatisme_temps_essai = null;
					this.etude_automatisme_temps_service = null;
					this.etude_automatisme_date_essai = null;
					this.etude_automatisme_date_service = null;
					this.etude_automatisme_commentaires = null;
				}
				break;
			case 'atelier' :
				if (this.atelier === 'Non') {
					this.id_chef_atelier = null;
					this.atelier_essai = null;
					this.atelier_date_livraison = null;
					this.atelier_adresse_livraison = null;
					this.atelier_mode_livraison = null;
					this.atelier_contrainte_livraison = null;
					this.atelier_specifications = null;
					this.atelier_temps_cablage = null;
					this.atelier_commentaires = null;
				}
				break;
			case 'chantier' :
				if (this.chantier === 'Non') {
					this.chantier_chef = null;
					this.chantier_specificites = null;
					this.chantier_date_demarrage = null;
					this.chantier_date_fin = null;
					this.chantier_temps_estime = null;
					this.chantier_commentaires = null;
				}
				break;
			default :
				break;
		}
	}

	missingMandatoryFields(step) {
		let missingMandatory = false;
		const missingDescriptions = [];
		switch (step) {
			case 2 :
				if (this.adresse_chantier == null || this.adresse_chantier === '') {
					this.adresse_chantier_missing = true;
					missingMandatory = true;
				}
				break;
			case 3 :
				if (this.etude_electrique == null) {
					this.etude_electrique_missing = true;
					missingMandatory = true;
				}
				if (this.etude_electrique === 'Oui' && this.id_resp_elec == null) {
					this.id_resp_elec_missing = true;
					missingMandatory = true;
				}
				if (this.etude_automatisme == null) {
					this.etude_automatisme_missing = true;
					missingMandatory = true;
				}
				if (this.etude_automatisme === 'Oui' && this.id_resp_auto == null) {
					this.id_resp_auto_missing = true;
					missingMandatory = true;
				}
				if (this.atelier == null) {
					this.atelier_missing = true;
					missingMandatory = true;
				}
				if (this.atelier === 'Oui' && this.id_chef_atelier == null) {
					this.id_chef_atelier_missing = true;
					missingMandatory = true;
				}
				if (this.chantier == null) {
					this.chantier_missing = true;
					missingMandatory = true;
				}
				if (this.chantier === 'Oui' && this.chantier_chef == null) {
					this.chantier_chef_missing = true;
					missingMandatory = true;
				}
				if (this.chantier === 'Oui' && this.chantier_date_demarrage != null && this.chantier_date_fin != null && moment(this.chantier_date_demarrage).format('YYYY-MM-DD') > moment(this.chantier_date_fin).format('YYYY-MM-DD')) {
					missingMandatory = true;
					missingDescriptions.push('La date de fin de chantier doit être supérieure ou égale à la date de début de chantier.');
				}
				if (this.analyse_necessaire == null) {
					this.analyse_necessaire_missing = true;
					missingMandatory = true;
				}
				break;
			default :
				break;
		}
		return {missingMandatory: missingMandatory, missingDescriptions: missingDescriptions};
	}

	updateMissing(source) {
		switch (source) {
			case 'adresse_chantier' :
				if (this.adresse_chantier != null && this.adresse_chantier !== '') {
					this.adresse_chantier_missing = false;
				}
				break;
			case 'etude_electrique' :
				if (this.etude_electrique != null) {
					this.etude_electrique_missing = false;
					if (this.etude_electrique === 'Non') {
						this.id_resp_elec_missing = false;
					}
				}
				break;
			case 'id_resp_elec' :
				if (this.id_resp_elec != null) {
					this.id_resp_elec_missing = false;
				}
				break;
			case 'etude_automatisme' :
				if (this.etude_automatisme != null) {
					this.etude_automatisme_missing = false;
					if (this.etude_automatisme === 'Non') {
						this.id_resp_auto_missing = false;
					}
				}
				break;
			case 'id_resp_auto' :
				if (this.id_resp_auto != null) {
					this.id_resp_auto_missing = false;
				}
				break;
			case 'atelier' :
				if (this.atelier != null) {
					this.atelier_missing = false;
					if (this.atelier === 'Non') {
						this.id_chef_atelier_missing = false;
					}
				}
				break;
			case 'id_chef_atelier' :
				if (this.id_chef_atelier != null) {
					this.id_chef_atelier_missing = false;
				}
				break;
			case 'chantier' :
				if (this.chantier != null) {
					this.chantier_missing = false;
					if (this.chantier === 'Non') {
						this.chantier_chef_missing = false;
					}
				}
				break;
			case 'chantier_chef' :
				if (this.chantier_chef != null) {
					this.chantier_chef_missing = false;
				}
				break;
			case 'analyse_necessaire' :
				if (this.analyse_necessaire != null) {
					this.analyse_necessaire_missing = false;
				}
				break;
			default :
				break;
		}
	}

	getFormData() {
		const formData = new FormData();
		if (this.id != null) {
			formData.append('id', JSON.stringify(this.id));
		}
		if (this.status != null) {
			formData.append('status', this.status);
		}

		// Step 1

		if (this.id_affaire != null) {
			formData.append('id_affaire', JSON.stringify(Number(this.id_affaire)));
		}

		// Step 2

		if (this.no_devis != null) {
			formData.append('no_devis', this.no_devis);
		}
		if (this.numero_affaire != null) {
			formData.append('numero_affaire', this.numero_affaire);
		}
		if (this.adresse_chantier != null) {
			formData.append('adresse_chantier', this.adresse_chantier);
		}
		if (this.id_assistant_charge_affaire != null) {
			formData.append('id_assistant_charge_affaire', JSON.stringify(this.id_assistant_charge_affaire.id));
		}

		// Step 3

		if (this.infos_importantes != null) {
			formData.append('infos_importantes', this.infos_importantes);
		}

		if (this.etude_electrique != null) {
			formData.append('etude_electrique', this.etude_electrique);
		}
		if (this.id_resp_elec != null) {
			formData.append('id_resp_elec', JSON.stringify(this.id_resp_elec.id));
		}
		if (this.etude_electrique_temps != null) {
			formData.append('etude_electrique_temps', this.etude_electrique_temps);
		}
		if (this.etude_electrique_specificites != null) {
			formData.append('etude_electrique_specificites', this.etude_electrique_specificites);
		}
		if (this.etude_electrique_description != null) {
			formData.append('etude_electrique_description', this.etude_electrique_description);
		}
		if (this.etude_electrique_diffusion != null) {
			formData.append('etude_electrique_diffusion', this.etude_electrique_diffusion);
		}
		if (this.etude_electrique_date_prevue != null) {
			formData.append('etude_electrique_date_prevue', moment(this.etude_electrique_date_prevue).format());
		}
		if (this.etude_electrique_commentaires != null) {
			formData.append('etude_electrique_commentaires', this.etude_electrique_commentaires);
		}
		if (this.etude_automatisme != null) {
			formData.append('etude_automatisme', this.etude_automatisme);
		}
		if (this.id_resp_auto != null) {
			formData.append('id_resp_auto', JSON.stringify(this.id_resp_auto.id));
		}
		if (this.etude_automatisme_technicien != null) {
			formData.append('etude_automatisme_technicien', this.etude_automatisme_technicien);
		}
		if (this.etude_automatisme_specificites != null) {
			formData.append('etude_automatisme_specificites', this.etude_automatisme_specificites);
		}
		if (this.etude_automatisme_automate != null) {
			formData.append('etude_automatisme_automate', this.etude_automatisme_automate);
		}
		if (this.etude_automatisme_ihm != null) {
			formData.append('etude_automatisme_ihm', this.etude_automatisme_ihm);
		}
		if (this.etude_automatisme_supervision != null) {
			formData.append('etude_automatisme_supervision', this.etude_automatisme_supervision);
		}
		if (this.etude_automatisme_variateur != null) {
			formData.append('etude_automatisme_variateur', this.etude_automatisme_variateur);
		}
		if (this.etude_automatisme_analyse != null) {
			formData.append('etude_automatisme_analyse', this.etude_automatisme_analyse);
		}
		if (this.etude_automatisme_manuel != null) {
			formData.append('etude_automatisme_manuel', this.etude_automatisme_manuel);
		}
		if (this.etude_automatisme_cahier != null) {
			formData.append('etude_automatisme_cahier', this.etude_automatisme_cahier);
		}
		if (this.etude_automatisme_temps_developpement != null) {
			formData.append('etude_automatisme_temps_developpement', this.etude_automatisme_temps_developpement);
		}
		if (this.etude_automatisme_temps_essai != null) {
			formData.append('etude_automatisme_temps_essai', this.etude_automatisme_temps_essai);
		}
		if (this.etude_automatisme_temps_service != null) {
			formData.append('etude_automatisme_temps_service', this.etude_automatisme_temps_service);
		}
		if (this.etude_automatisme_date_essai != null) {
			formData.append('etude_automatisme_date_essai', moment(this.etude_automatisme_date_essai).format());
		}
		if (this.etude_automatisme_date_service != null) {
			formData.append('etude_automatisme_date_service', moment(this.etude_automatisme_date_service).format());
		}
		if (this.etude_automatisme_commentaires != null) {
			formData.append('etude_automatisme_commentaires', this.etude_automatisme_commentaires);
		}
		if (this.atelier != null) {
			formData.append('atelier', this.atelier);
		}
		if (this.id_chef_atelier != null) {
			formData.append('id_chef_atelier', JSON.stringify(this.id_chef_atelier.id));
		}
		if (this.atelier_essai != null) {
			formData.append('atelier_essai', this.atelier_essai);
		}
		if (this.atelier_date_livraison != null) {
			formData.append('atelier_date_livraison', moment(this.atelier_date_livraison).format());
		}
		if (this.atelier_adresse_livraison != null) {
			formData.append('atelier_adresse_livraison', this.atelier_adresse_livraison);
		}
		if (this.atelier_mode_livraison != null) {
			formData.append('atelier_mode_livraison', this.atelier_mode_livraison);
		}
		if (this.atelier_contrainte_livraison != null) {
			formData.append('atelier_contrainte_livraison', this.atelier_contrainte_livraison);
		}
		if (this.atelier_specifications != null) {
			formData.append('atelier_specifications', this.atelier_specifications);
		}
		if (this.atelier_temps_cablage != null) {
			formData.append('atelier_temps_cablage', this.atelier_temps_cablage);
		}
		if (this.atelier_commentaires != null) {
			formData.append('atelier_commentaires', this.atelier_commentaires);
		}
		if (this.chantier != null) {
			formData.append('chantier', this.chantier);
		}
		if (this.chantier_chef != null) {
			formData.append('chantier_chef', JSON.stringify(this.chantier_chef.id));
		}
		if (this.chantier_specificites != null) {
			formData.append('chantier_specificites', this.chantier_specificites);
		}
		if (this.chantier_date_demarrage != null) {
			formData.append('chantier_date_demarrage', moment(this.chantier_date_demarrage).format());
		}
		if (this.chantier_date_fin != null) {
			formData.append('chantier_date_fin', moment(this.chantier_date_fin).format());
		}
		if (this.chantier_temps_estime != null) {
			formData.append('chantier_temps_estime', this.chantier_temps_estime);
		}
		if (this.chantier_commentaires != null) {
			formData.append('chantier_commentaires', this.chantier_commentaires);
		}

		for (const i in this.ids_destinataires) {
			if (this.ids_destinataires[i] != null && this.ids_destinataires[i].id != null) {
				formData.append('ids_destinataires[]', JSON.stringify(this.ids_destinataires[i].id));
			}
		}

		if (this.analyse_necessaire != null) {
			formData.append('analyse_necessaire', this.analyse_necessaire);
		}

		return formData;
	}

	destinatairesList(destinataires: Array<UserObject>) {
		if (destinataires != null && destinataires.length > 0) {
			let list = '';
			for (const i in destinataires) {
				list += destinataires[i].complete_name + '\n';
			}
			list = list.substr(0, list.length - 1);
			return list;
		} else {
			return 'Choisir';
		}
	}
}
