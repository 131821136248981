import * as moment from 'moment';

import {FeuilleHeuresItemObject} from './feuille-heures-item.object';
import {PrimeObject} from '../parametres/prime.object';

export class FeuilleHeuresJourObject {
	id: number;
	table = 'feuille_heure_jour';
	titre = 'fh jour';
	createdAt: string;
	updatedAt: string;
	semaine_id: number;
	date: string;
	panier: boolean;
	prime_panier: PrimeObject;
	montant_panier: number;
	mobilite: boolean;
	prime_mobilite: PrimeObject;
	montant_mobilite: number;
	nuite: boolean;
	prime_nuite: PrimeObject;
	frais_deplacement: string;
	prime_deplacement: PrimeObject;
	montant_frais_reels: number;
	remarques: string;
	rtt_paye: boolean;
	regularisation_hs_majorees: number;
	regularisation_hs_non_majorees: number;
	reliquat: number;
	items: Array<FeuilleHeuresItemObject>;

	constructor(id = null, createdAt = null, updatedAt = null, semaine_id = null, date = null, panier = null, prime_panier = null, montant_panier = null, mobilite = null, prime_mobilite = null, montant_mobilite = null,
				nuite = null, prime_nuite = null, frais_deplacement = null, prime_deplacement = null, montant_frais_reels = null, remarques = null,
				rtt_paye = null, regularisation_hs_majorees = null, regularisation_hs_non_majorees = null, reliquat = null, items = []) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.semaine_id = semaine_id;
		this.date = date;
		this.panier = panier;
		this.prime_panier = prime_panier;
		this.montant_panier = montant_panier;
		this.mobilite = mobilite;
		this.prime_mobilite = prime_mobilite;
		this.montant_mobilite = montant_mobilite;
		this.nuite = nuite;
		this.prime_nuite = prime_nuite;
		this.frais_deplacement = frais_deplacement;
		this.prime_deplacement = prime_deplacement;
		this.montant_frais_reels = montant_frais_reels;
		this.remarques = remarques;
		this.rtt_paye = rtt_paye;
		this.regularisation_hs_majorees = regularisation_hs_majorees;
		this.regularisation_hs_non_majorees = regularisation_hs_non_majorees;
		this.reliquat = reliquat;
		this.items = items;
	}

	public static LoadFromJSON(json: any) {
		return new FeuilleHeuresJourObject(
			json['id'],
			json['createdAt'],
			json['updatedAt'],
			json['semaine_id'],
			json['date'],
			json['panier'],
			json['prime_panier'] ? PrimeObject.LoadFromJSON(json['prime_panier']) : null,
			json['montant_panier'],
			json['mobilite'],
			json['prime_mobilite'] ? PrimeObject.LoadFromJSON(json['prime_mobilite']) : null,
			json['montant_mobilite'],
			json['nuite'],
			json['prime_nuite'] ? PrimeObject.LoadFromJSON(json['prime_nuite']) : null,
			json['frais_deplacement'],
			json['prime_deplacement'] ? PrimeObject.LoadFromJSON(json['prime_deplacement']) : null,
			json['montant_frais_reels'],
			json['remarques'],
			json['rtt_paye'],
			json['regularisation_hs_majorees'],
			json['regularisation_hs_non_majorees'],
			json['reliquat'],
			this.LoadFromArray(json['items'])
		);
	}

	public static LoadFromArray(arr: any[]) {
		const ret = [];
		if (arr) {
			for (let i = 0; i < arr.length; i++) {
				ret.push(FeuilleHeuresItemObject.LoadFromJSON(arr[i]));
			}
		}
		return ret;
	}

	missingMandatoryFields() {
		const day = moment(this.date).format('dddd DD/MM/YYYY');
		for (const i in this.items) {
			const missingFieldMessage = this.items[i].missingMandatoryFields(day);
			if (missingFieldMessage) {
				return missingFieldMessage;
			}
		}
		return null;
	}

	clearRelatedFields(field) {
		switch (field) {
			case 'panier':
				if (!this.panier) {
					this.prime_panier = null;
					this.montant_panier = null;
				}
				break;
			case 'mobilite':
				if (!this.mobilite) {
					this.prime_mobilite = null;
					this.montant_panier = null;
				}
				break;
			case 'nuite':
				if (!this.nuite) {
					this.prime_nuite = null;
				}
				break;
			case 'frais_deplacement':
				if (this.frais_deplacement !== 'Grand déplacement') {
					this.prime_deplacement = null;
				}
				if (this.frais_deplacement !== 'Frais réels') {
					this.montant_frais_reels = null;
				}
				break;
			default :
				break;
		}
	}

	getTempsPasse() {
		let tempsPasse = 0;
		for (const i in this.items) {
			if (this.items[i].temps_travail !== null) {
				tempsPasse += this.items[i].temps_travail;
			}
		}
		return tempsPasse;
	}

	getFormData() {
		return {
			semaine_id: this.semaine_id,
			date: this.date ? moment(this.date).format('YYYY-MM-DD') : null,
			panier: this.panier,
			prime_panier: this.prime_panier ? this.prime_panier.getFormData(true) : null,
			montant_panier: this.montant_panier,
			mobilite: this.mobilite,
			prime_mobilite: this.prime_mobilite ? this.prime_mobilite.getFormData(true) : null,
			montant_mobilite: this.montant_mobilite,
			nuite: this.nuite,
			prime_nuite: this.prime_nuite ? this.prime_nuite.getFormData(true) : null,
			frais_deplacement: this.frais_deplacement,
			prime_deplacement: this.prime_deplacement ? this.prime_deplacement.getFormData(true) : null,
			montant_frais_reels: this.montant_frais_reels,
			remarques: this.remarques,
			rtt_paye: this.rtt_paye,
			regularisation_hs_majorees: this.regularisation_hs_majorees,
			regularisation_hs_non_majorees: this.regularisation_hs_non_majorees,
			reliquat: this.reliquat
		};
	}

	getTotalPrimes() {
		let total = 0;
		if (this.prime_panier) {
			total += Number(this.prime_panier.montant);
		}
		if (this.prime_mobilite) {
			total += Number(this.prime_mobilite.montant);
		}
		if (this.prime_nuite) {
			total += Number(this.prime_nuite.montant);
		}
		if (this.prime_deplacement) {
			total += Number(this.prime_deplacement.montant);
		}
		if (this.montant_frais_reels) {
			total += this.montant_frais_reels;
		}
		return total;
	}
}
