import {NgModule} from '@angular/core';
import {NoPreloading, RouterModule, Routes} from '@angular/router';

import {SecuredGuard} from './guards/secured.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full'
	},
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
	},
	{
		path: 'home',
		loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'tabs',
		loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
	},
	{
		path: 'forms-list',
		loadChildren: () => import('./pages/forms-list/forms-list.module').then(m => m.FormsListPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'indicators',
		loadChildren: () => import('./pages/indicators/indicators.module').then(m => m.IndicatorsPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'infos',
		loadChildren: () => import('./pages/infos/infos.module').then(m => m.InfosPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'new-form',
		loadChildren: () => import('./pages/new-form/new-form.module').then(m => m.NewFormPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'users',
		loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'accueil-securite-desktop',
		loadChildren: () => import('./pages/forms/securite/accueil-securite-desktop/accueil-securite-desktop.module').then(m => m.AccueilSecuriteDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'analyse-risques-desktop',
		loadChildren: () => import('./pages/forms/securite/analyse-risques-desktop/analyse-risques-desktop.module').then(m => m.AnalyseRisquesDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'demande-travaux-desktop',
		loadChildren: () => import('./pages/forms/production/demande-travaux-desktop/demande-travaux-desktop.module').then(m => m.DemandeTravauxDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'inspection-chantier-desktop',
		loadChildren: () => import('./pages/forms/securite/inspection-chantier-desktop/inspection-chantier-desktop.module').then(m => m.InspectionChantierDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'rapport-accident-desktop',
		loadChildren: () => import('./pages/forms/securite/rapport-accident-desktop/rapport-accident-desktop.module').then(m => m.RapportAccidentDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'pv-reception-desktop',
		loadChildren: () => import('./pages/forms/production/pv-reception-desktop/pv-reception-desktop.module').then(m => m.PvReceptionDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'cr-intervention-desktop',
		loadChildren: () => import('./pages/forms/production/cr-intervention-desktop/cr-intervention-desktop.module').then(m => m.CrInterventionDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'fiche-qualite-desktop',
		loadChildren: () => import('./pages/forms/production/fiche-qualite-desktop/fiche-qualite-desktop.module').then(m => m.FicheQualiteDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'demande-absence-desktop',
		loadChildren: () => import('./pages/forms/administratif/demande-absence-desktop/demande-absence-desktop.module').then(m => m.DemandeAbsenceDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'consigne-harnais-desktop',
		loadChildren: () => import('./pages/forms/securite/consigne-harnais-desktop/consigne-harnais-desktop.module').then(m => m.ConsigneHarnaisDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'ouverture-affaire-desktop',
		loadChildren: () => import('./pages/forms/production/ouverture-affaire-desktop/ouverture-affaire-desktop.module').then(m => m.OuvertureAffaireDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'plan-prevention-desktop',
		loadChildren: () => import('./pages/forms/securite/plan-prevention-desktop/plan-prevention-desktop.module').then(m => m.PlanPreventionDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'tasks-list',
		loadChildren: () => import('./pages/tasks-list/tasks-list.module').then(m => m.TasksListPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'task-demande-travaux-desktop',
		loadChildren: () => import('./pages/tasks/production/task-demande-travaux-desktop/task-demande-travaux-desktop.module').then(m => m.TaskDemandeTravauxDesktopPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'chiffrages',
		loadChildren: () => import('./pages/forms/commerce/chiffrages/chiffrages.module').then(m => m.ChiffragesPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'chiffrage',
		loadChildren: () => import('./pages/forms/commerce/chiffrage/chiffrage.module').then(m => m.ChiffragePageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'affaires',
		loadChildren: () => import('./pages/forms/commerce/affaires/affaires.module').then(m => m.AffairesPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'affaire',
		loadChildren: () => import('./pages/forms/commerce/affaire/affaire.module').then(m => m.AffairePageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'vehicules',
		loadChildren: () => import('./pages/forms/parametres/vehicules/vehicules.module').then(m => m.VehiculesPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'planning-general',
		loadChildren: () => import('./pages/plannings/planning-general/planning-general.module').then(m => m.PlanningGeneralPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'planning-affaires',
		loadChildren: () => import('./pages/plannings/planning-affaires/planning-affaires.module').then(m => m.PlanningAffairesPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'planning-service',
		loadChildren: () => import('./pages/plannings/planning-service/planning-service.module').then(m => m.PlanningServicePageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'planning-vehicules',
		loadChildren: () => import('./pages/plannings/planning-vehicules/planning-vehicules.module').then(m => m.PlanningVehiculesPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'feuilles-heures',
		loadChildren: () => import('./pages/forms/administratif/feuilles-heures/feuilles-heures.module').then(m => m.FeuillesHeuresPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'feuille-heures',
		loadChildren: () => import('./pages/forms/administratif/feuille-heures/feuille-heures.module').then(m => m.FeuilleHeuresPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'primes',
		loadChildren: () => import('./pages/forms/parametres/primes/primes.module').then(m => m.PrimesPageModule),
		canActivate: [SecuredGuard]
	},
	{
		path: 'suivi-heures',
		loadChildren: () => import('./pages/rapports/suivi-heures/suivi-heures.module').then(m => m.SuiviHeuresPageModule),
		canActivate: [SecuredGuard]
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading, useHash: true, relativeLinkResolution: 'legacy'})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
