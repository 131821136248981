import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'formStatus',
})
export class FormStatusPipe implements PipeTransform {

	transform(value: string, table: string) {
		switch (table) {

			// Sécurité

			case 'accueil_securite' :
				switch (value) {
					case 'draft' :
						return 'Brouillon';
					case 'signed' :
						return 'Terminé';
					default :
						return 'Inconnu';
				}
			case 'plan_prevention' :
				switch (value) {
					case 'draft' :
						return 'Brouillon';
					case 'signed' :
						return 'Terminé';
					default :
						return 'Inconnu';
				}
			case 'analyse_risques' :
				switch (value) {
					case 'draft' :
						return 'Brouillon';
					case 'signed' :
						return 'Terminée';
					default :
						return 'Inconnu';
				}
			case 'inspection_chantier' :
				switch (value) {
					case 'draft' :
						return 'Brouillon';
					case 'signed' :
						return 'Terminée';
					default :
						return 'Inconnu';
				}
			case 'rapport_incident' :
				switch (value) {
					case 'draft' :
						return 'Brouillon';
					case 'signed' :
						return 'Terminé';
					case 'validate' :
						return 'Validé';
					case 'closed' :
						return 'Cloturé';
					default :
						return 'Inconnu';
				}
			case 'consigne_harnais' :
				switch (value) {
					case 'draft' :
						return 'Brouillon';
					case 'signed' :
						return 'Terminée';
					default :
						return 'Inconnu';
				}

			// Production

			case 'ouverture_affaire' :
				switch (value) {
					case 'draft' :
						return 'Brouillon';
					case 'to_be_completed' :
						return 'À compléter';
					case 'signed' :
						return 'Terminé';
					case 'closed' :
						return 'Cloturée';
					default :
						return 'Inconnu';
				}
			case 'demande_travaux' :
				switch (value) {
					case 'draft' :
						return 'Brouillon';
					case 'signed' :
						return 'Terminée';
					default :
						return 'Inconnu';
				}
			case 'cr_intervention' :
				switch (value) {
					case 'draft' :
						return 'Brouillon';
					case 'signed' :
						return 'Terminé';
					default :
						return 'Inconnu';
				}
			case 'pv_reception' :
				switch (value) {
					case 'draft' :
						return 'Brouillon';
					case 'signed' :
						return 'Terminé';
					default :
						return 'Inconnu';
				}
			case 'fiche_qualite' :
				switch (value) {
					case 'draft' :
						return 'Brouillon';
					case 'signed' :
						return 'Terminée';
					case 'validate' :
						return 'Validée';
					case 'closed' :
						return 'Traitée';
					default :
						return 'Inconnu';
				}

			// Administratif

			case 'demande_absence' :
				switch (value) {
					case 'draft' :
						return 'Brouillon';
					case 'to_be_validated' :
						return 'À valider';
					case 'validate' :
						return 'Validée';
					case 'rejected' :
						return 'Refusée';
					case 'processed' :
						return 'Traitée';
					default :
						return 'Inconnu';
				}

			default :
				return 'Inconnu';
		}
	}
}
