import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'formCategory',
})
export class FormCategoryPipe implements PipeTransform {

	transform(value: string) {
		switch (value) {
			case 'accueil_securite' :
			case 'plan_prevention':
			case 'analyse_risques':
			case 'inspection_chantier':
			case 'rapport_incident':
			case 'consigne_harnais':
				return 'Sécurité';
			case 'ouverture_affaire' :
			case 'demande_travaux' :
			case 'pv_reception':
			case 'cr_intervention':
			case 'fiche_qualite':
				return 'Production';
			case 'demande_absence':
				return 'Administratif';
			default :
				return 'Inconnue';
		}
	}
}
