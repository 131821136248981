export class UserObject {
	id: number;
	last_name: string;
	first_name: string;
	complete_name: string;
	profil: string;
	profil_desc: string;
	poste: string;
	service: string;
	contract: string;
	mail: string;
	password: string;
	user_name: string;
	checked: boolean;
	actif: boolean;
	trigramme: string;

	constructor(id = null, last_name = '', first_name = '', profil = 'profil_0', poste = '', service = null, contract = '', mail = '', actif = true, trigramme = null, user_name?, password?) {
		this.id = id;
		this.last_name = last_name;
		this.first_name = first_name;
		this.complete_name = last_name + ' ' + first_name;
		this.profil = profil;
		this.setProfilDesc(profil);
		this.poste = poste;
		this.service = service;
		this.contract = contract;
		this.mail = mail;
		this.actif = actif;
		this.trigramme = trigramme;
		this.user_name = user_name;
		this.password = password;
	}

	public static LoadFromJSON(json: any) {
		return new UserObject(
			json['id'],
			json['last_name'],
			json['first_name'],
			json['profil'],
			json['poste'],
			json['service'],
			json['contract'],
			json['mail'] ? json['mail'] : json['email'],
			json['actif'],
			json['trigramme'],
			json['user_name'],
			json['password']
		);
	}

	setProfilDesc(profil) {
		switch (profil) {
			case 'profil_0' :
				this.profil_desc = 'Profil non attribué';
				break;
			case 'profil_1' :
				this.profil_desc = 'Administrateur';
				break;
			case 'profil_2' :
				this.profil_desc = 'Responsable HSE';
				break;
			case 'profil_3' :
				this.profil_desc = 'Responsable BE élec';
				break;
			case 'profil_4' :
				this.profil_desc = 'Responsable achats / logistique';
				break;
			case 'profil_5' :
				this.profil_desc = 'Chef d’atelier';
				break;
			case 'profil_6' :
				this.profil_desc = 'Responsable BE automatisme';
				break;
			case 'profil_7' :
				this.profil_desc = 'Chargé d’affaire';
				break;
			case 'profil_8' :
				this.profil_desc = 'Assistante Administrative';
				break;
			case 'profil_9' :
				this.profil_desc = 'Assistant chargé d’affaire';
				break;
			case 'profil_10' :
				this.profil_desc = 'Chef d’équipe';
				break;
			case 'profil_11' :
				this.profil_desc = 'Chef de chantier Resp Equipes';
				break;
			case 'profil_12' :
				this.profil_desc = 'Technicien / Projeteur';
				break;
			case 'profil_13' :
				this.profil_desc = 'Monteur/cableur';
				break;
			case 'profil_14' :
				this.profil_desc = 'Administrateur Directrice Générale';
				break;
			case 'profil_16' :
				this.profil_desc = 'Administrateur Responsable Technique';
				break;
			case 'profil_17' :
				this.profil_desc = 'Magasinier';
				break;
			case 'profil_18' :
				this.profil_desc = 'Apprenti';
				break;
			case 'profil_19' :
				this.profil_desc = 'Intérimaire';
				break;
			default :
				this.profil_desc = 'Profil non attribué';
				break;
		}
	}

	isAuthorized(page, action, status?, createdBy?) {
		switch (page) {

			// Home

			case 'HomePage' :
			case '/home' :
			case '/tabs/home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
							case 'profil_18' :
							case 'profil_19' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Utilisateurs

			case 'UsersPage' :
			case '/users' :
			case '/tabs/users' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Modifier' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Supprimer' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_14' :
							case 'profil_16' :
								return this.id !== Number(createdBy);
							default :
								return false;
						}
					default :
						return false;
				}

			// Infos flash

			case 'InfosPage' :
			case '/infos' :
			case '/tabs/infos' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
							case 'profil_18' :
							case 'profil_19' :
								return true;
							default :
								return false;
						}
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Modifier' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Supprimer' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Liste des formulaires

			case 'FormsListPage' :
			case '/forms-list' :
			case '/tabs/forms-list' :
			case '/new-form' :
			case '/tabs/forms-list/new-form' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Sécurité

			// Accueil Sécurité

			case 'accueil_securite' :
			case '/accueil-securite-desktop':
			case '/tabs/forms-list/accueil-securite-mobile' :
			case '/tabs/forms-list/new-form/accueil-securite-mobile' :
				switch (action) {
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Visualiser' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Dupliquer' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}

			// Plan de prévention

			case 'plan_prevention' :
			case '/plan-prevention-desktop':
			case '/tabs/forms-list/plan-prevention-mobile' :
			case '/tabs/forms-list/new-form/plan-prevention-mobile' :
				switch (action) {
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Visualiser' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Dupliquer' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}

			// Analyse des risques

			case 'analyse_risques' :
			case '/analyse-risques-desktop':
			case '/tabs/forms-list/analyse-risques-mobile' :
			case '/tabs/forms-list/new-form/analyse-risques-mobile' :
				switch (action) {
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_9' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Visualiser' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}

			// Inspection chantier

			case 'inspection_chantier' :
			case '/inspection-chantier-desktop':
			case '/tabs/forms-list/inspection-chantier-mobile' :
			case '/tabs/forms-list/new-form/inspection-chantier-mobile' :
				switch (action) {
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Visualiser' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter photos' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_7' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}

			// Rapport accident/incident/situation dangereuse

			case 'rapport_incident' :
			case '/rapport-accident-desktop':
			case '/tabs/forms-list/rapport-accident-mobile' :
			case '/tabs/forms-list/new-form/rapport-accident-mobile' :
				switch (action) {
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					case 'Visualiser' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
							case 'validate' :
							case 'closed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_14' :
									case 'profil_16' :
									case 'profil_17' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							case 'validate' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							case 'closed' :
								return false;
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
							case 'validate' :
							case 'closed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Dupliquer' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_14' :
									case 'profil_16' :
									case 'profil_17' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter' :
						switch (status) {
							case 'signed' :
							case 'validate' :
							case 'closed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_14' :
									case 'profil_16' :
									case 'profil_17' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter photos' :
						switch (status) {
							case 'signed' :
							case 'validate' :
							case 'closed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_7' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}

			// Consigne harnais

			case 'consigne_harnais' :
			case '/consigne-harnais-desktop':
			case '/tabs/forms-list/consigne-harnais-mobile' :
			case '/tabs/forms-list/new-form/consigne-harnais-mobile' :
				switch (action) {
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_5' :
							case 'profil_7' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Visualiser' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_5' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}

			// Production

			// Demande d'ouverture d'affaire

			case 'ouverture_affaire' :
			case '/ouverture-affaire-desktop':
			case '/tabs/forms-list/ouverture-affaire-mobile' :
			case '/tabs/forms-list/new-form/ouverture-affaire-mobile' :
				switch (action) {
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Visualiser' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'to_be_completed' :
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'to_be_completed' :
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							case 'closed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'to_be_completed' :
							case 'signed' :
							case 'closed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter' :
						switch (status) {
							case 'to_be_completed' :
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}

			// Demande de travaux

			case 'demande_travaux' :
			case '/demande-travaux-desktop':
			case '/tabs/forms-list/demande-travaux-mobile' :
			case '/tabs/forms-list/new-form/demande-travaux-mobile' :
				switch (action) {
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_9' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Visualiser' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}

			// Compte rendu d'intervention

			case 'cr_intervention' :
			case '/cr-intervention-desktop':
			case '/tabs/forms-list/cr-intervention-mobile' :
			case '/tabs/forms-list/new-form/cr-intervention-mobile' :
				switch (action) {
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Visualiser' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return this.id === Number(createdBy);
								}
							default :
								return false;
						}
					case 'Exporter photos' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_7' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}

			// PV de réception

			case 'pv_reception' :
			case '/pv-reception-desktop':
			case '/tabs/forms-list/pv-reception-mobile' :
			case '/tabs/forms-list/new-form/pv-reception-mobile' :
				switch (action) {
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Visualiser' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_7' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter photos' :
						switch (status) {
							case 'signed' :
								switch (this.profil) {
									case 'profil_2' :
									case 'profil_7' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}

			// Fiche qualité

			case 'fiche_qualite' :
			case '/fiche-qualite-desktop':
			case '/tabs/forms-list/fiche-qualite-mobile' :
			case '/tabs/forms-list/new-form/fiche-qualite-mobile' :
				switch (action) {
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Visualiser' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
							case 'validate' :
							case 'closed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
								switch (this.profil) {
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							case 'validate' :
								switch (this.profil) {
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							case 'closed' :
								return false;
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'signed' :
							case 'validate' :
							case 'closed' :
								switch (this.profil) {
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter' :
						switch (status) {
							case 'signed' :
							case 'validate' :
							case 'closed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter photos' :
						switch (status) {
							case 'signed' :
							case 'validate' :
							case 'closed' :
								switch (this.profil) {
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}

			// Administratif

			// Demande d'absence

			case 'demande_absence' :
			case '/demande-absence-desktop':
			case '/tabs/forms-list/demande-absence-mobile' :
			case '/tabs/forms-list/new-form/demande-absence-mobile' :
				switch (action) {
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					case 'Visualiser' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'to_be_validated' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return this.id === Number(createdBy);
								}
							case 'validate' :
							case 'rejected' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return this.id === Number(createdBy);
								}
							case 'processed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_7' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return this.id === Number(createdBy);
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'to_be_validated' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_14' :
									case 'profil_16' :
										return this.id !== Number(createdBy);
									default :
										return false;
								}
							case 'validate' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_7' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							case 'rejected' :
							case 'processed' :
								return false;
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								return this.id === Number(createdBy);
							case 'to_be_validated' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return this.id === Number(createdBy);
								}
							case 'validate' :
							case 'rejected' :
							case 'processed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter' :
						switch (status) {
							case 'draft' :
								return false;
							case 'to_be_validated' :
							case 'validate' :
							case 'rejected' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return this.id === Number(createdBy);
								}
							case 'processed' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_7' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return this.id === Number(createdBy);
								}
							default :
								return false;
						}
					default :
						return false;
				}

			// Liste des tâches

			case 'TasksListPage' :
			case '/tasks-list' :
			case '/tabs/tasks-list' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}


			// Tache demande de travaux

			case 'task_demande_travaux' :
			case '/task-demande-travaux-desktop':
			case '/tabs/tasks-list/task-demande-travaux-mobile' :
			case '/tabs/tasks-list/new-form/task-demande-travaux-mobile' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'inprogress' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_14' :
									case 'profil_16' :
									case 'profil_17' :
										return true;
									default :
										return false;
								}
							case 'finished' :
								return false;
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'inprogress' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							case 'finished' :
								return false;
							default :
								return false;
						}
					default :
						return false;
				}

			case 'task_ouverture_affaire' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_8' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_8' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'inprogress' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_8' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							case 'finished' :
								return false;
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'inprogress' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_8' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							case 'finished' :
								return false;
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateurs

			case 'IndicatorsPage' :
			case '/indicators' :
			case '/tabs/indicators' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					case 'Modifier' :
						switch (this.profil) {
							case 'profil_2' :
								return true;
							default :
								return false;
						}
					case 'Exporter' :
						switch (this.profil) {
							case 'profil_2' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Réalisation des analyses de risques si pas de PDP

			case 'Indicator_2' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_2_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Réalisation globale des analyses de risques

			case 'Indicator_3' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_3_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Traitement de remontées d'événements

			case 'Indicator_4' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_4_home' :
				switch (action) {
					case 'Visualiser' :
						return false;
					default :
						return false;
				}

			// Indicateur Réalisation des analyses de risques par chargé d'affaire

			case 'Indicator_5' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_5_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_7' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Réalisation des inspections chantier par chargé d'affaire

			case 'Indicator_6' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_6_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_7' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Réalisation des inspections chantier

			case 'Indicator_7' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_7_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_2' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Accidents avec arrêt

			case 'Indicator_8' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_8_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Accidents sans arrêt

			case 'Indicator_9' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_9_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Soins

			case 'Indicator_10' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_10_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Situations dangereuses

			case 'Indicator_11' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_11_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Incidents sur véhicules

			case 'Indicator_12' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_12_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Accueil chantier / Nombre de Non-Conformités

			case 'Indicator_13' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_13_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Accueil chantier / Répartition des Non-Conformités

			case 'Indicator_14' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_14_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Analyse des risques / Répartition des risques

			case 'Indicator_15' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_15_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Inspection chantier / Nombre de Non-Conformités

			case 'Indicator_16' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_16_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							default :
								return false;
						}
					default :
						return false;
				}

			// Indicateur Inspection chantier / Répartition des Non-Conformités

			case 'Indicator_17' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'Indicator_17_home' :
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							default :
								return false;
						}
					default :
						return false;
				}

			// Commerce

			case 'ChiffragesPage' :
			case '/chiffrages':
			case '/chiffrage':
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_3':
							case 'profil_6':
							case 'profil_7' :
							case 'profil_9' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Modifier' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_6':
							case 'profil_7' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Supprimer' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_7' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'AffairesPage' :
			case '/affaires':
			case '/affaire':
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
							case 'profil_18' :
							case 'profil_19' :
								return true;
							default :
								return false;
						}
					case 'Modifier' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_3' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_11' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Supprimer' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Afficher' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					case 'Masquer' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'VehiculesPage' :
			case '/vehicules':
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_8' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Modifier' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Activer' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'Désactiver' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_14' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'PlanningGeneralPage' :
			case '/planning-general':
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
							case 'profil_18' :
							case 'profil_19' :
								return true;
							default :
								return false;
						}
					case 'Modifier' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'AjouterTache' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_7' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					case 'ModifierTache' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_7' :
							case 'profil_16' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'PlanningAffairesPage' :
			case '/planning-affaires':
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
							case 'profil_18' :
							case 'profil_19' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'PlanningServicePage' :
			case '/planning-service':
				switch (action) {
					case 'Visualiser' :
						switch (status) {
							case 'etude-elec' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_14' :
									case 'profil_16' :
									case 'profil_17' :
									case 'profil_18' :
									case 'profil_19' :
										return true;
									default :
										return false;
								}
							case 'etude-auto' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_14' :
									case 'profil_16' :
									case 'profil_17' :
									case 'profil_18' :
									case 'profil_19' :
										return true;
									default :
										return false;
								}
							case 'atelier' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_14' :
									case 'profil_16' :
									case 'profil_17' :
									case 'profil_18' :
									case 'profil_19' :
										return true;
									default :
										return false;
								}
							case 'chantier' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_2' :
									case 'profil_3' :
									case 'profil_4' :
									case 'profil_5' :
									case 'profil_6' :
									case 'profil_7' :
									case 'profil_8' :
									case 'profil_9' :
									case 'profil_10' :
									case 'profil_11' :
									case 'profil_12' :
									case 'profil_13' :
									case 'profil_14' :
									case 'profil_16' :
									case 'profil_17' :
									case 'profil_18' :
									case 'profil_19' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'etude-elec' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_3' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							case 'etude-auto' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_6' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							case 'atelier' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_5' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							case 'chantier' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_7' :
									case 'profil_11' :
									case 'profil_14' :
									case 'profil_16' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}
			case 'PlanningVehiculesPage' :
			case '/planning-vehicules':
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_2' :
							case 'profil_3' :
							case 'profil_4' :
							case 'profil_5' :
							case 'profil_6' :
							case 'profil_7' :
							case 'profil_8' :
							case 'profil_9' :
							case 'profil_10' :
							case 'profil_11' :
							case 'profil_12' :
							case 'profil_13' :
							case 'profil_14' :
							case 'profil_16' :
							case 'profil_17' :
							case 'profil_18' :
							case 'profil_19' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'FeuillesHeuresPage' :
			case '/feuilles-heures':
			case '/feuille-heures':
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					case 'VisualiserTout' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					case 'Supprimer' :
						switch (status) {
							case 'draft' :
								if (this.id === Number(createdBy)) {
									return true;
								} else {
									switch (this.profil) {
										case 'profil_1' :
										case 'profil_14' :
											return true;
										default :
											return false;
									}
								}
							case 'done' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							case 'validated' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							case 'verified' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Modifier' :
						switch (status) {
							case 'draft' :
								if (this.id === Number(createdBy)) {
									return true;
								} else {
									switch (this.profil) {
										case 'profil_1' :
										case 'profil_14' :
											return true;
										default :
											return false;
									}
								}
							case 'done' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							case 'validated' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							case 'verified' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'ModifierChampsRegle1' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					case 'ModifierChampsRegle2' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					case 'ModifierChampsRegle3' :
						switch (status) {
							case 'draft' :
								return false
							case 'done' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							case 'validated' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							case 'verified' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Terminer' :
						switch (status) {
							case 'done':
							case 'validated' :
							case 'verified' :
								return false;
							case 'draft' :
								if (this.id === Number(createdBy)) {
									return true;
								} else {
									switch (this.profil) {
										case 'profil_1' :
										case 'profil_14' :
											return true;
										default :
											return false;
									}
								}
							default :
								return false;
						}
					case 'Valider' :
						switch (status) {
							case 'draft' :
							case 'validated' :
							case 'verified' :
								return false;
							case 'done' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Vérifier' :
						switch (status) {
							case 'draft' :
							case 'done' :
							case 'verified' :
								return false;
							case 'validated' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					case 'Exporter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					case 'SuiviPointages' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'PrimesPage' :
			case '/primes':
				switch (action) {
					case 'Visualiser' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					case 'Ajouter' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					case 'Modifier' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					case 'Supprimer' :
						switch (this.profil) {
							case 'profil_1' :
							case 'profil_14' :
								return true;
							default :
								return false;
						}
					default :
						return false;
				}
			case 'SuiviHeuresPage' :
			case '/suivi-heures':
				// TODO uncomment
				return false;
				switch (action) {
					case 'Visualiser' :
						switch (status) {
							case 'salaire' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							case 'heures_supp' :
								switch (this.profil) {
									case 'profil_1' :
									case 'profil_14' :
										return true;
									default :
										return false;
								}
							default :
								return false;
						}
					default :
						return false;
				}
			default :
				return false;
		}
	}
}
