import {FileService} from '../../../services/file.service';

import {UserObject} from '../../user.object';

import * as moment from 'moment';

export class InspectionChantierObject {
	id: number;
	table = 'inspection_chantier';
	titre = 'Inspection Atelier / Chantier';
	createdAt: string;
	updatedAt: string;
	status: string;
	createdBy: UserObject;
	updatedBy: UserObject;

	// Step 1

	id_affaire: number;

	// Step 2

	nom_client: string;
	code_client: string;
	nom_affaire: string;
	responsable: string;
	nature_travaux: string;
	nb_stemi: number;
	nb_interim: number;
	personne_stemi_auditee: Array<UserObject>;
	personne_interim_auditee: Array<UserObject>;
	personnes_supplementaires: Array<{ societe: string, societe_missing: boolean, nom: string, nom_missing: boolean, type: string, type_missing: boolean }>;

	// Step 2 missing

	nature_travaux_missing: boolean;
	nb_stemi_missing: boolean;
	nb_interim_missing: boolean;
	personne_stemi_auditee_missing: boolean;

	// Step 3

	voie_acces: string;
	voie_acces_precision: string;
	chantier_signalise: string;
	chantier_signalise_precision: string;
	sortie_secours_libre: string;
	sortie_secours_libre_precision: string;
	chantier_ordre: string;
	chantier_ordre_precision: string;
	dta: string;
	dta_precision: string;
	autorisation_permis_valide: string;
	autorisation_permis_valide_precision: string;
	manoeuvre_consignation: string;
	manoeuvre_consignation_precision: string;
	permis_feu_extincteur: string;
	permis_feu_extincteur_precision: string;
	permis_penetrer: string;
	permis_penetrer_precision: string;
	personnel_stemi_identifie: string;
	personnel_stemi_identifie_precision: string;
	personnel_interim_identifie: string;
	personnel_interim_identifie_precision: string;
	accueils_hse: string;
	accueils_hse_precision: string;
	plan_prevention: string;
	plan_prevention_precision: string;
	analyse_risques: string;
	analyse_risques_precision: string;
	risques_lies_chantier: string;
	risques_lies_chantier_precision: string;
	risques_specifiques: string;
	risques_specifiques_precision: string;
	habilitations_disponibles: string;
	habilitations_disponibles_precision: string;
	habilitations_valides: string;
	habilitations_valides_precision: string;
	sst_present: string;
	sst_present_precision: string;
	echelle_etat: string;
	echelle_etat_precision: string;
	echafaudage_conforme: string;
	echafaudage_conforme_precision: string;
	fiches_autorisation: string;
	fiches_autorisation_precision: string;
	nacelle_conforme: string;
	nacelle_conforme_precision: string;
	etat_outillage: string;
	etat_outillage_precision: string;
	epi: string;
	epi_precision: string;
	epi_etat: string;
	epi_etat_precision: string;
	risques_coactivite: string;
	risques_coactivite_precision: string;
	vat_chantier: string;
	vat_chantier_precision: string;
	emplacement_douches: string;
	emplacement_douches_precision: string;
	moyens_alerte: string;
	moyens_alerte_precision: string;
	localisation_extincteurs: string;
	localisation_extincteurs_precision: string;
	harnais_controle: string;
	harnais_controle_precision: string;
	cadenas_consignation: string;
	cadenas_consignation_precision: string;
	etat_vestiaires: string;
	etat_vestiaires_precision: string;
	etat_sanitaires: string;
	etat_sanitaires_precision: string;
	tri_dechets: string;
	tri_dechets_precision: string;
	emplacement_bennes: string;
	emplacement_bennes_precision: string;
	actions_non_securitaires: string;
	actions_non_securitaires_precision: string;
	actions_securitaires: string;
	actions_securitaires_precision: string;
	situations_dangereuses: string;
	situations_dangereuses_precision: string;
	photos: Array<any>;

	// Step 3 missing

	voie_acces_missing: boolean;
	voie_acces_precision_missing: boolean;
	chantier_signalise_missing: boolean;
	chantier_signalise_precision_missing: boolean;
	sortie_secours_libre_missing: boolean;
	sortie_secours_libre_precision_missing: boolean;
	chantier_ordre_missing: boolean;
	chantier_ordre_precision_missing: boolean;
	dta_missing: boolean;
	dta_precision_missing: boolean;
	autorisation_permis_valide_missing: boolean;
	autorisation_permis_valide_precision_missing: boolean;
	manoeuvre_consignation_missing: boolean;
	manoeuvre_consignation_precision_missing: boolean;
	permis_feu_extincteur_missing: boolean;
	permis_feu_extincteur_precision_missing: boolean;
	permis_penetrer_missing: boolean;
	permis_penetrer_precision_missing: boolean;
	personnel_stemi_identifie_missing: boolean;
	personnel_stemi_identifie_precision_missing: boolean;
	personnel_interim_identifie_missing: boolean;
	personnel_interim_identifie_precision_missing: boolean;
	accueils_hse_missing: boolean;
	accueils_hse_precision_missing: boolean;
	plan_prevention_missing: boolean;
	plan_prevention_precision_missing: boolean;
	risques_lies_chantier_missing: boolean;
	risques_lies_chantier_precision_missing: boolean;
	risques_specifiques_missing: boolean;
	risques_specifiques_precision_missing: boolean;
	habilitations_disponibles_missing: boolean;
	habilitations_disponibles_precision_missing: boolean;
	habilitations_valides_missing: boolean;
	habilitations_valides_precision_missing: boolean;
	sst_present_missing: boolean;
	sst_present_precision_missing: boolean;
	echelle_etat_missing: boolean;
	echelle_etat_precision_missing: boolean;
	echafaudage_conforme_missing: boolean;
	echafaudage_conforme_precision_missing: boolean;
	fiches_autorisation_missing: boolean;
	fiches_autorisation_precision_missing: boolean;
	nacelle_conforme_missing: boolean;
	nacelle_conforme_precision_missing: boolean;
	etat_outillage_missing: boolean;
	etat_outillage_precision_missing: boolean;
	epi_missing: boolean;
	epi_precision_missing: boolean;
	epi_etat_missing: boolean;
	epi_etat_precision_missing: boolean;
	risques_coactivite_missing: boolean;
	risques_coactivite_precision_missing: boolean;
	vat_chantier_missing: boolean;
	vat_chantier_precision_missing: boolean;
	emplacement_douches_missing: boolean;
	emplacement_douches_precision_missing: boolean;
	moyens_alerte_missing: boolean;
	moyens_alerte_precision_missing: boolean;
	localisation_extincteurs_missing: boolean;
	localisation_extincteurs_precision_missing: boolean;
	harnais_controle_missing: boolean;
	harnais_controle_precision_missing: boolean;
	cadenas_consignation_missing: boolean;
	cadenas_consignation_precision_missing: boolean;
	etat_vestiaires_missing: boolean;
	etat_vestiaires_precision_missing: boolean;
	etat_sanitaires_missing: boolean;
	etat_sanitaires_precision_missing: boolean;
	tri_dechets_missing: boolean;
	tri_dechets_precision_missing: boolean;
	emplacement_bennes_missing: boolean;
	emplacement_bennes_precision_missing: boolean;
	actions_non_securitaires_missing: boolean;
	actions_non_securitaires_precision_missing: boolean;
	actions_securitaires_missing: boolean;
	actions_securitaires_precision_missing: boolean;
	situations_dangereuses_missing: boolean;
	situations_dangereuses_precision_missing: boolean;

	// Step 4

	action_voie_acces_description: string;
	action_voie_acces_responsable: UserObject;
	action_voie_acces_application: string;
	action_chantier_signalise_description: string;
	action_chantier_signalise_responsable: UserObject;
	action_chantier_signalise_application: string;
	action_sortie_secours_libre_description: string;
	action_sortie_secours_libre_responsable: UserObject;
	action_sortie_secours_libre_application: string;
	action_chantier_ordre_description: string;
	action_chantier_ordre_responsable: UserObject;
	action_chantier_ordre_application: string;
	action_dta_description: string;
	action_dta_responsable: UserObject;
	action_dta_application: string;
	action_autorisation_permis_valide_description: string;
	action_autorisation_permis_valide_responsable: UserObject;
	action_autorisation_permis_valide_application: string;
	action_manoeuvre_consignation_description: string;
	action_manoeuvre_consignation_responsable: UserObject;
	action_manoeuvre_consignation_application: string;
	action_permis_feu_extincteur_description: string;
	action_permis_feu_extincteur_responsable: UserObject;
	action_permis_feu_extincteur_application: string;
	action_permis_penetrer_description: string;
	action_permis_penetrer_responsable: UserObject;
	action_permis_penetrer_application: string;
	action_personnel_stemi_identifie_description: string;
	action_personnel_stemi_identifie_responsable: UserObject;
	action_personnel_stemi_identifie_application: string;
	action_personnel_interim_identifie_description: string;
	action_personnel_interim_identifie_responsable: UserObject;
	action_personnel_interim_identifie_application: string;
	action_accueils_hse_description: string;
	action_accueils_hse_responsable: UserObject;
	action_accueils_hse_application: string;
	action_plan_prevention_description: string;
	action_plan_prevention_responsable: UserObject;
	action_plan_prevention_application: string;
	action_analyse_risques_description: string;
	action_analyse_risques_responsable: UserObject;
	action_analyse_risques_application: string;
	action_risques_lies_chantier_description: string;
	action_risques_lies_chantier_responsable: UserObject;
	action_risques_lies_chantier_application: string;
	action_risques_specifiques_description: string;
	action_risques_specifiques_responsable: UserObject;
	action_risques_specifiques_application: string;
	action_habilitations_disponibles_description: string;
	action_habilitations_disponibles_responsable: UserObject;
	action_habilitations_disponibles_application: string;
	action_habilitations_valides_description: string;
	action_habilitations_valides_responsable: UserObject;
	action_habilitations_valides_application: string;
	action_sst_present_description: string;
	action_sst_present_responsable: UserObject;
	action_sst_present_application: string;
	action_echelle_etat_description: string;
	action_echelle_etat_responsable: UserObject;
	action_echelle_etat_application: string;
	action_echafaudage_conforme_description: string;
	action_echafaudage_conforme_responsable: UserObject;
	action_echafaudage_conforme_application: string;
	action_fiches_autorisation_description: string;
	action_fiches_autorisation_responsable: UserObject;
	action_fiches_autorisation_application: string;
	action_nacelle_conforme_description: string;
	action_nacelle_conforme_responsable: UserObject;
	action_nacelle_conforme_application: string;
	action_etat_outillage_description: string;
	action_etat_outillage_responsable: UserObject;
	action_etat_outillage_application: string;
	action_epi_description: string;
	action_epi_responsable: UserObject;
	action_epi_application: string;
	action_epi_etat_description: string;
	action_epi_etat_responsable: UserObject;
	action_epi_etat_application: string;
	action_risques_coactivite_description: string;
	action_risques_coactivite_responsable: UserObject;
	action_risques_coactivite_application: string;
	action_vat_chantier_description: string;
	action_vat_chantier_responsable: UserObject;
	action_vat_chantier_application: string;
	action_emplacement_douches_description: string;
	action_emplacement_douches_responsable: UserObject;
	action_emplacement_douches_application: string;
	action_moyens_alerte_description: string;
	action_moyens_alerte_responsable: UserObject;
	action_moyens_alerte_application: string;
	action_localisation_extincteurs_description: string;
	action_localisation_extincteurs_responsable: UserObject;
	action_localisation_extincteurs_application: string;
	action_harnais_controle_description: string;
	action_harnais_controle_responsable: UserObject;
	action_harnais_controle_application: string;
	action_cadenas_consignation_description: string;
	action_cadenas_consignation_responsable: UserObject;
	action_cadenas_consignation_application: string;
	action_etat_vestiaires_description: string;
	action_etat_vestiaires_responsable: UserObject;
	action_etat_vestiaires_application: string;
	action_etat_sanitaires_description: string;
	action_etat_sanitaires_responsable: UserObject;
	action_etat_sanitaires_application: string;
	action_tri_dechets_description: string;
	action_tri_dechets_responsable: UserObject;
	action_tri_dechets_application: string;
	action_emplacement_bennes_description: string;
	action_emplacement_bennes_responsable: UserObject;
	action_emplacement_bennes_application: string;

	// Step 4 missing

	action_voie_acces_description_missing: boolean;
	action_voie_acces_responsable_missing: boolean;
	action_voie_acces_application_missing: boolean;
	action_chantier_signalise_description_missing: boolean;
	action_chantier_signalise_responsable_missing: boolean;
	action_chantier_signalise_application_missing: boolean;
	action_sortie_secours_libre_description_missing: boolean;
	action_sortie_secours_libre_responsable_missing: boolean;
	action_sortie_secours_libre_application_missing: boolean;
	action_chantier_ordre_description_missing: boolean;
	action_chantier_ordre_responsable_missing: boolean;
	action_chantier_ordre_application_missing: boolean;
	action_dta_description_missing: boolean;
	action_dta_responsable_missing: boolean;
	action_dta_application_missing: boolean;
	action_autorisation_permis_valide_description_missing: boolean;
	action_autorisation_permis_valide_responsable_missing: boolean;
	action_autorisation_permis_valide_application_missing: boolean;
	action_manoeuvre_consignation_description_missing: boolean;
	action_manoeuvre_consignation_responsable_missing: boolean;
	action_manoeuvre_consignation_application_missing: boolean;
	action_permis_feu_extincteur_description_missing: boolean;
	action_permis_feu_extincteur_responsable_missing: boolean;
	action_permis_feu_extincteur_application_missing: boolean;
	action_permis_penetrer_description_missing: boolean;
	action_permis_penetrer_responsable_missing: boolean;
	action_permis_penetrer_application_missing: boolean;
	action_personnel_stemi_identifie_description_missing: boolean;
	action_personnel_stemi_identifie_responsable_missing: boolean;
	action_personnel_stemi_identifie_application_missing: boolean;
	action_personnel_interim_identifie_description_missing: boolean;
	action_personnel_interim_identifie_responsable_missing: boolean;
	action_personnel_interim_identifie_application_missing: boolean;
	action_accueils_hse_description_missing: boolean;
	action_accueils_hse_responsable_missing: boolean;
	action_accueils_hse_application_missing: boolean;
	action_plan_prevention_description_missing: boolean;
	action_plan_prevention_responsable_missing: boolean;
	action_plan_prevention_application_missing: boolean;
	action_risques_lies_chantier_description_missing: boolean;
	action_risques_lies_chantier_responsable_missing: boolean;
	action_risques_lies_chantier_application_missing: boolean;
	action_risques_specifiques_description_missing: boolean;
	action_risques_specifiques_responsable_missing: boolean;
	action_risques_specifiques_application_missing: boolean;
	action_habilitations_disponibles_description_missing: boolean;
	action_habilitations_disponibles_responsable_missing: boolean;
	action_habilitations_disponibles_application_missing: boolean;
	action_habilitations_valides_description_missing: boolean;
	action_habilitations_valides_responsable_missing: boolean;
	action_habilitations_valides_application_missing: boolean;
	action_sst_present_description_missing: boolean;
	action_sst_present_responsable_missing: boolean;
	action_sst_present_application_missing: boolean;
	action_echelle_etat_description_missing: boolean;
	action_echelle_etat_responsable_missing: boolean;
	action_echelle_etat_application_missing: boolean;
	action_echafaudage_conforme_description_missing: boolean;
	action_echafaudage_conforme_responsable_missing: boolean;
	action_echafaudage_conforme_application_missing: boolean;
	action_fiches_autorisation_description_missing: boolean;
	action_fiches_autorisation_responsable_missing: boolean;
	action_fiches_autorisation_application_missing: boolean;
	action_nacelle_conforme_description_missing: boolean;
	action_nacelle_conforme_responsable_missing: boolean;
	action_nacelle_conforme_application_missing: boolean;
	action_etat_outillage_description_missing: boolean;
	action_etat_outillage_responsable_missing: boolean;
	action_etat_outillage_application_missing: boolean;
	action_epi_description_missing: boolean;
	action_epi_responsable_missing: boolean;
	action_epi_application_missing: boolean;
	action_epi_etat_description_missing: boolean;
	action_epi_etat_responsable_missing: boolean;
	action_epi_etat_application_missing: boolean;
	action_risques_coactivite_description_missing: boolean;
	action_risques_coactivite_responsable_missing: boolean;
	action_risques_coactivite_application_missing: boolean;
	action_vat_chantier_description_missing: boolean;
	action_vat_chantier_responsable_missing: boolean;
	action_vat_chantier_application_missing: boolean;
	action_emplacement_douches_description_missing: boolean;
	action_emplacement_douches_responsable_missing: boolean;
	action_emplacement_douches_application_missing: boolean;
	action_moyens_alerte_description_missing: boolean;
	action_moyens_alerte_responsable_missing: boolean;
	action_moyens_alerte_application_missing: boolean;
	action_localisation_extincteurs_description_missing: boolean;
	action_localisation_extincteurs_responsable_missing: boolean;
	action_localisation_extincteurs_application_missing: boolean;
	action_harnais_controle_description_missing: boolean;
	action_harnais_controle_responsable_missing: boolean;
	action_harnais_controle_application_missing: boolean;
	action_cadenas_consignation_description_missing: boolean;
	action_cadenas_consignation_responsable_missing: boolean;
	action_cadenas_consignation_application_missing: boolean;
	action_etat_vestiaires_description_missing: boolean;
	action_etat_vestiaires_responsable_missing: boolean;
	action_etat_vestiaires_application_missing: boolean;
	action_etat_sanitaires_description_missing: boolean;
	action_etat_sanitaires_responsable_missing: boolean;
	action_etat_sanitaires_application_missing: boolean;
	action_tri_dechets_description_missing: boolean;
	action_tri_dechets_responsable_missing: boolean;
	action_tri_dechets_application_missing: boolean;
	action_emplacement_bennes_description_missing: boolean;
	action_emplacement_bennes_responsable_missing: boolean;
	action_emplacement_bennes_application_missing: boolean;

	// Step 5

	date_signature: string;

	// Status

	// draft
	// signed

	constructor(
		id = null,
		createdAt = null,
		updatedAt = null,
		status = 'draft',
		createdBy = null,
		updatedBy = null,
		// Step 1

		id_affaire = null,
		// Step 2

		nom_client = null,
		code_client = null,
		nom_affaire = null,
		responsable = null,
		nature_travaux = null,
		nb_stemi = null,
		nb_interim = null,
		personne_stemi_auditee = null,
		personne_interim_auditee = null,
		personnes_supplementaires = null,
		// Step 3

		voie_acces = null,
		voie_acces_precision = null,
		chantier_signalise = null,
		chantier_signalise_precision = null,
		sortie_secours_libre = null,
		sortie_secours_libre_precision = null,
		chantier_ordre = null,
		chantier_ordre_precision = null,
		dta = null,
		dta_precision = null,
		autorisation_permis_valide = null,
		autorisation_permis_valide_precision = null,
		manoeuvre_consignation = null,
		manoeuvre_consignation_precision = null,
		permis_feu_extincteur = null,
		permis_feu_extincteur_precision = null,
		permis_penetrer = null,
		permis_penetrer_precision = null,
		personnel_stemi_identifie = null,
		personnel_stemi_identifie_precision = null,
		personnel_interim_identifie = null,
		personnel_interim_identifie_precision = null,
		accueils_hse = null,
		accueils_hse_precision = null,
		plan_prevention = null,
		plan_prevention_precision = null,
		analyse_risques = null,
		analyse_risques_precision = null,
		risques_lies_chantier = null,
		risques_lies_chantier_precision = null,
		risques_specifiques = null,
		risques_specifiques_precision = null,
		habilitations_disponibles = null,
		habilitations_disponibles_precision = null,
		habilitations_valides = null,
		habilitations_valides_precision = null,
		sst_present = null,
		sst_present_precision = null,
		echelle_etat = null,
		echelle_etat_precision = null,
		echafaudage_conforme = null,
		echafaudage_conforme_precision = null,
		fiches_autorisation = null,
		fiches_autorisation_precision = null,
		nacelle_conforme = null,
		nacelle_conforme_precision = null,
		etat_outillage = null,
		etat_outillage_precision = null,
		epi = null,
		epi_precision = null,
		epi_etat = null,
		epi_etat_precision = null,
		risques_coactivite = null,
		risques_coactivite_precision = null,
		vat_chantier = null,
		vat_chantier_precision = null,
		emplacement_douches = null,
		emplacement_douches_precision = null,
		moyens_alerte = null,
		moyens_alerte_precision = null,
		localisation_extincteurs = null,
		localisation_extincteurs_precision = null,
		harnais_controle = null,
		harnais_controle_precision = null,
		cadenas_consignation = null,
		cadenas_consignation_precision = null,
		etat_vestiaires = null,
		etat_vestiaires_precision = null,
		etat_sanitaires = null,
		etat_sanitaires_precision = null,
		tri_dechets = null,
		tri_dechets_precision = null,
		emplacement_bennes = null,
		emplacement_bennes_precision = null,
		actions_non_securitaires = null,
		actions_non_securitaires_precision = null,
		actions_securitaires = null,
		actions_securitaires_precision = null,
		situations_dangereuses = null,
		situations_dangereuses_precision = null,
		photos = null,
		// step 4

		action_voie_acces_description = null,
		action_voie_acces_responsable = null,
		action_voie_acces_application = null,
		action_chantier_signalise_description = null,
		action_chantier_signalise_responsable = null,
		action_chantier_signalise_application = null,
		action_sortie_secours_libre_description = null,
		action_sortie_secours_libre_responsable = null,
		action_sortie_secours_libre_application = null,
		action_chantier_ordre_description = null,
		action_chantier_ordre_responsable = null,
		action_chantier_ordre_application = null,
		action_dta_description = null,
		action_dta_responsable = null,
		action_dta_application = null,
		action_autorisation_permis_valide_description = null,
		action_autorisation_permis_valide_responsable = null,
		action_autorisation_permis_valide_application = null,
		action_manoeuvre_consignation_description = null,
		action_manoeuvre_consignation_responsable = null,
		action_manoeuvre_consignation_application = null,
		action_permis_feu_extincteur_description = null,
		action_permis_feu_extincteur_responsable = null,
		action_permis_feu_extincteur_application = null,
		action_permis_penetrer_description = null,
		action_permis_penetrer_responsable = null,
		action_permis_penetrer_application = null,
		action_personnel_stemi_identifie_description = null,
		action_personnel_stemi_identifie_responsable = null,
		action_personnel_stemi_identifie_application = null,
		action_personnel_interim_identifie_description = null,
		action_personnel_interim_identifie_responsable = null,
		action_personnel_interim_identifie_application = null,
		action_accueils_hse_description = null,
		action_accueils_hse_responsable = null,
		action_accueils_hse_application = null,
		action_plan_prevention_description = null,
		action_plan_prevention_responsable = null,
		action_plan_prevention_application = null,
		action_analyse_risques_description = null,
		action_analyse_risques_responsable = null,
		action_analyse_risques_application = null,
		action_risques_lies_chantier_description = null,
		action_risques_lies_chantier_responsable = null,
		action_risques_lies_chantier_application = null,
		action_risques_specifiques_description = null,
		action_risques_specifiques_responsable = null,
		action_risques_specifiques_application = null,
		action_habilitations_disponibles_description = null,
		action_habilitations_disponibles_responsable = null,
		action_habilitations_disponibles_application = null,
		action_habilitations_valides_description = null,
		action_habilitations_valides_responsable = null,
		action_habilitations_valides_application = null,
		action_sst_present_description = null,
		action_sst_present_responsable = null,
		action_sst_present_application = null,
		action_echelle_etat_description = null,
		action_echelle_etat_responsable = null,
		action_echelle_etat_application = null,
		action_echafaudage_conforme_description = null,
		action_echafaudage_conforme_responsable = null,
		action_echafaudage_conforme_application = null,
		action_fiches_autorisation_description = null,
		action_fiches_autorisation_responsable = null,
		action_fiches_autorisation_application = null,
		action_nacelle_conforme_description = null,
		action_nacelle_conforme_responsable = null,
		action_nacelle_conforme_application = null,
		action_etat_outillage_description = null,
		action_etat_outillage_responsable = null,
		action_etat_outillage_application = null,
		action_epi_description = null,
		action_epi_responsable = null,
		action_epi_application = null,
		action_epi_etat_description = null,
		action_epi_etat_responsable = null,
		action_epi_etat_application = null,
		action_risques_coactivite_description = null,
		action_risques_coactivite_responsable = null,
		action_risques_coactivite_application = null,
		action_vat_chantier_description = null,
		action_vat_chantier_responsable = null,
		action_vat_chantier_application = null,
		action_emplacement_douches_description = null,
		action_emplacement_douches_responsable = null,
		action_emplacement_douches_application = null,
		action_moyens_alerte_description = null,
		action_moyens_alerte_responsable = null,
		action_moyens_alerte_application = null,
		action_localisation_extincteurs_description = null,
		action_localisation_extincteurs_responsable = null,
		action_localisation_extincteurs_application = null,
		action_harnais_controle_description = null,
		action_harnais_controle_responsable = null,
		action_harnais_controle_application = null,
		action_cadenas_consignation_description = null,
		action_cadenas_consignation_responsable = null,
		action_cadenas_consignation_application = null,
		action_etat_vestiaires_description = null,
		action_etat_vestiaires_responsable = null,
		action_etat_vestiaires_application = null,
		action_etat_sanitaires_description = null,
		action_etat_sanitaires_responsable = null,
		action_etat_sanitaires_application = null,
		action_tri_dechets_description = null,
		action_tri_dechets_responsable = null,
		action_tri_dechets_application = null,
		action_emplacement_bennes_description = null,
		action_emplacement_bennes_responsable = null,
		action_emplacement_bennes_application = null,
		// Step 5

		date_signature = null
	) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;

		// Step 1

		this.id_affaire = id_affaire;

		// Step 2

		this.nom_client = nom_client;
		this.code_client = code_client;
		this.nom_affaire = nom_affaire;
		this.responsable = responsable;
		this.nature_travaux = nature_travaux;
		this.nb_stemi = nb_stemi;
		this.nb_interim = nb_interim;
		this.personne_stemi_auditee = personne_stemi_auditee;
		this.personne_interim_auditee = personne_interim_auditee;
		this.personnes_supplementaires = personnes_supplementaires;

		// Step 2 missing

		this.nature_travaux_missing = false;
		this.nb_stemi_missing = false;
		this.nb_interim_missing = false;
		this.personne_stemi_auditee_missing = false;

		// Step 3

		this.voie_acces = voie_acces;
		this.voie_acces_precision = voie_acces_precision;
		this.chantier_signalise = chantier_signalise;
		this.chantier_signalise_precision = chantier_signalise_precision;
		this.sortie_secours_libre = sortie_secours_libre;
		this.sortie_secours_libre_precision = sortie_secours_libre_precision;
		this.chantier_ordre = chantier_ordre;
		this.chantier_ordre_precision = chantier_ordre_precision;
		this.dta = dta;
		this.dta_precision = dta_precision;
		this.autorisation_permis_valide = autorisation_permis_valide;
		this.autorisation_permis_valide_precision = autorisation_permis_valide_precision;
		this.manoeuvre_consignation = manoeuvre_consignation;
		this.manoeuvre_consignation_precision = manoeuvre_consignation_precision;
		this.permis_feu_extincteur = permis_feu_extincteur;
		this.permis_feu_extincteur_precision = permis_feu_extincteur_precision;
		this.permis_penetrer = permis_penetrer;
		this.permis_penetrer_precision = permis_penetrer_precision;
		this.personnel_stemi_identifie = personnel_stemi_identifie;
		this.personnel_stemi_identifie_precision = personnel_stemi_identifie_precision;
		this.personnel_interim_identifie = personnel_interim_identifie;
		this.personnel_interim_identifie_precision = personnel_interim_identifie_precision;
		this.accueils_hse = accueils_hse;
		this.accueils_hse_precision = accueils_hse_precision;
		this.plan_prevention = plan_prevention;
		this.plan_prevention_precision = plan_prevention_precision;
		this.analyse_risques = analyse_risques;
		this.analyse_risques_precision = analyse_risques_precision;
		this.risques_lies_chantier = risques_lies_chantier;
		this.risques_lies_chantier_precision = risques_lies_chantier_precision;
		this.risques_specifiques = risques_specifiques;
		this.risques_specifiques_precision = risques_specifiques_precision;
		this.habilitations_disponibles = habilitations_disponibles;
		this.habilitations_disponibles_precision = habilitations_disponibles_precision;
		this.habilitations_valides = habilitations_valides;
		this.habilitations_valides_precision = habilitations_valides_precision;
		this.sst_present = sst_present;
		this.sst_present_precision = sst_present_precision;
		this.echelle_etat = echelle_etat;
		this.echelle_etat_precision = echelle_etat_precision;
		this.echafaudage_conforme = echafaudage_conforme;
		this.echafaudage_conforme_precision = echafaudage_conforme_precision;
		this.fiches_autorisation = fiches_autorisation;
		this.fiches_autorisation_precision = fiches_autorisation_precision;
		this.nacelle_conforme = nacelle_conforme;
		this.nacelle_conforme_precision = nacelle_conforme_precision;
		this.etat_outillage = etat_outillage;
		this.etat_outillage_precision = etat_outillage_precision;
		this.epi = epi;
		this.epi_precision = epi_precision;
		this.epi_etat = epi_etat;
		this.epi_etat_precision = epi_etat_precision;
		this.risques_coactivite = risques_coactivite;
		this.risques_coactivite_precision = risques_coactivite_precision;
		this.vat_chantier = vat_chantier;
		this.vat_chantier_precision = vat_chantier_precision;
		this.emplacement_douches = emplacement_douches;
		this.emplacement_douches_precision = emplacement_douches_precision;
		this.moyens_alerte = moyens_alerte;
		this.moyens_alerte_precision = moyens_alerte_precision;
		this.localisation_extincteurs = localisation_extincteurs;
		this.localisation_extincteurs_precision = localisation_extincteurs_precision;
		this.harnais_controle = harnais_controle;
		this.harnais_controle_precision = harnais_controle_precision;
		this.cadenas_consignation = cadenas_consignation;
		this.cadenas_consignation_precision = cadenas_consignation_precision;
		this.etat_vestiaires = etat_vestiaires;
		this.etat_vestiaires_precision = etat_vestiaires_precision;
		this.etat_sanitaires = etat_sanitaires;
		this.etat_sanitaires_precision = etat_sanitaires_precision;
		this.tri_dechets = tri_dechets;
		this.tri_dechets_precision = tri_dechets_precision;
		this.emplacement_bennes = emplacement_bennes;
		this.emplacement_bennes_precision = emplacement_bennes_precision;
		this.actions_non_securitaires = actions_non_securitaires;
		this.actions_non_securitaires_precision = actions_non_securitaires_precision;
		this.actions_securitaires = actions_securitaires;
		this.actions_securitaires_precision = actions_securitaires_precision;
		this.situations_dangereuses = situations_dangereuses;
		this.situations_dangereuses_precision = situations_dangereuses_precision;
		this.photos = photos;

		// Step 3 missing

		this.voie_acces_missing = false;
		this.voie_acces_precision_missing = false;
		this.chantier_signalise_missing = false;
		this.chantier_signalise_precision_missing = false;
		this.sortie_secours_libre_missing = false;
		this.sortie_secours_libre_precision_missing = false;
		this.chantier_ordre_missing = false;
		this.chantier_ordre_precision_missing = false;
		this.dta_missing = false;
		this.dta_precision_missing = false;
		this.autorisation_permis_valide_missing = false;
		this.autorisation_permis_valide_precision_missing = false;
		this.manoeuvre_consignation_missing = false;
		this.manoeuvre_consignation_precision_missing = false;
		this.permis_feu_extincteur_missing = false;
		this.permis_feu_extincteur_precision_missing = false;
		this.permis_penetrer_missing = false;
		this.permis_penetrer_precision_missing = false;
		this.personnel_stemi_identifie_missing = false;
		this.personnel_stemi_identifie_precision_missing = false;
		this.personnel_interim_identifie_missing = false;
		this.personnel_interim_identifie_precision_missing = false;
		this.accueils_hse_missing = false;
		this.accueils_hse_precision_missing = false;
		this.plan_prevention_missing = false;
		this.plan_prevention_precision_missing = false;
		this.risques_lies_chantier_missing = false;
		this.risques_lies_chantier_precision_missing = false;
		this.risques_specifiques_missing = false;
		this.risques_specifiques_precision_missing = false;
		this.habilitations_disponibles_missing = false;
		this.habilitations_disponibles_precision_missing = false;
		this.habilitations_valides_missing = false;
		this.habilitations_valides_precision_missing = false;
		this.sst_present_missing = false;
		this.sst_present_precision_missing = false;
		this.echelle_etat_missing = false;
		this.echelle_etat_precision_missing = false;
		this.echafaudage_conforme_missing = false;
		this.echafaudage_conforme_precision_missing = false;
		this.fiches_autorisation_missing = false;
		this.fiches_autorisation_precision_missing = false;
		this.nacelle_conforme_missing = false;
		this.nacelle_conforme_precision_missing = false;
		this.etat_outillage_missing = false;
		this.etat_outillage_precision_missing = false;
		this.epi_missing = false;
		this.epi_precision_missing = false;
		this.epi_etat_missing = false;
		this.epi_etat_precision_missing = false;
		this.risques_coactivite_missing = false;
		this.risques_coactivite_precision_missing = false;
		this.vat_chantier_missing = false;
		this.vat_chantier_precision_missing = false;
		this.emplacement_douches_missing = false;
		this.emplacement_douches_precision_missing = false;
		this.moyens_alerte_missing = false;
		this.moyens_alerte_precision_missing = false;
		this.localisation_extincteurs_missing = false;
		this.localisation_extincteurs_precision_missing = false;
		this.harnais_controle_missing = false;
		this.harnais_controle_precision_missing = false;
		this.cadenas_consignation_missing = false;
		this.cadenas_consignation_precision_missing = false;
		this.etat_vestiaires_missing = false;
		this.etat_vestiaires_precision_missing = false;
		this.etat_sanitaires_missing = false;
		this.etat_sanitaires_precision_missing = false;
		this.tri_dechets_missing = false;
		this.tri_dechets_precision_missing = false;
		this.emplacement_bennes_missing = false;
		this.emplacement_bennes_precision_missing = false;
		this.actions_non_securitaires_missing = false;
		this.actions_non_securitaires_precision_missing = false;
		this.actions_securitaires_missing = false;
		this.actions_securitaires_precision_missing = false;
		this.situations_dangereuses_missing = false;
		this.situations_dangereuses_precision_missing = false;

		// Step 4

		this.action_voie_acces_description = action_voie_acces_description;
		this.action_voie_acces_responsable = action_voie_acces_responsable;
		this.action_voie_acces_application = action_voie_acces_application;
		this.action_chantier_signalise_description = action_chantier_signalise_description;
		this.action_chantier_signalise_responsable = action_chantier_signalise_responsable;
		this.action_chantier_signalise_application = action_chantier_signalise_application;
		this.action_sortie_secours_libre_description = action_sortie_secours_libre_description;
		this.action_sortie_secours_libre_responsable = action_sortie_secours_libre_responsable;
		this.action_sortie_secours_libre_application = action_sortie_secours_libre_application;
		this.action_chantier_ordre_description = action_chantier_ordre_description;
		this.action_chantier_ordre_responsable = action_chantier_ordre_responsable;
		this.action_chantier_ordre_application = action_chantier_ordre_application;
		this.action_dta_description = action_dta_description;
		this.action_dta_responsable = action_dta_responsable;
		this.action_dta_application = action_dta_application;
		this.action_autorisation_permis_valide_description = action_autorisation_permis_valide_description;
		this.action_autorisation_permis_valide_responsable = action_autorisation_permis_valide_responsable;
		this.action_autorisation_permis_valide_application = action_autorisation_permis_valide_application;
		this.action_manoeuvre_consignation_description = action_manoeuvre_consignation_description;
		this.action_manoeuvre_consignation_responsable = action_manoeuvre_consignation_responsable;
		this.action_manoeuvre_consignation_application = action_manoeuvre_consignation_application;
		this.action_permis_feu_extincteur_description = action_permis_feu_extincteur_description;
		this.action_permis_feu_extincteur_responsable = action_permis_feu_extincteur_responsable;
		this.action_permis_feu_extincteur_application = action_permis_feu_extincteur_application;
		this.action_permis_penetrer_description = action_permis_penetrer_description;
		this.action_permis_penetrer_responsable = action_permis_penetrer_responsable;
		this.action_permis_penetrer_application = action_permis_penetrer_application;
		this.action_personnel_stemi_identifie_description = action_personnel_stemi_identifie_description;
		this.action_personnel_stemi_identifie_responsable = action_personnel_stemi_identifie_responsable;
		this.action_personnel_stemi_identifie_application = action_personnel_stemi_identifie_application;
		this.action_personnel_interim_identifie_description = action_personnel_interim_identifie_description;
		this.action_personnel_interim_identifie_responsable = action_personnel_interim_identifie_responsable;
		this.action_personnel_interim_identifie_application = action_personnel_interim_identifie_application;
		this.action_accueils_hse_description = action_accueils_hse_description;
		this.action_accueils_hse_responsable = action_accueils_hse_responsable;
		this.action_accueils_hse_application = action_accueils_hse_application;
		this.action_plan_prevention_description = action_plan_prevention_description;
		this.action_plan_prevention_responsable = action_plan_prevention_responsable;
		this.action_plan_prevention_application = action_plan_prevention_application;
		this.action_analyse_risques_description = action_analyse_risques_description;
		this.action_analyse_risques_responsable = action_analyse_risques_responsable;
		this.action_analyse_risques_application = action_analyse_risques_application;
		this.action_risques_lies_chantier_description = action_risques_lies_chantier_description;
		this.action_risques_lies_chantier_responsable = action_risques_lies_chantier_responsable;
		this.action_risques_lies_chantier_application = action_risques_lies_chantier_application;
		this.action_risques_specifiques_description = action_risques_specifiques_description;
		this.action_risques_specifiques_responsable = action_risques_specifiques_responsable;
		this.action_risques_specifiques_application = action_risques_specifiques_application;
		this.action_habilitations_disponibles_description = action_habilitations_disponibles_description;
		this.action_habilitations_disponibles_responsable = action_habilitations_disponibles_responsable;
		this.action_habilitations_disponibles_application = action_habilitations_disponibles_application;
		this.action_habilitations_valides_description = action_habilitations_valides_description;
		this.action_habilitations_valides_responsable = action_habilitations_valides_responsable;
		this.action_habilitations_valides_application = action_habilitations_valides_application;
		this.action_sst_present_description = action_sst_present_description;
		this.action_sst_present_responsable = action_sst_present_responsable;
		this.action_sst_present_application = action_sst_present_application;
		this.action_echelle_etat_description = action_echelle_etat_description;
		this.action_echelle_etat_responsable = action_echelle_etat_responsable;
		this.action_echelle_etat_application = action_echelle_etat_application;
		this.action_echafaudage_conforme_description = action_echafaudage_conforme_description;
		this.action_echafaudage_conforme_responsable = action_echafaudage_conforme_responsable;
		this.action_echafaudage_conforme_application = action_echafaudage_conforme_application;
		this.action_fiches_autorisation_description = action_fiches_autorisation_description;
		this.action_fiches_autorisation_responsable = action_fiches_autorisation_responsable;
		this.action_fiches_autorisation_application = action_fiches_autorisation_application;
		this.action_nacelle_conforme_description = action_nacelle_conforme_description;
		this.action_nacelle_conforme_responsable = action_nacelle_conforme_responsable;
		this.action_nacelle_conforme_application = action_nacelle_conforme_application;
		this.action_etat_outillage_description = action_etat_outillage_description;
		this.action_etat_outillage_responsable = action_etat_outillage_responsable;
		this.action_etat_outillage_application = action_etat_outillage_application;
		this.action_epi_description = action_epi_description;
		this.action_epi_responsable = action_epi_responsable;
		this.action_epi_application = action_epi_application;
		this.action_epi_etat_description = action_epi_etat_description;
		this.action_epi_etat_responsable = action_epi_etat_responsable;
		this.action_epi_etat_application = action_epi_etat_application;
		this.action_risques_coactivite_description = action_risques_coactivite_description;
		this.action_risques_coactivite_responsable = action_risques_coactivite_responsable;
		this.action_risques_coactivite_application = action_risques_coactivite_application;
		this.action_vat_chantier_description = action_vat_chantier_description;
		this.action_vat_chantier_responsable = action_vat_chantier_responsable;
		this.action_vat_chantier_application = action_vat_chantier_application;
		this.action_emplacement_douches_description = action_emplacement_douches_description;
		this.action_emplacement_douches_responsable = action_emplacement_douches_responsable;
		this.action_emplacement_douches_application = action_emplacement_douches_application;
		this.action_moyens_alerte_description = action_moyens_alerte_description;
		this.action_moyens_alerte_responsable = action_moyens_alerte_responsable;
		this.action_moyens_alerte_application = action_moyens_alerte_application;
		this.action_localisation_extincteurs_description = action_localisation_extincteurs_description;
		this.action_localisation_extincteurs_responsable = action_localisation_extincteurs_responsable;
		this.action_localisation_extincteurs_application = action_localisation_extincteurs_application;
		this.action_harnais_controle_description = action_harnais_controle_description;
		this.action_harnais_controle_responsable = action_harnais_controle_responsable;
		this.action_harnais_controle_application = action_harnais_controle_application;
		this.action_cadenas_consignation_description = action_cadenas_consignation_description;
		this.action_cadenas_consignation_responsable = action_cadenas_consignation_responsable;
		this.action_cadenas_consignation_application = action_cadenas_consignation_application;
		this.action_etat_vestiaires_description = action_etat_vestiaires_description;
		this.action_etat_vestiaires_responsable = action_etat_vestiaires_responsable;
		this.action_etat_vestiaires_application = action_etat_vestiaires_application;
		this.action_etat_sanitaires_description = action_etat_sanitaires_description;
		this.action_etat_sanitaires_responsable = action_etat_sanitaires_responsable;
		this.action_etat_sanitaires_application = action_etat_sanitaires_application;
		this.action_tri_dechets_description = action_tri_dechets_description;
		this.action_tri_dechets_responsable = action_tri_dechets_responsable;
		this.action_tri_dechets_application = action_tri_dechets_application;
		this.action_emplacement_bennes_description = action_emplacement_bennes_description;
		this.action_emplacement_bennes_responsable = action_emplacement_bennes_responsable;
		this.action_emplacement_bennes_application = action_emplacement_bennes_application;

		// Step 4 missing

		this.action_voie_acces_description_missing = false;
		this.action_voie_acces_responsable_missing = false;
		this.action_voie_acces_application_missing = false;
		this.action_chantier_signalise_description_missing = false;
		this.action_chantier_signalise_responsable_missing = false;
		this.action_chantier_signalise_application_missing = false;
		this.action_sortie_secours_libre_description_missing = false;
		this.action_sortie_secours_libre_responsable_missing = false;
		this.action_sortie_secours_libre_application_missing = false;
		this.action_chantier_ordre_description_missing = false;
		this.action_chantier_ordre_responsable_missing = false;
		this.action_chantier_ordre_application_missing = false;
		this.action_dta_description_missing = false;
		this.action_dta_responsable_missing = false;
		this.action_dta_application_missing = false;
		this.action_autorisation_permis_valide_description_missing = false;
		this.action_autorisation_permis_valide_responsable_missing = false;
		this.action_autorisation_permis_valide_application_missing = false;
		this.action_manoeuvre_consignation_description_missing = false;
		this.action_manoeuvre_consignation_responsable_missing = false;
		this.action_manoeuvre_consignation_application_missing = false;
		this.action_permis_feu_extincteur_description_missing = false;
		this.action_permis_feu_extincteur_responsable_missing = false;
		this.action_permis_feu_extincteur_application_missing = false;
		this.action_permis_penetrer_description_missing = false;
		this.action_permis_penetrer_responsable_missing = false;
		this.action_permis_penetrer_application_missing = false;
		this.action_personnel_stemi_identifie_description_missing = false;
		this.action_personnel_stemi_identifie_responsable_missing = false;
		this.action_personnel_stemi_identifie_application_missing = false;
		this.action_personnel_interim_identifie_description_missing = false;
		this.action_personnel_interim_identifie_responsable_missing = false;
		this.action_personnel_interim_identifie_application_missing = false;
		this.action_accueils_hse_description_missing = false;
		this.action_accueils_hse_responsable_missing = false;
		this.action_accueils_hse_application_missing = false;
		this.action_plan_prevention_description_missing = false;
		this.action_plan_prevention_responsable_missing = false;
		this.action_plan_prevention_application_missing = false;
		this.action_risques_lies_chantier_description_missing = false;
		this.action_risques_lies_chantier_responsable_missing = false;
		this.action_risques_lies_chantier_application_missing = false;
		this.action_risques_specifiques_description_missing = false;
		this.action_risques_specifiques_responsable_missing = false;
		this.action_risques_specifiques_application_missing = false;
		this.action_habilitations_disponibles_description_missing = false;
		this.action_habilitations_disponibles_responsable_missing = false;
		this.action_habilitations_disponibles_application_missing = false;
		this.action_habilitations_valides_description_missing = false;
		this.action_habilitations_valides_responsable_missing = false;
		this.action_habilitations_valides_application_missing = false;
		this.action_sst_present_description_missing = false;
		this.action_sst_present_responsable_missing = false;
		this.action_sst_present_application_missing = false;
		this.action_echelle_etat_description_missing = false;
		this.action_echelle_etat_responsable_missing = false;
		this.action_echelle_etat_application_missing = false;
		this.action_echafaudage_conforme_description_missing = false;
		this.action_echafaudage_conforme_responsable_missing = false;
		this.action_echafaudage_conforme_application_missing = false;
		this.action_fiches_autorisation_description_missing = false;
		this.action_fiches_autorisation_responsable_missing = false;
		this.action_fiches_autorisation_application_missing = false;
		this.action_nacelle_conforme_description_missing = false;
		this.action_nacelle_conforme_responsable_missing = false;
		this.action_nacelle_conforme_application_missing = false;
		this.action_etat_outillage_description_missing = false;
		this.action_etat_outillage_responsable_missing = false;
		this.action_etat_outillage_application_missing = false;
		this.action_epi_description_missing = false;
		this.action_epi_responsable_missing = false;
		this.action_epi_application_missing = false;
		this.action_epi_etat_description_missing = false;
		this.action_epi_etat_responsable_missing = false;
		this.action_epi_etat_application_missing = false;
		this.action_risques_coactivite_description_missing = false;
		this.action_risques_coactivite_responsable_missing = false;
		this.action_risques_coactivite_application_missing = false;
		this.action_vat_chantier_description_missing = false;
		this.action_vat_chantier_responsable_missing = false;
		this.action_vat_chantier_application_missing = false;
		this.action_emplacement_douches_description_missing = false;
		this.action_emplacement_douches_responsable_missing = false;
		this.action_emplacement_douches_application_missing = false;
		this.action_moyens_alerte_description_missing = false;
		this.action_moyens_alerte_responsable_missing = false;
		this.action_moyens_alerte_application_missing = false;
		this.action_localisation_extincteurs_description_missing = false;
		this.action_localisation_extincteurs_responsable_missing = false;
		this.action_localisation_extincteurs_application_missing = false;
		this.action_harnais_controle_description_missing = false;
		this.action_harnais_controle_responsable_missing = false;
		this.action_harnais_controle_application_missing = false;
		this.action_cadenas_consignation_description_missing = false;
		this.action_cadenas_consignation_responsable_missing = false;
		this.action_cadenas_consignation_application_missing = false;
		this.action_etat_vestiaires_description_missing = false;
		this.action_etat_vestiaires_responsable_missing = false;
		this.action_etat_vestiaires_application_missing = false;
		this.action_etat_sanitaires_description_missing = false;
		this.action_etat_sanitaires_responsable_missing = false;
		this.action_etat_sanitaires_application_missing = false;
		this.action_tri_dechets_description_missing = false;
		this.action_tri_dechets_responsable_missing = false;
		this.action_tri_dechets_application_missing = false;
		this.action_emplacement_bennes_description_missing = false;
		this.action_emplacement_bennes_responsable_missing = false;
		this.action_emplacement_bennes_application_missing = false;

		// Step 5

		this.date_signature = date_signature;
	}

	clearRelated(source) {
		switch (source) {
			case 'voie_acces' :
				if (this.voie_acces !== 'NC') {
					this.voie_acces_precision = null;
					this.action_voie_acces_description = null;
					this.action_voie_acces_responsable = null;
					this.action_voie_acces_application = null;
				}
				break;
			case 'chantier_signalise' :
				if (this.chantier_signalise !== 'NC') {
					this.chantier_signalise_precision = null;
					this.action_chantier_signalise_description = null;
					this.action_chantier_signalise_responsable = null;
					this.action_chantier_signalise_application = null;
				}
				break;
			case 'sortie_secours_libre' :
				if (this.sortie_secours_libre !== 'NC') {
					this.sortie_secours_libre_precision = null;
					this.action_sortie_secours_libre_description = null;
					this.action_sortie_secours_libre_responsable = null;
					this.action_sortie_secours_libre_application = null;
				}
				break;
			case 'chantier_ordre' :
				if (this.chantier_ordre !== 'NC') {
					this.chantier_ordre_precision = null;
					this.action_chantier_ordre_description = null;
					this.action_chantier_ordre_responsable = null;
					this.action_chantier_ordre_application = null;
				}
				break;
			case 'dta' :
				if (this.dta !== 'NC') {
					this.dta_precision = null;
					this.action_dta_description = null;
					this.action_dta_responsable = null;
					this.action_dta_application = null;
				}
				break;
			case 'autorisation_permis_valide' :
				if (this.autorisation_permis_valide !== 'NC') {
					this.autorisation_permis_valide_precision = null;
					this.action_autorisation_permis_valide_description = null;
					this.action_autorisation_permis_valide_responsable = null;
					this.action_autorisation_permis_valide_application = null;
				}
				break;
			case 'manoeuvre_consignation' :
				if (this.manoeuvre_consignation !== 'NC') {
					this.manoeuvre_consignation_precision = null;
					this.action_manoeuvre_consignation_description = null;
					this.action_manoeuvre_consignation_responsable = null;
					this.action_manoeuvre_consignation_application = null;
				}
				break;
			case 'permis_feu_extincteur' :
				if (this.permis_feu_extincteur !== 'NC') {
					this.permis_feu_extincteur_precision = null;
					this.action_permis_feu_extincteur_description = null;
					this.action_permis_feu_extincteur_responsable = null;
					this.action_permis_feu_extincteur_application = null;
				}
				break;
			case 'permis_penetrer' :
				if (this.permis_penetrer !== 'NC') {
					this.permis_penetrer_precision = null;
					this.action_permis_penetrer_description = null;
					this.action_permis_penetrer_responsable = null;
					this.action_permis_penetrer_application = null;
				}
				break;
			case 'personnel_stemi_identifie' :
				if (this.personnel_stemi_identifie !== 'NC') {
					this.personnel_stemi_identifie_precision = null;
					this.action_personnel_stemi_identifie_description = null;
					this.action_personnel_stemi_identifie_responsable = null;
					this.action_personnel_stemi_identifie_application = null;
				}
				break;
			case 'personnel_interim_identifie' :
				if (this.personnel_interim_identifie !== 'NC') {
					this.personnel_interim_identifie_precision = null;
					this.action_personnel_interim_identifie_description = null;
					this.action_personnel_interim_identifie_responsable = null;
					this.action_personnel_interim_identifie_application = null;
				}
				break;
			case 'accueils_hse' :
				if (this.accueils_hse !== 'NC') {
					this.accueils_hse_precision = null;
					this.action_accueils_hse_description = null;
					this.action_accueils_hse_responsable = null;
					this.action_accueils_hse_application = null;
				}
				break;
			case 'plan_prevention' :
				if (this.plan_prevention !== 'NC') {
					this.plan_prevention_precision = null;
					this.action_plan_prevention_description = null;
					this.action_plan_prevention_responsable = null;
					this.action_plan_prevention_application = null;
				}
				break;
			case 'risques_lies_chantier' :
				if (this.risques_lies_chantier !== 'NC') {
					this.risques_lies_chantier_precision = null;
					this.action_risques_lies_chantier_description = null;
					this.action_risques_lies_chantier_responsable = null;
					this.action_risques_lies_chantier_application = null;
				}
				break;
			case 'risques_specifiques' :
				if (this.risques_specifiques !== 'NC') {
					this.risques_specifiques_precision = null;
					this.action_risques_specifiques_description = null;
					this.action_risques_specifiques_responsable = null;
					this.action_risques_specifiques_application = null;
				}
				break;
			case 'habilitations_disponibles' :
				if (this.habilitations_disponibles !== 'NC') {
					this.habilitations_disponibles_precision = null;
					this.action_habilitations_disponibles_description = null;
					this.action_habilitations_disponibles_responsable = null;
					this.action_habilitations_disponibles_application = null;
				}
				break;
			case 'habilitations_valides' :
				if (this.habilitations_valides !== 'NC') {
					this.habilitations_valides_precision = null;
					this.action_habilitations_valides_description = null;
					this.action_habilitations_valides_responsable = null;
					this.action_habilitations_valides_application = null;
				}
				break;
			case 'sst_present' :
				if (this.sst_present !== 'NC') {
					this.sst_present_precision = null;
					this.action_sst_present_description = null;
					this.action_sst_present_responsable = null;
					this.action_sst_present_application = null;
				}
				break;
			case 'echelle_etat' :
				if (this.echelle_etat !== 'NC') {
					this.echelle_etat_precision = null;
					this.action_echelle_etat_description = null;
					this.action_echelle_etat_responsable = null;
					this.action_echelle_etat_application = null;
				}
				break;
			case 'echafaudage_conforme' :
				if (this.echafaudage_conforme !== 'NC') {
					this.echafaudage_conforme_precision = null;
					this.action_echafaudage_conforme_description = null;
					this.action_echafaudage_conforme_responsable = null;
					this.action_echafaudage_conforme_application = null;
				}
				break;
			case 'fiches_autorisation' :
				if (this.fiches_autorisation !== 'NC') {
					this.fiches_autorisation_precision = null;
					this.action_fiches_autorisation_description = null;
					this.action_fiches_autorisation_responsable = null;
					this.action_fiches_autorisation_application = null;
				}
				break;
			case 'nacelle_conforme' :
				if (this.nacelle_conforme !== 'NC') {
					this.nacelle_conforme_precision = null;
					this.action_nacelle_conforme_description = null;
					this.action_nacelle_conforme_responsable = null;
					this.action_nacelle_conforme_application = null;
				}
				break;
			case 'etat_outillage' :
				if (this.etat_outillage !== 'NC') {
					this.etat_outillage_precision = null;
					this.action_etat_outillage_description = null;
					this.action_etat_outillage_responsable = null;
					this.action_etat_outillage_application = null;
				}
				break;
			case 'epi' :
				if (this.epi !== 'NC') {
					this.epi_precision = null;
					this.action_epi_description = null;
					this.action_epi_responsable = null;
					this.action_epi_application = null;
				}
				break;
			case 'epi_etat' :
				if (this.epi_etat !== 'NC') {
					this.epi_etat_precision = null;
					this.action_epi_etat_description = null;
					this.action_epi_etat_responsable = null;
					this.action_epi_etat_application = null;
				}
				break;
			case 'risques_coactivite' :
				if (this.risques_coactivite !== 'NC') {
					this.risques_coactivite_precision = null;
					this.action_risques_coactivite_description = null;
					this.action_risques_coactivite_responsable = null;
					this.action_risques_coactivite_application = null;
				}
				break;
			case 'vat_chantier' :
				if (this.vat_chantier !== 'NC') {
					this.vat_chantier_precision = null;
					this.action_vat_chantier_description = null;
					this.action_vat_chantier_responsable = null;
					this.action_vat_chantier_application = null;
				}
				break;
			case 'emplacement_douches' :
				if (this.emplacement_douches !== 'NC') {
					this.emplacement_douches_precision = null;
					this.action_emplacement_douches_description = null;
					this.action_emplacement_douches_responsable = null;
					this.action_emplacement_douches_application = null;
				}
				break;
			case 'moyens_alerte' :
				if (this.moyens_alerte !== 'NC') {
					this.moyens_alerte_precision = null;
					this.action_moyens_alerte_description = null;
					this.action_moyens_alerte_responsable = null;
					this.action_moyens_alerte_application = null;
				}
				break;
			case 'localisation_extincteurs' :
				if (this.localisation_extincteurs !== 'NC') {
					this.localisation_extincteurs_precision = null;
					this.action_localisation_extincteurs_description = null;
					this.action_localisation_extincteurs_responsable = null;
					this.action_localisation_extincteurs_application = null;
				}
				break;
			case 'harnais_controle' :
				if (this.harnais_controle !== 'NC') {
					this.harnais_controle_precision = null;
					this.action_harnais_controle_description = null;
					this.action_harnais_controle_responsable = null;
					this.action_harnais_controle_application = null;
				}
				break;
			case 'cadenas_consignation' :
				if (this.cadenas_consignation !== 'NC') {
					this.cadenas_consignation_precision = null;
					this.action_cadenas_consignation_description = null;
					this.action_cadenas_consignation_responsable = null;
					this.action_cadenas_consignation_application = null;
				}
				break;
			case 'etat_vestiaires' :
				if (this.etat_vestiaires !== 'NC') {
					this.etat_vestiaires_precision = null;
					this.action_etat_vestiaires_description = null;
					this.action_etat_vestiaires_responsable = null;
					this.action_etat_vestiaires_application = null;
				}
				break;
			case 'etat_sanitaires' :
				if (this.etat_sanitaires !== 'NC') {
					this.etat_sanitaires_precision = null;
					this.action_etat_sanitaires_description = null;
					this.action_etat_sanitaires_responsable = null;
					this.action_etat_sanitaires_application = null;
				}
				break;
			case 'tri_dechets' :
				if (this.tri_dechets !== 'NC') {
					this.tri_dechets_precision = null;
					this.action_tri_dechets_description = null;
					this.action_tri_dechets_responsable = null;
					this.action_tri_dechets_application = null;
				}
				break;
			case 'emplacement_bennes' :
				if (this.emplacement_bennes !== 'NC') {
					this.emplacement_bennes_precision = null;
					this.action_emplacement_bennes_description = null;
					this.action_emplacement_bennes_responsable = null;
					this.action_emplacement_bennes_application = null;
				}
				break;
			case 'actions_non_securitaires' :
				if (this.actions_non_securitaires !== 'NC') {
					this.actions_non_securitaires_precision = null;
				}
				break;
			case 'actions_securitaires' :
				if (this.actions_securitaires !== 'NC') {
					this.actions_securitaires_precision = null;
				}
				break;
			case 'situations_dangereuses' :
				if (this.situations_dangereuses !== 'NC') {
					this.situations_dangereuses_precision = null;
				}
				break;
			default :
				break;
		}
	}

	missingMandatoryFields(step) {
		let missingMandatory = false;
		switch (step) {
			case 2 :
				if (this.nature_travaux == null || this.nature_travaux === '') {
					this.nature_travaux_missing = true;
					missingMandatory = true;
				}
				if (this.nb_stemi == null || this.nb_stemi.toString() === '') {
					this.nb_stemi_missing = true;
					missingMandatory = true;
				}
				if (this.nb_interim == null || this.nb_interim.toString() === '') {
					this.nb_interim_missing = true;
					missingMandatory = true;
				}
				if (this.personne_stemi_auditee == null || this.personne_stemi_auditee.length === 0) {
					this.personne_stemi_auditee_missing = true;
					missingMandatory = true;
				}
				for (const i in this.personnes_supplementaires) {
					if (this.personnes_supplementaires[i].societe === null || this.personnes_supplementaires[i].societe === '') {
						this.personnes_supplementaires[i].societe_missing = true;
						missingMandatory = true;
					}
					if (this.personnes_supplementaires[i].nom === null || this.personnes_supplementaires[i].nom === '') {
						this.personnes_supplementaires[i].nom_missing = true;
						missingMandatory = true;
					}
					if (this.personnes_supplementaires[i].type === null || this.personnes_supplementaires[i].type === '') {
						this.personnes_supplementaires[i].type_missing = true;
						missingMandatory = true;
					}
				}
				break;
			case 3 :
				if (this.voie_acces == null) {
					this.voie_acces_missing = true;
					missingMandatory = true;
				}
				if (this.voie_acces === 'NC' && (this.voie_acces_precision == null || this.voie_acces_precision === '')) {
					this.voie_acces_precision_missing = true;
					missingMandatory = true;
				}
				if (this.chantier_signalise == null) {
					this.chantier_signalise_missing = true;
					missingMandatory = true;
				}
				if (this.chantier_signalise === 'NC' && (this.chantier_signalise_precision == null || this.chantier_signalise_precision === '')) {
					this.chantier_signalise_precision_missing = true;
					missingMandatory = true;
				}
				if (this.sortie_secours_libre == null) {
					this.sortie_secours_libre_missing = true;
					missingMandatory = true;
				}
				if (this.sortie_secours_libre === 'NC' && (this.sortie_secours_libre_precision == null || this.sortie_secours_libre_precision === '')) {
					this.sortie_secours_libre_precision_missing = true;
					missingMandatory = true;
				}
				if (this.chantier_ordre == null) {
					this.chantier_ordre_missing = true;
					missingMandatory = true;
				}
				if (this.chantier_ordre === 'NC' && (this.chantier_ordre_precision == null || this.chantier_ordre_precision === '')) {
					this.chantier_ordre_precision_missing = true;
					missingMandatory = true;
				}
				if (this.dta == null) {
					this.dta_missing = true;
					missingMandatory = true;
				}
				if (this.dta === 'NC' && (this.dta_precision == null || this.dta_precision === '')) {
					this.dta_precision_missing = true;
					missingMandatory = true;
				}
				if (this.autorisation_permis_valide == null) {
					this.autorisation_permis_valide_missing = true;
					missingMandatory = true;
				}
				if (this.autorisation_permis_valide === 'NC' && (this.autorisation_permis_valide_precision == null || this.autorisation_permis_valide_precision === '')) {
					this.autorisation_permis_valide_precision_missing = true;
					missingMandatory = true;
				}
				if (this.manoeuvre_consignation == null) {
					this.manoeuvre_consignation_missing = true;
					missingMandatory = true;
				}
				if (this.manoeuvre_consignation === 'NC' && (this.manoeuvre_consignation_precision == null || this.manoeuvre_consignation_precision === '')) {
					this.manoeuvre_consignation_precision_missing = true;
					missingMandatory = true;
				}
				if (this.permis_feu_extincteur == null) {
					this.permis_feu_extincteur_missing = true;
					missingMandatory = true;
				}
				if (this.permis_feu_extincteur === 'NC' && (this.permis_feu_extincteur_precision == null || this.permis_feu_extincteur_precision === '')) {
					this.permis_feu_extincteur_precision_missing = true;
					missingMandatory = true;
				}
				if (this.permis_penetrer == null) {
					this.permis_penetrer_missing = true;
					missingMandatory = true;
				}
				if (this.permis_penetrer === 'NC' && (this.permis_penetrer_precision == null || this.permis_penetrer_precision === '')) {
					this.permis_penetrer_precision_missing = true;
					missingMandatory = true;
				}
				if (this.personnel_stemi_identifie == null) {
					this.personnel_stemi_identifie_missing = true;
					missingMandatory = true;
				}
				if (this.personnel_stemi_identifie === 'NC' && (this.personnel_stemi_identifie_precision == null || this.personnel_stemi_identifie_precision === '')) {
					this.personnel_stemi_identifie_precision_missing = true;
					missingMandatory = true;
				}
				if (this.personnel_interim_identifie == null) {
					this.personnel_interim_identifie_missing = true;
					missingMandatory = true;
				}
				if (this.personnel_interim_identifie === 'NC' && (this.personnel_interim_identifie_precision == null || this.personnel_interim_identifie_precision === '')) {
					this.personnel_interim_identifie_precision_missing = true;
					missingMandatory = true;
				}
				if (this.accueils_hse == null) {
					this.accueils_hse_missing = true;
					missingMandatory = true;
				}
				if (this.accueils_hse === 'NC' && (this.accueils_hse_precision == null || this.accueils_hse_precision === '')) {
					this.accueils_hse_precision_missing = true;
					missingMandatory = true;
				}
				if (this.plan_prevention == null) {
					this.plan_prevention_missing = true;
					missingMandatory = true;
				}
				if (this.plan_prevention === 'NC' && (this.plan_prevention_precision == null || this.plan_prevention_precision === '')) {
					this.plan_prevention_precision_missing = true;
					missingMandatory = true;
				}
				if (this.risques_lies_chantier == null) {
					this.risques_lies_chantier_missing = true;
					missingMandatory = true;
				}
				if (this.risques_lies_chantier === 'NC' && (this.risques_lies_chantier_precision == null || this.risques_lies_chantier_precision === '')) {
					this.risques_lies_chantier_precision_missing = true;
					missingMandatory = true;
				}
				if (this.risques_specifiques == null) {
					this.risques_specifiques_missing = true;
					missingMandatory = true;
				}
				if (this.risques_specifiques === 'NC' && (this.risques_specifiques_precision == null || this.risques_specifiques_precision === '')) {
					this.risques_specifiques_precision_missing = true;
					missingMandatory = true;
				}
				if (this.habilitations_disponibles == null) {
					this.habilitations_disponibles_missing = true;
					missingMandatory = true;
				}
				if (this.habilitations_disponibles === 'NC' && (this.habilitations_disponibles_precision == null || this.habilitations_disponibles_precision === '')) {
					this.habilitations_disponibles_precision_missing = true;
					missingMandatory = true;
				}
				if (this.habilitations_valides == null) {
					this.habilitations_valides_missing = true;
					missingMandatory = true;
				}
				if (this.habilitations_valides === 'NC' && (this.habilitations_valides_precision == null || this.habilitations_valides_precision === '')) {
					this.habilitations_valides_precision_missing = true;
					missingMandatory = true;
				}
				if (this.sst_present == null) {
					this.sst_present_missing = true;
					missingMandatory = true;
				}
				if (this.sst_present === 'NC' && (this.sst_present_precision == null || this.sst_present_precision === '')) {
					this.sst_present_precision_missing = true;
					missingMandatory = true;
				}
				if (this.echelle_etat == null) {
					this.echelle_etat_missing = true;
					missingMandatory = true;
				}
				if (this.echelle_etat === 'NC' && (this.echelle_etat_precision == null || this.echelle_etat_precision === '')) {
					this.echelle_etat_precision_missing = true;
					missingMandatory = true;
				}
				if (this.echafaudage_conforme == null) {
					this.echafaudage_conforme_missing = true;
					missingMandatory = true;
				}
				if (this.echafaudage_conforme === 'NC' && (this.echafaudage_conforme_precision == null || this.echafaudage_conforme_precision === '')) {
					this.echafaudage_conforme_precision_missing = true;
					missingMandatory = true;
				}
				if (this.fiches_autorisation == null) {
					this.fiches_autorisation_missing = true;
					missingMandatory = true;
				}
				if (this.fiches_autorisation === 'NC' && (this.fiches_autorisation_precision == null || this.fiches_autorisation_precision === '')) {
					this.fiches_autorisation_precision_missing = true;
					missingMandatory = true;
				}
				if (this.nacelle_conforme == null) {
					this.nacelle_conforme_missing = true;
					missingMandatory = true;
				}
				if (this.nacelle_conforme === 'NC' && (this.nacelle_conforme_precision == null || this.nacelle_conforme_precision === '')) {
					this.nacelle_conforme_precision_missing = true;
					missingMandatory = true;
				}
				if (this.etat_outillage == null) {
					this.etat_outillage_missing = true;
					missingMandatory = true;
				}
				if (this.etat_outillage === 'NC' && (this.etat_outillage_precision == null || this.etat_outillage_precision === '')) {
					this.etat_outillage_precision_missing = true;
					missingMandatory = true;
				}
				if (this.epi == null) {
					this.epi_missing = true;
					missingMandatory = true;
				}
				if (this.epi === 'NC' && (this.epi_precision == null || this.epi_precision === '')) {
					this.epi_precision_missing = true;
					missingMandatory = true;
				}
				if (this.epi_etat == null) {
					this.epi_etat_missing = true;
					missingMandatory = true;
				}
				if (this.epi_etat === 'NC' && (this.epi_etat_precision == null || this.epi_etat_precision === '')) {
					this.epi_etat_precision_missing = true;
					missingMandatory = true;
				}
				if (this.risques_coactivite == null) {
					this.risques_coactivite_missing = true;
					missingMandatory = true;
				}
				if (this.risques_coactivite === 'NC' && (this.risques_coactivite_precision == null || this.risques_coactivite_precision === '')) {
					this.risques_coactivite_precision_missing = true;
					missingMandatory = true;
				}
				if (this.vat_chantier == null) {
					this.vat_chantier_missing = true;
					missingMandatory = true;
				}
				if (this.vat_chantier === 'NC' && (this.vat_chantier_precision == null || this.vat_chantier_precision === '')) {
					this.vat_chantier_precision_missing = true;
					missingMandatory = true;
				}
				if (this.emplacement_douches == null) {
					this.emplacement_douches_missing = true;
					missingMandatory = true;
				}
				if (this.emplacement_douches === 'NC' && (this.emplacement_douches_precision == null || this.emplacement_douches_precision === '')) {
					this.emplacement_douches_precision_missing = true;
					missingMandatory = true;
				}
				if (this.moyens_alerte == null) {
					this.moyens_alerte_missing = true;
					missingMandatory = true;
				}
				if (this.moyens_alerte === 'NC' && (this.moyens_alerte_precision == null || this.moyens_alerte_precision === '')) {
					this.moyens_alerte_precision_missing = true;
					missingMandatory = true;
				}
				if (this.localisation_extincteurs == null) {
					this.localisation_extincteurs_missing = true;
					missingMandatory = true;
				}
				if (this.localisation_extincteurs === 'NC' && (this.localisation_extincteurs_precision == null || this.localisation_extincteurs_precision === '')) {
					this.localisation_extincteurs_precision_missing = true;
					missingMandatory = true;
				}
				if (this.harnais_controle == null) {
					this.harnais_controle_missing = true;
					missingMandatory = true;
				}
				if (this.harnais_controle === 'NC' && (this.harnais_controle_precision == null || this.harnais_controle_precision === '')) {
					this.harnais_controle_precision_missing = true;
					missingMandatory = true;
				}
				if (this.cadenas_consignation == null) {
					this.cadenas_consignation_missing = true;
					missingMandatory = true;
				}
				if (this.cadenas_consignation === 'NC' && (this.cadenas_consignation_precision == null || this.cadenas_consignation_precision === '')) {
					this.cadenas_consignation_precision_missing = true;
					missingMandatory = true;
				}
				if (this.etat_vestiaires == null) {
					this.etat_vestiaires_missing = true;
					missingMandatory = true;
				}
				if (this.etat_vestiaires === 'NC' && (this.etat_vestiaires_precision == null || this.etat_vestiaires_precision === '')) {
					this.etat_vestiaires_precision_missing = true;
					missingMandatory = true;
				}
				if (this.etat_sanitaires == null) {
					this.etat_sanitaires_missing = true;
					missingMandatory = true;
				}
				if (this.etat_sanitaires === 'NC' && (this.etat_sanitaires_precision == null || this.etat_sanitaires_precision === '')) {
					this.etat_sanitaires_precision_missing = true;
					missingMandatory = true;
				}
				if (this.tri_dechets == null) {
					this.tri_dechets_missing = true;
					missingMandatory = true;
				}
				if (this.tri_dechets === 'NC' && (this.tri_dechets_precision == null || this.tri_dechets_precision === '')) {
					this.tri_dechets_precision_missing = true;
					missingMandatory = true;
				}
				if (this.emplacement_bennes == null) {
					this.emplacement_bennes_missing = true;
					missingMandatory = true;
				}
				if (this.emplacement_bennes === 'NC' && (this.emplacement_bennes_precision == null || this.emplacement_bennes_precision === '')) {
					this.emplacement_bennes_precision_missing = true;
					missingMandatory = true;
				}
				if (this.actions_non_securitaires == null) {
					this.actions_non_securitaires_missing = true;
					missingMandatory = true;
				}
				if (this.actions_non_securitaires === 'Oui' && (this.actions_non_securitaires_precision == null || this.actions_non_securitaires_precision === '')) {
					this.actions_non_securitaires_precision_missing = true;
					missingMandatory = true;
				}
				if (this.actions_securitaires == null) {
					this.actions_securitaires_missing = true;
					missingMandatory = true;
				}
				if (this.actions_securitaires === 'Oui' && (this.actions_securitaires_precision == null || this.actions_securitaires_precision === '')) {
					this.actions_securitaires_precision_missing = true;
					missingMandatory = true;
				}
				if (this.situations_dangereuses == null) {
					this.situations_dangereuses_missing = true;
					missingMandatory = true;
				}
				if (this.situations_dangereuses === 'Oui' && (this.situations_dangereuses_precision == null || this.situations_dangereuses_precision === '')) {
					this.situations_dangereuses_precision_missing = true;
					missingMandatory = true;
				}
				break;
			case 4 :
				if (this.voie_acces === 'NC') {
					if (this.action_voie_acces_description == null || this.action_voie_acces_description === '') {
						this.action_voie_acces_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_voie_acces_responsable == null) {
						this.action_voie_acces_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_voie_acces_application == null) {
						this.action_voie_acces_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.chantier_signalise === 'NC') {
					if (this.action_chantier_signalise_description == null || this.action_chantier_signalise_description === '') {
						this.action_chantier_signalise_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_chantier_signalise_responsable == null) {
						this.action_chantier_signalise_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_chantier_signalise_application == null) {
						this.action_chantier_signalise_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.sortie_secours_libre === 'NC') {
					if (this.action_sortie_secours_libre_description == null || this.action_sortie_secours_libre_description === '') {
						this.action_sortie_secours_libre_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_sortie_secours_libre_responsable == null) {
						this.action_sortie_secours_libre_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_sortie_secours_libre_application == null) {
						this.action_sortie_secours_libre_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.chantier_ordre === 'NC') {
					if (this.action_chantier_ordre_description == null || this.action_chantier_ordre_description === '') {
						this.action_chantier_ordre_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_chantier_ordre_responsable == null) {
						this.action_chantier_ordre_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_chantier_ordre_application == null) {
						this.action_chantier_ordre_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.dta === 'NC') {
					if (this.action_dta_description == null || this.action_dta_description === '') {
						this.action_dta_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_dta_responsable == null) {
						this.action_dta_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_dta_application == null) {
						this.action_dta_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.autorisation_permis_valide === 'NC') {
					if (this.action_autorisation_permis_valide_description == null || this.action_autorisation_permis_valide_description === '') {
						this.action_autorisation_permis_valide_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_autorisation_permis_valide_responsable == null) {
						this.action_autorisation_permis_valide_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_autorisation_permis_valide_application == null) {
						this.action_autorisation_permis_valide_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.manoeuvre_consignation === 'NC') {
					if (this.action_manoeuvre_consignation_description == null || this.action_manoeuvre_consignation_description === '') {
						this.action_manoeuvre_consignation_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_manoeuvre_consignation_responsable == null) {
						this.action_manoeuvre_consignation_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_manoeuvre_consignation_application == null) {
						this.action_manoeuvre_consignation_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.permis_feu_extincteur === 'NC') {
					if (this.action_permis_feu_extincteur_description == null || this.action_permis_feu_extincteur_description === '') {
						this.action_permis_feu_extincteur_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_permis_feu_extincteur_responsable == null) {
						this.action_permis_feu_extincteur_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_permis_feu_extincteur_application == null) {
						this.action_permis_feu_extincteur_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.permis_penetrer === 'NC') {
					if (this.action_permis_penetrer_description == null || this.action_permis_penetrer_description === '') {
						this.action_permis_penetrer_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_permis_penetrer_responsable == null) {
						this.action_permis_penetrer_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_permis_penetrer_application == null) {
						this.action_permis_penetrer_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.personnel_stemi_identifie === 'NC') {
					if (this.action_personnel_stemi_identifie_description == null || this.action_personnel_stemi_identifie_description === '') {
						this.action_personnel_stemi_identifie_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_personnel_stemi_identifie_responsable == null) {
						this.action_personnel_stemi_identifie_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_personnel_stemi_identifie_application == null) {
						this.action_personnel_stemi_identifie_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.personnel_interim_identifie === 'NC') {
					if (this.action_personnel_interim_identifie_description == null || this.action_personnel_interim_identifie_description === '') {
						this.action_personnel_interim_identifie_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_personnel_interim_identifie_responsable == null) {
						this.action_personnel_interim_identifie_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_personnel_interim_identifie_application == null) {
						this.action_personnel_interim_identifie_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.accueils_hse === 'NC') {
					if (this.action_accueils_hse_description == null || this.action_accueils_hse_description === '') {
						this.action_accueils_hse_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_accueils_hse_responsable == null) {
						this.action_accueils_hse_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_accueils_hse_application == null) {
						this.action_accueils_hse_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.plan_prevention === 'NC') {
					if (this.action_plan_prevention_description == null || this.action_plan_prevention_description === '') {
						this.action_plan_prevention_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_plan_prevention_responsable == null) {
						this.action_plan_prevention_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_plan_prevention_application == null) {
						this.action_plan_prevention_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_lies_chantier === 'NC') {
					if (this.action_risques_lies_chantier_description == null || this.action_risques_lies_chantier_description === '') {
						this.action_risques_lies_chantier_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_risques_lies_chantier_responsable == null) {
						this.action_risques_lies_chantier_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_risques_lies_chantier_application == null) {
						this.action_risques_lies_chantier_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_specifiques === 'NC') {
					if (this.action_risques_specifiques_description == null || this.action_risques_specifiques_description === '') {
						this.action_risques_specifiques_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_risques_specifiques_responsable == null) {
						this.action_risques_specifiques_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_risques_specifiques_application == null) {
						this.action_risques_specifiques_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.habilitations_disponibles === 'NC') {
					if (this.action_habilitations_disponibles_description == null || this.action_habilitations_disponibles_description === '') {
						this.action_habilitations_disponibles_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_habilitations_disponibles_responsable == null) {
						this.action_habilitations_disponibles_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_habilitations_disponibles_application == null) {
						this.action_habilitations_disponibles_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.habilitations_valides === 'NC') {
					if (this.action_habilitations_valides_description == null || this.action_habilitations_valides_description === '') {
						this.action_habilitations_valides_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_habilitations_valides_responsable == null) {
						this.action_habilitations_valides_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_habilitations_valides_application == null) {
						this.action_habilitations_valides_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.sst_present === 'NC') {
					if (this.action_sst_present_description == null || this.action_sst_present_description === '') {
						this.action_sst_present_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_sst_present_responsable == null) {
						this.action_sst_present_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_sst_present_application == null) {
						this.action_sst_present_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.echelle_etat === 'NC') {
					if (this.action_echelle_etat_description == null || this.action_echelle_etat_description === '') {
						this.action_echelle_etat_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_echelle_etat_responsable == null) {
						this.action_echelle_etat_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_echelle_etat_application == null) {
						this.action_echelle_etat_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.echafaudage_conforme === 'NC') {
					if (this.action_echafaudage_conforme_description == null || this.action_echafaudage_conforme_description === '') {
						this.action_echafaudage_conforme_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_echafaudage_conforme_responsable == null) {
						this.action_echafaudage_conforme_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_echafaudage_conforme_application == null) {
						this.action_echafaudage_conforme_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.fiches_autorisation === 'NC') {
					if (this.action_fiches_autorisation_description == null || this.action_fiches_autorisation_description === '') {
						this.action_fiches_autorisation_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_fiches_autorisation_responsable == null) {
						this.action_fiches_autorisation_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_fiches_autorisation_application == null) {
						this.action_fiches_autorisation_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.nacelle_conforme === 'NC') {
					if (this.action_nacelle_conforme_description == null || this.action_nacelle_conforme_description === '') {
						this.action_nacelle_conforme_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_nacelle_conforme_responsable == null) {
						this.action_nacelle_conforme_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_nacelle_conforme_application == null) {
						this.action_nacelle_conforme_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.etat_outillage === 'NC') {
					if (this.action_etat_outillage_description == null || this.action_etat_outillage_description === '') {
						this.action_etat_outillage_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_etat_outillage_responsable == null) {
						this.action_etat_outillage_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_etat_outillage_application == null) {
						this.action_etat_outillage_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.epi === 'NC') {
					if (this.action_epi_description == null || this.action_epi_description === '') {
						this.action_epi_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_epi_responsable == null) {
						this.action_epi_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_epi_application == null) {
						this.action_epi_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.epi_etat === 'NC') {
					if (this.action_epi_etat_description == null || this.action_epi_etat_description === '') {
						this.action_epi_etat_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_epi_etat_responsable == null) {
						this.action_epi_etat_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_epi_etat_application == null) {
						this.action_epi_etat_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.risques_coactivite === 'NC') {
					if (this.action_risques_coactivite_description == null || this.action_risques_coactivite_description === '') {
						this.action_risques_coactivite_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_risques_coactivite_responsable == null) {
						this.action_risques_coactivite_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_risques_coactivite_application == null) {
						this.action_risques_coactivite_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.vat_chantier === 'NC') {
					if (this.action_vat_chantier_description == null || this.action_vat_chantier_description === '') {
						this.action_vat_chantier_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_vat_chantier_responsable == null) {
						this.action_vat_chantier_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_vat_chantier_application == null) {
						this.action_vat_chantier_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.emplacement_douches === 'NC') {
					if (this.action_emplacement_douches_description == null || this.action_emplacement_douches_description === '') {
						this.action_emplacement_douches_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_emplacement_douches_responsable == null) {
						this.action_emplacement_douches_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_emplacement_douches_application == null) {
						this.action_emplacement_douches_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.moyens_alerte === 'NC') {
					if (this.action_moyens_alerte_description == null || this.action_moyens_alerte_description === '') {
						this.action_moyens_alerte_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_moyens_alerte_responsable == null) {
						this.action_moyens_alerte_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_moyens_alerte_application == null) {
						this.action_moyens_alerte_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.localisation_extincteurs === 'NC') {
					if (this.action_localisation_extincteurs_description == null || this.action_localisation_extincteurs_description === '') {
						this.action_localisation_extincteurs_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_localisation_extincteurs_responsable == null) {
						this.action_localisation_extincteurs_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_localisation_extincteurs_application == null) {
						this.action_localisation_extincteurs_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.harnais_controle === 'NC') {
					if (this.action_harnais_controle_description == null || this.action_harnais_controle_description === '') {
						this.action_harnais_controle_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_harnais_controle_responsable == null) {
						this.action_harnais_controle_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_harnais_controle_application == null) {
						this.action_harnais_controle_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.cadenas_consignation === 'NC') {
					if (this.action_cadenas_consignation_description == null || this.action_cadenas_consignation_description === '') {
						this.action_cadenas_consignation_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_cadenas_consignation_responsable == null) {
						this.action_cadenas_consignation_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_cadenas_consignation_application == null) {
						this.action_cadenas_consignation_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.etat_vestiaires === 'NC') {
					if (this.action_etat_vestiaires_description == null || this.action_etat_vestiaires_description === '') {
						this.action_etat_vestiaires_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_etat_vestiaires_responsable == null) {
						this.action_etat_vestiaires_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_etat_vestiaires_application == null) {
						this.action_etat_vestiaires_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.etat_sanitaires === 'NC') {
					if (this.action_etat_sanitaires_description == null || this.action_etat_sanitaires_description === '') {
						this.action_etat_sanitaires_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_etat_sanitaires_responsable == null) {
						this.action_etat_sanitaires_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_etat_sanitaires_application == null) {
						this.action_etat_sanitaires_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.tri_dechets === 'NC') {
					if (this.action_tri_dechets_description == null || this.action_tri_dechets_description === '') {
						this.action_tri_dechets_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_tri_dechets_responsable == null) {
						this.action_tri_dechets_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_tri_dechets_application == null) {
						this.action_tri_dechets_application_missing = true;
						missingMandatory = true;
					}
				}
				if (this.emplacement_bennes === 'NC') {
					if (this.action_emplacement_bennes_description == null || this.action_emplacement_bennes_description === '') {
						this.action_emplacement_bennes_description_missing = true;
						missingMandatory = true;
					}
					if (this.action_emplacement_bennes_responsable == null) {
						this.action_emplacement_bennes_responsable_missing = true;
						missingMandatory = true;
					}
					if (this.action_emplacement_bennes_application == null) {
						this.action_emplacement_bennes_application_missing = true;
						missingMandatory = true;
					}
				}
				break;
			default :
				break;
		}
		return missingMandatory;
	}

	updateMissing(source, index?) {
		switch (source) {
			case 'nature_travaux' :
				if (this.nature_travaux != null) {
					this.nature_travaux_missing = false;
				}
				break;
			case 'nb_stemi' :
				if (this.nb_stemi != null) {
					this.nb_stemi_missing = false;
				}
				break;
			case 'nb_interim' :
				if (this.nb_interim != null) {
					this.nb_interim_missing = false;
				}
				break;
			case 'personne_stemi_auditee' :
				if (this.personne_stemi_auditee != null) {
					this.personne_stemi_auditee_missing = false;
				}
				break;
			case 'personnes_supplementaires.societe' :
				if (this.personnes_supplementaires[index].societe != null) {
					this.personnes_supplementaires[index].societe_missing = false;
				}
				break;
			case 'personnes_supplementaires.nom' :
				if (this.personnes_supplementaires[index].nom != null) {
					this.personnes_supplementaires[index].nom_missing = false;
				}
				break;
			case 'personnes_supplementaires.type' :
				if (this.personnes_supplementaires[index].type != null) {
					this.personnes_supplementaires[index].type_missing = false;
				}
				break;
			case 'voie_acces' :
				if (this.voie_acces != null) {
					this.voie_acces_missing = false;
				}
				if (this.voie_acces !== 'NC') {
					this.voie_acces_precision_missing = false;
					this.action_voie_acces_description_missing = false;
					this.action_voie_acces_responsable_missing = false;
					this.action_voie_acces_application_missing = false;
				}
				break;
			case 'voie_acces_precision' :
				if (this.voie_acces_precision != null) {
					this.voie_acces_precision_missing = false;
				}
				break;
			case 'action_voie_acces_description' :
				if (this.action_voie_acces_description != null) {
					this.action_voie_acces_description_missing = false;
				}
				break;
			case 'action_voie_acces_responsable' :
				if (this.action_voie_acces_responsable != null) {
					this.action_voie_acces_responsable_missing = false;
				}
				break;
			case 'action_voie_acces_application' :
				if (this.action_voie_acces_application != null) {
					this.action_voie_acces_application_missing = false;
				}
				break;
			case 'chantier_signalise' :
				if (this.chantier_signalise != null) {
					this.chantier_signalise_missing = false;
				}
				if (this.chantier_signalise !== 'NC') {
					this.chantier_signalise_precision_missing = false;
					this.action_chantier_signalise_description_missing = false;
					this.action_chantier_signalise_responsable_missing = false;
					this.action_chantier_signalise_application_missing = false;
				}
				break;
			case 'chantier_signalise_precision' :
				if (this.chantier_signalise_precision != null) {
					this.chantier_signalise_precision_missing = false;
				}
				break;
			case 'action_chantier_signalise_description' :
				if (this.action_chantier_signalise_description != null) {
					this.action_chantier_signalise_description_missing = false;
				}
				break;
			case 'action_chantier_signalise_responsable' :
				if (this.action_chantier_signalise_responsable != null) {
					this.action_chantier_signalise_responsable_missing = false;
				}
				break;
			case 'action_chantier_signalise_application' :
				if (this.action_chantier_signalise_application != null) {
					this.action_chantier_signalise_application_missing = false;
				}
				break;
			case 'sortie_secours_libre' :
				if (this.sortie_secours_libre != null) {
					this.sortie_secours_libre_missing = false;
				}
				if (this.sortie_secours_libre !== 'NC') {
					this.sortie_secours_libre_precision_missing = false;
					this.action_sortie_secours_libre_description_missing = false;
					this.action_sortie_secours_libre_responsable_missing = false;
					this.action_sortie_secours_libre_application_missing = false;
				}
				break;
			case 'sortie_secours_libre_precision' :
				if (this.sortie_secours_libre_precision != null) {
					this.sortie_secours_libre_precision_missing = false;
				}
				break;
			case 'action_sortie_secours_libre_description' :
				if (this.action_sortie_secours_libre_description != null) {
					this.action_sortie_secours_libre_description_missing = false;
				}
				break;
			case 'action_sortie_secours_libre_responsable' :
				if (this.action_sortie_secours_libre_responsable != null) {
					this.action_sortie_secours_libre_responsable_missing = false;
				}
				break;
			case 'action_sortie_secours_libre_application' :
				if (this.action_sortie_secours_libre_application != null) {
					this.action_sortie_secours_libre_application_missing = false;
				}
				break;
			case 'chantier_ordre' :
				if (this.chantier_ordre != null) {
					this.chantier_ordre_missing = false;
				}
				if (this.chantier_ordre !== 'NC') {
					this.chantier_ordre_precision_missing = false;
					this.action_chantier_ordre_description_missing = false;
					this.action_chantier_ordre_responsable_missing = false;
					this.action_chantier_ordre_application_missing = false;
				}
				break;
			case 'chantier_ordre_precision' :
				if (this.chantier_ordre_precision != null) {
					this.chantier_ordre_precision_missing = false;
				}
				break;
			case 'action_chantier_ordre_description' :
				if (this.action_chantier_ordre_description != null) {
					this.action_chantier_ordre_description_missing = false;
				}
				break;
			case 'action_chantier_ordre_responsable' :
				if (this.action_chantier_ordre_responsable != null) {
					this.action_chantier_ordre_responsable_missing = false;
				}
				break;
			case 'action_chantier_ordre_application' :
				if (this.action_chantier_ordre_application != null) {
					this.action_chantier_ordre_application_missing = false;
				}
				break;
			case 'dta' :
				if (this.dta != null) {
					this.dta_missing = false;
				}
				if (this.dta !== 'NC') {
					this.dta_precision_missing = false;
					this.action_dta_description_missing = false;
					this.action_dta_responsable_missing = false;
					this.action_dta_application_missing = false;
				}
				break;
			case 'dta_precision' :
				if (this.dta_precision != null) {
					this.dta_precision_missing = false;
				}
				break;
			case 'action_dta_description' :
				if (this.action_dta_description != null) {
					this.action_dta_description_missing = false;
				}
				break;
			case 'action_dta_responsable' :
				if (this.action_dta_responsable != null) {
					this.action_dta_responsable_missing = false;
				}
				break;
			case 'action_dta_application' :
				if (this.action_dta_application != null) {
					this.action_dta_application_missing = false;
				}
				break;
			case 'autorisation_permis_valide' :
				if (this.autorisation_permis_valide != null) {
					this.autorisation_permis_valide_missing = false;
				}
				if (this.autorisation_permis_valide !== 'NC') {
					this.autorisation_permis_valide_precision_missing = false;
					this.action_autorisation_permis_valide_description_missing = false;
					this.action_autorisation_permis_valide_responsable_missing = false;
					this.action_autorisation_permis_valide_application_missing = false;
				}
				break;
			case 'autorisation_permis_valide_precision' :
				if (this.autorisation_permis_valide_precision != null) {
					this.autorisation_permis_valide_precision_missing = false;
				}
				break;
			case 'action_autorisation_permis_valide_description' :
				if (this.action_autorisation_permis_valide_description != null) {
					this.action_autorisation_permis_valide_description_missing = false;
				}
				break;
			case 'action_autorisation_permis_valide_responsable' :
				if (this.action_autorisation_permis_valide_responsable != null) {
					this.action_autorisation_permis_valide_responsable_missing = false;
				}
				break;
			case 'action_autorisation_permis_valide_application' :
				if (this.action_autorisation_permis_valide_application != null) {
					this.action_autorisation_permis_valide_application_missing = false;
				}
				break;
			case 'manoeuvre_consignation' :
				if (this.manoeuvre_consignation != null) {
					this.manoeuvre_consignation_missing = false;
				}
				if (this.manoeuvre_consignation !== 'NC') {
					this.manoeuvre_consignation_precision_missing = false;
					this.action_manoeuvre_consignation_description_missing = false;
					this.action_manoeuvre_consignation_responsable_missing = false;
					this.action_manoeuvre_consignation_application_missing = false;
				}
				break;
			case 'manoeuvre_consignation_precision' :
				if (this.manoeuvre_consignation_precision != null) {
					this.manoeuvre_consignation_precision_missing = false;
				}
				break;
			case 'action_manoeuvre_consignation_description' :
				if (this.action_manoeuvre_consignation_description != null) {
					this.action_manoeuvre_consignation_description_missing = false;
				}
				break;
			case 'action_manoeuvre_consignation_responsable' :
				if (this.action_manoeuvre_consignation_responsable != null) {
					this.action_manoeuvre_consignation_responsable_missing = false;
				}
				break;
			case 'action_manoeuvre_consignation_application' :
				if (this.action_manoeuvre_consignation_application != null) {
					this.action_manoeuvre_consignation_application_missing = false;
				}
				break;
			case 'permis_feu_extincteur' :
				if (this.permis_feu_extincteur != null) {
					this.permis_feu_extincteur_missing = false;
				}
				if (this.permis_feu_extincteur !== 'NC') {
					this.permis_feu_extincteur_precision_missing = false;
					this.action_permis_feu_extincteur_description_missing = false;
					this.action_permis_feu_extincteur_responsable_missing = false;
					this.action_permis_feu_extincteur_application_missing = false;
				}
				break;
			case 'permis_feu_extincteur_precision' :
				if (this.permis_feu_extincteur_precision != null) {
					this.permis_feu_extincteur_precision_missing = false;
				}
				break;
			case 'action_permis_feu_extincteur_description' :
				if (this.action_permis_feu_extincteur_description != null) {
					this.action_permis_feu_extincteur_description_missing = false;
				}
				break;
			case 'action_permis_feu_extincteur_responsable' :
				if (this.action_permis_feu_extincteur_responsable != null) {
					this.action_permis_feu_extincteur_responsable_missing = false;
				}
				break;
			case 'action_permis_feu_extincteur_application' :
				if (this.action_permis_feu_extincteur_application != null) {
					this.action_permis_feu_extincteur_application_missing = false;
				}
				break;
			case 'permis_penetrer' :
				if (this.permis_penetrer != null) {
					this.permis_penetrer_missing = false;
				}
				if (this.permis_penetrer !== 'NC') {
					this.permis_penetrer_precision_missing = false;
					this.action_permis_penetrer_description_missing = false;
					this.action_permis_penetrer_responsable_missing = false;
					this.action_permis_penetrer_application_missing = false;
				}
				break;
			case 'permis_penetrer_precision' :
				if (this.permis_penetrer_precision != null) {
					this.permis_penetrer_precision_missing = false;
				}
				break;
			case 'action_permis_penetrer_description' :
				if (this.action_permis_penetrer_description != null) {
					this.action_permis_penetrer_description_missing = false;
				}
				break;
			case 'action_permis_penetrer_responsable' :
				if (this.action_permis_penetrer_responsable != null) {
					this.action_permis_penetrer_responsable_missing = false;
				}
				break;
			case 'action_permis_penetrer_application' :
				if (this.action_permis_penetrer_application != null) {
					this.action_permis_penetrer_application_missing = false;
				}
				break;
			case 'personnel_stemi_identifie' :
				if (this.personnel_stemi_identifie != null) {
					this.personnel_stemi_identifie_missing = false;
				}
				if (this.personnel_stemi_identifie !== 'NC') {
					this.personnel_stemi_identifie_precision_missing = false;
					this.action_personnel_stemi_identifie_description_missing = false;
					this.action_personnel_stemi_identifie_responsable_missing = false;
					this.action_personnel_stemi_identifie_application_missing = false;
				}
				break;
			case 'personnel_stemi_identifie_precision' :
				if (this.personnel_stemi_identifie_precision != null) {
					this.personnel_stemi_identifie_precision_missing = false;
				}
				break;
			case 'action_personnel_stemi_identifie_description' :
				if (this.action_personnel_stemi_identifie_description != null) {
					this.action_personnel_stemi_identifie_description_missing = false;
				}
				break;
			case 'action_personnel_stemi_identifie_responsable' :
				if (this.action_personnel_stemi_identifie_responsable != null) {
					this.action_personnel_stemi_identifie_responsable_missing = false;
				}
				break;
			case 'action_personnel_stemi_identifie_application' :
				if (this.action_personnel_stemi_identifie_application != null) {
					this.action_personnel_stemi_identifie_application_missing = false;
				}
				break;
			case 'personnel_interim_identifie' :
				if (this.personnel_interim_identifie != null) {
					this.personnel_interim_identifie_missing = false;
				}
				if (this.personnel_interim_identifie !== 'NC') {
					this.personnel_interim_identifie_precision_missing = false;
					this.action_personnel_interim_identifie_description_missing = false;
					this.action_personnel_interim_identifie_responsable_missing = false;
					this.action_personnel_interim_identifie_application_missing = false;
				}
				break;
			case 'personnel_interim_identifie_precision' :
				if (this.personnel_interim_identifie_precision != null) {
					this.personnel_interim_identifie_precision_missing = false;
				}
				break;
			case 'action_personnel_interim_identifie_description' :
				if (this.action_personnel_interim_identifie_description != null) {
					this.action_personnel_interim_identifie_description_missing = false;
				}
				break;
			case 'action_personnel_interim_identifie_responsable' :
				if (this.action_personnel_interim_identifie_responsable != null) {
					this.action_personnel_interim_identifie_responsable_missing = false;
				}
				break;
			case 'action_personnel_interim_identifie_application' :
				if (this.action_personnel_interim_identifie_application != null) {
					this.action_personnel_interim_identifie_application_missing = false;
				}
				break;
			case 'accueils_hse' :
				if (this.accueils_hse != null) {
					this.accueils_hse_missing = false;
				}
				if (this.accueils_hse !== 'NC') {
					this.accueils_hse_precision_missing = false;
					this.action_accueils_hse_description_missing = false;
					this.action_accueils_hse_responsable_missing = false;
					this.action_accueils_hse_application_missing = false;
				}
				break;
			case 'accueils_hse_precision' :
				if (this.accueils_hse_precision != null) {
					this.accueils_hse_precision_missing = false;
				}
				break;
			case 'action_accueils_hse_description' :
				if (this.action_accueils_hse_description != null) {
					this.action_accueils_hse_description_missing = false;
				}
				break;
			case 'action_accueils_hse_responsable' :
				if (this.action_accueils_hse_responsable != null) {
					this.action_accueils_hse_responsable_missing = false;
				}
				break;
			case 'action_accueils_hse_application' :
				if (this.action_accueils_hse_application != null) {
					this.action_accueils_hse_application_missing = false;
				}
				break;
			case 'plan_prevention' :
				if (this.plan_prevention != null) {
					this.plan_prevention_missing = false;
				}
				if (this.plan_prevention !== 'NC') {
					this.plan_prevention_precision_missing = false;
					this.action_plan_prevention_description_missing = false;
					this.action_plan_prevention_responsable_missing = false;
					this.action_plan_prevention_application_missing = false;
				}
				break;
			case 'plan_prevention_precision' :
				if (this.plan_prevention_precision != null) {
					this.plan_prevention_precision_missing = false;
				}
				break;
			case 'action_plan_prevention_description' :
				if (this.action_plan_prevention_description != null) {
					this.action_plan_prevention_description_missing = false;
				}
				break;
			case 'action_plan_prevention_responsable' :
				if (this.action_plan_prevention_responsable != null) {
					this.action_plan_prevention_responsable_missing = false;
				}
				break;
			case 'action_plan_prevention_application' :
				if (this.action_plan_prevention_application != null) {
					this.action_plan_prevention_application_missing = false;
				}
				break;
			case 'risques_lies_chantier' :
				if (this.risques_lies_chantier != null) {
					this.risques_lies_chantier_missing = false;
				}
				if (this.risques_lies_chantier !== 'NC') {
					this.risques_lies_chantier_precision_missing = false;
					this.action_risques_lies_chantier_description_missing = false;
					this.action_risques_lies_chantier_responsable_missing = false;
					this.action_risques_lies_chantier_application_missing = false;
				}
				break;
			case 'risques_lies_chantier_precision' :
				if (this.risques_lies_chantier_precision != null) {
					this.risques_lies_chantier_precision_missing = false;
				}
				break;
			case 'action_risques_lies_chantier_description' :
				if (this.action_risques_lies_chantier_description != null) {
					this.action_risques_lies_chantier_description_missing = false;
				}
				break;
			case 'action_risques_lies_chantier_responsable' :
				if (this.action_risques_lies_chantier_responsable != null) {
					this.action_risques_lies_chantier_responsable_missing = false;
				}
				break;
			case 'action_risques_lies_chantier_application' :
				if (this.action_risques_lies_chantier_application != null) {
					this.action_risques_lies_chantier_application_missing = false;
				}
				break;
			case 'risques_specifiques' :
				if (this.risques_specifiques != null) {
					this.risques_specifiques_missing = false;
				}
				if (this.risques_specifiques !== 'NC') {
					this.risques_specifiques_precision_missing = false;
					this.action_risques_specifiques_description_missing = false;
					this.action_risques_specifiques_responsable_missing = false;
					this.action_risques_specifiques_application_missing = false;
				}
				break;
			case 'risques_specifiques_precision' :
				if (this.risques_specifiques_precision != null) {
					this.risques_specifiques_precision_missing = false;
				}
				break;
			case 'action_risques_specifiques_description' :
				if (this.action_risques_specifiques_description != null) {
					this.action_risques_specifiques_description_missing = false;
				}
				break;
			case 'action_risques_specifiques_responsable' :
				if (this.action_risques_specifiques_responsable != null) {
					this.action_risques_specifiques_responsable_missing = false;
				}
				break;
			case 'action_risques_specifiques_application' :
				if (this.action_risques_specifiques_application != null) {
					this.action_risques_specifiques_application_missing = false;
				}
				break;
			case 'habilitations_disponibles' :
				if (this.habilitations_disponibles != null) {
					this.habilitations_disponibles_missing = false;
				}
				if (this.habilitations_disponibles !== 'NC') {
					this.habilitations_disponibles_precision_missing = false;
					this.action_habilitations_disponibles_description_missing = false;
					this.action_habilitations_disponibles_responsable_missing = false;
					this.action_habilitations_disponibles_application_missing = false;
				}
				break;
			case 'habilitations_disponibles_precision' :
				if (this.habilitations_disponibles_precision != null) {
					this.habilitations_disponibles_precision_missing = false;
				}
				break;
			case 'action_habilitations_disponibles_description' :
				if (this.action_habilitations_disponibles_description != null) {
					this.action_habilitations_disponibles_description_missing = false;
				}
				break;
			case 'action_habilitations_disponibles_responsable' :
				if (this.action_habilitations_disponibles_responsable != null) {
					this.action_habilitations_disponibles_responsable_missing = false;
				}
				break;
			case 'action_habilitations_disponibles_application' :
				if (this.action_habilitations_disponibles_application != null) {
					this.action_habilitations_disponibles_application_missing = false;
				}
				break;
			case 'habilitations_valides' :
				if (this.habilitations_valides != null) {
					this.habilitations_valides_missing = false;
				}
				if (this.habilitations_valides !== 'NC') {
					this.habilitations_valides_precision_missing = false;
					this.action_habilitations_valides_description_missing = false;
					this.action_habilitations_valides_responsable_missing = false;
					this.action_habilitations_valides_application_missing = false;
				}
				break;
			case 'habilitations_valides_precision' :
				if (this.habilitations_valides_precision != null) {
					this.habilitations_valides_precision_missing = false;
				}
				break;
			case 'action_habilitations_valides_description' :
				if (this.action_habilitations_valides_description != null) {
					this.action_habilitations_valides_description_missing = false;
				}
				break;
			case 'action_habilitations_valides_responsable' :
				if (this.action_habilitations_valides_responsable != null) {
					this.action_habilitations_valides_responsable_missing = false;
				}
				break;
			case 'action_habilitations_valides_application' :
				if (this.action_habilitations_valides_application != null) {
					this.action_habilitations_valides_application_missing = false;
				}
				break;
			case 'sst_present' :
				if (this.sst_present != null) {
					this.sst_present_missing = false;
				}
				if (this.sst_present !== 'NC') {
					this.sst_present_precision_missing = false;
					this.action_sst_present_description_missing = false;
					this.action_sst_present_responsable_missing = false;
					this.action_sst_present_application_missing = false;
				}
				break;
			case 'sst_present_precision' :
				if (this.sst_present_precision != null) {
					this.sst_present_precision_missing = false;
				}
				break;
			case 'action_sst_present_description' :
				if (this.action_sst_present_description != null) {
					this.action_sst_present_description_missing = false;
				}
				break;
			case 'action_sst_present_responsable' :
				if (this.action_sst_present_responsable != null) {
					this.action_sst_present_responsable_missing = false;
				}
				break;
			case 'action_sst_present_application' :
				if (this.action_sst_present_application != null) {
					this.action_sst_present_application_missing = false;
				}
				break;
			case 'echelle_etat' :
				if (this.echelle_etat != null) {
					this.echelle_etat_missing = false;
				}
				if (this.echelle_etat !== 'NC') {
					this.echelle_etat_precision_missing = false;
					this.action_echelle_etat_description_missing = false;
					this.action_echelle_etat_responsable_missing = false;
					this.action_echelle_etat_application_missing = false;
				}
				break;
			case 'echelle_etat_precision' :
				if (this.echelle_etat_precision != null) {
					this.echelle_etat_precision_missing = false;
				}
				break;
			case 'action_echelle_etat_description' :
				if (this.action_echelle_etat_description != null) {
					this.action_echelle_etat_description_missing = false;
				}
				break;
			case 'action_echelle_etat_responsable' :
				if (this.action_echelle_etat_responsable != null) {
					this.action_echelle_etat_responsable_missing = false;
				}
				break;
			case 'action_echelle_etat_application' :
				if (this.action_echelle_etat_application != null) {
					this.action_echelle_etat_application_missing = false;
				}
				break;
			case 'echafaudage_conforme' :
				if (this.echafaudage_conforme != null) {
					this.echafaudage_conforme_missing = false;
				}
				if (this.echafaudage_conforme !== 'NC') {
					this.echafaudage_conforme_precision_missing = false;
					this.action_echafaudage_conforme_description_missing = false;
					this.action_echafaudage_conforme_responsable_missing = false;
					this.action_echafaudage_conforme_application_missing = false;
				}
				break;
			case 'echafaudage_conforme_precision' :
				if (this.echafaudage_conforme_precision != null) {
					this.echafaudage_conforme_precision_missing = false;
				}
				break;
			case 'action_echafaudage_conforme_description' :
				if (this.action_echafaudage_conforme_description != null) {
					this.action_echafaudage_conforme_description_missing = false;
				}
				break;
			case 'action_echafaudage_conforme_responsable' :
				if (this.action_echafaudage_conforme_responsable != null) {
					this.action_echafaudage_conforme_responsable_missing = false;
				}
				break;
			case 'action_echafaudage_conforme_application' :
				if (this.action_echafaudage_conforme_application != null) {
					this.action_echafaudage_conforme_application_missing = false;
				}
				break;
			case 'fiches_autorisation' :
				if (this.fiches_autorisation != null) {
					this.fiches_autorisation_missing = false;
				}
				if (this.fiches_autorisation !== 'NC') {
					this.fiches_autorisation_precision_missing = false;
					this.action_fiches_autorisation_description_missing = false;
					this.action_fiches_autorisation_responsable_missing = false;
					this.action_fiches_autorisation_application_missing = false;
				}
				break;
			case 'fiches_autorisation_precision' :
				if (this.fiches_autorisation_precision != null) {
					this.fiches_autorisation_precision_missing = false;
				}
				break;
			case 'action_fiches_autorisation_description' :
				if (this.action_fiches_autorisation_description != null) {
					this.action_fiches_autorisation_description_missing = false;
				}
				break;
			case 'action_fiches_autorisation_responsable' :
				if (this.action_fiches_autorisation_responsable != null) {
					this.action_fiches_autorisation_responsable_missing = false;
				}
				break;
			case 'action_fiches_autorisation_application' :
				if (this.action_fiches_autorisation_application != null) {
					this.action_fiches_autorisation_application_missing = false;
				}
				break;
			case 'nacelle_conforme' :
				if (this.nacelle_conforme != null) {
					this.nacelle_conforme_missing = false;
				}
				if (this.nacelle_conforme !== 'NC') {
					this.nacelle_conforme_precision_missing = false;
					this.action_nacelle_conforme_description_missing = false;
					this.action_nacelle_conforme_responsable_missing = false;
					this.action_nacelle_conforme_application_missing = false;
				}
				break;
			case 'nacelle_conforme_precision' :
				if (this.nacelle_conforme_precision != null) {
					this.nacelle_conforme_precision_missing = false;
				}
				break;
			case 'action_nacelle_conforme_description' :
				if (this.action_nacelle_conforme_description != null) {
					this.action_nacelle_conforme_description_missing = false;
				}
				break;
			case 'action_nacelle_conforme_responsable' :
				if (this.action_nacelle_conforme_responsable != null) {
					this.action_nacelle_conforme_responsable_missing = false;
				}
				break;
			case 'action_nacelle_conforme_application' :
				if (this.action_nacelle_conforme_application != null) {
					this.action_nacelle_conforme_application_missing = false;
				}
				break;
			case 'etat_outillage' :
				if (this.etat_outillage != null) {
					this.etat_outillage_missing = false;
				}
				if (this.etat_outillage !== 'NC') {
					this.etat_outillage_precision_missing = false;
					this.action_etat_outillage_description_missing = false;
					this.action_etat_outillage_responsable_missing = false;
					this.action_etat_outillage_application_missing = false;
				}
				break;
			case 'etat_outillage_precision' :
				if (this.etat_outillage_precision != null) {
					this.etat_outillage_precision_missing = false;
				}
				break;
			case 'action_etat_outillage_description' :
				if (this.action_etat_outillage_description != null) {
					this.action_etat_outillage_description_missing = false;
				}
				break;
			case 'action_etat_outillage_responsable' :
				if (this.action_etat_outillage_responsable != null) {
					this.action_etat_outillage_responsable_missing = false;
				}
				break;
			case 'action_etat_outillage_application' :
				if (this.action_etat_outillage_application != null) {
					this.action_etat_outillage_application_missing = false;
				}
				break;
			case 'epi' :
				if (this.epi != null) {
					this.epi_missing = false;
				}
				if (this.epi !== 'NC') {
					this.epi_precision_missing = false;
					this.action_epi_description_missing = false;
					this.action_epi_responsable_missing = false;
					this.action_epi_application_missing = false;
				}
				break;
			case 'epi_precision' :
				if (this.epi_precision != null) {
					this.epi_precision_missing = false;
				}
				break;
			case 'action_epi_description' :
				if (this.action_epi_description != null) {
					this.action_epi_description_missing = false;
				}
				break;
			case 'action_epi_responsable' :
				if (this.action_epi_responsable != null) {
					this.action_epi_responsable_missing = false;
				}
				break;
			case 'action_epi_application' :
				if (this.action_epi_application != null) {
					this.action_epi_application_missing = false;
				}
				break;
			case 'epi_etat' :
				if (this.epi_etat != null) {
					this.epi_etat_missing = false;
				}
				if (this.epi_etat !== 'NC') {
					this.epi_etat_precision_missing = false;
					this.action_epi_etat_description_missing = false;
					this.action_epi_etat_responsable_missing = false;
					this.action_epi_etat_application_missing = false;
				}
				break;
			case 'epi_etat_precision' :
				if (this.epi_etat_precision != null) {
					this.epi_etat_precision_missing = false;
				}
				break;
			case 'action_epi_etat_description' :
				if (this.action_epi_etat_description != null) {
					this.action_epi_etat_description_missing = false;
				}
				break;
			case 'action_epi_etat_responsable' :
				if (this.action_epi_etat_responsable != null) {
					this.action_epi_etat_responsable_missing = false;
				}
				break;
			case 'action_epi_etat_application' :
				if (this.action_epi_etat_application != null) {
					this.action_epi_etat_application_missing = false;
				}
				break;
			case 'risques_coactivite' :
				if (this.risques_coactivite != null) {
					this.risques_coactivite_missing = false;
				}
				if (this.risques_coactivite !== 'NC') {
					this.risques_coactivite_precision_missing = false;
					this.action_risques_coactivite_description_missing = false;
					this.action_risques_coactivite_responsable_missing = false;
					this.action_risques_coactivite_application_missing = false;
				}
				break;
			case 'risques_coactivite_precision' :
				if (this.risques_coactivite_precision != null) {
					this.risques_coactivite_precision_missing = false;
				}
				break;
			case 'action_risques_coactivite_description' :
				if (this.action_risques_coactivite_description != null) {
					this.action_risques_coactivite_description_missing = false;
				}
				break;
			case 'action_risques_coactivite_responsable' :
				if (this.action_risques_coactivite_responsable != null) {
					this.action_risques_coactivite_responsable_missing = false;
				}
				break;
			case 'action_risques_coactivite_application' :
				if (this.action_risques_coactivite_application != null) {
					this.action_risques_coactivite_application_missing = false;
				}
				break;
			case 'vat_chantier' :
				if (this.vat_chantier != null) {
					this.vat_chantier_missing = false;
				}
				if (this.vat_chantier !== 'NC') {
					this.vat_chantier_precision_missing = false;
					this.action_vat_chantier_description_missing = false;
					this.action_vat_chantier_responsable_missing = false;
					this.action_vat_chantier_application_missing = false;
				}
				break;
			case 'vat_chantier_precision' :
				if (this.vat_chantier_precision != null) {
					this.vat_chantier_precision_missing = false;
				}
				break;
			case 'action_vat_chantier_description' :
				if (this.action_vat_chantier_description != null) {
					this.action_vat_chantier_description_missing = false;
				}
				break;
			case 'action_vat_chantier_responsable' :
				if (this.action_vat_chantier_responsable != null) {
					this.action_vat_chantier_responsable_missing = false;
				}
				break;
			case 'action_vat_chantier_application' :
				if (this.action_vat_chantier_application != null) {
					this.action_vat_chantier_application_missing = false;
				}
				break;
			case 'emplacement_douches' :
				if (this.emplacement_douches != null) {
					this.emplacement_douches_missing = false;
				}
				if (this.emplacement_douches !== 'NC') {
					this.emplacement_douches_precision_missing = false;
					this.action_emplacement_douches_description_missing = false;
					this.action_emplacement_douches_responsable_missing = false;
					this.action_emplacement_douches_application_missing = false;
				}
				break;
			case 'emplacement_douches_precision' :
				if (this.emplacement_douches_precision != null) {
					this.emplacement_douches_precision_missing = false;
				}
				break;
			case 'action_emplacement_douches_description' :
				if (this.action_emplacement_douches_description != null) {
					this.action_emplacement_douches_description_missing = false;
				}
				break;
			case 'action_emplacement_douches_responsable' :
				if (this.action_emplacement_douches_responsable != null) {
					this.action_emplacement_douches_responsable_missing = false;
				}
				break;
			case 'action_emplacement_douches_application' :
				if (this.action_emplacement_douches_application != null) {
					this.action_emplacement_douches_application_missing = false;
				}
				break;
			case 'moyens_alerte' :
				if (this.moyens_alerte != null) {
					this.moyens_alerte_missing = false;
				}
				if (this.moyens_alerte !== 'NC') {
					this.moyens_alerte_precision_missing = false;
					this.action_moyens_alerte_description_missing = false;
					this.action_moyens_alerte_responsable_missing = false;
					this.action_moyens_alerte_application_missing = false;
				}
				break;
			case 'moyens_alerte_precision' :
				if (this.moyens_alerte_precision != null) {
					this.moyens_alerte_precision_missing = false;
				}
				break;
			case 'action_moyens_alerte_description' :
				if (this.action_moyens_alerte_description != null) {
					this.action_moyens_alerte_description_missing = false;
				}
				break;
			case 'action_moyens_alerte_responsable' :
				if (this.action_moyens_alerte_responsable != null) {
					this.action_moyens_alerte_responsable_missing = false;
				}
				break;
			case 'action_moyens_alerte_application' :
				if (this.action_moyens_alerte_application != null) {
					this.action_moyens_alerte_application_missing = false;
				}
				break;
			case 'localisation_extincteurs' :
				if (this.localisation_extincteurs != null) {
					this.localisation_extincteurs_missing = false;
				}
				if (this.localisation_extincteurs !== 'NC') {
					this.localisation_extincteurs_precision_missing = false;
					this.action_localisation_extincteurs_description_missing = false;
					this.action_localisation_extincteurs_responsable_missing = false;
					this.action_localisation_extincteurs_application_missing = false;
				}
				break;
			case 'localisation_extincteurs_precision' :
				if (this.localisation_extincteurs_precision != null) {
					this.localisation_extincteurs_precision_missing = false;
				}
				break;
			case 'action_localisation_extincteurs_description' :
				if (this.action_localisation_extincteurs_description != null) {
					this.action_localisation_extincteurs_description_missing = false;
				}
				break;
			case 'action_localisation_extincteurs_responsable' :
				if (this.action_localisation_extincteurs_responsable != null) {
					this.action_localisation_extincteurs_responsable_missing = false;
				}
				break;
			case 'action_localisation_extincteurs_application' :
				if (this.action_localisation_extincteurs_application != null) {
					this.action_localisation_extincteurs_application_missing = false;
				}
				break;
			case 'harnais_controle' :
				if (this.harnais_controle != null) {
					this.harnais_controle_missing = false;
				}
				if (this.harnais_controle !== 'NC') {
					this.harnais_controle_precision_missing = false;
					this.action_harnais_controle_description_missing = false;
					this.action_harnais_controle_responsable_missing = false;
					this.action_harnais_controle_application_missing = false;
				}
				break;
			case 'harnais_controle_precision' :
				if (this.harnais_controle_precision != null) {
					this.harnais_controle_precision_missing = false;
				}
				break;
			case 'action_harnais_controle_description' :
				if (this.action_harnais_controle_description != null) {
					this.action_harnais_controle_description_missing = false;
				}
				break;
			case 'action_harnais_controle_responsable' :
				if (this.action_harnais_controle_responsable != null) {
					this.action_harnais_controle_responsable_missing = false;
				}
				break;
			case 'action_harnais_controle_application' :
				if (this.action_harnais_controle_application != null) {
					this.action_harnais_controle_application_missing = false;
				}
				break;
			case 'cadenas_consignation' :
				if (this.cadenas_consignation != null) {
					this.cadenas_consignation_missing = false;
				}
				if (this.cadenas_consignation !== 'NC') {
					this.cadenas_consignation_precision_missing = false;
					this.action_cadenas_consignation_description_missing = false;
					this.action_cadenas_consignation_responsable_missing = false;
					this.action_cadenas_consignation_application_missing = false;
				}
				break;
			case 'cadenas_consignation_precision' :
				if (this.cadenas_consignation_precision != null) {
					this.cadenas_consignation_precision_missing = false;
				}
				break;
			case 'action_cadenas_consignation_description' :
				if (this.action_cadenas_consignation_description != null) {
					this.action_cadenas_consignation_description_missing = false;
				}
				break;
			case 'action_cadenas_consignation_responsable' :
				if (this.action_cadenas_consignation_responsable != null) {
					this.action_cadenas_consignation_responsable_missing = false;
				}
				break;
			case 'action_cadenas_consignation_application' :
				if (this.action_cadenas_consignation_application != null) {
					this.action_cadenas_consignation_application_missing = false;
				}
				break;
			case 'etat_vestiaires' :
				if (this.etat_vestiaires != null) {
					this.etat_vestiaires_missing = false;
				}
				if (this.etat_vestiaires !== 'NC') {
					this.etat_vestiaires_precision_missing = false;
					this.action_etat_vestiaires_description_missing = false;
					this.action_etat_vestiaires_responsable_missing = false;
					this.action_etat_vestiaires_application_missing = false;
				}
				break;
			case 'etat_vestiaires_precision' :
				if (this.etat_vestiaires_precision != null) {
					this.etat_vestiaires_precision_missing = false;
				}
				break;
			case 'action_etat_vestiaires_description' :
				if (this.action_etat_vestiaires_description != null) {
					this.action_etat_vestiaires_description_missing = false;
				}
				break;
			case 'action_etat_vestiaires_responsable' :
				if (this.action_etat_vestiaires_responsable != null) {
					this.action_etat_vestiaires_responsable_missing = false;
				}
				break;
			case 'action_etat_vestiaires_application' :
				if (this.action_etat_vestiaires_application != null) {
					this.action_etat_vestiaires_application_missing = false;
				}
				break;
			case 'etat_sanitaires' :
				if (this.etat_sanitaires != null) {
					this.etat_sanitaires_missing = false;
				}
				if (this.etat_sanitaires !== 'NC') {
					this.etat_sanitaires_precision_missing = false;
					this.action_etat_sanitaires_description_missing = false;
					this.action_etat_sanitaires_responsable_missing = false;
					this.action_etat_sanitaires_application_missing = false;
				}
				break;
			case 'etat_sanitaires_precision' :
				if (this.etat_sanitaires_precision != null) {
					this.etat_sanitaires_precision_missing = false;
				}
				break;
			case 'action_etat_sanitaires_description' :
				if (this.action_etat_sanitaires_description != null) {
					this.action_etat_sanitaires_description_missing = false;
				}
				break;
			case 'action_etat_sanitaires_responsable' :
				if (this.action_etat_sanitaires_responsable != null) {
					this.action_etat_sanitaires_responsable_missing = false;
				}
				break;
			case 'action_etat_sanitaires_application' :
				if (this.action_etat_sanitaires_application != null) {
					this.action_etat_sanitaires_application_missing = false;
				}
				break;
			case 'tri_dechets' :
				if (this.tri_dechets != null) {
					this.tri_dechets_missing = false;
				}
				if (this.tri_dechets !== 'NC') {
					this.tri_dechets_precision_missing = false;
					this.action_tri_dechets_description_missing = false;
					this.action_tri_dechets_responsable_missing = false;
					this.action_tri_dechets_application_missing = false;
				}
				break;
			case 'tri_dechets_precision' :
				if (this.tri_dechets_precision != null) {
					this.tri_dechets_precision_missing = false;
				}
				break;
			case 'action_tri_dechets_description' :
				if (this.action_tri_dechets_description != null) {
					this.action_tri_dechets_description_missing = false;
				}
				break;
			case 'action_tri_dechets_responsable' :
				if (this.action_tri_dechets_responsable != null) {
					this.action_tri_dechets_responsable_missing = false;
				}
				break;
			case 'action_tri_dechets_application' :
				if (this.action_tri_dechets_application != null) {
					this.action_tri_dechets_application_missing = false;
				}
				break;
			case 'emplacement_bennes' :
				if (this.emplacement_bennes != null) {
					this.emplacement_bennes_missing = false;
				}
				if (this.emplacement_bennes !== 'NC') {
					this.emplacement_bennes_precision_missing = false;
					this.action_emplacement_bennes_description_missing = false;
					this.action_emplacement_bennes_responsable_missing = false;
					this.action_emplacement_bennes_application_missing = false;
				}
				break;
			case 'emplacement_bennes_precision' :
				if (this.emplacement_bennes_precision != null) {
					this.emplacement_bennes_precision_missing = false;
				}
				break;
			case 'action_emplacement_bennes_description' :
				if (this.action_emplacement_bennes_description != null) {
					this.action_emplacement_bennes_description_missing = false;
				}
				break;
			case 'action_emplacement_bennes_responsable' :
				if (this.action_emplacement_bennes_responsable != null) {
					this.action_emplacement_bennes_responsable_missing = false;
				}
				break;
			case 'action_emplacement_bennes_application' :
				if (this.action_emplacement_bennes_application != null) {
					this.action_emplacement_bennes_application_missing = false;
				}
				break;
			case 'actions_non_securitaires' :
				if (this.actions_non_securitaires != null) {
					this.actions_non_securitaires_missing = false;
				}
				if (this.actions_non_securitaires !== 'Oui') {
					this.actions_non_securitaires_precision_missing = false;
				}
				break;
			case 'actions_non_securitaires_precision' :
				if (this.actions_non_securitaires_precision != null) {
					this.actions_non_securitaires_precision_missing = false;
				}
				break;
			case 'actions_securitaires' :
				if (this.actions_securitaires != null) {
					this.actions_securitaires_missing = false;
				}
				if (this.actions_securitaires !== 'Oui') {
					this.actions_securitaires_precision_missing = false;
				}
				break;
			case 'actions_securitaires_precision' :
				if (this.actions_securitaires_precision != null) {
					this.actions_securitaires_precision_missing = false;
				}
				break;
			case 'situations_dangereuses' :
				if (this.situations_dangereuses != null) {
					this.situations_dangereuses_missing = false;
				}
				if (this.situations_dangereuses !== 'Oui') {
					this.situations_dangereuses_precision_missing = false;
				}
				break;
			case 'situations_dangereuses_precision' :
				if (this.situations_dangereuses_precision != null) {
					this.situations_dangereuses_precision_missing = false;
				}
				break;
			default :
				break;
		}
	}

	getNbActions() {
		let nbActions = 0;
		if (this.voie_acces === 'NC') {
			nbActions++;
		}
		if (this.chantier_signalise === 'NC') {
			nbActions++;
		}
		if (this.sortie_secours_libre === 'NC') {
			nbActions++;
		}
		if (this.chantier_ordre === 'NC') {
			nbActions++;
		}
		if (this.dta === 'NC') {
			nbActions++;
		}
		if (this.autorisation_permis_valide === 'NC') {
			nbActions++;
		}
		if (this.manoeuvre_consignation === 'NC') {
			nbActions++;
		}
		if (this.permis_feu_extincteur === 'NC') {
			nbActions++;
		}
		if (this.permis_penetrer === 'NC') {
			nbActions++;
		}
		if (this.personnel_stemi_identifie === 'NC') {
			nbActions++;
		}
		if (this.personnel_interim_identifie === 'NC') {
			nbActions++;
		}
		if (this.accueils_hse === 'NC') {
			nbActions++;
		}
		if (this.plan_prevention === 'NC') {
			nbActions++;
		}
		if (this.analyse_risques === 'NC') {
			nbActions++;
		}
		if (this.risques_lies_chantier === 'NC') {
			nbActions++;
		}
		if (this.risques_specifiques === 'NC') {
			nbActions++;
		}
		if (this.habilitations_disponibles === 'NC') {
			nbActions++;
		}
		if (this.habilitations_valides === 'NC') {
			nbActions++;
		}
		if (this.sst_present === 'NC') {
			nbActions++;
		}
		if (this.echelle_etat === 'NC') {
			nbActions++;
		}
		if (this.echafaudage_conforme === 'NC') {
			nbActions++;
		}
		if (this.fiches_autorisation === 'NC') {
			nbActions++;
		}
		if (this.nacelle_conforme === 'NC') {
			nbActions++;
		}
		if (this.etat_outillage === 'NC') {
			nbActions++;
		}
		if (this.epi === 'NC') {
			nbActions++;
		}
		if (this.epi_etat === 'NC') {
			nbActions++;
		}
		if (this.risques_coactivite === 'NC') {
			nbActions++;
		}
		if (this.vat_chantier === 'NC') {
			nbActions++;
		}
		if (this.emplacement_douches === 'NC') {
			nbActions++;
		}
		if (this.moyens_alerte === 'NC') {
			nbActions++;
		}
		if (this.localisation_extincteurs === 'NC') {
			nbActions++;
		}
		if (this.harnais_controle === 'NC') {
			nbActions++;
		}
		if (this.cadenas_consignation === 'NC') {
			nbActions++;
		}
		if (this.etat_vestiaires === 'NC') {
			nbActions++;
		}
		if (this.etat_sanitaires === 'NC') {
			nbActions++;
		}
		if (this.tri_dechets === 'NC') {
			nbActions++;
		}
		if (this.emplacement_bennes === 'NC') {
			nbActions++;
		}
		return nbActions;
	}

	getFormData() {
		const formData = new FormData();

		if (this.id != null) {
			formData.append('id', JSON.stringify(this.id));
		}
		if (this.status != null) {
			formData.append('status', this.status);
		}

		// Step 1

		if (this.id_affaire != null) {
			formData.append('id_affaire', JSON.stringify(Number(this.id_affaire)));
		}

		// Step 2

		if (this.nature_travaux != null) {
			formData.append('nature_travaux', this.nature_travaux);
		}
		if (this.nb_stemi != null) {
			formData.append('nb_stemi', JSON.stringify(Number(this.nb_stemi)));
		}
		if (this.nb_interim != null) {
			formData.append('nb_interim', JSON.stringify(Number(this.nb_interim)));
		}

		for (const i in this.personne_stemi_auditee) {
			formData.append('personne_stemi_auditee[]', JSON.stringify(this.personne_stemi_auditee[i].id));
		}
		for (const i in this.personne_interim_auditee) {
			formData.append('personne_interim_auditee[]', JSON.stringify(this.personne_interim_auditee[i].id));
		}

		for (const i in this.personnes_supplementaires) {
			if (this.personnes_supplementaires[i].societe !== null) {
				formData.append('personnes_supplementaires[' + i + '][societe]', this.personnes_supplementaires[i].societe);
			}
			if (this.personnes_supplementaires[i].nom !== null) {
				formData.append('personnes_supplementaires[' + i + '][nom]', this.personnes_supplementaires[i].nom);
			}
			if (this.personnes_supplementaires[i].type !== null) {
				formData.append('personnes_supplementaires[' + i + '][type]', this.personnes_supplementaires[i].type);
			}
		}

		// Step 3

		if (this.voie_acces != null) {
			formData.append('voie_acces', this.voie_acces);
		}
		if (this.voie_acces_precision != null) {
			formData.append('voie_acces_precision', this.voie_acces_precision);
		}
		if (this.chantier_signalise != null) {
			formData.append('chantier_signalise', this.chantier_signalise);
		}
		if (this.chantier_signalise_precision != null) {
			formData.append('chantier_signalise_precision', this.chantier_signalise_precision);
		}
		if (this.sortie_secours_libre != null) {
			formData.append('sortie_secours_libre', this.sortie_secours_libre);
		}
		if (this.sortie_secours_libre_precision != null) {
			formData.append('sortie_secours_libre_precision', this.sortie_secours_libre_precision);
		}
		if (this.chantier_ordre != null) {
			formData.append('chantier_ordre', this.chantier_ordre);
		}
		if (this.chantier_ordre_precision != null) {
			formData.append('chantier_ordre_precision', this.chantier_ordre_precision);
		}
		if (this.dta != null) {
			formData.append('dta', this.dta);
		}
		if (this.dta_precision != null) {
			formData.append('dta_precision', this.dta_precision);
		}
		if (this.autorisation_permis_valide != null) {
			formData.append('autorisation_permis_valide', this.autorisation_permis_valide);
		}
		if (this.autorisation_permis_valide_precision != null) {
			formData.append('autorisation_permis_valide_precision', this.autorisation_permis_valide_precision);
		}
		if (this.manoeuvre_consignation != null) {
			formData.append('manoeuvre_consignation', this.manoeuvre_consignation);
		}
		if (this.manoeuvre_consignation_precision != null) {
			formData.append('manoeuvre_consignation_precision', this.manoeuvre_consignation_precision);
		}
		if (this.permis_feu_extincteur != null) {
			formData.append('permis_feu_extincteur', this.permis_feu_extincteur);
		}
		if (this.permis_feu_extincteur_precision != null) {
			formData.append('permis_feu_extincteur_precision', this.permis_feu_extincteur_precision);
		}
		if (this.permis_penetrer != null) {
			formData.append('permis_penetrer', this.permis_penetrer);
		}
		if (this.permis_penetrer_precision != null) {
			formData.append('permis_penetrer_precision', this.permis_penetrer_precision);
		}
		if (this.personnel_stemi_identifie != null) {
			formData.append('personnel_stemi_identifie', this.personnel_stemi_identifie);
		}
		if (this.personnel_stemi_identifie_precision != null) {
			formData.append('personnel_stemi_identifie_precision', this.personnel_stemi_identifie_precision);
		}
		if (this.personnel_interim_identifie != null) {
			formData.append('personnel_interim_identifie', this.personnel_interim_identifie);
		}
		if (this.personnel_interim_identifie_precision != null) {
			formData.append('personnel_interim_identifie_precision', this.personnel_interim_identifie_precision);
		}
		if (this.accueils_hse != null) {
			formData.append('accueils_hse', this.accueils_hse);
		}
		if (this.accueils_hse_precision != null) {
			formData.append('accueils_hse_precision', this.accueils_hse_precision);
		}
		if (this.plan_prevention != null) {
			formData.append('plan_prevention', this.plan_prevention);
		}
		if (this.plan_prevention_precision != null) {
			formData.append('plan_prevention_precision', this.plan_prevention_precision);
		}
		if (this.risques_lies_chantier != null) {
			formData.append('risques_lies_chantier', this.risques_lies_chantier);
		}
		if (this.risques_lies_chantier_precision != null) {
			formData.append('risques_lies_chantier_precision', this.risques_lies_chantier_precision);
		}
		if (this.risques_specifiques != null) {
			formData.append('risques_specifiques', this.risques_specifiques);
		}
		if (this.risques_specifiques_precision != null) {
			formData.append('risques_specifiques_precision', this.risques_specifiques_precision);
		}
		if (this.habilitations_disponibles != null) {
			formData.append('habilitations_disponibles', this.habilitations_disponibles);
		}
		if (this.habilitations_disponibles_precision != null) {
			formData.append('habilitations_disponibles_precision', this.habilitations_disponibles_precision);
		}
		if (this.habilitations_valides != null) {
			formData.append('habilitations_valides', this.habilitations_valides);
		}
		if (this.habilitations_valides_precision != null) {
			formData.append('habilitations_valides_precision', this.habilitations_valides_precision);
		}
		if (this.sst_present != null) {
			formData.append('sst_present', this.sst_present);
		}
		if (this.sst_present_precision != null) {
			formData.append('sst_present_precision', this.sst_present_precision);
		}
		if (this.echelle_etat != null) {
			formData.append('echelle_etat', this.echelle_etat);
		}
		if (this.echelle_etat_precision != null) {
			formData.append('echelle_etat_precision', this.echelle_etat_precision);
		}
		if (this.echafaudage_conforme != null) {
			formData.append('echafaudage_conforme', this.echafaudage_conforme);
		}
		if (this.echafaudage_conforme_precision != null) {
			formData.append('echafaudage_conforme_precision', this.echafaudage_conforme_precision);
		}
		if (this.fiches_autorisation != null) {
			formData.append('fiches_autorisation', this.fiches_autorisation);
		}
		if (this.fiches_autorisation_precision != null) {
			formData.append('fiches_autorisation_precision', this.fiches_autorisation_precision);
		}
		if (this.nacelle_conforme != null) {
			formData.append('nacelle_conforme', this.nacelle_conforme);
		}
		if (this.nacelle_conforme_precision != null) {
			formData.append('nacelle_conforme_precision', this.nacelle_conforme_precision);
		}
		if (this.etat_outillage != null) {
			formData.append('etat_outillage', this.etat_outillage);
		}
		if (this.etat_outillage_precision != null) {
			formData.append('etat_outillage_precision', this.etat_outillage_precision);
		}
		if (this.epi != null) {
			formData.append('epi', this.epi);
		}
		if (this.epi_precision != null) {
			formData.append('epi_precision', this.epi_precision);
		}
		if (this.epi_etat != null) {
			formData.append('epi_etat', this.epi_etat);
		}
		if (this.epi_etat_precision != null) {
			formData.append('epi_etat_precision', this.epi_etat_precision);
		}
		if (this.risques_coactivite != null) {
			formData.append('risques_coactivite', this.risques_coactivite);
		}
		if (this.risques_coactivite_precision != null) {
			formData.append('risques_coactivite_precision', this.risques_coactivite_precision);
		}
		if (this.vat_chantier != null) {
			formData.append('vat_chantier', this.vat_chantier);
		}
		if (this.vat_chantier_precision != null) {
			formData.append('vat_chantier_precision', this.vat_chantier_precision);
		}
		if (this.emplacement_douches != null) {
			formData.append('emplacement_douches', this.emplacement_douches);
		}
		if (this.emplacement_douches_precision != null) {
			formData.append('emplacement_douches_precision', this.emplacement_douches_precision);
		}
		if (this.moyens_alerte != null) {
			formData.append('moyens_alerte', this.moyens_alerte);
		}
		if (this.moyens_alerte_precision != null) {
			formData.append('moyens_alerte_precision', this.moyens_alerte_precision);
		}
		if (this.localisation_extincteurs != null) {
			formData.append('localisation_extincteurs', this.localisation_extincteurs);
		}
		if (this.localisation_extincteurs_precision != null) {
			formData.append('localisation_extincteurs_precision', this.localisation_extincteurs_precision);
		}
		if (this.harnais_controle != null) {
			formData.append('harnais_controle', this.harnais_controle);
		}
		if (this.harnais_controle_precision != null) {
			formData.append('harnais_controle_precision', this.harnais_controle_precision);
		}
		if (this.cadenas_consignation != null) {
			formData.append('cadenas_consignation', this.cadenas_consignation);
		}
		if (this.cadenas_consignation_precision != null) {
			formData.append('cadenas_consignation_precision', this.cadenas_consignation_precision);
		}
		if (this.etat_vestiaires != null) {
			formData.append('etat_vestiaires', this.etat_vestiaires);
		}
		if (this.etat_vestiaires_precision != null) {
			formData.append('etat_vestiaires_precision', this.etat_vestiaires_precision);
		}
		if (this.etat_sanitaires != null) {
			formData.append('etat_sanitaires', this.etat_sanitaires);
		}
		if (this.etat_sanitaires_precision != null) {
			formData.append('etat_sanitaires_precision', this.etat_sanitaires_precision);
		}
		if (this.tri_dechets != null) {
			formData.append('tri_dechets', this.tri_dechets);
		}
		if (this.tri_dechets_precision != null) {
			formData.append('tri_dechets_precision', this.tri_dechets_precision);
		}
		if (this.emplacement_bennes != null) {
			formData.append('emplacement_bennes', this.emplacement_bennes);
		}
		if (this.emplacement_bennes_precision != null) {
			formData.append('emplacement_bennes_precision', this.emplacement_bennes_precision);
		}
		if (this.actions_non_securitaires != null) {
			formData.append('actions_non_securitaires', this.actions_non_securitaires);
		}
		if (this.actions_non_securitaires_precision != null) {
			formData.append('actions_non_securitaires_precision', this.actions_non_securitaires_precision);
		}
		if (this.actions_securitaires != null) {
			formData.append('actions_securitaires', this.actions_securitaires);
		}
		if (this.actions_securitaires_precision != null) {
			formData.append('actions_securitaires_precision', this.actions_securitaires_precision);
		}
		if (this.situations_dangereuses != null) {
			formData.append('situations_dangereuses', this.situations_dangereuses);
		}
		if (this.situations_dangereuses_precision != null) {
			formData.append('situations_dangereuses_precision', this.situations_dangereuses_precision);
		}

		for (const i in this.photos) {
			const curPhoto = this.photos[i];
			if (curPhoto.type === 'local') {
				formData.append('photos[]', FileService.dataURItoBlob(curPhoto.preview), curPhoto.fileName);
			}
		}

		// Step 4

		if (this.action_voie_acces_description != null) {
			formData.append('action_voie_acces_description', this.action_voie_acces_description);
		}
		if (this.action_voie_acces_responsable != null) {
			formData.append('action_voie_acces_responsable', JSON.stringify(this.action_voie_acces_responsable.id));
		}
		if (this.action_voie_acces_application != null) {
			formData.append('action_voie_acces_application', this.action_voie_acces_application);
		}
		if (this.action_chantier_signalise_description != null) {
			formData.append('action_chantier_signalise_description', this.action_chantier_signalise_description);
		}
		if (this.action_chantier_signalise_responsable != null) {
			formData.append('action_chantier_signalise_responsable', JSON.stringify(this.action_chantier_signalise_responsable.id));
		}
		if (this.action_chantier_signalise_application != null) {
			formData.append('action_chantier_signalise_application', this.action_chantier_signalise_application);
		}
		if (this.action_sortie_secours_libre_description != null) {
			formData.append('action_sortie_secours_libre_description', this.action_sortie_secours_libre_description);
		}
		if (this.action_sortie_secours_libre_responsable != null) {
			formData.append('action_sortie_secours_libre_responsable', JSON.stringify(this.action_sortie_secours_libre_responsable.id));
		}
		if (this.action_sortie_secours_libre_application != null) {
			formData.append('action_sortie_secours_libre_application', this.action_sortie_secours_libre_application);
		}
		if (this.action_chantier_ordre_description != null) {
			formData.append('action_chantier_ordre_description', this.action_chantier_ordre_description);
		}
		if (this.action_chantier_ordre_responsable != null) {
			formData.append('action_chantier_ordre_responsable', JSON.stringify(this.action_chantier_ordre_responsable.id));
		}
		if (this.action_chantier_ordre_application != null) {
			formData.append('action_chantier_ordre_application', this.action_chantier_ordre_application);
		}
		if (this.action_dta_description != null) {
			formData.append('action_dta_description', this.action_dta_description);
		}
		if (this.action_dta_responsable != null) {
			formData.append('action_dta_responsable', JSON.stringify(this.action_dta_responsable.id));
		}
		if (this.action_dta_application != null) {
			formData.append('action_dta_application', this.action_dta_application);
		}
		if (this.action_autorisation_permis_valide_description != null) {
			formData.append('action_autorisation_permis_valide_description', this.action_autorisation_permis_valide_description);
		}
		if (this.action_autorisation_permis_valide_responsable != null) {
			formData.append('action_autorisation_permis_valide_responsable', JSON.stringify(this.action_autorisation_permis_valide_responsable.id));
		}
		if (this.action_autorisation_permis_valide_application != null) {
			formData.append('action_autorisation_permis_valide_application', this.action_autorisation_permis_valide_application);
		}
		if (this.action_manoeuvre_consignation_description != null) {
			formData.append('action_manoeuvre_consignation_description', this.action_manoeuvre_consignation_description);
		}
		if (this.action_manoeuvre_consignation_responsable != null) {
			formData.append('action_manoeuvre_consignation_responsable', JSON.stringify(this.action_manoeuvre_consignation_responsable.id));
		}
		if (this.action_manoeuvre_consignation_application != null) {
			formData.append('action_manoeuvre_consignation_application', this.action_manoeuvre_consignation_application);
		}
		if (this.action_permis_feu_extincteur_description != null) {
			formData.append('action_permis_feu_extincteur_description', this.action_permis_feu_extincteur_description);
		}
		if (this.action_permis_feu_extincteur_responsable != null) {
			formData.append('action_permis_feu_extincteur_responsable', JSON.stringify(this.action_permis_feu_extincteur_responsable.id));
		}
		if (this.action_permis_feu_extincteur_application != null) {
			formData.append('action_permis_feu_extincteur_application', this.action_permis_feu_extincteur_application);
		}
		if (this.action_permis_penetrer_description != null) {
			formData.append('action_permis_penetrer_description', this.action_permis_penetrer_description);
		}
		if (this.action_permis_penetrer_responsable != null) {
			formData.append('action_permis_penetrer_responsable', JSON.stringify(this.action_permis_penetrer_responsable.id));
		}
		if (this.action_permis_penetrer_application != null) {
			formData.append('action_permis_penetrer_application', this.action_permis_penetrer_application);
		}
		if (this.action_personnel_stemi_identifie_description != null) {
			formData.append('action_personnel_stemi_identifie_description', this.action_personnel_stemi_identifie_description);
		}
		if (this.action_personnel_stemi_identifie_responsable != null) {
			formData.append('action_personnel_stemi_identifie_responsable', JSON.stringify(this.action_personnel_stemi_identifie_responsable.id));
		}
		if (this.action_personnel_stemi_identifie_application != null) {
			formData.append('action_personnel_stemi_identifie_application', this.action_personnel_stemi_identifie_application);
		}
		if (this.action_personnel_interim_identifie_description != null) {
			formData.append('action_personnel_interim_identifie_description', this.action_personnel_interim_identifie_description);
		}
		if (this.action_personnel_interim_identifie_responsable != null) {
			formData.append('action_personnel_interim_identifie_responsable', JSON.stringify(this.action_personnel_interim_identifie_responsable.id));
		}
		if (this.action_personnel_interim_identifie_application != null) {
			formData.append('action_personnel_interim_identifie_application', this.action_personnel_interim_identifie_application);
		}
		if (this.action_accueils_hse_description != null) {
			formData.append('action_accueils_hse_description', this.action_accueils_hse_description);
		}
		if (this.action_accueils_hse_responsable != null) {
			formData.append('action_accueils_hse_responsable', JSON.stringify(this.action_accueils_hse_responsable.id));
		}
		if (this.action_accueils_hse_application != null) {
			formData.append('action_accueils_hse_application', this.action_accueils_hse_application);
		}
		if (this.action_plan_prevention_description != null) {
			formData.append('action_plan_prevention_description', this.action_plan_prevention_description);
		}
		if (this.action_plan_prevention_responsable != null) {
			formData.append('action_plan_prevention_responsable', JSON.stringify(this.action_plan_prevention_responsable.id));
		}
		if (this.action_plan_prevention_application != null) {
			formData.append('action_plan_prevention_application', this.action_plan_prevention_application);
		}
		if (this.action_risques_lies_chantier_description != null) {
			formData.append('action_risques_lies_chantier_description', this.action_risques_lies_chantier_description);
		}
		if (this.action_risques_lies_chantier_responsable != null) {
			formData.append('action_risques_lies_chantier_responsable', JSON.stringify(this.action_risques_lies_chantier_responsable.id));
		}
		if (this.action_risques_lies_chantier_application != null) {
			formData.append('action_risques_lies_chantier_application', this.action_risques_lies_chantier_application);
		}
		if (this.action_risques_specifiques_description != null) {
			formData.append('action_risques_specifiques_description', this.action_risques_specifiques_description);
		}
		if (this.action_risques_specifiques_responsable != null) {
			formData.append('action_risques_specifiques_responsable', JSON.stringify(this.action_risques_specifiques_responsable.id));
		}
		if (this.action_risques_specifiques_application != null) {
			formData.append('action_risques_specifiques_application', this.action_risques_specifiques_application);
		}
		if (this.action_habilitations_disponibles_description != null) {
			formData.append('action_habilitations_disponibles_description', this.action_habilitations_disponibles_description);
		}
		if (this.action_habilitations_disponibles_responsable != null) {
			formData.append('action_habilitations_disponibles_responsable', JSON.stringify(this.action_habilitations_disponibles_responsable.id));
		}
		if (this.action_habilitations_disponibles_application != null) {
			formData.append('action_habilitations_disponibles_application', this.action_habilitations_disponibles_application);
		}
		if (this.action_habilitations_valides_description != null) {
			formData.append('action_habilitations_valides_description', this.action_habilitations_valides_description);
		}
		if (this.action_habilitations_valides_responsable != null) {
			formData.append('action_habilitations_valides_responsable', JSON.stringify(this.action_habilitations_valides_responsable.id));
		}
		if (this.action_habilitations_valides_application != null) {
			formData.append('action_habilitations_valides_application', this.action_habilitations_valides_application);
		}
		if (this.action_sst_present_description != null) {
			formData.append('action_sst_present_description', this.action_sst_present_description);
		}
		if (this.action_sst_present_responsable != null) {
			formData.append('action_sst_present_responsable', JSON.stringify(this.action_sst_present_responsable.id));
		}
		if (this.action_sst_present_application != null) {
			formData.append('action_sst_present_application', this.action_sst_present_application);
		}
		if (this.action_echelle_etat_description != null) {
			formData.append('action_echelle_etat_description', this.action_echelle_etat_description);
		}
		if (this.action_echelle_etat_responsable != null) {
			formData.append('action_echelle_etat_responsable', JSON.stringify(this.action_echelle_etat_responsable.id));
		}
		if (this.action_echelle_etat_application != null) {
			formData.append('action_echelle_etat_application', this.action_echelle_etat_application);
		}
		if (this.action_echafaudage_conforme_description != null) {
			formData.append('action_echafaudage_conforme_description', this.action_echafaudage_conforme_description);
		}
		if (this.action_echafaudage_conforme_responsable != null) {
			formData.append('action_echafaudage_conforme_responsable', JSON.stringify(this.action_echafaudage_conforme_responsable.id));
		}
		if (this.action_echafaudage_conforme_application != null) {
			formData.append('action_echafaudage_conforme_application', this.action_echafaudage_conforme_application);
		}
		if (this.action_fiches_autorisation_description != null) {
			formData.append('action_fiches_autorisation_description', this.action_fiches_autorisation_description);
		}
		if (this.action_fiches_autorisation_responsable != null) {
			formData.append('action_fiches_autorisation_responsable', JSON.stringify(this.action_fiches_autorisation_responsable.id));
		}
		if (this.action_fiches_autorisation_application != null) {
			formData.append('action_fiches_autorisation_application', this.action_fiches_autorisation_application);
		}
		if (this.action_nacelle_conforme_description != null) {
			formData.append('action_nacelle_conforme_description', this.action_nacelle_conforme_description);
		}
		if (this.action_nacelle_conforme_responsable != null) {
			formData.append('action_nacelle_conforme_responsable', JSON.stringify(this.action_nacelle_conforme_responsable.id));
		}
		if (this.action_nacelle_conforme_application != null) {
			formData.append('action_nacelle_conforme_application', this.action_nacelle_conforme_application);
		}
		if (this.action_etat_outillage_description != null) {
			formData.append('action_etat_outillage_description', this.action_etat_outillage_description);
		}
		if (this.action_etat_outillage_responsable != null) {
			formData.append('action_etat_outillage_responsable', JSON.stringify(this.action_etat_outillage_responsable.id));
		}
		if (this.action_etat_outillage_application != null) {
			formData.append('action_etat_outillage_application', this.action_etat_outillage_application);
		}
		if (this.action_epi_description != null) {
			formData.append('action_epi_description', this.action_epi_description);
		}
		if (this.action_epi_responsable != null) {
			formData.append('action_epi_responsable', JSON.stringify(this.action_epi_responsable.id));
		}
		if (this.action_epi_application != null) {
			formData.append('action_epi_application', this.action_epi_application);
		}
		if (this.action_epi_etat_description != null) {
			formData.append('action_epi_etat_description', this.action_epi_etat_description);
		}
		if (this.action_epi_etat_responsable != null) {
			formData.append('action_epi_etat_responsable', JSON.stringify(this.action_epi_etat_responsable.id));
		}
		if (this.action_epi_etat_application != null) {
			formData.append('action_epi_etat_application', this.action_epi_etat_application);
		}
		if (this.action_risques_coactivite_description != null) {
			formData.append('action_risques_coactivite_description', this.action_risques_coactivite_description);
		}
		if (this.action_risques_coactivite_responsable != null) {
			formData.append('action_risques_coactivite_responsable', JSON.stringify(this.action_risques_coactivite_responsable.id));
		}
		if (this.action_risques_coactivite_application != null) {
			formData.append('action_risques_coactivite_application', this.action_risques_coactivite_application);
		}
		if (this.action_vat_chantier_description != null) {
			formData.append('action_vat_chantier_description', this.action_vat_chantier_description);
		}
		if (this.action_vat_chantier_responsable != null) {
			formData.append('action_vat_chantier_responsable', JSON.stringify(this.action_vat_chantier_responsable.id));
		}
		if (this.action_vat_chantier_application != null) {
			formData.append('action_vat_chantier_application', this.action_vat_chantier_application);
		}
		if (this.action_emplacement_douches_description != null) {
			formData.append('action_emplacement_douches_description', this.action_emplacement_douches_description);
		}
		if (this.action_emplacement_douches_responsable != null) {
			formData.append('action_emplacement_douches_responsable', JSON.stringify(this.action_emplacement_douches_responsable.id));
		}
		if (this.action_emplacement_douches_application != null) {
			formData.append('action_emplacement_douches_application', this.action_emplacement_douches_application);
		}
		if (this.action_moyens_alerte_description != null) {
			formData.append('action_moyens_alerte_description', this.action_moyens_alerte_description);
		}
		if (this.action_moyens_alerte_responsable != null) {
			formData.append('action_moyens_alerte_responsable', JSON.stringify(this.action_moyens_alerte_responsable.id));
		}
		if (this.action_moyens_alerte_application != null) {
			formData.append('action_moyens_alerte_application', this.action_moyens_alerte_application);
		}
		if (this.action_localisation_extincteurs_description != null) {
			formData.append('action_localisation_extincteurs_description', this.action_localisation_extincteurs_description);
		}
		if (this.action_localisation_extincteurs_responsable != null) {
			formData.append('action_localisation_extincteurs_responsable', JSON.stringify(this.action_localisation_extincteurs_responsable.id));
		}
		if (this.action_localisation_extincteurs_application != null) {
			formData.append('action_localisation_extincteurs_application', this.action_localisation_extincteurs_application);
		}
		if (this.action_harnais_controle_description != null) {
			formData.append('action_harnais_controle_description', this.action_harnais_controle_description);
		}
		if (this.action_harnais_controle_responsable != null) {
			formData.append('action_harnais_controle_responsable', JSON.stringify(this.action_harnais_controle_responsable.id));
		}
		if (this.action_harnais_controle_application != null) {
			formData.append('action_harnais_controle_application', this.action_harnais_controle_application);
		}
		if (this.action_cadenas_consignation_description != null) {
			formData.append('action_cadenas_consignation_description', this.action_cadenas_consignation_description);
		}
		if (this.action_cadenas_consignation_responsable != null) {
			formData.append('action_cadenas_consignation_responsable', JSON.stringify(this.action_cadenas_consignation_responsable.id));
		}
		if (this.action_cadenas_consignation_application != null) {
			formData.append('action_cadenas_consignation_application', this.action_cadenas_consignation_application);
		}
		if (this.action_etat_vestiaires_description != null) {
			formData.append('action_etat_vestiaires_description', this.action_etat_vestiaires_description);
		}
		if (this.action_etat_vestiaires_responsable != null) {
			formData.append('action_etat_vestiaires_responsable', JSON.stringify(this.action_etat_vestiaires_responsable.id));
		}
		if (this.action_etat_vestiaires_application != null) {
			formData.append('action_etat_vestiaires_application', this.action_etat_vestiaires_application);
		}
		if (this.action_etat_sanitaires_description != null) {
			formData.append('action_etat_sanitaires_description', this.action_etat_sanitaires_description);
		}
		if (this.action_etat_sanitaires_responsable != null) {
			formData.append('action_etat_sanitaires_responsable', JSON.stringify(this.action_etat_sanitaires_responsable.id));
		}
		if (this.action_etat_sanitaires_application != null) {
			formData.append('action_etat_sanitaires_application', this.action_etat_sanitaires_application);
		}
		if (this.action_tri_dechets_description != null) {
			formData.append('action_tri_dechets_description', this.action_tri_dechets_description);
		}
		if (this.action_tri_dechets_responsable != null) {
			formData.append('action_tri_dechets_responsable', JSON.stringify(this.action_tri_dechets_responsable.id));
		}
		if (this.action_tri_dechets_application != null) {
			formData.append('action_tri_dechets_application', this.action_tri_dechets_application);
		}
		if (this.action_emplacement_bennes_description != null) {
			formData.append('action_emplacement_bennes_description', this.action_emplacement_bennes_description);
		}
		if (this.action_emplacement_bennes_responsable != null) {
			formData.append('action_emplacement_bennes_responsable', JSON.stringify(this.action_emplacement_bennes_responsable.id));
		}
		if (this.action_emplacement_bennes_application != null) {
			formData.append('action_emplacement_bennes_application', this.action_emplacement_bennes_application);
		}

		// Step 5

		if (this.date_signature != null) {
			formData.append('date_signature', moment(this.date_signature).format());
		}

		return formData;
	}

	personnesAuditeesList(personnesAuditees: Array<UserObject>) {
		if (personnesAuditees != null && personnesAuditees.length > 0) {
			let list = '';
			for (const i in personnesAuditees) {
				list += personnesAuditees[i].complete_name + '\n';
			}
			list = list.substr(0, list.length - 1);
			return list;
		} else {
			return 'Choisir';
		}
	}
}
