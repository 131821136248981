import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
	transform(value: any): number {
		if (value != null) {
			return this.localeString(value);
		} else {
			return null;
		}
	}

	missingOneDecimalCheck(nStr) {
		nStr += '';
		const x = nStr.split(',')[1];
		return x && x.length === 1;
	}

	missingAllDecimalsCheck(nStr) {
		nStr += '';
		const x = nStr.split(',')[1];
		return !x;
	}

	localeString(nStr) {
		if (nStr === '') {
			return '';
		}
		let x, x1, x2, rgx, y1, y2;
		nStr += '';
		x = nStr.split('.');
		x1 = x[0];
		x2 = x.length > 1 ? ',' + x[1] : '';
		rgx = /(\d+)(\d{3})/;
		while (rgx.test(x1)) {
			x1 = x1.replace(rgx, '$1' + ' ' + '$2');
		}

		if (x1.indexOf(',') !== -1) {
			y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');

			y2 = x1.split(',');
			x = y2[0] + y1;
		} else {
			x = x1 + x2;
			if (this.missingOneDecimalCheck(x)) {
				return x += '0';
			}
			if (this.missingAllDecimalsCheck(x)) {
				return x += ',00';
			}
		}

		return x;
	}
}
