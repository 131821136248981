import {UserObject} from '../../user.object';

import * as moment from 'moment';

export class ConsigneHarnaisObject {
	id: number;
	table = 'consigne_harnais';
	titre = 'Sensibilisation au port du harnais';
	createdAt: string;
	updatedAt: string;
	status: string;
	createdBy: UserObject;
	updatedBy: UserObject;

	// Step 1

	date_signature: string;
	signatories: Array<UserObject>;
	signatories_dates: Array<string>;

	// Status

	// draft
	// signed

	constructor(
		id = null,
		createdAt = null,
		updatedAt = null,
		status = 'draft',
		createdBy = null,
		updatedBy = null,
		// Step 6

		date_signature = null,
		signatories = [],
		signatories_dates = [],
	) {
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.status = status;
		this.createdBy = createdBy;
		this.updatedBy = updatedBy;

		// Step 6

		this.date_signature = date_signature;
		this.signatories = signatories;
		this.signatories_dates = signatories_dates;
	}

	getFormData() {
		const formData = new FormData();

		if (this.id != null) {
			formData.append('id', JSON.stringify(this.id));
		}
		if (this.status != null) {
			formData.append('status', this.status);
		}

		// Step 1

		if (this.date_signature != null) {
			formData.append('date_signature', moment(this.date_signature).format());
		}

		for (const i in this.signatories) {
			if (this.signatories[i].id !== null) {
				formData.append('autres_signatures[' + i + '][id_user]', JSON.stringify(this.signatories[i].id));
			}
			if (this.signatories_dates[i] !== null) {
				formData.append('autres_signatures[' + i + '][date]', moment(this.signatories_dates[i]).format());
			}
		}

		return formData;
	}
}
